import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { hidePopupAction, showPopupAction } from 'src/app/store/actions/popup.actions';
import { IAppState } from 'src/app/store/states/app.state';
import { selectAgentPhoneNumber } from '../../../../store/selectors/active-theme.selector';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'app-agent-details',
	templateUrl: './agent-details.component.html',
	styleUrls: ['./agent-details.component.scss'],
})
export class AgentDetailsComponent implements OnInit {
	phoneNumber: string;
	innerHtml: string;
	data: any;
	vendorName: string;
	firstName: string;

	constructor(
		private cdRef: ChangeDetectorRef,
		private store: Store<IAppState>,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.store
			.select(selectAgentPhoneNumber)
			.pipe(untilDestroyed(this))
			.subscribe((phoneNumber) => {
				this.phoneNumber = phoneNumber;
			});
	}

	handleRequestCallback() {
		this.store.dispatch(hidePopupAction());
		this.store.dispatch(
			showPopupAction({
				componentName: 'thank-you-callback',
				payload: {
					fullScreen: false,
					desktopCenter: true,
					text: `${this.translateService.instant('popups.titles.thank-you-callback')} ${this.data.payload.name}`,
					name: this.data.name,
					gtmLabel: 'CallbackPopUp',
				},
			})
		);
	}
}
