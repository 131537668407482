import { Component, Injector, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { IAppState } from 'src/app/store/states/app.state';
import { EventActions } from '../../../../models/event-data.model';
import { BuyCoverageButtonService } from '../../../../services/buy-coverage-button.service';
import { selectAddress, selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import {
	AdditionalDwellingCoverageLimitOptions,
	ADDITIONAL_COVERAGES_TEXTS,
	FoundationCoverageOptions,
	OrdinanceorLawAmountofCoverageOptions,
	WaterBackupOptions,
} from './texts.data';

import { CoverageModel } from '../../../../models/coverage.model';
import { ActionApiService } from 'src/app/services/action-api.service';
import { hidePopupAction } from '../../../../store/actions/popup.actions';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BasePageComponent } from '../../pages/base-page.component';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { RateService } from '../../pages/shared/result/services/rate.service';

@UntilDestroy()
@Component({
	selector: 'app-additional-coverages',
	templateUrl: './additional-coverages.component.html',
	styleUrls: ['./additional-coverages.component.scss'],
})
export class AdditionalCoveragesComponent extends BasePageComponent implements OnInit {
	data: any;
	eventActions = EventActions;
	coveragesTexts = ADDITIONAL_COVERAGES_TEXTS;
	options = {
		OrdinanceorLawAmountofCoverage: OrdinanceorLawAmountofCoverageOptions,
		FoundationCoverage: FoundationCoverageOptions,
		WaterBackup: WaterBackupOptions,
		AdditionalDwellingCoverageLimit: AdditionalDwellingCoverageLimitOptions,
	};

	isOffline: boolean;
	actionApiService: ActionApiService;
	carrier;
	openForSelect = false;
	lob;
	selectRatePlanSelectedForOfflineBid: CoverageModel[];
	isSelected: boolean;
	isYearPriceVisible = true;
	discounts;
	showLoading = false;
	formGroup: FormGroup;

	constructor(
		protected store: Store<IAppState>,
		actionApiService: ActionApiService,
		injector: Injector,
		private rateServices: RateService

	) {
		super(injector, store);
		this.formBuilder = injector.get(FormBuilder);
		this.actionApiService = actionApiService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.formGroup);
	}

	initForm() {
		combineLatest([this.store.select(selectAddress), this.store.select(selectQuoteData)])
			.pipe(first())
			.subscribe(([address, quoteData]) => {
				this.coveragesTexts = this.coveragesTexts.filter((coverage) => {
					if (
						!coverage.availableStates ||
						(coverage.availableStates && coverage.availableStates.includes(address.State))
					) {
						return coverage;
					}
				});
				this.formGroup = new FormGroup({});
				for (let i = 0; i < this.coveragesTexts.length; i++) {
					this.formGroup.addControl(
						this.coveragesTexts[i].name,
						new FormControl(quoteData[this.coveragesTexts[i].name] ? true : false, Validators.required)
					);
					if (this.coveragesTexts[i].withDD) {
						this.formGroup.addControl(
							this.coveragesTexts[i].name + 'DD',
							new FormControl(
								{
									value: quoteData[this.coveragesTexts[i].name] ? quoteData[this.coveragesTexts[i].name] : null,
									disabled: quoteData[this.coveragesTexts[i].name] ? false : true,
								},
								Validators.required
							)
						);
					}
				}
			});
	}

	handleToggle(coverageName) {
		if (this.formGroup.get(coverageName + 'DD')) {
			if (this.formGroup.get(coverageName).value !== false) {
				this.formGroup.get(coverageName + 'DD').disable();
			} else {
				this.formGroup.get(coverageName + 'DD').enable();
			}
		}
	}

	handleContinue() {
		//call to resubmission only if some data has changed
		if (this.formGroup.touched) {
			let coveragesValues = { ...this.formGroup.value };
			//replacing the coverageDD value to coverage value and deletes coverageDD value
			this.coveragesTexts
				.filter((i) => i.withDD)
				.map((j) => j.name)
				.forEach((element) => {
					if (this.formGroup.get(element + 'DD') !== null && this.formGroup.get(element + 'DD').enabled) {
						coveragesValues[element] =
							this.formGroup.get(element + 'DD').value == false ? null : this.formGroup.get(element + 'DD').value;
						delete coveragesValues[element + 'DD'];
					} else {
						coveragesValues[element] = null;
					}
				});



			// Updates the quote
			this.actionApiService
				.updateApplication({
					...coveragesValues,
				})
				.pipe(first())
				.subscribe(() => {
					this.store.dispatch(
						UpdateQuoteData({
							data: { ...coveragesValues },
						})
					);
					this.rateServices.editCoverages(true);
					this.store.dispatch(hidePopupAction());
				});
		} else {
			this.store.dispatch(hidePopupAction());
		}

	}

	checkIsActive(cov) {
		return this.formGroup.get(cov + 'DD').enabled;
	}

	handleCancel() {
		this.store.dispatch(hidePopupAction());
	}
}
