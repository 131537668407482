<div class="primary-vehicle">
	<div class="top">
		<div class="left">
			<img
				*ngIf="data.value?.PLMake"
				[class.car-disabled]="!data.value?.enabled"
				alt="{{ data.value?.PLMake }} logo"
				class="car-logo"
				src="assets/images/car-logos/thumb/{{ getVehicleLogoName(data.value?.PLMake) }}.png"
			/>
		</div>

		<div class="right">
			<div class="accordion-title-wrapper">
				<div *ngIf="title" class="car-name">
					{{ title }}
				</div>
			</div>

			<div class="accordion-title-wrapper">
				<div *ngIf="data.value.VIN" class="car-vin">
					{{ data.value.VIN }}
				</div>
			</div>
		</div>
	</div>

	<div [formGroup]="form" class="form-wrapper">
		<app-question-wrapper *ngIf="form.get(Fields.OwnershipType.name).enabled">
			<app-dropdown
				[name]="Fields.OwnershipType.name"
				[options]="OwnershipTypeOptions"
				[reAlphabeticalOrder]="false"
				labelInsideField="{{ 'labels.ownershipType' | translate }}"
			></app-dropdown>
		</app-question-wrapper>

		<app-question-wrapper>
			<unmasked-input
				[name]="Fields.NumberOfMiles.name"
				[pattern]="Fields.NumberOfMiles.pattern"
				[placeholder]="'labels.numberOfMiles' | translate"
				[withCommaFormat]="true"
				maxlength="6"
				secondaryType="number"
			></unmasked-input>
			<div class="sub-text">{{ "titles.medianMileagePerYear" | translate }}</div>
		</app-question-wrapper>
	</div>
</div>
<div class="divider"></div>
<div class="footer">
	<app-button
		(clicked)="updatePrimaryVehicle()"
		[disabled]="!form.valid"
		[title]="'buttons.continue' | translate"
		materialIcon="chevron_right"
	></app-button>
</div>
