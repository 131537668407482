import { createAction, props } from '@ngrx/store';
import { IPaymentPlan } from 'src/entities/result-page.interfaces';
import { IResultState } from '../states/result-data.state';
import { CoverageModel } from '../../models/coverage.model';

export const getSubmissionResults = createAction('[getSubmissionResults]', props<{ data: string }>());
export const goToSubmission = createAction('[Go Submissions] go to');
export const getPaymentData = createAction('[Payment Data] get');
export const showAltRates = createAction('[Alt Rates] show', props<{ show: boolean }>());
export const setPaymentData = createAction('[Payment Data] set', props<{ url: string; transactionId: string }>());
export const setResultData = createAction('[Result Data] set', props<{ data: IResultState }>());
export const singleApplicationSubmitted = createAction('[Result Data] single application submitted');
export const carrierSelectSubmitted = createAction(
	'[Result Data] carrier select submitted',
	props<{ carrier: string }>()
);
export const carrierSelectedForOfflineBid = createAction(
	'[Result Data] carrier selected for offline bid',
	props<{ rates: CoverageModel[] }>()
);
export const clearToggledRatesSelected = createAction('[Result Data] clear toggled rates selected');
export const addToggledRatesSelected = createAction(
	'[Result Data] add toggled rates selected',
	props<{ rates: CoverageModel[] }>()
);
export const removeToggledRatesSelected = createAction(
	'[Result Data] remove toggled rates selected',
	props<{ rates: CoverageModel[] }>()
);
export const toggleOtherRates = createAction('[Other Rates] toggle');
export const carrierSelect = createAction(
	'[Carrier] select',
	props<{ rateParamsArray: { carrier: string; rateID: string }[] }>()
);
export const paymentPlanSelect = createAction('[Payment Plan] select', props<{ selectedPaymentPlan: IPaymentPlan }>());
export const setOriginTypeOfDwelling = createAction(
	'[OriginTypeOfDwelling] set',
	props<{ OriginTypeOfDwelling: string }>()
);
export const setIsAdditionalQuestionsChosen = createAction(
	'[IsAdditionalQuestionsChosen] set',
	props<{ isAdditionalQuestionsChosen: boolean }>()
);

export const setIsSecondAdditionalQuestionsChosen = createAction('[IsSecondAdditionalQuestionsChosen] set');
export const setIsDetailsChosen = createAction('[IsDetailsChosen] set');
export const setIsUtilitiesChosen = createAction('[IsUtilitiesChosen] set');
export const setSource = createAction('[Source] set', props<{ data: string }>());
export const setSecretSource = createAction('[SecretSource] set', props<{ data: string }>());
export const setIsQuoteCompleted = createAction('[Source] set quote completed', props<{ data: boolean }>());

export const ResetResultData = createAction('[Result Data] reset');
export const setIsDatePolicyChosen = createAction('[IsDatePolicyChosen] set', props<{ isDatePolicyChosen: boolean }>());
