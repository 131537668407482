import { Injectable } from '@angular/core';

import { IAppState } from '../store/states/app.state';

@Injectable({ providedIn: 'root' })
export class ConfigService {
	config: IConfig;

	constructor() { }

	getConfig(): IConfig {
		return this.config;
	}

	getState(): IAppState {
		const state = sessionStorage.getItem('state');
		return state ? JSON.parse(state) : null;
	}

	getTenant(): string {
		return sessionStorage.getItem('tenant');
	}

	getKey(key: string): string {
		return sessionStorage.getItem(key);
	}

	setKey(key: string, url: string) {
		sessionStorage.setItem(key, url);
	}
}

export interface IConfig {
	ENV: string;
	production: string;
	apiBaseUrl: string;
	contentApiUrl: string;
	tagManagerId: string;
	splunkUrl: string;
	splunkRTDS: string;
	sessionTimeoutMinutes: number;
	offlineLobLinks: any;
	placesApiKey: string;
	videoStorageUrl: string;
	mixpanel_proxy_url: string;
}
