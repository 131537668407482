import { createAction, props } from '@ngrx/store';
import { IProgressMeter } from '../states/progress-meter.state';

export const UpdateProgressMeter = createAction('[Progress Meter] Update', props<{ data: IProgressMeter }>());
export const ActivateStep = createAction('[Progress Meter] Activate Step', props<{ stepName: string }>());
export const setFinalStep = createAction('[Progress Meter] set Final', props<{ stepName: string }>());
export const setAllAsNotVisited = createAction('[Progress Meter] set setAllAsNotVisited');
export const resetProgressBar = createAction('[Progress Meter] Reset');

export const setFlowType = createAction('[Progress Meter] set flow type', props<{ flowType: string }>());
