import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ToggleVisibleDirective } from 'src/app/directives/toggle-visible.directive';
import { MaskedInputComponent } from './masked-input/masked-input.component';
import { UnMaskedInputComponent } from './unmasked-input/unmasked-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LabelModule } from './label/label.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { BaseFormControlComponent } from './base-form-control.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { AddressSuggestionComponent } from './address-suggestion/address-suggestion.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { YesNoComponent } from './yes-no/yes-no.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { CheckboxImageComponent } from './checkbox-image/checkbox-image.component';
import { ButtonComponent } from './button/button.component';
import { DirectivesModule } from '../../directives/directives.module';
import { ImageModule } from '../shared/image/image.module';
import { NumberStepperComponent } from './number-stepper/number-stepper.component';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { GenderDropdownComponent } from '../shared/gender-dropdown/gender-dropdown.component';

@NgModule({
	declarations: [
		DropdownComponent,
		RadioButtonComponent,
		CheckboxComponent,
		ToggleVisibleDirective,
		UnMaskedInputComponent,
		MaskedInputComponent,
		BaseFormControlComponent,
		AddressSuggestionComponent,
		YesNoComponent,
		DatepickerComponent,
		CheckboxImageComponent,
		ButtonComponent,
		NumberStepperComponent,
		GenderDropdownComponent,
	],
	imports: [
		CommonModule,
		NgSelectModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		LabelModule,
		MatDatepickerModule,
		ErrorMessageModule,
		FormsModule,
		ReactiveFormsModule,
		TextMaskModule,
		GooglePlaceModule,
		DirectivesModule,
		ImageModule,
		MatInputModule,
		TranslateModule,
	],
	exports: [
		DropdownComponent,
		RadioButtonComponent,
		CheckboxComponent,
		ToggleVisibleDirective,
		UnMaskedInputComponent,
		MaskedInputComponent,
		BaseFormControlComponent,
		AddressSuggestionComponent,
		YesNoComponent,
		DatepickerComponent,
		CheckboxImageComponent,
		ButtonComponent,
		NumberStepperComponent,
		GenderDropdownComponent,
	],
})
export class FormControlModule {}
