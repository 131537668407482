import { Injectable } from '@angular/core';
import { CoverageModel } from '../../../../../../models/coverage.model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RateService {

	public coveragesApplied = new Subject<string>();


	transformRates(selectedRates: CoverageModel[]): CoverageModel[] {
		return selectedRates.map((rate) => {
			let coverages = {};
			const policyLevelCoverages = (Object.values(rate.coverages)[0] as any).policyLevelCoverages;
			const entityCoverages = (Object.values(rate.coverages)[0] as any).entityCoverages;

			if (policyLevelCoverages && policyLevelCoverages.length) {
				coverages = {
					policyLevelCoverages,
				};
			}

			if (entityCoverages && Object.keys(entityCoverages).length) {
				coverages = {
					...coverages,
					entityCoverages,
				};
			}

			return {
				...rate,
				coverages,
			};
		});
	}

	editCoverages(value) {
		this.coveragesApplied.next(value);
	}
}
