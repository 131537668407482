import { AfterContentInit, AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { LoaderActions } from 'src/app/store/actions';
import { IAppState } from 'src/app/store/states/app.state';
import { Fields } from 'src/app/constants/fields';
import { RoofUpdatedYear } from 'src/app/constants/field-options';
import { BasePageComponent } from '../../base-page.component';
import { FormControl, FormGroup } from '@angular/forms';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { Validations } from 'src/app/utils/validation';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';

@Component({
	selector: 'app-year-built',
	templateUrl: './year-built.component.html',
	styleUrls: ['./year-built.component.scss'],
})
export class YearBuiltComponent extends BasePageComponent implements OnInit, AfterViewInit, AfterContentInit {
	originYear: string = null;
	yesNoYearBuilt: string = null;
	Fields = Fields;
	RoofUpdatedYear = RoofUpdatedYear;
	@ViewChild('nooption') el: ElementRef<HTMLInputElement>;

	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	get pageTitle(): string {
		return this.originYear
			? `Our records say this house was built in ${this.originYear}. Is that correct?`
			: 'What is your apartment built year?';
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();
		this.patchData(this.form);
	}

	initform(): void {
		this.form = new FormGroup({}, null, null);
		this.form.addControl(Fields.YearBuilt.name, new FormControl({ value: null, disabled: false }));
	}

	ngAfterContentInit(): void {
		this.getYearBuilt();
		this.baseNgAfterViewInit();
	}

	ngAfterViewInit(): void {
		//for showing error message when user types year with 4 letters length
		let currentYear = new Date().getFullYear();
		let PLYearBuiltElement = <HTMLInputElement>document.getElementById('PLYearBuilt');
		PLYearBuiltElement.addEventListener('input', function () {
			if (
				PLYearBuiltElement.value.length === 4 &&
				(!(Number(PLYearBuiltElement.value) < currentYear) || !(Number(PLYearBuiltElement.value) > 1800))
			) {
				PLYearBuiltElement.blur();
			}
		});
	}

	getYearBuilt() {
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((data) => {
				//get the current answer yes/no
				this.yesNoYearBuilt = data.yearBuiltYesNo;

				//check if there is origin built year - for the case the is origin year built - show the yes option
				if (data.originYearBuilt) {
					this.originYear = data.originYearBuilt;
					if (this.yesNoYearBuilt === 'no') {
						this.form.controls.PLYearBuilt.markAsPristine();
						this.form.controls.PLYearBuilt.markAsTouched();
						this.form.controls.PLYearBuilt.patchValue(this.form.controls.PLYearBuilt.value.toString());
						this.form.controls.PLYearBuilt.setValidators([
							Validations.mandatory,
							Validations.yearBuiltFuture,
							Validations.minNumber(1800),
						]);
						this.form.controls.PLYearBuilt.updateValueAndValidity();
					}
				} else {
					// for the case there is not origin year built at all - sets validators the PLYearBuilt control
					this.form.controls.PLYearBuilt.setValidators([
						Validations.mandatory,
						Validations.yearBuiltFuture,
						Validations.minNumber(1800),
					]);
					this.form.controls.PLYearBuilt.patchValue(this.form.controls.PLYearBuilt.value.toString());
					this.form.controls.PLYearBuilt.updateValueAndValidity();
				}
			});
	}

	handleChooseAnswer(value) {
		if (this.yesNoYearBuilt !== value) {
			if (value == 'no') {
				this.store.dispatch(LoaderActions.ShowLoader());
				this.form.controls.PLYearBuilt.setValue(null);
				this.form.controls.PLYearBuilt.reset();
				this.form.controls.PLYearBuilt.setValidators([
					Validations.mandatory,
					Validations.yearBuiltFuture,
					Validations.minNumber(1800),
				]);
				this.form.controls.PLYearBuilt.updateValueAndValidity();
				this.store.dispatch(UpdateInterviewMetadata({ data: { yearBuiltYesNo: 'no' } }));
				this.store.dispatch(LoaderActions.Hideloader());
			} else {
				this.store.dispatch(LoaderActions.ShowLoader());
				this.el.nativeElement.classList.add('disabled-option');
				setTimeout(() => {
					this.form.controls.PLYearBuilt.setValue(this.originYear);
					this.el.nativeElement.classList.remove('disabled-option');
				}, 300);
				this.form.controls.PLYearBuilt.setValidators(null);
				this.form.controls.PLYearBuilt.updateValueAndValidity();
				this.store.dispatch(LoaderActions.Hideloader());
				this.store.dispatch(UpdateInterviewMetadata({ data: { yearBuiltYesNo: 'yes' } }));
			}
			setTimeout(() => {
				this.yesNoYearBuilt = value;
			}, 1);
		}
	}

	ngOnDestroy(): void {
		this.store.dispatch(LoaderActions.Hideloader());
	}
}
