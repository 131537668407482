<div *ngIf="selectedRate" class="questions-container">
	<div class="top">
		<ng-container *ngFor="let rate of rates; let index = index">
			<div *ngIf="rate.carrier !== rates[index - 1]?.carrier" class="carrier-container">
				<img [attr.alt]="rate.carrier + ' logo'" [src]="getLogo(rate)" role="presentation" />
			</div>
		</ng-container>
	</div>

	<div class="quote-number">{{ "labels.quoteNumber" | translate }} #: {{ friendlyId }}</div>

	<div class="total-price">
		{{ price | zeroOrTwoDigitsFraction }}<span class="term"> / {{ displayTerm | translate | lowercase }}</span>
	</div>

	<h2 appTranslation="quoteConfirmation.covMainTitle" class="coverages-main-title"></h2>

	<app-coverages-list [selectedRate]="selectedRate"></app-coverages-list>

	<next-button titleKey="quoteConfirmation.button"></next-button>
</div>
