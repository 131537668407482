<section class="main-content">
	<app-page-heading [title]="'titles.carrierDisclosure' | translate"></app-page-heading>

	<ng-container *ngIf="form">
		<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper">
			<div class="questions-container">
				<app-question-wrapper>
					<app-checkbox
						[fieldObj]="Fields.InspectionNotification"
						[tabindex]="
							form.controls[Fields.InspectionNotification.name] &&
							form.controls[Fields.InspectionNotification.name].enabled
								? 0
								: -1
						"
						[valueChecked]="form.controls[Fields.InspectionNotification.name].value"
					>
					</app-checkbox>
				</app-question-wrapper>

				<next-button
					[loaderVisible]="true"
					[disabled]="form.invalid"
					[myForm]="myForm"
					[isFullQuote]="true"
					[title]="'buttons.continue' | translate"
					class="fullscreen"
					(validationFailed)="handleValidation($event)"
				></next-button>
			</div>
		</form>
	</ng-container>
</section>
