import { Injectable } from "@angular/core";
import mixpanel from 'mixpanel-browser';
import { IAppState } from "../store/states/app.state";
import { Store } from "@ngrx/store";
import { selectInterviewMetadata } from "../store/selectors/interview-metadata.selector";
import { ConfigService } from "./config.service";

export enum MixPanelEventActions {
	BUTTON_CLICK = 'click',
	END_SESSION = 'session_end',
	START_SESSION = 'session_start',
	PAGE_VIEW = 'pageview',
}

@Injectable({
	providedIn: 'root',
})
export class MixPanelService {
	mixPanel;
	applicationId;
	applicantId;
	session_id;
	constructor(private store: Store<IAppState>, private configService: ConfigService) { }

	initService(mixPanelKey) {
		if (!mixPanelKey) {
			return;
		}
		this.store.select(selectInterviewMetadata).subscribe((interviewMetadata) => {
			this.applicationId = interviewMetadata.applicationId;
			this.applicantId = interviewMetadata.applicantId;
		});

		this.mixPanel = mixpanel;
		let mixpanel_proxy_url = this.configService.getConfig().mixpanel_proxy_url;
		if (mixpanel_proxy_url) {
			this.mixPanel.init(mixPanelKey, { api_host: this.configService.getConfig().mixpanel_proxy_url });
		} else {
			this.mixPanel.init(mixPanelKey);
		}

		const queryParams: any = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(String(prop)),
		});

		const utm_medium = queryParams.utm_medium;
		const utm_content = queryParams.utm_content;
		if (utm_medium && utm_content && utm_medium === 'session_id') {
			this.session_id = utm_content;
		}
		let data = { session_id: this.session_id };
		this.applicationId && (data['application_id'] = this.applicationId);
		this.applicantId && (data['applicant_id'] = this.applicantId);
		this.mixPanel.track('session_start', data);
	}

	fireEvent(eventType: string, eventData: any) {
		if (!this.mixPanel) {
			return;
		}

		if (!(eventType in MixPanelEventActions)) {
			return (`Invalid Mixpanel event type: ${eventType}`);
		}

		// to add new events need to add in MixPanelEventActions enum
		if (eventType in MixPanelEventActions) {
			let data = { ...eventData, session_id: this.session_id };
			this.applicationId && (data['application_id'] = this.applicationId);
			this.applicantId && (data['applicant_id'] = this.applicantId);
			console.info('MixPanel Event:', MixPanelEventActions[eventType], data);
			this.mixPanel.track(MixPanelEventActions[eventType], data);
		}
	}
}
