import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	
	constructor(private translateService: TranslateService) {}

	getLabelsByState(key, state): Observable<any> {
		return this.translateService
			.get(key + '.' + state)
			.pipe(map((res: string) => (res !== key + '.' + state ? res : null)));
	}

	getBullets(key) : Observable<any> {
		return this.translateService.onLangChange.pipe(
			startWith({}), 
			switchMap(() => this.translateService.get('bullets' + '.' + key))
		  );
	  }
}
