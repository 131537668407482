import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { selectInterviewMetadata } from '../store/selectors/interview-metadata.selector';
import { IInterviewMetadataState } from '../store/states/interview-metadata.state';
import { ConfigService } from './config.service';

declare var dataLayer;

// add new events in this enum
export enum GTMEventActions {
	BUTTON_CLICK = 'ButtonClick',
	LINK_CLICK = 'LinkClick',
	DISPLAY = 'Display',
	EDIT = 'EditAction',
	PREFILL = 'PreFill',
	SYS = 'SysEvent',
	RADIO_BUTTON = 'RadioButton',
	INPUT_FOCUS = 'TextBoxFocus',
	DROPDOWN_CHANGE = 'DropDownChange',
	BOX_CHECK = 'BoxCheck',
}

@Injectable({
	providedIn: 'root',
})
export class GtmService {
	displayList: Array<string>;
	prefillList: Array<string>;
	routeDisplayList: Array<string>;
	key: any;
	interviewMetadata: IInterviewMetadataState;

	constructor(private store: Store, private configService: ConfigService) {}

	initService() {
		this.init(window, document, 'script', 'dataLayer', this.configService.config.tagManagerId);
		this.store.select(selectInterviewMetadata).subscribe((d) => {
			this.interviewMetadata = d;
		});
	}

	init(w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js',
		});

		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);

		j.async = true;
		j.src = `//www.googletagmanager.com/gtm.js?id=${i}${l !== 'dataLayer' ? '&l=' + l : ''}`;

		f.parentNode.insertBefore(j, f);
	}

	fireEvent(eventType: string, eventData: any) {
		if (!(eventType in GTMEventActions)) {
			return `Invalid GTM event type: ${eventType}`;
		}
		eventType = GTMEventActions[eventType];
		if (window['dataLayer']) {
			const gaObj: any = {
				event: eventType,
				eventCategory: this.interviewMetadata.lobSelection[0],
				eventAction: eventType,
				eventLabel: eventData.label.replace('[]', ''),
				route: window.location.pathname,
				dataValue: eventData.value || '',
			};
			dataLayer.push(gaObj);
		}
	}
}
