import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GtmControlsActions } from '../../models/gtm-controls-actions.enum';
import * as moment from 'moment';
import { EventsService } from 'src/app/services/events.service';
import { EventActions } from 'src/app/models/event-data.model';

@UntilDestroy()
@Component({
	selector: 'error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit, OnChanges {
	@Input() field: FormControl;
	@Input() fieldDisplayName: string;
	@Input() describedByErrorId: string;
	@Input() showOnInit: boolean;
	@Input() isFocus$: Subject<boolean>;
	@Input() wasOnFocus: boolean;
	@Input() showIconError: boolean;

	isFocus: boolean;
	isEventSent: boolean;
	sendQuickError: boolean;
	hasBeenFocused: boolean;
	isFocusObs: Subscription;
	moment = moment;

	constructor(private eventsService: EventsService, private store: Store) {
		if (!this.isFocus$) {
			this.isFocus$ = new Subject<boolean>();
		}
	}

	ngOnInit(): void {
		this.isFocus$.pipe(untilDestroyed(this)).subscribe((isFocus) => {
			this.isFocus = isFocus;
			if (isFocus) {
				this.hasBeenFocused = true;
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if ((changes.isFocus && changes.isFocus.currentValue) || (changes.wasOnFocus && changes.wasOnFocus.currentValue)) {
			this.hasBeenFocused = true;
		}
	}

	checkField(): boolean {
		if (!this.isEventSent && this.sendQuickError && this.hasBeenFocused) {

			this.eventsService.addEvent(EventActions.SYS, { label: `${this.fieldDisplayName}_${GtmControlsActions.ERROR}` })
			this.isEventSent = true;
		}

		return true;
	}
}
