import { Injectable } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { ActivatedRoute, Router } from '../../../node_modules/@angular/router';
import { SetLanguage } from '../store/actions/languages.actions';
import { IAppState } from '../store/states/app.state';
import { selectLanguageSettings } from '../store/selectors/languages.selector';
import { BehaviorSubject, map } from 'rxjs';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	static currentLanguage = new BehaviorSubject('');

	constructor(private store: Store<IAppState>, private router: Router, private route: ActivatedRoute) {
		this.store
			.select(selectLanguageSettings)
			.pipe(map((settings) => settings.selected.toUpperCase()))
			.subscribe(LanguageService.currentLanguage);
	}

	initLanguage(): void {
		this.route.queryParams.subscribe((params) => {
			if (params.lang) {
				this.store.dispatch(SetLanguage({ selected: params.lang }));

				this.router.navigate([], {
					queryParams: {
						token: null,
					},
					queryParamsHandling: 'merge',
				});
			}
		});
	}
}
