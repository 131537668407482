<app-label
	*ngIf="label"
	[label]="label"
	[name]="this.name"
	[popupLink]="popupLink"
	[subLabel]="subLabel"
	[tabindex]="tabindex"
>
</app-label>

<div
	[ngClass]="{
		error: error,
		searchable: searchable,
		multiple,
		'with-inner-label': labelInsideField,
		disabled: disabled,
		focus: (isFocus$ | async),
		'has-value': value,
		isMobile: isMobile
	}"
	class="dropdown-container"
>
	<div [ngClass]="{ notShowLabel: value !== null && notShowLabelWhenSelected }">
		<label
			[for]="namePrefix + name"
			[ngClass]="{
				focus: (isFocus$ | async),
				notSelected: value === null && !placeholder,
				'has-value': value !== null,
				notShowLabelWhenSelected : notShowLabelWhenSelected
			}"
			class="inner-label"
		>
			{{ labelInsideField }}
		</label>

		<ng-select
			(blur)="onBlur()"
			(change)="updateAnswer($event)"
			(close)="onClose()"
			(focus)="onFocus()"
			(open)="onOpen()"
			[(ngModel)]="value"
			[attr.aria-invalid]="isInvalid() ? true : null"
			[attr.name]="name"
			[clearable]="false"
			[disabled]="disabled || tabindex == -1"
			[dropdownPosition]="dropdownPosition"
			[labelForId]="namePrefix + name"
			[multiple]="multiple"
			[ngClass]="{ error: isInvalid(), 'force-expand': forceExpand }"
			[placeholder]="placeholder"
			[searchable]="searchable"
		>
			<ng-option *ngFor="let option of options" [value]="option.value">
				<ng-container *ngIf="option.labelKey; else withoutLabelKey">
					{{ "dropdowns." + option.labelKey | translate }}
				</ng-container>

				<ng-template #withoutLabelKey>
					{{ option.title }}
				</ng-template>
			</ng-option>
		</ng-select>
	</div>
	<error-message
		*ngIf="!withoutErrorMessage"
		[describedByErrorId]="describedByErrorId"
		[fieldDisplayName]="postfix || name"
		[field]="controlContainer.control.controls[this.name]"
		[isFocus$]="isFocus$"
	></error-message>
</div>

<form [formGroup]="controlContainer.control">
	<input (ngModelChange)="setValue()" [attr.name]="name" [formControlName]="name" type="hidden" />
</form>
