export enum SectionTypesEnum {
	sectionMain = 'sectionMain',
	statistic = 'statistic',
	plainText = 'plainText',
	carriers = 'carriers',
	howItWorks = 'howItWorks',
	video = 'video',
	testimonials = 'testimonials',
	faq = 'faq',
	wwa = 'wwa',
	footer = 'footer',
}
