import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { Fields } from 'src/app/constants/fields';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { first } from 'rxjs';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
import { BasePageComponent } from 'src/app/components/layout/pages/base-page.component';
import { combineLatest } from 'rxjs';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';

@Component({
	selector: 'app-progressive-snapshot',
	templateUrl: './progressive-snapshot.component.html',
	styleUrls: ['./progressive-snapshot.component.scss'],
})
export class ProgressiveSnapshotComponent extends BasePageComponent implements OnInit, AfterViewInit {
	ProgressivePersonalAutoUBIExperienceEnrollment;
	Fields = Fields;
	subtitleSnapshot= 'In some  <a href="https://www.progressive.com/auto/discounts/snapshot/snapshot-details/" target="_blank">states</a>, at new business, customers will save 15% on the first term by participating in Snapshot';

	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();

		combineLatest([this.store.select(selectInterviewMetadata), this.store.select(selectQuoteData)])
			.pipe(first())
			.subscribe(([interviewMetadata, quoteData]) => {
				if (interviewMetadata.isRetrieved || interviewMetadata.isProgressiveSnapshot) {
					this.patchData(this.form);
					if (quoteData['FQData.ProgressivePersonalAuto.UBIExperienceEnrollment'] == 'DNP') {
						this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].setValue(undefined);
						this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].setValidators(null);
						this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].updateValueAndValidity();
					}
				}
			});

		this.registerOnChange(this.form);
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	initform(): void {
		this.form = new FormGroup({}, null, null);
		this.form.addControl(
			Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);
		this.form.addControl(
			Fields.ProgressivePersonalAutoUBIAcknowledgement.name,
			new FormControl({ value: null, disabled: false }, [Validators.requiredTrue])
		);
	}

	handleSelect(event) {
		this.store.dispatch(UpdateInterviewMetadata({ data: { isProgressiveSnapshot: true } }));
		if (event !== this.form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value) {
			setTimeout(() => {
				this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].setValue(false);
			}, 0);
			this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].markAsUntouched();
			this.form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].setValue(event);
			this.ProgressivePersonalAutoUBIExperienceEnrollment = event;
		}

		if (event === 'DNP') {
			setTimeout(() => {
				this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].setValue(undefined);
			}, 300);
			this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].setValidators(null);
			this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].updateValueAndValidity();
		} else {
			this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].setValidators([
				Validators.requiredTrue,
			]);
			this.form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].updateValueAndValidity();
		}
	}
}
