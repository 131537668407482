import { Component, OnInit } from '@angular/core';
import { getSelectedRate, selectFriendly } from 'src/app/store/selectors/result-data.selectors';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventActions } from '../../../../models/event-data.model';
import { isMobile } from 'src/app/utils/general.utils';
import { selectInterviewTexts } from '../../../../store/selectors/interview-texts.selectors';
import { map } from 'rxjs';
import { selectAgentPhoneNumber } from '../../../../store/selectors/active-theme.selector';

@UntilDestroy()
@Component({
	selector: 'app-contact-agent',
	templateUrl: './contact-agent.component.html',
	styleUrls: ['./contact-agent.component.scss'],
})
export class ContactAgentComponent implements OnInit {
	data;
	selectFriendly;
	phoneNumber: string;
	title: string;
	eventActions = EventActions;
	isMobile;

	constructor(private store: Store) {
		this.isMobile = isMobile();
	}

	get price(): number {
		return this.data.isMonthly
			? this.data.rate.term == 'SixMonths'
				? this.data.rate.premium / 6
				: this.data.rate.premium / 12
			: this.data.rate.premium;
	}

	ngOnInit(): void {
		if (!this.data || !this.data.rate) {
			this.store.select(getSelectedRate).subscribe((data) => {
				this.data = { rate: data };
			});
		}

		this.store.select(selectFriendly).subscribe((data) => {
			this.selectFriendly = data;
		});

		this.store
			.select(selectAgentPhoneNumber)
			.pipe(untilDestroyed(this))
			.subscribe((phoneNumber) => {
				this.phoneNumber = phoneNumber;
			});

		this.store
			.select(selectInterviewTexts)
			.pipe(
				untilDestroyed(this),
				map((popupTexts) => popupTexts.contactAgentPopup)
			)
			.subscribe((popup) => {
				this.title = popup.title;
			});
	}
}
