<app-label
	*ngIf="label"
	[label]="label"
	[name]="this.name"
	[popupLink]="popupLink"
	[subLabel]="subLabel"
	[tabindex]="tabindex"
>
</app-label>

<div
	[ngClass]="{
		'has-value': (value !== null && value !== undefined) || (isFocus$ | async),
		'with-placeholder': placeholder,
		'with-inner-label': labelInsideField,
		'with-currency-sign': followByDollar && value,
		'special-sign-watermark': specialSignWatermark,
		focus: (isFocus$ | async),
		error,
		disabled
	}"
	class="input-container"
	data-currency="$"
>
	<label [for]="name" class="inner-label" *ngIf="!notshowLabelWhenFilled">{{ placeholder }}</label>

	<ng-template #labelInsideFieldTemplate>
		<label *ngIf="labelInsideField" [for]="name" class="inner-label label-inside-field">{{ labelInsideField }}</label>
	</ng-template>

	<i
		*ngIf="isCurrency && (value || (isFocus$ | async))"
		[ngClass]="{
			'has-value': value,
			notshowLabelWhenFilled: notshowLabelWhenFilled
		}"
		class="currency-symbol"
		>$</i
	>

	<input
		(blur)="onBlur($event)"
		(focus)="onFocus()"
		(keyup)="onKeyUp($event)"
		[(ngModel)]="value"
		[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
		[attr.aria-invalid]="isInvalid() ? true : null"
		[attr.id]="name"
		[attr.name]="name"
		[autocomplete]="autocomplete ? 'off' : 'nope'"
		[disabled]="disabled"
		[maxlength]="maxlength"
		[minlength]="minlength"
		[ngClass]="{
			currency: isCurrency,
			error: isInvalid(),
			focus: (isFocus$ | async),
			'has-value': value,
			disabled: disabled,
			notshowLabelWhenFilled: notshowLabelWhenFilled
		}"
		[type]="type || 'text'"
		class="unmasked-input"
	/>
	<div *ngIf="error" class="error-container">
		<div class="error">
			<i class="error-icon"></i>
		</div>
	</div>
</div>

<form [formGroup]="controlContainer.control">
	<input (ngModelChange)="setValue()" [attr.name]="name" [formControlName]="name" type="hidden" />
</form>

<error-message
	*ngIf="!withoutErrorMessage"
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || name"
	[field]="controlContainer.control.controls[this.name]"
	[isFocus$]="isFocus$"
	showIconError="true"
>
</error-message>
