<section class="main-content bottom-space">
	<ng-container *ngIf="showPreBindLoader">
		<app-loader></app-loader>
		<app-page-heading [title]="'titles.oneMoment' | translate"></app-page-heading>
	</ng-container>
	<ng-container *ngIf="!showPreBindLoader">
		<app-page-heading [title]="'titles.enterAdditionalDriverInformation' | translate"></app-page-heading>

		<form #myform="ngForm" [formGroup]="form" class="stage">
			<div class="questions-container">
				<div [ngStyle]="{ width: ((activeTabIndex + 1) * 100) / drivers.length + '%' }" class="progress-bar"></div>
				<ng-container *ngIf="drivers.length > 0">
					<form *ngFor="let driver of drivers; let index = index" [formGroup]="getDriverHistoryForm(index)">
						<ng-container *ngIf="isTabActive(index)">
							<div class="driver-header">
								<app-image [name]="'driverActiveIcon'" alt="null" height="32" role="presentation"></app-image>
								<p>
									{{
										getDriverHistoryForm(index).value["FirstName"] + " " + getDriverHistoryForm(index).value["LastName"]
											| uppercase
									}}
								</p>
							</div>

							<app-question-wrapper
								[show]="
									getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name) &&
									getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name).enabled
								"
							>
								<unmasked-input
									*ngIf="
										getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name) &&
										getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name).enabled
									"
									[name]="Fields.DriverLicenseNumber.name"
									[pattern]="Fields.DriverLicenseNumber.pattern"
									[placeholder]="'labels.driverLicenseNumber' | translate"
									maxlength="16"
									type="text"
								>
								</unmasked-input>
							</app-question-wrapper>

							<app-question-wrapper
								[show]="
									getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name) &&
									getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name).enabled
								"
								class="gender"
							>
								<app-dropdown
									[labelInsideField]="'labels.licenseState' | translate"
									[name]="Fields.DriverStateLicensed.name"
									[options]="FieldOptions.DriverStateLicensed"
									[reAlphabeticalOrder]="false"
									[tabindex]="
										getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name) &&
										getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name).enabled
									"
									searchable="true"
								></app-dropdown>
							</app-question-wrapper>
							<app-question-wrapper
								*ngIf="
									getDriverHistoryForm(index).get(Fields.DriverLicenseStatus.name).value &&
									getDriverHistoryForm(index).get(Fields.DriverLicenseStatus.name).value !== 'NotLicensed'
								"
								[show]="
									getDriverHistoryForm(index).get(Fields.DriverDateLicensed.name) &&
									getDriverHistoryForm(index).get(Fields.DriverDateLicensed.name).enabled
								"
								class="date-of-birth"
							>
								<masked-input
									[inputMask]="'**/**/9999'"
									[name]="Fields.DriverDateLicensed.name"
									[placeholder]="'personalInfo.driverDateLicensed' | translate"
									class="date-input"
								>
								</masked-input>
							</app-question-wrapper>

							<app-question-wrapper
								[show]="
									getDriverHistoryForm(index).get(Fields.DriversLicenseBeenSuspendedOrRevokedSafeco.name) &&
									getDriverHistoryForm(index).get(Fields.DriversLicenseBeenSuspendedOrRevokedSafeco.name).enabled
								"
							>
								<app-dropdown
									[labelInsideField]="'labels.driversLicenseBeenSuspendedOrRevokedSafeco' | translate"
									[name]="Fields.DriversLicenseBeenSuspendedOrRevokedSafeco.name"
									[options]="FieldOptions.YesNoOptions"
									[tabindex]="getDriverHistoryForm(index).get(Fields.DriversLicenseBeenSuspendedOrRevokedSafeco.name)"
								></app-dropdown>
							</app-question-wrapper>

							<app-question-wrapper [show]="getDriverHistoryForm(index).get(Fields.SR22OrFinancialResponsibility.name)">
								<app-dropdown
									[labelInsideField]="'labels.sr22OrFinancialResponsibility' | translate"
									[name]="Fields.SR22OrFinancialResponsibility.name"
									[options]="FieldOptions.YesNoOptions"
									[reAlphabeticalOrder]="false"
									[tabindex]="getDriverHistoryForm(index).get(Fields.SR22OrFinancialResponsibility.name)"
								></app-dropdown>
							</app-question-wrapper>
						</ng-container>
					</form>
				</ng-container>
				<div class="divider"></div>
				<div class="next-button-wrapper">
					<app-button
						(clicked)="activateNextTab()"
						*ngIf="!this.isLastTabActive()"
						[disabled]="getDriverHistoryForm(activeTabIndex) && !getDriverHistoryForm(activeTabIndex).valid"
						[title]="'buttons.continue' | translate"
						materialIcon="chevron_right"
						severity="primary"
					></app-button>
					<next-button
						(clicked)="handleContinue()"
						*ngIf="this.isLastTabActive()"
						[continue]="true"
						[disabled]="!form.valid"
						[myForm]="myform"
						[title]="'buttons.continue' | translate"
						isFullQuote="true"
					></next-button>
					<span [ngClass]="{ show: isLoading }" class="loader show"></span>
					<button (click)="goBack()" class="go-back">{{ "buttons.goBack" | translate }}</button>
				</div>
			</div>
		</form>
	</ng-container>
</section>
