import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { hidePopupAction } from '../../../../store/actions/popup.actions';
import { Store } from '@ngrx/store';
import {
	AddManualVehicle,
	UpdateDiscoverVehicle,
	UpdateManualVehicle,
	UpdatePersonalVehicle,
} from '../../../../store/actions/quote-data.actions';
import { v4 as uuidv4 } from 'uuid';
import { ActionApiService } from '../../../../services/action-api.service';
import { first, pairwise, startWith } from 'rxjs/operators';
import { IDropdownOption } from '../../../form-controls/form-control-interfaces';
import { Fields } from 'src/app/constants/fields';
import { Validations } from 'src/app/utils/validation';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { addVehiclesOpened } from 'src/app/store/actions/interview-metadata.actions';
import * as FieldOptions from '../../../../constants/field-options';
import { OwnershipTypeOptions } from 'src/app/constants/vehicle-options.data';
import { EventsService } from 'src/app/services/events.service';
import { EventActions } from 'src/app/models/event-data.model';

const MAX_YEARS_FROM_NOW = 20;

@UntilDestroy()
@Component({
	selector: 'app-add-edit-vehicle',
	templateUrl: './add-edit-vehicle.component.html',
	styleUrls: ['./add-edit-vehicle.component.scss'],
})
export class AddEditVehicleComponent implements OnInit {
	@Input() data: any;
	@Input() isNoVehicleFound: any;
	EventActions = EventActions;
	FieldOptions = FieldOptions;
	form: FormGroup;
	yearOptions = [];
	isEdit = false;
	makeOptions: IDropdownOption[] = [];
	modelOptions: IDropdownOption[] = [];
	bodyStyleOptions: any[] = [];
	Fields = Fields;
	mode: string = 'Both';
	OwnershipTypeOptions = OwnershipTypeOptions;
	invalidVinError = false;

	constructor(private store: Store, private actionService: ActionApiService, private eventsService: EventsService) { }

	ngOnInit(): void {
		this.isEdit = !!this.data.Id;

		this.form = new FormGroup({
			PLYear: new FormControl(null, [Validators.required]),
			PLMake: new FormControl(null, [Validators.required]),
			PLModel: new FormControl(null, [Validators.required]),
			BodyStyle: new FormControl(null, [Validators.required]),
			NumberOfMiles: new FormControl(null, [
				Validators.required,
				Validations.mileageMoreThan1000,
				Validations.mileageLessThan100k,
			]),
			ExternalVINPrefix: new FormControl(null),
			OwnershipType: new FormControl(null, [Validators.required]),
		});

		this.store.select(selectQuoteData).subscribe((res) => {
			let allVehicles = [];
			res.discoverVehicles ? allVehicles.push(res.discoverVehicles) : null;
			res.manualVehicles ? allVehicles.push(res.manualVehicles) : null;
			res.PersonalVehicles ? allVehicles.push(res.PersonalVehicles) : null;
			this.form.addControl(
				'VIN',
				new FormControl(null, [Validations.ValidateVINpopup(this.data.Id, [].concat(...Object.values(allVehicles)))])
			);
		});

		// need to check if it has no VIN so it has ExternalVINPrefix
		if (this.isEdit) {
			this.form.patchValue(this.data.value);
			if (this.data.value.VIN) {
				this.setVINMode('VIN');
			}

			setTimeout(() => {
				this.data.formErrors.forEach((formErrorsEntry) => {
					if (formErrorsEntry.errors && this.form.controls[formErrorsEntry.name]) {
						this.form.controls[formErrorsEntry.name].setErrors(formErrorsEntry.errors);
						this.form.controls[formErrorsEntry.name].markAsTouched();
					}
				});
			});
		}

		setTimeout(() => {
			this.form.controls.VIN.addAsyncValidators([Validations.validateVINAsync(this.actionService)]);
		}, 0);

		this.checkNextYear();

		if (this.isEdit) {
			this.setMakeOptions();
			this.setModelOptions();
			this.setBodyStyleOptions();
		}

		this.form.controls.PLYear.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
			this.checkMode();
			this.form.controls.PLMake.reset();
			if (this.mode !== 'VIN') {
				if (this.form.value.PLYear) {
					this.setMakeOptions();
				}
			}
		});

		this.form.controls.PLMake.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
			this.checkMode();
			this.form.controls.PLModel.reset();
			if (this.mode !== 'VIN') {
				if (this.form.value.PLMake) {
					this.setModelOptions();
				}
			}
		});

		this.form.controls.PLModel.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
			this.checkMode();
			this.form.controls.BodyStyle.reset();
			if (this.mode !== 'VIN') {
				if (this.form.value.PLModel) {
					this.setBodyStyleOptions();
				}
			}
		});

		this.form.controls.BodyStyle.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value) {
				if (this.mode == 'Both') {
					this.setVINMode('notVIN');
				}
				if (this.mode == 'notVIN') {
					this.form.controls.ExternalVINPrefix.setValue(
						this.bodyStyleOptions.find((option) => option.value === value).vin
					);
				}
			}
		});
		this.form.controls.VIN.valueChanges
			.pipe(untilDestroyed(this), startWith(undefined), pairwise())
			.subscribe((value) => {
				if (value[1] !== value[0]) {
					if (value[1] !== null && this.mode !== 'notVIN') {
						if (value[1] && value[1].length > 0) {
							this.setVINMode('VIN');
							if (value[1].length == 17) {
								this.actionService
									.decodeVin(value[1])
									.pipe(first())
									.subscribe((res) => {
										if (res['message']) {
											this.form.controls.VIN.setErrors({ vinnotvalid: true });
											this.invalidVinError = true;
											this.resetAllFields();
										} else {
											this.invalidVinError = false;
											this.form.controls.VIN.setErrors({ vinnotvalid: null });
											this.form.controls.VIN.updateValueAndValidity();
											this.form.controls.PLYear.setValue(res.year);
											this.form.controls.PLMake.setValue(res.make);
											this.form.controls.PLModel.setValue(res.model);
											this.form.controls.BodyStyle.setValue(res.bodyStyle);
										}
									});
							} else {
								this.resetAllFields();
								this.makeOptions = [];
								this.modelOptions = [];
								this.bodyStyleOptions = [];
							}
						} else {
							this.resetAllFields();
							this.makeOptions = [];
							this.modelOptions = [];
							this.bodyStyleOptions = [];
							this.setVINMode('Both');
						}
					}
				} else {
					this.invalidVinError ? this.form.controls.VIN.setErrors({ vinnotvalid: true }) : null;
				}
			});
		this.store.dispatch(addVehiclesOpened({ Id: this.data.Id }));
	}

	checkNextYear() {
		this.actionService
			.getMakePerYear(new Date().getFullYear() + 1)
			.pipe(first())
			.subscribe((makeOptions: Array<string>) => {
				makeOptions.length > 0 ? this.setYearOptions(new Date().getFullYear() + 1) : this.setYearOptions(null);
			});
	}

	setYearOptions(nextYear) {
		const currentYear = new Date().getFullYear();
		if (nextYear) {
			this.yearOptions.push({
				value: nextYear,
				title: nextYear.toString(10),
			});
		}
		for (let i = currentYear; i >= currentYear - MAX_YEARS_FROM_NOW; i--) {
			this.yearOptions.push({
				value: i,
				title: i.toString(10),
			});
		}
	}

	setMakeOptions() {
		this.actionService
			.getMakePerYear(this.form.value.PLYear)
			.pipe(first())
			.subscribe((makeOptions: Array<string>) => {
				if (Array.isArray(makeOptions)) {
					this.makeOptions = makeOptions.map((option, index) => ({
						id: index,
						value: option,
						title: option,
					}));
				} else {
					this.makeOptions = [];
				}
			});
	}

	setModelOptions() {
		this.actionService
			.getModelPerMakeYear(this.form.value.PLMake, this.form.value.PLYear)
			.pipe(first())
			.subscribe((modelOptions: Array<string>) => {
				if (Array.isArray(modelOptions)) {
					this.modelOptions = modelOptions.map((option, index) => ({
						id: index,
						value: option,
						title: option,
					}));
				} else {
					this.modelOptions = [];
				}
			});
	}

	setBodyStyleOptions() {
		this.actionService
			.getBodyStylePerModelPerMakeYear(this.form.value.PLModel, this.form.value.PLMake, this.form.value.PLYear)
			.pipe(first())
			.subscribe((vinOptions: Array<{ bodyStyle: string; vin: string }>) => {
				if (Array.isArray(vinOptions)) {
					this.bodyStyleOptions = vinOptions
						.filter((value, index, self) => index === self.findIndex((t) => t.bodyStyle === value.bodyStyle))
						.map((option, index) => ({
							id: index,
							vin: option.vin,
							value: option.bodyStyle,
							title: option.bodyStyle,
						}));
				} else {
					this.bodyStyleOptions = [];
				}
			});
	}

	cancel() {
		this.store.dispatch(hidePopupAction());
	}

	addVehicle() {
		//check if the value on input VIN is VIN PREFIX,
		// If it is, so deletes the VIN field and put the value on VIN Prefix.
		if (this.isEdit) {
			let vehicleData = { data: { Id: this.data.Id, ...this.form.value } };
			this.form.value.ExternalVINPrefix && !this.form.value.VIN ? (vehicleData.data.VIN = null) : null;
			if (this.data.value.type == 'PersonalVehicles') {
				this.store.dispatch(UpdatePersonalVehicle(vehicleData));
			} else if (this.data.value.type == 'discoverVehicles') {
				this.store.dispatch(UpdateDiscoverVehicle(vehicleData));
			} else {
				this.store.dispatch(UpdateManualVehicle(vehicleData));
			}
			this.store.dispatch(hidePopupAction());
			this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'edit-another-car', button: 'edit-another-car', module: 'vehicles-module' });
		} else {
			const Id = uuidv4();
			const item = {
				Id: Id,
				SequenceNum: this.data.SequenceNum++,
				DateVehiclePurchased: '',
				PrimaryUseOfVehicle: '',
				MilesToWork: '',
				newListing: true,
				enabled: true,
				...this.form.value,
			};
			this.store.dispatch(AddManualVehicle({ value: item }));
			this.store.dispatch(addVehiclesOpened({ Id: Id }));
			this.store.dispatch(hidePopupAction());
			this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'add-another-car', button: 'add-another-car', module: 'vehicles-module' });

		}
	}

	getAddButtonText() {
		if (this.isNoVehicleFound) {
			return 'Continue';
		} else {
			return this.isEdit ? 'buttons.save' : 'buttons.addCar';
		}
	}

	getAddButtonIcon() {
		return this.isNoVehicleFound ? 'chevron_right' : 'check';
	}

	checkMode() {
		//if the mode is both and some value which is not VIN to annual mileage has changed,
		// need to change mode to 'not VIN'
		if (this.mode == 'Both') {
			this.setVINMode('notVIN');
		}
	}

	setVINMode(mode: 'VIN' | 'notVIN' | 'Both') {
		this.mode = mode;
		if (mode === 'notVIN') {
			this.form.controls[Fields.VIN.name].disable();
		} else {
			this.form.controls[Fields.VIN.name].enable();
		}
	}

	resetAllFields() {
		this.form.controls.PLYear.reset();
		this.form.controls.PLMake.reset();
		this.form.controls.PLModel.reset();
		this.form.controls.BodyStyle.reset();
	}
}
