import { Directive, HostListener, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';


@Directive({
    selector: '[matTooltip][appToggleTooltip]'
  })
  export class ToggleTooltipDirective {
    constructor(private tooltip: MatTooltip) {}
  
    @HostListener('click')
    onClick() {
      this.tooltip.toggle();
    }
  }