<section class="main-content main-content-personal-details">
	<app-page-heading
		[subtitle]="isMultiLob ? ('titles.policyOwnerAdditionalHouseholdDrivers' | translate) : ''"
		[title]="'titles.policyOwnerName' | translate"
	></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div [ngClass]="{ 'auto-flow': isDriverStatusEnable() }" class="questions-container">
			<b class="basic-text">{{ "titles.basicInformation" | translate }}</b>

			<app-question-wrapper
				[show]="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
				class="first-name"
				icon="person"
			>
				<unmasked-input
					*ngIf="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
					[name]="Fields.FirstName.name"
					[pattern]="Fields.FirstName.pattern"
					[placeholder]="'personalInfo.firstName' | translate"
					maxlength="50"
					type="text"
				>
				</unmasked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled"
				class="last-name"
				icon="person"
			>
				<unmasked-input
					[name]="Fields.LastName.name"
					[pattern]="Fields.LastName.pattern"
					[placeholder]="'personalInfo.lastName' | translate"
					maxlength="30"
					type="text"
				></unmasked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.DateOfBirth.name) && form.get(Fields.DateOfBirth.name).enabled"
				class="date-of-birth"
				icon="calendar_today"
			>
				<masked-input
					[inputMask]="'**/**/9999'"
					[name]="Fields.DateOfBirth.name"
					[placeholder]="'personalInfo.dateOfBirth' | translate"
					class="date-input"
				>
				</masked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled"
				class="maritial_status"
				icon="family_restroom"
			>
				<app-dropdown
					[labelInsideField]="'personalInfo.maritalStatus' | translate"
					[name]="Fields.MaritalStatus.name"
					[options]="FieldOptions.maritalStatusOptions"
					[reAlphabeticalOrder]="false"
					[tabindex]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled ? 0 : -1"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.PersonalLineGender.name) && form.get(Fields.PersonalLineGender.name).enabled"
				class="gender"
				icon="person"
				optionalKeyText="labels.moreInfo"
				tooltipKeyText="tooltips.chooseOption"
			>
				<app-gender-dropdown
					[name]="Fields.PersonalLineGender.name"
					[tabindex]="
						form.get(Fields.PersonalLineGender.name) && form.get(Fields.PersonalLineGender.name).enabled ? 0 : -1
					"
				></app-gender-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.get(Fields.DriverLicenseStatus.name) && isMultiLob"
				[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				class="driver_license_status"
				icon="person"
			>
				<app-dropdown
					[labelInsideField]="'personalInfo.driverLicenseStatus' | translate"
					[name]="Fields.DriverLicenseStatus.name"
					[options]="FieldOptions.DriverLicenseStatus"
					[reAlphabeticalOrder]="false"
					[tabindex]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				></app-dropdown>
			</app-question-wrapper>

			<b class="contact-text">{{ "labels.contactInformation" | translate }}</b>

			<app-question-wrapper
				[show]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled"
				class="email"
				icon="email"
			>
				<unmasked-input
					[name]="Fields.Email.name"
					[pattern]="Fields.Email.pattern"
					[placeholder]="'personalInfo.email' | translate"
					[subLabel]="'labels.emailQuoteAlert' | translate"
					[tabindex]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled ? 0 : -1"
					type="email"
				>
				</unmasked-input>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.PrimaryPhoneNumber.name) && form.get(Fields.PrimaryPhoneNumber.name).enabled"
				class="phoneNumber"
				icon="call"
			>
				<masked-input
					[inputMask]="phoneNumberMask"
					[name]="Fields.PrimaryPhoneNumber.name"
					[placeholder]="'personalInfo.phone' | translate"
					[tabindex]="
						form.get(Fields.PrimaryPhoneNumber.name) && form.get(Fields.PrimaryPhoneNumber.name).enabled ? 0 : -1
					"
					[topPlaceholder]="true"
					type="tel"
				>
				</masked-input>
			</app-question-wrapper>
			<div class="upsell-box-wrapper">
				<app-upsell-box
					(upsellToggled)="onUpsellToggled($event)"
					[currentLob]="LobsEnum.PERSONAL_HOME"
					[upsellLob]="upsellLob"
				></app-upsell-box>
			</div>

			<app-question-wrapper
				*ngIf="form.get(Fields.DriverLicenseStatus.name) && upsellSelected"
				[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				class="driver_license_status"
				icon="person"
			>
				<app-dropdown
					[labelInsideField]="'personalInfo.driverLicenseStatus' | translate"
					[name]="Fields.DriverLicenseStatus.name"
					[options]="FieldOptions.DriverLicenseStatus"
					[reAlphabeticalOrder]="false"
					[tabindex]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.get(Fields.DriverLicenseStatus.name) && upsellSelected"
				[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				class="employment_industry"
				icon="person"
			>
				<app-dropdown
					[labelInsideField]="'labels.employmentIndustry' | translate"
					[name]="Fields.DriverEmploymentIndustry.name"
					[options]="DriverEmploymentIndustryEmployedOptions"
					[reAlphabeticalOrder]="false"
					[tabindex]="
						form.get(Fields.DriverEmploymentIndustry.name) && form.get(Fields.DriverLicenseStatus.name).enabled
					"
					[searchable]="true"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.get(Fields.DriverLicenseStatus.name) && upsellSelected"
				[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				class="occupation"
				icon="person"
			>
				<app-dropdown
					[labelInsideField]="'labels.occupation' | translate"
					[name]="Fields.DriverOccupationStr.name"
					[options]="occupationsOptions"
					[tabindex]="form.get(Fields.DriverOccupationStr.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
					[searchable]="true"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.get(Fields.DriverLicenseStatus.name) && upsellSelected"
				[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				class="education"
				icon="person"
			>
				<app-dropdown
					[labelInsideField]="'labels.driverEducation' | translate"
					[name]="Fields.DriverEducation.name"
					[options]="FieldOptions.DriverEducationOptions"
					[reAlphabeticalOrder]="false"
					[tabindex]="form.get(Fields.DriverEducation.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				></app-dropdown>
			</app-question-wrapper>
		</div>
		<div *ngIf="isAgreementEnabled" class="form-group checkbox-item">
			<div
				[ngClass]="{
					show:
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name) &&
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name).enabled
				}"
			>
				<app-checkbox
					[fieldObj]="Fields.IAgreeToReceiveEmailsByBolt"
					[tabindex]="
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name) &&
						form.get(Fields.IAgreeToReceiveEmailsByBolt.name).enabled
							? 0
							: -1
					"
					[valueChecked]="form.get(Fields.IAgreeToReceiveEmailsByBolt.name).value"
				>
				</app-checkbox>
			</div>
		</div>

		<div
			*ngIf="isLegalConsentEnabled && customFields"
			class="form-group checkbox-item legal-consent"
			formGroupName="CustomFields"
		>
			<app-checkbox
				[fieldObj]="Fields.D2CAgreeToTerms"
				[tabindex]="legalConsentControl && legalConsentControl.enabled ? 0 : -1"
				[valueChecked]="legalConsentControl.value"
			>
			</app-checkbox>
		</div>
	</form>

	<div class="next-button-wrapper">
		<next-button
			(clicked)="handleContinue()"
			(validationFailed)="handleValidation($event)"
			[disabled]="!form.valid"
			[myForm]="myform"
			[title]="'buttons.continue' | translate"
			continue="true"
		></next-button>

		<app-legal-text></app-legal-text>
	</div>
</section>
