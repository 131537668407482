<section class="main-content main-content-personal-details">
	<app-page-heading subtitleKey="primaryDriver.subTitle" titleKey="primaryDriver.title"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div [ngStyle]="{ width: (currentPage * 100) / 2 + '%' }" class="progress-bar"></div>

			<ng-container [ngTemplateOutlet]="page1"></ng-container>
			<ng-container [ngTemplateOutlet]="page2"></ng-container>
		</div>

		<ng-template #page1>
			<div class="sub-page-wrraper" [ngClass]="{ hidden: currentPage === 2 }">
				<div
					[ngClass]="{
						'cross-sell-layout': showCrossSellLayout()
					}"
					class="form-grid-layout first-page"
				>
					<b class="basic-text">{{ "titles.basicInformation" | translate }}</b>

					<app-question-wrapper
						[show]="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
						class="first-name"
						icon="person"
					>
						<unmasked-input
							*ngIf="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
							[name]="Fields.FirstName.name"
							[pattern]="Fields.FirstName.pattern"
							[placeholder]="'personalInfo.firstName' | translate"
							maxlength="50"
							type="text"
						>
						</unmasked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled"
						class="last-name"
						icon="person"
					>
						<unmasked-input
							[name]="Fields.LastName.name"
							[pattern]="Fields.LastName.pattern"
							[placeholder]="'personalInfo.lastName' | translate"
							maxlength="30"
							type="text"
						></unmasked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.DateOfBirth.name) && form.get(Fields.DateOfBirth.name).enabled"
						class="date-of-birth"
						icon="calendar_today"
					>
						<masked-input
							[inputMask]="'**/**/9999'"
							[name]="Fields.DateOfBirth.name"
							[placeholder]="'personalInfo.dateOfBirth' | translate"
							class="date-input"
						>
						</masked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled"
						class="maritial-status"
						icon="family_restroom"
					>
						<app-dropdown
							[labelInsideField]="'personalInfo.maritalStatus' | translate"
							[name]="Fields.MaritalStatus.name"
							[options]="FieldOptions.maritalStatusOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled ? 0 : -1"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.PersonalLineGender.name) && form.get(Fields.PersonalLineGender.name).enabled"
						class="gender"
						icon="person"
						optionalKeyText="labels.moreInfo"
						tooltipKeyText="tooltips.chooseOption"
					>
						<app-gender-dropdown
							[name]="Fields.PersonalLineGender.name"
							[tabindex]="
								form.get(Fields.PersonalLineGender.name) && form.get(Fields.PersonalLineGender.name).enabled ? 0 : -1
							"
						></app-gender-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
						class="driver-license-status"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'personalInfo.driverLicenseStatus' | translate"
							[name]="Fields.DriverLicenseStatus.name"
							[options]="FieldOptions.DriverLicenseStatus"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled
							"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.TypeOfResidence.name) && form.get(Fields.TypeOfResidence.name).enabled"
						class="type-of-residence"
						icon="home"
					>
						<app-dropdown
							[labelInsideField]="'personalInfo.typeOfResidence' | translate"
							[name]="Fields.TypeOfResidence.name"
							[options]="FieldOptions.TypeOfResidenceOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="form.get(Fields.TypeOfResidence.name) && form.get(Fields.TypeOfResidence.name).enabled"
						></app-dropdown>
					</app-question-wrapper>

					<app-upsell-box
						(upsellToggled)="onUpsellToggled($event)"
						*ngIf="showCrossSellLayout()"
						[currentLob]="LobsEnum.PERSONAL_AUTO"
						[upsellLob]="upsellLob"
						class="upsell-box"
					></app-upsell-box>
				</div>

				<app-button
					(clicked)="goToNextPage()"
					[disabled]="!checkFieldsFirstStepValidity()"
					[title]="'buttons.nextStep' | translate"
					[ngClass]="currentPage === 1 ? 'inner-next-button' : ''"
					materialIcon="chevron_right"
					severity="primary"
				></app-button>
			</div>
		</ng-template>

		<ng-template #page2>
			<div class="sub-page-wrraper" [ngClass]="{ hidden: currentPage === 1 }">
				<div [hidden]="currentPage === 2" class="form-grid-layout second-page">
					<b class="employment-text">Employment & Education</b>

					<app-question-wrapper
						[show]="
							form.get(Fields.DriverEmploymentIndustry.name) && form.get(Fields.DriverEmploymentIndustry.name).enabled
						"
						class="driver-employment-industry"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'labels.employmentIndustry' | translate"
							[name]="Fields.DriverEmploymentIndustry.name"
							[options]="DriverEmploymentIndustryEmployedOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								form.get(Fields.DriverEmploymentIndustry.name) && form.get(Fields.DriverLicenseStatus.name).enabled
							"
							[searchable]="true"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.DriverOccupationStr.name) && form.get(Fields.DriverOccupationStr.name).enabled"
						class="occupation"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'labels.occupation' | translate"
							[name]="Fields.DriverOccupationStr.name"
							[options]="occupationsOptions"
							[tabindex]="
								form.get(Fields.DriverOccupationStr.name) && form.get(Fields.DriverLicenseStatus.name).enabled
							"
							[searchable]="true"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.DriverEducation.name) && form.get(Fields.DriverEducation.name).enabled"
						class="education"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'labels.driverEducation' | translate"
							[name]="Fields.DriverEducation.name"
							[options]="FieldOptions.DriverEducationOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="form.get(Fields.DriverEducation.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
						></app-dropdown>
					</app-question-wrapper>

					<b class="contact-text">{{ "labels.contactInformation" | translate }}</b>

					<app-question-wrapper
						[show]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled"
						class="email"
						icon="email"
					>
						<unmasked-input
							[name]="Fields.Email.name"
							[pattern]="Fields.Email.pattern"
							[placeholder]="'Email' | translate"
							[subLabel]="'labels.emailQuoteAlert' | translate"
							[tabindex]="form.get(Fields.Email.name) && form.get(Fields.Email.name).enabled ? null : -1"
							type="email"
						>
						</unmasked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="form.get(Fields.PrimaryPhoneNumber.name) && form.get(Fields.PrimaryPhoneNumber.name).enabled"
						class="phoneNumber"
						icon="call"
					>
						<masked-input
							[inputMask]="phoneNumberMask"
							[name]="Fields.PrimaryPhoneNumber.name"
							[placeholder]="'personalInfo.phone' | translate"
							[tabindex]="
								form.get(Fields.PrimaryPhoneNumber.name) && form.get(Fields.PrimaryPhoneNumber.name).enabled ? null : -1
							"
							[topPlaceholder]="true"
							type="tel"
						>
						</masked-input>
					</app-question-wrapper>

					<div
						*ngIf="isLegalConsentEnabled && customFields"
						class="form-group checkbox-item legal-consent"
						formGroupName="CustomFields"
					>
						<app-checkbox
							[fieldObj]="Fields.D2CAgreeToTerms"
							[tabindex]="legalConsentControl && legalConsentControl.enabled ? 0 : -1"
							[valueChecked]="legalConsentControl.value"
						>
						</app-checkbox>
					</div>

					<div *ngIf="isAgreementEnabled" class="form-group checkbox-item">
						<div
							[ngClass]="{
								show:
									form.get(Fields.IAgreeToReceiveEmailsByBolt.name) &&
									form.get(Fields.IAgreeToReceiveEmailsByBolt.name).enabled
							}"
						>
							<app-checkbox
								[fieldObj]="Fields.IAgreeToReceiveEmailsByBolt"
								[tabindex]="
									form.get(Fields.IAgreeToReceiveEmailsByBolt.name) &&
									form.get(Fields.IAgreeToReceiveEmailsByBolt.name).enabled
										? 0
										: -1
								"
								[valueChecked]="form.get(Fields.IAgreeToReceiveEmailsByBolt.name).value"
							>
							</app-checkbox>
						</div>
					</div>
				</div>
				<div class="next-button-wrapper-primary-driver">
					<next-button
						(clicked)="handleContinue()"
						(validationFailed)="handleValidation($event)"
						[disabled]="!this.form.valid"
						[myForm]="myform"
						[textLoading]="'buttons.carsDriversLoadingText' | translate"
						[title]="'buttons.continue' | translate"
						continue="true"
						showTextLoading="true"
					></next-button>
					<button (click)="goToPreviousPage()" class="go-back">{{ "buttons.goBack" | translate }}</button>
				</div>
			</div>
		</ng-template>
	</form>

	<app-legal-text class="primary-driver-legal-text"></app-legal-text>
</section>
