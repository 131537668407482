<div *ngIf="showHeader()" [ngClass]="{ 'first-page': isFirstPage }" class="header-container">
	<span *ngIf="isBackBtnVisible" class="back-container">
		<button
			(click)="onBackButtonClick()"
			[attr.aria-label]="'buttons.backButton' | translate"
			[eventData]="{
				action: eventActions.BUTTON_CLICK,
				label: 'back-button',
				button: 'back-button',
				modal: 'header'
			}"
			appClickEvent
			class="back-button"
			tabindex="0"
		>
			<i appHiddenWhenCssDisabled class="material-icons" role="presentation"> navigate_before </i>
			{{ "buttons.back" | translate }}
		</button>
	</span>

	<a
		(click)="backToHomepage()"
		[class.not-clickable]="!logoUrl?.length"
		[eventData]="{
			action: eventActions.BUTTON_CLICK,
			label: 'Header.Logo'
		}"
		appClickEvent
		class="logo-area"
		role="button"
		tabindex="0"
	>
		<ng-container *ngIf="!alternativeLogo">
			<app-image
				*ngIf="isMainLogoVisible"
				alt="{{ source }} logo"
				class="logo"
				name="logo"
				role="presentation"
			></app-image>

			<app-image
				*ngIf="isAddLogoVisible"
				alt="{{ source }} logo"
				class="logo"
				name="additionalLogo"
				role="presentation"
			></app-image>
		</ng-container>

		<app-image
			*ngIf="alternativeLogo"
			[name]="alternativeLogo"
			alt="{{ source }} logo"
			class="logo"
			role="presentation"
		></app-image>
	</a>

	<app-progress-meter-header></app-progress-meter-header>

	<span class="right-side" role="presentation">
		<form #myform="ngForm" *ngIf="languageOptions?.length > 1" [formGroup]="languageForm" class="language-form">
			<app-dropdown
				[labelInsideField]="'labels.selectLanguage' | translate"
				[options]="languageOptions"
				[reAlphabeticalOrder]="false"
				name="languageControl"
			></app-dropdown>
		</form>

		<span *ngIf="isFirstPage" class="find-quote-button">
			<a (click)="routingService.navigateToRoute('retrieve-quote')" appTranslation="header.FindMyQuoteText"></a>
		</span>

		<span *ngIf="isFirstPage && isPhoneVisible && phoneNumber" class="separator"></span>

		<span *ngIf="isPhoneVisible && phoneNumber" class="phone-number">
			<a
				[eventData]="{
					action: eventActions.BUTTON_CLICK,
					label: 'Header.PhoneNumber',
					button: 'phone',
					modal: 'header'
				}"
				appClickEvent
				class="phone-number-link phone-number-link-desktop"
				href="tel:{{ phoneNumber }}"
				tabindex="0"
			>
				<app-image alt="null" height="33" name="chat" role="presentation"></app-image>
				<span class="phone-number-digits">{{ phoneNumber }}</span>
			</a>
			<button
				(click)="openPhonePopup()"
				[eventData]="{
					action: eventActions.BUTTON_CLICK,
					label: 'Header.PhoneNumber',
					button: 'phone',
					modal: 'header'
				}"
				appClickEvent
				class="phone-number-link phone-number-link-mobile"
				tabindex="0"
			>
				<app-image alt="null" height="33" name="chat" role="presentation"></app-image>
			</button>
		</span>

		<span *ngIf="isPhoneVisible" appTranslation="header.phoneBottom" class="phone-bottom"></span>
	</span>
</div>
