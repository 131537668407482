import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { IAppState } from '../../../../../store/states/app.state';
import { selectInterviewTexts } from '../../../../../store/selectors/interview-texts.selectors';
import { getSelectedCarrier, getSelectedRate } from '../../../../../store/selectors/result-data.selectors';
import { selectInterviewMetadata } from '../../../../../store/selectors/interview-metadata.selector';
import { LobService } from '../../../../../services/lob.service';
import { first } from 'rxjs/operators';
import { ActionApiService } from '../../../../../services/action-api.service';
import { Validations } from '../../../../../utils/validation';
import { FormBuilder } from '../../../../../../../node_modules/@angular/forms';
import { DriverStateLicensed } from 'src/app/constants/field-options';

@Component({
	selector: 'app-payment-plan-escrow',
	templateUrl: './payment-plan-escrow.component.html',
	styleUrls: ['./payment-plan-escrow.component.scss'],
})
export class PaymentPlanEscrowComponent extends BasePageComponent implements OnInit, OnDestroy {
	isMortgageBillPayCheckboxOn = false;
	totalPrice: number;
	fieldsNamesPreviousAddress = {
		AddressLine1: 'MortgageCompanyAddress.AddressLine1',
		AddressLine2: 'MortgageCompanyAddress.AddressLine2',
		City: 'MortgageCompanyAddress.City',
		State: 'MortgageCompanyAddress.State',
		ZipCode: 'MortgageCompanyAddress.ZipCode',
		//County: 'MortgageCompanyAddress.County',
	};
	statesOptions = DriverStateLicensed;
	private selectedCarrier: string;
	private lobsInitialized: string[];

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private lobService: LobService,
		private fb: FormBuilder,
		private actionApiService: ActionApiService
	) {
		super(injector, store);
	}

	get customFields(): FormGroup {
		return this.form.get('CustomFields') as FormGroup;
	}

	get MortgageBillPayControl(): FormControl {
		return this.customFields.get(this.Fields.MortgageBillPay.name) as FormControl;
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.initForm();

		combineLatest([
			this.store.select(selectInterviewTexts),
			this.store.select(getSelectedRate),
			this.store.select(getSelectedCarrier),
			this.store.select(selectInterviewMetadata),
			this.lobService.getLob$(),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([stepTexts, selectedRate, selectedCarrier, interviewMetadata]) => {
				this.totalPrice = selectedRate.premium;
				this.selectedCarrier = selectedCarrier;
				this.isMortgageBillPayCheckboxOn = stepTexts['paymentEscrow']?.isMortgageBillPayCheckboxOn;
				this.lobsInitialized = interviewMetadata.lobsInitialized;

				if (this.isMortgageBillPayCheckboxOn) {
					this.Fields.MortgageBillPay = {
						...this.Fields.MortgageBillPay,
						labelKey: 'paymentEscrow.mortgageBillPayConsent',
					};

					this.form.addControl(
						'CustomFields',
						new FormGroup({
							[this.Fields.MortgageBillPay.name]: new FormControl({ value: null, disabled: false }, [
								Validators.requiredTrue,
							]),
						})
					);

					setTimeout(() => {
						if (
							this.quoteData.hasOwnProperty('CustomFields') &&
							this.quoteData.CustomFields.hasOwnProperty(this.Fields.MortgageBillPay.name)
						) {
							this.MortgageBillPayControl.setValue(
								this.quoteData.CustomFields[this.Fields.MortgageBillPay.name] === 'True' ||
									this.quoteData.CustomFields[this.Fields.MortgageBillPay.name]
							);
						}
					});
				}
			});
	}

	initForm(): void {
		this.form = this.fb.group({
			MortgageCompanyName: new FormControl(null, Validators.required),
			MortgageAccountNumber: new FormControl(null, Validators.required),
			MortgageBillPay: new FormControl(false, Validators.required),
			[this.Fields.MortgageCompanyAddress.name]: this.fb.group({
				AddressLine1: [null, [Validators.required, Validators.maxLength(50)]],
				AddressLine2: [null, [Validators.maxLength(50)]],
				City: [null, [Validators.required, Validators.maxLength(25)]],
				State: [null, [Validators.required, Validators.maxLength(2)]],
				ZipCode: [null, [Validators.required, Validations.zipStrictLength(5)]],
				//County: [null],
			}),
		});

		this.patchData(this.form);
		this.registerOnChange(this.form);
	}

	handleContinue() {
		const formValue: any = { ...this.form.value, ...this.customFields.value };

		delete formValue['CustomFields'];

		this.actionApiService
			.updateApplication(formValue, this.lobsInitialized, this.selectedCarrier)
			.pipe(first())
			.subscribe((serverRes) => {
				this.onUpdateApplicationResult(serverRes);
			});
	}

	private transformText(text: string): string {
		return text.replace(new RegExp('<%totalPrice%>', 'g'), `$${this.totalPrice}`);
	}
}
