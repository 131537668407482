<section class="main-content">
	<app-page-heading [title]="'titles.primaryResidence' | translate"></app-page-heading>

	<app-single-yes-no (handleAns)="handleSelect($event)" [choosedAns]="this.primaryResidence"></app-single-yes-no>
	<next-button
		(clicked)="handleContinue()"
		[disabled]="!this.primaryResidence"
		[showTextLoading]="this.primaryResidence === 'yes'"
		continue="true"
		[title]="'buttons.continue' | translate"
	></next-button>
</section>
