<div class="container">
	<div class="text">
		<p>{{ "titles.byPurchasingPolicyIAgree" | translate }}</p>
		<ul>
			<li>{{ "labels.noDangerousAnimals" | translate }}</li>
			<li>{{ "labels.animalLiabilityNotNeeded" | translate }}</li>
		</ul>
	</div>

	<next-button
		(click)="handleAgreementClick()"
		continue="true"
		animation="true"
		[title]="'buttons.agreeAndContinue' | translate"
	>
	</next-button>
</div>
