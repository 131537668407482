<section class="main-content main-content-personal-details">
	<app-page-heading [title]="'titles.pleaseProvideBelowInformation' | translate"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div [style.width]="getProgressbarWidth()" class="progress-bar"></div>
			<div
				*ngFor="let vehicleForm of getActiveFields(); let index = index"
				[class.hidden]="index !== this.internalStep"
				[formGroup]="vehicleForm"
				class="vehicle-form"
			>
				<app-vehicle
					[cantAdd]="false"
					[formGroup]="vehicleForm"
					[isAdditionalQuestionsPage]="true"
					source="discoverVehicles"
				></app-vehicle>
				<app-question-wrapper>
					<unmasked-input
						[disabled]="!vehicleForm.value.ExternalVINPrefix"
						[emitKeyupOnBlur]="false"
						[name]="Fields.VIN.name"
						[pattern]="Fields.VIN.pattern"
						[placeholder]="'labels.vin' | translate"
						maxlength="17"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper>
					<masked-input
						[inputMask]="'**/**/9999'"
						[name]="Fields.DateVehiclePurchased.name"
						[placeholder]="'labels.dateVehiclePurchased' | translate"
						class="date-input"
					>
					</masked-input>
				</app-question-wrapper>

				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.primaryUseOfVehicle' | translate"
						[name]="Fields.PrimaryUseOfVehicle.name"
						[options]="PrimaryUseOfVehicleOptionsProgressive"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.MilesToWorkPGR.name).enabled">
					<unmasked-input
						[maxlength]="4"
						[name]="Fields.MilesToWorkPGR.name"
						[pattern]="Fields.MilesToWorkPGR.pattern"
						[placeholder]="'labels.milesToWorkDaily' | translate"
						[withCommaFormat]="true"
						secondaryType="number"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.NumDaysDrivenPerWeek.name).enabled">
					<unmasked-input
						[maxlength]="1"
						[name]="Fields.NumDaysDrivenPerWeek.name"
						[pattern]="Fields.NumDaysDrivenPerWeek.pattern"
						[placeholder]="'labels.daysDrivenToWeek' | translate"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.VehicleUseForRideShareInd.name).enabled">
					<app-dropdown
						[labelInsideField]="'labels.vehicleUseForRideShareInd' | translate"
						[name]="Fields.VehicleUseForRideShareInd.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>
				<app-question-wrapper *ngIf="vehicleForm.get(Fields.VehDeliveryUseInd.name).enabled">
					<app-dropdown
						[labelInsideField]="'labels.vehDeliveryUseInd' | translate"
						[name]="Fields.VehDeliveryUseInd.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.BlindSpotWarningInd.name).enabled">
					<app-dropdown
						[labelInsideField]="'labels.blindSpotWarningInd' | translate"
						[name]="Fields.BlindSpotWarningInd.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.AnyModifications.name).enabled">
					<app-dropdown
						[labelInsideField]="'labels.anyModifications' | translate"
						[name]="Fields.AnyModifications.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.ModificationsValue.name).enabled">
					<unmasked-input
						[followByDollar]="true"
						[maxlength]="9"
						[name]="Fields.ModificationsValue.name"
						[pattern]="Fields.ModificationsValue.pattern"
						[placeholder]="'labels.modificationsValue' | translate"
						[withCommaFormat]="true"
						secondaryType="number"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.GarageAddressDifferent.name).enabled">
					<app-dropdown
						[labelInsideField]="'labels.garageAddressDifferent' | translate"
						[name]="Fields.GarageAddressDifferent.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<div
					*ngIf="
						vehicleForm.get('PersonalVehicleGarageAddress.AddressLine1') &&
						vehicleForm.get('PersonalVehicleGarageAddress.AddressLine1').enabled
					"
				>
					<p class="previous-address-details-text">{{ "titles.vehicleGarageAddress" | translate }}</p>

					<div class="address-field-wrapper PersonalVehicleGarageAddress">
						<app-address-suggestion
							(addressChangedEvent)="handleAddressChange(vehicleForm)"
							*ngIf="
								vehicleForm.get('PersonalVehicleGarageAddress.AddressLine1') &&
								vehicleForm.get('PersonalVehicleGarageAddress.AddressLine1').enabled
							"
							[addressLine1]="vehicleForm.get('PersonalVehicleGarageAddress.AddressLine1').value"
							[addressLine2]="vehicleForm.get('PersonalVehicleGarageAddress.AddressLine2').value"
							[city]="vehicleForm.get('PersonalVehicleGarageAddress.City').value"
							[fieldsNames]="fieldsNames2"
							[form]="form"
							[placeholder]="'address.suggestion.address' | translate"
							[showIcon]="false"
							[state]="vehicleForm.get('PersonalVehicleGarageAddress.State').value"
							[toShowMap]="false"
							[zipCode]="vehicleForm.get('PersonalVehicleGarageAddress.ZipCode').value"
							addressFormName="PersonalVehicleGarageAddress"
						>
						</app-address-suggestion>
					</div>

					<error-message
						[field]="vehicleForm.get('PersonalVehicleGarageAddress').get('AddressLine1')"
						[isFocus$]="true"
					></error-message>
				</div>
				<p *ngIf="vehicleForm.get('FQLienholderAddress').enabled" class="lease-title">
					{{ "labels.leaseLienInformation" | translate }}
				</p>
				<app-question-wrapper *ngIf="vehicleForm.get(Fields.LoanLeaseAmount.name).enabled">
					<unmasked-input
						[name]="Fields.LoanLeaseAmount.name"
						[pattern]="Fields.LoanLeaseAmount.pattern"
						[placeholder]="'labels.loanLeaseAmount' | translate"
						[withCommaFormat]="true"
						followByDollar="true"
						secondaryType="number"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.LoanLeaseName.name).enabled">
					<unmasked-input
						[name]="Fields.LoanLeaseName.name"
						[placeholder]="'labels.loanLeaseName' | translate"
						[pattern]="Fields.LoanLeaseName.pattern"
					></unmasked-input>
				</app-question-wrapper>

				<div class="address-field-wrapper FQLienholderAddress">
					<app-address-suggestion
						(addressChangedEvent)="this.address = true"
						*ngIf="
							vehicleForm.get('FQLienholderAddress.AddressLine1') &&
							vehicleForm.get('FQLienholderAddress.AddressLine1').enabled
						"
						[addressLine1]="vehicleForm.get('FQLienholderAddress.AddressLine1').value"
						[addressLine2]="vehicleForm.get('FQLienholderAddress.AddressLine2').value"
						[city]="vehicleForm.get('FQLienholderAddress.City').value"
						[fieldsNames]="fieldsNames"
						[form]="form"
						[placeholder]="'address.suggestion.leaseAddress' | translate"
						[showIcon]="false"
						[state]="vehicleForm.get('FQLienholderAddress.State').value"
						[toShowMap]="false"
						[zipCode]="vehicleForm.get('FQLienholderAddress.ZipCode').value"
					>
					</app-address-suggestion>
				</div>
			</div>

			<div class="spacer"></div>
			<next-button
				(clicked)="handleContinue()"
				(validationFailed)="handleValidation($event)"
				[continue]="true"
				[disabled]="isNextDisabled"
				[myForm]="myform"
				[title]="'buttons.continue' | translate"
				isFullQuote="true"
			></next-button>
			<button (click)="goBack()" *ngIf="this.internalStep" class="back-button" type="button">
				{{ "buttons.goBack" | translate }}
			</button>
		</div>
	</form>
</section>
