<section class="main-content main-content-personal-details">
	<app-page-heading subtitleKey="coverages.subTitle" titleKey="coverages.title"></app-page-heading>
	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div [ngClass]="{ stepTwo: internalStep == 2 }" class="progress-bar"></div>

			<div *ngIf="internalStep == 1" class="BI">
				<h4 appTranslation="coverages.bilTitle" class="questions-container-title"></h4>
				<div appTranslation="coverages.bilSubTitle" class="questions-container-subtitle"></div>
				<app-question-wrapper
					[show]="form.get(Fields.BodilyInjuryliability.name) && form.get(Fields.BodilyInjuryliability.name).enabled"
					class="gender"
				>
					<app-dropdown
						[labelInsideField]="'labels.bodilyInjuryLiability' | translate"
						[name]="Fields.BodilyInjuryliability.name"
						[options]="FieldOptions[typeOptions]"
						[reAlphabeticalOrder]="false"
						[tabindex]="
							form.get(Fields.BodilyInjuryliability.name) && form.get(Fields.BodilyInjuryliability.name).enabled
								? 0
								: -1
						"
					></app-dropdown>
				</app-question-wrapper>

				<div class="spacer"></div>
				<next-button
					(click)="navigateInternal()"
					[disabled]="!this.form.controls['BI'].valid"
					[title]="'buttons.continue' | translate"
					continue="true"
				></next-button>
			</div>

			<div *ngIf="internalStep == 2" class="collisions">
				<h4 appTranslation="coverages.cacTitle" class="questions-container-title"></h4>
				<div appTranslation="coverages.cacSubTitle" class="questions-container-subtitle"></div>

				<div *ngFor="let vehicle of this.PersonalVehiclesFormArray.controls; let i = index">
					<app-vehicle-cover *ngIf="vehicle.value.enabled" [formVehicle]="vehicle"></app-vehicle-cover>
				</div>

				<div *ngFor="let vehicle of this.discoverdFormArray.controls; let i = index">
					<app-vehicle-cover *ngIf="vehicle.value.enabled" [formVehicle]="vehicle"></app-vehicle-cover>
				</div>

				<div *ngFor="let vehicle of this.vehiclesFormArray.controls; let i = index">
					<app-vehicle-cover *ngIf="vehicle.value.enabled" [formVehicle]="vehicle"></app-vehicle-cover>
				</div>
				<div class="spacer"></div>
				<next-button
					(clicked)="handleContinue()"
					[disabled]="!this.form.valid"
					[title]="'buttons.continue' | translate"
					continue="true"
				></next-button>
				<button (click)="navigateInternal()" class="back-button">{{ "buttons.goBack" | translate }}</button>
			</div>
		</div>
	</form>
</section>
