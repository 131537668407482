import { IDropdownOption } from '../components/form-controls/form-control-interfaces';

export const PGR_CARRIERS_LIST = [
	{
		title: '21st Century',
		value: 'TwentyFirstCentury',
		id: 0,
	},
	{
		title: 'AAA',
		value: 'AAA',
		id: 1,
	},
	{
		title: 'Agricultural Workers Auto Ins',
		value: 'AgriculturalWorkers',
		id: 2,
	},
	{
		title: 'AIG National',
		value: 'AIGNational',
		id: 3,
	},
	{
		title: 'AIG: Other',
		value: 'AIGOther',
		id: 4,
	},
	{
		title: 'Alinsco',
		value: 'Alinsco',
		id: 5,
	},
	{
		title: 'Allstate',
		value: 'Allstate',
		id: 6,
	},
	{
		title: 'AIG American',
		value: 'AIGAmerican',
		id: 7,
	},
	{
		title: 'American Access',
		value: 'AmericanAccess',
		id: 8,
	},
	{
		title: 'American Century Casualty',
		value: 'AmericanCentury',
		id: 9,
	},
	{
		title: 'American National',
		value: 'AmericanNational',
		id: 10,
	},
	{
		title: 'American Overseas',
		value: 'AmericanOverseas',
		id: 11,
	},
	{
		title: 'Amica',
		value: 'Amica',
		id: 12,
	},
	{
		title: 'Central Mutual',
		value: 'CentralMutualOfOH',
		id: 13,
	},
	{
		title: 'Chubb',
		value: 'Chubb',
		id: 14,
	},
	{
		title: 'Consumers County Mutual',
		value: 'ConsumersCNTY',
		id: 15,
	},
	{
		title: 'CWI',
		value: 'CWI',
		id: 16,
	},
	{
		title: 'Dairyland',
		value: 'Dairyland',
		id: 17,
	},
	{
		title: 'Direct',
		value: 'Direct',
		id: 18,
	},
	{
		title: 'Elephant',
		value: 'Elephant',
		id: 19,
	},
	{
		title: 'Encompass',
		value: 'Encompass',
		id: 20,
	},
	{
		title: 'Falcon',
		value: 'Falcon',
		id: 21,
	},
	{
		title: 'Farm Burea',
		value: 'FarmBurea',
		id: 22,
	},
	{
		title: 'Farmers',
		value: 'Farmers',
		id: 23,
	},
	{
		title: 'Firemans Fund',
		value: 'FiremansFund',
		id: 24,
	},
	{
		title: 'Foremost',
		value: 'Foremost',
		id: 25,
	},
	{
		title: 'Geico',
		value: 'Geico',
		id: 26,
	},
	{
		title: 'Germania',
		value: 'Germania',
		id: 27,
	},
	{
		title: 'GMAC',
		value: 'GMAC',
		id: 28,
	},
	{
		title: 'Hartford',
		value: 'Hartford',
		id: 29,
	},
	{
		title: 'Hochheim',
		value: 'Hochheim',
		id: 30,
	},
	{
		title: 'Home State Insurance Group',
		value: 'HomeState',
		id: 31,
	},
	{
		title: 'Horace Mann',
		value: 'HoraceMann',
		id: 32,
	},
	{
		title: 'Infinity',
		value: 'Infinity',
		id: 33,
	},
	{
		title: 'Kemper',
		value: 'Kemper',
		id: 34,
	},
	{
		title: 'Liberty Mutual',
		value: 'LibertyMutual',
		id: 35,
	},
	{
		title: 'Loya',
		value: 'Loya',
		id: 36,
	},
	{
		title: 'Mercury',
		value: 'Mercury',
		id: 37,
	},
	{
		title: 'Metlife',
		value: 'Metlife',
		id: 38,
	},
	{
		title: 'Nationwide',
		value: 'Nationwide',
		id: 39,
	},
	{
		title: 'Old American',
		value: 'OldAmerican',
		id: 40,
	},
	{
		title: 'Other NonStandard',
		value: 'OtherNonStandard',
		id: 41,
	},
	{
		title: 'Other Standard',
		value: 'OtherStandard',
		id: 42,
	},
	{
		title: 'Progressive',
		value: 'Progressive',
		id: 43,
	},
	{
		title: 'Root',
		value: 'Root',
		id: 44,
	},
	{
		title: 'Republic',
		value: 'Republic',
		id: 45,
	},
	{
		title: 'Safe Auto',
		value: 'SafeAuto',
		id: 46,
	},
	{
		title: 'Safeco',
		value: 'Safeco',
		id: 47,
	},
	{
		title: 'Sentry',
		value: 'Sentry',
		id: 48,
	},
	{
		title: 'Southern',
		value: 'Southern',
		id: 49,
	},
	{
		title: 'Statefarm',
		value: 'Statefarm',
		id: 50,
	},
	{
		title: 'StateNational',
		value: 'StateNational',
		id: 51,
	},
	{
		title: 'Travelers',
		value: 'Travelers',
		id: 52,
	},
	{
		title: 'Unitrin',
		value: 'Unitrin',
		id: 53,
	},
	{
		title: 'USAA',
		value: 'USAA',
		id: 54,
	},
	{
		title: 'Zurich',
		value: 'Zurich',
		id: 55,
	},
];

export const priorLiabilityLimits: IDropdownOption[] = [
	{ id: 0, value: 'Dontknow', title: "Don't Know", labelKey: 'priorLiabilityLimits.dontKnow' },
	{ id: 1, value: 'ThirtyToSixty', title: '30/60', labelKey: 'priorLiabilityLimits.thirtyToSixty' },
	{ id: 2, value: 'FiftyToHundred', title: '50/100', labelKey: 'priorLiabilityLimits.fiftyToHundred' },
	{ id: 3, value: 'HundredToHundred', title: '100/100', labelKey: 'priorLiabilityLimits.hundredToHundred' },
	{ id: 4, value: 'HundredToThreeHundred', title: '100/300', labelKey: 'priorLiabilityLimits.hundredToThreeHundred' },
	{
		id: 5,
		value: 'TwoHundredFiftyToFiveHundred',
		title: '250/500',
		labelKey: 'priorLiabilityLimits.twoHundredFiftyToFiveHundred',
	},
	{
		id: 6,
		value: 'ThreeHundredToThreeHundred',
		title: '300/300',
		labelKey: 'priorLiabilityLimits.threeHundredToThreeHundred',
	},
	{
		id: 7,
		value: 'FiveHundredToFiveHundred',
		title: '500/500',
		labelKey: 'priorLiabilityLimits.fiveHundredToFiveHundred',
	},
	{ id: 8, value: 'HundredCSL', title: '100CSL', labelKey: 'priorLiabilityLimits.hundredCSL' },
	{ id: 9, value: 'ThreeHundredCSL', title: '300CSL', labelKey: 'priorLiabilityLimits.threeHundredCSL' },
	{ id: 10, value: 'FiveHundredCSL', title: '500CSL', labelKey: 'priorLiabilityLimits.fiveHundredCSL' },
];

export const spinOffCode: IDropdownOption[] = [
	{ id: 0, value: 'Moved', title: 'Moved out of state', labelKey: 'spinOffCode.movedOutOfState' },
	{
		id: 1,
		value: 'Separated',
		title: 'Separated/divorced (current policy remains in force)',
		labelKey: 'spinOffCode.separatedDivorced',
	},
	{ id: 2, value: 'Leaving', title: 'Leaving a policy that remains in force', labelKey: 'spinOffCode.leavingPolicy' },
	{
		id: 3,
		value: 'Commercial',
		title: 'Other policy is commercial lines',
		labelKey: 'spinOffCode.otherPolicyCommercial',
	},
	{
		id: 4,
		value: 'Direct',
		title: 'Customer changing from Progressive Direct',
		labelKey: 'spinOffCode.customerChanging',
	},
	{ id: 5, value: 'SR22', title: 'SR-22/FR-44 rewrite with prior insurance', labelKey: 'spinOffCode.sr22Rewrite' },
	{ id: 6, value: 'Other', title: 'Other reason not listed', labelKey: 'spinOffCode.otherReasonNotListed' },
];

export const progressivePolicies: IDropdownOption[] = [
	{ id: 0, value: 'Homeowners', title: 'Homeowners', labelKey: 'progressivePolicies.homeowners' },
	{ id: 1, value: 'Condo', title: 'Condo', labelKey: 'progressivePolicies.condo' },
	{ id: 2, value: 'Renters', title: 'Renters', labelKey: 'progressivePolicies.renters' },
	{ id: 3, value: 'MotorcycleATV', title: 'Motorcycle/ATV', labelKey: 'progressivePolicies.motorcycleATV' },
	{ id: 4, value: 'MotorHome', title: 'Motor Home', labelKey: 'progressivePolicies.motorHome' },
	{ id: 5, value: 'TrvlTrlr', title: 'Travel Trailer', labelKey: 'progressivePolicies.travelTrailer' },
	{ id: 6, value: 'Snwmbl', title: 'Snowmobile', labelKey: 'progressivePolicies.snowmobile' },
	{ id: 7, value: 'BoatPWC', title: 'Boat/PWC', labelKey: 'progressivePolicies.boatPWC' },
	{ id: 8, value: 'Umbrella', title: 'Umbrella', labelKey: 'progressivePolicies.umbrella' },
	{ id: 9, value: 'CommlLn', title: 'Commercial Lines', labelKey: 'progressivePolicies.commercialLines' },
];

export const numberOfOccupant: IDropdownOption[] = [
	{ id: 0, value: 1, title: '1', labelKey: 'numberOfOccupant.one' },
	{ id: 1, value: 2, title: '2', labelKey: 'numberOfOccupant.two' },
	{ id: 2, value: 3, title: '3', labelKey: 'numberOfOccupant.three' },
	{ id: 3, value: 4, title: '4', labelKey: 'numberOfOccupant.four' },
	{ id: 4, value: 5, title: '5', labelKey: 'numberOfOccupant.five' },
	{ id: 5, value: 6, title: '6', labelKey: 'numberOfOccupant.six' },
	{ id: 6, value: 7, title: '7', labelKey: 'numberOfOccupant.seven' },
	{ id: 7, value: 8, title: '8', labelKey: 'numberOfOccupant.eight' },
];

export const reasonForLapse: IDropdownOption[] = [
	{ id: 0, value: 'ActiveMilitaryDuty', title: 'Active military duty', labelKey: 'reasonForLapse.activeMilitaryDuty' },
	{ id: 1, value: 'DrivingCompanyCar', title: 'Driving a company car', labelKey: 'reasonForLapse.drivingCompanyCar' },
	{
		id: 2,
		value: 'DrivingCarOwnedByRelative',
		title: 'Driving a car owned by a relative',
		labelKey: 'reasonForLapse.drivingCarOwnedByRelative',
	},
	{
		id: 3,
		value: 'HasNotOwnedOrOperatedVehicle',
		title: 'Has not owned or operated a vehicle',
		labelKey: 'reasonForLapse.hasNotOwnedOrOperatedVehicle',
	},
	{
		id: 4,
		value: 'OwnedVehParkedStoredOffRoadNotDriven',
		title: 'Owned a Parked/Stored Off-Road/Not Driven vehicle',
		labelKey: 'reasonForLapse.ownedVehicleParkedStoredOffRoadNotDriven',
	},
	{ id: 5, value: 'NonPayment', title: 'Non-Payment', labelKey: 'reasonForLapse.nonPayment' },
	{ id: 6, value: 'Other', title: 'Other', labelKey: 'reasonForLapse.other' },
];
