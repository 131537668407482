import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAppState } from '../../../../../../../../store/states/app.state';
import { BasePageComponent } from '../../../../../base-page.component';
import { SYSTEM_UPDATES } from './steps-fileds';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';

@UntilDestroy()
@Component({
	selector: 'app-stillwater-utilities',
	templateUrl: './stillwater-utilities.component.html',
	styleUrls: ['./stillwater-utilities.component.scss', '../stillwater.component.scss'],
})
export class StillwaterUtilitiesComponent extends BasePageComponent implements OnInit {
	stepTwoFields = SYSTEM_UPDATES;
	isStepFilled: boolean;
	vertical = true;

	constructor(
		protected injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService
	) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({
			[this.Fields.PLHeatingType.name]: [null, Validators.required],
			[this.Fields.SolidFuel.name]: [null, Validators.required],
			[this.Fields.SW_ElectricCircuitBreaker.name]: [null, Validators.required],
			[this.Fields.HeatingUpdateYN.name]: [null],
			[this.Fields.SW_PLHeatingUpdate.name]: [null],
			[this.Fields.SW_HeatingUpdateYear.name]: [null],
			[this.Fields.ElectricalUpdateYN.name]: [null],
			[this.Fields.SW_PLElectricalUpdated.name]: [null],
			[this.Fields.SW_ElectricalUpdatedYear.name]: [null],
			[this.Fields.PlumbingUpdateYN.name]: [null],
			[this.Fields.SW_PLPlumbingUpdated.name]: [null],
			[this.Fields.SW_PlumbingUpdatedYear.name]: [null],
		});

		this.patchData(this.form);
		this.onFormChange();
	}

	private onFormChange(): void {
		// transform values for the systems related fields
		this.stepTwoFields.forEach((field) => {
			this.form.controls[field.name].valueChanges.pipe(untilDestroyed(this)).subscribe((fieldValue) => {
				if (fieldValue !== null) {
					field.children.forEach((child) => {
						const childValue = eval(eval(child.rule));

						this.form.controls[child.name]?.setValue(childValue);
					});
				}
			});
		});

		super.registerOnChange(this.form);
	}
}
