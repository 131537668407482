import { createAction, props } from '@ngrx/store';
import { IMappedSessionResponse } from '../../../entities/session-response.interface';
import { IQuoteDataState } from '../states/quote-data.state';

export const SetQuoteData = createAction('[Quote Data] set', props<IMappedSessionResponse>());
export const UpdateQuoteData = createAction('[Quote Data] Update', props<{ data: IQuoteDataState }>());
export const MergeQuoteData = createAction('[Quote Data] Merge Quote Data', props<{ data: IQuoteDataState, deleteOnEmpty: boolean }>());
export const UpdateQuoteDataWithInitial = createAction('[Quote Data With Initial] Update', props<{ data: any }>());
export const UpdateQuoteDataFinished = createAction('[Quote Data] Update finished');
export const ResetQuoteData = createAction('[Quote Data] Reset');
export const ResetQuoteDataExceptAddress = createAction('[Quote Data] Reset except Address');
export const AddManualDriver = createAction('[Manual Driver] Add', props<{ data: any }>());
export const EditManualDriver = createAction('[Manual Driver] Edit', props<{ data: any }>());
export const EditDiscoveredDriver = createAction('[Discovered Driver] Edit', props<{ data: any }>());
export const EditDrivers = createAction('[Drivers] Edit', props<{ data: any }>());

export const AddManualVehicle = createAction('[Quote Data] Add manual vehicle', props<{ value: any }>());

export const AddPet = createAction('[Quote Data] Add pet', props<{ value: any }>());
export const UpdateManualVehicle = createAction(
	'[Quote Data] Update manual vehicle',
	props<{ data: { Id: string; value: any } }>()
);
export const UpdateDiscoverVehicle = createAction(
	'[Quote Data] Update discover vehicle',
	props<{ data: { Id: string; value: any } }>()
);
export const UpdatePersonalVehicle = createAction(
	'[Quote Data] Update personal vehicle',
	props<{ data: { Id: string; value: any } }>()
);

