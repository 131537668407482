import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { BasePageComponent } from '../../../../../base-page.component';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Fields } from '../stillwater-fields';
import { inOutAnimation } from 'src/app/animations';
import { selectAddress, selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { ActionApiService } from 'src/app/services/action-api.service';
import { TranslationService } from 'src/app/services/translation.service';

const CONSTRUCTION_PERMIT_STATES = ['AL', 'FL', 'KY'];
const DISTANCE_TO_COAST_COASTAL_DISTANCE = ['DE', 'NJ'];
const MONTHS_UNOCCUPIED_YESNO = ['AR', 'DC', 'LA', 'MA', 'ME', 'MO', 'NY', 'WI'];
const MONTHS_UNOCCUPIED_DD = ['AL', 'FL'];

@UntilDestroy()
@Component({
	selector: 'app-stillwater-additional-property-information',
	templateUrl: './stillwater-additional-property-information.component.html',
	styleUrls: ['./stillwater-additional-property-information.component.scss', '../stillwater.component.scss'],
	animations: [inOutAnimation],
})
export class StillwaterAdditionalPropertyInformationComponent extends BasePageComponent implements OnInit {
	Fields = Fields;
	vertical = true;
	State;
	actionApiService: ActionApiService;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService,
		actionApiService: ActionApiService,
		public translationService: TranslationService,
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({
			[this.Fields.BusinessOrDaycare.name]: [null, [Validators.required]],
			[this.Fields.FarmOrRanch.name]: [null, [Validators.required]],
			[this.Fields.ConvertedResidency.name]: [null, [Validators.required]],
			[this.Fields.ConstructionPermit.name]: [null, [Validators.required]],
			[this.Fields.RoofCondition.name]: [null, [Validators.required]],
			[this.Fields.DistanceToOcean.name]: [null, [Validators.required]],
			[this.Fields.NumberOfDogsOnPremises.name]: [null, [Validators.required]],
			[this.Fields.ViciousExoticAnimals.name]: [{ value: null, disabled: true }, [Validators.required]],
			[this.Fields.MonthsUnoccupiedYesNo.name]: [{ value: null, disabled: true }, [Validators.required]],
			[this.Fields.MonthsUnoccupiedDD.name]: [{ value: null, disabled: true }, [Validators.required]],
			[this.Fields.ExtendedVacancy.name]: [{ value: null, disabled: true }, [Validators.required]],
			[this.Fields.UnusualVehicleStorage.name]: [null, [Validators.required]],
			[this.Fields.UnusualConst.name]: [null, [Validators.required]],
		});

		this.store
			.select(selectAddress)
			.pipe(first())
			.subscribe((address) => {
				this.State = address.State;
				if (!CONSTRUCTION_PERMIT_STATES.includes(address.State)) {
					this.form.controls[this.Fields.ConstructionPermit.name].disable();
				}
				if (!DISTANCE_TO_COAST_COASTAL_DISTANCE.includes(address.State)) {
					this.form.controls[this.Fields.DistanceToOcean.name].disable();
				}
			});
		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				if (new Date().getFullYear() <= quoteData.PLYearBuilt) {
					this.form.controls[this.Fields.RoofCondition.name].disable();
					this.form.controls[this.Fields.UnusualVehicleStorage.name].disable();
					this.form.controls[this.Fields.UnusualConst.name].disable();
				}

				//MonthsUnoccupied
				if (new Date().getFullYear() > quoteData.PLYearBuilt) {
					if (
						MONTHS_UNOCCUPIED_YESNO.includes(quoteData.PropertyAddress.State) &&
						quoteData.OccupancyType == 'OwnerSecondary'
					) {
						this.form.controls[this.Fields.MonthsUnoccupiedYesNo.name].enable();
					} else if (
						MONTHS_UNOCCUPIED_DD.includes(quoteData.PropertyAddress.State) &&
						quoteData.OccupancyType == 'OwnerSecondary'
					) {
						this.form.controls[this.Fields.MonthsUnoccupiedDD.name].enable();
					} else if (
						!MONTHS_UNOCCUPIED_YESNO.includes(quoteData.PropertyAddress.State) ||
						(MONTHS_UNOCCUPIED_YESNO.includes(quoteData.PropertyAddress.State) &&
							quoteData.OccupancyType != 'OwnerSecondary')
					) {
						this.form.controls[this.Fields.ExtendedVacancy.name].enable();
					}
				}
			});

		this.form.controls[this.Fields.NumberOfDogsOnPremises.name].valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value) => {
				if (!value) {
					this.form.controls[this.Fields.ViciousExoticAnimals.name].disable();
				} else {
					this.form.controls[this.Fields.ViciousExoticAnimals.name].enable();
				}
			});
		super.registerOnChange(this.form);
		this.patchData(this.form);
	}

	handleContinue() {
		let data = this.form.value;
		if (data.MonthsUnoccupiedDD) {
			data.MonthsUnoccupied = data.MonthsUnoccupiedDD;
			delete data.MonthsUnoccupiedDD;
		} else if (typeof data.MonthsUnoccupiedYesNo == 'boolean') {
			if (data.MonthsUnoccupiedYesNo) {
				data.MonthsUnoccupied = 3;
			} else {
				data.MonthsUnoccupied = 7;
			}
			delete data.MonthsUnoccupiedYesNo;
		}

		this.actionApiService
			.updateApplication(data, [LobsEnum.PERSONAL_HOME], CarriersEnum.stillwater)
			.subscribe((serverRes) => {
				this.onUpdateApplicationResult(serverRes);
			});
	}

	get today(): Date {
		return new Date();
	}
}
