import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CARRIERS_TEXTS } from './carriers-texts.data';
import { CoverageModel } from '../../../../../models/coverage.model';
import { ActionApiService } from 'src/app/services/action-api.service';
import { pricePerMonth, yearlyTermDisplayName } from '../../../../../utils/general.utils';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addToggledRatesSelected, removeToggledRatesSelected } from '../../../../../store/actions/result-data.actions';
import { selectToggledRatesSelected } from '../../../../../store/selectors/result-data.selectors';

@UntilDestroy()
@Component({
	selector: 'app-toggled-coverage',
	templateUrl: './toggled-coverage.component.html',
	styleUrls: ['./toggled-coverage.component.scss'],
})
export class ToggledCoverageComponent implements OnInit {
	@Output() handleToggleEmit = new EventEmitter<boolean>();
	@Input() coverage: CoverageModel;
	@Input() isShortMode = false;
	@Input() isSelected;
	@Input() lob = '';
	@Input() loading: boolean;
	actionApiService: ActionApiService;
	title: string;
	subtitle: string;
	pricePerMonth = pricePerMonth;
	toggleValue: boolean;
	yearlyTermDisplayName = yearlyTermDisplayName;

	constructor(actionApiService: ActionApiService, private store: Store) {
		this.actionApiService = actionApiService;
	}

	ngOnInit(): void {
		this.title = this.isShortMode
			? CARRIERS_TEXTS[this.coverage.carrier].shortTitle
			: CARRIERS_TEXTS[this.coverage.carrier].title;
		this.subtitle = CARRIERS_TEXTS[this.coverage.carrier].subtitle;

		this.toggleValue = this.isSelected;
		this.handleToggleEmit.emit(this.isSelected);

		this.store
			.select(selectToggledRatesSelected)
			.pipe(untilDestroyed(this))
			.subscribe((toggleRatesSelected) => {
				const isSelected = !!toggleRatesSelected.find((toggledRate) => toggledRate.id === this.coverage.id);
				if (isSelected !== this.isSelected) {
					this.handleToggle(isSelected);
				}
			});
	}

	handleToggle(isSelected, shouldEmitEvent?: boolean): void {
		this.isSelected = isSelected;
		this.toggleValue = isSelected;
		this.handleToggleEmit.emit(isSelected);
		if (shouldEmitEvent) {
			this.toggleCoverageState(isSelected);
		}
	}

	toggleCoverageState(isSelected) {
		if (isSelected) {
			this.store.dispatch(addToggledRatesSelected({ rates: [this.coverage] }));
		} else {
			this.store.dispatch(removeToggledRatesSelected({ rates: [this.coverage] }));
		}
	}
}
