import { Fields } from '../../../../../../../../constants/fields';
import { SystemsOptionsGenerate } from '../../../../../../../../utils/systems-options';

export const SYSTEM_UPDATES = [
	{
		...Fields.HeatingUpdateYN,
		options: SystemsOptionsGenerate(25),
	},
	{
		...Fields.ElectricalUpdateYN,
		options: SystemsOptionsGenerate(25),
	},
	{
		...Fields.PlumbingUpdateYN,
		options: SystemsOptionsGenerate(25),
	},
];
