<section class="main-content">
	<app-page-heading [title]="'titles.hasRoofReplaced' | translate"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="question-selector-wrapper">
			<div
				(click)="handleSelect('CompleteUpdate')"
				[ngClass]="{ selected: form.controls.PLRoofUpdated.value === 'CompleteUpdate' }"
				class="option yes-option"
			>
				<label class="inner-wrapper">
					<div class="circle">
						<div class="inner"></div>
					</div>
					<input
						[attr.checked]="form.controls.PLRoofUpdated.value === 'CompleteUpdate' ? true : null"
						class="hidden-radio"
						name="single-yes-no"
						type="radio"
						value="true"
					/>
					<span>{{ "labels.yes" | translate }}</span>
				</label>
				<div
					[appHiddenWhenCssDisabled]="form.controls.PLRoofUpdated.value !== 'CompleteUpdate'"
					class="roof-checkbox-section"
					[ngClass]="{ hidden: form.controls.PLRoofUpdated.value !== 'CompleteUpdate' }"
				>
					<div
						[ngClass]="{
							show: form.get(Fields.RoofUpdatedYear.name) && form.get(Fields.RoofUpdatedYear.name).enabled
						}"
						class="question-wrapper roof-shape field-inline"
					>
						<app-dropdown
							[labelInsideField]="'labels.when' | translate"
							[name]="Fields.RoofUpdatedYear.name"
							[options]="FieldOptions.RoofUpdatedYear"
							[tabindex]="
								form.get(Fields.RoofUpdatedYear.name) && form.get(Fields.RoofUpdatedYear.name).enabled ? 0 : -1
							"
							class="app-dropdown-custom"
						>
						</app-dropdown>
					</div>
				</div>
			</div>

			<div
				(click)="handleSelect('NotUpdated')"
				[ngClass]="{ selected: form.controls.PLRoofUpdated.value === 'NotUpdated' }"
				class="option no-option"
			>
				<label class="inner-wrapper">
					<div class="circle">
						<div class="inner"></div>
					</div>
					<input
						[attr.checked]="form.controls.PLRoofUpdated.value === 'NotUpdated' ? true : null"
						class="hidden-radio"
						name="single-yes-no"
						type="radio"
						value="true"
					/>
					<span>{{ "labels.no" | translate }}</span>
				</label>
			</div>
		</div>
	</form>
	<next-button
		[disabled]="!form.valid"
		[myForm]="{ form: form }"
		[title]="'buttons.continue' | translate"
	></next-button>
</section>
