import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { interval, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounce, distinctUntilChanged, filter } from 'rxjs/operators';

import { BaseFormControlComponent } from '../base-form-control.component';
import { formatNumberWithCommas } from 'src/app/utils/general.utils';
import { IAppState } from 'src/app/store/states/app.state';
import { ControlsType } from 'src/app/constants/controls-type';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'unmasked-input',
	templateUrl: './unmasked-input.component.html',
	styleUrls: ['./unmasked-input.component.scss'],
})
export class UnMaskedInputComponent extends BaseFormControlComponent implements OnInit {
	@Output() sendAddressLookupValue = new EventEmitter<string>();
	@Output() sendAddressValidationValue = new EventEmitter<string>();
	@Input() isCurrency = false;
	@Input() context: any;
	@Input() type: string;
	@Input() secondaryType: string;
	@Input() pattern: string;
	@Input() maxlength: number;
	@Input() minlength: number;
	@Input() DynamicMobileKeyboard: any;
	@Input() mobileKeyboardType: string;
	@Input() title: string;
	@Input() integersLength: number;
	@Input() decimalLength: number;
	@Input() labelInsideField: string;
	@Input() withoutErrorMessage: boolean;
	@Input() restrictDots = false;
	@Input() withCommaFormat = false;
	@Input() autocomplete = true;
	@Input() specialSignWatermark = false;
	@Input() emitKeyupOnBlur = true;
	@Input() disabled;
	@Input() followByDollar = false;
	@Input() notshowLabelWhenFilled: boolean;

	oldValue: any;
	restrictPatternRegex: RegExp;
	addressLookupFilter = new Subject<string>();

	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.controlType = ControlsType.unmaskedInput;
		this.value = this.controlContainer.control.controls[this.name].value;

		// sets comma format
		if (this.secondaryType === 'number' && this.withCommaFormat) {
			this.value = formatNumberWithCommas(this.value, this.maxlength);
		}

		if (this.integersLength && this.decimalLength) {
			this.maxlength =
				!this.value || this.value.length < this.integersLength
					? this.integersLength
					: this.integersLength + this.decimalLength + 1;
		}
		const isZipCode = this.name.indexOf('ZipCode') > -1;
		this.oldValue = this.value;

		if (this.pattern && this.pattern.includes('^[')) {
			this.restrictPatternRegex = new RegExp(this.pattern.replace('^[', '[^'), 'g');
		}

		this.controlContainer.control.controls[this.name].valueChanges.subscribe((value) => {
			this.value =
				this.secondaryType === 'number' && this.withCommaFormat
					? formatNumberWithCommas(this.value, this.maxlength)
					: value;
			// if (this.controlContainer.control.controls[this.name].value) {
			// 	this.controlContainer.control.controls[this.name].value = this.controlContainer.control.controls[
			// 		this.name
			// 	].value
			// 		.toString()
			// 		.trim();
			// }
		});

		if (isZipCode) {
			this.setAddressLookUp();
		}
	}

	setAddressLookUp(): void {
		this.addressLookupFilter
			.pipe(
				debounce(() => interval(500)),
				filter((x) => (x && x.length === 5) || x.length === 0),
				distinctUntilChanged()
			)
			.subscribe((x) => {
				this.sendAddressLookupValue.emit(x);
			});
	}

	onKeyUp(event) {
		if (this.type === 'number' || this.secondaryType === 'number') {
			if (event.currentTarget.value.length > this.maxlength) {
				this.value = event.currentTarget.value.slice(0, this.maxlength);
			}
		}

		let storeVal: any;

		this.value = event.target.value;

		if (event.keyCode === 8 && this.oldValue) {
			if (this.value && this.oldValue.toString().charAt(this.oldValue.length - 2) === '.') {
				this.oldValue = this.oldValue.slice(0, -1);
			} else {
				this.oldValue = this.value;
			}
		}

		if (this.secondaryType === 'number' && !(this.integersLength && this.decimalLength)) {
			this.value = this.value.replace(/[^0-9]/g, '');
		}

		storeVal = this.value;

		this.controlContainer.control.controls[this.name].setValue(storeVal);

		if (this.secondaryType === 'number' && /^\d+$/.test(event.currentTarget.value)) {
			this.value = parseInt(event.currentTarget.value, 10).toString();
		}

		if (this.withCommaFormat) {
			this.value = formatNumberWithCommas(this.value, this.maxlength);
		}

		this.addressLookupFilter.next(event.target.value);
	}

	onBlur(event) {
		super.baseOnBlur();
		if (this.emitKeyupOnBlur) {
			this.onKeyUp(event);
		}
		const field = this.controlContainer.control.controls[this.name];
		field.markAsTouched();
		if (this.name.indexOf('AddressLine1') > -1) {
			this.sendAddressValidationValue.emit(field.value);
		}
	}

	onFocus() {
		super.baseOnFocus();
	}

	isInvalid() {
		const field = this.controlContainer.control.controls[this.name];
		if (!field) {
			return false;
		}
		return field.invalid && field.touched;
	}

	setValue(): void {
		if (this.restrictPatternRegex) {
			if (this.controlContainer.control.controls[this.name].value) {
				this.controlContainer.control.controls[this.name].value = this.controlContainer.control.controls[
					this.name
				].value
					.toString()
					.replaceAll(this.restrictPatternRegex, '');
			}
		}

		if (this.integersLength && this.decimalLength) {
			const controlVal = this.controlContainer.control.controls[this.name].value;

			if (controlVal && typeof controlVal === 'string') {
				if (this.oldValue.indexOf('.') > -1 && this.oldValue.indexOf('.') !== this.oldValue.length - 1) {
					this.controlContainer.control.controls[this.name].value = this.oldValue;
				}
			}
		}
		this.value = this.controlContainer.control.controls[this.name].value;
	}
}
