<div class="container">
	<div class="phone-number">
		<a
			[eventData]="{
				action: eventActions.BUTTON_CLICK,
				label: 'Header.PhoneNumber',
				button: 'phone',
				modal: 'header'
			}"
			appClickEvent
			class="phone-icon"
			href="tel:{{ phoneNumber }}"
			tabindex="0"
		>
			<app-image alt="null" height="33" name="chat" role="presentation"></app-image>
		</a>
		<div class="texts-container">
			<a
				[eventData]="{
					action: eventActions.BUTTON_CLICK,
					label: 'Header.PhoneNumber',
					button: 'phone',
					modal: 'header'
				}"
				appClickEvent
				class="phone-number-digits"
				href="tel:{{ phoneNumber }}"
				tabindex="0"
				>{{ phoneNumber }}</a
			>
			<span *ngIf="workingHours">{{ workingHours | slice: 0:100 }}</span>
		</div>
	</div>
</div>
