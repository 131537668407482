import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-discounts',
	templateUrl: './discounts.component.html',
	styleUrls: ['./discounts.component.scss'],
})
export class DiscountsComponent {
	@Input() discounts: { name: string }[];

	formatDiscount(discount: string) {
		const arrayOfWords = discount
			.replace('Discount', '')
			.replace(/([A-Z])/g, ' $1')
			.split(' ')
			.map((word) => {
				if (['of', 'and', 'or', 'in', 'my'].includes(word.toLowerCase())) {
					return word.toLowerCase();
				}

				return word.charAt(0).toUpperCase() + word.slice(1);
			});

		return arrayOfWords.join(' ');
	}
}
