<app-label
	*ngIf="label"
	[label]="label"
	[name]="this.name"
	[popupLink]="popupLink"
	[subLabel]="subLabel"
	[tabindex]="tabindex ? tabindex : 0"
>
</app-label>
<div [ngClass]="{ 'has-value': value || (isFocus$ | async) }" class="input-container">
	<form #maskedInputForm [formGroup]="controlContainer.control" [ngClass]="{ error: error, disabled: disabled }">
		<label [for]="name" class="inner-label" *ngIf="!notshowLabelWhenFilled">
			{{ placeholder }}
		</label>

		<input
			(blur)="updateAnswer($event, true)"
			(click)="setCarret($event)"
			(focus)="unSelectText()"
			(keyup.enter)="keypadClose()"
			(ngModelChange)="updateAnswer($event, false)"
			(touched)="setCarret($event)"
			[attr.aria-describedby]="error ? describedByErrorId : null"
			[attr.aria-invalid]="!!error ? true : null"
			[attr.id]="name"
			[attr.inputmode]="inputMode"
			[attr.name]="name"
			[attr.pattern]="inputPattern"
			[formControlName]="name"
			[ngClass]="{
				textcolor: value && value.length > 0 && !hideTextColor,
				filled: value,
				empty: !value,
				focus: (isFocus$ | async),
				notshowLabelWhenFilled: notshowLabelWhenFilled
			}"
			[ngModel]="controlContainer.control.controls[this.name].value"
			[tabindex]="tabindex ? tabindex : 0"
			[textMask]="maskConfigs()"
			[type]="type || 'text'"
			autocomplete="off"
			class="masked-input"
			name="maskedInput"
		/>

		<div *ngIf="error" class="error-container">
			<div class="error">
				<i class="error-icon"></i>
			</div>
		</div>
	</form>
</div>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || name"
	[field]="controlContainer.control.controls[this.name]"
	[isFocus$]="isFocus$"
	showIconError="true"
>
</error-message>
