<section class="main-content">
	<ng-container *ngIf="isMedia && mediaPosition === 'resMediaBeforeTS'">
		<ng-container *ngTemplateOutlet="media"></ng-container>
	</ng-container>

	<ng-container *ngIf="loaderInformation$ | async as loaderData">
		<ng-container *ngIf="loaderData?.visible">
			<app-loader></app-loader>
			<app-page-heading subtitleKey="result-loader.subTitle" titleKey="result-loader.title"></app-page-heading>
			<br />
			<br />
		</ng-container>

		<div *ngIf="!loaderData.visible && isRatesLazyLoading && !noRates && !anchorCarrier" class="title-container">
			<app-page-heading
				[subtitleKey]="isOem ? 'rates.oemSubtitle' : 'rates.subTitle'"
				[titleKey]="isOem ? 'rates.oemTitle' : 'rates.title'"
			></app-page-heading>
		</div>
	</ng-container>

	<ng-container *ngIf="!isAnchorCarrier && isMedia && mediaPosition === 'resMediaAfterTS'">
		<ng-container *ngTemplateOutlet="media"></ng-container>
	</ng-container>

	<app-anchor-carrier *ngIf="isAnchorCarrier && anchorCarrier" [anchorCarrier]="anchorCarrier"></app-anchor-carrier>

	<ng-container *ngIf="isRatesLazyLoading && !anchorCarrier">
		<div *ngIf="isAdditionalCoveragesActive && isFetchingResultsCompleted && !noRates" class="additional-coverages">
			<div class="text-wrapper">
				<ng-container *ngIf="this.additionalCoveragesIncluded.length; else no_additional_coverages">
					<h3 appTranslation="rates.addCovTitle"></h3>
					<p>
						<span appTranslation="rates.addCovSubtitle"></span>
						<button
							[attr.aria-label]="this.tooltipAdditionalCoveragesText"
							[matTooltip]="this.tooltipAdditionalCoveragesText"
							appHiddenWhenCssDisabled
							appToggleTooltip
							class="help-icon material-icons"
						>
							<span aria-hidden="true">help_outline</span>
						</button>
					</p>
				</ng-container>
				<ng-template #no_additional_coverages>
					<p>{{ "labels.standardCoverage" | translate }}</p>
					<p>{{ "labels.noAdditionalCoverages" | translate }}</p>
				</ng-template>
			</div>
			<button (click)="handleEditCoverages()" class="edit-coverages-button">
				<i class="material-icons">edit</i>
				<span appTranslation="rates.addCovButton"></span>
			</button>
		</div>

		<div *ngIf="!noRates && isMultiLob" class="coverages-container">
			<mat-tab-group
				(selectedTabChange)="onMultilobTabChange()"
				*ngIf="rates?.ratesByBundle && rates?.ratesByBundle.length; else buySeparatelyNoTabs"
				animationDuration="0ms"
			>
				<mat-tab [label]="'labels.bundleAndSave' | translate">
					<div class="multilob-group">
						<ul [attr.aria-label]="'labels.bundleQuotes' | translate" class="coverages">
							<li *ngFor="let bundle of rates?.ratesByBundle">
								<ng-container *ngIf="loaderInformation$ | async as loaderData">
									<app-coverage
										(buttonClicked)="chooseMultiLobBundle(bundle.rates, true)"
										[attr.carrier-name-automation]="bundle.carrier"
										[buttonText]="'buttons.viewQuote' | translate"
										[carrier]="bundle.carrier"
										[isMonthly]="true"
										[isOffline]="true"
										[loading]="loaderData.visible"
										[lob]="bundle.lobs[0]"
										[premium]="bundle.premium * 12"
										[term]="'TwelveMonths'"
									>
									</app-coverage>
								</ng-container>
							</li>
						</ul>
					</div>
				</mat-tab>

				<mat-tab [label]="'labels.buySeparately' | translate">
					<ng-container *ngTemplateOutlet="buySeparatelyNoTabs"></ng-container>
				</mat-tab>
			</mat-tab-group>
		</div>

		<ng-container *ngIf="!noRates">
			<div *ngIf="!isMultiLob" class="coverages-container">
				<ng-container
					*ngIf="
						!(
							this.lobSelection[0] == LobsEnum.PERSONAL_HOME &&
							isAdditionalCoveragesActive &&
							(this.getAllFullAdditionalCoverages()?.length || this.getSomeAdditionalCoverages()?.length) &&
							this.additionalCoveragesIncluded.length
						);
						else AdditionalCoveragesMode
					"
				>
					<div *ngIf="isOem && rates?.rates?.oem?.length" class="rate-group-title">
						<span appTranslation="rates.verifiedTitle"></span>
						<button (click)="showOemInfo()" class="tooltip-btn type-1">
							<i class="material-icons"> info_outline </i> <span appTranslation="rates.verifiedTtTitle"></span>
						</button>
					</div>

					<ul [attr.aria-label]="'labels.quotes' | translate">
						<ng-container *ngIf="rates?.rates?.oem?.length">
							<li *ngFor="let rate of rates?.rates?.oem">
								<ng-container *ngIf="loaderInformation$ | async as loaderData">
									<app-coverage
										(buttonClicked)="chooseRate(rate, rate.isOffline)"
										[attr.carrier-name-automation]="rate.carrier"
										[buttonText]="rate.isOffline ? ('buttons.viewQuote' | translate) : ('buttons.buyNow' | translate)"
										[carrier]="rate.carrier"
										[customLogo]="rate.customLogo"
										[isInEligible]="rate.isInEligible"
										[isMonthly]="isMonthly"
										[isOffline]="rate.isOffline"
										[loading]="loaderData.visible"
										[lob]="this.lob"
										[premium]="rate.premium"
										[term]="rate.term"
									>
									</app-coverage>
								</ng-container>
							</li>
						</ng-container>

						<ng-container *ngIf="rates?.rates?.oemOffline?.length">
							<li *ngFor="let rate of rates?.rates?.oemOffline">
								<ng-container *ngIf="loaderInformation$ | async as loaderData">
									<app-coverage
										(buttonClicked)="chooseRate(rate, rate.isOffline)"
										[attr.carrier-name-automation]="rate.carrier"
										[buttonText]="rate.isOffline ? ('buttons.viewQuote' | translate) : ('buttons.buyNow' | translate)"
										[carrier]="rate.carrier"
										[customLogo]="rate.customLogo"
										[isInEligible]="rate.isInEligible"
										[isMonthly]="isMonthly"
										[isOffline]="rate.isOffline"
										[loading]="loaderData.visible"
										[lob]="this.lob"
										[premium]="rate.premium"
										[term]="rate.term"
									>
									</app-coverage>
								</ng-container>
							</li>
						</ng-container>
					</ul>

					<button
						(click)="loadAlternativeCarriers()"
						*ngIf="
							isOem &&
							!isOnlineRatesVisible &&
							(rates?.rates?.online?.length || rates?.rates?.offline?.length) &&
							isNotOemResultsLazyLoading
						"
						class="load-alt-carriers-btn app-button bordered"
					>
						<span appTranslation="rates.loadAltCarriersButton" class="title"> </span>
					</button>

					<ng-container *ngIf="isOnlineRatesVisible">
						<div
							*ngIf="isOem && (rates?.rates?.online?.length || rates?.rates?.offline?.length)"
							class="rate-group-title"
						>
							<span appTranslation="rates.alternativeTitle"></span>
							<button (click)="showNonOemInfo()" class="tooltip-btn type-2">
								<i class="material-icons"> info_outline </i> <span appTranslation="rates.alternativeTtTitle"></span>
							</button>
						</div>

						<ul [attr.aria-label]="'labels.quotes' | translate">
							<ng-container *ngFor="let rate of rates?.rates.online">
								<li *ngIf="rate.isVisible">
									<ng-container *ngIf="loaderInformation$ | async as loaderData">
										<app-coverage
											(buttonClicked)="chooseRate(rate)"
											[attr.carrier-name-automation]="rate.carrier"
											[buttonText]="'buttons.buyNow' | translate"
											[carrier]="rate.carrier"
											[customLogo]="rate.customLogo"
											[isInEligible]="rate.isInEligible"
											[isMonthly]="isMonthly"
											[loading]="loaderData.visible"
											[lob]="this.lob"
											[premium]="rate.premium"
											[term]="rate.term"
										>
										</app-coverage>
									</ng-container>
								</li>
							</ng-container>

							<ng-container *ngFor="let rate of rates?.rates.offline">
								<li>
									<ng-container *ngIf="loaderInformation$ | async as loaderData">
										<app-coverage
											(buttonClicked)="chooseRate(rate, true)"
											[attr.carrier-name-automation]="rate.carrier"
											[buttonText]="'buttons.viewQuote' | translate"
											[carrier]="rate.carrier"
											[customLogo]="rate.customLogo"
											[isInEligible]="rate.isInEligible"
											[isMonthly]="isMonthly"
											[isOffline]="true"
											[loading]="loaderData.visible"
											[lob]="this.lob"
											[premium]="rate.premium"
											[term]="rate.term"
										>
										</app-coverage>
									</ng-container>
								</li>
							</ng-container>

							<ng-container *ngIf="rates?.rates.offline?.length || rates?.rates.online?.length">
								<li *ngFor="let rate of rates?.rates.toggled">
									<ng-container *ngIf="loaderInformation$ | async as loaderData">
										<app-toggled-coverage
											(handleToggleEmit)="handleToggledRate($event, rate)"
											[coverage]="rate"
											[isSelected]="rate.isSelected"
											[loading]="loaderData.visible"
											[lob]="this.lob"
										></app-toggled-coverage>
									</ng-container>
								</li>
							</ng-container>
						</ul>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>

		<app-no-rates-error *ngIf="noRates" [showFriendlyId]="false"></app-no-rates-error>

		<ng-container *ngIf="isMedia && mediaPosition === 'resMediaAfterC'">
			<ng-container *ngTemplateOutlet="media"></ng-container>
		</ng-container>
	</ng-container>
</section>

<ng-template #media>
	<div *ngIf="isMedia" class="media-section">
		<div *ngIf="url" class="video-section">
			<ng-template #videoTemplate let-posterSrc="posterSrc">
				<video
					[autoplay]="autoplay"
					[poster]="posterSrc"
					[preload]="'auto'"
					[src]="url | safe: 'resourceUrl'"
					[volume]="initialVolume"
					controls
					playsinline
					style="width: 100%; height: 100%; z-index: 10002"
				></video>
			</ng-template>

			<ng-container *ngIf="isVideoPosterExists; else withoutPoster">
				<ng-container *ngIf="videoPosterSrc$ | async as posterSrc">
					<ng-container
						[ngTemplateOutletContext]="{ posterSrc: posterSrc }"
						[ngTemplateOutlet]="videoTemplate"
					></ng-container>
				</ng-container>
			</ng-container>

			<ng-template #withoutPoster>
				<ng-container [ngTemplateOutletContext]="{ posterSrc: null }" [ngTemplateOutlet]="videoTemplate"></ng-container>
			</ng-template>
		</div>
		<div *ngIf="!url && image" class="image-section">
			<app-image [alt]="mediaAlt" name="ratesImage" role="presentation"></app-image>
		</div>
	</div>
</ng-template>

<ng-template #AdditionalCoveragesMode>
	<ng-container *ngIf="this.getAllFullAdditionalCoverages()?.length; else noFull">
		<p class="section-header-additional-cov">
			{{ "labels.results" | translate }} {{ " " }} ({{ this.getAllFullAdditionalCoverages()?.length }})
		</p>
		<ul [attr.aria-label]="'labels.quotes' | translate">
			<ng-container *ngFor="let rate of this.getAllFullAdditionalCoverages()">
				<li *ngIf="rate.isVisible">
					<ng-container *ngIf="loaderInformation$ | async as loaderData">
						<app-coverage
							(buttonClicked)="chooseRate(rate, rate.consumerBindType == 'CallAgent')"
							[attr.carrier-name-automation]="rate.carrier"
							[buttonText]="rate.isOnline ? ('buttons.buyNow' | translate) : ('buttons.viewQuote' | translate)"
							[carrier]="rate.carrier"
							[customLogo]="rate.customLogo"
							[isInEligible]="rate.isInEligible"
							[isMonthly]="isMonthly"
							[isOffline]="!rate.isOnline"
							[loading]="loaderData.visible"
							[lob]="this.lob"
							[premium]="rate.premium"
							[term]="rate.term"
						>
						</app-coverage>
					</ng-container>
				</li>
			</ng-container>
		</ul>
	</ng-container>
	<ng-template #noFull><p appTranslation="rates.coveragesMatchNotFound" class="no-full"></p></ng-template>

	<ng-container *ngIf="this.getSomeAdditionalCoverages()?.length">
		<div class="show-more-results-header">
			<button (click)="handleShowMore()" *ngIf="!showMore; else moreResults" class="show-more-results-button">
				{{ "buttons.loadAlternativeCoverage" | translate }}
				<button
					[attr.aria-label]="'labels.browseRangeQuotes' | translate"
					[matTooltip]="'labels.browseRangeQuotes' | translate"
					appHiddenWhenCssDisabled
					appToggleTooltip
					class="help-icon material-icons"
				>
					<span aria-hidden="true">help_outline</span>
				</button>
			</button>
			<ng-template #moreResults>
				<p class="section-header-additional-cov">
					{{ "labels.moreResults" | translate }} {{ " " }} ({{ this.getSomeAdditionalCoverages()?.length }})
					<button
						[attr.aria-label]="'labels.browseRangeQuotes' | translate"
						[matTooltip]="'labels.browseRangeQuotes' | translate"
						appHiddenWhenCssDisabled
						appToggleTooltip
						class="help-icon material-icons"
					>
						<span aria-hidden="true">help_outline</span>
					</button>
				</p>
			</ng-template>
		</div>
		<ul [attr.aria-label]="'labels.quotes' | translate">
			<div
				*ngFor="let rate of this.getSomeAdditionalCoverages()"
				[ngClass]="{ showSomeAdditional: showMore }"
				class="notShowSomeAdditonalCoverages"
			>
				<li *ngIf="rate.isVisible">
					<ng-container *ngIf="loaderInformation$ | async as loaderData">
						<app-coverage
							(buttonClicked)="chooseRate(rate, rate.consumerBindType == 'CallAgent')"
							[attr.carrier-name-automation]="rate.carrier"
							[buttonText]="rate.isOnline ? ('buttons.buyNow' | translate) : ('buttons.viewQuote' | translate)"
							[carrier]="rate.carrier"
							[customLogo]="rate.customLogo"
							[isInEligible]="rate.isInEligible"
							[isMonthly]="isMonthly"
							[isOffline]="!rate.isOnline"
							[loading]="loaderData.visible"
							[lob]="this.lob"
							[premium]="rate.premium"
							[term]="rate.term"
						>
						</app-coverage>
					</ng-container>
				</li>
			</div>
		</ul>
	</ng-container>

	<!-- Flood -->
	<ng-container *ngIf="rates?.rates.offline?.length || rates?.rates.online?.length">
		<ng-container *ngFor="let rate of rates?.rates.toggled">
			<ng-container *ngIf="loaderInformation$ | async as loaderData">
				<app-toggled-coverage
					(handleToggleEmit)="handleToggledRate($event, rate)"
					[coverage]="rate"
					[isSelected]="rate.isSelected"
					[loading]="loaderData.visible"
					[lob]="this.lob"
				></app-toggled-coverage>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #buySeparatelyNoTabs>
	<ng-container *ngFor="let lobGroup of rates?.ratesByLob">
		<ul *ngIf="lobGroup.rates.length || isFetchingResultsCompleted" [attr.aria-label]="'labels.quotes' | translate">
			<li class="multilob-group">
				<button (click)="toggleGroup(lobGroup)" class="group-title">
					{{ "lobs." + lobGroup.lob | translate }}
					<span *ngIf="lobGroup.rates.length === 0" class="no-quotes">
						&nbsp;{{ "labels.noQuotesReturned" | translate }} {{ " " }}</span
					>
					<span *ngIf="getLobGroupSelectedRateCarrierName(lobGroup.lob)" class="checked-carrier">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">check</i>
						{{ getLobGroupSelectedRateCarrierName(lobGroup.lob) }}
					</span>
					<i
						*ngIf="lobGroup.rates.length !== 0"
						appHiddenWhenCssDisabled
						aria-hidden="true"
						class="material-icons edit"
						role="presentation"
					>
						{{ lobGroup.expanded ? "expand_less" : "expand_more" }}
					</i>
				</button>

				<ul [attr.aria-label]="'labels.quotes' | translate" [class.collapsed]="!lobGroup.expanded" class="coverages">
					<li *ngFor="let rate of lobGroup.rates">
						<ng-container *ngIf="loaderInformation$ | async as loaderData">
							<app-coverage
								(buttonClicked)="chooseMultiLobSeparateRate(rate, true)"
								[attr.carrier-name-automation]="rate.carrier"
								[buttonText]="'buttons.viewQuote' | translate"
								[carrier]="rate.carrier"
								[isInEligible]="rate.isInEligible"
								[isMonthly]="isMonthly"
								[isOffline]="true"
								[loading]="loaderData.visible"
								[lob]="lobGroup.lob"
								[premium]="rate.premium"
								[term]="rate.term"
							>
							</app-coverage>
						</ng-container>
					</li>
				</ul>
			</li>
		</ul>
	</ng-container>
</ng-template>
