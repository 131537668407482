import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BasePageComponent } from '../../base-page.component';
import { Fields } from '../../../../../constants/fields';
import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { EventActions } from '../../../../../models/event-data.model';
import { ActionApiService } from 'src/app/services/action-api.service';
import { PrefillDefaultsService } from 'src/app/services/prefill-defaults.service';
import { DriversService } from '../../../../../services/drivers.service';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { selectInterviewTexts } from 'src/app/store/selectors/interview-texts.selectors';
import { LobsEnum } from '../../../../../enums/lobs.enum';
import { UpsellBoxComponent } from 'src/app/components/shared/upsell-box/components/upsell-box/upsell-box.component';
import { LobService } from '../../../../../services/lob.service';
import { IServerRes } from '../../../../../../entities/payload.interface';
import { NextButtonComponent } from '../../../../next-button/next-button.component';
import { LoaderActions } from 'src/app/store/actions';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { IndustryModel } from 'src/entities/industry-api-response.interface';
import { Dictionary } from 'src/entities/key-value.interface';
import { toLowerCaseOccupation } from 'src/app/utils/general.utils';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';

@Component({
	selector: 'app-primary-driver',
	templateUrl: './primary-driver.component.html',
	styleUrls: ['./primary-driver.component.scss'],
})
export class PrimaryDriverComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@ViewChild(UpsellBoxComponent) upsellBoxComponent;
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	prefillService: PrefillDefaultsService;
	lastName: string;
	firstName: string;

	phoneNumberMask = '999-999-9999';
	isAgreementEnabled = true;
	isLegalConsentEnabled = false;
	eventActions = EventActions;
	upsellLob = LobsEnum.PERSONAL_HOME;
	upsellSelected = false;
	LobsEnum = LobsEnum;
	DriverEmploymentIndustryEmployedOptions: IDropdownOption[];
	industries: IndustryModel[];
	occupationsOptions: IDropdownOption[];
	currentPage: number = 1;
	isMultiLob = false;


	private fieldsFirstStep = [
		Fields.FirstName.name,
		Fields.LastName.name,
		Fields.DateOfBirth.name,
		Fields.PersonalLineGender.name,
		Fields.MaritalStatus.name,
		Fields.DriverLicenseStatus.name,
		Fields.TypeOfResidence.name,
	];

	constructor(
		actionApiService: ActionApiService,
		prefillService: PrefillDefaultsService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private driversService: DriversService,
		private lobService: LobService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
		this.prefillService = prefillService;
	}

	get customFields(): FormGroup {
		return this.form.get('CustomFields') as FormGroup;
	}

	get legalConsentControl(): FormControl {
		return this.customFields.get(this.Fields.D2CAgreeToTerms.name) as FormControl;
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();
		this.initIndustries();

		setTimeout(() => {
			this.patchData(this.form);
			this.customPatchData();
			this.registerOnChange(this.form);
		});

		this.store
		.select(selectInterviewMetadata)
		.pipe(first())
		.subscribe((interviewMetadata) => {
			this.isMultiLob = interviewMetadata.isMultiLob;
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((res) => {
				this.firstName = res.FirstName;
				this.lastName = res.LastName;
			});

		this.store
			.select(selectInterviewTexts)
			.pipe(
				untilDestroyed(this),
				map((stepTexts) => stepTexts['tspa'])
			)
			.subscribe((tspa) => {
				this.isAgreementEnabled = tspa.tcpaOn;
				this.isLegalConsentEnabled = tspa.legalConsentOn;

				this.Fields.IAgreeToReceiveEmailsByBolt = {
					...this.Fields.IAgreeToReceiveEmailsByBolt,
					labelKey: 'tspa.tcpaText',
				};

				if (this.isLegalConsentEnabled && tspa.legalConsent.length) {
					this.Fields.D2CAgreeToTerms = {
						...this.Fields.D2CAgreeToTerms,
						labelKey: 'tspa.legalConsent',
					};

					this.form.addControl(
						'CustomFields',
						new FormGroup({
							[this.Fields.D2CAgreeToTerms.name]: new FormControl({ value: null, disabled: false }, [
								Validators.requiredTrue,
							]),
						})
					);

					setTimeout(() => {
						if (
							this.quoteData.hasOwnProperty('CustomFields') &&
							this.quoteData.CustomFields?.hasOwnProperty(this.Fields.D2CAgreeToTerms.name)
						) {
							this.legalConsentControl.setValue(
								this.quoteData.CustomFields[this.Fields.D2CAgreeToTerms.name] === 'True' ||
									this.quoteData.CustomFields[this.Fields.D2CAgreeToTerms.name]
							);
						}
					});
				}
			});
	}

	customPatchData() {
		this.form.patchValue(
			{
				[Fields.DriverLicenseStatus.name]:
					this.quoteData?.Drivers[0]?.DriverLicenseStatus || this.quoteData.DriverLicenseStatus
						? this.quoteData?.Drivers[0]?.DriverLicenseStatus || this.quoteData.DriverLicenseStatus
						: null,
				[Fields.MaritalStatus.name]:
					this.quoteData?.Drivers[0]?.MaritalStatus || this.quoteData.MaritalStatus
						? this.quoteData?.Drivers[0]?.MaritalStatus || this.quoteData.MaritalStatus
						: null,
				[Fields.PersonalLineGender.name]:
					this.quoteData?.Drivers[0]?.Gender || this.quoteData.PersonalLineGender
						? this.quoteData?.Drivers[0]?.Gender || this.quoteData.PersonalLineGender
						: null,
				[Fields.DriverEmploymentIndustry.name]:
					this.quoteData?.Drivers[0]?.DriverEmploymentIndustry || this.quoteData.DriverEmploymentIndustry
						? this.quoteData?.Drivers[0]?.DriverEmploymentIndustry || this.quoteData.DriverEmploymentIndustry
						: null,
				[Fields.DriverOccupationStr.name]:
					this.quoteData?.Drivers[0]?.DriverOccupationStr || this.quoteData.DriverOccupationStr
						? this.quoteData?.Drivers[0]?.DriverOccupationStr || this.quoteData.DriverOccupationStr
						: null,
				[Fields.DriverEducation.name]:
					this.quoteData?.Drivers[0]?.DriverEducation || this.quoteData.DriverEducation
						? this.quoteData?.Drivers[0]?.DriverEducation || this.quoteData.DriverEducation
						: null,
			},
			{ onlySelf: true, emitEvent: false }
		);
	}

	initform(): void {
		this.form = new FormGroup({});

		this.form.addControl(
			Fields.FirstName.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(50),
				Validations.firstNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.LastName.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(30),
				Validations.lastNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.PersonalLineGender.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.MaritalStatus.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverLicenseStatus.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validations.LicenseStatusForPrimaryDriver,
			])
		);

		this.form.addControl(
			Fields.TypeOfResidence.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.Email.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(60),
				Validations.emailValidAuto,
			])
		);

		this.form.addControl(
			Fields.PrimaryPhoneNumber.name,
			new FormControl({ value: null, disabled: false }, [Validators.required, Validations.validPhoneNumberAuto])
		);

		this.form.addControl(
			Fields.DateOfBirth.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validations.isDOBpastDate,
				Validations.dateFormat,
				Validations.isMoreThan16,
				Validations.isEqulesOrAfter1920,
				Validations.unmaskedDOB,
			])
		);

		this.form.addControl(
			Fields.DriverEmploymentIndustry.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverOccupationStr.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverEducation.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.controls.DriverEmploymentIndustry?.valueChanges.subscribe((currentIndustry) => {
			if (!currentIndustry) {
				this.occupationsOptions = [];
			} else {
				this.form.controls.DriverOccupationStr.setValue(null);
				this.form.controls.DriverOccupationStr.markAsUntouched();
				this.occupationsOptions = this.mapToDropdownValues(
					this.industries?.find((i) => i.key === currentIndustry).occupations
				);
			}
		});

		this.form.addControl('IAgreeToReceiveEmailsByBolt', new FormControl({ value: null, disabled: false }));

		this.form.controls[Fields.TypeOfResidence.name].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			this.upsellLob = value === 'OwnHome' ? LobsEnum.PERSONAL_HOME : LobsEnum.CONDOMINIUM;
			setTimeout(() => {
				this.upsellBoxComponent?.toggleUpsellBoxVisibility(value === 'OwnHome' || value === 'OwnCondo');
			}, 0);
		});
	}

	handleContinue() {
		this.store.dispatch(LoaderActions.ShowLoader());

		const formValue: any = { ...this.form.value };

		if (formValue.TypeOfResidence === 'OwnHome' || formValue.TypeOfResidence === 'OwnCondo') {
			formValue.PLTypeOfDwelling = formValue.TypeOfResidence === 'OwnHome' ? 'SingleFamilyHouse' : 'Condominium';
		}

		if (this.firstName !== formValue.FirstName || this.lastName !== formValue.LastName) {
			this.store.dispatch(UpdateInterviewMetadata({ data: { prefillCall: true } }));
		}

		this.driversService
			.updatePolicyInfoWithInitialDriversData(this.getUpdatedFormValue(formValue), this.getUpdatedLobsList())
			.pipe(first())
			.subscribe((serverRes: IServerRes) => {
				this.onUpdateApplicationResult(serverRes);
				this.upsellBoxComponent?.updateFlowType();
			});
	}

	onUpsellToggled(upsellSelected: boolean) {
		this.upsellSelected = upsellSelected;
	}

	showCrossSellLayout() {
		let featureIsOn = this.d2CFeatures?.crossSell[LobsEnum.PERSONAL_AUTO]?.indexOf(this.upsellLob) > -1;

		let val = this.form.controls[Fields.TypeOfResidence.name].value;
		return (val === 'OwnHome' || val === 'OwnCondo') && featureIsOn && !this.isMultiLob;
	}

	private getUpdatedFormValue(formValue: any) {
		const updatedFormValue = formValue;
		if (this.upsellBoxComponent?.featureOn && this.upsellBoxComponent?.isSelected) {
			if (this.upsellLob === LobsEnum.PERSONAL_HOME || this.upsellLob === LobsEnum.CONDOMINIUM) {
				updatedFormValue.OccupancyType = 'OwnerPrimary';
			}
		}
		return updatedFormValue;
	}

	private populateHomeFieldsFromPrimaryDriverData() {
		if (this.upsellLob === LobsEnum.PERSONAL_HOME || this.upsellLob === LobsEnum.CONDOMINIUM) {
			const value =
				this.form.value[Fields.TypeOfResidence.name] === 'OwnHome' ? LobsEnum.PERSONAL_HOME : LobsEnum.CONDOMINIUM;
			this.store.dispatch(UpdateInterviewMetadata({ data: { _typeProperty: value } }));
		}
	}

	private getUpdatedLobsList() {
		// should return lobs for re-init only if upsell feature is ON and the list of lobs has changed
		// (checked in this.lobService.checkIsLobDifferent(...))
		let selectedLobs = [LobsEnum.PERSONAL_AUTO];

		if (this.upsellBoxComponent?.featureOn) {
			const typeOfResidence = this.form.value[Fields.TypeOfResidence.name];

			if (this.upsellBoxComponent?.isSelected) {
				if (
					(this.upsellLob === LobsEnum.PERSONAL_HOME || this.upsellLob === LobsEnum.CONDOMINIUM) &&
					(typeOfResidence === 'OwnHome' || typeOfResidence === 'OwnCondo')
				) {
					selectedLobs.push(typeOfResidence === 'OwnHome' ? LobsEnum.PERSONAL_HOME : LobsEnum.CONDOMINIUM);
					this.populateHomeFieldsFromPrimaryDriverData();
					return selectedLobs;
				}
			} else {
				// if upsell NOT selected, but the feature is enabled and checkIsLobDifferent is true, it means that the quote was previously initialized
				// with an upsell lob and needs to be re-initialized without it.
				return selectedLobs;
			}
		} else {
			//check if it is multi-lob
			if (this.isMultiLob) {
				selectedLobs.push(LobsEnum.PERSONAL_HOME);
			}
			// when cross sell feature off remove the cross sell lob
			return selectedLobs;
		}
	}

	goToNextPage(): void {
		window.scroll({
			top: 0,
		});
		this.currentPage++;
	}

	goToPreviousPage(): void {
		window.scroll({
			top: 0,
		});
		if (this.currentPage > 1) {
			this.currentPage--;
		}
	}

	private initIndustries(): void {
		this.actionApiService
			.getIndustriesAndOccupations()
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.industries = data.industries;

				if (this.industries) {
					this.DriverEmploymentIndustryEmployedOptions = this.mapToDropdownValues(
						this.industries.map((i) => ({ key: i.key, value: i.value }))
					);
				}

				if (this.form.controls.DriverEmploymentIndustry.value) {
					this.occupationsOptions = this.mapToDropdownValues(
						this.industries?.find((i) => i.key === this.form.controls.DriverEmploymentIndustry.value).occupations
					);
				}
			});
	}

	private mapToDropdownValues(dataArray: Dictionary<string>[]): IDropdownOption[] {
		if (dataArray) {
			return dataArray.map(
				(data, index) =>
					({
						id: ++index,
						title: toLowerCaseOccupation(data.value),
						value: data.key,
					} as IDropdownOption)
			);
		}
	}

	checkFieldsFirstStepValidity(): boolean {
		for (const fieldName of this.fieldsFirstStep) {
			const field = this.form.get(fieldName);
			if (!field || !field.valid) {
				return false;
			}
		}
		return true;
	}
}
