import { LobsEnum } from '../../../../enums/lobs.enum';

export const COVERAGES_TEXTS = {
	// Personal Auto Data
	[LobsEnum.PERSONAL_AUTO]: {
		Liab_CSL_BI_Limit: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Liab_CSL_BI_Limit.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Liab_CSL_BI_Limit.0.displayName',
			},
		],
		Liab_PD_Limit: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Liab_PD_Limit.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Liab_PD_Limit.0.displayName',
			},
		],
		StateSpecific_PipLimit: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_PipLimit.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_PipLimit.0.displayName',
			},
		],
		StateSpecific_PIPDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_PIPDeductible.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_PIPDeductible.0.displayName',
			},
		],
		StateSpecific_PIPStacking: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_PIPStacking.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_PIPStacking.0.displayName',
			},
		],
		StateSpecific_PIPoption: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_PIPoption.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_PIPoption.0.displayName',
			},
		],
		MedicalPayments: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.MedicalPayments.0.description',
				displayName: 'coveragesTexts.PersonalAuto.MedicalPayments.0.displayName',
			},
		],
		UninsuredMotoristsbodilyinjury: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.UninsuredMotoristsbodilyinjury.0.description',
				displayName: 'coveragesTexts.PersonalAuto.UninsuredMotoristsbodilyinjury.0.displayName',
			},
		],
		UnderInsuredMotorists: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.UnderInsuredMotorists.0.description',
				displayName: 'coveragesTexts.PersonalAuto.UnderInsuredMotorists.0.displayName',
			},
		],
		ComprehensiveDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.ComprehensiveDeductible.0.description',
				displayName: 'coveragesTexts.PersonalAuto.ComprehensiveDeductible.0.displayName',
			},
		],
		CollisionDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.CollisionDeductible.0.description',
				displayName: 'coveragesTexts.PersonalAuto.CollisionDeductible.0.displayName',
			},
		],
		Towing_AND_Labor: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Towing_AND_Labor.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Towing_AND_Labor.0.displayName',
			},
		],
		Transportation_Expense: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Transportation_Expense.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Transportation_Expense.0.displayName',
			},
		],
		Personalinjuryprotection: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Personalinjuryprotection.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Personalinjuryprotection.0.displayName',
			},
		],
		Uninsured_Motorists: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Uninsured_Motorists.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Uninsured_Motorists.0.displayName',
			},
		],
		StateSpecific_UIMPD: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_UIMPD.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_UIMPD.0.displayName',
			},
		],
		StateSpecific_Umoption: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Umoption.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Umoption.0.displayName',
			},
		],
		Uninsured_Underinsured_Motorists: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Uninsured_Underinsured_Motorists.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Uninsured_Underinsured_Motorists.0.displayName',
			},
		],
		SelectPriorLiabilityLimitsAuto: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.SelectPriorLiabilityLimitsAuto.0.description',
				displayName: 'coveragesTexts.PersonalAuto.SelectPriorLiabilityLimitsAuto.0.displayName',
			},
		],
		StateSpecific_StackedUM: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_StackedUM.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_StackedUM.0.displayName',
			},
		],
		StateSpecific_StackedUIM: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_StackedUIM.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_StackedUIM.0.displayName',
			},
		],
		StateSpecific_Combined_UM_UIM: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Combined_UM_UIM.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Combined_UM_UIM.0.displayName',
			},
		],
		StateSpecific_UM_UIM: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_UM_UIM.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_UM_UIM.0.displayName',
			},
		],
		UM_PD_Limit: [
			{
				states: 'AK,GA,VA,TX',
				description: 'coveragesTexts.PersonalAuto.UM_PD_Limit.0.description',
				displayName: 'coveragesTexts.PersonalAuto.UM_PD_Limit.0.displayName',
			},
			{
				states: 'AR,CA,CO,DC,ID,IL,IN,LA,MD,MS,MT,NC,ND,NJ,NM,OH,OR,RI,SC,SD,TN,UT,WA,WV,WY',
				description: 'coveragesTexts.PersonalAuto.UM_PD_Limit.1.description',
				displayName: 'coveragesTexts.PersonalAuto.UM_PD_Limit.1.displayName',
			},
		],
		MIPIPLimit: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.MIPIPLimit.0.description',
				displayName: 'coveragesTexts.PersonalAuto.MIPIPLimit.0.displayName',
			},
		],
		StateSpecific_LimitedPropertyDamage: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_LimitedPropertyDamage.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_LimitedPropertyDamage.0.displayName',
			},
		],
		PIPAttendantCare: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.PIPAttendantCare.0.description',
				displayName: 'coveragesTexts.PersonalAuto.PIPAttendantCare.0.displayName',
			},
		],
		StateSpecific_Additional_PIP: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Additional_PIP.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Additional_PIP.0.displayName',
			},
		],
		StateSpecific_PIP_applies: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_PIP_applies.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_PIP_applies.0.displayName',
			},
		],
		StateSpecific_HealthCare: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_HealthCare.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_HealthCare.0.displayName',
			},
		],
		StateSpecific_ExtendedMedical: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_ExtendedMedical.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_ExtendedMedical.0.displayName',
			},
		],
		StateSpecific_WageLoss: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_WageLoss.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_WageLoss.0.displayName',
			},
		],
		StateSpecific_WorkLoss: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_WorkLoss.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_WorkLoss.0.displayName',
			},
		],
		StateSpecific_Accidental_Death: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Accidental_Death.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Accidental_Death.0.displayName',
			},
		],
		StateSpecific_BRBLimit: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_BRBLimit.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_BRBLimit.0.displayName',
			},
		],
		StateSpecific_MedicalExpense: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_MedicalExpense.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_MedicalExpense.0.displayName',
			},
		],
		StateSpecific_Funeral_Expense: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Funeral_Expense.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Funeral_Expense.0.displayName',
			},
		],
		StateSpecific_Tort_Threshold: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Tort_Threshold.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Tort_Threshold.0.displayName',
			},
		],
		StateSpecific_Tort_Limitation: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Tort_Limitation.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Tort_Limitation.0.displayName',
			},
		],
		StateSpecific_OptionalBI: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_OptionalBI.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_OptionalBI.0.displayName',
			},
		],
		StateSpecific_First_Party_Benefits: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_First_Party_Benefits.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_First_Party_Benefits.0.displayName',
			},
		],
		StateSpecific_First_Party: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_First_Party.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_First_Party.0.displayName',
			},
		],
		StateSpecific_AutoDeathIndemnity: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_AutoDeathIndemnity.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_AutoDeathIndemnity.0.displayName',
			},
		],
		StateSpecific_IncomeLoss: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_IncomeLoss.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_IncomeLoss.0.displayName',
			},
		],
		StateSpecific_TotalDisability: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_TotalDisability.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_TotalDisability.0.displayName',
			},
		],
		StateSpecific_ExtraMedicalExp: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_ExtraMedicalExp.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_ExtraMedicalExp.0.displayName',
			},
		],
		StateSpecific_Obel: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_Obel.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_Obel.0.displayName',
			},
		],
		StateSpecific_AdditionalDeath: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_AdditionalDeath.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_AdditionalDeath.0.displayName',
			},
		],
		StateSpecific_SupplementalSpousalLiability: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_SupplementalSpousalLiability.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_SupplementalSpousalLiability.0.displayName',
			},
		],
		PIPWorkLossWaiver: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.PIPWorkLossWaiver.0.description',
				displayName: 'coveragesTexts.PersonalAuto.PIPWorkLossWaiver.0.displayName',
			},
		],
		StateSpecific_CollisionType: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_CollisionType.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_CollisionType.0.displayName',
			},
		],
		StateSpecific_LimitedCollisionDed: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.StateSpecific_LimitedCollisionDed.0.description',
				displayName: 'coveragesTexts.PersonalAuto.StateSpecific_LimitedCollisionDed.0.displayName',
			},
		],
		MAWaivCollDed: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.MAWaivCollDed.0.description',
				displayName: 'coveragesTexts.PersonalAuto.MAWaivCollDed.0.displayName',
			},
		],
		Full_Glass: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Full_Glass.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Full_Glass.0.displayName',
			},
		],
		Loan_Lease: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalAuto.Loan_Lease.0.description',
				displayName: 'coveragesTexts.PersonalAuto.Loan_Lease.0.displayName',
			},
		],
	},
	// PersonalHome, Condominium and DwellingFire
	_home: {
		PersonalProperty: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.PersonalProperty.0.description',
				displayName: 'coveragesTexts.PersonalHome.PersonalProperty.0.displayName',
			},
		],
		PLAllPerilsDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.PLAllPerilsDeductible.0.description',
				displayName: 'coveragesTexts.PersonalHome.PLAllPerilsDeductible.0.displayName',
			},
		],
		PersonalLiability: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.PersonalLiability.0.description',
				displayName: 'coveragesTexts.PersonalHome.PersonalLiability.0.displayName',
			},
		],
		DwellingMedicalPayments: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.DwellingMedicalPayments.0.description',
				displayName: 'coveragesTexts.PersonalHome.DwellingMedicalPayments.0.displayName',
			},
		],
		LossOfUse: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.LossOfUse.0.description',
				displayName: 'coveragesTexts.PersonalHome.LossOfUse.0.displayName',
			},
		],
		OtherStructures: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.OtherStructures.0.description',
				displayName: 'coveragesTexts.PersonalHome.OtherStructures.0.displayName',
			},
		],
		WindstormDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.WindstormDeductible.0.description',
				displayName: 'coveragesTexts.PersonalHome.WindstormDeductible.0.displayName',
			},
		],
		'AnnualHurricaneDed (FL)': [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.AnnualHurricaneDed (FL).0.description',
				displayName: 'coveragesTexts.PersonalHome.AnnualHurricaneDed (FL).0.displayName',
			},
		],
		ContentDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.ContentDeductible.0.description',
				displayName: 'coveragesTexts.PersonalHome.ContentDeductible.0.displayName',
			},
		],
		FloodZone: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.FloodZone.0.description',
				displayName: 'coveragesTexts.PersonalHome.FloodZone.0.displayName',
			},
		],
		Dwelling: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.Dwelling.0.description',
				displayName: 'coveragesTexts.PersonalHome.Dwelling.0.displayName',
			},
		],
		ReplaceMentCostContents: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.ReplaceMentCostContents.0.description',
				displayName: 'coveragesTexts.PersonalHome.ReplaceMentCostContents.0.displayName',
			},
		],
		ReplaceMentCostContentsACV: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.ReplaceMentCostContentsACV.0.description',
				displayName: 'coveragesTexts.PersonalHome.ReplaceMentCostContentsACV.0.displayName',
			},
		],
		AllPerils: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.AllPerils.0.description',
				displayName: 'coveragesTexts.PersonalHome.AllPerils.0.displayName',
			},
		],
		MedicalPayments: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.MedicalPayments.0.description',
				displayName: 'coveragesTexts.PersonalHome.MedicalPayments.0.displayName',
			},
		],
		WindStorm: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.WindStorm.0.description',
				displayName: 'coveragesTexts.PersonalHome.WindStorm.0.displayName',
			},
		],
		HurricaneDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.HurricaneDeductible.0.description',
				displayName: 'coveragesTexts.PersonalHome.HurricaneDeductible.0.displayName',
			},
		],
		AnimalLiabilityCoverage: [
			{
				states: 'default',
				description: 'coveragesTexts.PersonalHome.AnimalLiabilityCoverage.0.description',
				displayName: 'coveragesTexts.PersonalHome.AnimalLiabilityCoverage.0.displayName',
			},
		],

		// ADDITIONAL COVERAGES
		WaterBackup: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.WaterBackup.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.WaterBackup.0.description',
			},
		],
		OrdinanceorLawAmountofCoverage: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.OrdinanceorLawAmountofCoverage.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.OrdinanceorLawAmountofCoverage.0.description',
			},
		],
		FoundationCoverage: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.FoundationCoverage.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.FoundationCoverage.0.description',
			},
		],
		AdditionalDwellingCoverageLimit: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.AdditionalDwellingCoverageLimit.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.AdditionalDwellingCoverageLimit.0.description',
			},
		],
		PersonalInjury: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.PersonalInjury.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.PersonalInjury.0.description',
			},
		],
		PersonalPropertyRC: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.PersonalPropertyRC.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.PersonalPropertyRC.0.description',
			},
		],
		IdentityTheft: [
			{
				states: 'default',
				displayName: 'coveragesTexts.PersonalHome.IdentityTheft.0.displayName',
				isAdditionalCoverage: true,
				description: 'coveragesTexts.PersonalHome.IdentityTheft.0.description',
			},
		],
	},

	get [LobsEnum.CONDOMINIUM]() {
		return this._home;
	},

	get [LobsEnum.PERSONAL_HOME]() {
		return this._home;
	},

	get [LobsEnum.DWELLING_FIRE]() {
		return this._home;
	},

	// Renters Data
	[LobsEnum.RENTERS]: {
		PersonalProperty: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.PersonalProperty.0.description',
				displayName: 'coveragesTexts.Renters.PersonalProperty.0.displayName',
			},
		],
		LossOfUse: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.LossOfUse.0.description',
				displayName: 'coveragesTexts.Renters.LossOfUse.0.displayName',
			},
		],
		PersonalLiability: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.PersonalLiability.0.description',
				displayName: 'coveragesTexts.Renters.PersonalLiability.0.displayName',
			},
		],
		DwellingMedicalPayments: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.DwellingMedicalPayments.0.description',
				displayName: 'coveragesTexts.Renters.DwellingMedicalPayments.0.displayName',
			},
		],
		PLAllPerilsDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.PLAllPerilsDeductible.0.description',
				displayName: 'coveragesTexts.Renters.PLAllPerilsDeductible.0.displayName',
			},
		],
		WindstormDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.WindstormDeductible.0.description',
				displayName: 'coveragesTexts.Renters.WindstormDeductible.0.displayName',
			},
		],
		'AnnualHurricaneDed (FL)': [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.AnnualHurricaneDed (FL).0.description',
				displayName: 'coveragesTexts.Renters.AnnualHurricaneDed (FL).0.displayName',
			},
		],
		MedicalPayments: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.MedicalPayments.0.description',
				displayName: 'coveragesTexts.Renters.MedicalPayments.0.displayName',
			},
		],
		AllPerils: [
			{
				states: 'default',
				description: 'coveragesTexts.Renters.AllPerils.0.description',
				displayName: 'coveragesTexts.Renters.AllPerils.0.displayName',
			},
		],
	},

	// Pets Data
	[LobsEnum.PETS]: {
		PetPlan: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.PetPlan.0.description',
				displayName: 'coveragesTexts.Pets.PetPlan.0.displayName',
			},
		],
		AccidentCoverage: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.AccidentCoverage.0.description',
				displayName: 'coveragesTexts.Pets.AccidentCoverage.0.displayName',
			},
		],
		IllnessCoverage: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.IllnessCoverage.0.description',
				displayName: 'coveragesTexts.Pets.IllnessCoverage.0.displayName',
			},
		],
		AnnualDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.AnnualDeductible.0.description',
				displayName: 'coveragesTexts.Pets.AnnualDeductible.0.displayName',
			},
		],
		AnnualMaximum: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.AnnualMaximum.0.description',
				displayName: 'coveragesTexts.Pets.AnnualMaximum.0.displayName',
			},
		],
		Reimbursment: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.Reimbursment.0.description',
				displayName: 'coveragesTexts.Pets.Reimbursment.0.displayName',
			},
		],
		Wellness: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.Wellness.0.description',
				displayName: 'coveragesTexts.Pets.Wellness.0.displayName',
			},
		],
		CompanyHighlights: [
			{
				states: 'default',
				description: 'coveragesTexts.Pets.CompanyHighlights.0.description',
				displayName: 'coveragesTexts.Pets.CompanyHighlights.0.displayName',
			},
		],
	},

	// Flood Data
	[LobsEnum.FLOOD]: {
		BuildingCoverage: [
			{
				states: 'default',
				description: 'coveragesTexts.Flood.BuildingCoverage.0.description',
				displayName: 'coveragesTexts.Flood.BuildingCoverage.0.displayName',
			},
		],
		ContentsCoverage: [
			{
				states: 'default',
				description: 'coveragesTexts.Flood.ContentsCoverage.0.description',
				displayName: 'coveragesTexts.Flood.ContentsCoverage.0.displayName',
			},
		],
		BuildingDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.Flood.BuildingDeductible.0.description',
				displayName: 'coveragesTexts.Flood.BuildingDeductible.0.displayName',
			},
		],
		ContentsDeductible: [
			{
				states: 'default',
				description: 'coveragesTexts.Flood.ContentsDeductible.0.description',
				displayName: 'coveragesTexts.Flood.ContentsDeductible.0.displayName',
			},
		],
	},

	// Flood Data
	Additional_Coverages_Home: {
		WaterBackup: [
			{
				states: 'default',
				description: 'coveragesTexts.Additional_Coverages_Home.WaterBackup.0.description',
				displayName: 'coveragesTexts.Additional_Coverages_Home.WaterBackup.0.displayName',
			},
		],
	},
};
