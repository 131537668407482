import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { EventsService } from '../services/events.service';

@Directive({
	selector: '[appClickEvent]',
})
export class ClickEventDirective {
	@Input() eventData: any;

	constructor(private eventsService: EventsService, private store: Store) { }

	@HostListener('click', ['$event']) onClick() {
		this.eventsService.addEvent(this.eventData.action, { label: this.eventData.label, button: this.eventData.button, module: this.eventData.module });
	}

}
