import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { selectLobSelection, selectLobsInitialized } from '../store/selectors/interview-metadata.selector';
import { Store } from '@ngrx/store';
import { LobsEnum } from '../enums/lobs.enum';
import { ActionApiService } from './action-api.service';

@Injectable({
	providedIn: 'root',
})
export class LobService {
	lobSelection = [];

	constructor(private store: Store, private actionApiService: ActionApiService) {
		this.store.select(selectLobSelection).subscribe((lobSelection) => {
			this.lobSelection = lobSelection;
		});

		this.store.select(selectLobsInitialized).subscribe((lobsInitialized) => {
			if (lobsInitialized && lobsInitialized.length > 0) {
				this.actionApiService.saveFlow(JSON.stringify({ lobsInitialized }));
			}
		});
	}

	// TODO: change this to use this.lobSelection
	getLob$(): Observable<any> {
		return this.store.select(selectLobSelection).pipe(map((lobs) => this.getLob(lobs)));
	}

	getLob(lobs: string[]) {
		lobs = lobs.filter((lob) => lob !== LobsEnum.FLOOD);
		if (!lobs || lobs?.length === 0) {
			return null;
		} else if (lobs.length === 1) {
			return lobs[0];
		} else {
			return LobsEnum.HOME_AUTO;
		}
	}

	getFlowType(lob: string) {
		return lob === LobsEnum.CONDOMINIUM || lob === LobsEnum.DWELLING_FIRE ? LobsEnum.PERSONAL_HOME : lob;
	}

	isHomeAuto() {
		return this.lobSelection[0] === LobsEnum.PERSONAL_HOME && this.lobSelection[1] === LobsEnum.PERSONAL_AUTO;
	}

	isAutoHome() {
		return this.lobSelection[0] === LobsEnum.PERSONAL_AUTO && this.lobSelection[1] === LobsEnum.PERSONAL_HOME;
	}

	checkIsLobDifferent(lobs, lobsInPolicyData) {
		let currentLobs;
		if (lobsInPolicyData) {
			currentLobs = [...lobsInPolicyData];
		} else {
			return true;
		}
		if (JSON.stringify(currentLobs.sort()) === JSON.stringify(lobs.sort())) {
			return false;
		} else {
			return true;
		}
	}

	getLobTitleForNote(lob: LobsEnum) {
		const keyBase = 'Resource.EnumMapping.Lobs.';

		// some lobs are expected to have a different key than the one in LobsEnum to be mapped properly
		switch (lob) {
			case LobsEnum.PERSONAL_HOME:
				return keyBase + 'Homeowners';
			case LobsEnum.PETS:
				return keyBase + 'PetHealth';
			default:
				return keyBase + lob;
		}
	}
}
