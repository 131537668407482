import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { BasePageComponent } from '../../base-page.component';
import { Fields } from '../../../../../constants/fields';
import { Validations } from 'src/app/utils/validation';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { EventActions } from '../../../../../models/event-data.model';
import { selectInterviewTexts } from 'src/app/store/selectors/interview-texts.selectors';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
	selector: 'app-personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent extends BasePageComponent implements OnInit, AfterViewInit {
	phoneNumberMask = '999-999-9999';
	eventActions = EventActions;
	isAgreementEnabled = true;
	isLegalConsentEnabled = false;

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store);
	}

	get customFields(): FormGroup {
		return this.form.get('CustomFields') as FormGroup;
	}

	get legalConsentControl(): FormControl {
		return this.customFields.get(this.Fields.D2CAgreeToTerms.name) as FormControl;
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();

		setTimeout(() => {
			this.patchData(this.form);
			this.registerOnChange(this.form);
		});

		this.store
			.select(selectInterviewTexts)
			.pipe(
				untilDestroyed(this),
				map((stepTexts) => stepTexts['tspa'])
			)
			.subscribe((tspa) => {
				this.isAgreementEnabled = tspa.tcpaOn;
				this.isLegalConsentEnabled = tspa.legalConsentOn;

				if (this.isAgreementEnabled) {
					this.Fields.IAgreeToReceiveEmailsByBolt = {
						...this.Fields.IAgreeToReceiveEmailsByBolt,
						labelKey: 'tspa.tcpaText',
					};
				}

				if (this.isLegalConsentEnabled && tspa.legalConsent.length) {
					this.Fields.D2CAgreeToTerms = {
						...this.Fields.D2CAgreeToTerms,
						labelKey: 'tspa.legalConsent',
					};

					this.form.addControl(
						'CustomFields',
						new FormGroup({
							[this.Fields.D2CAgreeToTerms.name]: new FormControl({ value: null, disabled: false }, [
								Validators.requiredTrue,
							]),
						})
					);

					setTimeout(() => {
						if (
							this.quoteData.hasOwnProperty('CustomFields') &&
							this.quoteData.CustomFields.hasOwnProperty(this.Fields.D2CAgreeToTerms.name)
						) {
							this.legalConsentControl.setValue(
								this.quoteData.CustomFields[this.Fields.D2CAgreeToTerms.name] === 'True' ||
									this.quoteData.CustomFields[this.Fields.D2CAgreeToTerms.name]
							);
						}
					});
				}
			});
	}

	initform(): void {
		this.form = new FormGroup(
			{
				CreditCheckPermission: new FormControl(true),
				IHerebyConfirm: new FormControl(true),
			},
			null,
			null
		);

		this.form.addControl(
			Fields.FirstName.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(50),
				Validations.firstNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.LastName.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(30),
				Validations.lastNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.PersonalLineGender.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.MaritalStatus.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.Email.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(60),
				Validations.emailValid,
			])
		);

		this.form.addControl(
			Fields.PrimaryPhoneNumber.name,
			new FormControl({ value: null, disabled: false }, [Validators.required, Validations.validPhoneNumber])
		);

		this.form.addControl(
			Fields.DateOfBirth.name,
			new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validations.isDOBpastDate,
				Validations.dateFormat,
				Validations.isMoreThen18,
				Validations.isEarlierThan1900,
			])
		);

		this.form.addControl('IAgreeToReceiveEmailsByBolt', new FormControl({ value: null, disabled: false }));
	}
}
