import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { selectProgressMeter } from '../../../store/selectors/progress-meter.selector';
import { IStep } from '../../../store/states/progress-meter.state';
import { IAppState } from '../../../store/states/app.state';
import { LobsEnum } from '../../../enums/lobs.enum';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { combineLatest } from 'rxjs';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { duplicateObjectWithoutReadOnly } from 'src/app/utils/general.utils';
import { NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'app-progress-meter-header',
	templateUrl: './progress-meter-header.component.html',
	styleUrls: ['./progress-meter-header.component.scss'],
})
export class ProgressMeterHeaderComponent implements OnInit {
	steps: IStep[];
	isVisible: boolean;
	moreThenOneLob = false;
	lob;
	progressMeter;
	interviewMetaData;
	constructor(
		private store: Store<IAppState>,
		private router: Router
	) { }

	ngOnInit(): void {

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				untilDestroyed(this)
			)
			.subscribe((event: NavigationEnd) => {
				this.isVisible =
					(this.interviewMetaData.lobSelection.indexOf(LobsEnum.PERSONAL_AUTO) > -1 || this.interviewMetaData.lobSelection.indexOf(LobsEnum.HOME_AUTO) - 1 && this.interviewMetaData.lobSelection.indexOf(LobsEnum.PERSONAL_AUTO) > -1) &&
					window.location.href.indexOf(StepsEnum.YOUR_ADDRESS) === -1 && window.location.href.indexOf(StepsEnum.LOB_SELECTION) === -1 && window.location.href.indexOf(StepsEnum.FISKER_DISCLAIMER) === -1 && window.location.href.indexOf(StepsEnum.RETRIEVE_QUOTE) === -1 && window.location.href.indexOf('error') === -1;
			});

		combineLatest([this.store.select(selectInterviewMetadata), this.store.select(selectProgressMeter)])
			.pipe(untilDestroyed(this))
			.subscribe(([interviewMetaData, progressMeter]) => {
				this.progressMeter = progressMeter;
				this.interviewMetaData = interviewMetaData;
				this.isVisible =
					(interviewMetaData.lobSelection.indexOf(LobsEnum.PERSONAL_AUTO) > -1 || interviewMetaData.lobSelection.indexOf(LobsEnum.HOME_AUTO) - 1 && interviewMetaData.lobSelection.indexOf(LobsEnum.PERSONAL_AUTO) > -1) &&
					window.location.href.indexOf(StepsEnum.YOUR_ADDRESS) === -1 && window.location.href.indexOf(StepsEnum.LOB_SELECTION) === -1 && window.location.href.indexOf(StepsEnum.FISKER_DISCLAIMER) === -1 && window.location.href.indexOf(StepsEnum.RETRIEVE_QUOTE) === -1 && window.location.href.indexOf('error') === -1;
				this.moreThenOneLob = interviewMetaData.lobSelection?.length > 1; //MultiLob & cross sales
				if (this.moreThenOneLob) {
					this.lob = interviewMetaData.lobSelection.join('-');
				} else {
					this.lob = interviewMetaData.lobSelection[0];
				}

				const steps = this.formatSteps(progressMeter);
				this.steps = steps;
				setTimeout(() => {

					let list = document.querySelectorAll('.steps-wrapper .steps-flow .step.active');
					if (this.moreThenOneLob) {
						list = document.querySelectorAll('.steps-wrapper .steps-flow .multiLob-step-progress.active');
						if (list.length === 0) {
							list = document.querySelectorAll('.steps-wrapper .steps-flow .multiLob-step.active');
						}
					}

					if (list.length === 0) {
						list = document.querySelectorAll('.steps-wrapper .steps-flow .step:last-child');
					}

					if (list.length > 0) {
						document.querySelectorAll('.last').forEach(elem => {
							elem?.classList.remove('last');
						})
						list[list.length - 1].classList.add('last');
					}
				}, 0)
			});
	}

	isStepVisible(step: IStep): boolean {
		return step.stepProperties.title !== '';
	}

	formatSteps(progressMeter) {
		let activeStep = progressMeter.flowOptions[progressMeter.flowType][progressMeter.activeStep];
		const flowOptions = progressMeter.flowOptions[progressMeter.flowType];
		const filteredSteps = [];

		for (let index = 0; index < flowOptions.length; index++) {
			const step = duplicateObjectWithoutReadOnly(flowOptions[index]);

			let active = index === progressMeter.activeStep;
			let visited = index < progressMeter.activeStep;
			if (step.name === StepsEnum.PRIMARY_DRIVER && !this.moreThenOneLob) {
				// single lob and auto flow should show primary driver as active
				step.stepProperties.showStepAsActiveFor = [StepsEnum.DRIVERS];
			}
			// if step has showStepAsActiveFor property, then show it as active if the active step is in the showStepAsActiveFor array
			if (step.stepProperties.showStepAsActiveFor && progressMeter.activeStep > index) {
				filteredSteps.push({
					...step,
					active: step.stepProperties.showStepAsActiveFor?.includes(activeStep.name),
					visited,
				});
			} else {
				filteredSteps.push({ ...step, active, visited });
			}
		}

		return filteredSteps;
	}

	trackByFn(index, item) {
		return index;
	}

	shouldDisplayStep(currentStep): boolean {
		let stepIndex = this.progressMeter.flowOptions[this.progressMeter.flowType].findIndex(step => step.name === currentStep.name);
		let resultStepIndex = this.progressMeter.flowOptions[this.progressMeter.flowType].findIndex(step => step.name === StepsEnum.RESULT) + 1;
		let firstStepIndex = this.progressMeter.flowOptions[this.progressMeter.flowType].findIndex(step => step.name === StepsEnum.LOB_SELECTION || step.name === StepsEnum.YOUR_ADDRESS) + 1;

		// Auto should ignore stages with condition and show only stages with title
		if (this.lob === LobsEnum.PERSONAL_AUTO) {
			if (stepIndex > firstStepIndex && stepIndex < resultStepIndex && !currentStep.checkCondition && !!currentStep.stepProperties.title) {
				return true
			} else {
				return false;
			}
		}

		// any other lob/s start after lob selection and until result stage
		return stepIndex > firstStepIndex && stepIndex < resultStepIndex ? true : false;
	}
}
