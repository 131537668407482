import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { BasePageComponent } from '../../../../../base-page.component';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Fields } from '../stillwater-fields';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
import { ActionApiService } from 'src/app/services/action-api.service';

const ADDITIONAL_STRUCTURES_POOL_CHILDREN = [
	Fields.SW_PL_PoolFeatures_DivingBoard.name,
	Fields.SW_PL_PoolFeatures_Slide.name,
	Fields.SW_PL_PoolFeatures_4ftFence.name,
	Fields.SW_PoolUnfenced.name,
];

const PL_ADDITIONAL_STRUCTURES_GARAGE_CHILDREN = [Fields.TypeGarageCarport.name, Fields.PL_NumberCarSpace.name];

const ROOF_TYPE_CHILDREN = [Fields.RoofSprayPoly.name, Fields.RoofSprayPolyLast3.name, Fields.RoofSlopeValid.name];

@UntilDestroy()
@Component({
	selector: 'app-stillwater-details',
	templateUrl: './stillwater-details.component.html',
	styleUrls: ['./stillwater-details.component.scss', '../stillwater.component.scss'],
})
export class StillwaterDetailsComponent extends BasePageComponent implements OnInit {
	Fields = Fields;
	vertical = true;
	State;
	actionApiService: ActionApiService;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService,
		actionApiService: ActionApiService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	FieldsForCurrentPage = [
		{ ...this.Fields.RoofSprayPoly },
		{ ...this.Fields.RoofSprayPolyLast3 },
		{ ...this.Fields.RoofSlopeValid },
		{ ...this.Fields.SW_PL_AdditionalStructures_Pool },
		{ ...this.Fields.SW_PL_PoolFeatures_DivingBoard },
		{ ...this.Fields.SW_PL_PoolFeatures_Slide },
		{ ...this.Fields.SW_PL_PoolFeatures_4ftFence },
		{ ...this.Fields.SW_PoolUnfenced, divider: true },
		{ ...this.Fields.TrampolineSafety },
		{ ...this.Fields.PL_AdditionalStructures_Garage },
		{ ...this.Fields.TypeGarageCarport },
		{ ...this.Fields.PL_NumberCarSpace, divider: true },
		{ ...this.Fields.BasementFinishType },
	];

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({});

		this.FieldsForCurrentPage.forEach((field) => {
			this.form.addControl(field.name, new FormControl({ value: null, disabled: false }, Validators.required));
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				quoteData.RoofType == 'FOAM' || quoteData.RoofType == 'VINYL'
					? ROOF_TYPE_CHILDREN.forEach((i) => this.form.controls[i].enable())
					: ROOF_TYPE_CHILDREN.forEach((i) => this.form.controls[i].disable());

				quoteData.TypeOfFoundation == 'Basement' || quoteData.TypeOfFoundation == 'PartialBasement'
					? this.form.controls[this.Fields.BasementFinishType.name].enable()
					: this.form.controls[this.Fields.BasementFinishType.name].disable();
				if (!quoteData.SW_PL_AdditionalStructures_Pool) {
					ADDITIONAL_STRUCTURES_POOL_CHILDREN.forEach((i) => this.form.controls[i].disable());
				}
			});

		this.onFormChange();
		super.registerOnChange(this.form);
		this.patchData(this.form);
	}

	onFormChange() {
		this.form.controls[this.Fields.SW_PL_AdditionalStructures_Pool.name].valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value) => {
				value
					? ADDITIONAL_STRUCTURES_POOL_CHILDREN.forEach((i) => this.form.controls[i].enable())
					: ADDITIONAL_STRUCTURES_POOL_CHILDREN.forEach((i) => this.form.controls[i].disable());
			});

		this.form.controls[this.Fields.PL_AdditionalStructures_Garage.name].valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value) => {
				value
					? PL_ADDITIONAL_STRUCTURES_GARAGE_CHILDREN.forEach((i) => this.form.controls[i].enable())
					: PL_ADDITIONAL_STRUCTURES_GARAGE_CHILDREN.forEach((i) => this.form.controls[i].disable());
			});
	}
}
