import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/states/app.state';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { ActionApiService } from 'src/app/services/action-api.service';
import { RoutingService } from 'src/app/services/routing.service';
import { StepsEnum } from 'src/app/enums/steps.enum';

@Component({
	selector: 'homesite-agreement',
	templateUrl: './homesite-agreement.component.html',
	styleUrls: ['./homesite-agreement.component.scss'],
})
export class HomsiteAgreementComponent implements OnInit {
	data: any;
	dataToUpdate;

	constructor(private store: Store<IAppState>,
		private progressMeterService: ProgressMeterService,
		private actionApiService: ActionApiService,
		public routingService: RoutingService,


	) {
		this.dataToUpdate = {
			AnimalBites: false,
			ViciousExoticAnimals: false,
		}
	}

	ngOnInit(): void { }


	handleAgreementClick() {
		this.actionApiService.updateApplication(this.dataToUpdate).subscribe({
			error: (e) => this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR),
		});
		this.progressMeterService.goToNextPage();
	}
}
