import { createReducer, on } from '@ngrx/store';
import { initialQuoteDataState } from '../states/quote-data.state';
import {
	AddManualDriver,
	AddManualVehicle,
	AddPet,
	EditDiscoveredDriver,
	EditManualDriver,
	EditDrivers,
	ResetQuoteData,
	ResetQuoteDataExceptAddress,
	UpdateManualVehicle,
	UpdateDiscoverVehicle,
	UpdateQuoteData,
	UpdateQuoteDataWithInitial,
	UpdatePersonalVehicle,
	MergeQuoteData,
} from '../actions/quote-data.actions';
import { duplicateObjectWithoutReadOnly } from 'src/app/utils/general.utils';

const reducer = createReducer(
	initialQuoteDataState,
	on(UpdateQuoteData, (state, { data }) => {
		if (!data) {
			return;
		}
		return { ...state, ...data };
	}),
	on(MergeQuoteData, (state, { data, deleteOnEmpty }) => {
		let duplicateData = duplicateObjectWithoutReadOnly(data);
		// merge data from PersonalVehicles to manualVehicles
		if (duplicateData.PersonalVehicles?.length > 0 && state.manualVehicles?.length > 0) {
			duplicateData.manualVehicles = mergeVehicles(state, duplicateData, deleteOnEmpty, 'manualVehicles', 'PersonalVehicles');
		}

		if (duplicateData.manualVehicles?.length > 0) {
			duplicateData.manualVehicles = mergeVehicles(state, duplicateData, deleteOnEmpty, 'manualVehicles');
		}

		if (duplicateData.discoverVehicles?.length > 0) {
			duplicateData.discoverVehicles = mergeVehicles(state, duplicateData, deleteOnEmpty, 'discoverVehicles');
		}

		if (duplicateData.PersonalVehicles?.length > 0) {
			duplicateData.PersonalVehicles = mergeVehicles(state, duplicateData, deleteOnEmpty, 'PersonalVehicles');
		}

		// merge data from Drivers to manualDrivers
		if (duplicateData.Drivers?.length > 0 && state.manualDrivers?.length > 0) {
			duplicateData.manualDrivers = mergeDrivers(state, duplicateData, deleteOnEmpty, 'manualDrivers', 'Drivers');
		}

		if (duplicateData.Drivers?.length > 0) {
			duplicateData.manualDrivers = mergeDrivers(state, duplicateData, deleteOnEmpty, 'manualDrivers');
		}

		if (duplicateData.Drivers?.length > 0 && state.Drivers?.length > 0) {
			duplicateData.Drivers = mergeDrivers(state, duplicateData, deleteOnEmpty, 'Drivers');
		}

		return { ...state, ...duplicateData };

	}),
	on(UpdateQuoteDataWithInitial, (state, { data }) => {
		return { ...initialQuoteDataState, ...data };
	}),
	on(AddManualDriver, (state, { data }) => ({
		...state,
		manualDrivers: state.manualDrivers.concat([data]),
	})),
	on(EditManualDriver, (state, { data }) => {
		const driverToUpdateIndex = state.manualDrivers.findIndex((item) => item.Id == data.Id);
		const manualDriversUpdated = [...state.manualDrivers];
		manualDriversUpdated[driverToUpdateIndex] = { ...manualDriversUpdated[driverToUpdateIndex], ...data };
		return { ...state, manualDrivers: manualDriversUpdated };
	}),
	on(EditDiscoveredDriver, (state, { data }) => {
		const driverToUpdateIndex = state.discoverDrivers.findIndex((item) => item.Id == data.Id);
		const discoverDriversUpdated = [...state.discoverDrivers];
		discoverDriversUpdated[driverToUpdateIndex] = { ...discoverDriversUpdated[driverToUpdateIndex], ...data };
		return { ...state, discoverDrivers: discoverDriversUpdated };
	}),
	on(EditDrivers, (state, { data }) => {
		const driverToUpdateIndex = state.Drivers.findIndex((item) => item.Id == data.Id);
		const DriversUpdated = [...state.Drivers];
		DriversUpdated[driverToUpdateIndex] = { ...DriversUpdated[driverToUpdateIndex], ...data };
		return { ...state, Drivers: DriversUpdated };
	}),
	on(AddManualVehicle, (state, { value }) => ({ ...state, manualVehicles: [...state.manualVehicles, value] })),
	on(UpdateManualVehicle, (state, { data }) => {
		const vehicleToUpdateIndex = state.manualVehicles.findIndex((vehicle) => vehicle.Id === data.Id);
		const manualVehiclesUpdated = [...state.manualVehicles];

		if (vehicleToUpdateIndex !== -1) {
			manualVehiclesUpdated[vehicleToUpdateIndex] = { ...manualVehiclesUpdated[vehicleToUpdateIndex], ...data };
		}
		return {
			...state,
			manualVehicles: manualVehiclesUpdated,
		};
	}),
	on(UpdateDiscoverVehicle, (state, { data }) => {
		const vehicleToUpdateIndex = state.discoverVehicles.findIndex((vehicle) => vehicle.Id === data.Id);
		const discoverVehiclesUpdated = [...state.discoverVehicles];
		if (vehicleToUpdateIndex !== -1) {
			discoverVehiclesUpdated[vehicleToUpdateIndex] = { ...discoverVehiclesUpdated[vehicleToUpdateIndex], ...data };
		}
		return {
			...state,
			discoverVehicles: discoverVehiclesUpdated,
		};
	}),
	on(UpdatePersonalVehicle, (state, { data }) => {
		const vehicleToUpdateIndex = state.PersonalVehicles.findIndex((vehicle) => vehicle.Id === data.Id);
		const PersonalVehiclesUpdated = [...state.PersonalVehicles];
		if (vehicleToUpdateIndex !== -1) {
			PersonalVehiclesUpdated[vehicleToUpdateIndex] = { ...PersonalVehiclesUpdated[vehicleToUpdateIndex], ...data };
		}
		return {
			...state,
			PersonalVehicles: PersonalVehiclesUpdated,
		};
	}),
	on(AddPet, (state, { value }) => ({ ...state, Pets: [...state.Pets, value] })),
	on(ResetQuoteData, (state) => ({
		...initialQuoteDataState,
	})),
	on(ResetQuoteDataExceptAddress, (state) => ({
		...initialQuoteDataState,
		'PropertyAddress.AddressLine1': state.PropertyAddressAddressLine1,
		'PropertyAddress.AddressLine2': state.PropertyAddressAddressLine2,
		'PropertyAddress.City': state.City,
		'PropertyAddress.County': state.County,
		'PropertyAddress.State': state.State,
		'PropertyAddress.ZipCode': state.ZipCode,
	}))
);

const mergeVehicles = (state, data, deleteOnEmpty, keyInData, secondaryKey = '') => {
	let vehiclesUpdated = [];
	let changed = false;
	data[secondaryKey || keyInData]?.forEach(vehicle => {
		const vehicleToUpdateIndex = state[keyInData]?.findIndex((vehicleInState) => vehicleInState.Id === vehicle.Id);

		if (vehicleToUpdateIndex !== -1) {
			// delete undefined and null values from vehicle
			deleteOnEmpty && Object.keys(vehicle).forEach((key) => vehicle[key] === undefined || vehicle[key] === null && delete vehicle[key]);
			vehiclesUpdated.push({ ...state[keyInData][vehicleToUpdateIndex], ...vehicle });
			changed = true;
		}

	});
	return secondaryKey && !changed ? data[keyInData] : vehiclesUpdated; //if can't update secondary return the original object
}

const mergeDrivers = (state, data, deleteOnEmpty, keyInData, secondaryKey = '') => {
	let driverUpdated = [];
	let changed = false;
	data[secondaryKey || keyInData]?.forEach((driver) => {
		const driverToUpdateIndex = state[keyInData]?.findIndex((driverInState) => driverInState.Id === driver.Id);

		if (driverToUpdateIndex !== -1) {
			// delete undefined and null values from driver
			deleteOnEmpty && Object.keys(driver).forEach((key) => driver[key] === undefined || driver[key] === null && delete driver[key]);
			driverUpdated.push({ ...state[keyInData][driverToUpdateIndex], ...driver });
			changed = true;
		}
	});
	return secondaryKey && !changed ? data[keyInData] : driverUpdated; //if can't update secondary return the original object
}

export function quoteDataReducer(state, action) {
	return reducer(state, action);
}
