import { createReducer, on } from '@ngrx/store';
import {
	ActivateStep,
	resetProgressBar,
	setAllAsNotVisited,
	setFinalStep,
	setFlowType,
	UpdateProgressMeter,
} from '../actions/progress-meter.actions';
import { initialProgressMeter, IProgressMeter, IStep } from '../states/progress-meter.state';

const reducer = createReducer(
	initialProgressMeter,
	on(UpdateProgressMeter, (state, { data }) => ({ ...state, ...data })),
	on(ActivateStep, (state, { stepName }) => {
		const newState: IProgressMeter = JSON.parse(JSON.stringify(state));
		const stepIndex: number = newState.flowOptions[newState.flowType].findIndex((step: IStep) => step.name === stepName);
		if (stepIndex > -1) {
			newState.activeStep = stepIndex;
			newState.flowOptions[newState.flowType][stepIndex].visited = true;
		}

		return { ...newState };
	}),
	on(setFinalStep, (state, { }) => {
		const newState: IProgressMeter = JSON.parse(JSON.stringify(state));
		newState.activeStep = newState.flowOptions[newState.flowType].length - 1;
		return { ...newState };
	}),
	on(resetProgressBar, (state) => {
		return { ...initialProgressMeter };
	}),
	on(setFlowType, (state, { flowType }) => {
		return { ...state, flowType };
	}),
	on(setAllAsNotVisited, (state) => {
		const newState = JSON.parse(JSON.stringify(state));
		newState.flowOptions[newState.flowType].forEach((item) => {
			return item;
		});
		for (let i = 1; i < newState.flowOptions[newState.flowType].length - 1; i++) {
			newState.flowOptions[newState.flowType][i].visited = false;
		}
		return { ...initialProgressMeter };
	})
);

export function ProgressMeterReducer(state, action) {
	return reducer(state, action);
}
