export enum StepsTitleEnum {
	// General
	RESULT = 'Quotes',
	AUTO = 'Auto',
	HOME = 'Home',

	// Auto lob
	PRIMARY_DRIVER = 'Driver',
	DRIVERS = 'Drivers',
	VEHICLES = 'Vehicles',
	DRIVER_HISTORY = 'History',
	AUTO_COVERAGES = 'Coverage',
	POLICY_START_DATE = 'Start Date',
	ADDITIONAL_QUESTIONS_SAFECO_VEHICLES = 'Last details to confirm',

	// Home lob
	PROPERTIES = 'Properties',
	PRIMARY_RESIDENCE = 'Primary residence',
	PERSONAL_DETAILS_HOMEOWNERS = 'Personal details',
	SAFETY_ALARMS = 'safety-alarms',
}
