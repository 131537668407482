<section class="main-content main-content-personal-details">
	<app-page-heading
		[subtitleKey]="isDiscoverDrivers ? 'drivers.subTitleWithInfo' : 'drivers.subTitle'"
		[titleKey]="isDiscoverDrivers ? 'drivers.titleWithInfo' : 'drivers.title'"
	></app-page-heading>

	<form [formGroup]="form" class="stage">
		<ng-container *ngFor="let driver of getValidDrivers(primaryDriverFormControl); let i = index">
			<app-driver
				(editDriverFunc)="editDriver($event)"
				[Id]="driver.value.Id"
				[applicant]="applicant"
				[disabledToggle]="this.numActiveDrivers >= this.MAX_NUM_ACTIVE_DRIVERS"
				[formDriver]="driver"
				source="primaryDriver"
			></app-driver>
		</ng-container>
		<ng-container *ngFor="let driver of getValidDrivers(discoverDriversFormControl); let i = index">
			<app-driver
				(editDriverFunc)="editDriver($event)"
				[Id]="driver.value.Id"
				[applicant]="applicant"
				[disabledToggle]="this.numActiveDrivers >= this.MAX_NUM_ACTIVE_DRIVERS"
				[formDriver]="driver"
				[source]="driver.value.type"
			></app-driver>
		</ng-container>
		<ng-container *ngFor="let driver of getValidDrivers(manualDriversFormControl); let i = index">
			<app-driver
				(editDriverFunc)="editDriver($event)"
				[Id]="driver.value.Id"
				[applicant]="applicant"
				[disabledToggle]="this.numActiveDrivers >= this.MAX_NUM_ACTIVE_DRIVERS"
				[formDriver]="driver"
				source="manualDrivers"
			></app-driver>
		</ng-container>
	</form>

	<div class="add-driver">
		<app-button
			(clicked)="addDriver()"
			[disabled]="numDrivers == this.MAX_NUM_DRIVERS || this.numActiveDrivers == this.MAX_NUM_ACTIVE_DRIVERS"
			materialIcon="add"
			severity="bordered"
			[title]="'buttons.addAnotherDriver' | translate"
		></app-button>
		<p
			[ngClass]="{ show: numDrivers == this.MAX_NUM_DRIVERS || this.numActiveDrivers == this.MAX_NUM_ACTIVE_DRIVERS }"
			class="max-drivers-message"
		>
			<br />
			{{ "titles.sixDriversLimit" | translate }}
		</p>
	</div>
	<div class="next-button-wrapper">
		<next-button
			(clicked)="handleContinue()"
			[disabled]="checkValid()"
			continue="true"
			[title]="'buttons.continue' | translate"
		></next-button>
	</div>
</section>
