<section class="main-content">
	<app-page-heading [title]="'titles.lastDetailsToConfirm' | translate"></app-page-heading>
	<div class="questions-container">
		<div [style.width.%]="currentProgress" class="progress-bar"></div>

		<form #myform="ngForm" [formGroup]="form">
			<!----------------------->
			<!-- First inner step -->
			<!----------------------->
			<section [class.hidden]="internalStep !== 1">
				<b class="basic-text">{{ "labels.previousPolicyDetails" | translate }}</b>

				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.pgrCurrentCarrier' | translate"
						[name]="Fields.PGR_CurrentCarrier.name"
						[options]="carriersList"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.PGR_CurrentCarrier.name)"
						searchable="true"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.autoInsuranceCancelled' | translate"
						[name]="Fields.AutoInsuranceCancelled.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.AutoInsuranceCancelled.name)"
						searchable="true"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_LiabilityLimits.name].disabled">
					<app-dropdown
						[labelInsideField]="'labels.pgrLiabilityLimits' | translate"
						[name]="Fields.PGR_LiabilityLimits.name"
						[options]="fieldsOptions.priorLiabilityLimits"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.PGR_LiabilityLimits.name)"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_CarrierExpirationDate.name].disabled">
					<masked-input
						[inputMask]="'**/**/9999'"
						[name]="Fields.PGR_CarrierExpirationDate.name"
						[placeholder]="'labels.previousPolicyExpire' | translate"
						[tabindex]="tabindex(Fields.PGR_CarrierExpirationDate.name)"
						class="date-input"
					>
					</masked-input>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.ReasonForLapse.name].disabled">
					<app-dropdown
						[labelInsideField]="'labels.reasonForLapse' | translate"
						[name]="Fields.ReasonForLapse.name"
						[options]="fieldsOptions.reasonForLapse"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.ReasonForLapse.name)"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.YearsWithContinuousCoverageAuto.name].disabled">
					<unmasked-input
						[autocomplete]="false"
						[name]="Fields.YearsWithContinuousCoverageAuto.name"
						[pattern]="'^[0-9]*$'"
						[placeholder]="'labels.yearsWithContinuousCoverageAuto' | translate"
						[tabindex]="tabindex(Fields.YearsWithContinuousCoverageAuto.name)"
						maxlength="2"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.YearsWithPriorCarrierAuto.name].disabled">
					<unmasked-input
						[autocomplete]="false"
						[name]="Fields.YearsWithPriorCarrierAuto.name"
						[pattern]="'^(0?[1-9]|[1-9][0-9])$'"
						[placeholder]="'labels.yearsWithPriorCarrierAuto' | translate"
						[tabindex]="tabindex(Fields.YearsWithPriorCarrierAuto.name)"
						maxlength="2"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.NumPIPClaims.name].disabled">
					<unmasked-input
						[autocomplete]="false"
						[name]="Fields.NumPIPClaims.name"
						[pattern]="'^[0-9]*$'"
						[placeholder]="'labels.numPIPClaims' | translate"
						[tabindex]="tabindex(Fields.NumPIPClaims.name)"
						maxlength="2"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<div class="years-months-wrapper">
					<p class="years-months-text">{{ "labels.durationOwnedProperty" | translate }}</p>
					<button
						[matTooltip]="'labels.yearsLivedOwned' | translate"
						appHiddenWhenCssDisabled
						class="help-icon material-icons"
						appToggleTooltip
					>
						<span aria-hidden="true">help_outline</span>
					</button>
				</div>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_YearsAtAddress.name].disabled">
					<unmasked-input
						[autocomplete]="false"
						[name]="Fields.PGR_YearsAtAddress.name"
						[pattern]="'^[0-9]*$'"
						[placeholder]="'labels.yearsAtAddress' | translate"
						[tabindex]="tabindex(Fields.PGR_YearsAtAddress.name)"
						maxlength="2"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_MonthsAtAddress.name].disabled">
					<unmasked-input
						[autocomplete]="false"
						[name]="Fields.PGR_MonthsAtAddress.name"
						[pattern]="'^[0-9]*$'"
						[placeholder]="'labels.monthsAtAddress' | translate"
						[tabindex]="tabindex(Fields.PGR_MonthsAtAddress.name)"
						maxlength="2"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<div [class.hidden]="this.form.controls['PreviousAddress'].disabled">
					<p class="previous-address-details-text">{{ "labels.enterPreviousAddressDetails" | translate }}</p>

					<div class="address-field-wrapper">
						<app-address-suggestion
							(addressChangedEvent)="handleAddressChange()"
							[addressLine1]="this.form.get('PreviousAddress.AddressLine1').value"
							[addressLine2]="this.form.get('PreviousAddress.AddressLine2').value"
							[city]="this.form.get('PreviousAddress.City').value"
							[class.hidden]="this.form.controls['PreviousAddress'].disabled"
							[fieldsNames]="fieldsNames"
							[form]="form"
							[placeholder]="'address.suggestion.enterPreviousAddress' | translate"
							[showIcon]="false"
							[state]="this.form.get('PreviousAddress.State').value"
							[toShowMap]="false"
							[zipCode]="this.form.get('PreviousAddress.ZipCode').value"
						>
						</app-address-suggestion>
					</div>

					<error-message [field]="form.get('PreviousAddress').get('AddressLine1')" [isFocus$]="true"></error-message>
				</div>
			</section>

			<!----------------------->
			<!-- Second inner step -->
			<!----------------------->
			<section [class.hidden]="internalStep !== 2">
				<ng-container [class.hidden]="priorCarrier !== 'Progressive'">
					<b class="basic-text">{{ "labels.progressivePolicyInformation" | translate }}</b>

					<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_SpinOffCode.name].disabled">
						<app-dropdown
							[labelInsideField]="'labels.pgrSpinOffCode' | translate"
							[name]="Fields.PGR_SpinOffCode.name"
							[options]="fieldsOptions.spinOffCode"
							[reAlphabeticalOrder]="false"
							[tabindex]="tabindex(Fields.PGR_SpinOffCode.name)"
						></app-dropdown>
					</app-question-wrapper>
					<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_PriorCarrierPolicyNumAuto.name].disabled">
						<unmasked-input
							[autocomplete]="false"
							[name]="Fields.PGR_PriorCarrierPolicyNumAuto.name"
							[pattern]="'^[0-9]*$'"
							[placeholder]="'labels.priorCarrierPolicyNumAuto' | translate"
							[tabindex]="tabindex(Fields.PGR_PriorCarrierPolicyNumAuto.name)"
							maxlength="25"
						>
						</unmasked-input>
					</app-question-wrapper>
				</ng-container>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_MultiPolicyInd.name].disabled">
					<app-dropdown
						[labelInsideField]="'labels.pgrMultiPolicyInd' | translate"
						[name]="Fields.PGR_MultiPolicyInd.name"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.PGR_MultiPolicyInd.name)"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_MultiPolicyRiskCd.name].disabled">
					<app-dropdown
						[labelInsideField]="'labels.pgrMultiPolicyRiskCd' | translate"
						[multiple]="true"
						[name]="Fields.PGR_MultiPolicyRiskCd.name"
						[options]="fieldsOptions.progressivePolicies"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.PGR_MultiPolicyRiskCd.name)"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [class.hidden]="form.controls[Fields.PGR_Houseoccup.name].disabled">
					<app-dropdown
						[labelInsideField]="'labels.pgrHouseoccup' | translate"
						[name]="Fields.PGR_Houseoccup.name"
						[options]="fieldsOptions.numberOfOccupant"
						[reAlphabeticalOrder]="false"
						[tabindex]="tabindex(Fields.PGR_Houseoccup.name)"
					></app-dropdown>
				</app-question-wrapper>

				<b class="basic-text">{{ "labels.discounts" | translate }}</b>

				<div [class.hidden]="form.controls[Fields.PGR_DsbPkgInd.name].disabled" class="question-container">
					<div class="question-wrapper yes-no-row show">
						<label [attr.id]="Fields.PGR_DsbPkgInd.name" class="input-label">
							{{ "labels.pgrDsbPkgInd" | translate }}
						</label>
						<app-yes-no [name]="Fields.PGR_DsbPkgInd.name"></app-yes-no>
					</div>
				</div>

				<div [class.hidden]="form.controls[Fields.PGR_DsbBalanceInd.name].disabled" class="question-container">
					<div class="question-wrapper yes-no-row show">
						<label [attr.id]="Fields.PGR_DsbBalanceInd.name" class="input-label">
							{{ "labels.pgrDsbBalanceInd" | translate }}
						</label>
						<app-yes-no [name]="Fields.PGR_DsbBalanceInd.name"></app-yes-no>
					</div>
				</div>
			</section>

			<!----------------------->
			<!-- Third inner step -->
			<!----------------------->
			<section [class.hidden]="internalStep !== 3">
				<b class="basic-text">{{ "labels.progressiveAlsoWantsToKnow" | translate }}</b>

				<div [class.hidden]="form.controls[Fields.PGR_AllHdshldDrvListedInd.name].disabled" class="question-container">
					<div class="question-wrapper yes-no-row show">
						<label [attr.id]="Fields.PGR_AllHdshldDrvListedInd.name" class="input-label">
							{{ "labels.pgrAllHdshldDrvListedInd" | translate }}
						</label>
						<app-yes-no [name]="Fields.PGR_AllHdshldDrvListedInd.name"></app-yes-no>
					</div>
				</div>

				<div [class.hidden]="form.controls[Fields.eSignAcknowledgeDriversInd.name].disabled" class="question-container">
					<div class="question-wrapper yes-no-row show">
						<label [attr.id]="Fields.eSignAcknowledgeDriversInd.name" class="input-label">
							{{ "labels.eSignAcknowledgeDriversInd" | translate }}
						</label>
						<app-yes-no [name]="Fields.eSignAcknowledgeDriversInd.name"></app-yes-no>
					</div>
				</div>

				<div
					[class.hidden]="form.controls[Fields.eSignAcknowledgeVehiclesInd.name].disabled"
					class="question-container"
				>
					<div class="question-wrapper yes-no-row show">
						<label [attr.id]="Fields.eSignAcknowledgeVehiclesInd.name" class="input-label">
							{{ "labels.eSignAcknowledgeVehiclesInd" | translate }}
						</label>
						<app-yes-no [name]="Fields.eSignAcknowledgeVehiclesInd.name"></app-yes-no>
					</div>
				</div>
			</section>
		</form>

		<hr class="question-spacer" />
		<next-button
			(click)="navigateInternal()"
			(validationFailed)="handleValidation($event)"
			[continue]="internalStep !== 3"
			[disabled]="form.invalid"
			[loaderVisible]="false"
			[myForm]="myform"
			[shouldIgnoreDisabledFields]="true"
			[title]="'buttons.continue' | translate"
			class="fullscreen"
			isFullQuote="true"
		></next-button>
		<button (click)="navigateInternalBack()" *ngIf="internalStep > 1" class="question-step-back">
			{{ "buttons.goBack" | translate }}
		</button>
	</div>
</section>
