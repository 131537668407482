import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../store/states/app.state';
import { selectQuoteData } from '../../../../../store/selectors/quote-data.selectors';
import { untilDestroyed } from '@ngneat/until-destroy';
import { selectRatePlanSelectedForOfflineBid } from '../../../../../store/selectors/result-data.selectors';
import { IQuoteDataState } from '../../../../../store/states/quote-data.state';
import { showPopupAction } from '../../../../../store/actions/popup.actions';
import { selectFriendlyId } from '../../../../../store/selectors/interview-metadata.selector';
import { selectAgentPhoneNumber } from '../../../../../store/selectors/active-theme.selector';

import * as moment from 'moment';
import { CoverageModel } from '../../../../../models/coverage.model';
import { combineLatest, first } from 'rxjs';
import { ActionApiService } from 'src/app/services/action-api.service';
import { LobService } from 'src/app/services/lob.service';
import { TranslateService } from '@ngx-translate/core';
import { numOfMonthsInTerm } from '../../../../../utils/general.utils';

@Component({
	selector: 'app-call-agent',
	templateUrl: './call-agent.component.html',
	styleUrls: ['./call-agent.component.scss'],
})
export class CallAgentComponent extends BasePageComponent implements OnInit {
	quoteData: IQuoteDataState;
	rates: CoverageModel[];
	lob: string;
	logo: string;
	agentPhoneNumber: string;
	effectiveDate: string;
	friendlyId: string;
	firstName: string;
	actionApiService: ActionApiService;
	getLobService: LobService;

	constructor(
		injector: Injector,
		actionApiService: ActionApiService,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private translateService: TranslateService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	get holderAddress(): string {
		return [
			this.quoteData.PropertyAddress.AddressLine1,
			this.quoteData.PropertyAddress.City,
			this.quoteData.PropertyAddress.State,
			this.quoteData.PropertyAddress.ZipCode,
			this.quoteData.PropertyAddress.AddressLine2,
		].join(', ');
	}

	ngOnInit(): void {
		this.eventsService.addEvent(this.EventActions.END_SESSION);
		combineLatest([
			this.store.select(selectFriendlyId),
			this.store.select(selectQuoteData),
			this.store.select(selectRatePlanSelectedForOfflineBid),
			this.store.select(selectAgentPhoneNumber),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([friendlyId, quoteData, rates, agentPhoneNumber]) => {
				this.friendlyId = friendlyId;
				this.quoteData = quoteData;
				this.effectiveDate = moment(quoteData.EffectiveDate).format('MM/DD/YYYY');
				this.firstName = quoteData.FirstName.charAt(0).toUpperCase() + quoteData.FirstName.slice(1);
				this.rates = rates;
				this.agentPhoneNumber = agentPhoneNumber;
			});

		this.getLobService
			.getLob$()
			.pipe(first())
			.subscribe((lob) => {
				this.lob = lob;
			});
	}

	viewCoverage() {
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'view-coverage',
			button: 'view-coverage',
			module: 'call-agent-module',
		});
		this.store.dispatch(
			showPopupAction({
				componentName: 'all-coverages',
				payload: {
					text: this.translateService.instant('popups.titles.all-coverages') + this.friendlyId,
					rates: this.rates,
					isMonthly: false,
					gtmLabel: 'CoveragePopUp',
					customClass: 'side-popup',
					isOffline: true,
					isToggleCoveragesHidden: true,
					isActionsHidden: true,
				},
			})
		);
	}

	isBundle(): boolean {
		return this.rates.length === 2 && this.rates[0].carrier === this.rates[1].carrier;
	}

	getCarrierSubtitle(lob: string): string {
		return this.isBundle()
			? `${this.translateService.instant('lobs.' + this.rates[0].lob)} + ${this.translateService.instant(
					'lobs.' + this.rates[1].lob
			  )} Insurance`
			: `${this.translateService.instant('lobs.' + lob)} Insurance`;
	}

	callAgent(): void {
		this.routingService.callTo(this.agentPhoneNumber);
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'call-to-agent',
			button: 'call-to-agent',
			module: 'call-agent-module',
		});
	}

	getTermNumber(term: string) {
		return numOfMonthsInTerm(term);
	}
}
