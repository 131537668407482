import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { hidePopupAction } from '../../../../store/actions/popup.actions';
import { showAltRates } from '../../../../store/actions/result-data.actions';

@Component({
	selector: 'app-alt-carriers',
	templateUrl: './alt-carriers.component.html',
	styleUrls: ['./alt-carriers.component.scss'],
})
export class AltCarriersComponent {
	@Input() data: any;

	constructor(private store: Store) {}

	showAltCarriers() {
		this.store.dispatch(showAltRates({ show: true }));
		this.cancel();
	}

	cancel() {
		this.store.dispatch(hidePopupAction());
	}
}
