<section class="main-content">
  <app-page-heading [title]="'titles.whatLob' | translate"></app-page-heading>

  <app-radio-buttons (handleSelect)="handleSelect($event)" [options]="typeOfHomeOptionsHomeowners"
                     [selectedOption]="typeProperty" name="PLTypeOfDwelling">
  </app-radio-buttons>

  <next-button (clicked)="handleContinue()" [disabled]="this.typeProperty===''" continue="true" [title]="'buttons.continue' | translate">
  </next-button>
</section>
