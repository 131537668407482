import { createAction, props } from '@ngrx/store';

export const UpdateInterviewMetadata = createAction('[Interview Metadata] Update', props<{ data }>());
export const UpdateInterviewTexts = createAction('[Interview Texts] Update', props<{ data }>());
export const UpdateOriginYearBuilt = createAction('[Interview Metadata] Update Origin Year Built', props<{ data }>());
export const ResetInterviewMetadata = createAction('[Interview Metadata] Reset Interview Metadata');
export const UpdatePrefillIndication = createAction(
	'[Interview Metadata] Update Prefill Indication',
	props<{ data }>()
);
export const UpdateLobsInitialized = createAction(
	'[Interview Metadata] Update Lobs Initialized',
	props<{ lobsInitialized: string[] }>()
);
export const UpdateCrossSellTo = createAction(
	'[Interview Metadata] Update crossSellTo',
	props<{ crossSellTo: string[] }>()
);
export const removeCrossSellTo = createAction(
	'[Interview Metadata] Remove crossSellTo',
	props<{ crossSellTo: string[] }>()
);
export const addDriversOpened = createAction('[Interview Metadata] Add Driver Drivers Opened', props<{ Id: string }>());
export const addVehiclesOpened = createAction(
	'[Interview Metadata] Add Vehicle Vehicles Opened',
	props<{ Id: string }>()
);
