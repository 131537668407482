export const ADDITIONAL_COVERAGES_TEXTS = [
	{
		name: 'PersonalPropertyRC',
		withDD: false,
	},
	{
		name: 'OrdinanceorLawAmountofCoverage',
		withDD: true,
	},
	{
		name: 'FoundationCoverage',
		withDD: true,
		availableStates: ['TX'],
	},
	{
		name: 'WaterBackup',
		withDD: true,
	},
	{
		name: 'AdditionalDwellingCoverageLimit',
		withDD: true,
	},
	{
		name: 'PersonalInjury',
		withDD: false,
	},
	{
		name: 'IdentityTheft',
		withDD: false,
	},
];

export const OrdinanceorLawAmountofCoverageOptions = [
	{ id: 1, value: 'TenPrecent', title: '10%' },
	{ id: 2, value: 'TwentyFivePrecent', title: '25%' },
	{ id: 3, value: 'FiftyPrecent', title: '50%' },
	{ id: 4, value: 'SeventyFivePrecent', title: '75%' },
	{ id: 5, value: 'HondredPrecent', title: '100%' },
];

export const FoundationCoverageOptions = [
	{ id: 1, value: 'TenThousand', title: '$10,000' },
	{ id: 2, value: 'FiftheenThousand', title: '$15,000' },
	{ id: 3, value: 'TwentyThousand', title: '$20,000' },
	{ id: 4, value: 'TwentyFiveThousand', title: '$25,000' },
	{ id: 5, value: 'FiftyThousand', title: '$50,000' },
	{ id: 6, value: 'OneHunderdThousand', title: '$100,000' },
];

export const WaterBackupOptions = [
	{ id: 1, value: 'Cov1000', title: '$1,000' },
	{ id: 2, value: 'Cov2000', title: '$2,000' },
	{ id: 3, value: 'Cov3000', title: '$3,000' },
	{ id: 4, value: 'Cov4000', title: '$4,000' },
	{ id: 5, value: 'Cov5000', title: '$5,000' },
];

export const AdditionalDwellingCoverageLimitOptions = [
	{ id: 1, value: 'TwentyFivePercent', title: '25%' },
	{ id: 2, value: 'FiftyPercent', title: '50%' },
	{ id: 3, value: 'OneHundredPercent', title: '100%' },
];
