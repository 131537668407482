import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'toggle-slider',
	templateUrl: './toggle-slider.component.html',
	styleUrls: ['./toggle-slider.component.scss'],
})
export class ToggleSliderComponent implements OnChanges {
	Input;
	@Output() parentFun: EventEmitter<any> = new EventEmitter();
	@Input() value;
	@Input() disabled;
	in;
	@Input() accessibilityLabel: string;

	constructor() {}

	ngOnChanges(): void {
		this.in = this.value;
	}

	handleToggle() {
		setTimeout(() => {
			this.in = !this.in;
			this.parentFun.emit(this.in);
		}, 400);
	}
}
