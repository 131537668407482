<ng-template #vehicleTemplate let-formVehicle="formVehicle">
	<section class="main-content main-content-vehicle-cover">
		<form #myform="ngForm" [formGroup]="formVehicle" class="applicant-form stage">
			<div class="questions-container">
				<div class="header-vehicle">
					<img
						alt="{{ form?.value?.PLMake }} logo"
						class="car-logo"
						src="assets/images/car-logos/thumb/{{ getVehicleLogoName(formVehicle?.value?.PLMake) }}.png"
					/>
					<p class="name">
						{{ formVehicle.value.PLYear }} {{ formVehicle.value.PLModel }} {{ formVehicle.value.PLMake }}
					</p>
				</div>

				<app-question-wrapper
					[show]="formVehicle.get('CompDeductible') && formVehicle.get('CompDeductible').enabled"
					class="CompDeductible"
				>
					<app-dropdown
						[options]="FieldOptions.CompDeductibleOptions"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.comprehensiveDeductible' | translate"
						name="CompDeductible"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="formVehicle.get('CollDeductible') && formVehicle.get('CollDeductible').enabled"
					class="CollisionDed"
				>
					<button
						appHiddenWhenCssDisabled
						class="info-icon material-icons"
						[matTooltip]="'labels.CollisionDisabled' | translate"
						*ngIf="formVehicle.get('CompDeductible').value == 'NoCoverage'"
						appToggleTooltip
					>
						<span aria-hidden="true">info_outline</span>
					</button>
					<app-dropdown
						[disabled]="formVehicle.get('CompDeductible').value == 'NoCoverage'"
						[options]="FieldOptions.CollDeductibleOptions"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.collisionDeductible' | translate"
						name="CollDeductible"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="formVehicle.get('TowingAndLabor') && formVehicle.get('TowingAndLabor').enabled"
					class="TowingAndLabor"
				>
					<app-dropdown
						[name]="'TowingAndLabor'"
						[options]="TowingLaborListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="formVehicle.get('TowingAndLabor') && formVehicle.get('TowingAndLabor').enabled ? 0 : -1"
						[labelInsideField]="'labels.towingAndLabor' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="formVehicle.get('TransportationExpense') && formVehicle.get('TransportationExpense').enabled"
					class="TransportationExpense"
				>
					<app-dropdown
						[name]="'TransportationExpense'"
						[options]="TransportationExpenseListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="
							formVehicle.get('TransportationExpense') && formVehicle.get('TransportationExpense').enabled ? 0 : -1
						"
						[labelInsideField]="'labels.transportationExpense' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="formVehicle.get('FullGlass') && formVehicle.get('FullGlass').enabled"
					class="FullGlass"
				>
					<app-dropdown
						[name]="'FullGlass'"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="formVehicle.get('FullGlass') && formVehicle.get('FullGlass').enabled ? 0 : -1"
						[labelInsideField]="'labels.fullGlass' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="formVehicle.get('LoanLease') && formVehicle.get('LoanLease').enabled"
					class="LoanLease"
				>
					<app-dropdown
						[name]="'LoanLease'"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="formVehicle.get('LoanLease') && formVehicle.get('LoanLease').enabled ? 0 : -1"
						[labelInsideField]="'labels.gapInsurance' | translate"
					></app-dropdown>
				</app-question-wrapper>
			</div>
		</form>
	</section>
</ng-template>

<section class="main-content main-content-personal-details">
	<app-page-heading title="What coverage would you like?"></app-page-heading>
	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div [ngStyle]="{ width: (internalStep / (getAllVehicles().length + 1)) * 100 + '%' }" class="progress-bar"></div>

			<div [ngClass]="{ show: internalStep == 1 }" class="BI step">
				<app-question-wrapper
					[show]="form.get(Fields.BodilyInjuryliability.name) && form.get(Fields.BodilyInjuryliability.name).enabled"
					class="BodilyInjuryliability"
				>
					<app-dropdown
						[name]="Fields.BodilyInjuryliability.name"
						[options]="BodilyInjuryliabilityListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="
							form.get(Fields.BodilyInjuryliability.name) && form.get(Fields.BodilyInjuryliability.name).enabled
								? 0
								: -1
						"
						[labelInsideField]="'labels.bodilyInjuryLiability' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [show]="form.get('UM') && form.get('UM').enabled" class="UM">
					<app-dropdown
						[name]="'UM'"
						[options]="UMListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get('UM') && form.get('UM').enabled ? 0 : -1"
						[labelInsideField]="'labels.uninsuredMotorist' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [show]="form.get('PD') && form.get('PD').enabled" class="PD">
					<app-dropdown
						[name]="'PD'"
						[options]="PDListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get('PD') && form.get('PD').enabled ? 0 : -1"
						[labelInsideField]="'labels.propertyDamageLiability' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [show]="form.get('MP') && form.get('MP').enabled" class="MP">
					<app-dropdown
						[name]="'MP'"
						[options]="MPListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get('MP') && form.get('MP').enabled ? 0 : -1"
						[labelInsideField]="'labels.medicalPayments' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [show]="form.get('UMPD') && form.get('UMPD').enabled" class="UMPD">
					<app-dropdown
						[name]="'UMPD'"
						[options]="UMPDListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get('UMPD') && form.get('UMPD').enabled ? 0 : -1"
						[labelInsideField]="'labels.uninsuredMotoristPropertyDamage' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper [show]="form.get('PIP') && form.get('PIP').enabled" class="PIP">
					<app-dropdown
						[name]="'PIP'"
						[options]="PIPLIMITListOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get('PIP') && form.get('PIP').enabled ? 0 : -1"
						[labelInsideField]="'labels.pipLimit' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="form.get('AccidentForgiveness') && form.get('AccidentForgiveness').enabled"
					class="AccidentForgiveness"
				>
					<app-dropdown
						[name]="'AccidentForgiveness'"
						[options]="FieldOptions.YesNoOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get('AccidentForgiveness') && form.get('AccidentForgiveness').enabled ? 0 : -1"
						[labelInsideField]="'labels.enrollInPgrAccidentForgiveness' | translate"
					></app-dropdown>
				</app-question-wrapper>

				<div class="spacer"></div>
				<next-button
					(click)="navigateInternal(1)"
					[disabled]="!this.form.controls['BI'].valid"
					[loaderVisible]="false"
					continue="true"
					[title]="'buttons.continue' | translate"
				></next-button>
			</div>

			<div
				*ngFor="let vehicle of getAllVehicles(); let i = index"
				[ngClass]="{ show: internalStep == i + 2 }"
				class="collisions step"
			>
				<ng-container *ngTemplateOutlet="vehicleTemplate; context: { formVehicle: vehicle }"></ng-container>
				<div class="spacer"></div>
				<next-button
					(click)="navigateInternal(1)"
					(validationFailed)="handleValidation($event)"
					[disabled]="!vehicle.valid"
					continue="true"
					[title]="'buttons.continue' | translate"
				></next-button>
				<button (click)="navigateInternal(-1)" class="back-button">{{ "buttons.goBack" | translate }}</button>
			</div>
		</div>
	</form>
</section>
