import { createAction, props } from '@ngrx/store';
import { ILoaderData } from 'src/entities/loader-data';
import { IServerRes } from 'src/entities/payload.interface';

export const NavigateToNextPage = createAction('[Routing] To Next Page', props<IServerRes>());
export const NavigateToErrorPage = createAction('[Routing] Navigate To Error Page', props<{ errorType: string }>());
export const NavigateToFirstStage = createAction('[Routing] Navigate To First Stage');
export const NavigateBack = createAction('[Routing] Navigate Back');
export const ShowLoader = createAction('[Routing] Show Loader', props<{ data: ILoaderData }>());
export const HideRouter = createAction('[Routing] Hide Router');
export const GenerateBundleUrl = createAction('[Routing] Generate Bundle Url');
export const HideLocalAgentMsg = createAction('[Routing] Hide HideLocalAgentMsg');
export const NavigateInternalStep = createAction('[Routing] Navigate Internal Step', props<{ forward: boolean }>());
