<section class="main-content">
	<app-page-heading [title]="pageTitle"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="question-selector-wrapper">
			<div
				(click)="handleChooseAnswer('yes')"
				*ngIf="this.originYear"
				[ngClass]="{ selected: yesNoYearBuilt === 'yes' }"
				class="option yes-option"
			>
				<div class="inner-wrapper">
					<div class="circle">
						<div class="inner"></div>
					</div>
					<span>{{ "labels.yes" | translate }}</span>
				</div>
			</div>

			<div
				#nooption
				(click)="handleChooseAnswer('no')"
				[ngClass]="{ selected: yesNoYearBuilt === 'no', noOriginYear: !this.originYear }"
				class="option no-option"
			>
				<div *ngIf="this.originYear" class="inner-wrapper">
					<div class="circle">
						<div class="inner"></div>
					</div>
					<span>{{ "labels.no" | translate }}</span>
				</div>

				<div [ngClass]="{ hidden: yesNoYearBuilt === 'yes' }" class="option-additional">
					<app-question-wrapper class="year-built-wrapper" icon="foundation">
						<unmasked-input
							[name]="Fields.YearBuilt.name"
							[pattern]="Fields.YearBuilt.pattern"
							[placeholder]="'home.yearBuilt' | translate"
							maxlength="4"
							type="text"
						>
						</unmasked-input>
					</app-question-wrapper>
				</div>
			</div>
		</div>
	</form>
	<next-button
		[disabled]="!this.form.valid"
		[myForm]="{ form: form }"
		[title]="'buttons.continue' | translate"
	></next-button>
</section>
