import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { getD2CFeatures } from '../utils/general.utils';
@Directive({
	selector: '[featureFlag]',
})
export class FeatureFlagDirective {
	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

	@Input() set featureFlag(featureName: string) {
		if (getD2CFeatures()?.[featureName]) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
