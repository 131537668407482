import { createSelector } from '@ngrx/store';
import { IAppState } from '../states/app.state';
import { GeneralPropsEnum } from '../../models/general-props.enum';
import { SectionTypesEnum } from '../../models/section-types.enum';

const activeTheme = (state: IAppState) => state.theme;

export const selectActiveTheme = createSelector(activeTheme, (activeThemeState) => activeThemeState);

export const selectAlternativeLogo = createSelector(
	activeTheme,
	(activeThemeState) => activeThemeState?.localSettings?.altLogo
);

export const selectFooter = createSelector(
	activeTheme,
	(activeThemeState) =>
		activeThemeState[GeneralPropsEnum.landingPage].filter(({ type }) => type === SectionTypesEnum.footer)[0]
);

export const selectAgentPhoneNumber = createSelector(
	activeTheme,
	(activeThemeState) => activeThemeState[GeneralPropsEnum.agentPhoneNumber]
);
