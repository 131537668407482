import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { inOutAnimation } from 'src/app/animations';
import { BasePageComponent } from '../../base-page.component';
import { first, Observable } from 'rxjs';
import { IQuoteDataState } from 'src/app/store/states/quote-data.state';
import { LobsEnum } from '../../../../../enums/lobs.enum';

@UntilDestroy()
@Component({
	selector: 'safety-alarms-page',
	templateUrl: './safety-alarms.component.html',
	styleUrls: ['./safety-alarms.component.scss'],
	animations: [inOutAnimation],
})
export class SafetyAlarmsPage extends BasePageComponent implements OnInit, OnDestroy {
	@Output() changePage = new EventEmitter();

	form;
	showSprinklerSystem: boolean;
	public quoteData$: Observable<IQuoteDataState>;

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store, quoteDataService);
		this.quoteData$ = this.store.select(selectQuoteData);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form);
		this.toggleControl(
			this.form.controls.FireDetection.value && !this.form.controls['FireDetection'].disabled,
			'FireDetectionType'
		);
		this.toggleControl(
			this.form.controls.BurglarAlarm.value && !this.form.controls['BurglarAlarm'].disabled,
			'BurglarAlarmType'
		);
		this.toggleControl(
			this.form.controls.SprinklerSystem.value && !this.form.controls['SprinklerSystem'].disabled,
			'SprinklerSystemType'
		);
	}

	initForm(): void {
		this.form = new FormGroup({
			SmokeDetector: new FormControl({ value: false, disabled: false }, [Validators.required]),
			DeadBoltLocks: new FormControl({ value: false, disabled: false }, [Validators.required]),
			FireExtinguisher: new FormControl({ value: false, disabled: false }, [Validators.required]),
			FireDetection: new FormControl({ value: false, disabled: false }, [Validators.required]),
			BurglarAlarm: new FormControl({ value: false, disabled: false }, [Validators.required]),
			SprinklerSystem: new FormControl({ value: false, disabled: false }, [Validators.required]),
			// child controls
			FireDetectionType: new FormControl({ value: null, disabled: true }, [Validators.required]),
			BurglarAlarmType: new FormControl({ value: null, disabled: true }, [Validators.required]),
			SprinklerSystemType: new FormControl({ value: null, disabled: true }, [Validators.required]),
		});

		this.quoteData$.pipe(first()).subscribe((res) => {
			if (res.Lobs.filter((i) => i == LobsEnum.PERSONAL_HOME).length == 1) {
				this.form.controls.SprinklerSystem.enable();
				this.form.controls.SprinklerSystemType.enable();
			} else {
				this.form.controls.SprinklerSystem.disable();
				this.form.controls.SprinklerSystemType.disable();
			}
		});

		this.form.controls.FireDetection.valueChanges.subscribe((value) => {
			this.toggleControl(value, 'FireDetectionType');
		});

		this.form.controls.BurglarAlarm.valueChanges.subscribe((value) => {
			this.toggleControl(value, 'BurglarAlarmType');
		});

		this.form.controls.SprinklerSystem?.valueChanges.subscribe((value) => {
			this.toggleControl(value, 'SprinklerSystemType');
		});
	}

	toggleControl(toggleStatus, controlName) {
		if (!toggleStatus) {
			this.form.controls[controlName].setValue(null);
			this.form.controls[controlName].disable();
		} else {
			this.form.controls[controlName].enable();
		}
	}
}
