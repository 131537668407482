<form [formGroup]="form" class="button-cover">
	<label>
		<input [value]="false" formControlName="control" tabindex="0" type="radio" />
		<span class="option">{{ falseText }}</span>
	</label>
	<label>
		<input [value]="true" formControlName="control" type="radio" />
		<span class="option">{{ trueText }}</span>
	</label>
</form>
