import { Injectable } from '@angular/core';
import { selectEffectiveDate } from '../store/selectors/quote-data.selectors';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class FormattedDateService {
	constructor(private store: Store) {}

	getFormattedPolicyStartDate(format?: string): Observable<string> {
		return this.store.select(selectEffectiveDate).pipe(
			map((data) => {
				const dateFormat = format ? format : 'DD MMMM YYYY';
				return moment(data).isValid() ? moment(data).format(dateFormat) : '';
			})
		);
	}

	formatDate(data?: Date, format?: string) {
		format ??= 'YYYY-MM-DDTHH:mm:ss';
		return moment(data).format(format);
	}

	findYearOnDate(date) {
		if (date) {
			const regex = /\d{4}/g;
			const matches = date.match(regex);
			if (matches && matches.length > 0) {
				return 'XX/XX/' + matches[0];
			}
		}
		return date;
	}

	formatDateIfItIsNotMasked(date, format?) {
		const DEFAULT_FORMAT_DATE = 'MM/DD/YYYY';
		if (date) {
			if (date.includes('XX')) {
				return date;
			} else {
				return moment(date).format(format ? format : DEFAULT_FORMAT_DATE);
			}
		} else {
			return date;
		}
	}
}
