<div class="toggle">
	<label class="switch">
		<input
			(click)="handleToggle()"
			[attr.aria-label]="accessibilityLabel"
			[checked]="in"
			[disabled]="disabled"
			type="checkbox"
		/>
		<span class="slider round"></span>
		<span class="mat-slide-toggle-content">{{ accessibilityLabel }}</span>
	</label>
</div>
