import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { first } from 'rxjs';

import { typeOfHomeOptionsHomeowners } from 'src/app/constants/field-options';
import { RoutingActions } from 'src/app/store/actions';
import { selectInterviewMetadata, selectTypeProperty } from 'src/app/store/selectors/interview-metadata.selector';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { LobsEnum } from 'src/app/enums/lobs.enum';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesHomeownersComponent implements OnInit {
  typeOfHomeOptionsHomeowners = typeOfHomeOptionsHomeowners;
  typeProperty = '';
  optionsLobs = [];
  lobSelection;
  constructor(
    private store: Store,
    private progressMeterService: ProgressMeterService
  ) {
  }

  ngOnInit(): void {
    this.getLastSelectionProperty();
    this.store.select(selectInterviewMetadata).pipe(first()).subscribe((interviewMetadata) => {
      this.lobSelection = interviewMetadata.lobSelection;
      if (interviewMetadata._typeProperty) {
        this.typeProperty = interviewMetadata._typeProperty;
      }
      this.store.dispatch(UpdateInterviewMetadata({ data: { prevTypeProperty: interviewMetadata._typeProperty } }));
    });
  }

  getLastSelectionProperty() {
    this.store.select(selectTypeProperty).pipe().subscribe((data) => {
      this.typeProperty ??= data;
    });
  }

  handleSelect(value) {
    this.typeProperty = value;
    // if lobSelection have LobsEnum.PERSONAL_HOME or LobsEnum.CONDOMINIUM and it's not the same as value we need to replace it with value but keep the rest of the lobs
    if (this.lobSelection.includes(LobsEnum.PERSONAL_HOME) || this.lobSelection.includes(LobsEnum.CONDOMINIUM)) {
      this.lobSelection = this.lobSelection.filter((lob) => lob !== LobsEnum.PERSONAL_HOME && lob !== LobsEnum.CONDOMINIUM);
      this.lobSelection = [value, ...this.lobSelection];
    }

    this.lobSelection
    this.store.dispatch(UpdateInterviewMetadata({ data: { _typeProperty: value, lobSelection: this.lobSelection } }));
  }

  handleContinue() {
    this.progressMeterService.goToNextPage();
  }
}
