import {
	AfterViewInit,
	Component,
	EventEmitter,
	HostBinding,
	Injector,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { BaseFormControlComponent } from '../base-form-control.component';
import { IDropdownOption } from '../form-control-interfaces';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty, isMobile } from '../../../utils/general.utils';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { ControlsType } from 'src/app/constants/controls-type';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends BaseFormControlComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() searchable = false;
	@Input() options: IDropdownOption[] = [];
	@Input() labelInsideField: string;
	@Input() isAddressDropdown: boolean;
	@Input() multiple = false;
	@Input() withoutErrorMessage: boolean;
	@Input() forceExpand: boolean;
	@Input() placeholder: string;
	@Input() reAlphabeticalOrder: boolean;
	@Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto';
	@Input() notShowLabelWhenSelected: boolean;
	@ViewChild(NgSelectComponent, { static: true })
	ngSelect: NgSelectComponent;
	@HostBinding('attr.name')
	@Input()
	namePrefix = '';
	isOpen: boolean;
	fullAutocomplete = false;
	invalid: boolean;
	@Input() disabled: boolean;
	@Output() dropdownOpen: EventEmitter<boolean> = new EventEmitter();
	isMobile = isMobile();

	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.isAddressDropdown) {
			this.setValueOnAddressChange(changes);
			this.setValue();
		}
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.isOpen = false;

		if (
			this.options &&
			this.options[0] &&
			isNaN(Number.parseInt(this.options[0].title.charAt(0), 10)) &&
			this.reAlphabeticalOrder !== false
		) {
			this.options.sort((a, b) => a.title.localeCompare(b.title));
		}

		this.setValue();
		this.controlType = ControlsType.dropdown;
	}

	setValue(): void {
		this.value = this.controlContainer.control.controls[this.name].value;
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();

		const elem: HTMLInputElement = this.getNativeInput();
		const combobox: HTMLInputElement = this.getNativeCombobox();
		this.renderer.setAttribute(elem, 'id', this.name.split('.').join('_'));
		this.renderer.setAttribute(combobox, 'aria-label', this.name);
		this.updateDescribedByAttribute();

		if (!this.fullAutocomplete) {
			elem.addEventListener('keyup', (event) => {
				// if backspace hit then reset list and show all options again.
				if (event.keyCode === 8 && isEmpty(elem.value)) {
					this.ngSelect.close();
					this.isOpen = false;
					this.ngSelect.open();
					this.isOpen = true;
				}
			});
		}
	}

	getNativeInput(): HTMLInputElement {
		return this.elementRef.nativeElement.querySelectorAll('input')[0];
	}

	getNativeCombobox(): HTMLInputElement {
		return this.elementRef.nativeElement.querySelectorAll('[role="combobox"]')[0];
	}

	onBlur(): void {
		this.ngSelect.close();
		super.baseOnBlur();
		this.isOpen = false;
		const field = this.controlContainer.control.controls[this.name];
		field.markAsTouched();

		setTimeout(() => {
			this.invalid = field.status === 'INVALID';
			this.updateDescribedByAttribute();
		}, 1);
	}

	onFocus(): void {
		super.baseOnFocus();
	}

	setValueOnAddressChange(changes): void {
		if (changes && changes.options && changes.options.currentValue) {
			if (changes.options.currentValue.length >= 1) {
				this.value = changes.options.currentValue[0].value;
				if (changes.options.currentValue.length === 1) {
					this.controlContainer.control.controls[this.name].disable();
					this.disabled = true;
				} else {
					this.controlContainer.control.controls[this.name].enable();
					this.disabled = false;
				}
			} else {
				this.value = null;
				this.controlContainer.control.controls[this.name].disable();
				this.disabled = true;
			}
		} else {
			this.value = null;
			this.controlContainer.control.controls[this.name].disable();
			this.disabled = true;
		}
	}

	updateAnswer(item): void {
		if (item && item.type === 'change') {
			return;
		}

		this.value = item;
		this.controlContainer.control.controls[this.name].setValue(this.value);
	}

	updateDescribedByAttribute() {
		if (this.isInvalid()) {
			this.renderer.setAttribute(this.getNativeInput(), 'aria-describedby', this.name + '_error');
		} else {
			this.renderer.removeAttribute(this.getNativeInput(), 'aria-describedby');
		}
	}

	onClose(): void {
		event.stopPropagation();
		this.dropdownOpen.emit(false);
	}

	onOpen(): void {
		this.dropdownOpen.emit(true);
	}

	isInvalid(): boolean {
		const field = this.controlContainer.control.controls[this.name];
		if (!field) {
			return false;
		}
		return field.invalid && field.touched;
	}
}
