<div class="main-wrapper">
	<button
		(click)="onNextButtonClick()"
		[attr.aria-label]="title"
		[attr.data-automation-data]="last_route"
		[class.transparent]="loading || doneLoading"
		[disabled]="disabled ? 'disabled' : null"
		[ngClass]="{ loading: loading }"
		class="app-button"
		data-automation-element="next-button-element"
		type="button"
	>
		<span [class.invisible]="loading || doneLoading" class="title">
			<span *ngIf="title">{{ title }}</span>
			<span *ngIf="titleKey" [appTranslation]="titleKey"></span>
			<i *ngIf="showIcon" appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
		</span>
		<span [ngClass]="{ show: loading }" class="loader"></span>
	</button>

	<p
		*ngIf="showTextLoading && !textLoading && loading"
		[appHiddenWhenCssDisabled]="!showTextLoading || !loading"
		[ngClass]="{ show: showTextLoading && loading }"
		class="text-loading-button"
	>
	<ng-container *ngIf="!textLoading; else textloading">
		{{ "labels.justMoment" | translate }}<br />{{ "labels.propertyDetailsRetrieving" | translate }}
	</ng-container>
	<ng-template #textloading>
		<p>
		  {{textLoading}}
		</p>
	  </ng-template>
	</p>
	<p
		*ngIf="showTextLoading && textLoading && loading"
		[appHiddenWhenCssDisabled]="!showTextLoading || !loading"
		[ngClass]="{ show: showTextLoading && loading }"
		class="text-loading-button"
	>
		{{ textLoading }}
	</p>
</div>
