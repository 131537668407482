<section class="main-content">
	<app-page-heading subtitleKey="policy-date-picker.subTitle" titleKey="policy-date-picker.title"></app-page-heading>

	<form #myForm="ngForm" [formGroup]="form" class="stage-choose">
		<div [ngClass]="{ 'auto-calendar': lob == 'PersonalAuto' }" class="calendar-start-policy-datpicker">
			<mat-card *ngIf="showCalendar" class="demo-inline-calendar-card calendar-card">
				<mat-calendar
					(selectedChange)="updateFormDate($event)"
					[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
					[minDate]="minDate"
					[selected]="moment(form.get(questionName).value)"
				>
				</mat-calendar>
			</mat-card>
		</div>
	</form>
	<div class="error-container">
		<error-message
			[describedByErrorId]="describedByErrorId"
			[fieldDisplayName]="questionName"
			[field]="getFormControl()"
			showIconError="true"
		>
		</error-message>
	</div>

	<next-button
		(validationFailed)="handleValidation($event)"
		[addPrefill]="prefilledIndicationKeys"
		[animation]="true"
		[disabled]="!!form.get(questionName).errors"
		[myForm]="myForm"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
