<section class="main-content">
	<app-page-heading
		subtitleKey="summary.callToAgentPageSubtitle"
		titleKey="summary.callToAgentPageTitle"
	></app-page-heading>

	<div class="questions-container">
		<div class="content">
			<div class="top">
				<ng-container *ngFor="let rate of rates; let index = index">
					<div *ngIf="rate.carrier !== rates[index - 1]?.carrier" class="carrier-container">
						<img
							[attr.alt]="rate.carrier + ' logo'"
							[src]="this.actionApiService.getLogo(rate.carrier, rate.lob)"
							role="presentation"
						/>
					</div>
				</ng-container>
			</div>

			<caption [attr.aria-label]="'titles.quoteSummary' | translate" class="sr-only">
				{{
					"titles.quoteSummary" | translate
				}}
			</caption>

			<table class="table">
				<tr class="table-row">
					<td [attr.aria-label]="'labels.quoteNumber' | translate" class="row-label">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">policy</i>
						<b>{{ "labels.quoteNumber" | translate }}</b>
					</td>

					<td [attr.aria-label]="friendlyId" class="row-content">
						{{ friendlyId }}
					</td>
					<button
						(click)="viewCoverage()"
						[attr.aria-label]="'buttons.viewCoverage' | translate"
						class="link"
						tabindex="0"
						type="button"
					>
						{{ "buttons.viewCoverage" | translate }}
					</button>
				</tr>
				<tr class="table-row">
					<td [attr.aria-label]="'labels.policyEffectiveDate' | translate" class="row-label">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">calendar_month</i>
						<b>{{ "labels.policyEffectiveDate" | translate }}</b>
					</td>
					<td [attr.aria-label]="effectiveDate" class="row-content">{{ effectiveDate }}</td>
				</tr>
				<tr class="table-row">
					<td [attr.aria-label]="'labels.policyEffectiveDate' | translate" class="row-label">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">calendar_month</i>
						<b>{{ "labels.policyTerm" | translate }}</b>
					</td>
					<td [attr.aria-label]="effectiveDate" class="row-content">
						<ng-container *ngIf="rates.length > 1">
							<ng-container *ngFor="let rate of rates; let index = index">
								<div class="term-container">
									<div
										*ngIf="isBundle()"
										[attr.aria-label]="('labels.productName' | translate) + ' - ' + rate.lob"
										class="term-title"
									>
										{{ "lobs." + rate.lob | translate }}
									</div>
									<div
										*ngIf="!isBundle()"
										[attr.aria-label]="('labels.carrierName' | translate) + ' - ' + rate.carrier"
										class="term-title"
									>
										{{ rate.carrier }}
									</div>
									<div
										[attr.aria-label]="('labels.carrierLob' | translate) + ' - ' + getCarrierSubtitle(rate.lob)"
										class="carrier-subtitle"
									>
										{{ getTermNumber(rate.term) }} {{ " " }} {{ "labels.months" | translate }}
									</div>
								</div>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="rates.length === 1">
							{{ getTermNumber(rates[0].term) }} {{ " " }} {{ "labels.months" | translate }}
						</ng-container>
					</td>
				</tr>
				<tr class="table-row">
					<td [attr.aria-label]="'labels.policyHolderInformation' | translate" class="row-label">
						<i appHiddenWhenCssDisabled aria-hidden="true" class="material-icons" role="presentation">person</i>
						<b>{{ "labels.policyHolder" | translate }}</b>
					</td>
					<td class="row-content">
						<p [attr.aria-label]="quoteData.FirstName + ' ' + quoteData.LastName" class="details-policy-holder">
							{{ quoteData.FirstName }} {{ quoteData.LastName }}
						</p>
						<p [attr.aria-label]="holderAddress" class="details-policy-holder">
							{{ holderAddress }}
						</p>
						<p [attr.aria-label]="quoteData.PrimaryPhoneNumber" class="details-policy-holder">
							{{ quoteData.PrimaryPhoneNumber | phone }}
						</p>
					</td>
				</tr>
			</table>

			<div [attr.aria-label]="'labels.callToFinalizeQuote' | translate" class="call-agent-disclaimer">
				{{ "labels.callToFinalizeQuote" | translate }}
			</div>

			<button (click)="callAgent()" class="app-button" tabindex="0">
				<span class="title">
					<i appHiddenWhenCssDisabled class="material-icons" role="presentation">phone</i>
					{{ agentPhoneNumber }}
				</span>
			</button>
		</div>
	</div>
</section>
