<div [class.selected]="isSelected" [class.short]="isShortMode" class="questions-container">
	<div class="row align-center">
		<div class="col-md-auto logo">
			<img [src]="this.actionApiService.getLogo(coverage.carrier, this.lob)" alt="null" role="presentation" />
		</div>
		<div class="col-md-auto text">
			<h4 *ngIf="title" [attr.aria-label]="title" role="heading" tabindex="0">{{ title }}</h4>
		</div>
		<button
			[attr.aria-label]="'labels.addComprehensiveNeptuneFlood' | translate"
			[matTooltip]="'labels.addComprehensiveNeptuneFlood' | translate"
			appHiddenWhenCssDisabled
			appToggleTooltip
			class="help-icon material-icons"
		>
			<span aria-hidden="true">help_outline</span>
		</button>

		<div class="col-md-auto price">
			+ ${{ coverage.premium | number: "1.0-0" }}/{{ yearlyTermDisplayName(coverage.term, true) | translate }}
			<div
				[attr.aria-label]="
					('labels.pricePer' | translate) +
					(yearlyTermDisplayName(coverage.term) | translate) +
					('labels.isCost' | translate) +
					pricePerMonth(coverage.premium, coverage.term)
				"
				class="price-monthly"
				tabindex="0"
			>
				(${{ pricePerMonth(coverage.premium, coverage.term) | number: "1.0-0" }}/mo)
			</div>
		</div>

		<ng-container *ngIf="loading; else continue">
			<span class="material-icons loader-toggled"></span>
		</ng-container>
		<ng-template #continue>
			<div class="col-md-auto toggle">
				<toggle-slider
					(parentFun)="handleToggle($event, true)"
					[accessibilityLabel]="title"
					[value]="toggleValue"
				></toggle-slider>
			</div>
		</ng-template>
	</div>
</div>
