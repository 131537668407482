import * as moment from 'moment';
import { isEmpty } from 'lodash';

import { AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { ActionApiService } from '../services/action-api.service';
import { debounceTime, distinctUntilChanged, map, of, switchMap, take } from 'rxjs';

window.moment = moment;

const invalidCharStringValidation = (control: AbstractControl) => {
	const regex = /[<>&"%;:!\)\("\+\|~\*\?\^#\$\/\\@_={}\[\],\.\d]+/;
	if (control.value && regex.test(control.value)) {
		return { invalidChars: true };
	}
	return null;
};

const emailValid = (control: AbstractControl) => {
	if (/^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.\+]+)\.([a-zA-Z]{2,5})$/.test(control.value)) {
		return null;
	} else {
		return { invalidEmail: true };
	}
};

const emailValidAuto = (control: AbstractControl) => {
	if (/^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.\+]+)\.([a-zA-Z]{2,5})$/.test(control.value)) {
		return null;
	} else {
		return { invalidEmailAuto: true };
	}
};
const valueEquals = (value) => (ctrl: AbstractControl) => ctrl.value == value ? null : { valueNotEquals: true };

function dateLessThan(from: string, to: string) {
	return (group: FormGroup): { [key: string]: any } => {
		const f = group.controls[from];
		const t = group.controls[to];
		try {
			if (moment(f.value).isAfter(moment(t.value))) {
				return {
					beforeDate: true,
				};
			}

			if (t.value.diff(f.value, 'days') > 180) {
				return {
					exceededMaxDays: true,
				};
			}
			return {};
		} catch (e) { }
	};
}

const validPhoneNumber = (control: AbstractControl) => {
	const regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
	const phone = control.value;

	if (phone && regex.test(phone)) {
		return null;
	}
	return { invalidPhoneNumber: true };
};

const validPhoneNumberAuto = (control: AbstractControl) => {
	const regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
	const phone = control.value;

	if (phone && regex.test(phone)) {
		return null;
	}
	return { invalidPhoneNumberAuto: true };
};

const validAddress = (control: AbstractControl) => {
	const regex = /^[A-zÀ-ú.,0-9'/\s]*$/;
	if (control.value && regex.test(control.value)) {
		return null;
	}
	return { invalidAddress: true };
};

const postalCode = (control: AbstractControl) => {
	const regex = /^[0-9]{5}$/;
	if (control.value && !regex.test(control.value)) {
		return { PostalCodeMinChars: true };
	}
	return null;
};

const postalCodeNumberOnly = (control: AbstractControl) => {
	const regex = /^[0-9]{5}$/;
	if (control.value && !regex.test(control.value)) {
		return { postalCodeNumbersOnly: true };
	}
	return null;
};

const minMaxAge = (travelInfo) => (ctrl: AbstractControl) => {
	const dateOfBirth = moment(ctrl.value);
	// validation for lob of type travel
	if (travelInfo && travelInfo.departureDate && travelInfo.returnDate) {
		const departureDate = moment(travelInfo.departureDate);
		const returnDate = moment(travelInfo.returnDate);
		if (departureDate.diff(dateOfBirth, 'years') < 18) {
			return { ageLessThanDeparture: true };
		}
		if (returnDate.diff(dateOfBirth, 'years') >= 65) {
			return { ageGreaterThanReturn: true };
		}
	} else {
		const now = moment();
		if (now.diff(dateOfBirth, 'years') < 18) {
			return { ageLessThan18: true };
		}
		if (now.diff(dateOfBirth, 'years') >= 120) {
			return { ageGreaterThan120: true };
		}
	}
};

const dateOfBirthBefore1900 = (control: AbstractControl) => {
	if (moment(control.value).year() >= 1900) {
		return null;
	}
	return { dateOfBirthBefore1900: true };
};

const alphabetAndDigitsAnd = (control: AbstractControl) => {
	const regex = /^[\w]*$/;
	if (control.value && regex.test(control.value)) {
		return null;
	}
	return { invalidAlphabetAndDigitsAnd: true };
};

const SSN = (control: AbstractControl) => {
	const regex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
	const pristineRegex = /^[•X0-9]{3}-[•X0-9]{2}-[0-9]{4}$/;

	if (
		!control.value ||
		(control.pristine && pristineRegex.test(control.value)) ||
		(control.value && regex.test(control.value))
	) {
		return null;
	}
	return { invalidSSN: true };
};

const numbersdDigits = (control: AbstractControl) => {
	const regex = /^[0-9]*$/;
	if ((control.value || control.value === 0) && regex.test(control.value)) {
		return null;
	}
	return { numbersdDigits: true };
};

const alphabet = (control: AbstractControl) => {
	const value = control?.value;
	if (!value) {
		return null;
	}

	const isValid = /^[a-zA-Z-'\s]*$/.test(value);
	return isValid ? null : { invalidAlphabet: true };
};

const MiddleNamealphabet = (control: AbstractControl) => {
	if (isEmpty(control.value)) {
		return null;
	}
	const regex = /^[a-zA-Z-'\s]*$/;
	if (control.value && regex.test(control.value)) {
		return null;
	}
	return { invalidAlphabet: true };
};

const fieldsMatch = (fieldA, fieldB) => {
	return (group: FormGroup) => {
		const controlA = group.controls[fieldA];
		const controlB = group.controls[fieldB];

		const errors =
			controlA.value != controlB.value
				? { ...controlB.errors, notMatch: true }
				: controlB.errors != null && Object.keys({ ...controlB.errors }).filter((k) => k !== 'notMatch').length
					? { ...controlB.errors }
					: null;
		controlB.setErrors(errors);
	};
};

const futureDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { futureDate: true };
	}
	return null;
};

const roofUpdatePastDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isBefore(moment())) {
		return { invalidPastDateRoofUpdate: true };
	}
	return null;
};

const roofUpdateFutureDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { invalidFutureDateRoofUpdate: true };
	}
	return null;
};

const FutureDateValid = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { FutureDateIsInvalid: true };
	}
	return null;
};

const plumbingUpdatePastDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { plumbingUpdatePastDate: true };
	}
	return null;
};

const futureDateAnd60Days = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	const days = moment(control.value).diff(moment(), 'days');
	if (moment(control.value).isAfter(moment()) && days > 60) {
		return { notfutureDateAnd60Days: true };
	}
	return null;
};

const notToday = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	const a = moment(control.value).format('MM/DD/YYYY');
	const b = moment().format('MM/DD/YYYY');
	if (a === b) {
		return { notToday: true };
	}
	return null;
};

const yearBuiltFuture = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { invalidYearBuiltFuture: true };
	}
	return null;
};

const yearBuiltPast = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value < 1800) {
		return { invalidYearBuiltPast: true };
	}
	return null;
};

const isPastDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isBefore(moment().format('YYYY-MM-DD'))) {
		return { invalidExpirationDate: true };
	}
	return null;
};

const isFutureDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	const controlMoment = moment(control.value).set({ hour: 23, minute: 59, second: 59 });
	if (controlMoment.isBefore(moment())) {
		return { invalidFutureDate: true };
	}
	return null;
};

const isMoreThen18 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isBefore(moment()) && moment().diff(control.value, 'years') < 18) {
		return { lessThen18: true };
	}
	return null;
};

const isDateAfterEffectiveDate = (date: string) => {
	return (control: AbstractControl) => {
		const dateInput = new Date(control.value);
		const effectiveDate = new Date(date);
		if (control.value && date && dateInput > effectiveDate) {
			return { isDateAfterEffectiveDate: true };
		}
		return null;
	};
};

const isMoreThan16 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isBefore(moment()) && moment().diff(control.value, 'years') < 16) {
		return { lessThan16: true };
	}
	return null;
};

const fieldMoreThan0 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value <= 0) {
		return { fieldLessOrEqualTo0: true };
	}
	return null;
};

const costNewValueMoreThan0 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value <= 0) {
		return { costNewValueLessOrEqualTo0: true };
	}
	return null;
};

const mileageMoreThan1000 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (parseInt(control.value, 10) < 1000) {
		return { mileageLessThan1000: true };
	}
	return null;
};

const mileageLessThan100k = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (parseInt(control.value, 10) > 100000) {
		return { mileageMoreThan100k: true };
	}
	return null;
};

const fieldLessThen99 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value > 99) {
		return { fieldGreaterThen99: true };
	}
	return null;
};

const halfFloor = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value % 1 !== 0) {
		return { invalidHalfFloor: true };
	}
	return null;
};

const numberOfFloorsHigher = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value > 99) {
		return { numberOfFloorsHigher: true };
	}
	return null;
};

const lessThen4Stories = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value > 4) {
		return { moreThen4Stories: true };
	}
	return null;
};

const ExteriorWallsNotNone = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (!control.value) {
		return { ExteriorWallsIsNone: true };
	}
	return null;
};

const yearsAtAddressMoreThenAge = (fieldA, fieldB) => {
	return (group: FormGroup) => {
		const controlA = group.controls[fieldA];
		const controlB = group.controls[fieldB];

		if (controlA.value > controlB.value) {
			return { yearsAtAddressLessThenAge: true };
		}
		return null;
	};
};

const yearRoofUpdateMoreThenBuildTime = (fieldA, fieldB) => {
	return (group: FormGroup) => {
		const controlA = group.controls[fieldA];
		const controlB = group.controls[fieldB];

		if (controlA.value > controlB.value) {
			return { invalidYearRoofUpdateMoreThenBuildTime: true };
		}
		return null;
	};
};

const dateOfCoApplicantChildAfterDateOfParent = (controlBVal: string, relationshipToClient: AbstractControl) => {
	return (control: AbstractControl) => {
		if (
			control &&
			control.value &&
			controlBVal &&
			moment(control.value).isBefore(moment(controlBVal)) &&
			relationshipToClient &&
			relationshipToClient.value == 'Child'
		) {
			return { invalidDateOfCoApplicantChildAfterDateOfParent: true };
		}
		return null;
	};
};

const PersonalLossesDateBeforeDOB = (DOB: string) => {
	return (control: AbstractControl) => {
		if (DOB && control && control.value && moment(control.value).isBefore(moment(DOB))) {
			return { PersonalLossesDateBeforeDOB: true };
		}
		return null;
	};
};

const priorCarrierHomeBeforeDOB = (DOB: string) => {
	return (control: AbstractControl) => {
		if (DOB && control && control.value && moment().subtract(control.value, 'y').isBefore(moment(DOB))) {
			return { priorCarrierHomeBeforeDOB: true };
		}
		return null;
	};
};

const yearsAtAddressBeforeDOB = (DOB: string) => {
	return (control: AbstractControl) => {
		if (DOB && control && control.value && moment().subtract(control.value, 'y').isBefore(moment(DOB))) {
			return { YearsAtAddressBeforeDOB: true };
		}
		return null;
	};
};

const dateOfCoApplicantParentBeforeDateOfChild = (controlB: string, relationshipToClient: AbstractControl) => {
	return (control: AbstractControl) => {
		if (
			control.value &&
			controlB &&
			moment(control.value).isAfter(moment(controlB)) &&
			relationshipToClient.value == 'Parent'
		) {
			return { invalidDateOfCoApplicantParentBeforeDateOfChild: true };
		}
		return null;
	};
};

const maxFirePlaces = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value > 9) {
		return { exceedMaxFirePlaces: true };
	}
	return null;
};

const bruglarAlarmTypeRequired = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (!control.value) {
		return { bruglarAlarmTypeRequired: true };
	}
	return null;
};

const fireDetectionTypeRequired = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (!control.value) {
		return { fireDetectionTypeRequired: true };
	}
	return null;
};

const isDOBpastDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { invalidDobPast: true };
	}
	return null;
};

const isDefensiveFutureDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { isDefensiveFutureDate: true };
	}
	return null;
};

const zipStrictLength = (strictLength: number) => {
	return (control: AbstractControl) => {
		if (!control || !control.value) {
			return null;
		}
		if (control.value.length !== strictLength) {
			return { invalidZipStrictLength: true, requiredLength: strictLength };
		}
		return null;
	};
};

const isPoBox = (control: AbstractControl) => {
	if (!control || !control.value) {
		return null;
	}
	const currValueFormatted = control.value.replaceAll(' ', '').toLowerCase();
	if (
		currValueFormatted.replaceAll('.', '').indexOf('pobox') > -1 ||
		currValueFormatted.indexOf('p.o') > -1 ||
		currValueFormatted.indexOf('postoffice') > -1
	) {
		return { invalidPoBox: true };
	}
	return null;
};

const isEarlierThan1900 = (control: AbstractControl) => {
	if (!control || !control.value) {
		return null;
	}
	const splittedDate = control.value.split('/');
	if (splittedDate && splittedDate.length == 3 && splittedDate[2] && parseInt(splittedDate[2]) < 1900) {
		return { invalidEarlierThan1900: true };
	}
	return null;
};

const isEqulesOrAfter1920 = (control: AbstractControl) => {
	if (!control || !control.value) {
		return null;
	}
	let splittedDate = control.value.split('/');
	if (splittedDate && splittedDate.length == 3 && splittedDate[2] && parseInt(splittedDate[2]) < 1920) {
		return { invalidEqulesOrAfter1920: true };
	}
	return null;
};

const ageUnder18 = (control: AbstractControl) => {
	if (!control || !control.value) {
		return null;
	}
	const dateFormatted = new Date(control.value);
	if (new Date(Date.now() - dateFormatted.valueOf()).getFullYear() - 1970 < 18) {
		return { invalidAgeUnder18: true };
	}
	return null;
};

const SquareFootageMustBetween0 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value < 1 || control.value > 999999) {
		return { SquareFootageMustBetween0: true };
	}
	return null;
};

const unitsMustBetween1to99 = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (control.value < 1 || control.value > 99) {
		return { unitsMustBetween1to99: true };
	}
	return null;
};

// TODO: check this
const dateOccupidLessThenYear = (fieldA, fieldB) => {
	return (group: FormGroup) => {
		const controlA = group.controls[fieldA];
		const controlB = group.controls[fieldB];
		if (controlA.value.diff(controlB.value, 'years') < 1) {
			return { dateOccupidInvalid: true };
		}
		return null;
	};
};

const floorNumberHigher = (controlB: AbstractControl) => {
	return (control: AbstractControl) => {
		if (control.value > controlB.value) {
			return { floorNumberHigher: true };
		}
		return null;
	};
};
const plumbingUpdateFetureDate = (controlB: AbstractControl) => {
	return (control: AbstractControl) => {
		if (control.value < controlB.value) {
			return { plumbingUpdateFetureDate: true };
		}
		return null;
	};
};

const heatingUpdateFetureDate = (controlB: AbstractControl) => {
	return (control: AbstractControl) => {
		if (control.value < controlB.value) {
			return { heatingUpdateFetureDate: true };
		}
		return null;
	};
};

const heatingUpdatePastDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { heatingUpdatePastDate: true };
	}
	return null;
};

const electricalUpdatePastDate = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { electricalUpdatePastDate: true };
	}
	return null;
};

const electricalUpdateFetureDate = (controlB: AbstractControl) => {
	return (control: AbstractControl) => {
		if (controlB && control.value < controlB.value) {
			return { electricalUpdateFetureDate: true };
		}
		return null;
	};
};

const dateOccupiedInvalid = (EffectiveDate: AbstractControl, purchaseDate: AbstractControl, yearBuilt: number) => {
	return (control: AbstractControl) => {
		if (EffectiveDate && moment(control.value).isAfter(moment(EffectiveDate.value).add(1, 'y').format('MM/DD/YYYY'))) {
			return { dateOccupiedPastDateInvalid: true };
		}
		if (yearBuilt && moment(control.value).isBefore(moment(yearBuilt, 'year'))) {
			return { dateOccupiedFetureDateInvalid: true };
		}
		if (purchaseDate && moment(control.value).isBefore(purchaseDate.value)) {
			return { dateOccupiedPurchaseDateInvalid: true };
		}
		return null;
	};
};

const puchaseFetureDateInvalid = (EffectiveDate: AbstractControl, yearBuilt: number) => {
	return (control: AbstractControl) => {
		if (EffectiveDate && moment(control.value).isAfter(EffectiveDate.value)) {
			return { puchasePastDateInvalid: true };
		}
		if (yearBuilt && moment(control.value).isBefore(moment(yearBuilt, 'year'))) {
			return { puchaseFetureDateInvalid: true };
		}
		return null;
	};
};

const maxNumber = (max: number) => {
	return (control: AbstractControl) => {
		if (control.value > max) {
			return { max: true, number: max };
		}
		return null;
	};
};

const minNumber = (min: number) => {
	return (control: AbstractControl) => {
		if (control.value && control.value < min) {
			return { min: true, number: min };
		}
		return null;
	};
};

const dateFormat = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	const regex = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](1|2|)?[0-9]{3})*$/;
	//adding handle februar not valid date
	if (control.value) {
		let month = control.value.split('/')[0];
		let day = control.value.split('/')[1];
		let year = control.value.split('/')[2];

		if (
			((month == '04' || month == '06' || month == '09' || month == '11') && day > 30) ||
			(month == '02' && day > 29) ||
			//februar
			(month == '02' && year % 4 !== 0 && day == '29')
		) {
			return { dateFormatInvalid: true };
		}
	}

	if (
		control.value != null &&
		regex.test(
			moment(
				typeof control.value !== 'string' ? control.value : control.value.replace('XX', '01').replace('XX', '01')
			).format('MM/DD/YYYY')
		)
	) {
		return null;
	}

	// Check for the case of februar

	return { dateFormatInvalid: true };
};

const multiDropdownRequired = (control: AbstractControl) => {
	if (!control) {
		return null;
	}
	return { multiDropdownRequired: true };
};

const PersonalLossesDateFuture = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	if (moment(control.value).isAfter(moment())) {
		return { personalLossesDateFuture: true };
	}
	return null;
};

const DogsBreedsMaxSelection = (maxDogsNumber: AbstractControl) => {
	return (control: AbstractControl) => {
		if (control.value && control.value.length && maxDogsNumber && maxDogsNumber.value < control.value.length) {
			return { dogsBreedsMaxSelection: true };
		}
		return null;
	};
};

const lastNameOneChar = (control: AbstractControl) => {
	if (!control || !control.value) {
		return null;
	}
	if (control.value.length <= 1) {
		return { lastNameOneChar: true };
	}
	return null;
};

const firstNameOneChar = (control: AbstractControl) => {
	if (!control || !control.value) {
		return null;
	}
	if (control.value.length <= 1) {
		return { firstNameOneChar: true };
	}
	return null;
};

const mandatory = (control: AbstractControl) => {
	if (control.value && control.value.length > 0) {
		return null;
	}
	return { mandatory: true };
};

const requiredNo = (control: AbstractControl) => {
	if (control.value !== null && !control.value) {
		return null;
	}
	return { requiredNo: true };
};

const alphabetAndDigits = (control: AbstractControl) => {
	const regex = /^[0-9A-Za-z]*$/;
	if (control.value && regex.test(control.value)) {
		return null;
	}
	return { invalidCapitalalphabetAndDigits: true };
};

const fieldMoreThan = (maxValue: number) => {
	return (control: AbstractControl) => {
		if (!control && !control.value) {
			return null;
		}
		if (control.value > maxValue) {
			return { fieldLessThan: true, maxValue };
		}
		return null;
	};
};

const unique = (list, control: AbstractControl, key: string, errorType) => {
	const currentId = control.value.Id;
	const SequenceNum = control.value.SequenceNum;

	return (control: AbstractControl) => {
		let found = false;
		list?.forEach((formGroup: FormGroup) => {
			if (
				formGroup.get(key) &&
				formGroup.get(key).value === control.value &&
				currentId !== formGroup.value.Id &&
				SequenceNum > -1
			) {
				found = true;
			}
		});
		if (found) {
			return { [errorType]: true };
		} else {
			return null;
		}
	};
};

const validateVINduplicate = (control: AbstractControl) => {
	if (control.parent != null) {
		let flattened = [].concat(...Object.values(control.parent.parent.parent.value));
		flattened.map((i) => i.VIN);
		let fa = flattened.map((i) => i.VIN);
		let count = 0;
		for (let i = 0; i < fa.length; i++) {
			if (fa[i] && control.value && fa[i] === control.value) {
				count++;
				if (count > 1) {
					return { duplicatedVIN: true };
				}
			}
		}
		return null;
	}
	return null;
};

const ValidateVINpopup = (Id: string, vins: any) => {
	return (control: AbstractControl) => {
		if (control.value && control.value.length > 0 && control.value.length !== 17) {
			return { invalidVINLength: true };
		}
		if (control.value && vins.filter((i) => i.Id !== Id && i.VIN == control.value).length > 0) {
			return { duplicatedVIN: true };
		}
		const regex = /^[0-9a-zA-Z-'\s]*$/;
		if (control.value && !regex.test(control.value)) {
			return { invalidVINCharactersPopup: true };
		}
		return null;
	};
};

const validateVINAsync = (actionService: ActionApiService): any => {
	return (control: AbstractControl) => {
		if (!control.valueChanges || control.pristine) {
			return of(null);
		} else {
			return (control) =>
				control.valueChanges.pipe(
					debounceTime(400),
					distinctUntilChanged(),
					take(1),
					switchMap((value) => actionService.decodeVin(control.value)),
					map((result: any) => {
						if ((!result.year || !result.make || !result.model || !result.bodyStyle) && control.value.length == 17) {
							return { invalidVIN: true };
						}

						return null;
					})
				);
		}
	};
};

const driverDateLicensedIsMoreThan16YearsAfterDOB = (dob: string) => {
	return (control: AbstractControl): ValidationErrors => {
		const dobYear = parseInt(dob.match(/\d{4}/)[0]);
		if (moment(control.value).isAfter(moment())) {
			return { FutureDateIsInvalid: true };
		}
		if (!isNaN(dobYear) && moment(control.value).year() - dobYear < 16) {
			return { driverDateLicensedIsLessThan16YearsAfterDOB: true };
		}
		return null;
	};
};

const LicenseStatusForPrimaryDriver = (control: AbstractControl) => {
	if (control.value && (control.value == 'NotLicensed' || control.value == 'Permit')) {
		return { licenseStatusForPrimaryDriver: true };
	}
	return null;
};

const ValidateVinPrefix = (VINPrefix: string) => {
	return (control: AbstractControl): ValidationErrors => {
		if (control.value && control.value.length == 0) {
			return { required: true };
		}

		if (control.value && control.value.length !== 17) {
			return { invalidVINLength: true };
		}
		const regex = /^[0-9A-Za-z]*$/;
		if (control.value && !regex.test(control.value)) {
			return { invalidVINCharacters: true };
		}
		if (control.value && (!/^\d+$/.test(control.value.substring(8, 9)) || !/^\d+$/.test(control.value.substring(11)))) {
			return { invalidVIN: true };
		}
		if (
			control.value &&
			(!(VINPrefix.substring(0, 8).toUpperCase() == control.value.substring(0, 8).toUpperCase()) ||
				!(VINPrefix.substring(9, 11).toUpperCase() == control.value.substring(9, 11).toUpperCase()))
		) {
			return { vinNotMatchingVehicle: true };
		}
		return null;
	};
};

const petDOB = (control: AbstractControl) => {
	if (!control && !control.value) {
		return null;
	}
	const dob = moment(control.value, 'MM/YYYY');

	if (!dob.isValid()) {
		return { invalidDate: true };
	}
	if (dob.isAfter(moment())) {
		return { invalidPetDobPast: true };
	}

	if (dob.isBefore(moment().subtract(20, 'years'))) {
		return { petMoreThan20YearsOld: true };
	}
	return null;
};

const unmaskedDOB = (control: AbstractControl) => {
	if (!control.pristine) {
		if (control.value.includes('X')) {
			return { required: true };
		}
	}
	return null;
};

const notEqualesTo = (string: string) => {
	return (control: AbstractControl): ValidationErrors => {
		if (control.value && control.value == string && string == 'Other') {
			if (string == 'Other') {
				return { cannotbeother: true };
			}
			return { notValidSelection: true };
		}
		return null;
	};
};

const validDayWeek = (control: AbstractControl) => {
	if (Number(control.value) > 7 || Number(control.value) < 1) {
		return { notValidDayWeek: true };
	}
	return null;
};

const validSnapshotNumber = (control: AbstractControl) => {
	const regex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
	const phone = control.value;

	if (phone && regex.test(phone)) {
		return null;
	}
	return { invalidSnapshotNumber: true };
};

const isDateLessThanYears = (years: number) => {
	return (control: AbstractControl) => {
		if (control.value && moment(control.value).isBefore(moment().subtract(years, 'years'))) {
			return { isDateLessThanYears: true };
		}
		return null;
	};
};

const positiveOnly = (control: AbstractControl) => {
	if (control.value == 0 || control.value < 0) {
		return { positiveNumber: true };
	}
	return null;
};

const greaterThan0 = (control: AbstractControl) => {
	if (!(control.value > 0)) {
		return { greaterThan0: true };
	}
	return null;
};

const validDate = (control: AbstractControl) => {
	if (control.value && moment(control.value).isValid()) {
		return null;
	}
	return { invalidDate: true };
};

const number1to10 = (control: AbstractControl) => {
	const regex = /^([1-9]|10)$/;
	if (control.value && regex.test(control.value)) {
		return null;
	}
	return { number1to10: true };
};

export const Validations = {
	alphabetAndDigitsAnd,
	alphabet,
	SSN,
	dateOfBirthBefore1900,
	invalidCharStringValidation,
	emailValid,
	emailValidAuto,
	valueEquals,
	dateLessThan,
	validPhoneNumber,
	validPhoneNumberAuto,
	validAddress,
	minMaxAge,
	postalCode,
	postalCodeNumberOnly,
	fieldsMatch,
	futureDate,
	isPastDate,
	isFutureDate,
	notToday,
	isMoreThen18,
	isMoreThan16,
	yearBuiltFuture,
	yearBuiltPast,
	fieldMoreThan0,
	mileageMoreThan1000,
	mileageLessThan100k,
	lessThen4Stories,
	ExteriorWallsNotNone,
	futureDateAnd60Days,
	yearsAtAddressMoreThenAge,
	fieldLessThen99,
	halfFloor,
	roofUpdatePastDate,
	roofUpdateFutureDate,
	yearRoofUpdateMoreThenBuildTime,
	maxFirePlaces,
	dateOfCoApplicantChildAfterDateOfParent,
	dateOfCoApplicantParentBeforeDateOfChild,
	bruglarAlarmTypeRequired,
	isDOBpastDate,
	dateOccupidLessThenYear,
	fireDetectionTypeRequired,
	SquareFootageMustBetween0,
	floorNumberHigher,
	heatingUpdatePastDate,
	numberOfFloorsHigher,
	plumbingUpdatePastDate,
	plumbingUpdateFetureDate,
	electricalUpdateFetureDate,
	heatingUpdateFetureDate,
	electricalUpdatePastDate,
	numbersdDigits,
	puchaseFetureDateInvalid,
	dateOccupiedInvalid,
	dateFormat,
	maxNumber,
	minNumber,
	isPoBox,
	isEarlierThan1900,
	isEqulesOrAfter1920,
	ageUnder18,
	zipStrictLength,
	MiddleNamealphabet,
	multiDropdownRequired,
	PersonalLossesDateFuture,
	PersonalLossesDateBeforeDOB,
	priorCarrierHomeBeforeDOB,
	yearsAtAddressBeforeDOB,
	DogsBreedsMaxSelection,
	lastNameOneChar,
	firstNameOneChar,
	unitsMustBetween1to99,
	mandatory,
	alphabetAndDigits,
	fieldMoreThan,
	unique,
	requiredNo,
	driverDateLicensedIsMoreThan16YearsAfterDOB,
	isDateAfterEffectiveDate,
	LicenseStatusForPrimaryDriver,
	FutureDateValid,
	petDOB,
	costNewValueMoreThan0,
	unmaskedDOB,
	ValidateVinPrefix,
	validateVINduplicate,
	validateVINAsync,
	ValidateVINpopup,
	notEqualesTo,
	validDayWeek,
	validSnapshotNumber,
	isDefensiveFutureDate,
	isDateLessThanYears,
	validDate,
	positiveOnly,
	greaterThan0,
	number1to10,
};
