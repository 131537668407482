import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appHiddenWhenCssDisabled]',
})
export class HiddenWhenCssDisabledDirective implements OnChanges {
	@Input('appHiddenWhenCssDisabled') hideCondition?: any;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnChanges() {
    if (this.hideCondition === '' || this.hideCondition === true) {
      this.renderer.setProperty(this.el.nativeElement, 'hidden', 'hidden');
      this.renderer.addClass(this.el.nativeElement, 'hidden-when-css-disabled');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'hidden');
      this.renderer.removeClass(this.el.nativeElement, 'hidden-when-css-disabled');
    }
  }
}
