import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectQuoteData } from '../store/selectors/quote-data.selectors';
import { AGENT_NUMBER, FIRST_NAME } from '../constants/source-mask-string';
import { selectAgentPhoneNumber } from '../store/selectors/active-theme.selector';
import { combineLatest } from 'rxjs';
import { getSelectedCarrier } from '../store/selectors/result-data.selectors';
import { selectLobSelection } from '../store/selectors/interview-metadata.selector';
import * as moment from 'moment';
import { getLobDisplayName } from '../utils/general.utils';

@UntilDestroy()
@Pipe({ name: 'textUnmask' })
export class TextUnmaskingPipe implements PipeTransform {
	masks = {};

	constructor(private store: Store) {
		combineLatest([
			this.store.select(selectQuoteData),
			this.store.select(selectAgentPhoneNumber),
			this.store.select(getSelectedCarrier),
			this.store.select(selectLobSelection),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([quoteData, agentNumber, selectedCarrier, lobSelection]) => {
				const quoteDataMasks = {};

				Object.keys(quoteData).forEach((key) => {
					quoteDataMasks[`<%${key}%>`] = this.transformMaskValue(quoteData[key]);
				});

				this.masks = {
					...quoteDataMasks,
					[`<%selectedCarrier%>`]: selectedCarrier,
					[`<%lobSelection%>`]: getLobDisplayName(lobSelection[0]),
					[FIRST_NAME]: quoteData.FirstName
						? quoteData.FirstName.charAt(0).toUpperCase() + quoteData.FirstName.slice(1)
						: '',
					[AGENT_NUMBER]: `<span class="nowrap">${agentNumber}</span>`,
				};
			});
	}

	transformMaskValue(value: string): string {
		return moment(value, moment.ISO_8601, true).isValid() ? moment(value).format('MM/DD/YY') : value;
	}

	transform(value: string): string {
		let newValue = value;

		if (newValue) {
			for (let mask in this.masks) {
				newValue = newValue.replace(new RegExp(mask, 'g'), this.masks[mask]);
			}
		}

		return newValue;
	}
}
