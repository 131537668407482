<section class="main-content">
	<app-page-heading [title]="'titles.talkSafety' | translate"></app-page-heading>

	<ng-container *ngIf="form">
		<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper stillwater-fq">
			<app-question-wrapper
				*ngIf="form.controls[Fields.DistanceToFireHydrant.name]?.enabled"
				class="question-container"
			>
				<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
					<label [attr.id]="Fields.DistanceToFireHydrant.name" class="input-label">
						{{ "fields.DistanceToFireHydrant" | translate }}
					</label>
					<app-dropdown
						[options]="FieldOptions.DistanceToFireHydrant"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.pleaseSelect' | translate"
						[name]="Fields.DistanceToFireHydrant.name"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.controls[Fields.DistanceToFireStation.name]?.enabled"
				class="question-container"
			>
				<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
					<label [attr.id]="Fields.DistanceToFireStation.name" class="input-label">
						{{ "fields.DistanceToFireStation" | translate }}
					</label>
					<app-dropdown
						[options]="FieldOptions.DistanceToFireStation"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.pleaseSelect' | translate"
						[name]="Fields.DistanceToFireStation.name"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.controls[Fields.LimitedAccessLocation.name]?.enabled"
				class="question-container"
			>
				<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
					<label [attr.id]="Fields.LimitedAccessLocation.name" class="input-label">
						{{ 'fields.FQData.StillWaterPersonalHome.LimitedAccessLocation' | translate }}
					</label>
					<app-yes-no [name]="Fields.LimitedAccessLocation.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.controls[Fields.PLNumberOfUnitsInFirewall.name]?.enabled"
				class="question-container"
			>
				<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
					<label [attr.id]="Fields.PLNumberOfUnitsInFirewall.name" class="input-label">
						{{ "fields.PLNumberOfUnitsInFirewall" | translate }}
					</label>
					<unmasked-input
						[name]="Fields.PLNumberOfUnitsInFirewall.name"
						maxlength="2"
						placeholder="#"
						secondaryType="number"
					>
					</unmasked-input>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.PermanentFoundation.name]?.enabled" class="question-container">
				<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
					<label [attr.id]="Fields.PermanentFoundation.name" class="input-label">
						{{ 'fields.FQData.StillWaterPersonalHome.PermanentFoundation' | translate }}
					</label>
					<app-yes-no [name]="Fields.PermanentFoundation.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.AltWaterSource.name]?.enabled" class="question-container">
				<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
					<label [attr.id]="Fields.AltWaterSource.name" class="input-label">
						{{ "fields.AltWaterSource" | translate }}
					</label>
					<app-yes-no [name]="Fields.AltWaterSource.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<next-button
				(validationFailed)="handleValidation($event)"
				[loaderVisible]="false"
				[disabled]="!form.valid"
				[myForm]="myForm"
				isFullQuote="true"
				[title]="'buttons.continue' | translate"
				[shouldIgnoreDisabledFields]="true"
				class="fullscreen"
			></next-button>
		</form>
	</ng-container>
</section>
