<section class="main-content">
	<ng-container *ngIf="loaderInformation$ | async as loaderData">
		<app-loader *ngIf="loaderData?.visible"></app-loader>
	</ng-container>

	<app-page-heading subtitleKey="summary.subTitle" titleKey="summary.title"></app-page-heading>

	<div class="content">
		<div *ngIf="selectedRate" class="details-wrapper">
			<app-carrier-offer-card
				[data]="selectedRate"
				[isEscrow]="isEscrow"
				[logo]="'/assets/images/logos/' + selectedRate.carrier + '/logo.png'"
				[policyStartDate]="PolicyStartDate"
				[price]="selectedRate.premium"
			>
			</app-carrier-offer-card>
		</div>
	</div>
</section>
