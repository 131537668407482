import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

import { BasePageComponent } from '../../../../../base-page.component';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Fields } from '../stillwater-fields';
import { Validations } from 'src/app/utils/validation';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
@Component({
	selector: 'app-stillwater-property-features',
	templateUrl: './stillwater-property-features.component.html',
	styleUrls: ['./stillwater-property-features.component.scss', '../stillwater.component.scss'],
})
export class StillwaterPropertyFeaturesComponent extends BasePageComponent implements OnInit {
	Fields = Fields;
	vertical = true;
	maxSequenceNum: number = 0;
	numActiveBathrooms = 0;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService
	) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({
			[this.Fields.ExteriorWallsConstruction.name]: [null, [Validators.required]],
			[this.Fields.NumberOfFireplaces.name]: [null, [Validators.required]],
			[this.Fields.NumberOfKitchens.name]: [null, [Validators.required]],
			[this.Fields.KitchenConstructionQuality.name]: [null, [Validators.required]],
			FQData: this.fb.group({
				StillWaterPersonalHome: this.fb.group({ BathroomInformation: this.fb.array([]) }),
			}),
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				if (quoteData.FQData?.StillWaterPersonalHome?.BathroomInformation?.length) {
					let bathroomInformation = [...quoteData.FQData?.StillWaterPersonalHome?.BathroomInformation];
					this.maxSequenceNum = Math.max(...bathroomInformation.map((o) => o.SequenceNum), -1);
					this.numActiveBathrooms = bathroomInformation.filter((bathroom) => bathroom.SequenceNum != -1).length;
					for (let i = 0; i < bathroomInformation.length; i++) {
						this.bathroomInformationArray.push(this.createFormControl(bathroomInformation[i]));
					}
				} else {
					this.maxSequenceNum = -1;
					this.addBathroomRow();
				}
			});
		super.registerOnChange(this.form);
		this.patchData(this.form);
	}

	addBathroomRow(reportEvent = false) {
		this.maxSequenceNum++;
		let formGroupItem;

		let data = {
			Id: uuidv4(),
			SequenceNum: this.maxSequenceNum,
			BathroomType: [null, [Validators.required]],
			NumberOfBathrooms: [null, [Validators.required, Validations.number1to10]],
			BathroomConstructionQuality: [null, [Validators.required]],
		};

		this.numActiveBathrooms++;
		formGroupItem = this.formBuilder.group(data) as FormGroup;
		this.bathroomInformationArray.push(formGroupItem);
		reportEvent &&
			this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
				label: 'add-another-one',
				button: 'add-another-one-bathroom',
				module: 'stillwater-property-features-module',
			});
	}

	deleteBathroomRow(index) {
		this.bathroomInformationArray.controls[index].get('SequenceNum').setValue('-1');
		this.bathroomInformationArray.controls[index].clearValidators();
		this.bathroomInformationArray.controls[index].disable();
		this.numActiveBathrooms--;
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'remove-bathroom',
			button: 'remove-bathroom',
			module: 'stillwater-property-features-module',
		});
	}

	createFormControl(item): FormGroup {
		const data = {
			Id: item.Id,
			SequenceNum: item.SequenceNum,
			BathroomType: [item.BathroomType || null, [Validators.required]],
			NumberOfBathrooms: [item.NumberOfBathrooms || null, [Validators.required, Validations.number1to10]],
			BathroomConstructionQuality: [item.BathroomConstructionQuality || null, [Validators.required]],
		};
		return this.formBuilder.group(data) as FormGroup;
	}

	get bathroomInformationArray(): FormArray {
		return (<FormGroup>(<FormGroup>this.form.controls.FQData)?.controls.StillWaterPersonalHome).controls
			.BathroomInformation as FormArray;
	}

}
