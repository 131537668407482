import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAppState } from 'src/app/store/states/app.state';
import { EventActions } from '../../../../models/event-data.model';
import { BuyCoverageButtonService } from '../../../../services/buy-coverage-button.service';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { COVERAGES_TEXTS } from './texts.data';
import { CoverageModel } from '../../../../models/coverage.model';
import { LobsEnum } from '../../../../enums/lobs.enum';
import { ActionApiService } from 'src/app/services/action-api.service';
import {
	NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP,
	numOfMonthsInTerm,
	pricePerMonth,
	yearlyTermDisplayName,
} from '../../../../utils/general.utils';
import { hidePopupAction } from '../../../../store/actions/popup.actions';
import { selectRatePlanSelectedForOfflineBid } from '../../../../store/selectors/result-data.selectors';
import { first } from 'rxjs/operators';
import { carrierSelectedForOfflineBid } from '../../../../store/actions/result-data.actions';
import { map } from 'rxjs';
import { selectInterviewMetadata } from '../../../../store/selectors/interview-metadata.selector';
import { selectInterviewTexts } from '../../../../store/selectors/interview-texts.selectors';
import { StepsEnum } from '../../../../enums/steps.enum';
import { ADDITIONAL_COVERAGES_TEXTS } from '../additional-coverages/texts.data';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	selector: 'app-all-coverages',
	templateUrl: './all-coverages.component.html',
	styleUrls: ['./all-coverages.component.scss'],
})
export class AllCoveragesComponent implements OnInit {
	data: any;
	isMonthly: boolean;
	EventActions = EventActions;
	cars = [];
	pets = [];
	rates: CoverageModel[] = [];
	toggledRates: CoverageModel[] = [];
	coveragesTexts = COVERAGES_TEXTS;
	isOffline: boolean;
	isActionsHidden = false;
	actionApiService: ActionApiService;
	carrier;
	openForSelect = false;
	lob;
	quoteData;
	selectRatePlanSelectedForOfflineBid: CoverageModel[];
	isSelected: boolean;
	isYearPriceVisible = true;
	discounts;
	showLoading = false;
	callAgentTitle: string;
	showAdditionalCoveragesSection = false;
	lobsAdditionalCoveragesMode = [
		[LobsEnum.PERSONAL_HOME],
		[LobsEnum.FLOOD, LobsEnum.PERSONAL_HOME],
		[LobsEnum.PERSONAL_AUTO, LobsEnum.PERSONAL_HOME],
	];
	activeAdditionalCoverages = [];

	constructor(
		private store: Store<IAppState>,
		private buyCoverageButtonService: BuyCoverageButtonService,
		actionApiService: ActionApiService,
		private eventsService: EventsService
	) {
		this.actionApiService = actionApiService;
	}

	get price(): number {
		let price = 0;

		this.rates.forEach((rate) => {
			if (rate.isSelected) {
				price += rate.premium;
			}
		});

		return price;
	}

	get isMultiLob(): boolean {
		return this.rates.filter((rate) => rate.isSelected).length > 1;
	}

	ngOnInit(): void {
		this.rates = this.data.rates;
		this.openForSelect = this.data.openForSelect;
		this.isOffline = this.data.isOffline;
		this.isActionsHidden = this.data.isActionsHidden;
		this.isMonthly = this.data.isMonthly;
		this.toggledRates = this.data.rates.filter((rate) => rate.isToggledType);

		this.store
			.select(selectInterviewMetadata)
			.pipe(untilDestroyed(this))
			.subscribe((interviewMetadata) => {
				this.isYearPriceVisible = !(interviewMetadata.lobSelection.length > 1);
				this.lobsAdditionalCoveragesMode.forEach((lobs) => {
					if (lobs.every((value) => interviewMetadata.lobSelection.includes(value))) {
						this.showAdditionalCoveragesSection = true;
					}
				});
			});

		if (this.openForSelect) {
			this.store
				.select(selectRatePlanSelectedForOfflineBid)
				.pipe(
					first(),
					map((selectedRates) => selectedRates.filter((selectedRate) => selectedRate.lob !== this.rates[0].lob))
				)
				.subscribe((selectedRates) => {
					this.selectRatePlanSelectedForOfflineBid = selectedRates;
					this.rates = [...selectedRates, ...this.rates];
					this.initLobSpecificRates();
				});
		} else {
			this.initLobSpecificRates();
		}

		this.store
			.select(selectInterviewTexts)
			.pipe(untilDestroyed(this))
			.subscribe((step) => {
				this.callAgentTitle = step[StepsEnum.RESULT].callAgentTitle;
			});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				this.quoteData = quoteData;
				ADDITIONAL_COVERAGES_TEXTS.forEach((r) => {
					if (quoteData[r.name] && !NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP.includes(quoteData[r.name])) {
						this.activeAdditionalCoverages.push(r.name);
					}
				});
			});

		this.getDiscounts();
	}

	initLobSpecificRates() {
		const rateForAuto = this.rates.filter((rate) => rate.lob === LobsEnum.PERSONAL_AUTO);
		const rateForPets = this.rates.filter((rate) => rate.lob === LobsEnum.PETS);

		if (rateForAuto.length) {
			this.createCarsResponse(rateForAuto[0]);
		}

		if (rateForPets.length) {
			this.createPetsResponse();
		}
	}

	createCarsResponse(rate: CoverageModel): void {
		this.cars = [];

		this.store.select(selectQuoteData).subscribe((res) => {
			for (let i = 0; i < Object.keys(rate.coverages.entityCoverages['personalAuto']).length; i++) {
				const p = rate.coverages.entityCoverages['personalAuto'][i + 1];
				const personalVehicles = res.PersonalVehicles.concat(res.discoverVehicles)
					.concat(res.manualVehicles)
					.filter((r) => r.enabled); // enabled added when going through the vehicles page but when coming though page skipping don't have enabled

				personalVehicles.forEach((car) => {
					const carToAdd = {
						PLMake: car.PLMake,
						PLModel: car.PLModel,
						PLYear: car.PLYear,
						VIN: car.VIN,
						coverages: [
							{
								coverage: 'ComprehensiveDeductible',
								value: p.find((r) => r.coverage === 'ComprehensiveDeductible').value,
							},
							{ coverage: 'CollisionDeductible', value: p.find((r) => r.coverage === 'CollisionDeductible').value },
							{
								coverage: 'Transportation_Expense',
								value: p.find((r) => r.coverage === 'Transportation_Expense').value,
							},
							{ coverage: 'Towing_AND_Labor', value: p.find((r) => r.coverage === 'Towing_AND_Labor').value },
						],
					};

					this.cars.push(carToAdd);
				});
			}
		});
	}

	filterAdditionalCoverages(rateCoverages, lob) {
		// return only coverages that are additional
		// check if rateCoverages has additional coverages
		// and if user added them
		// and if they are not in NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP
		let data = rateCoverages.filter((i) => {
			return (
				this.coveragesTexts[lob]?.[i.coverage]?.filter((i) => i.isAdditionalCoverage).length > 0 &&
				!NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP.includes(i.value) &&
				this.activeAdditionalCoverages.includes(i.coverage)
			);
		});

		return data.length > 0 ? data : [];
	}

	filterCoverages(rateCoverages, lob) {
		// return only coverages that are not additional
		return rateCoverages.filter((i) => {
			return this.coveragesTexts[lob]?.[i.coverage]?.filter((i) => !i.isAdditionalCoverage).length > 0;
		});
	}

	createPetsResponse() {
		this.store.select(selectQuoteData).subscribe((res) => {
			this.pets = res.Pets;
		});
	}

	onButtonClick(isToCallAgent = false) {
		if (this.showLoading) {
			return;
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'select',
			button: 'select-carrier',
			module: 'all-coverages-module',
		});
		const rates = this.rates.filter((rate) => rate.isSelected);
		const route = isToCallAgent || this.isOffline ? 'call-agent' : `additional-question-${rates[0].carrier}`;

		if (this.openForSelect) {
			this.store.dispatch(carrierSelectedForOfflineBid({ rates }));
			this.store
				.select(selectRatePlanSelectedForOfflineBid)
				.pipe(first())
				.subscribe((selectedRates) => {
					this.selectRatePlanSelectedForOfflineBid = selectedRates;
					if (
						this.selectRatePlanSelectedForOfflineBid &&
						this.selectRatePlanSelectedForOfflineBid.length > 1 &&
						// TODO: support more than 2 lobs
						this.selectRatePlanSelectedForOfflineBid[0].lob !== this.selectRatePlanSelectedForOfflineBid[1].lob
					) {
						this.buyCoverageButtonService.buyCoverageButtonClicked(rates, route);
						this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
							label: 'continue',
							button: 'continue-call-to-agent',
							module: 'all-coverages-module',
						});
					} else {
						this.store.dispatch(hidePopupAction());
					}
				});
		} else {
			this.showLoading = true;
			const response = this.buyCoverageButtonService.buyCoverageButtonClicked(rates, route);

			response &&
				response.pipe(first()).subscribe(() => {
					this.showLoading = false;
				});
		}
	}

	isValueNaN(input) {
		return isNaN(input);
	}

	getDwellingValue() {
		return this.data.coverages.policyLevelCoverages.filter((item) => item.coverage === 'Dwelling')[0].value;
	}

	reArrangeArray(array) {
		let arr = [...array];
		//make 'AnimalLiabilityCoverage' last coverage
		if (arr.findIndex((i) => i.coverage == 'AnimalLiabilityCoverage') !== -1) {
			let AnimalLiabilityCoverageObject = arr.find((i) => i.coverage == 'AnimalLiabilityCoverage');
			arr.splice(
				arr.findIndex((i) => i.coverage == 'AnimalLiabilityCoverage'),
				1
			);
			arr.push(AnimalLiabilityCoverageObject);
		}
		const el = arr.find((item) => item.coverage === 'Dwelling');
		const l = arr.filter((item) => item.coverage !== 'Dwelling');

		return el ? [el, ...l] : l;
	}

	getVehicleLogoName(make: string): string {
		return make.split(' ').join('-').toLowerCase().replace('.', '');
	}

	getCoverageTexts(coverage: { coverage: string; value: any }, lob: string) {
		if (coverage.coverage === 'ReplaceMentCostContents' && coverage.value === 'Actual Cash Value') {
			return this.coveragesTexts[lob]['ReplaceMentCostContentsACV'].filter((cov) => cov.states === 'default')[0];
		}

		if (this.coveragesTexts[lob][coverage.coverage]) {
			return this.getConvergeTextByState(coverage, lob);
		}
	}

	getConvergeTextByState(coverage: { coverage: string; value: any }, lob: string) {
		let cov = this.coveragesTexts[lob][coverage.coverage].filter(
			(cov) => cov.states.indexOf(this.quoteData.PropertyAddress.State) > -1
		);
		if (cov.length > 0) {
			return cov[0];
		} else {
			return this.coveragesTexts[lob][coverage.coverage].filter((cov) => cov.states === 'default')[0];
		}
	}

	getCoveragePrice(coverage) {
		if (!coverage) {
			return '';
		}
		if (coverage.coverage === 'Replacement Cost') {
			return this.getDwellingValue();
		} else if (coverage.value) {
			if (coverage.value.includes('/')) {
				const [value1, value2] = coverage.value.split('/');
				let ret;
				if (!isNaN(value2)) {
					ret = `$${value1}K / $${value2}K`;
				} else {
					ret = `${value1} / ${value2}`;
				}
				if (coverage.coverage === 'Transportation_Expense') {
					ret += '';
				}
				return ret;
			} else {
				return coverage.value;
			}
		}
	}

	getEntityCoveragesArray(lob: string, entityCoverages) {
		const entityCoveragesLobName = lob === LobsEnum.PERSONAL_AUTO ? 'personalAuto' : lob.toLowerCase();

		return Object.keys(entityCoverages[entityCoveragesLobName]).map(
			(key) => entityCoverages[entityCoveragesLobName][key]
		);
	}

	getEntityTitle(lob: string, index: number) {
		switch (lob) {
			case LobsEnum.PERSONAL_AUTO:
				if (this.cars && this.cars.length) {
					return `${this.cars[index].PLYear} ${this.cars[index].PLMake} ${this.cars[index].PLModel}`;
				} else {
					return '';
				}
			case LobsEnum.PETS:
				if (this.pets && this.pets.length) {
					return this.pets[index].PLPetName;
				} else {
					return '';
				}
		}
	}

	handleRateSelection(isSelected: boolean, rate: CoverageModel): void {
		this.isSelected = isSelected;
		if (isSelected) {
			this.selectRate(isSelected, rate);
		} else {
			setTimeout(() => {
				this.selectRate(isSelected, rate);
			}, 500);
		}
	}

	selectRate(isSelected, rate) {
		this.rates = this.rates.filter((item) => item.carrier !== rate.carrier);

		this.rates.push({ ...rate, isSelected });
		if (!this.data.isOffline) {
			this.isOffline = isSelected;
		}
	}

	getRates() {
		return this.rates
			.filter((rate) => rate.isSelected)
			.reduce((memo, rate) => {
				memo += pricePerMonth(rate.premium, rate.term);
				return memo;
			}, 0);
	}

	getFullTermPremium() {
		if (this.rates.length === 1) {
			return this.getRates() * numOfMonthsInTerm(this.rates[0].term);
		} else {
			return this.getRates() * 12;
		}
	}

	getFullTermName() {
		if (this.rates.length === 1) {
			return yearlyTermDisplayName(this.rates[0].term);
		} else {
			return 'labels.year';
		}
	}

	getDiscounts() {
		this.rates.forEach((i) => {
			if (i.discounts) {
				this.discounts = i.discounts;
			}
		});
	}
}
