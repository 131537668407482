import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

import { hidePopupAction, showPopupAction } from 'src/app/store/actions/popup.actions';
import {
	selectApplicationId,
	selectFriendlyId,
	selectInterviewMetadata,
} from 'src/app/store/selectors/interview-metadata.selector';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { getPaymentData, setPaymentData } from 'src/app/store/actions/result-data.actions';
import { selectSecretSource, selectSource } from 'src/app/store/selectors/result-data.selectors';
import { first } from 'rxjs/operators';
import { StepsEnum } from '../../../enums/steps.enum';
import { RoutingService } from 'src/app/services/routing.service';
import * as moment from 'moment';
import { ActionApiService } from 'src/app/services/action-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RateService } from '../../layout/pages/shared/result/services/rate.service';
import { CoverageModel, PaymentPlan } from '../../../models/coverage.model';
import { LobService } from 'src/app/services/lob.service';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { getLobDisplayName, numOfMonthsInTerm } from 'src/app/utils/general.utils';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { EscrowService } from 'src/app/services/escrow.service';
import { EventsService } from 'src/app/services/events.service';
import { EventActions } from 'src/app/models/event-data.model';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'app-carrier-offer-card',
	templateUrl: './carrier-offer-card.component.html',
	styleUrls: ['./carrier-offer-card.component.scss'],
})
export class CarrierOfferCardComponent implements OnInit, OnDestroy {
	@Input() data: CoverageModel;
	@Input() price;
	@Input() logo;
	@Input() policyStartDate;
	@Input() buttonData: any;
	@Input() isEscrow = false;
	selectedPaymentPlan: PaymentPlan;
	quoteData;
	friendlyId;
	selectedRates: CoverageModel[];
	actionApiService: ActionApiService;
	lob;
	lobTitle;
	lobsEnum = LobsEnum;

	iframeMessageEventHandler;
	applicationId: string;

	constructor(
		private store: Store,
		public routingService: RoutingService,
		private getLobService: LobService,
		private eventsService: EventsService,
		private renderer: Renderer2,
		actionApiService: ActionApiService,
		private rateServices: RateService,
		private progressMeterService: ProgressMeterService,
		private escrowService: EscrowService,
		private translateService: TranslateService
	) {
		this.iframeMessageEventHandler = renderer.listen('window', 'message', this.handleIframeMessage.bind(this));
		this.actionApiService = actionApiService;
	}

	get paymentText(): string {
		if (this.isEscrow) {
			// offline escrow
			return 'Pay in full';
		} else {
			const { firstPaymentAmount, totalAmount, numberOfPayments, type } = this.selectedPaymentPlan;
			if (type === 'Escrow') {
				// online bind escrow
				return 'Escrow';
			}
			return (
				((firstPaymentAmount / totalAmount) * 100 === 100 ? 'Pay in full' : 'Down payment ') +
				(numberOfPayments - 1 > 1 ? '+ ' + (numberOfPayments - 1).toString() + ' monthly installments' : '') +
				(numberOfPayments - 1 === 1 ? '+ 1 installment' : '')
			);
		}
	}

	ngOnInit(): void {
		this.selectedPaymentPlan = this.isEscrow
			? this.data.paymentPlans[0]
			: this.data.paymentPlans.filter((paymentPlan) => paymentPlan.selected)[0];

		combineLatest([this.store.select(selectFriendlyId), this.store.select(selectQuoteData)])
			.pipe(untilDestroyed(this))
			.subscribe(([friendlyId, quoteData]) => {
				this.friendlyId = friendlyId;

				this.quoteData = quoteData;
			});

		this.getLobService
			.getLob$()
			.pipe(untilDestroyed(this))
			.subscribe((lob) => {
				this.lob = lob;
				this.lobTitle = getLobDisplayName(lob);
			});

		this.selectedRates = this.rateServices.transformRates([this.data]).map((rate) => ({
			...rate,
			isSelected: rate.selected,
			premium: this.selectedPaymentPlan.totalAmount,
		}));

		if (this.isEscrow) {
			this.store
				.select(selectApplicationId)
				.pipe(first())
				.subscribe((appId) => {
					this.applicationId = appId;
				});
		}
	}

	ngOnDestroy() {
		this.iframeMessageEventHandler();
	}

	handleChangeClick() {
		combineLatest([
			this.store.select(selectSource),
			this.store.select(selectSecretSource),
			this.getLobService.getLob$(),
			this.store.select(selectInterviewMetadata),
		])
			.pipe(first())
			.subscribe(([source, secretSource, lob, interviewMetadata]) => {
				//if multiLob change need to update this
				// TODO: change to this.interviewMetadata.isMultiLob more accurate
				if (interviewMetadata.isMultiLob) {
					lob = [LobsEnum.HOME_AUTO];
				}
				if (secretSource) {
					this.routingService.navigateToRoute(
						this.progressMeterService.getRouteName(lob) + '/' + StepsEnum.PAYMENT_PLAN
					);
				} else {
					this.routingService.navigateToRoute(
						this.progressMeterService.getRouteName(lob) + '/' + StepsEnum.PAYMENT_PLAN
					);
				}
			});
	}

	openAllCoveragesPopup() {
		this.store.dispatch(
			showPopupAction({
				componentName: 'all-coverages',
				payload: {
					customClass: 'side-popup',
					gtmLabel: 'CoveragePopUp',
					text: this.translateService.instant('popups.titles.all-coverages') + this.friendlyId,
					rates: this.rateServices.transformRates([this.data]).map((rate) => ({
						...rate,
						isSelected: rate.selected,
						premium: this.selectedPaymentPlan.totalAmount,
					})),
					isMonthly: false,
					isOffline: false,
					isToggleCoveragesHidden: true,
					isActionsHidden: true,
				},
			})
		);
		this.eventsService.addEvent(EventActions.BUTTON_CLICK, { label: `${this.data.carrier}_AllCoveragesButton` });
	}

	handleClick(): void {
		this.isEscrow ? this.approvePaymentEscrow() : this.openPopupPayment();
	}

	formatDate(date) {
		return moment(date).format('MM/DD/YYYY');
	}

	handleIframeMessage(event: Event) {
		const message = event as MessageEvent;

		// Only trust messages from the below origin.
		if (message.origin !== window.origin) {
			return;
		}

		if (message.data && message.data.action) {
			switch (message.data.action) {
				case 'hidePopupActionAndRedirectToPaymentFailed':
					this.store.dispatch(hidePopupAction());
					this.routingService.navigateToRoute(StepsEnum.PAYMENT_FAILED);
					break;
			}
		}
	}

	getTermNumber(term: string) {
		return numOfMonthsInTerm(term);
	}

	getLogo(rate) {
		return this.actionApiService.getLogo(rate.carrier, this.lob);
	}

	private openPopupPayment() {
		// set payment data to null, so the ngOnInit won't get an old value from store until getPaymentData request is finished
		this.store.dispatch(setPaymentData({ url: null, transactionId: null }));
		this.store.dispatch(getPaymentData());

		this.store.dispatch(
			showPopupAction({
				componentName: 'payment',
				payload: {
					text: 'popups.titles.payment',
					gtmLabel: 'CoveragePopUp',
				},
			})
		);
		this.eventsService.addEvent(EventActions.BUTTON_CLICK, { label: `dd_AllCoveragesButton` });
	}

	private approvePaymentEscrow() {
		this.eventsService.addEvent(EventActions.BUTTON_CLICK, {
			label: 'next-button-payment-escrow',
			button: 'next-button-payment-escrow',
			module: 'next-button-module',
		});
		this.actionApiService
			.createQuoteNote({
				tenant: sessionStorage.getItem('tenant'),
				applicationId: this.applicationId,
				...this.escrowService.getEscrowNote(
					this.selectedRates[0],
					this.quoteData.MortgageAccountNumber,
					this.quoteData.MortgageCompanyName
				),
			})
			.subscribe(() => {
				this.routingService.navigateToRoute(StepsEnum.SUCCESS_PAGE_ESCROW);
			});
	}
}
