import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { ActionApiService } from 'src/app/services/action-api.service';
import { RoutingService } from 'src/app/services/routing.service';
import { ApiActions, LoaderActions } from '../actions';
import { Store } from '@ngrx/store';
import { IServerRes } from 'src/entities/payload.interface';
import { UpdateQuoteData } from '../actions/quote-data.actions';
import { StepsEnum } from '../../enums/steps.enum';
import { QuoteDataService } from '../../services/quote-data.service';
import { NavigateToNextPage } from '../actions/routing.actions';
import { selectProgressMeter } from '../selectors/progress-meter.selector';
import {
	UpdateInterviewMetadata,
	UpdateOriginYearBuilt,
	UpdatePrefillIndication,
} from '../actions/interview-metadata.actions';
import { IProgressMeter } from '../states/progress-meter.state';
import { selectLobSelection } from '../selectors/interview-metadata.selector';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';

@Injectable()
export class ApiEffect {
	StepsEnum = StepsEnum;
	updateApplication$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ApiActions.updateApplication),
				concatLatestFrom(() => [this.store.select(selectLobSelection)]),
				switchMap(([payload, lobSelection]) =>
					this.actionService
						.updateApplication(payload.formData, payload.isFullQuote ? lobSelection : null, payload.Carrier)
						.pipe(
							map((serverRes: IServerRes) => ({
								...serverRes,
								page: payload.page,
								additionalData: payload.additionalData,
							}))
						)
				),
				tap((serverRes) => {
					this.quoteDataService.serverQuoteErrors.next(serverRes);
					//Setting Prefill Indication
					this.store.dispatch(UpdatePrefillIndication({ data: serverRes['prefillIndication'] }));
					this.store.dispatch(UpdateInterviewMetadata({ data: { FQSubmission: true, Submission: true, applicantId: serverRes.applicantId } }));
					this.progressMeterService.hasApatite.next(serverRes.hasApplicationAppetite);
					this.store
						.select(selectProgressMeter)
						.pipe(first())
						.subscribe((progressMeter: IProgressMeter) => {
							if (
								this.routingService.currentRoute
									.split('/')
								[this.routingService.currentRoute.split('/').length - 1].split('?')[0] ===
								progressMeter.flowOptions[progressMeter.flowType][progressMeter.activeStep].name
							) {
								//Drivers property will no be updated
								// TODO - DELETE PERSONALVEHICLES AND DRIVERS INSIDE UpdateQuoteData
								delete serverRes['data'].Drivers;
								delete serverRes['data'].PersonalVehicles;

								//PLRoofUpdated has default value, no need to update
								delete serverRes['data'].PLRoofUpdated;
								let dataToUpdate = this.customChanges(serverRes['data']);
								this.store.dispatch(UpdateQuoteData({ data: dataToUpdate }));
								// navigate to next route
								setTimeout(() => {
									this.store.dispatch(LoaderActions.Hideloader());
									this.store.dispatch(NavigateToNextPage(serverRes)); //TODO: should be updated to goToNextPage?
								}, 1);
							}
						});
					if (serverRes.page) {
						this.store.dispatch(UpdateOriginYearBuilt({ data: serverRes['data'].PLYearBuilt }));
					}
				})
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private actionService: ActionApiService,
		private store: Store,
		private routingService: RoutingService,
		private quoteDataService: QuoteDataService,
		private progressMeterService: ProgressMeterService
	) { }

	customChanges(serverResponse) {
		//Custom changes to the server response
		//sort BathroomInformation by sequence number
		serverResponse?.FQData?.StillWaterPersonalHome?.BathroomInformation?.sort((a, b) => a.SequenceNum - b.SequenceNum);
		return serverResponse;
	}
}
