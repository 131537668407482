<section class="main-content">
	<app-page-heading [title]="'titles.propertyUsage' | translate"></app-page-heading>

	<app-radio-buttons
		(handleSelect)="handleSelect($event)"
		[options]="optionsPropertyUsages"
		[selectedOption]="OccupancyType"
		name="OccupancyType"
	>
	</app-radio-buttons>

	<next-button
		(clicked)="handleContinue()"
		[disabled]="this.OccupancyType === ''"
		continue="true"
		showTextLoading="true"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
