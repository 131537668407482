import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleSliderComponent } from './toggle-slider/toggle-slider.component';
import { DynamicToggleComponent } from './dynamic-toggle/dynamic-toggle.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [ToggleComponent, ToggleSliderComponent, DynamicToggleComponent],
	exports: [ToggleComponent, ToggleSliderComponent, DynamicToggleComponent],
	imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class ToggleModule {}
