import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/states/app.state';
import { hidePopupAction } from '../../../../store/actions/popup.actions';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
	data: {
		subtext: string;
		text: string;
	};

	constructor(private store: Store<IAppState>) {}

	ngOnInit(): void {}

	close() {
		this.store.dispatch(hidePopupAction());
	}
}
