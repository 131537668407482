import { IDropdownOption } from '../components/form-controls/form-control-interfaces';
import * as moment from 'moment';

export const SystemsOptionsGenerate = (upToYearAmt: number): IDropdownOption[] => {
	const options: IDropdownOption[] = [
		{
			id: 0,
			value: false,
			title: 'Not updated',
			labelKey: 'systemOptions.notUpdated',
		},
	];

	let i = 0;

	while (i <= upToYearAmt) {
		const title = i === 0 ? 'In the past year' : `${i} ${i === 1 ? 'year' : 'years'} ago`;
		const labelKey = i === 0 ? 'systemOptions.inThePastYear' : `systemOptions.yearsAgo${i}`;

		options.push({
			id: i + 1,
			value: moment().year() - i,
			title,
			labelKey,
		});

		i++;
	}

	return options;
};
