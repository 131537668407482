<div
	[ngClass]="{
		show: show,
		icon: !!icon
	}"
	class="question-wrapper"
>
	<div *ngIf="field?.title" class="question-title">{{ field.title }}</div>
	<ng-content></ng-content>
	<i *ngIf="icon" appHiddenWhenCssDisabled class="question-icon material-icons" role="presentation">{{ icon }}</i>

	<div *ngIf="optionalKeyText || tooltipKeyText" class="optional-text">
		<span *ngIf="optionalKeyText" class="text">
			{{ optionalKeyText | translate }}
		</span>
		<button
			[attr.aria-label]="tooltipKeyText"
			[matTooltip]="tooltipKeyText | translate"
			appHiddenWhenCssDisabled
			class="help-icon material-icons"
			appToggleTooltip
		>
			<span aria-hidden="true">help_outline</span>
		</button>
	</div>
</div>
