import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, expand, interval, map, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { carrierSelect, carrierSelectSubmitted, getPaymentData, setPaymentData } from '../actions/result-data.actions';
import { ActionApiService } from '../../services/action-api.service';
import { RoutingService } from '../../services/routing.service';
import { Store } from '@ngrx/store';
import { getSelectedRate, getSelectedRatePlan } from '../selectors/result-data.selectors';

@Injectable()
export class ResultDataEffect {
	delayer = interval(10000);
	localCarrier;
	// update getQuoteApi with the carrier and after getting response update the store
	selectCarrier$ = createEffect(() =>
		this.actions$.pipe(
			ofType(carrierSelect),
			switchMap(({ rateParamsArray }) => {
				let count = 0;
				this.localCarrier = rateParamsArray[0].carrier;

				// If there is more than one rate selected (e.g. Flood or multilob), should send carrier select request once per
				// each rate, after the previous request finished (to prevent cancelling the request)
				return this.callCarrierSelect(rateParamsArray[0].rateID).pipe(
					expand(
						(response) => /*(response && count < 2 ? this.callCarrierSelect(rateParamsArray[count].rateID) : EMPTY))*/ {
							if (response && count < rateParamsArray.length - 1) {
								count++;
								return this.callCarrierSelect(rateParamsArray[count].rateID);
							} else {
								return EMPTY;
							}
						}
					)
				);
			})
		)
	);
	getPaymentData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getPaymentData),
			concatLatestFrom(() => [this.store.select(getSelectedRate), this.store.select(getSelectedRatePlan)]),
			switchMap(([action, selectedRate, selectedPlan]) =>
				this.actionService
					.getPaymentData(selectedRate.id, selectedPlan.id)
					.pipe(map((serverRes: any) => setPaymentData({ url: serverRes.url, transactionId: serverRes.transactionId })))
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store,
		private routingService: RoutingService,
		private actionService: ActionApiService
	) {}

	callCarrierSelect(rateID: string) {
		return this.actionService
			.carrierSelect(rateID)
			.pipe(map(() => carrierSelectSubmitted({ carrier: this.localCarrier })));
	}
}
