import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActionApiService } from './action-api.service';
import { selectQuoteData } from '../store/selectors/quote-data.selectors';
import { first } from 'rxjs';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { Store } from '@ngrx/store';
import { UpdateInterviewMetadata } from '../store/actions/interview-metadata.actions';
import { MergeQuoteData, UpdateQuoteData } from '../store/actions/quote-data.actions';
import { RoutingService } from './routing.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class PrebindService {
	apiPrebindInterval;

	constructor(
		private store: Store,
		private actionApiService: ActionApiService,
		private routingService: RoutingService
	) {}

	startPrebind(carrierName, lob, numberOfDrivers?, allowNavigateToError = true) {
		this.store.dispatch(UpdateInterviewMetadata({ data: { preBindProcess: true } }));

		this.actionApiService.preBind(carrierName).subscribe({
			next: () => {
				const intervalFunction = () => {
					this.actionApiService.preBindResults(carrierName).subscribe((res: any) => {
						if (res.status === 'Completed') {
							clearInterval(this.apiPrebindInterval);
							this.actionApiService
								.clearQQDefaults({ carrier: carrierName, product: lob })
								.subscribe((clearDefaultRes) => {
									// clear defaults
									if (clearDefaultRes) {
										this.store.dispatch(MergeQuoteData({ data: clearDefaultRes, deleteOnEmpty: false }));
									}
									if (res.quotes.length == 0) {
										if (allowNavigateToError) {
											this.navigateToNoResult();
										}
										this.onFinishPrebindAndUpdate([]);
										this.store.dispatch(UpdateInterviewMetadata({ data: { preBindSucceed: false } }));
									} else {
										this.store.dispatch(UpdateInterviewMetadata({ data: { preBindSucceed: true } }));
										let filteredDrivers = [];
										if (res.updatedData && res.updatedData['DiscoveredDrivers']) {
											if (numberOfDrivers) {
												res.updatedData['DiscoveredDrivers'] = res.updatedData['DiscoveredDrivers'].map(
													(item, index) => {
														return {
															...item,
															SequenceNum: index + numberOfDrivers,
														};
													}
												);
											}
											let splittedData = this.filterDrivers(res.updatedData['DiscoveredDrivers']);
											filteredDrivers = splittedData.missingDrivers;
											if (splittedData.onApplicationDrivers.length > 0) {
												this.actionApiService
													.updateApplication({
														DiscoveredDrivers: splittedData.onApplicationDrivers.map((item) => {
															return {
																Id: item.Id,
																SequenceNum: item.SequenceNum,
																DriverAction: 'AlreadyOnApplication',
															};
														}),
													})
													.subscribe(() => {
														this.onFinishPrebindAndUpdate(filteredDrivers);
													});
											} else {
												this.onFinishPrebindAndUpdate(filteredDrivers);
											}
										} else {
											//no missing drivers
											this.onFinishPrebindAndUpdate([]);
										}
									}
								});
						}
					});
				};

				this.apiPrebindInterval = setInterval(() => {
					intervalFunction();
				}, 5000);
			},
			error: (e) => this.routingService.navigateToRoute(StepsEnum.CARRIER_KICKOUT),
		});
	}

	onFinishPrebindAndUpdate(filteredDrivers) {
		this.store.dispatch(UpdateQuoteData({ data: { missingDrivers: filteredDrivers } }));
		this.store.dispatch(UpdateInterviewMetadata({ data: { preBindProcess: false } }));
	}

	navigateToNoResult(): void {
		this.routingService.navigateToErrorPage(StepsEnum.NO_RESULTS);
	}

	filterDrivers(drivers) {
		let driversObj = {
			missingDrivers: [],
			onApplicationDrivers: [],
		};

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((res) => {
				if (res.discoverDrivers.length > 0) {
					drivers.forEach((driver) => {
						let i = res.discoverDrivers.findIndex(
							(item) =>
								item.FirstName == driver.FirstName &&
								item.LastName == driver.LastName &&
								item.Gender == driver.Gender &&
								item.active == false
						);
						if (i !== -1) {
							driversObj.missingDrivers.push(driver);
						} else {
							driversObj.onApplicationDrivers.push(driver);
						}
					});
					return driversObj;
				} else {
					driversObj.missingDrivers = drivers;
					return driversObj;
				}
			});
		return driversObj;
	}
}
