<section class="main-content main-content-personal-details">
	<app-page-heading
		[subtitle]="'titles.answerFollowingQuestions' | translate"
		[title]="'titles.lastDetailsToConfirm' | translate"
	></app-page-heading>
	<form #myform="ngForm" [formGroup]="form">
		<div class="questions-container">
			<app-question-wrapper [show]="this.form.controls.AutoInsuranceCancelled.enabled">
				<app-dropdown
					[labelInsideField]="'labels.autoInsuranceCancelledIn5Years' | translate"
					[options]="FieldOptions.YesNoOptions"
					[reAlphabeticalOrder]="false"
					name="AutoInsuranceCancelled"
					searchable="true"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper [show]="this.form.controls.CurrentPersonalAutoCarrier.enabled">
				<app-dropdown
					[labelInsideField]="'labels.currentInsuranceProvider' | translate"
					[name]="Fields.CurrentPersonalAutoCarrier.name"
					[options]="carriersList"
					[reAlphabeticalOrder]="false"
					searchable="true"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="
					this.form.controls.CurrentPersonalAutoCarrier.value == 'NoPriorInsurance' &&
					this.form.controls.SelectReasonNoPriorAutoInsurance.enabled
				"
			>
				<app-dropdown
					[labelInsideField]="'labels.reasonNoPriorAutoInsurance' | translate"
					[name]="Fields.SelectReasonNoPriorAutoInsurance.name"
					[options]="reasonsNoPrior"
					[reAlphabeticalOrder]="false"
				></app-dropdown>
			</app-question-wrapper>

			<ng-container *ngIf="this.form.controls.CurrentPersonalAutoCarrier.value !== 'NoPriorInsurance'">
				<app-question-wrapper [show]="this.form.controls.PriorCarrierExpirationDateAuto.enabled" class="date-of-birth">
					<masked-input
						[inputMask]="'**/**/9999'"
						[name]="Fields.PriorCarrierExpirationDateAuto.name"
						[placeholder]="'labels.whenPolicyExpire' | translate"
						class="date-input"
					>
					</masked-input>
				</app-question-wrapper>

				<app-question-wrapper [show]="this.form.controls.YearsWithContinuousCoverageAuto.enabled">
					<unmasked-input
						[autocomplete]="false"
						[pattern]="'^[0-9]*$'"
						[placeholder]="'labels.yearsWithContinuousCoverageAuto' | translate"
						maxlength="2"
						name="YearsWithContinuousCoverageAuto"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper [show]="this.form.controls.YearsWithPriorCarrierAuto.enabled">
					<unmasked-input
						[autocomplete]="false"
						[pattern]="'^[0-9]*$'"
						[placeholder]="'labels.yearsInsuredWithCompany' | translate"
						maxlength="2"
						name="YearsWithPriorCarrierAuto"
						secondaryType="number"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper [show]="this.form.controls.SelectPriorLiabilityLimitsAuto.enabled">
					<app-dropdown
						[labelInsideField]="'labels.currentBodilyInjuryLimits' | translate"
						[options]="priorLiabilityLimitsAutoOptions"
						[reAlphabeticalOrder]="false"
						name="SelectPriorLiabilityLimitsAuto"
					></app-dropdown>
				</app-question-wrapper>
			</ng-container>

			<app-question-wrapper [show]="!!this.form.controls['FQData.SafecoPersonalPersonalAuto.VehDeliveryUse']">
				<app-dropdown
					[labelInsideField]="'labels.vehiclesUsedForDelivery' | translate"
					[options]="FieldOptions.YesNoOptions"
					[reAlphabeticalOrder]="false"
					name="FQData.SafecoPersonalPersonalAuto.VehDeliveryUse"
					searchable="true"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper [show]="this.isNCstate">
				<app-dropdown
					[labelInsideField]="'labels.residencyVerificationNC' | translate"
					[options]="FieldOptions.YesNoOptions"
					[reAlphabeticalOrder]="false"
					name="FQData.SafecoPersonalPersonalAuto.ResidencyVerificationNC"
					searchable="true"
				></app-dropdown>
			</app-question-wrapper>

			<div class="spacer"></div>
			<next-button
				(click)="handleContinue()"
				(validationFailed)="handleValidation($event)"
				[animation]="true"
				[disabled]="!this.form.valid"
				[title]="'buttons.continue' | translate"
				continue="true"
				isFullQuote="true"
			></next-button>
			<button (click)="goBack()" class="go-back">{{ "buttons.goBack" | translate }}</button>
		</div>
	</form>
</section>
