<section class="main-content main-content-personal-details">
	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<p class="header-text">{{ "titles.noLongerDriverInYourHousehold" | translate }}</p>
			<p class="subtitle-text">
				{{ "titles.driversNotListedReason" | translate }}
			</p>
			<div class="dropdowns-wrraper">
				<form *ngFor="let driver of drivers; let index = index" [formGroup]="getMissingDriverForm(index)">
					<p class="name-driver">
						{{
							getMissingDriverForm(index).get("FirstName").value +
								" " +
								getMissingDriverForm(index).get("LastName").value
						}}
					</p>
					<app-question-wrapper
						[show]="
							getMissingDriverForm(index).get(Fields.DriverAction.name) &&
							getMissingDriverForm(index).get(Fields.DriverAction.name).enabled
						"
						class="gender"
					>
						<app-dropdown
							[labelInsideField]="'labels.selectReason' | translate"
							[name]="Fields.DriverAction.name"
							[options]="FieldOptions.missingDriverProgressiveOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getMissingDriverForm(index).get(Fields.DriverAction.name) &&
								getMissingDriverForm(index).get(Fields.DriverAction.name).enabled
									? 0
									: -1
							"
							notShowLabelWhenSelected="true"
						></app-dropdown>
					</app-question-wrapper>
				</form>
			</div>
		</div>
	</form>

	<div class="next-button-wrapper">
		<next-button
			(click)="handleContinue()"
			[disabled]="!this.form.valid"
			[title]="'buttons.continue' | translate"
			continue="true"
		></next-button>
		<button (click)="goBack()" class="go-back">{{ "buttons.goBack" | translate }}</button>
	</div>
</section>
