import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-oem-selection',
	templateUrl: './oem-selection.component.html',
	styleUrls: ['./oem-selection.component.scss'],
})
export class OemSelectionComponent {
	@Input() data: any;

	constructor() {}
}
