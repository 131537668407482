import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpsellBoxComponent } from './components/upsell-box/upsell-box.component';
import { FormControlModule } from '../../form-controls/form-control.module';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
	declarations: [UpsellBoxComponent],
	exports: [UpsellBoxComponent],
	imports: [CommonModule, FormControlModule, DirectivesModule],
})
export class UpsellBoxModule {}
