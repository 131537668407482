<section class="main-content">
	<app-page-heading [title]="'titles.lobSelection' | translate"></app-page-heading>

	<div class="lob-selection-rows">
		<app-radio-buttons-multi-selection
			(handleSelects)="handleSelects($event)"
			[options]="lobsToShow"
			[possibleBundles]="lobBundle"
			name="lobs"
		>
		</app-radio-buttons-multi-selection>

		<app-radio-buttons-multi-selection
			class="offline-lobs"
			(handleSelects)="handleSelects($event)"
			[options]="offlineLobsToShow"
			[possibleBundles]="offlineLobBundle"
			name="offlineLobs"
		>
		</app-radio-buttons-multi-selection>
	</div>

	<next-button
		(clicked)="handleContinue()"
		[disabled]="!this.lobsSelected.length"
		continue="true"
		[title]="'buttons.continue' | translate"
	></next-button>
</section>
