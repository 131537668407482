<section class="main-content agent-details">
	<div class="top-background"></div>
	<img [src]="this.data.payload.img" alt="null" class="image-container" role="presentation" />

	<h1 class="name">{{ this.data.payload.name }}</h1>
	<h2 class="address">{{ this.data.payload.location }}</h2>
	<a class="phone-number" href="tel:419 298 8826" tabindex="0">{{ phoneNumber }}</a>

	<div class="divider"><span>OR</span></div>

	<button (click)="handleRequestCallback()" class="app-button" tabindex="0">
		<span class="title">
			<span>{{ "buttons.requestCallback" | translate }}</span>
			<i appHiddenWhenCssDisabled class="material-icons" role="presentation">chevron_right</i>
		</span>
	</button>
</section>
