import { StepsEnum } from '../../enums/steps.enum';
import { StepsTitleEnum } from '../../enums/steps-title.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';

export interface IProgressMeter {
	activeStep: number;
	flowType: string;
	flowOptions: { [key: string]: IStep[] };
	steps?: IStep[];
}

export interface IStep {
	name?: string;
	visited?: boolean;
	active?: boolean;
	isCrossSell?: LobsEnum[];
	stepProperties?: IStepProperties;
	/*
	 * required fields for the step
	 * childQuestions are the fields that are required if the parent field is true
	 * relevantLobs is the lobs that the field is required for
	 */
	requiredFields?: {
		name: string;
		isReproducible?: string;
		childQuestions?: any[];
		relevantLobs?: string[];
		conditions?: string[];
	}[];
	checkCondition?: boolean;
}

export interface IStepProperties {
	phoneVisible?: boolean;
	title?: StepsTitleEnum | string;
	multiLobTitle?: StepsTitleEnum | string;
	showStepAsActiveFor?: string[];
}

export const GeneralSteps: { [key: string]: IStep } = {
	YOUR_ADDRESS: {
		name: StepsEnum.YOUR_ADDRESS,
		visited: true,
		stepProperties: {
			phoneVisible: false,
		},
		requiredFields: [
			{ name: 'PropertyAddress.AddressLine1' },
			{ name: 'PropertyAddress.City' },
			{ name: 'PropertyAddress.State' },
			{ name: 'PropertyAddress.ZipCode' },
		],
	},
	LOB_SELECTION: {
		name: StepsEnum.LOB_SELECTION,
		visited: false,
		stepProperties: {
			phoneVisible: false,
		},
	},
	START_DATE: {
		name: StepsEnum.POLICY_START_DATE,
		visited: false,
		stepProperties: {},
		requiredFields: [{ name: 'EffectiveDate' }],
	},
	CONFIRMATION_EMAIL_SENT: {
		name: StepsEnum.CONFIRMATION_EMAIL_SENT,
		checkCondition: true,
		visited: false,
		stepProperties: {},
		requiredFields: [{ name: 'EffectiveDate' }],
	},
	RESULT: {
		name: StepsEnum.RESULT,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
};

let init = [
	{
		name: StepsEnum.YOUR_ADDRESS,
		visited: true,
		stepProperties: {
			phoneVisible: false,
		},
		requiredFields: [
			{ name: 'PropertyAddress.AddressLine1' },
			{ name: 'PropertyAddress.City' },
			{ name: 'PropertyAddress.State' },
			{ name: 'PropertyAddress.ZipCode' },
		],
	},
	{
		name: StepsEnum.LOB_SELECTION,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: false,
		},
	},
];

let stillwater_steps = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER_SECOND_PAGE,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
];

let stillwater_home_steps = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_INFORMATION_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_INFORMATION_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_SAFETY_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_FEATURES_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_FEATURES_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_UTILITIES_STILLWATER_HOME,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},

	{
		name: StepsEnum.CURRENT_INSURANCE_STILLWATER,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.CARRIER_DISCLOSURE_STILLWATER,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
];

let lemonade_steps = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_LEMONADE,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
];

let safeco_steps = [
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_POLICY,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_MISSING_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_VEHICLES,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
];

let progressive_steps = [
	{
		name: StepsEnum.PROGRESSIVE_DISCLOSURE,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_SNAPSHOT,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_VEHICLES,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS_MISSING_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_ADDITIONAL_DRIVERS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
	{
		name: StepsEnum.PROGRESSIVE_COVERAGES,
		checkCondition: true,
		visited: false,
		stepProperties: {
			phoneVisible: true,
		},
	},
];

const home_cross_sale_stage = [
	{
		name: StepsEnum.ALMOST_THERE,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			multiLobTitle: StepsTitleEnum.HOME,
			showStepAsActiveFor: [
				StepsEnum.PRIMARY_RESIDENCE,
				StepsEnum.PROPERTY_USAGE,
				StepsEnum.HOUSE_DETAILS,
				StepsEnum.ROOF_REPLACEMENT,
				StepsEnum.SAFETY_ALARMS,
			],
		},
	},
	{
		name: StepsEnum.PROPERTY_USAGE,
		checkCondition: true,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
		},
	},
	{
		name: StepsEnum.HOUSE_DETAILS,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
		},
	},
	{
		name: StepsEnum.ROOF_REPLACEMENT,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		checkCondition: true,
		stepProperties: {
			phoneVisible: false,
		},
	},
	{
		name: StepsEnum.SAFETY_ALARMS,
		isCrossSell: [LobsEnum.PERSONAL_HOME, LobsEnum.CONDOMINIUM, LobsEnum.DWELLING_FIRE],
		visited: false,
		stepProperties: {
			phoneVisible: false,
		},
		requiredFields: [
			{ name: 'BurglarAlarm', childQuestions: [{ name: 'BurglarAlarmType' }] },
			{ name: 'DeadBoltLocks' },
			{ name: 'FireDetection', childQuestions: [{ name: 'FireDetectionType' }] },
			{ name: 'FireExtinguisher' },
			{ name: 'SmokeDetector' },
			{
				name: 'SprinklerSystem',
				relevantLobs: [LobsEnum.PERSONAL_HOME],
				childQuestions: [{ name: 'SprinklerSystemType' }],
			},
		],
	},
];

const auto_cross_sale_stage = [
	{
		name: StepsEnum.ALMOST_THERE,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			multiLobTitle: StepsTitleEnum.AUTO,
			showStepAsActiveFor: [
				StepsEnum.PRIMARY_RESIDENCE,
				StepsEnum.PROPERTY_USAGE,
				StepsEnum.HOUSE_DETAILS,
				StepsEnum.ROOF_REPLACEMENT,
				StepsEnum.SAFETY_ALARMS,
			],
		},
	},
	{
		name: StepsEnum.DRIVERS,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
		},
	},
	{
		name: StepsEnum.VEHICLES,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			title: StepsTitleEnum.VEHICLES,
		},
		requiredFields: [
			{ name: 'PersonalVehicles.0.PLYear' },
			{ name: 'PersonalVehicles.0.PLMake' },
			{ name: 'PersonalVehicles.0.PLModel' },
			{ name: 'PersonalVehicles.0.BodyStyle' },
			{ name: 'PersonalVehicles.0.NumberOfMiles' },
		],
	},
	{
		name: StepsEnum.DRIVER_HISTORY,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			title: StepsTitleEnum.DRIVER_HISTORY,
		},
		requiredFields: [
			{
				name: 'HasAccidents',
				isReproducible: 'Drivers',
				childQuestions: [
					{ name: 'Description', isReproducible: 'Accidents' },
					{ name: 'DateOfAccident', isReproducible: 'Accidents' },
				],
			},
			{
				name: 'HasViolations',
				isReproducible: 'Drivers',
				childQuestions: [
					{ name: 'Description', isReproducible: 'Violations' },
					{ name: 'DateOfViolation', isReproducible: 'Violations' },
				],
			},
			{
				name: 'HasAutoLosses',
				isReproducible: 'Drivers',
				childQuestions: [
					{ name: 'AutoLossesDescription', isReproducible: 'Losses' },
					{ name: 'AutoLossesDate', isReproducible: 'Losses' },
				],
			},
		],
	},
	{
		name: StepsEnum.AUTO_COVERAGES,
		isCrossSell: [LobsEnum.PERSONAL_AUTO],
		visited: false,
		stepProperties: {
			phoneVisible: false,
			title: StepsTitleEnum.AUTO_COVERAGES,
		},
		requiredFields: [
			{ name: 'BI' },
			{ name: 'PersonalVehicles.0.CollDeductible' },
			{ name: 'PersonalVehicles.0.CompDeductible' },
		],
	},
];

export const initialProgressMeter: IProgressMeter = {
	activeStep: 0,
	flowType: 'init',
	flowOptions: {
		init,
		[LobsEnum.RENTERS]: [
			...init,
			{
				name: StepsEnum.PROPERTIES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [{ name: 'PLTypeOfDwelling' }],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			{
				name: StepsEnum.POLICY_DATEPICKER,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			GeneralSteps.RESULT,
			...stillwater_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
		],
		[LobsEnum.PERSONAL_HOME]: [
			...init,
			{
				name: StepsEnum.PROPERTIES,
				visited: false,
				stepProperties: {
					title: StepsTitleEnum.PROPERTIES,
					multiLobTitle: StepsTitleEnum.HOME,
					phoneVisible: false,
					showStepAsActiveFor: [
						StepsEnum.PRIMARY_RESIDENCE,
						StepsEnum.PROPERTY_USAGE,
						StepsEnum.HOUSE_DETAILS,
						StepsEnum.ROOF_REPLACEMENT,
						StepsEnum.SAFETY_ALARMS,
						StepsEnum.PERSONAL_DETAILS_HOMEOWNERS,
					],
				},
				requiredFields: [{ name: 'PLTypeOfDwelling' }],
			},
			{
				name: StepsEnum.PRIMARY_RESIDENCE,
				visited: false,
				stepProperties: {
					title: StepsTitleEnum.PRIMARY_RESIDENCE,
					phoneVisible: false,
				},
				requiredFields: [{ name: 'OccupancyType' }],
			},
			{
				name: StepsEnum.PROPERTY_USAGE,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.HOUSE_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.ROOF_REPLACEMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.SAFETY_ALARMS,
				visited: false,
				stepProperties: {
					title: StepsTitleEnum.SAFETY_ALARMS,
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'BurglarAlarm', childQuestions: [{ name: 'BurglarAlarmType' }] },
					{ name: 'DeadBoltLocks' },
					{ name: 'FireDetection', childQuestions: [{ name: 'FireDetectionType' }] },
					{ name: 'FireExtinguisher' },
					{ name: 'SmokeDetector' },
					{
						name: 'SprinklerSystem',
						childQuestions: [{ name: 'SprinklerSystemType' }],
						relevantLobs: [LobsEnum.PERSONAL_HOME],
					},
				],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS_HOMEOWNERS,
				visited: false,
				stepProperties: {
					title: StepsTitleEnum.PERSONAL_DETAILS_HOMEOWNERS,
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			...auto_cross_sale_stage,
			GeneralSteps.START_DATE,
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				name: StepsEnum.RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
					multiLobTitle: StepsTitleEnum.RESULT,
				},
			},
			...stillwater_home_steps,
			...lemonade_steps,
			...safeco_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
		],
		[LobsEnum.PERSONAL_AUTO]: [
			...init,
			{
				name: StepsEnum.PRIMARY_DRIVER,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					title: StepsTitleEnum.DRIVERS,
					multiLobTitle: StepsTitleEnum.AUTO,
					showStepAsActiveFor: [
						StepsEnum.DRIVERS,
						StepsEnum.VEHICLES,
						StepsEnum.DRIVER_HISTORY,
						StepsEnum.AUTO_COVERAGES,
					],
				},
				requiredFields: [
					{ name: 'FirstName' },
					{ name: 'LastName' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Drivers.0.DriverLicenseStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			{
				name: StepsEnum.DRIVERS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.VEHICLES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					title: StepsTitleEnum.VEHICLES,
				},
				requiredFields: [
					{ name: 'PersonalVehicles.0.PLYear' },
					{ name: 'PersonalVehicles.0.PLMake' },
					{ name: 'PersonalVehicles.0.PLModel' },
					{ name: 'PersonalVehicles.0.BodyStyle' },
					{ name: 'PersonalVehicles.0.NumberOfMiles' },
				],
			},
			{
				name: StepsEnum.DRIVER_HISTORY,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					title: StepsTitleEnum.DRIVER_HISTORY,
				},
				requiredFields: [
					{
						name: 'HasAccidents',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Accidents' },
							{ name: 'DateOfAccident', isReproducible: 'Accidents' },
						],
					},
					{
						name: 'HasViolations',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Violations' },
							{ name: 'DateOfViolation', isReproducible: 'Violations' },
						],
					},
					{
						name: 'HasAutoLosses',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'AutoLossesDescription', isReproducible: 'Losses' },
							{ name: 'AutoLossesDate', isReproducible: 'Losses' },
						],
					},
				],
			},
			{
				name: StepsEnum.AUTO_COVERAGES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					title: StepsTitleEnum.AUTO_COVERAGES,
				},
				requiredFields: [
					{ name: 'BI' },
					{ name: 'PersonalVehicles.0.CollDeductible' },
					{ name: 'PersonalVehicles.0.CompDeductible' },
				],
			},
			...home_cross_sale_stage,
			{
				...GeneralSteps.START_DATE,
				stepProperties: {
					phoneVisible: false,
					title: StepsTitleEnum.POLICY_START_DATE,
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				...GeneralSteps.RESULT,
				stepProperties: {
					phoneVisible: true,
					title: StepsTitleEnum.RESULT,
					multiLobTitle: StepsTitleEnum.RESULT,
				},
			},
			...stillwater_steps,
			...lemonade_steps,
			...safeco_steps,
			...progressive_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
		],
		[LobsEnum.PETS]: [
			...init,
			{
				name: StepsEnum.PET_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'Pets.0.PLPetSpecies' },
					{ name: 'Pets.0.PLPetName' },
					{ name: 'Pets.0.PLPetBreedType' },
					{ name: 'Pets.0.PLPetBreed' },
					{ name: 'Pets.0.PLPetGender' },
					{ name: 'Pets.0.PetDOB' },
					{ name: 'Pets.0.PLSpayedNeutered' },
					{ name: 'Pets.0.PLHasDisease' },
				],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'FirstName' },
					{ name: 'LastName' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
				],
			},
			{
				name: StepsEnum.POLICY_DATEPICKER,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			GeneralSteps.RESULT,
			...stillwater_steps,
			...lemonade_steps,
			...safeco_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_ESCROW,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
		],
		[LobsEnum.HOME_AUTO]: [
			...init,
			{
				name: StepsEnum.PROPERTIES,
				visited: false,
				stepProperties: {
					multiLobTitle: StepsTitleEnum.HOME,
					phoneVisible: false,
					showStepAsActiveFor: [
						StepsEnum.PRIMARY_RESIDENCE,
						StepsEnum.PROPERTY_USAGE,
						StepsEnum.HOUSE_DETAILS,
						StepsEnum.ROOF_REPLACEMENT,
						StepsEnum.SAFETY_ALARMS,
						StepsEnum.PERSONAL_DETAILS_HOMEOWNERS,
					],
				},
				requiredFields: [{ name: 'PLTypeOfDwelling' }],
			},
			{
				name: StepsEnum.PRIMARY_RESIDENCE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [{ name: 'OccupancyType' }],
			},
			{
				name: StepsEnum.PROPERTY_USAGE,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.HOUSE_DETAILS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.ROOF_REPLACEMENT,
				checkCondition: true,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.SAFETY_ALARMS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'BurglarAlarm', childQuestions: [{ name: 'BurglarAlarmType' }] },
					{ name: 'DeadBoltLocks' },
					{ name: 'FireDetection', childQuestions: [{ name: 'FireDetectionType' }] },
					{ name: 'FireExtinguisher' },
					{ name: 'SmokeDetector' },
					{
						name: 'SprinklerSystem',
						childQuestions: [{ name: 'SprinklerSystemType' }],
						relevantLobs: [LobsEnum.PERSONAL_HOME],
					},
				],
			},
			{
				name: StepsEnum.PERSONAL_DETAILS_BUNDLE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'CreditCheckPermission' },
					{ name: 'PersonalLineGender' },
					{ name: 'MaritalStatus' },
					{ name: 'Email' },
					{ name: 'PrimaryPhoneNumber' },
					{ name: 'DateOfBirth' },
					{ name: 'IAgreeToReceiveEmailsByBolt' },
					{ name: 'Drivers.0.DriverLicenseStatus' },
				],
			},
			{
				name: StepsEnum.ALMOST_THERE,
				visited: false,
				stepProperties: {
					phoneVisible: false,
					multiLobTitle: StepsTitleEnum.AUTO,
					showStepAsActiveFor: [
						StepsEnum.DRIVERS,
						StepsEnum.VEHICLES,
						StepsEnum.DRIVER_HISTORY,
						StepsEnum.AUTO_COVERAGES,
						StepsEnum.POLICY_START_DATE,
					],
				},
			},
			{
				name: StepsEnum.DRIVERS,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
			},
			{
				name: StepsEnum.VEHICLES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'PersonalVehicles.0.PLYear' },
					{ name: 'PersonalVehicles.0.PLMake' },
					{ name: 'PersonalVehicles.0.PLModel' },
					{ name: 'PersonalVehicles.0.BodyStyle' },
					{ name: 'PersonalVehicles.0.NumberOfMiles' },
				],
			},
			{
				name: StepsEnum.DRIVER_HISTORY,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{
						name: 'HasAccidents',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Accidents' },
							{ name: 'DateOfAccident', isReproducible: 'Accidents' },
						],
					},
					{
						name: 'HasViolations',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'Description', isReproducible: 'Violations' },
							{ name: 'DateOfViolation', isReproducible: 'Violations' },
						],
					},
					{
						name: 'HasAutoLosses',
						isReproducible: 'Drivers',
						childQuestions: [
							{ name: 'AutoLossesDescription', isReproducible: 'Losses' },
							{ name: 'AutoLossesDate', isReproducible: 'Losses' },
						],
					},
				],
			},
			{
				name: StepsEnum.AUTO_COVERAGES,
				visited: false,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [
					{ name: 'BI' },
					{ name: 'PersonalVehicles.0.CollDeductible' },
					{ name: 'PersonalVehicles.0.CompDeductible' },
				],
			},
			{
				...GeneralSteps.START_DATE,
				stepProperties: {
					phoneVisible: false,
				},
				requiredFields: [{ name: 'EffectiveDate' }],
			},
			GeneralSteps.CONFIRMATION_EMAIL_SENT,
			{
				...GeneralSteps.RESULT,
				stepProperties: {
					phoneVisible: true,
					multiLobTitle: StepsTitleEnum.RESULT,
				},
			},
			...stillwater_steps,
			...lemonade_steps,
			...safeco_steps,
			{
				name: StepsEnum.QUOTE_CONFIRMATION,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_PLAN,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
			{
				name: StepsEnum.PAYMENT_RESULT,
				visited: false,
				stepProperties: {
					phoneVisible: true,
				},
			},
		],
	},
};
