import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';

import { EventActions } from '../../../../../../models/event-data.model';
import { GtmControlsActions } from '../../../../../../models/gtm-controls-actions.enum';
import { EventsService } from 'src/app/services/events.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
	selector: 'app-radio-buttons-multi-selection',
	templateUrl: './radio-buttons.component.html',
	styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsMultiSelectionComponent implements OnInit {
	@Output() handleSelects = new EventEmitter();
	@Input() selectedOptions: any[];
	@Input() options;
	@Input() name;
	@Input() lobSelectionPage: boolean;
	@Input() possibleBundles;
	@Output() childSubmit = new EventEmitter<any>();

	isPrefilled = false;

	constructor(private store: Store, private eventsService: EventsService, private translateService: TranslateService) {}

	ngOnInit(): void {
		this.displayAction();
	}

	handleChooseOption(option) {
		// this.selectedOptions = option;
		this.handleSelects.emit(option);
		this.editAction(option);
	}

	getFileName(optionName: string, isChecked: boolean): string {
		return isChecked ? optionName + 'Checked' : optionName;
	}

	isSomeOptionEnabled() {
		return this.options.filter((i) => i.selected == true).length > 0;
	}

	private displayAction(): void {
		const actionName = EventActions.DISPLAY;

		this.eventsService.addEvent(actionName, { label: GtmControlsActions.DISPLAY });
	}

	private prefillEdit(option) {
		this.eventsService.addEvent(EventActions.PREFILL, {
			label: this.getLabelName(GtmControlsActions.PREFILL),
			value: option,
		});
		this.isPrefilled = true;
	}

	private editAction(option) {
		const controlAction = this.isPrefilled ? 'PREFILL_EDIT' : 'EDIT';
		this.eventsService.addEvent(EventActions.EDIT, {
			label: this.getLabelName(GtmControlsActions[controlAction]),
			value: option,
		});
	}

	private getLabelName(controlAction: GtmControlsActions): string {
		return `${this.name}_${controlAction}`;
	}
}
