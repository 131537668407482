import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() falseText: string;
  @Input() trueText: string;
  @Input() initialValue: boolean;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      control: [this.initialValue]
    });

    this.form.controls.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => this.toggle.emit(value));
  }
}
