import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { hidePopupAction } from '../../../../store/actions/popup.actions';
import { Store } from '@ngrx/store';
import { ActionApiService } from '../../../../services/action-api.service';
import { Fields } from 'src/app/constants/fields';
import { Validations } from 'src/app/utils/validation';
import * as FieldOptions from '../../../../constants/field-options';
import { UpdatePersonalVehicle } from 'src/app/store/actions/quote-data.actions';
import { OwnershipTypeOptions } from 'src/app/constants/vehicle-options.data';

@UntilDestroy()
@Component({
	selector: 'primary-vehicle',
	templateUrl: './primary-vehicle.component.html',
	styleUrls: ['./primary-vehicle.component.scss'],
})
export class PrimaryVehicleComponent implements OnInit {
	@Input() data: any;
	FieldOptions = FieldOptions;
	form: FormGroup;
	Fields = Fields;
	OwnershipTypeOptions = OwnershipTypeOptions;
	constructor(private store: Store, private actionService: ActionApiService) { }

	ngOnInit(): void {
		this.form = new FormGroup({
			Id: new FormControl(null, [Validators.required]),
			OwnershipType: new FormControl(null, [Validators.required]),
			NumberOfMiles: new FormControl(null, [
				Validators.required,
				Validations.mileageMoreThan1000,
				Validations.mileageLessThan100k,
			]),
		});

		this.form.patchValue(this.data.value);
	}

	updatePrimaryVehicle() {
		this.store.dispatch(UpdatePersonalVehicle({ data: this.form.getRawValue() }));
		this.store.dispatch(hidePopupAction());
	}

	getVehicleLogoName(make: string): string {
		return make.split(' ').join('-').toLowerCase().replace('.', '');
	}

	get title(): string {
		const titleParts = [this.data.value.PLYear, this.data.value.PLMake, this.data.value.PLModel].filter((part) => part);
		return titleParts.join(' ');
	}
}
