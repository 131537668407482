<section class="main-content">
	<app-page-heading [title]="'titles.shareMoreFeatures' | translate"></app-page-heading>
	<ng-container *ngIf="form">
		<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper stillwater-fq">
			<ng-container *ngFor="let field of FieldsForCurrentPage">
				<app-question-wrapper *ngIf="form.controls[field.name]?.enabled" class="question-container">
					<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
						<label [attr.id]="field.name" class="input-label">
							{{ "fields."+field.name | translate }}
						</label>
						<div *ngIf="field.typeOfInput == 'YES/NO'; then content; else other_content"></div>
						<ng-template #content
							><app-yes-no [name]="field.name" [vertical]="vertical"> </app-yes-no>
							<mat-divider *ngIf="field.divider"></mat-divider>
						</ng-template>

						<ng-template #other_content
							><app-question-wrapper *ngIf="form.controls[field.name]?.enabled" class="question-container">
								<div class="question-wrapper show" [ngClass]="{ vertical: vertical }">
									<app-dropdown
										[options]="FieldOptions[field.name]"
										[reAlphabeticalOrder]="false"
										[labelInsideField]="'labels.pleaseSelect' | translate"
										[name]="field.name"
										notShowLabelWhenSelected="true"
									></app-dropdown>
								</div>
								<mat-divider *ngIf="field.divider"></mat-divider>
							</app-question-wrapper>
						</ng-template>
					</div>
				</app-question-wrapper>
			</ng-container>

			<next-button
				(validationFailed)="handleValidation($event)"
				[loaderVisible]="true"
				[disabled]="!form.valid"
				[myForm]="myForm"
				isFullQuote="true"
				[title]="'buttons.continue' | translate"
				[shouldIgnoreDisabledFields]="true"
				class="fullscreen"
			></next-button>
		</form>
	</ng-container>
</section>
