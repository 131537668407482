export interface IQuoteDataState {
	[key: string]: any;
}

export const initialQuoteDataState: IQuoteDataState = {
	BusinessOrDaycare: null,
	ConvictedOfFelony: false,
	CreditCheckPermission: true,
	IHerebyConfirm: true,
	CurrentPersonalHomeownerCarrier: 'AAA',
	DistanceToFireHydrant: null,
	DistanceToFireStation: null,
	EffectiveDate: null,
	PriorCarrierExperationDate: '2022-07-10',
	GatedOrLimited: null,
	HomeAutoInsurance: false,
	LastName: '',
	HomeUnderConstruction: false,
	HomeVisibleToNeighbors: true,
	LossOfUse: 40000,
	MitCreditForm: false,
	OccupancyType: '',
	PLAllPerilsDeductible: 'OneThousand',
	PLHeatingType: 'Electric',
	PLOtherStructures: 40000,
	PLTypeOfDwelling: '',
	PersonalLineGender: '',
	PlumbingType: 'EntirelyCopper',
	TypeOfFoundation: null,
	UndergroundFuelTank: false,
	YearsWithContinuousCoverageHome: 3,
	EmploymentIndustry: 'Agriclt/Forestry/Fish',
	RoofUpdatedYear: '',
	PLRoofUpdated: '',
	MonthsAtAddress: 0,
	NonSmoker: false,
	PurchasePrice: 150000,
	SinkholeInvestigationOrClaim: false,
	YearsWithPriorCarrierHome: 3,
	PriorLiabilityCoverageHome: 'Threehundredthousand',
	IAgreeToReceiveEmailsByBolt: true,
	PropertyAddress: {
		State: '',
		AddressLine1: '',
		AddressLine2: '',
		County: '',
		City: '',
		ZipCode: '',
	},
	Drivers: [],
	discoverDrivers: [],
	manualDrivers: [],
	PersonalVehicles: [],
	manualVehicles: [],
	discoverVehicles: [],
	MonthsWithPriorCarrierAuto: 0

};
