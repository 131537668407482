<section *ngIf="isD2CPage" class="main-content landing-page {{ classes }}">
	<div class="content">
		<div class="row">
			<div class="col-7 form-wrapper">
				<h1 *ngIf="!isTitleInside" appTranslation="firstSection.title" class="title"></h1>
				<p *ngIf="!isSubtitleInside" appTranslation="firstSection.subTitle" class="upperform-content"></p>

				<div [class.no-pads]="isNoPads" class="background">
					<h1 *ngIf="isTitleInside" appTranslation="firstSection.title" class="title"></h1>
					<p *ngIf="isSubtitleInside" appTranslation="firstSection.subTitle" class="upperform-content"></p>

					<form [formGroup]="form" class="form-area">
						<div class="form-control">
							<ng-container *ngIf="!showManualFields; else manualFields">
								<app-address-suggestion
									(addressChangedEvent)="this.handleAddressChange()"
									*ngIf="form.get('PropertyAddress.AddressLine1') && form.get('PropertyAddress.AddressLine1').enabled"
									[addressLine1]="form.get('PropertyAddress.AddressLine1').value"
									[addressLine2]="form.get('PropertyAddress.AddressLine2').value"
									[city]="form.get('PropertyAddress.City').value"
									[county]="form.get('PropertyAddress.County').value"
									[fieldsNames]="fieldsNames"
									[form]="form"
									[placeholder]="'address.suggestion.whatHomeAddress' | translate"
									[state]="form.get('PropertyAddress.State').value"
									[toShowMap]="false"
									[zipCode]="form.get('PropertyAddress.ZipCode').value"
								>
								</app-address-suggestion>
							</ng-container>
							<ng-template #manualFields>
								<div class="manual-fields" formGroupName="PropertyAddress">
									<app-address
										[addressLine1]="'AddressLine1'"
										[addressLine2]="'AddressLine2'"
										[city]="'City'"
										[state]="'State'"
										[zipCode]="'ZipCode'"
									>
									</app-address>
								</div>
							</ng-template>
							<error-message
								[field]="form.get('PropertyAddress').get('AddressLine1')"
								[isFocus$]="true"
							></error-message>
						</div>

						<div class="next-button">
							<next-button
								(clicked)="handleStartQuoteClick()"
								[disabled]="!form.valid"
								continue="true"
								titleKey="firstSection.button"
							></next-button>
						</div>
					</form>

					<app-image
						*ngIf="landingPage.images.startPageFormImage"
						[landingSection]="landingPage"
						alt="null"
						classNames="underform-image"
						name="startPageFormImage"
						role="presentation"
					></app-image>

					<div appTranslation="firstSection.legalText" class="fine-print form"></div>
				</div>

				<app-legal-text *ngIf="isMainLegalTextVisible" class="fine-print main"></app-legal-text>
			</div>

			<div class="col-5 image-area">
				<app-image
					[class.has-mob]="landingPage.images.startPageImageMob"
					[landingSection]="landingPage"
					alt="null"
					classNames="presentation-image"
					name="startPageImage"
					role="presentation"
				></app-image>

				<app-image
					*ngIf="landingPage.images.startPageImageMob"
					[landingSection]="landingPage"
					alt="null"
					class="mob"
					classNames="presentation-image"
					name="startPageImageMob"
					role="presentation"
				></app-image>
			</div>
		</div>
	</div>
	<app-legal-text *ngIf="isMainLegalTextVisible" class="legal-main-page"></app-legal-text>
</section>
