<section class="main-content">
	<app-page-heading
		[subtitle]="'titles.enrollInSnapshotStateDisclaimer' | translate"
		[title]="'titles.enrollInSnapshot' | translate"
	></app-page-heading>
	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="question-selector-wrapper">
			<div
				(click)="handleSelect('Mobile')"
				[ngClass]="{
					selected: form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'Mobile'
				}"
				class="option mobile-option"
			>
				<label class="inner-wrapper">
					<div class="circle">
						<div class="inner"></div>
					</div>
					<input
						[attr.checked]="
							form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'Mobile' ? true : null
						"
						class="hidden-radio"
						name="single-yes-no"
						type="radio"
						value="true"
					/>
					<span>{{ "labels.mobileApp" | translate }}</span>
				</label>
				<div
					[appHiddenWhenCssDisabled]="
						form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'NotUpdated'
					"
					class="mobile-checkbox-section"
					[ngClass]="{
						hidden: form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'NotUpdated',
						'show-checkbox':
							form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'Mobile'
					}"
				>
					<div
						[ngClass]="{
							show:
								form.get(Fields.ProgressivePersonalAutoUBIAcknowledgement.name) &&
								form.get(Fields.ProgressivePersonalAutoUBIAcknowledgement.name).enabled
						}"
						class="question-wrapper roof-shape field-inline"
					>
						<app-checkbox
							[fieldObj]="Fields.ProgressivePersonalAutoUBIAcknowledgement"
							[tabindex]="
								form.get(Fields.ProgressivePersonalAutoUBIAcknowledgement.name) &&
								form.get(Fields.ProgressivePersonalAutoUBIAcknowledgement.name).enabled
									? 0
									: -1
							"
							[valueChecked]="form.controls[Fields.ProgressivePersonalAutoUBIAcknowledgement.name].value"
							withoutErrorMessage="true"
						>
						</app-checkbox>
					</div>
				</div>
			</div>

			<div
				(click)="handleSelect('DNP')"
				[ngClass]="{
					selected: form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'DNP'
				}"
				class="option no-option"
			>
				<label class="inner-wrapper">
					<div class="circle">
						<div class="inner"></div>
					</div>
					<input
						[attr.checked]="
							form.controls[Fields.ProgressivePersonalAutoUBIExperienceEnrollment.name].value === 'DNP' ? true : null
						"
						class="hidden-radio"
						name="single-yes-no"
						type="radio"
						value="true"
					/>
					<span>{{ "labels.doNotParticipate" | translate }}</span>
				</label>
			</div>
		</div>
	</form>
	<div class="next-button-snapshot">
		<next-button
			[disabled]="!form.valid"
			[myForm]="{ form: form }"
			[title]="'buttons.continue' | translate"
			isFullQuote="true"
		></next-button>
	</div>
</section>
