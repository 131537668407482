<ng-container *ngIf="isMobile; else desktop">
	<div class="main-content contact-agent">
		<img
			[attr.alt]="data.rate.carrier + ' logo'"
			[src]="'/assets/images/logos/' + data.rate.carrier + '/logo.png'"
			class="logo"
		/>

		<div class="price-container">
			<p class="price">${{ price | number: "1.0-0" }}</p>
			<p class="monthly">
				{{ this.data.isMonthly ? "/ month *" : this.data.rate.term == "SixMonths" ? "/ 6 months *" : "/ year" }}
			</p>
		</div>
		<span class="estimated-premium-desktop"> * {{ " * " }} {{ "labels.estimatedPremium" | translate }} </span>

		<div class="divider"></div>
		<h2 [innerHTML]="title" class="call-to-finalize"></h2>

		<p class="reference">
			<span>{{ "labels.quoteReference" | translate }} {{ " # " }} </span>
			<span>{{ selectFriendly }}</span>
		</p>

		<a
			appHiddenWhenCssDisabled
			[attr.aria-label]="'personalInfo.phone' | translate"
			class="app-button button-for-mobile"
			href="tel:{{ phoneNumber }}"
			tabindex="0"
		>
			<span class="title">
				<span>{{ "buttons.callNow" | translate }} &nbsp;</span>
				<i appHiddenWhenCssDisabled class="material-icons" role="presentation">phone</i>
			</span>
		</a>

		<div class="phone-container">{{ "labels.orDial" | translate }} {{ " " }} {{ phoneNumber }}</div>
	</div>
</ng-container>
<ng-template #desktop>
	<div class="main-content contact-agent">
		<h2 class="call-to-finalize">{{ "titles.callToPurchase" | translate }}</h2>

		<div class="phone-container">
			<i appHiddenWhenCssDisabled class="material-icons phone-logo" role="presentation">phone</i>
			<a
				[eventData]="{
					action: eventActions.LINK_CLICK,
					label: 'ContactAgentPopUp.AgentPhonePopUp'
				}"
				appClickEvent
				class="phone-number"
				href="tel:{{ phoneNumber }}"
				tabindex="0"
			>
				{{ phoneNumber }}
			</a>
		</div>

		<div class="divider"></div>

		<img
			[attr.alt]="data.rate.carrier + ' logo'"
			[src]="'/assets/images/logos/' + data.rate.carrier + '/logo.png'"
			class="logo"
		/>
		<div class="price-container">
			<p class="price">${{ price | number: "1.0-0" }}</p>
			<p class="monthly">
				{{ this.data.isMonthly ? "/ month *" : this.data.rate.term == "SixMonths" ? "/ 6 months *" : "/ year *" }}
			</p>
		</div>
		<span class="estimated-premium-desktop">{{ " * " }} {{ "labels.estimatedPremium" | translate }} {{ " " }} </span>

		<p class="reference">
			<span>{{ "labels.quoteReference" | translate }} {{ " # " }}</span>
			<span>{{ selectFriendly }}</span>
		</p>
	</div>
</ng-template>
