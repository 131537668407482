import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggledCoverageComponent } from './components/toggled-coverage/toggled-coverage.component';
import { ToggleModule } from '../toggle/toggle.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [ToggledCoverageComponent],
	exports: [ToggledCoverageComponent],
	imports: [CommonModule, ToggleModule, MatTooltipModule, TranslateModule],
})
export class ToggledCoverageModule {}
