import { Injectable } from '@angular/core';
import { IAppState } from '../store/states/app.state';
import { Store } from '@ngrx/store';
import { combineLatest, first } from 'rxjs';
import { StepsEnum } from '../enums/steps.enum';
import { ProgressMeterService } from './progress-meter.service';
import { RoutingService } from './routing.service';
import { LoaderActions, ProgressMeterActions } from '../store/actions';
import { selectQuoteData } from '../store/selectors/quote-data.selectors';
import { LobsEnum } from '../enums/lobs.enum';
import { selectInterviewMetadata } from '../store/selectors/interview-metadata.selector';

@Injectable({
  providedIn: 'root'
})
export class SourceFeaturesService {
  flow;
  constructor(private store: Store<IAppState>, private progressMeterService: ProgressMeterService, private routingService: RoutingService) { }

  /**
   * Skips to the result page based on the server response.
   * Updates the flow and lobFlow based on quote data.
   *
   * @param {any} serverResponse - The server response.
   */
  skipToResultPage(serverResponse) {
    // need to add pages to flow and lobFlow based on quote data
    combineLatest([this.store.select(selectQuoteData), this.store.select(selectInterviewMetadata)])
      .pipe(first()).subscribe(([quoteData, interviewMetadata]) => {
        let lobs = interviewMetadata.lobsInitialized;
        // TODO: update when multiLob is changed
        if (!lobs || lobs.length === 0) {
          return;
        }
        lobs = lobs.filter(lob => lob !== LobsEnum.FLOOD); //remove flood

        const mappedLobs = lobs.map(item => {
          switch (item) {
            case LobsEnum.RENTERS:
              return LobsEnum.RENTERS;
            case LobsEnum.CONDOMINIUM:
              return LobsEnum.PERSONAL_HOME;
            case LobsEnum.PERSONAL_HOME:
              return LobsEnum.PERSONAL_HOME;
            case LobsEnum.DWELLING_FIRE:
              return LobsEnum.PERSONAL_HOME;
            case LobsEnum.PERSONAL_AUTO:
              return LobsEnum.PERSONAL_AUTO;
            case LobsEnum.PETS:
              return LobsEnum.PETS;
            default:
              return item; // Keep it as is if there's no mapping
          }
        });

        let flowType = mappedLobs && mappedLobs.length === 1 ? mappedLobs[0] : LobsEnum.HOME_AUTO; //TODO: update when multiLob changed
        this.progressMeterService.setFlowType(flowType);
        this.flow = this.progressMeterService.getActiveFlow();
        let routeLobName = this.progressMeterService.getRouteName(flowType);
        this.findPage(serverResponse, routeLobName || 'init', flowType, interviewMetadata.lobSelection);
      })
  }

  findPage(serverResponse, routeLobName, flowType, lobSelections: string[]) {
    let returnRoute = `${routeLobName}/${StepsEnum.RESULT}`;

    outerLoop: for (const stage of this.flow) {
      if (stage?.isCrossSell?.length > 0) { // if stage is part of cross-sell stop page-skipping
        continue;
      }

      // check if stage.requiredFields array have all it's fields in quoteData
      for (const field of stage.requiredFields || []) {

        /**
         * Handles the validation and processing of a field and its child questions.
         *
         * @param field - The field to be processed.
         * @param value - The value of the field.
         * @param validationErrors - An array of validation errors.
         * @param data - The data object containing the field values.
         * @returns A boolean indicating whether the field and its child questions passed validation.
         */
        let handleIt = (field, value, validationErrors, data) => {
          let fieldName = field.name;

          // add support if field.relevantLobs has lob and the lobSelections is not one of the relevantLobs skip this field check
          if (field.relevantLobs && field.relevantLobs.length > 0 && lobSelections && lobSelections.length > 0 && !field.relevantLobs.some(lob => lobSelections.includes(lob))) {
            return true;
          }

          if (typeof value === 'undefined' || validationErrors.some(error => error.id === fieldName)) {
            console.warn(`field ${fieldName} missing/has validation error redirect to stage`, `${routeLobName}/${stage.name}`);
            returnRoute = stage.name === StepsEnum.YOUR_ADDRESS || stage.name === StepsEnum.LOB_SELECTION ? stage.name : `${routeLobName}/${stage.name}`;
            return false;
          } else {
            // Check if the field has child questions and it's value is truthy
            if (value && field.childQuestions && field.childQuestions.length > 0) {
              let childQuestions = field.childQuestions;
              for (const childQuestion of childQuestions) {
                let fieldName = childQuestion.name;
                let value;
                if (childQuestion.isReproducible) {
                  value = this.getValue(data, `${childQuestion.isReproducible}.${data.SequenceNum}.${fieldName}`);
                } else {
                  value = this.getValue(data, fieldName);
                }
                if (!handleIt(childQuestion, value, validationErrors, data)) {
                  return false;
                }
              }
              return true;
            } else {
              // if field has any value that isn't undefined
              return true;
            }
          }
        }

        let fieldName = field.name;
        if (field.isReproducible) {
          for (let index = 0; index < serverResponse.quoteData[field.isReproducible].length; index++) {
            const item = serverResponse.quoteData[field.isReproducible][index];
            let value = this.getValue(item, fieldName);
            let validationErrors = this.modifyValidation(serverResponse.validationErrors, item);
            if (!handleIt(field, value, validationErrors, item)) {
              break outerLoop;
            }
          }
        } else {
          let value = this.getValue(serverResponse.quoteData, fieldName);
          let validationErrors = this.modifyValidation(serverResponse.validationErrors, serverResponse.quoteData);
          if (!handleIt(field, value, validationErrors, serverResponse.quoteData)) {
            break outerLoop;
          }
        }
      }
    }
    let step = returnRoute.indexOf("/") > -1 ? returnRoute.split("/")[1] : returnRoute;
    setTimeout(() => {
      this.routingService.navigateToStep(flowType, routeLobName, step, true);
      this.store.dispatch(LoaderActions.HideStartloader());
    }, 0)
    return returnRoute;
  }

  /**
   * Retrieves the value of a field from the quoteData object.
   * If the field contains a dot (.), it will use the getDescendantProp method to retrieve the nested value.
   * Otherwise, it will directly access the value from the quoteData object.
   *
   * @param quoteData - The quoteData object.
   * @param field - The field to retrieve the value from.
   * @returns The value of the field.
   */
  getValue(quoteData, field) {
    return field.indexOf('.') > -1 ? this.getDescendantProp(quoteData, field) : quoteData[field];
  }

  /**
   * Retrieves a nested property value from an object based on the provided path.
   * @param {object} theObject - The object to retrieve the property value from.
   * @param {string} path - The path to the nested property, using dot notation.
   * @returns {any} The value of the nested property, or undefined if the property does not exist.
   */
  getDescendantProp(theObject, path) {
    try {
      return path.
        split('.').
        reduce(
          function (obj, property) {
            return obj[property];
          }, theObject
        );
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Modifies the validation errors by replacing the ID with the corresponding index in the quoteData array.
   * @param validationErrors - The array of validation errors.
   * @param quoteData - The quote data object.
   * @returns An array of modified validation errors.
   */
  modifyValidation(validationErrors, quoteData): any {
    return validationErrors.map((error) => {
      // Extract the ID to be replaced from the id field
      const idToReplace = error.id.match(/'([^']+)'/);
      if (idToReplace && idToReplace.length > 1) {
        // Iterate over each property in quoteData
        for (const key in quoteData) {
          if (quoteData.hasOwnProperty(key)) {
            const value = quoteData[key];
            // Check if the value is an array
            if (Array.isArray(value)) {
              // Iterate over each item in the array and check if its id matches idToReplace
              for (let i = 0; i < value.length; i++) {
                if (value[i].Id === idToReplace[1]) {
                  // Replace the id with the index
                  const updatedError = {
                    "id": error.id.replace(`[?(@Id=='${idToReplace[1]}')]`, `.${i}`),
                    "errors": error.errors
                  };
                  return updatedError;
                }
              }
            }
          }
        }
        return error; // Return the original object if no change is needed
      }
      return error;
    });
  }
}
