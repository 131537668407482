import { Fields } from './fields';
import { IDropdownOption } from '../components/form-controls/form-control-interfaces';
import { IFieldItem } from 'src/entities/field.interface';
import { LobsEnum } from '../enums/lobs.enum';

const currentYear = new Date().getFullYear();

export const getNumberOptions = (start: number, end: number): IDropdownOption[] => {
	const result: IDropdownOption[] = [];

	for (let i = start; i <= end; i++) {
		result.push({ id: i, value: i, title: i.toString() });
	}

	return result;
};

// TODO: clean this file of unnecessary
export const animalsOnThePremisesOptions = [
	Fields.AnimalsOnThePremises_Exotic,
	Fields.AnimalsOnThePremises_Farm1to2,
	Fields.AnimalsOnThePremises_Farm3orMore,
];

export const poolFeaturesOptions: IFieldItem[] = [
	Fields.PL_PoolFeatures_DivingBoard,
	Fields.PL_PoolFeatures_4ftFence,
	Fields.PL_PoolFeatures_Slide,
	Fields.RemovableLockableLadder,
];

export const YesNoOptions: IDropdownOption[] = [
	{ id: 1, value: true, title: 'Yes', labelKey: 'yesNo.yes' },
	{ id: 2, value: false, title: 'No', labelKey: 'yesNo.no' },
];

export const typeOfHomeOptions: IDropdownOption[] = [
	{ id: 1, value: 'Apartment', title: 'Apartment', labelKey: 'typeOfHomeOptions.apartment' },
	{ id: 2, value: 'SingleFamilyHouse', title: 'Single Family House', labelKey: 'typeOfHomeOptions.singleFamilyHouse' },
	{ id: 3, value: 'Townhouse', title: 'Townhouse', labelKey: 'typeOfHomeOptions.townhouse' },
	{ id: 4, value: 'Rowhouse', title: 'Rowhouse', labelKey: 'typeOfHomeOptions.rowhouse' },
];

export const typeOfHomeOptionsHomeowners: IDropdownOption[] = [
	{
		id: 1,
		value: LobsEnum.PERSONAL_HOME,
		title: 'House',
		imageName: 'home',
		labelKey: 'typeOfHomeOptionsHomeowners.house',
	},
	{
		id: 2,
		value: LobsEnum.CONDOMINIUM,
		title: 'Condo',
		imageName: 'condo',
		labelKey: 'typeOfHomeOptionsHomeowners.condo',
	},
];

export const typeOfPropertyUsage: IDropdownOption[] = [
	{ id: 1, value: 'OwnerSecondary', title: 'Secondary / Seasonal', labelKey: 'typeOfPropertyUsage.secondarySeasonal' },
	{ id: 2, value: 'TenantPrimary', title: 'I’m renting it', labelKey: 'typeOfPropertyUsage.rentingIt' },
	{ id: 3, value: 'Vacant', title: 'It’s currently vacant', labelKey: 'typeOfPropertyUsage.currentlyVacant' },
];

export const PL_BasementFinish: IDropdownOption[] = [
	{ id: 5, value: 'None', title: '0%', labelKey: 'basementFinishTypes.none' },
	{ id: 4, value: 'Quarter', title: '25%', labelKey: 'basementFinishTypes.quarter' },
	{ id: 3, value: 'Half', title: '50%', labelKey: 'basementFinishTypes.half' },
	{ id: 2, value: 'ThreeQuarters', title: '75%', labelKey: 'basementFinishTypes.threeQuarters' },
	{ id: 1, value: 'full', title: '100%', labelKey: 'basementFinishTypes.full' },
];

export const typeOfDwellingOptionsHouse: IDropdownOption[] = [
	{
		id: 1,
		value: 'SingleFamilyHouse',
		title: 'Single Family House',
		labelKey: 'typeOfDwellingOptionsHouse.singleFamilyHouse',
	},
	{ id: 2, value: 'Townhouse', title: 'Townhouse', labelKey: 'typeOfDwellingOptionsHouse.townhouse' },
	{ id: 3, value: 'Rowhouse', title: 'Rowhouse', labelKey: 'typeOfDwellingOptionsHouse.rowhouse' },
	{ id: 4, value: 'Duplex', title: 'Duplex/Two family unit', labelKey: 'typeOfDwellingOptionsHouse.duplex' },
	{ id: 5, value: 'Triplex', title: 'Tri-plex/Four family unit', labelKey: 'typeOfDwellingOptionsHouse.triplex' },
	{ id: 6, value: 'Fourplex', title: 'Four-plex/Four family unit', labelKey: 'typeOfDwellingOptionsHouse.fourplex' },
	{ id: 7, value: 'MultFamily', title: 'Multi-Family', labelKey: 'typeOfDwellingOptionsHouse.multFamily' },
];

export const typeOfDwellingOptionsHouseHO6: IDropdownOption[] = [
	{
		id: 1,
		value: 'SingleFamilyHouse',
		title: 'Single Family House',
		labelKey: 'typeOfDwellingOptionsHouseHO6.singleFamilyHouse',
	},
	{ id: 2, value: 'Townhouse', title: 'Townhouse', labelKey: 'typeOfDwellingOptionsHouseHO6.townhouse' },
	{ id: 3, value: 'Rowhouse', title: 'Rowhouse', labelKey: 'typeOfDwellingOptionsHouseHO6.rowhouse' },
	{ id: 4, value: 'Duplex', title: 'Duplex/Two family unit', labelKey: 'typeOfDwellingOptionsHouseHO6.duplex' },
	{ id: 5, value: 'Triplex', title: 'Tri-plex/Four family unit', labelKey: 'typeOfDwellingOptionsHouseHO6.triplex' },
	{ id: 6, value: 'Fourplex', title: 'Four-plex/Four family unit', labelKey: 'typeOfDwellingOptionsHouseHO6.fourplex' },
	{ id: 7, value: 'Apartment', title: 'Apartment', labelKey: 'typeOfDwellingOptionsHouseHO6.apartment' },
	{ id: 8, value: 'CoOp', title: 'Co-op', labelKey: 'typeOfDwellingOptionsHouseHO6.coop' },
	{
		id: 9,
		value: 'ManufacturedHome',
		title: 'Manufactured Home',
		labelKey: 'typeOfDwellingOptionsHouseHO6.manufacturedHome',
	},
	{ id: 10, value: 'MultFamily', title: 'Multi-Family', labelKey: 'typeOfDwellingOptionsHouseHO6.multFamily' },
];

export const RoofUpdatedYear: IDropdownOption[] = [
	{ id: 1, value: currentYear - 1, title: '1 year ago', labelKey: 'roofUpdatedYear.oneYearAgo' },
	{ id: 2, value: currentYear - 2, title: '2 years ago', labelKey: 'roofUpdatedYear.twoYearsAgo' },
	{ id: 3, value: currentYear - 3, title: '3 years ago', labelKey: 'roofUpdatedYear.threeYearsAgo' },
	{ id: 4, value: currentYear - 4, title: '4 years ago', labelKey: 'roofUpdatedYear.fourYearsAgo' },
	{ id: 5, value: currentYear - 5, title: '5 years ago', labelKey: 'roofUpdatedYear.fiveYearsAgo' },
	{ id: 6, value: currentYear - 6, title: '6 years ago', labelKey: 'roofUpdatedYear.sixYearsAgo' },
	{ id: 7, value: currentYear - 7, title: '7 years ago', labelKey: 'roofUpdatedYear.sevenYearsAgo' },
	{ id: 8, value: currentYear - 8, title: '8 years ago', labelKey: 'roofUpdatedYear.eightYearsAgo' },
	{ id: 9, value: currentYear - 9, title: '9 years ago', labelKey: 'roofUpdatedYear.nineYearsAgo' },
	{ id: 10, value: currentYear - 10, title: '10 years ago', labelKey: 'roofUpdatedYear.tenYearsAgo' },
	{ id: 11, value: currentYear - 11, title: '11 years ago', labelKey: 'roofUpdatedYear.elevenYearsAgo' },
	{ id: 12, value: currentYear - 12, title: '12 years ago', labelKey: 'roofUpdatedYear.twelveYearsAgo' },
	{ id: 13, value: currentYear - 13, title: '13 years ago', labelKey: 'roofUpdatedYear.thirteenYearsAgo' },
	{ id: 14, value: currentYear - 14, title: '14 years ago', labelKey: 'roofUpdatedYear.fourteenYearsAgo' },
	{ id: 15, value: currentYear - 15, title: '15+ years ago', labelKey: 'roofUpdatedYear.fifteenPlusYearsAgo' },
];

export const tankOilLocationOptions: IDropdownOption[] = [
	{ id: 1, value: 'BelowGround', title: 'Below ground', labelKey: 'tankOilLocationOptions.belowGround' },
	{ id: 2, value: 'AboveGround', title: 'Above ground', labelKey: 'tankOilLocationOptions.aboveGround' },
	{ id: 3, value: 'InsideOnCement', title: 'Inside on cement', labelKey: 'tankOilLocationOptions.insideOnCement' },
	{
		id: 4,
		value: 'InsideNotOnCement',
		title: 'Inside not on cement',
		labelKey: 'tankOilLocationOptions.insideNotOnCement',
	},
	{
		id: 5,
		value: 'OutsideNotOnCement',
		title: 'Outside not on cement',
		labelKey: 'tankOilLocationOptions.outsideNotOnCement',
	},
	{ id: 6, value: 'OutsideOnCement', title: 'Outside on cement', labelKey: 'tankOilLocationOptions.outsideOnCement' },
	{
		id: 7,
		value: 'BuriedUderground',
		title: 'Buried underground',
		labelKey: 'tankOilLocationOptions.buriedUnderground',
	},
	{
		id: 8,
		value: 'ProfessionallyRemediated',
		title: 'Professionally Remediated',
		labelKey: 'tankOilLocationOptions.professionallyRemediated',
	},
	{
		id: 9,
		value: 'ProfessionallyRemoved',
		title: 'Professionally Removed',
		labelKey: 'tankOilLocationOptions.professionallyRemoved',
	},
];

export const FQLicenseClassOptions: IDropdownOption[] = [
	{ id: 1, value: 'OperatorPersonalAuto', title: 'Personal', labelKey: 'fQLicenseClassOptions.personal' },
	{
		id: 2,
		value: 'CommercialVehicleBusiness',
		title: 'Commercial (non-chauffeur)',
		labelKey: 'fQLicenseClassOptions.commercialNonChauffeur',
	},
	{ id: 3, value: 'Chauffeur', title: 'Chauffeur', labelKey: 'fQLicenseClassOptions.chauffeur' },
	{ id: 4, value: 'Permit', title: 'Permit', labelKey: 'fQLicenseClassOptions.permit' },
	{ id: 5, value: 'NotLicensed', title: 'Not Licensed/State ID', labelKey: 'fQLicenseClassOptions.notLicensedStateID' },
];

export const PriorCarrierHomeownersOptions: IDropdownOption[] = [
	{ value: 'TwentyFirstCentury', title: '21st Century', id: 10, labelKey: 'carrierHomeowners.twentyfirstcentury' },
	{ value: 'AAA', title: 'AAA', id: 20, labelKey: 'carrierHomeowners.aaa' },
	{
		value: 'AcaHomeInsuranceCorp',
		title: 'Aca Home Insurance Corp',
		id: 30,
		labelKey: 'carrierHomeowners.acahomeinsurancecorp',
	},
	{
		value: 'AceInsuranceCompanyOfTheMidwest',
		title: 'Ace Insurance Company Of The Midwest',
		id: 40,
		labelKey: 'carrierHomeowners.aceinsurancecompanyofthemidwest',
	},
	{ value: 'Acuity', title: 'Acuity', id: 50, labelKey: 'carrierHomeowners.acuity' },
	{
		value: 'AddisonInsuranceCompany',
		title: 'Addison Insurance Company',
		id: 60,
		labelKey: 'carrierHomeowners.addisoninsurancecompany',
	},
	{
		value: 'AdirondackInsExchange',
		title: 'Adirondack Ins Exchange',
		id: 70,
		labelKey: 'carrierHomeowners.adirondackinsexchange',
	},
	{
		value: 'AffiliatedFmInsuranceCompany',
		title: 'Affiliated Fm Insurance Company',
		id: 80,
		labelKey: 'carrierHomeowners.affiliatedfminsurancecompany',
	},
	{ value: 'AIG', title: 'AIG', id: 90, labelKey: 'carrierHomeowners.aig' },
	{ value: 'AlfaAlliance', title: 'Alfa Alliance', id: 100, labelKey: 'carrierHomeowners.alfaalliance' },
	{ value: 'AllRisks', title: 'All Risks', id: 105, labelKey: 'carrierHomeowners.allrisks' },
	{ value: 'AllianzOfAmerica', title: 'Allianz of America', id: 110, labelKey: 'carrierHomeowners.allianzofamerica' },
	{
		value: 'AllianzOfAmericaJefferson',
		title: 'Allianz of America-Jefferson',
		id: 120,
		labelKey: 'carrierHomeowners.allianzofamericajefferson',
	},
	{ value: 'Allied', title: 'Allied', id: 130, labelKey: 'carrierHomeowners.allied' },
	{ value: 'Allmerica', title: 'Allmerica', id: 140, labelKey: 'carrierHomeowners.allmerica' },
	{ value: 'Allstate', title: 'Allstate', id: 150, labelKey: 'carrierHomeowners.allstate' },
	{
		value: 'AllstateInsuranceCompany',
		title: 'Allstate Insurance Company',
		id: 160,
		labelKey: 'carrierHomeowners.allstateinsurancecompany',
	},
	{ value: 'AmericanFamily', title: 'American Family', id: 165, labelKey: 'carrierHomeowners.americanfamily' },
	{ value: 'AmericaFirst', title: 'America First', id: 170, labelKey: 'carrierHomeowners.americafirst' },
	{
		value: 'AmericanAutomobileInsuranceCompany',
		title: 'American Automobile Insurance Company',
		id: 180,
		labelKey: 'carrierHomeowners.americanautomobileinsurancecompany',
	},
	{
		value: 'AmericanBankersInsuranceCompanyOfFlorida',
		title: 'American Bankers Insurance Company Of Florida',
		id: 190,
		labelKey: 'carrierHomeowners.americanbankersinsurancecompanyofflorida',
	},
	{
		value: 'AmericanCapitalAssuranceCorp',
		title: 'American Capital Assurance Corp',
		id: 200,
		labelKey: 'carrierHomeowners.americancapitalassurancecorp',
	},
	{
		value: 'AmericanCasualtyCompanyOfReadingPennsylvania',
		title: 'American Casualty Company Of Reading, Pennsylvania',
		id: 210,
		labelKey: 'carrierHomeowners.americancasualtycompanyofreadingpennsylvania',
	},
	{ value: 'AmericanCommerce', title: 'American Commerce', id: 220, labelKey: 'carrierHomeowners.americancommerce' },
	{
		value: 'AmericanHomeAssuranceCompany',
		title: 'American Home Assurance Company',
		id: 230,
		labelKey: 'carrierHomeowners.americanhomeassurancecompany',
	},
	{
		value: 'AmericanIntegrityInsuranceCompanyOfFlorida',
		title: 'American Integrity Insurance Company Of Florida',
		id: 240,
		labelKey: 'carrierHomeowners.americanintegrityinsurancecompanyofflorida',
	},
	{
		value: 'AmericanMercuryInsuranceCompany',
		title: 'American Mercury Insurance Company',
		id: 250,
		labelKey: 'carrierHomeowners.americanmercuryinsurancecompany',
	},
	{
		value: 'AmericanModernInsuranceCompanyOfFloridaInc',
		title: 'American Modern Insurance Company Of Florida, Inc',
		id: 260,
		labelKey: 'carrierHomeowners.americanmoderninsurancecompanyoffloridainc',
	},
	{
		value: 'AmericanPlatinumPropertyAndCasualtyInsuranceCompany',
		title: 'American Platinum Property And Casualty Insurance Company',
		id: 270,
		labelKey: 'carrierHomeowners.americanplatinumpropertyandcasualtyinsurancecompany',
	},
	{
		value: 'AmericanReliableInsuranceCompany',
		title: 'American Reliable Insurance Company',
		id: 280,
		labelKey: 'carrierHomeowners.americanreliableinsurancecompany',
	},
	{
		value: 'AmericanSecurityInsuranceCompany',
		title: 'American Security Insurance Company',
		id: 290,
		labelKey: 'carrierHomeowners.americansecurityinsurancecompany',
	},
	{
		value: 'AmericanSouthernHomeInsuranceCompany',
		title: 'American Southern Home Insurance Company',
		id: 300,
		labelKey: 'carrierHomeowners.americansouthernhomeinsurancecompany',
	},
	{
		value: 'AmericanStatesInsuranceCompany',
		title: 'American States Insurance Company',
		id: 310,
		labelKey: 'carrierHomeowners.americanstatesinsurancecompany',
	},
	{
		value: 'AmericanStrategicInsuranceCorp',
		title: 'American Strategic Insurance Corp',
		id: 320,
		labelKey: 'carrierHomeowners.americanstrategicinsurancecorp',
	},
	{
		value: 'AmericanTraditionsInsuranceCompany',
		title: 'American Traditions Insurance Company',
		id: 330,
		labelKey: 'carrierHomeowners.americantraditionsinsurancecompany',
	},
	{ value: 'Amica', title: 'Amica', id: 340, labelKey: 'carrierHomeowners.amica' },
	{
		value: 'AmicaMutualInsuranceCompany',
		title: 'Amica Mutual Insurance Company',
		id: 350,
		labelKey: 'carrierHomeowners.amicamutualinsurancecompany',
	},
	{
		value: 'ArkRoyalInsuranceCompany',
		title: 'Ark Royal Insurance Company',
		id: 360,
		labelKey: 'carrierHomeowners.arkroyalinsurancecompany',
	},
	{
		value: 'ArmedForcesInsuranceExchange',
		title: 'Armed Forces Insurance Exchange',
		id: 370,
		labelKey: 'carrierHomeowners.armedforcesinsuranceexchange',
	},
	{ value: 'AsiAssuranceCorp', title: 'Asi Assurance Corp', id: 380, labelKey: 'carrierHomeowners.asiassurancecorp' },
	{ value: 'ASILloyds', title: 'ASI Lloyds', id: 390, labelKey: 'carrierHomeowners.asilloyds' },
	{
		value: 'AsiPreferredInsuranceCorp',
		title: 'Asi Preferred Insurance Corp',
		id: 400,
		labelKey: 'carrierHomeowners.asipreferredinsurancecorp',
	},
	{
		value: 'AssociatedIndemnityCorporation',
		title: 'Associated Indemnity Corporation',
		id: 410,
		labelKey: 'carrierHomeowners.associatedindemnitycorporation',
	},
	{ value: 'Assurant', title: 'Assurant', id: 415, labelKey: 'carrierHomeowners.assurant' },
	{
		value: 'AtlasGeneralAgency',
		title: 'Atlas General Agency',
		id: 420,
		labelKey: 'carrierHomeowners.atlasgeneralagency',
	},
	{ value: 'AustinMutual', title: 'Austin Mutual', id: 430, labelKey: 'carrierHomeowners.austinmutual' },
	{
		value: 'AutoClubInsuranceCompanyOfFlorida',
		title: 'Auto Club Insurance Company Of Florida',
		id: 440,
		labelKey: 'carrierHomeowners.autoclubinsurancecompanyofflorida',
	},
	{ value: 'AutoOwners', title: 'Auto-Owners', id: 450, labelKey: 'carrierHomeowners.autoowners' },
	{
		value: 'AutoOwnersInsuranceCompany',
		title: 'Auto-owners Insurance Company',
		id: 460,
		labelKey: 'carrierHomeowners.autoownersinsurancecompany',
	},
	{
		value: 'AvatarPropertyCasualtyInsuranceCompany',
		title: 'Avatar Property And Casualty Insurance Company',
		id: 470,
		labelKey: 'carrierHomeowners.avatarpropertycasualtyinsurancecompany',
	},
	{ value: 'BadgerMutual', title: 'Badger Mutual', id: 480, labelKey: 'carrierHomeowners.badgermutual' },
	{ value: 'Balboa', title: 'Balboa', id: 490, labelKey: 'carrierHomeowners.balboa' },
	{
		value: 'BalboaInsuranceCompany',
		title: 'Balboa Insurance Company',
		id: 500,
		labelKey: 'carrierHomeowners.balboainsurancecompany',
	},
	{
		value: 'BankersInsuranceCompany',
		title: 'Bankers Insurance Company',
		id: 510,
		labelKey: 'carrierHomeowners.bankersinsurancecompany',
	},
	{ value: 'BeaconNational', title: 'Beacon National', id: 520, labelKey: 'carrierHomeowners.beaconnational' },
	{ value: 'BunkerHill', title: 'Bunker Hill', id: 530, labelKey: 'carrierHomeowners.bunkerhill' },
	{
		value: 'CapitalInsuranceGroup',
		title: 'Capital Insurance Group',
		id: 540,
		labelKey: 'carrierHomeowners.capitalinsurancegroup',
	},
	{
		value: 'CapitolPreferredInsuranceCompanyInc',
		title: 'Capitol Preferred Insurance Company, Inc',
		id: 550,
		labelKey: 'carrierHomeowners.capitolpreferredinsurancecompanyinc',
	},
	{
		value: 'CastleKeyInsuranceCompany',
		title: 'Castle Key Insurance Company',
		id: 560,
		labelKey: 'carrierHomeowners.castlekeyinsurancecompany',
	},
	{
		value: 'CentralMutualOfOH',
		title: 'Central Mutual of OH',
		id: 570,
		labelKey: 'carrierHomeowners.centralmutualofoh',
	},
	{
		value: 'CenturyNationalInsuranceCompany',
		title: 'Century National Insurance Company',
		id: 580,
		labelKey: 'carrierHomeowners.centurynationalinsurancecompany',
	},
	{
		value: 'CharterOakFireInsuranceCompany',
		title: 'Charter Oak Fire Insurance Company',
		id: 590,
		labelKey: 'carrierHomeowners.charteroakfireinsurancecompany',
	},
	{
		value: 'ChartisPropertyCasualtyCompany',
		title: 'Chartis Property Casualty Company',
		id: 600,
		labelKey: 'carrierHomeowners.chartispropertycasualtycompany',
	},
	{ value: 'ChubbInsurance', title: 'Chubb', id: 620, labelKey: 'carrierHomeowners.chubbinsurance' },
	{
		value: 'ChurchMutualInsuranceCompany',
		title: 'Church Mutual Insurance Company',
		id: 630,
		labelKey: 'carrierHomeowners.churchmutualinsurancecompany',
	},
	{ value: 'Cincinnati', title: 'Cincinnati', id: 640, labelKey: 'carrierHomeowners.cincinnati' },
	{
		value: 'CincinnatiInsuranceCompany',
		title: 'Cincinnati Insurance Company',
		id: 650,
		labelKey: 'carrierHomeowners.cincinnatiinsurancecompany',
	},
	{ value: 'Citizens', title: 'Citizens', id: 660, labelKey: 'carrierHomeowners.citizens' },
	{
		value: 'CitizensPropertyInsuranceCorporation',
		title: 'Citizens Property Insurance Corporation',
		id: 670,
		labelKey: 'carrierHomeowners.citizenspropertyinsurancecorporation',
	},
	{ value: 'ColoradoCasualty', title: 'Colorado Casualty', id: 680, labelKey: 'carrierHomeowners.coloradocasualty' },
	{
		value: 'CompanionPropertyAndCasualtyInsuranceCompany',
		title: 'Companion Property And Casualty Insurance Company',
		id: 690,
		labelKey: 'carrierHomeowners.companionpropertyandcasualtyinsurancecompany',
	},
	{ value: 'Ameriprise', title: 'CONNECT', id: 695, labelKey: 'carrierHomeowners.ameriprise' },
	{
		value: 'CooperativaDeSegurosMultiplesDePuertoRicoInc',
		title: 'Cooperativa De Seguros Multiples De Puerto Rico, Inc',
		id: 700,
		labelKey: 'carrierHomeowners.cooperativadesegurosmultiplesdepuertoricoinc',
	},
	{
		value: 'CottonStatesMutualInsuranceCompany',
		title: 'Cotton States Mutual Insurance Company',
		id: 710,
		labelKey: 'carrierHomeowners.cottonstatesmutualinsurancecompany',
	},
	{ value: 'CSE', title: 'CSE', id: 720, labelKey: 'carrierHomeowners.cse' },
	{ value: 'Cumberland', title: 'Cumberland', id: 730, labelKey: 'carrierHomeowners.cumberland' },
	{ value: 'Cypress', title: 'Cypress', id: 740, labelKey: 'carrierHomeowners.cypress' },
	{
		value: 'CypressPropertyCasualtyInsuranceCompany',
		title: 'Cypress Property And Casualty Insurance Company',
		id: 750,
		labelKey: 'carrierHomeowners.cypresspropertycasualtyinsurancecompany',
	},
	{ value: 'Dairyland', title: 'Dairyland', id: 760, labelKey: 'carrierHomeowners.dairyland' },
	{ value: 'Donegal', title: 'Donegal', id: 770, labelKey: 'carrierHomeowners.donegal' },
	{ value: 'Electric', title: 'Electric', id: 780, labelKey: 'carrierHomeowners.electric' },
	{
		value: 'ElectricInsuranceCompany',
		title: 'Electric Insurance Company',
		id: 790,
		labelKey: 'carrierHomeowners.electricinsurancecompany',
	},
	{ value: 'EMC', title: 'EMC', id: 800, labelKey: 'carrierHomeowners.emc' },
	{ value: 'Encompass', title: 'Encompass', id: 810, labelKey: 'carrierHomeowners.encompass' },
	{
		value: 'EncompassFloridianInsuranceCompany',
		title: 'Encompass Floridian Insurance Company',
		id: 820,
		labelKey: 'carrierHomeowners.encompassfloridianinsurancecompany',
	},
	{ value: 'Erie', title: 'Erie', id: 830, labelKey: 'carrierHomeowners.erie' },
	{ value: 'Esurance', title: 'Esurance', id: 840, labelKey: 'carrierHomeowners.esurance' },
	{
		value: 'ExcelsiorInsuranceCompany',
		title: 'Excelsior Insurance Company',
		id: 850,
		labelKey: 'carrierHomeowners.excelsiorinsurancecompany',
	},
	{ value: 'FairPlan', title: 'Fair Plan', id: 860, labelKey: 'carrierHomeowners.fairplan' },
	{ value: 'FarmBurea', title: 'Farm Burea', id: 870, labelKey: 'carrierHomeowners.farmbureau' },
	{ value: 'Farmers', title: 'Farmers', id: 880, labelKey: 'carrierHomeowners.farmers' },
	{
		value: 'FederalInsuranceCompany',
		title: 'Federal Insurance Company',
		id: 890,
		labelKey: 'carrierHomeowners.federalinsurancecompany',
	},
	{
		value: 'FederatedNationalInsuranceCompany',
		title: 'Federated National Insurance Company',
		id: 900,
		labelKey: 'carrierHomeowners.federatednationalinsurancecompany',
	},
	{
		value: 'FidelityFireCasualtyCompany',
		title: 'Fidelity Fire And Casualty Company',
		id: 910,
		labelKey: 'carrierHomeowners.fidelityfirecasualtycompany',
	},
	{
		value: 'FidelityNationalPropertyAndCasualtyInsuranceCompany',
		title: 'Fidelity National Property And Casualty Insurance Company',
		id: 920,
		labelKey: 'carrierHomeowners.fidelitynationalpropertyandcasualtyinsurancecompany',
	},
	{ value: 'FiremansFund', title: 'Firemans Fund', id: 930, labelKey: 'carrierHomeowners.firemansfund' },
	{
		value: 'FiremanFundInsuranceCompany',
		title: "Fireman's Fund Insurance Company",
		id: 940,
		labelKey: 'carrierHomeowners.firemanfundinsurancecompany',
	},
	{ value: 'FirstAmerican', title: 'First American', id: 950, labelKey: 'carrierHomeowners.firstamerican' },
	{
		value: 'FirstAmericanPropertyCasualtyInsuranceCompany',
		title: 'First American Property And Casualty Insurance Company',
		id: 960,
		labelKey: 'carrierHomeowners.firstamericanpropertycasualtyinsurancecompany',
	},
	{
		value: 'FirstCommunityInsuranceCompany',
		title: 'First Community Insurance Company',
		id: 970,
		labelKey: 'carrierHomeowners.firstcommunityinsurancecompany',
	},
	{
		value: 'FirstFloridianAutoAndHomeInsuranceCompany',
		title: 'First Floridian Auto And Home Insurance Company',
		id: 980,
		labelKey: 'carrierHomeowners.firstfloridianautoandhomeinsurancecompany',
	},
	{
		value: 'FirstHomeInsuranceCompany',
		title: 'First Home Insurance Company',
		id: 990,
		labelKey: 'carrierHomeowners.firsthomeinsurancecompany',
	},
	{
		value: 'FirstLibertyInsuranceCorporation',
		title: 'First Liberty Insurance Corporation (the)',
		id: 1000,
		labelKey: 'carrierHomeowners.firstlibertyinsurancecorporation',
	},
	{
		value: 'FirstNationalInsuranceCompanyOfAmerica',
		title: 'First National Insurance Company Of America',
		id: 1010,
		labelKey: 'carrierHomeowners.firstnationalinsurancecompanyofamerica',
	},
	{
		value: 'FirstProtectiveInsuranceCompany',
		title: 'First Protective Insurance Company',
		id: 1020,
		labelKey: 'carrierHomeowners.firstprotectiveinsurancecompany',
	},
	{
		value: 'FloridaFamilyInsuranceCompany',
		title: 'Florida Family Insurance Company',
		id: 1030,
		labelKey: 'carrierHomeowners.floridafamilyinsurancecompany',
	},
	{
		value: 'FloridaFarmBureauCasualtyInsuranceCompany',
		title: 'Florida Farm Bureau Casualty Insurance Company',
		id: 1040,
		labelKey: 'carrierHomeowners.floridafarmbureaucasualtyinsurancecompany',
	},
	{
		value: 'FloridaPeninsulaInsuranceCompany',
		title: 'Florida Peninsula Insurance Company',
		id: 1050,
		labelKey: 'carrierHomeowners.floridapeninsulainsurancecompany',
	},
	{
		value: 'ForemostInsuranceCompany',
		title: 'Foremost Insurance Company',
		id: 1060,
		labelKey: 'carrierHomeowners.foremostinsurancecompany',
	},
	{ value: 'Geico', title: 'GEICO', id: 1070, labelKey: 'carrierHomeowners.geico' },
	{ value: 'GeneralCasualty', title: 'General Casualty', id: 1070, labelKey: 'carrierHomeowners.generalcasualty' },
	{ value: 'GeoVera', title: 'Geo Vera', id: 1080, labelKey: 'carrierHomeowners.geovera' },
	{ value: 'GermantownMutual', title: 'Germantown Mutual', id: 1090, labelKey: 'carrierHomeowners.germantownmutual' },
	{ value: 'GMAC', title: 'GMAC', id: 1100, labelKey: 'carrierHomeowners.gmac' },
	{ value: 'Grange', title: 'Grange', id: 1110, labelKey: 'carrierHomeowners.grange' },
	{
		value: 'GreatNorthernInsuranceCompany',
		title: 'Great Northern Insurance Company',
		id: 1120,
		labelKey: 'carrierHomeowners.greatnortherninsurancecompany',
	},
	{ value: 'Grinnell', title: 'Grinnell', id: 1130, labelKey: 'carrierHomeowners.grinnell' },
	{ value: 'GuideOne', title: 'Guide One', id: 1140, labelKey: 'carrierHomeowners.guideone' },
	{
		value: 'GuideoneEliteInsuranceCompany',
		title: 'Guideone Elite Insurance Company',
		id: 1150,
		labelKey: 'carrierHomeowners.guideoneeliteinsurancecompany',
	},
	{
		value: 'GulfstreamPropertyAndCasualtyInsuranceCompany',
		title: 'Gulfstream Property And Casualty Insurance Company',
		id: 1160,
		labelKey: 'carrierHomeowners.gulfstreampropertyandcasualtyinsurancecompany',
	},
	{ value: 'Hanover', title: 'Hanover', id: 1170, labelKey: 'carrierHomeowners.hanover' },
	{
		value: 'HanoverAmericanInsuranceCompany',
		title: 'Hanover American Insurance Company (the)',
		id: 1180,
		labelKey: 'carrierHomeowners.hanoveramericaninsurancecompany',
	},
	{ value: 'Harleysville', title: 'Harleysville', id: 1190, labelKey: 'carrierHomeowners.harleysville' },
	{ value: 'Hartford', title: 'Hartford', id: 1200, labelKey: 'carrierHomeowners.hartford' },
	{
		value: 'HartfordFireInsuranceCompany',
		title: 'Hartford Fire Insurance Company',
		id: 1210,
		labelKey: 'carrierHomeowners.hartfordfireinsurancecompany',
	},
	{ value: 'HartfordOMNI', title: 'Hartford OMNI', id: 1220, labelKey: 'carrierHomeowners.hartfordomni' },
	{ value: 'HawkeyeSecurity', title: 'Hawkeye Security', id: 1230, labelKey: 'carrierHomeowners.hawkeyesecurity' },
	{
		value: 'HeritagePropertyCasualty',
		title: 'Heritage Property And Casualty',
		id: 1240,
		labelKey: 'carrierHomeowners.heritagepropertycasualty',
	},
	{
		value: 'HomeownersChoicePropertyCasualtyInsuranceCompanyInc',
		title: 'Homeowners Choice Property And Casualty Insurance Company, Inc',
		id: 1250,
		labelKey: 'carrierHomeowners.homeownerschoicepropertycasualtyinsurancecompanyinc',
	},
	{
		value: 'HomeownersOfAmerica',
		title: 'Homeowners of America',
		id: 1260,
		labelKey: 'carrierHomeowners.homeownersofamerica',
	},
	{ value: 'Homesite', title: 'Homesite', id: 1270, labelKey: 'carrierHomeowners.homesite' },
	{ value: 'HoraceMann', title: 'Horace Mann', id: 1280, labelKey: 'carrierHomeowners.horacemann' },
	{
		value: 'HoraceMannInsuranceCompany',
		title: 'Horace Mann Insurance Company',
		id: 1290,
		labelKey: 'carrierHomeowners.horacemanninsurancecompany',
	},
	{ value: 'HoustonGeneral', title: 'Houston General', id: 1300, labelKey: 'carrierHomeowners.houstongeneral' },
	{
		value: 'IdsPropertyCasualtyInsuranceCompany',
		title: 'Ids Property Casualty Insurance Company',
		id: 1310,
		labelKey: 'carrierHomeowners.idspropertycasualtyinsurancecompany',
	},
	{ value: 'Indiana', title: 'Indiana', id: 1320, labelKey: 'carrierHomeowners.indiana' },
	{ value: 'IndianaFarmers', title: 'Indiana Farmers', id: 1330, labelKey: 'carrierHomeowners.indianafarmers' },
	{ value: 'Integrity', title: 'Integrity', id: 1340, labelKey: 'carrierHomeowners.integrity' },
	{ value: 'Kemper', title: 'Kemper', id: 1350, labelKey: 'carrierHomeowners.kemper' },
	{ value: 'Lexington', title: 'Lexington', id: 1360, labelKey: 'carrierHomeowners.lexington' },
	{ value: 'LibertyMutual', title: 'Liberty Mutual', id: 1370, labelKey: 'carrierHomeowners.libertymutual' },
	{
		value: 'LibertyMutualFireInsuranceCompany',
		title: 'Liberty Mutual Fire Insurance Company',
		id: 1380,
		labelKey: 'carrierHomeowners.libertymutualfireinsurancecompany',
	},
	{ value: 'LibertyNorthwest', title: 'Liberty Northwest', id: 1390, labelKey: 'carrierHomeowners.libertynorthwest' },
	{ value: 'Lloyds', title: 'Lloyds', id: 1400, labelKey: 'carrierHomeowners.lloyds' },
	{ value: 'LloydsOfLondon', title: 'Lloyds Of London', id: 1410, labelKey: 'carrierHomeowners.lloydsoflondon' },
	{
		value: 'MainStreetAmerica',
		title: 'Main Street America',
		id: 1420,
		labelKey: 'carrierHomeowners.mainstreetamerica',
	},
	{ value: 'Mapfre', title: 'MAPFRE', id: 1425, labelKey: 'carrierHomeowners.mapfre' },
	{
		value: 'MarkelInsuranceCompany',
		title: 'Markel Insurance Company',
		id: 1430,
		labelKey: 'carrierHomeowners.markelinsurancecompany',
	},
	{ value: 'MerchantsGroup', title: 'Merchants Group', id: 1440, labelKey: 'carrierHomeowners.merchantsgroup' },
	{ value: 'Mercury', title: 'Mercury', id: 1450, labelKey: 'carrierHomeowners.mercury' },
	{ value: 'Metlife', title: 'MetLife', id: 1460, labelKey: 'carrierHomeowners.metlife' },
	{
		value: 'MetropolitanCasualtyInsuranceCompany',
		title: 'Metropolitan Casualty Insurance Company',
		id: 1470,
		labelKey: 'carrierHomeowners.metropolitancasualtyinsurancecompany',
	},
	{
		value: 'MidwesternIndemnity',
		title: 'Midwestern Indemnity',
		id: 1480,
		labelKey: 'carrierHomeowners.midwesternindemnity',
	},
	{
		value: 'ModernUsaInsuranceCompany',
		title: 'Modern Usa Insurance Company',
		id: 1490,
		labelKey: 'carrierHomeowners.modernusainsurancecompany',
	},
	{ value: 'Montgomery', title: 'Montgomery', id: 1500, labelKey: 'carrierHomeowners.montgomery' },
	{ value: 'MSA', title: 'MSA', id: 1510, labelKey: 'carrierHomeowners.msa' },
	{ value: 'MutualBenefit', title: 'Mutual Benefit', id: 1520, labelKey: 'carrierHomeowners.mutualbenefit' },
	{ value: 'Tower', title: 'National General', id: 1530, labelKey: 'carrierHomeowners.tower' },
	{ value: 'Nationwide', title: 'Nationwide', id: 1540, labelKey: 'carrierHomeowners.nationwide' },
	{
		value: 'NationwideInsuranceCompanyOfFlorida',
		title: 'Nationwide Insurance Company Of Florida',
		id: 1550,
		labelKey: 'carrierHomeowners.nationwideinsurancecompanyofflorida',
	},
	{
		value: 'NationwideScottsdale',
		title: 'Nationwide-Scottsdale',
		id: 1560,
		labelKey: 'carrierHomeowners.nationwidescottsdale',
	},
	{
		value: 'NewHampshireInsuranceCompany',
		title: 'New Hampshire Insurance Company',
		id: 1570,
		labelKey: 'carrierHomeowners.newhampshireinsurancecompany',
	},
	{
		value: 'NewYorkCentralMutual',
		title: 'New York Central Mutual',
		id: 1580,
		labelKey: 'carrierHomeowners.newyorkcentralmutual',
	},
	{ value: 'NJSkylands', title: 'NJ Skylands', id: 1590, labelKey: 'carrierHomeowners.njskylands' },
	{ value: 'Northstar', title: 'Northstar', id: 1650, labelKey: 'carrierHomeowners.northstar' },
	{
		value: 'NovaCasualtyCompany',
		title: 'Nova Casualty Company',
		id: 1660,
		labelKey: 'carrierHomeowners.novacasualtycompany',
	},
	{ value: 'OhioCasualty', title: 'Ohio Casualty', id: 1670, labelKey: 'carrierHomeowners.ohiocasualty' },
	{
		value: 'OhioCasualtyInsuranceCompany',
		title: 'Ohio Casualty Insurance Company',
		id: 1680,
		labelKey: 'carrierHomeowners.ohiocasualtyinsurancecompany',
	},
	{
		value: 'OldDominionInsuranceCompany',
		title: 'Old Dominion Insurance Company',
		id: 1690,
		labelKey: 'carrierHomeowners.olddominioninsurancecompany',
	},
	{
		value: 'OlympusInsuranceCompany',
		title: 'Olympus Insurance Company',
		id: 1700,
		labelKey: 'carrierHomeowners.olympusinsurancecompany',
	},
	{
		value: 'OmegaInsuranceCompany',
		title: 'Omega Insurance Company',
		id: 1710,
		labelKey: 'carrierHomeowners.omegainsurancecompany',
	},
	{ value: 'OneBeacon', title: 'One Beacon', id: 1720, labelKey: 'carrierHomeowners.onebeacon' },
	{ value: 'OregonMutual', title: 'Oregon Mutual', id: 1730, labelKey: 'carrierHomeowners.oregonmutual' },
	{
		value: 'PeerlessMontgomery',
		title: 'Peerless/Montgomery',
		id: 1770,
		labelKey: 'carrierHomeowners.peerlessmontgomery',
	},
	{ value: 'Pekin', title: 'Pekin', id: 1780, labelKey: 'carrierHomeowners.pekin' },
	{ value: 'PennNational', title: 'Penn National', id: 1790, labelKey: 'carrierHomeowners.pennnational' },
	{
		value: 'PeopleTrustInsuranceCompany',
		title: "People's Trust Insurance Company",
		id: 1800,
		labelKey: 'carrierHomeowners.peopletrustinsurancecompany',
	},
	{ value: 'PlymouthRock', title: 'Plymouth Rock', id: 1810, labelKey: 'carrierHomeowners.plymouthrock' },
	{ value: 'PreferredMutual', title: 'Preferred Mutual', id: 1820, labelKey: 'carrierHomeowners.preferredmutual' },
	{
		value: 'PreparedInsuranceCompany',
		title: 'Prepared Insurance Company',
		id: 1830,
		labelKey: 'carrierHomeowners.preparedinsurancecompany',
	},
	{
		value: 'PrivilegeUnderwritersReciprocalExchange',
		title: 'Privilege Underwriters Reciprocal Exchange',
		id: 1840,
		labelKey: 'carrierHomeowners.privilegeunderwritersreciprocalexchange',
	},
	{
		value: 'ProtectionAndIndemnity',
		title: 'Protection And Indemnity',
		id: 1850,
		labelKey: 'carrierHomeowners.protectionandindemnity',
	},
	{ value: 'Prudential', title: 'Prudential', id: 1860, labelKey: 'carrierHomeowners.prudential' },
	{ value: 'QBE', title: 'QBE', id: 1865, labelKey: 'carrierHomeowners.qbe' },
	{
		value: 'RanchersAndFarmersInsuranceCompany',
		title: 'Ranchers And Farmers Insurance Company',
		id: 1870,
		labelKey: 'carrierHomeowners.ranchersandfarmersinsurancecompany',
	},
	{ value: 'Republic', title: 'Republic', id: 1880, labelKey: 'carrierHomeowners.republic' },
	{
		value: 'RoyalAndSunAlliance',
		title: 'Royal and Sun Alliance',
		id: 1890,
		labelKey: 'carrierHomeowners.royalandsunalliance',
	},
	{
		value: 'SafeHarborInsuranceCompany',
		title: 'Safe Harbor Insurance Company',
		id: 1900,
		labelKey: 'carrierHomeowners.safeharborinsurancecompany',
	},
	{ value: 'Safeco', title: 'Safeco', id: 1910, labelKey: 'carrierHomeowners.safeco' },
	{
		value: 'SafecoInsuranceCompanyOfAmerica',
		title: 'Safeco Insurance Company Of America',
		id: 1920,
		labelKey: 'carrierHomeowners.safecoinsurancecompanyofamerica',
	},
	{
		value: 'SafewayPropertyInsuranceCompany',
		title: 'Safeway Property Insurance Company',
		id: 1930,
		labelKey: 'carrierHomeowners.safewaypropertyinsurancecompany',
	},
	{ value: 'SageSure', title: 'Sage Sure', id: 1935, labelKey: 'carrierHomeowners.sagesure' },
	{
		value: 'SawgrassMutualInsuranceCompany',
		title: 'Sawgrass Mutual Insurance Company',
		id: 1940,
		labelKey: 'carrierHomeowners.sawgrassmutualinsurancecompany',
	},
	{ value: 'SECURA', title: 'SECURA', id: 1950, labelKey: 'carrierHomeowners.secura' },
	{
		value: 'SecurityFirstInsuranceCompany',
		title: 'Security First Insurance Company',
		id: 1960,
		labelKey: 'carrierHomeowners.securityfirstinsurancecompany',
	},
	{
		value: 'ServiceInsuranceCompany',
		title: 'Service Insurance Company',
		id: 1970,
		labelKey: 'carrierHomeowners.serviceinsurancecompany',
	},
	{
		value: 'ShieldInsuranceCompany',
		title: 'Shield Insurance Company',
		id: 1980,
		labelKey: 'carrierHomeowners.shieldinsurancecompany',
	},
	{
		value: 'SouthernFidelityInsuranceCompany',
		title: 'Southern Fidelity Insurance Company',
		id: 1990,
		labelKey: 'carrierHomeowners.southernfidelityinsurancecompany',
	},
	{
		value: 'SouthernFidelityPropertyCasualtyInc',
		title: 'Southern Fidelity Property And Casualty, Inc',
		id: 2000,
		labelKey: 'carrierHomeowners.southernfidelitypropertycasualtyinc',
	},
	{ value: 'SouthernMutual', title: 'Southern Mutual', id: 2010, labelKey: 'carrierHomeowners.southernmutual' },
	{
		value: 'SouthernOakInsuranceCompany',
		title: 'Southern Oak Insurance Company',
		id: 2020,
		labelKey: 'carrierHomeowners.southernoakinsurancecompany',
	},
	{ value: 'SouthernTrust', title: 'Southern Trust', id: 2030, labelKey: 'carrierHomeowners.southerntrust' },
	{
		value: 'SouthernOwnersInsuranceCompany',
		title: 'Southern-owners Insurance Company',
		id: 2040,
		labelKey: 'carrierHomeowners.southernownersinsurancecompany',
	},
	{
		value: 'StJohnsInsuranceCompanyInc',
		title: 'St Johns Insurance Company, Inc',
		id: 2050,
		labelKey: 'carrierHomeowners.stjohnsinsurancecompanyinc',
	},
	{ value: 'StPaulTravelers', title: 'St. Paul/Travelers', id: 2060, labelKey: 'carrierHomeowners.stpaultravelers' },
	{ value: 'StandardMutual', title: 'Standard Mutual', id: 2070, labelKey: 'carrierHomeowners.standardmutual' },
	{
		value: 'StarShieldInsuranceExchange',
		title: 'Star And Shield Insurance Exchange',
		id: 2080,
		labelKey: 'carrierHomeowners.starshieldinsuranceexchange',
	},
	{ value: 'StateAuto', title: 'State Auto', id: 2090, labelKey: 'carrierHomeowners.stateauto' },
	{ value: 'StateFarm', title: 'State Farm', id: 2100, labelKey: 'carrierHomeowners.statefarm' },
	{
		value: 'StateFarmFloridaInsuranceCompany',
		title: 'State Farm Florida Insurance Company',
		id: 2110,
		labelKey: 'carrierHomeowners.statefarmfloridainsurancecompany',
	},
	{ value: 'Fidelity', title: 'Stillwater', id: 2120, labelKey: 'carrierHomeowners.fidelity' },
	{
		value: 'SunshineStateInsuranceCompany',
		title: 'Sunshine State Insurance Company',
		id: 2130,
		labelKey: 'carrierHomeowners.sunshinestateinsurancecompany',
	},
	{
		value: 'TeachersInsuranceCompany',
		title: 'Teachers Insurance Company',
		id: 2140,
		labelKey: 'carrierHomeowners.teachersinsurancecompany',
	},
	{
		value: 'TowerHillPrimeInsuranceCompany',
		title: 'Tower Hill Prime Insurance Company',
		id: 2150,
		labelKey: 'carrierHomeowners.towerhillprimeinsurancecompany',
	},
	{
		value: 'TransportationInsuranceCompany',
		title: 'Transportation Insurance Company',
		id: 2160,
		labelKey: 'carrierHomeowners.transportationinsurancecompany',
	},
	{ value: 'Travelers', title: 'Travelers', id: 2170, labelKey: 'carrierHomeowners.travelers' },
	{
		value: 'TravelersIndemnityCompanyOfAmerica',
		title: 'Travelers Indemnity Company Of America',
		id: 2180,
		labelKey: 'carrierHomeowners.travelersindemnitycompanyofamerica',
	},
	{ value: 'TWFG', title: 'TWFG', id: 2190, labelKey: 'carrierHomeowners.twfg' },
	{ value: 'Unigard', title: 'Unigard', id: 2200, labelKey: 'carrierHomeowners.unigard' },
	{
		value: 'UnitedCasualtyInsuranceCompanyOfAmerica',
		title: 'United Casualty Insurance Company Of America',
		id: 2210,
		labelKey: 'carrierHomeowners.unitedcasualtyinsurancecompanyofamerica',
	},
	{
		value: 'UnitedFireAndCasualty',
		title: 'United Fire and Casualty',
		id: 2220,
		labelKey: 'carrierHomeowners.unitedfireandcasualty',
	},
	{
		value: 'UnitedFireAndCasualtyCompany',
		title: 'United Fire And Casualty Company',
		id: 2230,
		labelKey: 'carrierHomeowners.unitedfireandcasualtycompany',
	},
	{
		value: 'UnitedPropertyCasualtyInsuranceCompanyInc',
		title: 'United Property And Casualty Insurance Company, Inc',
		id: 2240,
		labelKey: 'carrierHomeowners.unitedpropertycasualtyinsurancecompanyinc',
	},
	{
		value: 'UnitedServicesAutomobileAssociation',
		title: 'United Services Automobile Association',
		id: 2250,
		labelKey: 'carrierHomeowners.unitedservicesautomobileassociation',
	},
	{ value: 'Unitrin', title: 'Unitrin', id: 2260, labelKey: 'carrierHomeowners.unitrin' },
	{ value: 'Universal', title: 'Universal', id: 2270, labelKey: 'carrierHomeowners.universal' },
	{
		value: 'UniversalInsuranceCompanyOfNorthAmerica',
		title: 'Universal Insurance Company Of North America',
		id: 2280,
		labelKey: 'carrierHomeowners.universalinsurancecompanyofnorthamerica',
	},
	{
		value: 'UniversalPropertyCasualtyInsuranceCompany',
		title: 'Universal Property And Casualty Insurance Company',
		id: 2290,
		labelKey: 'carrierHomeowners.universalpropertycasualtyinsurancecompany',
	},
	{ value: 'USAA', title: 'USAA', id: 2300, labelKey: 'carrierHomeowners.usaa' },
	{
		value: 'UsaaCasualtyInsuranceCompany',
		title: 'Usaa Casualty Insurance Company',
		id: 2310,
		labelKey: 'carrierHomeowners.usaacasualtyinsurancecompany',
	},
	{ value: 'UticaNational', title: 'Utica National', id: 2320, labelKey: 'carrierHomeowners.uticanational' },
	{
		value: 'VigilantInsuranceCompany',
		title: 'Vigilant Insurance Company',
		id: 2330,
		labelKey: 'carrierHomeowners.vigilantinsurancecompany',
	},
	{ value: 'WestBend', title: 'West Bend', id: 2340, labelKey: 'carrierHomeowners.westbend' },
	{ value: 'WesternNational', title: 'Western National', id: 2350, labelKey: 'carrierHomeowners.westernnational' },
	{
		value: 'WesternReserveGroup',
		title: 'Western Reserve Group',
		id: 2360,
		labelKey: 'carrierHomeowners.westernreservegroup',
	},
	{ value: 'Westfield', title: 'Westfield', id: 2370, labelKey: 'carrierHomeowners.westfield' },
	{
		value: 'WestfieldInsuranceCompany',
		title: 'Westfield Insurance Company',
		id: 2380,
		labelKey: 'carrierHomeowners.westfieldinsurancecompany',
	},
	{ value: 'Weston', title: 'Weston', id: 2390, labelKey: 'carrierHomeowners.weston' },
	{ value: 'WhiteMountains', title: 'White Mountains', id: 2400, labelKey: 'carrierHomeowners.whitemountains' },
	{ value: 'WilsonMutual', title: 'Wilson Mutual', id: 2410, labelKey: 'carrierHomeowners.wilsonmutual' },
	{ value: 'Zurich', title: 'Zurich', id: 2420, labelKey: 'carrierHomeowners.zurich' },
	{ value: 'OtherStandard', title: 'Other', id: 2450, labelKey: 'carrierHomeowners.otherstandard' },
];

export const personalLossesDescriptionOptions: IDropdownOption[] = [
	{ id: 1, value: 'ALLOTHER', title: 'All Other', labelKey: 'personalLossesDescription.allOther' },
	{ id: 2, value: 'CONTAMINATION', title: 'Contamination', labelKey: 'personalLossesDescription.contamination' },
	{ id: 3, value: 'CREDITCARD', title: 'Credit card', labelKey: 'personalLossesDescription.creditCard' },
	{
		id: 4,
		value: 'DAMAGETOPROPERTYOFOTHERS',
		title: 'Damage to property of others',
		labelKey: 'personalLossesDescription.damageToPropertyOfOthers',
	},
	{ id: 5, value: 'DOGBITELIABILITY', title: 'Dog bite', labelKey: 'personalLossesDescription.dogBite' },
	{ id: 6, value: 'EARTHMOVEMENT', title: 'Earth movement', labelKey: 'personalLossesDescription.earthMovement' },
	{ id: 7, value: 'EARTHQUAKE', title: 'Earthquake', labelKey: 'personalLossesDescription.earthquake' },
	{
		id: 8,
		value: 'EXTENDEDCOVERAGEPERILS',
		title: 'Extended coverage perils',
		labelKey: 'personalLossesDescription.extendedCoveragePerils',
	},
	{ id: 9, value: 'FIRE', title: 'Fire', labelKey: 'personalLossesDescription.fire' },
	{ id: 10, value: 'FLOOD', title: 'Flood', labelKey: 'personalLossesDescription.flood' },
	{
		id: 11,
		value: 'FREEZINGWATERINCLUDINGBURSTPIPES',
		title: 'Freezing Water',
		labelKey: 'personalLossesDescription.freezingWater',
	},
	{ id: 12, value: 'HAIL', title: 'Hail', labelKey: 'personalLossesDescription.hail' },
	{ id: 13, value: 'HURRICANE', title: 'Hurricane', labelKey: 'personalLossesDescription.hurricane' },
	{ id: 14, value: 'LIABILITYALLOTHER', title: 'Liability', labelKey: 'personalLossesDescription.liability' },
	{ id: 15, value: 'LIGHTNING', title: 'Lightning', labelKey: 'personalLossesDescription.lightning' },
	{
		id: 16,
		value: 'MEDICALPAYMENTS',
		title: 'Medical payments',
		labelKey: 'personalLossesDescription.medicalPayments',
	},
	{
		id: 17,
		value: 'MYSTERIOUSDISAPPEARANCE',
		title: 'Mysterious disappearance',
		labelKey: 'personalLossesDescription.mysteriousDisappearance',
	},
	{
		id: 18,
		value: 'MYSTERIOUSDISAPPEARANCESCHEDULEDPROPERTY',
		title: 'Mysterious Disappearance - Scheduled Property',
		labelKey: 'personalLossesDescription.mysteriousDisappearanceScheduledProperty',
	},
	{
		id: 19,
		value: 'PHYSICALDAMAGEALLOTHER',
		title: 'Physical Damage',
		labelKey: 'personalLossesDescription.physicalDamage',
	},
	{ id: 20, value: 'SINKHOLE', title: 'Sinkhole', labelKey: 'personalLossesDescription.sinkhole' },
	{ id: 21, value: 'SLIPFALLLIABILITY', title: 'SlipFall', labelKey: 'personalLossesDescription.slipFall' },
	{ id: 22, value: 'SMOKE', title: 'Smoke', labelKey: 'personalLossesDescription.smoke' },
	{
		id: 23,
		value: 'THEFTSCHEDULEDPROPERTY',
		title: 'Theft scheduled property',
		labelKey: 'personalLossesDescription.theftScheduledProperty',
	},
	{ id: 24, value: 'THEFTBURGLARY', title: 'Theft burglary', labelKey: 'personalLossesDescription.theftBurglary' },
	{ id: 25, value: 'TORNADO', title: 'Tornado', labelKey: 'personalLossesDescription.tornado' },
	{ id: 26, value: 'VANDALISMMALICIOUSMISCHIEF', title: 'Vandalism', labelKey: 'personalLossesDescription.vandalism' },
	{ id: 27, value: 'WATERDAMAGE', title: 'Water damage', labelKey: 'personalLossesDescription.waterDamage' },
	{ id: 28, value: 'WIND', title: 'Wind', labelKey: 'personalLossesDescription.wind' },
	{
		id: 29,
		value: 'WORKERSCOMPENSATION',
		title: 'Workers compensation',
		labelKey: 'personalLossesDescription.workersCompensation',
	},
];

export const dwellingUsageOptions: IDropdownOption[] = [
	{ id: 1, value: 'Secondary', title: 'Secondary', labelKey: 'dwellingUsage.secondary' },
	{ id: 2, value: 'Seasonal', title: 'Seasonal', labelKey: 'dwellingUsage.seasonal' },
	{ id: 3, value: 'Rental', title: 'Rental', labelKey: 'dwellingUsage.rental' },
	{ id: 4, value: 'Vacant', title: 'Vacant', labelKey: 'dwellingUsage.vacant' },
];

export const numOfStoriesOptions: IDropdownOption[] = [
	{ value: 'One', title: '1', id: 10, labelKey: 'numOfStories.one' },
	{ value: 'OneHalf', title: '1.5', id: 20, labelKey: 'numOfStories.oneHalf' },
	{ value: 'Two', title: '2', id: 30, labelKey: 'numOfStories.two' },
	{ value: 'TwoHalf', title: '2.5', id: 40, labelKey: 'numOfStories.twoHalf' },
	{ value: 'Three', title: '3', id: 50, labelKey: 'numOfStories.three' },
	{ value: 'ThreeHalf', title: '3.5', id: 60, labelKey: 'numOfStories.threeHalf' },
	{ value: 'Four', title: '4', id: 70, labelKey: 'numOfStories.four' },
	{ value: 'BiLevel', title: 'Bi-Level', id: 90, labelKey: 'numOfStories.biLevel' },
	{ value: 'TriLevel', title: 'Tri-Level', id: 90, labelKey: 'numOfStories.triLevel' },
];

export const flooringMaterialOptions: IDropdownOption[] = [
	{ id: 1, value: 'Carpet', title: 'Carpet', labelKey: 'flooringMaterial.carpet' },
	{ id: 2, value: 'SheetVinyl', title: 'Sheet Vinyl', labelKey: 'flooringMaterial.sheetVinyl' },
	{ id: 3, value: 'Hardwood', title: 'Hardwood', labelKey: 'flooringMaterial.hardwood' },
	{ id: 4, value: 'TileCeramic', title: 'Tile-Ceramic', labelKey: 'flooringMaterial.tileCeramic' },
	{ id: 5, value: 'LaminateWood', title: 'Laminate Wood', labelKey: 'flooringMaterial.laminateWood' },
	{ id: 6, value: 'HardwoodParquet', title: 'Hardwood-Parquet', labelKey: 'flooringMaterial.hardwoodParquet' },
	{ id: 7, value: 'BareConcrete', title: 'Bare Concrete', labelKey: 'flooringMaterial.bareConcrete' },
	{ id: 8, value: 'TileMarbleGranite', title: 'Tile-Marble/Granite', labelKey: 'flooringMaterial.tileMarbleGranite' },
	{ id: 9, value: 'Stone', title: 'Stone', labelKey: 'flooringMaterial.stone' },
	{ id: 10, value: 'Slate', title: 'Slate', labelKey: 'flooringMaterial.slate' },
	{ id: 11, value: 'Cork', title: 'Cork', labelKey: 'flooringMaterial.cork' },
	{ id: 12, value: 'BrickPavers', title: 'Brick Pavers', labelKey: 'flooringMaterial.brickPavers' },
	{ id: 13, value: 'Terrazzo', title: 'Terrazzo', labelKey: 'flooringMaterial.terrazzo' },
];

export const dogsBreedsSelectionOptions: IDropdownOption[] = [
	{ id: 1, value: 'Akita', title: 'Akita', labelKey: 'dogsBreeds.akita' },
	{ id: 2, value: 'AlaskanMalamute', title: 'Alaskan Malamute', labelKey: 'dogsBreeds.alaskanMalamute' },
	{ id: 3, value: 'AmericanBulldog', title: 'American Bulldog', labelKey: 'dogsBreeds.americanBulldog' },
	{
		id: 4,
		value: 'AmericanStaffordshireTerrier',
		title: 'American Staffordshire Terrier',
		labelKey: 'dogsBreeds.americanStaffordshireTerrier',
	},
	{ id: 5, value: 'Beauceron', title: 'Beauceron', labelKey: 'dogsBreeds.beauceron' },
	{ id: 6, value: 'BelgianMailnois', title: 'Belgian Mailnois', labelKey: 'dogsBreeds.belgianMailnois' },
	{ id: 7, value: 'Boxer', title: 'Boxer', labelKey: 'dogsBreeds.boxer' },
	{ id: 8, value: 'BullMastiff', title: 'Bull Mastiff', labelKey: 'dogsBreeds.bullMastiff' },
	{ id: 9, value: 'BullTerrier', title: 'Bull Terrier', labelKey: 'dogsBreeds.bullTerrier' },
	{
		id: 10,
		value: 'CaucasianMountainDog',
		title: 'Caucasian Mountain Dog',
		labelKey: 'dogsBreeds.caucasianMountainDog',
	},
	{ id: 11, value: 'Chow', title: 'Chow', labelKey: 'dogsBreeds.chow' },
	{ id: 12, value: 'Dalmatian', title: 'Dalmatian', labelKey: 'dogsBreeds.dalmatian' },
	{
		id: 14,
		value: 'DobermanPinscherOrDoberman',
		title: 'Doberman Pinscher / Doberman',
		labelKey: 'dogsBreeds.dobermanPinscherOrDoberman',
	},
	{ id: 15, value: 'DutchShepherd', title: 'Dutch Shepherd', labelKey: 'dogsBreeds.dutchShepherd' },
	{ id: 16, value: 'GermanShepherd', title: 'German Shepherd', labelKey: 'dogsBreeds.germanShepherd' },
	{ id: 17, value: 'GiantSchnauzer', title: 'Giant Schnauzer', labelKey: 'dogsBreeds.giantSchnauzer' },
	{ id: 18, value: 'GreatDane', title: 'Great Dane', labelKey: 'dogsBreeds.greatDane' },
	{ id: 19, value: 'Keeshond', title: 'Keeshond', labelKey: 'dogsBreeds.keeshond' },
	{ id: 20, value: 'Mastiff', title: 'Mastiff', labelKey: 'dogsBreeds.mastiff' },
	{ id: 21, value: 'NeapolitanMastiff', title: 'Neapolitan Mastiff', labelKey: 'dogsBreeds.neapolitanMastiff' },
	{ id: 22, value: 'Ovcharka', title: 'Ovcharka', labelKey: 'dogsBreeds.ovcharka' },
	{ id: 23, value: 'Pitbull', title: 'Pit bull', labelKey: 'dogsBreeds.pitbull' },
	{ id: 24, value: 'PresaCanario', title: 'Presa Canario', labelKey: 'dogsBreeds.presaCanario' },
	{ id: 25, value: 'RhodesianRidgeback', title: 'Rhodesian Ridgeback', labelKey: 'dogsBreeds.rhodesianRidgeback' },
	{ id: 26, value: 'Rottweiler', title: 'Rottweiler', labelKey: 'dogsBreeds.rottweiler' },
	{ id: 27, value: 'Huskies', title: 'Siberian Husky', labelKey: 'dogsBreeds.huskies' },
	{
		id: 28,
		value: 'StaffordshireBullTerrier',
		title: 'Staffordshire Bull Terrier',
		labelKey: 'dogsBreeds.staffordshireBullTerrier',
	},
	{ id: 29, value: 'StaffordshireTerrier', title: 'StaffordshireTerrier', labelKey: 'dogsBreeds.staffordshireTerrier' },
	{ id: 30, value: 'WolfHybrid', title: 'Wolf Hybrid', labelKey: 'dogsBreeds.wolfHybrid' },
	{ id: 31, value: 'NoneOfTheAbove', title: 'Not Listed', labelKey: 'dogsBreeds.noneOfTheAbove' },
];

export const dogsBreedsSelectionSimplifyOptions: IDropdownOption[] = [
	{ id: 0, value: 'NoneOfTheAbove', title: 'Other', labelKey: 'dogsBreedsSimplify.noneOfTheAbove' },
	{ id: 1, value: 'Akita', title: 'Akita', labelKey: 'dogsBreedsSimplify.akita' },
	{ id: 2, value: 'AlaskanMalamute', title: 'Alaskan Malamute', labelKey: 'dogsBreedsSimplify.alaskanMalamute' },
	{ id: 3, value: 'AmericanBulldog', title: 'American Bulldog', labelKey: 'dogsBreedsSimplify.americanBulldog' },
	{
		id: 4,
		value: 'AmericanStaffordshireTerrier',
		title: 'American Staffordshire Terrier',
		labelKey: 'dogsBreedsSimplify.americanStaffordshireTerrier',
	},
	{ id: 5, value: 'Beauceron', title: 'Beauceron', labelKey: 'dogsBreedsSimplify.beauceron' },
	{ id: 6, value: 'BelgianMailnois', title: 'Belgian Mailnois', labelKey: 'dogsBreedsSimplify.belgianMailnois' },
	{ id: 7, value: 'Boxer', title: 'Boxer', labelKey: 'dogsBreedsSimplify.boxer' },
	{ id: 8, value: 'BullMastiff', title: 'Bull Mastiff', labelKey: 'dogsBreedsSimplify.bullMastiff' },
	{ id: 9, value: 'BullTerrier', title: 'Bull Terrier', labelKey: 'dogsBreedsSimplify.bullTerrier' },
	{
		id: 10,
		value: 'CaucasianMountainDog',
		title: 'Caucasian Mountain Dog',
		labelKey: 'dogsBreedsSimplify.caucasianMountainDog',
	},
	{ id: 11, value: 'Chow', title: 'Chow', labelKey: 'dogsBreedsSimplify.chow' },
	{ id: 12, value: 'Dalmatian', title: 'Dalmatian', labelKey: 'dogsBreedsSimplify.dalmatian' },
	{
		id: 14,
		value: 'DobermanPinscherOrDoberman',
		title: 'Doberman Pinscher / Doberman',
		labelKey: 'dogsBreedsSimplify.dobermanPinscherOrDoberman',
	},
	{ id: 15, value: 'DutchShepherd', title: 'Dutch Shepherd', labelKey: 'dogsBreedsSimplify.dutchShepherd' },
	{ id: 16, value: 'GermanShepherd', title: 'German Shepherd', labelKey: 'dogsBreedsSimplify.germanShepherd' },
	{ id: 17, value: 'GiantSchnauzer', title: 'Giant Schnauzer', labelKey: 'dogsBreedsSimplify.giantSchnauzer' },
	{ id: 18, value: 'GreatDane', title: 'Great Dane', labelKey: 'dogsBreedsSimplify.greatDane' },
	{ id: 19, value: 'Keeshond', title: 'Keeshond', labelKey: 'dogsBreedsSimplify.keeshond' },
	{ id: 20, value: 'Mastiff', title: 'Mastiff', labelKey: 'dogsBreedsSimplify.mastiff' },
	{ id: 21, value: 'NeapolitanMastiff', title: 'Neapolitan Mastiff', labelKey: 'dogsBreedsSimplify.neapolitanMastiff' },
	{ id: 22, value: 'Ovcharka', title: 'Ovcharka', labelKey: 'dogsBreedsSimplify.ovcharka' },
	{ id: 23, value: 'Pitbull', title: 'Pit bull', labelKey: 'dogsBreedsSimplify.pitbull' },
	{ id: 24, value: 'PresaCanario', title: 'Presa Canario', labelKey: 'dogsBreedsSimplify.presaCanario' },
	{
		id: 25,
		value: 'RhodesianRidgeback',
		title: 'Rhodesian Ridgeback',
		labelKey: 'dogsBreedsSimplify.rhodesianRidgeback',
	},
	{ id: 26, value: 'Rottweiler', title: 'Rottweiler', labelKey: 'dogsBreedsSimplify.rottweiler' },
	{ id: 27, value: 'Huskies', title: 'Siberian Husky', labelKey: 'dogsBreedsSimplify.huskies' },
	{
		id: 28,
		value: 'StaffordshireBullTerrier',
		title: 'Staffordshire Bull Terrier',
		labelKey: 'dogsBreedsSimplify.staffordshireBullTerrier',
	},
	{
		id: 29,
		value: 'StaffordshireTerrier',
		title: 'StaffordshireTerrier',
		labelKey: 'dogsBreedsSimplify.staffordshireTerrier',
	},
	{ id: 30, value: 'WolfHybrid', title: 'Wolf Hybrid', labelKey: 'dogsBreedsSimplify.wolfHybrid' },
];

export const basementFinishOptions: IDropdownOption[] = [
	{ title: 'Full', value: 'full', id: 10, labelKey: 'basementFinishOptions.full' },
	{ title: '3/4', value: 'ThreeQuarters', id: 20, labelKey: 'basementFinishOptions.threeQuarters' },
	{ title: 'Half', value: 'Half', id: 30, labelKey: 'basementFinishOptions.half' },
	{ title: '1/4', value: 'Quarter', id: 40, labelKey: 'basementFinishOptions.quarter' },
	{ title: 'None', value: 'None', id: 50, labelKey: 'basementFinishOptions.none' },
];

export const TypeOfFoundation: IDropdownOption[] = [
	{ title: 'Slab', value: 'Slab', id: 10, labelKey: 'typeOfFoundation.slab' },
	{ title: 'Basement', value: 'Basement', id: 20, labelKey: 'typeOfFoundation.basement' },
	{ title: 'Crawl Space', value: 'CrawlSpace', id: 30, labelKey: 'typeOfFoundation.crawlSpace' },
	{ title: 'Partial Basement', value: 'PartialBasement', id: 40, labelKey: 'typeOfFoundation.partialBasement' },
	{ title: 'Piers, Posts and Piles', value: 'PiersPostsPiles', id: 50, labelKey: 'typeOfFoundation.piersPostsPiles' },
	{ title: 'Open', value: 'Open', id: 60, labelKey: 'typeOfFoundation.open' },
];

export const constructionTypeOptions: IDropdownOption[] = [
	{ title: 'Frame', value: 'Frame', id: 10, labelKey: 'constructionTypeOptions.frame' },
	{ title: 'Masonry', value: 'Masonry', id: 20, labelKey: 'constructionTypeOptions.masonry' },
	{ title: 'Masonry Veneer', value: 'MasonryVeneer', id: 30, labelKey: 'constructionTypeOptions.masonryVeneer' },
	{ title: 'Log', value: 'Log', id: 40, labelKey: 'constructionTypeOptions.log' },
	{ title: 'Asbestos', value: 'Asbestos', id: 50, labelKey: 'constructionTypeOptions.asbestos' },
	{ title: 'EFIS', value: 'EFIS', id: 60, labelKey: 'constructionTypeOptions.efis' },
	{ title: 'Hardi Plank', value: 'HardiPlank', id: 70, labelKey: 'constructionTypeOptions.hardiPlank' },
	{
		title: 'Superior Fire Resistive',
		value: 'SuperiorFireResistive',
		id: 80,
		labelKey: 'constructionTypeOptions.superiorFireResistive',
	},
	{
		title: 'Superior Masonry Non-Combustible',
		value: 'SuperiorMasonryNonCombustible',
		id: 90,
		labelKey: 'constructionTypeOptions.superiorMasonryNonCombustible',
	},
	{
		title: 'Superior Non-Combustible',
		value: 'SuperiorNonCombustible',
		id: 100,
		labelKey: 'constructionTypeOptions.superiorNonCombustible',
	},
];

/////// applicant page Options
export const maritalStatusOptions: IDropdownOption[] = [
	{ id: 1, value: 'Married', title: 'Married', labelKey: 'maritalStatus.married' },
	{ id: 2, value: 'Single', title: 'Single', labelKey: 'maritalStatus.single' },
	{ id: 3, value: 'DomesticPartner', title: 'Domestic partner', labelKey: 'maritalStatus.domesticPartner' },
	{ id: 4, value: 'Widowed', title: 'Widowed', labelKey: 'maritalStatus.widowed' },
	{ id: 5, value: 'Separated', title: 'Separated', labelKey: 'maritalStatus.separated' },
	{ id: 6, value: 'Divorced', title: 'Divorced', labelKey: 'maritalStatus.divorced' },
];

export const DefaultBIOptions: IDropdownOption[] = [
	{ id: 1, value: 'cov25to50', title: '$25k per person / $50k per accident', labelKey: 'defaultBI.cov25to50' },
	{
		id: 2,
		value: 'cov50to100',
		title: '$50k per person / $100k per accident',
		labelKey: 'defaultBI.cov50to100',
	},
	{
		id: 3,
		value: 'cov100to300',
		title: '$100k per person / $300k per accident',
		labelKey: 'defaultBI.cov100to300',
	},
	{
		id: 4,
		value: 'cov250to500',
		title: '$250k per person / $500k per accident',
		labelKey: 'defaultBI.cov250to500',
	},
];

export const FilteredBIOptions: IDropdownOption[] = [
	{
		id: 1,
		value: 'cov50to100',
		title: '$50k per person / $100k per accident',
		labelKey: 'filteredBI.cov50to100',
	},
	{
		id: 2,
		value: 'cov100to300',
		title: '$100k per person / $300k per accident',
		labelKey: 'filteredBI.cov100to300',
	},
	{
		id: 3,
		value: 'cov250to500',
		title: '$250k per person / $500k per accident',
		labelKey: 'filteredBI.cov250to500',
	},
];

export const CompDeductibleOptions: IDropdownOption[] = [
	{ id: 1, value: 'NoCoverage', title: 'No Coverage', labelKey: 'compDeductible.noCoverage' },
	{ id: 2, value: 'Hundred', title: '$100', labelKey: 'compDeductible.hundred' },
	{ id: 3, value: 'TwoHundredfifty', title: '$250', labelKey: 'compDeductible.twoHundredfifty' },
	{ id: 4, value: 'FiveHundred', title: '$500', labelKey: 'compDeductible.fiveHundred' },
	{ id: 5, value: 'Thousand', title: '$1000', labelKey: 'compDeductible.thousand' },
	{ id: 6, value: 'TwoThousand', title: '$2000', labelKey: 'compDeductible.twoThousand' },
];

export const CollDeductibleOptions: IDropdownOption[] = [
	{ id: 1, value: 'NoCoverage', title: 'No Coverage', labelKey: 'collDeductible.noCoverage' },
	{ id: 2, value: 'Hundred', title: '$100', labelKey: 'collDeductible.hundred' },
	{ id: 3, value: 'TwoHundredfifty', title: '$250', labelKey: 'collDeductible.twoHundredfifty' },
	{ id: 4, value: 'FiveHundred', title: '$500', labelKey: 'collDeductible.fiveHundred' },
	{ id: 5, value: 'Thousand', title: '$1000', labelKey: 'collDeductible.thousand' },
	{ id: 6, value: 'TwoThousand', title: '$2000', labelKey: 'collDeductible.twoThousand' },
];

export const petGenderOptions: IDropdownOption[] = [
	{ id: 1, value: 'Male', title: 'Male', labelKey: 'gender.male' },
	{ id: 2, value: 'Female', title: 'Female', labelKey: 'gender.female' },
];

export const PL_RelationshipToClientOptions: IDropdownOption[] = [
	{ id: 1, value: 'Spouse', title: 'Spouse', labelKey: 'plRelationshipToClient.spouse' },
	{
		id: 2,
		value: 'DomesticPartner',
		title: 'DomesticPartner',
		labelKey: 'plRelationshipToClient.domesticPartner',
	},
	{ id: 3, value: 'Parent', title: 'Parent', labelKey: 'plRelationshipToClient.parent' },
	{ id: 4, value: 'Child', title: 'Child', labelKey: 'plRelationshipToClient.child' },
	{ id: 5, value: 'Relative', title: 'Relative', labelKey: 'plRelationshipToClient.relative' },
	{ id: 6, value: 'Employee', title: 'Employee', labelKey: 'plRelationshipToClient.employee' },
	{ id: 7, value: 'Other', title: 'Other', labelKey: 'plRelationshipToClient.other' },
];

export const SelectRelationshipToClientOptions: IDropdownOption[] = [
	{ id: 1, value: 'Spouse', title: 'Spouse', labelKey: 'selectRelationshipToClient.spouse' },
	{
		id: 2,
		value: 'DomesticPartner',
		title: 'Domestic Partner',
		labelKey: 'selectRelationshipToClient.domesticPartner',
	},
	{ id: 3, value: 'Parent', title: 'Parent', labelKey: 'selectRelationshipToClient.parent' },
	{ id: 4, value: 'Child', title: 'Child', labelKey: 'selectRelationshipToClient.child' },
	{ id: 5, value: 'Relative', title: 'Relative', labelKey: 'selectRelationshipToClient.relative' },
	{ id: 6, value: 'Employee', title: 'Employee', labelKey: 'selectRelationshipToClient.employee' },
	{ id: 7, value: 'Other', title: 'Other', labelKey: 'selectRelationshipToClient.other' },
];

export const mitWindowOpeningOptions: IDropdownOption[] = [
	{ id: 1, value: 'Intermediate', title: 'All Windows Are Protected', labelKey: 'mitWindowOpening.intermediate' },
	{
		id: 2,
		value: 'HurricaneProtection',
		title: 'All Windows, Doors And Garages Are Protected',
		labelKey: 'mitWindowOpening.hurricaneProtection',
	},
	{ id: 3, value: 'None', title: 'None', labelKey: 'mitWindowOpening.none' },
	{ id: 4, value: 'Unknown', title: 'Unknown', labelKey: 'mitWindowOpening.unknown' },
];

///////

/// discounts options

export const fireSafetyFeaturesOptions: IFieldItem[] = [
	Fields.SmokeDetectors,
	Fields.FireExtinguishers,
	Fields.SprinklerSystem,
	Fields.FireDetection,
];

export const homeSecurityFeaturesOptions: IFieldItem[] = [Fields.DeadBoltLocks, Fields.BurglarAlarmType];

export const PL_TypeFireplacesOptions: IDropdownOption[] = [
	{ id: 1, value: 'Gas', title: 'Gas', labelKey: 'plTypeFireplaces.gas' },
	{ id: 2, value: 'Single', title: 'Single Brick or Stone', labelKey: 'plTypeFireplaces.singleBrickOrStone' },
	{ id: 3, value: 'Double', title: 'Double Brick or Stone', labelKey: 'plTypeFireplaces.doubleBrickOrStone' },
	{
		id: 4,
		value: 'MasonryCustomDesign',
		title: 'Masonry-Custom Design',
		labelKey: 'plTypeFireplaces.masonryCustomDesign',
	},
	{
		id: 5,
		value: 'PrefabricatedZeroClearance',
		title: 'Prefabricated/Zero Clearance',
		labelKey: 'plTypeFireplaces.prefabricatedZeroClearance',
	},
	{
		id: 6,
		value: 'WoodStoveFreestanding',
		title: 'Wood Stove/Freestanding',
		labelKey: 'plTypeFireplaces.woodStoveFreestanding',
	},
	{ id: 7, value: 'BeehiveKiva', title: 'Beehive (Kiva)', labelKey: 'plTypeFireplaces.beehiveKiva' },
];

export const PlumbingTypeOptions: IDropdownOption[] = [
	{ id: 1, value: 'EntirelyCopper', title: 'Entirely copper', labelKey: 'plumbingType.entirelyCopper' },
	{ id: 2, value: 'EntirelyGalvanized', title: 'Entirely galvanized', labelKey: 'plumbingType.entirelyGalvanized' },
	{ id: 3, value: 'EntirelyPEX', title: 'Entirely PEX', labelKey: 'plumbingType.entirelyPEX' },
	{ id: 4, value: 'EntirelyPVC', title: 'Entirely PVC', labelKey: 'plumbingType.entirelyPVC' },
	{ id: 5, value: 'Polybutylene', title: 'Polybutylene', labelKey: 'plumbingType.polybutylene' },
	{ id: 6, value: 'MixedOfTheAbove', title: 'Mixed of the above', labelKey: 'plumbingType.mixedOfTheAbove' },
	{
		id: 7,
		value: 'MixedInclOtherNonListedMaterial',
		title: 'Mixed including other non-listed materials',
		labelKey: 'plumbingType.mixedInclOtherNonListedMaterial',
	},
	{
		id: 8,
		value: 'OtherNonListedMaterial',
		title: 'Other non-listed material',
		labelKey: 'plumbingType.otherNonListedMaterial',
	},
	{ id: 9, value: 'IDontKnow', title: "I don't know", labelKey: 'plumbingType.iDontKnow' },
];

export const PLNumberOfStoriesOptions: IDropdownOption[] = [
	{ id: 1, value: 'One', title: '1', labelKey: 'plNumberOfStories.one' },
	{ id: 2, value: 'OneHalf', title: '1.5', labelKey: 'plNumberOfStories.oneHalf' },
	{ id: 3, value: 'Two', title: '2', labelKey: 'plNumberOfStories.two' },
	{ id: 4, value: 'TwoHalf', title: '2.5', labelKey: 'plNumberOfStories.twoHalf' },
	{ id: 5, value: 'Three', title: '3', labelKey: 'plNumberOfStories.three' },
	{ id: 6, value: 'ThreeHalf', title: '3.5', labelKey: 'plNumberOfStories.threeHalf' },
	{ id: 7, value: 'Four', title: '4', labelKey: 'plNumberOfStories.four' },
	{ id: 8, value: 'BiLevel', title: 'Bi-level', labelKey: 'plNumberOfStories.biLevel' },
	{ id: 9, value: 'TriLevel', title: 'Tri-Level', labelKey: 'plNumberOfStories.triLevel' },
];

export const ArchitectureStyleOptions: IDropdownOption[] = [
	{ id: 1, value: 'TwoFamilyHome', title: '2-Family Home', labelKey: 'architectureStyle.twoFamilyHome' },
	{ id: 2, value: 'American4square', title: 'American 4-square', labelKey: 'architectureStyle.american4square' },
	{ id: 3, value: 'Basic', title: 'Basic', labelKey: 'architectureStyle.basic' },
	{ id: 4, value: 'BiLevel', title: 'Bi-Level', labelKey: 'architectureStyle.biLevel' },
	{ id: 5, value: 'Bungalow', title: 'Bungalow', labelKey: 'architectureStyle.bungalow' },
	{ id: 6, value: 'CapeCod', title: 'Cape Cod', labelKey: 'architectureStyle.capeCod' },
	{ id: 7, value: 'Colonial', title: 'Colonial', labelKey: 'architectureStyle.colonial' },
	{ id: 8, value: 'ColonialSpanish', title: 'Colonial - Spanish', labelKey: 'architectureStyle.colonialSpanish' },
	{ id: 9, value: 'CompletelyCustom', title: 'Completely Custom', labelKey: 'architectureStyle.completelyCustom' },
	{ id: 10, value: 'Contemporary', title: 'Contemporary', labelKey: 'architectureStyle.contemporary' },
	{ id: 11, value: 'ContractorModern', title: 'Contractor Modern', labelKey: 'architectureStyle.contractorModern' },
	{ id: 12, value: 'Farmhouse', title: 'Farmhouse', labelKey: 'architectureStyle.farmhouse' },
	{ id: 13, value: 'Folk', title: 'Folk', labelKey: 'architectureStyle.folk' },
	{ id: 14, value: 'Log', title: 'Log', labelKey: 'architectureStyle.log' },
	{ id: 15, value: 'Mediterranean', title: 'Mediterranean', labelKey: 'architectureStyle.mediterranean' },
	{ id: 16, value: 'Ranch', title: 'Ranch', labelKey: 'architectureStyle.ranch' },
	{ id: 17, value: 'TriLevel', title: 'Tri-Level', labelKey: 'architectureStyle.triLevel' },
	{ id: 18, value: 'Tudor', title: 'Tudor', labelKey: 'architectureStyle.tudor' },
	{ id: 19, value: 'Vernacular', title: 'Vernacular', labelKey: 'architectureStyle.vernacular' },
	{ id: 20, value: 'Victorian', title: 'Victorian', labelKey: 'architectureStyle.victorian' },
];

export const MitRoofShapeOptions: IDropdownOption[] = [
	{ id: 1, value: 'A_Flat', title: 'Flat', labelKey: 'mitRoofShape.flat' },
	{ id: 2, value: 'B_Gable', title: 'Gable', labelKey: 'mitRoofShape.gable' },
	{ id: 3, value: 'C_Hip', title: 'Hip', labelKey: 'mitRoofShape.hip' },
	{ id: 4, value: 'D_Other', title: 'Other', labelKey: 'mitRoofShape.other' },
];

export const PL_PrimaryCounterMaterialOptions: IDropdownOption[] = [
	{
		id: 1,
		value: 'PlastinLaminatFormica',
		title: 'Plastic Laminate (Formica)',
		labelKey: 'plPrimaryCounterMaterial.plasticLaminate',
	},
	{
		id: 2,
		value: 'GraniteorMarble',
		title: 'Granite or Marble',
		labelKey: 'plPrimaryCounterMaterial.graniteOrMarble',
	},
	{
		id: 3,
		value: 'SolidSurfaceCorian',
		title: 'Solid Surface (Corian)',
		labelKey: 'plPrimaryCounterMaterial.solidSurface',
	},
	{ id: 4, value: 'Tile', title: 'Tile', labelKey: 'plPrimaryCounterMaterial.tile' },
	{ id: 5, value: 'Concrete', title: 'Concrete', labelKey: 'plPrimaryCounterMaterial.concrete' },
	{ id: 6, value: 'QuartzSilestone', title: 'Quartz (Silestone)', labelKey: 'plPrimaryCounterMaterial.quartz' },
	{ id: 7, value: 'StainlessSteel', title: 'Stainless Steel', labelKey: 'plPrimaryCounterMaterial.stainlessSteel' },
	{ id: 8, value: 'WoodButcherBlock', title: 'Wood (Butcher Block)', labelKey: 'plPrimaryCounterMaterial.wood' },
	{ id: 9, value: 'NonePedestalsink', title: 'None (Pedestal sink)', labelKey: 'plPrimaryCounterMaterial.none' },
];

export const RoofTypeOptions: IDropdownOption[] = [
	{
		id: 1,
		value: 'ARCHITECTURAL_SHINGLES',
		title: 'Architectural Shingles',
		labelKey: 'roofType.architecturalShingles',
	},
	{ id: 2, value: 'WOOD_SHINGLES', title: 'Wood Shingles', labelKey: 'roofType.woodShingles' },
	{ id: 3, value: 'ASBESTOS', title: 'Asbestos', labelKey: 'roofType.asbestos' },
	{ id: 4, value: 'ASPHALT_SHINGLES', title: 'Asphalt Shingles', labelKey: 'roofType.asphaltShingles' },
	{ id: 5, value: 'BUILT_UP', title: 'Built Up', labelKey: 'roofType.builtUp' },
	{ id: 6, value: 'COMPOSITION', title: 'Composition', labelKey: 'roofType.composition' },
	{ id: 7, value: 'CONCRETE', title: 'Concrete', labelKey: 'roofType.concrete' },
	{ id: 8, value: 'COPPER', title: 'Copper', labelKey: 'roofType.copper' },
	{ id: 9, value: 'PLASTIC', title: 'Plastic', labelKey: 'roofType.plastic' },
	{ id: 10, value: 'ROLLED_PAPER', title: 'Rolled Paper', labelKey: 'roofType.rolledPaper' },
	{ id: 11, value: 'SLATE', title: 'Slate', labelKey: 'roofType.slate' },
	{ id: 12, value: 'CORRUGATED_STEEL', title: 'Corrugated Steel', labelKey: 'roofType.corrugatedSteel' },
	{ id: 13, value: 'FIBERGLASS', title: 'Fiberglass', labelKey: 'roofType.fiberglass' },
	{
		id: 14,
		value: 'WOOD_FIBERGLASS_SHINGLES',
		title: 'Wood Fiberglass Shingles',
		labelKey: 'roofType.woodFiberglassShingles',
	},
	{ id: 15, value: 'FOAM', title: 'Foam', labelKey: 'roofType.foam' },
	{ id: 16, value: 'GRAVEL', title: 'Gravel', labelKey: 'roofType.gravel' },
	{ id: 17, value: 'METAL', title: 'Metal', labelKey: 'roofType.metal' },
	{ id: 18, value: 'MEMBRANE', title: 'Membrane', labelKey: 'roofType.membrane' },
	{ id: 19, value: 'MINERAL_FIBER_SHAKE', title: 'Mineral Fiber Shake', labelKey: 'roofType.mineralFiberShake' },
	{ id: 20, value: 'ROCK', title: 'Rock', labelKey: 'roofType.rock' },
	{ id: 21, value: 'RUBBER', title: 'Rubber', labelKey: 'roofType.rubber' },
	{ id: 22, value: 'TAR', title: 'Tar', labelKey: 'roofType.tar' },
	{ id: 23, value: 'TAR_GRAVEL', title: 'Tar and Gravel', labelKey: 'roofType.tarAndGravel' },
	{ id: 24, value: 'TILE_CLAY', title: 'Tile (Clay)', labelKey: 'roofType.tileClay' },
	{ id: 25, value: 'TILE_CONCRETE', title: 'Tile (Concrete)', labelKey: 'roofType.tileConcrete' },
	{ id: 26, value: 'TILE_MISSION', title: 'Tile (Mission)', labelKey: 'roofType.tileMission' },
	{ id: 27, value: 'TILE_SPANISH', title: 'Tile (Spanish)', labelKey: 'roofType.tileSpanish' },
	{ id: 28, value: 'TIN', title: 'Tin', labelKey: 'roofType.tin' },
	{ id: 29, value: 'WOOD_SHAKE', title: 'Wood Shake', labelKey: 'roofType.woodShake' },
	{ id: 30, value: 'OTHER', title: 'Other', labelKey: 'roofType.other' },
];

export const PL_FlooringMaterialOptions: IDropdownOption[] = [
	{ id: 1, value: 'Carpet', title: 'Carpet', labelKey: 'plFlooringMaterial.carpet' },
	{ id: 2, value: 'SheetVinyl', title: 'Sheet Vinyl', labelKey: 'plFlooringMaterial.sheetVinyl' },
	{ id: 3, value: 'Hardwood', title: 'Hardwood', labelKey: 'plFlooringMaterial.hardwood' },
	{ id: 4, value: 'TileCeramic', title: 'Tile-Ceramic', labelKey: 'plFlooringMaterial.tileCeramic' },
	{ id: 5, value: 'LaminateWood', title: 'Laminate Wood', labelKey: 'plFlooringMaterial.laminateWood' },
	{ id: 6, value: 'HardwoodParquet', title: 'Hardwood-Parquet', labelKey: 'plFlooringMaterial.hardwoodParquet' },
	{ id: 7, value: 'BareConcrete', title: 'Bare Concrete', labelKey: 'plFlooringMaterial.bareConcrete' },
	{ id: 8, value: 'TileMarbleGranite', title: 'Tile-Marble/Granite', labelKey: 'plFlooringMaterial.tileMarbleGranite' },
	{ id: 9, value: 'Stone', title: 'Stone', labelKey: 'plFlooringMaterial.stone' },
	{ id: 10, value: 'Slate', title: 'Slate', labelKey: 'plFlooringMaterial.slate' },
	{ id: 11, value: 'Cork', title: 'Cork', labelKey: 'plFlooringMaterial.cork' },
	{ id: 12, value: 'BrickPavers', title: 'Brick Pavers', labelKey: 'plFlooringMaterial.brickPavers' },
	{ id: 13, value: 'Terrazzo', title: 'Terrazzo', labelKey: 'plFlooringMaterial.terrazzo' },
];

export const PLHeatingTypeOptions: IDropdownOption[] = [
	{ id: 1, value: 'Electric', title: 'Electric', labelKey: 'plHeatingType.electric' },
	{ id: 2, value: 'Gas', title: 'Gas', labelKey: 'plHeatingType.gas' },
	{ id: 3, value: 'GasForcedAir', title: 'Gas - Forced Air', labelKey: 'plHeatingType.gasForcedAir' },
	{ id: 4, value: 'GasHotWater', title: 'Gas - Hot Water', labelKey: 'plHeatingType.gasHotWater' },
	{ id: 5, value: 'Oil', title: 'Oil', labelKey: 'plHeatingType.oil' },
	{ id: 6, value: 'OilForcedAir', title: 'Oil - Forced Air', labelKey: 'plHeatingType.oilForcedAir' },
	{ id: 7, value: 'OilHotWater', title: 'Oil - Hot Water', labelKey: 'plHeatingType.oilHotWater' },
	{ id: 8, value: 'SolidFuel', title: 'Solid Fuel', labelKey: 'plHeatingType.solidFuel' },
	{ id: 9, value: 'WoodBurningStove', title: 'Wood-burning stove', labelKey: 'plHeatingType.woodBurningStove' },
	{ id: 10, value: 'Other', title: 'Other', labelKey: 'plHeatingType.other' },
	{ id: 11, value: 'NoHeating', title: 'No Heating', labelKey: 'plHeatingType.noHeating' },
];

export const RoofUpdateYearRangeOptions: IDropdownOption[] = [
	{ id: 1, value: 'ZeroToFour', title: '0-4 years', labelKey: 'roofUpdateYearRange.zeroToFour' },
	{ id: 2, value: 'FiveToNine', title: '5-9 years', labelKey: 'roofUpdateYearRange.fiveToNine' },
	{ id: 3, value: 'TenToForteen', title: '10-14 years', labelKey: 'roofUpdateYearRange.tenToFourteen' },
	{ id: 4, value: 'FifteenToNineteen', title: '15-19 years', labelKey: 'roofUpdateYearRange.fifteenToNineteen' },
	{ id: 5, value: 'TwentyToTwentyNine', title: '20-30 years', labelKey: 'roofUpdateYearRange.twentyToThirty' },
	{ id: 6, value: 'ThirtyToThirtyNine', title: '31-40 years', labelKey: 'roofUpdateYearRange.thirtyToForty' },
	{ id: 7, value: 'FortyToFortyNine', title: '41-50 years', labelKey: 'roofUpdateYearRange.fortyToFifty' },
	{ id: 8, value: 'FiftyPlus', title: '51+ years', labelKey: 'roofUpdateYearRange.fiftyPlus' },
];

export const PL_InteriorWallMaterial: IDropdownOption[] = [
	{
		id: 1,
		value: 'DrywallVeneerPlaster',
		title: 'Drywall/Veneer Plaster',
		labelKey: 'plInteriorWallMaterial.drywallVeneerPlaster',
	},
	{ id: 2, value: 'LathandPlaster', title: 'Lath and Plaster', labelKey: 'plInteriorWallMaterial.lathAndPlaster' },
	{ id: 3, value: 'BrickSolid', title: 'Brick-Solid', labelKey: 'plInteriorWallMaterial.brickSolid' },
	{ id: 4, value: 'StoneSolid', title: 'Stone-Solid', labelKey: 'plInteriorWallMaterial.stoneSolid' },
	{ id: 5, value: 'LogSolid', title: 'Log-Solid', labelKey: 'plInteriorWallMaterial.logSolid' },
	{ id: 6, value: 'ConcreteBlock', title: 'Concrete Block', labelKey: 'plInteriorWallMaterial.concreteBlock' },
	{ id: 7, value: 'Adobe', title: 'Adobe', labelKey: 'plInteriorWallMaterial.adobe' },
	{ id: 8, value: 'GlassBlock', title: 'Glass Block', labelKey: 'plInteriorWallMaterial.glassBlock' },
	{ id: 9, value: 'StudsOnly', title: 'Studs Only', labelKey: 'plInteriorWallMaterial.studsOnly' },
];

export const SW_LossesOptions = [
	{ id: 1, value: 'LIABILITYALLOTHER', title: 'Liability', labelKey: 'SW_LossesOptions.liability-all-other' },
	{ id: 2, value: 'WATERDAMAGE', title: 'Water', labelKey: 'SW_LossesOptions.water-damage' },
	{ id: 3, value: 'WIND', title: 'Weather', labelKey: 'SW_LossesOptions.wind' },
	{ id: 4, value: 'ALLOTHER', title: 'Other', labelKey: 'SW_LossesOptions.all-other' },
];

export const SW_YearsAtAddressOptions = [
	{ id: 0, value: 1, title: 'Yes' },
	{ id: 1, value: 3, title: 'No' },
];

export const TypeGarageCarport: IDropdownOption[] = [
	{ id: 1, title: 'Attached', value: 'Attached', labelKey: 'garageCarport.attached' },
	{ id: 2, title: 'Basement', value: 'Basement', labelKey: 'garageCarport.basement' },
	{ id: 3, title: 'Built-in', value: 'BuiltIn', labelKey: 'garageCarport.builtIn' },
	{ id: 4, title: 'Carport', value: 'Carport', labelKey: 'garageCarport.carport' },
	{ id: 5, title: 'Detached', value: 'Detached', labelKey: 'garageCarport.detached' },
];

export const ExteriorWallsConstructionOptions: IDropdownOption[] = [
	{
		id: 1,
		value: 'StructuralInsulatedPanels',
		title: 'Structural Insulated Panels',
		labelKey: 'exteriorWallsConstruction.structuralInsulatedPanels',
	},
	{
		id: 2,
		value: 'CementFiberShingles',
		title: 'Cement Fiber Shingles',
		labelKey: 'exteriorWallsConstruction.cementFiberShingles',
	},
	{
		id: 3,
		value: 'PreEngineeredMetal',
		title: 'Pre Engineered Metal',
		labelKey: 'exteriorWallsConstruction.preEngineeredMetal',
	},
	{
		id: 4,
		value: 'SolidConcrete',
		title: 'Solid Concrete',
		labelKey: 'exteriorWallsConstruction.solidConcrete',
	},
	{
		id: 5,
		value: 'AluminumSiding',
		title: 'Aluminum Siding',
		labelKey: 'exteriorWallsConstruction.aluminumSiding',
	},
	{ id: 6, value: 'BrickVeneer', title: 'Brick Veneer', labelKey: 'exteriorWallsConstruction.brickVeneer' },
	{ id: 7, value: 'VinylSiding', title: 'Vinyl Siding', labelKey: 'exteriorWallsConstruction.vinylSiding' },
	{ id: 8, value: 'LogsSolid', title: 'Logs Solid', labelKey: 'exteriorWallsConstruction.logsSolid' },
	{ id: 9, value: 'Clapboard', title: 'Clapboard', labelKey: 'exteriorWallsConstruction.clapboard' },
	{
		id: 10,
		value: 'StandardPoleFramed',
		title: 'Standard Pole Framed',
		labelKey: 'exteriorWallsConstruction.standardPoleFramed',
	},
	{
		id: 11,
		value: 'LightGaugeSteelFraming',
		title: 'Light Gauge Steel Framing',
		labelKey: 'exteriorWallsConstruction.lightGaugeSteelFraming',
	},
	{ id: 12, value: 'AdobeBlock', title: 'Adobe Block', labelKey: 'exteriorWallsConstruction.adobeBlock' },
	{
		id: 13,
		value: 'MasoniteSiding',
		title: 'Masonite Siding',
		labelKey: 'exteriorWallsConstruction.masoniteSiding',
	},
	{
		id: 14,
		value: 'HardboardSiding',
		title: 'Hardboard Siding',
		labelKey: 'exteriorWallsConstruction.hardboardSiding',
	},
	{
		id: 15,
		value: 'ConcreteBlock',
		title: 'Concrete Block',
		labelKey: 'exteriorWallsConstruction.concreteBlock',
	},
	{ id: 16, value: 'Stucco', title: 'Stucco', labelKey: 'exteriorWallsConstruction.stucco' },
	{
		id: 17,
		value: 'InsulatedConcreteForms',
		title: 'Insulated Concrete Forms',
		labelKey: 'exteriorWallsConstruction.insulatedConcreteForms',
	},
	{ id: 18, value: 'WoodFraming', title: 'Wood Framing', labelKey: 'exteriorWallsConstruction.woodFraming' },
	{
		id: 19,
		value: 'SolidBrickConstruction',
		title: 'Solid Brick Construction',
		labelKey: 'exteriorWallsConstruction.solidBrickConstruction',
	},
	{ id: 20, value: 'Stone', title: 'Stone', labelKey: 'exteriorWallsConstruction.stone' },
	{
		id: 21,
		value: 'TimberFraming',
		title: 'Timber Framing',
		labelKey: 'exteriorWallsConstruction.timberFraming',
	},
];

export const KitchenConstructionQuality: IDropdownOption[] = [
	{ id: 1, value: 'BuildersGrade', title: "Builder's Grade", labelKey: "kitchenConstructionQuality.builder'sGrade" },
	{ id: 2, value: 'SemiCustom', title: 'Semi-Custom', labelKey: 'kitchenConstructionQuality.semiCustom' },
	{ id: 3, value: 'Custom', title: 'Custom', labelKey: 'kitchenConstructionQuality.custom' },
	{ id: 4, value: 'Designer', title: 'Designer', labelKey: 'kitchenConstructionQuality.designer' },
];

export const NumberZeroToTenOptions: IDropdownOption[] = [
	{ id: 0, value: '0', title: '0' },
	{ id: 1, value: '1', title: '1' },
	{ id: 2, value: '2', title: '2' },
	{ id: 3, value: '3', title: '3' },
	{ id: 4, value: '4', title: '4' },
	{ id: 5, value: '5', title: '5' },
	{ id: 6, value: '6', title: '6' },
	{ id: 7, value: '7', title: '7' },
	{ id: 8, value: '8', title: '8' },
	{ id: 9, value: '9', title: '9' },
	{ id: 10, value: '10', title: '10' },
];

export const NumberOneToTenOptions: IDropdownOption[] = [
	{ id: 1, value: '1', title: '1' },
	{ id: 2, value: '2', title: '2' },
	{ id: 3, value: '3', title: '3' },
	{ id: 4, value: '4', title: '4' },
	{ id: 5, value: '5', title: '5' },
	{ id: 6, value: '6', title: '6' },
	{ id: 7, value: '7', title: '7' },
	{ id: 8, value: '8', title: '8' },
	{ id: 9, value: '9', title: '9' },
	{ id: 10, value: '10', title: '10' },
];

export const MonthsUnoccupiedDD: IDropdownOption[] = [
	{ id: 1, value: '1', title: '1' },
	{ id: 2, value: '2', title: '2' },
	{ id: 3, value: '3', title: '3' },
	{ id: 4, value: '4', title: '4' },
	{ id: 5, value: '5', title: '5' },
	{ id: 6, value: '6', title: '6' },
	{ id: 7, value: '7', title: '7' },
	{ id: 8, value: '8', title: '8' },
	{ id: 9, value: '9', title: '9' },
	{ id: 10, value: '10', title: '10' },
	{ id: 11, value: '11', title: '11' },
	{ id: 12, value: '12', title: '12' },
	{ id: 13, value: '13', title: '13 or more' },
];

export const DriverStateLicensed: IDropdownOption[] = [
	{ id: 1, value: 'AK', title: 'AK', labelKey: 'driverStateLicensed.ak' },
	{ id: 2, value: 'AL', title: 'AL', labelKey: 'driverStateLicensed.al' },
	{ id: 3, value: 'AR', title: 'AR', labelKey: 'driverStateLicensed.ar' },
	{ id: 4, value: 'AZ', title: 'AZ', labelKey: 'driverStateLicensed.az' },
	{ id: 5, value: 'CA', title: 'CA', labelKey: 'driverStateLicensed.ca' },
	{ id: 6, value: 'CO', title: 'CO', labelKey: 'driverStateLicensed.co' },
	{ id: 7, value: 'CT', title: 'CT', labelKey: 'driverStateLicensed.ct' },
	{ id: 8, value: 'DC', title: 'DC', labelKey: 'driverStateLicensed.dc' },
	{ id: 9, value: 'DE', title: 'DE', labelKey: 'driverStateLicensed.de' },
	{ id: 10, value: 'FL', title: 'FL', labelKey: 'driverStateLicensed.fl' },
	{ id: 11, value: 'GA', title: 'GA', labelKey: 'driverStateLicensed.ga' },
	{ id: 12, value: 'HI', title: 'HI', labelKey: 'driverStateLicensed.hi' },
	{ id: 13, value: 'IA', title: 'IA', labelKey: 'driverStateLicensed.ia' },
	{ id: 14, value: 'ID', title: 'ID', labelKey: 'driverStateLicensed.id' },
	{ id: 15, value: 'IL', title: 'IL', labelKey: 'driverStateLicensed.il' },
	{ id: 16, value: 'IN', title: 'IN', labelKey: 'driverStateLicensed.in' },
	{ id: 17, value: 'KS', title: 'KS', labelKey: 'driverStateLicensed.ks' },
	{ id: 18, value: 'KY', title: 'KY', labelKey: 'driverStateLicensed.ky' },
	{ id: 19, value: 'LA', title: 'LA', labelKey: 'driverStateLicensed.la' },
	{ id: 20, value: 'MA', title: 'MA', labelKey: 'driverStateLicensed.ma' },
	{ id: 21, value: 'MD', title: 'MD', labelKey: 'driverStateLicensed.md' },
	{ id: 22, value: 'ME', title: 'ME', labelKey: 'driverStateLicensed.me' },
	{ id: 23, value: 'MI', title: 'MI', labelKey: 'driverStateLicensed.mi' },
	{ id: 24, value: 'MN', title: 'MN', labelKey: 'driverStateLicensed.mn' },
	{ id: 25, value: 'MO', title: 'MO', labelKey: 'driverStateLicensed.mo' },
	{ id: 26, value: 'MS', title: 'MS', labelKey: 'driverStateLicensed.ms' },
	{ id: 27, value: 'MT', title: 'MT', labelKey: 'driverStateLicensed.mt' },
	{ id: 28, value: 'NC', title: 'NC', labelKey: 'driverStateLicensed.nc' },
	{ id: 29, value: 'ND', title: 'ND', labelKey: 'driverStateLicensed.nd' },
	{ id: 30, value: 'NE', title: 'NE', labelKey: 'driverStateLicensed.ne' },
	{ id: 31, value: 'NH', title: 'NH', labelKey: 'driverStateLicensed.nh' },
	{ id: 32, value: 'NJ', title: 'NJ', labelKey: 'driverStateLicensed.nj' },
	{ id: 33, value: 'NM', title: 'NM', labelKey: 'driverStateLicensed.nm' },
	{ id: 34, value: 'NV', title: 'NV', labelKey: 'driverStateLicensed.nv' },
	{ id: 35, value: 'NY', title: 'NY', labelKey: 'driverStateLicensed.ny' },
	{ id: 36, value: 'OH', title: 'OH', labelKey: 'driverStateLicensed.oh' },
	{ id: 37, value: 'OK', title: 'OK', labelKey: 'driverStateLicensed.ok' },
	{ id: 38, value: 'OR', title: 'OR', labelKey: 'driverStateLicensed.or' },
	{ id: 39, value: 'PA', title: 'PA', labelKey: 'driverStateLicensed.pa' },
	{ id: 40, value: 'RI', title: 'RI', labelKey: 'driverStateLicensed.ri' },
	{ id: 41, value: 'SC', title: 'SC', labelKey: 'driverStateLicensed.sc' },
	{ id: 42, value: 'SD', title: 'SD', labelKey: 'driverStateLicensed.sd' },
	{ id: 43, value: 'TN', title: 'TN', labelKey: 'driverStateLicensed.tn' },
	{ id: 44, value: 'TX', title: 'TX', labelKey: 'driverStateLicensed.tx' },
	{ id: 45, value: 'UT', title: 'UT', labelKey: 'driverStateLicensed.ut' },
	{ id: 46, value: 'VA', title: 'VA', labelKey: 'driverStateLicensed.va' },
	{ id: 47, value: 'VT', title: 'VT', labelKey: 'driverStateLicensed.vt' },
	{ id: 48, value: 'WA', title: 'WA', labelKey: 'driverStateLicensed.wa' },
	{ id: 49, value: 'WI', title: 'WI', labelKey: 'driverStateLicensed.wi' },
	{ id: 50, value: 'WV', title: 'WV', labelKey: 'driverStateLicensed.wv' },
	{ id: 51, value: 'WY', title: 'WY', labelKey: 'driverStateLicensed.wy' },
	{ id: 52, value: 'Mexico', title: 'MX', labelKey: 'driverStateLicensed.mx' },
];

export const FilingStatesOptions: IDropdownOption[] = [
	{ id: 1, value: 'AL', title: 'AL', labelKey: 'filingStates.al' },
	{ id: 2, value: 'AK', title: 'AK', labelKey: 'filingStates.ak' },
	{ id: 3, value: 'AZ', title: 'AZ', labelKey: 'filingStates.az' },
	{ id: 4, value: 'AR', title: 'AR', labelKey: 'filingStates.ar' },
	{ id: 5, value: 'CA', title: 'CA', labelKey: 'filingStates.ca' },
	{ id: 6, value: 'CO', title: 'CO', labelKey: 'filingStates.co' },
	{ id: 8, value: 'DC', title: 'DC', labelKey: 'filingStates.dc' },
	{ id: 11, value: 'GA', title: 'GA', labelKey: 'filingStates.ga' },
	{ id: 12, value: 'HI', title: 'HI', labelKey: 'filingStates.hi' },
	{ id: 13, value: 'ID', title: 'ID', labelKey: 'filingStates.id' },
	{ id: 14, value: 'IL', title: 'IL', labelKey: 'filingStates.il' },
	{ id: 16, value: 'IN', title: 'IN', labelKey: 'filingStates.in' },
	{ id: 17, value: 'IA', title: 'IA', labelKey: 'filingStates.ia' },
	{ id: 19, value: 'KS', title: 'KS', labelKey: 'filingStates.ks' },
	{ id: 22, value: 'LA', title: 'LA', labelKey: 'filingStates.la' },
	{ id: 24, value: 'ME', title: 'ME', labelKey: 'filingStates.me' },
	{ id: 25, value: 'MN', title: 'MN', labelKey: 'filingStates.mn' },
	{ id: 26, value: 'MS', title: 'MS', labelKey: 'filingStates.ms' },
	{ id: 27, value: 'MO', title: 'MO', labelKey: 'filingStates.mo' },
	{ id: 28, value: 'MT', title: 'MT', labelKey: 'filingStates.mt' },
	{ id: 29, value: 'NE', title: 'NE', labelKey: 'filingStates.ne' },
	{ id: 30, value: 'NV', title: 'NV', labelKey: 'filingStates.nv' },
	{ id: 31, value: 'NH', title: 'NH', labelKey: 'filingStates.nh' },
	{ id: 32, value: 'ND', title: 'ND', labelKey: 'filingStates.nd' },
	{ id: 33, value: 'OH', title: 'OH', labelKey: 'filingStates.oh' },
	{ id: 34, value: 'OK', title: 'OK', labelKey: 'filingStates.ok' },
	{ id: 35, value: 'OR', title: 'OR', labelKey: 'filingStates.or' },
	{ id: 36, value: 'SC', title: 'SC', labelKey: 'filingStates.sc' },
	{ id: 37, value: 'SD', title: 'SD', labelKey: 'filingStates.sd' },
	{ id: 38, value: 'TN', title: 'TN', labelKey: 'filingStates.tn' },
	{ id: 39, value: 'TX', title: 'TX', labelKey: 'filingStates.tx' },
	{ id: 40, value: 'UT', title: 'UT', labelKey: 'filingStates.ut' },
	{ id: 41, value: 'VT', title: 'VT', labelKey: 'filingStates.vt' },
	{ id: 42, value: 'VA', title: 'VA', labelKey: 'filingStates.va' },
	{ id: 43, value: 'WA', title: 'WA', labelKey: 'filingStates.wa' },
	{ id: 44, value: 'WI', title: 'WI', labelKey: 'filingStates.wi' },
	{ id: 45, value: 'WY', title: 'WY', labelKey: 'filingStates.wy' },
];

export const FilingCodeOptions: IDropdownOption[] = [
	{ id: 1, value: 'OwnerOperator', title: 'Owner-Operator', labelKey: 'filingCode.ownerOperator' },
	{ id: 2, value: 'Operator', title: 'Operator', labelKey: 'filingCode.operator' },
	{ id: 3, value: 'Owner', title: 'Owner', labelKey: 'filingCode.owner' },
	{ id: 4, value: 'Other', title: 'Other', labelKey: 'filingCode.other' },
];

export const DriverLicenseStatus: IDropdownOption[] = [
	{ id: 1, value: 'Valid', title: 'Valid', labelKey: 'driverLicenseStatus.valid' },
	{ id: 2, value: 'Permit', title: 'Permit', labelKey: 'driverLicenseStatus.permit' },
	{ id: 3, value: 'NotLicensed', title: 'Never been licensed', labelKey: 'driverLicenseStatus.neverBeenLicensed' },
	{ id: 4, value: 'NotLicensed', title: 'No longer driving', labelKey: 'driverLicenseStatus.noLongerDriving' },
	{ id: 5, value: 'Suspended', title: 'Suspended', labelKey: 'driverLicenseStatus.suspended' },
	{ id: 6, value: 'Cancelled', title: 'Canceled', labelKey: 'driverLicenseStatus.canceled' },
	{
		id: 7,
		value: 'PermanentlyRevoked',
		title: 'Permanently revoked',
		labelKey: 'driverLicenseStatus.permanentlyRevoked',
	},
	{ id: 8, value: 'Expired', title: 'Expired', labelKey: 'driverLicenseStatus.expired' },
];

export const TypeOfResidenceOptions: IDropdownOption[] = [
	{ id: 0, value: 'OwnHome', title: 'Own home', labelKey: 'typeOfResidence.ownHome' },
	{ id: 1, value: 'OwnCondo', title: 'Own condo', labelKey: 'typeOfResidence.ownCondo' },
	{ id: 2, value: 'RentHome', title: 'Rent home', labelKey: 'typeOfResidence.rentHome' },
	{ id: 3, value: 'RentCondo', title: 'Rent condo', labelKey: 'typeOfResidence.rentCondo' },
	{ id: 4, value: 'RentApartment', title: 'Rent apartment', labelKey: 'typeOfResidence.rentApartment' },
	{ id: 5, value: 'LiveWithParents', title: 'Live with parents', labelKey: 'typeOfResidence.liveWithParents' },
];

export const DriverEducationOptions: IDropdownOption[] = [
	{
		id: 1,
		value: 'NoHighSchoolDiploma',
		title: 'No high school diploma',
		labelKey: 'driverEducation.noHighSchoolDiploma',
	},
	{ id: 2, value: 'HighSchoolDiploma', title: 'High school diploma', labelKey: 'driverEducation.highSchoolDiploma' },
	{
		id: 3,
		value: 'SomeCollegeNoDegree',
		title: 'Some college - no degree',
		labelKey: 'driverEducation.someCollegeNoDegree',
	},
	{
		id: 4,
		value: 'VocationalTechnicalDegree',
		title: 'Vocational/technical degree',
		labelKey: 'driverEducation.vocationalTechnicalDegree',
	},
	{ id: 5, value: 'AssociatesDegree', title: 'Associates degree', labelKey: 'driverEducation.associatesDegree' },
	{ id: 6, value: 'Bachelors', title: 'Bachelor’s degree', labelKey: 'driverEducation.bachelors' },
	{ id: 7, value: 'Masters', title: 'Master’s degree', labelKey: 'driverEducation.masters' },
	{ id: 8, value: 'Phd', title: 'PhD', labelKey: 'driverEducation.phd' },
	{ id: 8, value: 'MedicalDegree', title: 'Medical degree', labelKey: 'driverEducation.medicalDegree' },
	{ id: 8, value: 'LawDegree', title: 'Law degree', labelKey: 'driverEducation.lawDegree' },
];

export const DriverRelationshipToDriver1Options: IDropdownOption[] = [
	{ id: 1, value: 'Spouse', title: 'Spouse', labelKey: 'driverRelationshipToDriver1.spouse' },
	{ id: 2, value: 'Parent', title: 'Parent', labelKey: 'driverRelationshipToDriver1.parent' },
	{ id: 3, value: 'Child', title: 'Child', labelKey: 'driverRelationshipToDriver1.child' },
	{
		id: 4,
		value: 'DomesticPartner',
		title: 'Domestic partner',
		labelKey: 'driverRelationshipToDriver1.domesticPartner',
	},
	{ id: 5, value: 'Relative', title: 'Relative', labelKey: 'driverRelationshipToDriver1.relative' },
	{ id: 6, value: 'Employee', title: 'Employee', labelKey: 'driverRelationshipToDriver1.employee' },
	{ id: 7, value: 'Other', title: 'Other', labelKey: 'driverRelationshipToDriver1.other' },
];

export const missingDriverSafecoOptions: IDropdownOption[] = [
	{
		id: 1,
		value: 'NoLongerInHousehold',
		title: 'No longer in household ',
		labelKey: 'missingDriverSafeco.noLongerInHousehold',
	},
	{
		id: 2,
		value: 'Unknown',
		title: 'Person never in household',
		labelKey: 'missingDriverSafeco.personNeverInHousehold',
	},
	{ id: 3, value: 'Deceased', title: 'Deceased', labelKey: 'missingDriverSafeco.deceased' },
];

export const missingDriverProgressiveOptions: IDropdownOption[] = [
	{ id: 1, value: 'Add', title: 'Add driver to quote', labelKey: 'missingDriverProgressive.addDriverToQuote' },
	{
		id: 2,
		value: 'NoLongerInHousehold',
		title: 'No longer in household ',
		labelKey: 'missingDriverProgressive.noLongerInHousehold',
	},
	{
		id: 3,
		value: 'AlreadyOnApplication',
		title: 'Already on application',
		labelKey: 'missingDriverProgressive.alreadyOnApplication',
	},
	{ id: 4, value: 'Unknown', title: 'Unknown to insured', labelKey: 'missingDriverProgressive.unknownToInsured' },
	{ id: 5, value: 'Deceased', title: 'Deceased', labelKey: 'missingDriverProgressive.deceased' },
];

export const PaymentPlanOptions: IDropdownOption[] = [
	{ id: 1, value: 'PayInFull', title: 'Pay In Full', labelKey: 'paymentPlan.payInFull' },
];

export const petSpeciesOptions: IDropdownOption[] = [
	{ id: 1, value: 'Dog', title: 'Dog', labelKey: 'petSpecies.dog' },
	{ id: 2, value: 'Cat', title: 'Cat', labelKey: 'petSpecies.cat' },
];

export const petBreedTypeOptions: IDropdownOption[] = [
	{ id: 1, value: 'Mixed', title: 'Mixed', labelKey: 'petBreedType.mixed' },
	{ id: 2, value: 'Pure', title: 'Pure', labelKey: 'petBreedType.pure' },
];

export const OriginalPartsOptions: IDropdownOption[] = [
	{ id: 1, value: true, title: 'Original manufacturer', labelKey: 'originalParts.originalManufacturer' },
	{ id: 2, value: false, title: 'Aftermarket', labelKey: 'originalParts.aftermarket' },
];

export const PriorBodilyInjuryLimit: IDropdownOption[] = [
	{ id: 1, value: '25/25', title: '25/25', labelKey: 'priorBodilyInjuryLimit.25/25' },
	{ id: 2, value: '30/60', title: '30/60', labelKey: 'priorBodilyInjuryLimit.30/60' },
];

export const PRG_PriorBodilyInjuryLimit: IDropdownOption[] = [
	{ id: 0, value: 'Dontknow', title: "Don't Know", labelKey: 'prgPriorBodilyInjuryLimit.dontKnow' },
	{ id: 1, value: 'TenToTwenty', title: '10/20', labelKey: 'prgPriorBodilyInjuryLimit.tenToTwenty' },
	{ id: 1, value: '30/60', title: '30000', labelKey: 'prgPriorBodilyInjuryLimit.30000' },
	{ id: 2, value: '30/60', title: '30/60', labelKey: 'prgPriorBodilyInjuryLimit.30/60' },
];

export const NumOfFamilies: IDropdownOption[] = [
	{ id: 0, value: 1, title: '1', labelKey: 'numOfFamilies.1' },
	{ id: 1, value: 2, title: '2', labelKey: 'numOfFamilies.2' },
	{ id: 2, value: 3, title: '3', labelKey: 'numOfFamilies.3' },
	{ id: 3, value: 4, title: '4', labelKey: 'numOfFamilies.4' },
];

export const PL_NumberCarSpace: IDropdownOption[] = [
	{ id: 0, value: 1, title: '1', labelKey: 'numOfCars.1' },
	{ id: 1, value: 2, title: '2', labelKey: 'numOfCars.2' },
	{ id: 2, value: 3, title: '3', labelKey: 'numOfCars.3' },
];

export const NumBathrooms: IDropdownOption[] = [
	{ id: 0, value: 1, title: '1', labelKey: 'numBathrooms.1' },
	{ id: 1, value: 2, title: '2', labelKey: 'numBathrooms.2' },
	{ id: 2, value: 3, title: '3', labelKey: 'numBathrooms.3' },
	{ id: 3, value: 4, title: '4', labelKey: 'numBathrooms.4' },
	{ id: 4, value: 5, title: 'more than 4', labelKey: 'numBathrooms.moreThan4' },
	// { id: 4, value: 5, title: '5' },
	// { id: 5, value: 6, title: '6' },
	// { id: 6, value: 7, title: '7' },
	// { id: 7, value: 8, title: '8' },
	// { id: 8, value: 9, title: '9' },
	// { id: 9, value: 10, title: '10' },
];

export const num0to10: IDropdownOption[] = [
	{ id: 0, value: 0, title: '0' },
	{ id: 1, value: 1, title: '1' },
	{ id: 2, value: 2, title: '2' },
	{ id: 3, value: 3, title: '3' },
	{ id: 4, value: 4, title: '4' },
	{ id: 5, value: 5, title: '5' },
	{ id: 6, value: 6, title: '6' },
	{ id: 7, value: 7, title: '7' },
	{ id: 8, value: 8, title: '8' },
	{ id: 9, value: 9, title: '9' },
	{ id: 10, value: 10, title: '10' },
];

export const BathroomConstructionQuality: IDropdownOption[] = [
	{ id: 0, value: "Builder's Grade", title: "Builder's Grade", labelKey: 'bathroomConstructionQuality.buildersGrade' },
	{ id: 1, value: 'Semi-Custom', title: 'Semi-Custom', labelKey: 'bathroomConstructionQuality.semiCustom' },
	{ id: 2, value: 'Custom', title: 'Custom', labelKey: 'bathroomConstructionQuality.custom' },
	{ id: 3, value: 'Designer', title: 'Designer', labelKey: 'bathroomConstructionQuality.designer' },
];

export const BathroomTypeOptions: IDropdownOption[] = [
	{ id: 0, value: 'Half', title: 'Half', labelKey: 'bathroomType.half' },
	{ id: 1, value: 'Full', title: 'Full', labelKey: 'bathroomType.full' },
];

export const DistanceToFireHydrant: IDropdownOption[] = [
	{ id: 0, value: '_0_500ft', title: '0-500 ft', labelKey: 'distanceToFireHydrant.0-500ft' },
	{ id: 1, value: '_501_1000ft', title: '501-1000 ft', labelKey: 'distanceToFireHydrant.501-1000ft' },
	{ id: 2, value: '_1001ftAndGreater', title: '1001 or greater', labelKey: 'distanceToFireHydrant.1001ftAndGreater' },
];

export const DistanceToFireStation: IDropdownOption[] = [
	{ id: 0, value: 'NotAvailable', title: 'Not Available', labelKey: 'distanceToFireStation.notAvailable' },
	{ id: 1, value: 'Lessthan5miles', title: 'Less than 5 miles', labelKey: 'distanceToFireStation.lessThan5Miles' },
	{ id: 2, value: 'Over5miles', title: 'More than 5 miles', labelKey: 'distanceToFireStation.moreThan5Miles' },
];

export const PRG_FQDriverRatingTypeOptions: IDropdownOption[] = [
	{ id: 0, value: 'Rated', title: 'Rated', labelKey: 'prgFQDriverRatingTypeOptions.rated' },
	{ id: 1, value: 'NonRated', title: 'Non Rated', labelKey: 'prgFQDriverRatingTypeOptions.nonRated' },
	{ id: 2, value: 'Excluded', title: 'Excluded', labelKey: 'prgFQDriverRatingTypeOptions.excluded' },
	{ id: 3, value: 'OutOfCountry', title: 'Out Of Country', labelKey: 'prgFQDriverRatingTypeOptions.outOfCountry' },
	{
		id: 4,
		value: 'DriverUnder21NeverLicensed',
		title: 'Driver Under 21 Never Licensed',
		labelKey: 'prgFQDriverRatingTypeOptions.driverUnder21NeverLicensed',
	},
	{
		id: 5,
		value: 'MilitaryDeployedOverseas',
		title: 'Military Deployed Overseas',
		labelKey: 'prgFQDriverRatingTypeOptions.militaryDeployedOverseas',
	},
];

export const valueToTitle = [
	...typeOfHomeOptions,
	...tankOilLocationOptions,
	...PriorCarrierHomeownersOptions,
	...personalLossesDescriptionOptions,
	...dwellingUsageOptions,
	...numOfStoriesOptions,
	...flooringMaterialOptions,
	...dogsBreedsSelectionOptions,
	...basementFinishOptions,
	...TypeOfFoundation,
	...constructionTypeOptions,
	...maritalStatusOptions,
	...PL_RelationshipToClientOptions,
	...SelectRelationshipToClientOptions,
	...mitWindowOpeningOptions,
	...PL_TypeFireplacesOptions,
	...PlumbingTypeOptions,
	...PLNumberOfStoriesOptions,
	...ArchitectureStyleOptions,
	...MitRoofShapeOptions,
	...PL_PrimaryCounterMaterialOptions,
	...RoofTypeOptions,
	...PL_FlooringMaterialOptions,
	...PLHeatingTypeOptions,
	...RoofUpdateYearRangeOptions,
	...PL_InteriorWallMaterial,
	...ExteriorWallsConstructionOptions,
	...PaymentPlanOptions,
	...DriverStateLicensed,
	...DriverEducationOptions,
];

export function getTitle(value): string {
	const option = valueToTitle.filter((item) => item.value === value)[0];
	return option ? option.title : null;
}
