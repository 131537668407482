import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { IAppState } from '../store/states/app.state';
import { selectInterviewMetadata } from '../store/selectors/interview-metadata.selector';
import { IInterviewMetadataState } from '../store/states/interview-metadata.state';
import { IServerLoggerReq } from 'src/entities/payload.interface';
import { ConfigService } from './config.service';
import { catchError, first, map, Observable, of, Subject, switchMap } from 'rxjs';
import { ThemeDataModel } from '../models/theme.model';
import { ActivatedRoute, Router } from '@angular/router';
import { setSecretSource, setSource } from '../store/actions/result-data.actions';
import { RoutingService } from './routing.service';
import { StepsEnum } from '../enums/steps.enum';
import { addDefaultIndication, getD2CFeatures } from '../utils/general.utils';
import { VehicleInfoModel } from '../models/vehicle-info.model';
import { IndustryApiResponse } from 'src/entities/industry-api-response.interface';
import { PetBreedModel } from '../models/pet-breed.model';
import { InitAppResponseModel } from '../models/init-app-response.model';
import { ValidateSourceModel } from '../models/validate-source-model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UpdateInterviewMetadata } from '../store/actions/interview-metadata.actions';
import { LobsEnum } from '../enums/lobs.enum';
import { ProgressMeterService } from './progress-meter.service';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { SetLanguageSettings } from '../store/actions/languages.actions';
import { SessionService } from './session.service';
import { DEFAULT_UI_LANGUAGE } from '../constants/languages';
import { selectLanguageSettings } from '../store/selectors/languages.selector';

const defaultOptions = {
	headers: {
		'Content-Type': 'application/json',
	} as any,
	withCredentials: false,
};

@UntilDestroy()
@Injectable()
export class ActionApiService {
	token: string;
	interviewMetadata: IInterviewMetadataState;
	applicationInit = '';
	baseApi: string;
	contentApiUrl: string;
	source: string;
	tenant: string;
	lobs: LobsEnum;

	excludedSourceNames = [StepsEnum.PAGE_NOT_FOUND, StepsEnum.ERROR, StepsEnum.SUCCESS_PAGE, StepsEnum.PAYMENT_FAILED];

	constructor(
		private httpClient: HttpClient,
		private store: Store<IAppState>,
		private configService: ConfigService,
		private router: Router,
		private route: ActivatedRoute,
		public routingService: RoutingService,
		private progressMeterService: ProgressMeterService,
		private loc: Location,
		private titleService: Title,
		private sessionService: SessionService
	) { }

	initApp() {
		this.baseApi = this.configService.getConfig().apiBaseUrl;
		this.contentApiUrl = this.configService.getConfig().contentApiUrl;
		this.store.select(selectInterviewMetadata).subscribe((data) => {
			this.token = data.token;
			this.interviewMetadata = data;
			this.applicationInit = data && data.applicationId ? data.applicationId : '';
		});
	}

	isQuoteUrlStructure() {
		var angularRoute = this.loc.path();
		var fullUrl = window.location.href;
		var domainAndApp = fullUrl.replace(angularRoute, '');
		var host = domainAndApp.replace('http://', '').replace('https://', '');
		if (host.includes('quote-')) {
			return host;
		} else {
			return false;
		}
	}

	retrieveTenantFromNewUrl(host) {
		var domainUrl = host.split('.')[0].split('-');
		var tenant = domainUrl[domainUrl.length - 1];
		sessionStorage.setItem('tenantFromUrl', tenant.toUpperCase());
		this.store.dispatch(setSecretSource({ data: window.location.pathname.split('/')[1] }));
		defaultOptions.headers['tenant'] = tenant.toUpperCase();
		sessionStorage.setItem('tenant', tenant.toUpperCase());
	}

	initApplication(lobs: string[], PropertyAddress): Observable<InitAppResponseModel> {
		this.sessionService.initSessionId();
		this.store.dispatch(UpdateInterviewMetadata({ data: { lobSelection: lobs } }));
		return this.post({ action: 'api/init', product: [...lobs], PropertyAddress }) as Observable<InitAppResponseModel>;
	}

	updateApplication(data, products?: string[], Carrier?: string) {
		let productsWithoutFlood: string[];
		if (products && products.length) {
			productsWithoutFlood = products.filter((lob) => lob !== LobsEnum.FLOOD);
		}

		const payload = {
			action: Carrier ? 'api/update-fq' : 'api/update',
			data: addDefaultIndication(data),
			applicationId: this.interviewMetadata.applicationId,
			...(products && { products }),
			...(Carrier &&
				productsWithoutFlood &&
				productsWithoutFlood.length && { Context: this.getContext(Carrier, productsWithoutFlood[0]) }),
		};

		this.store.dispatch(UpdateInterviewMetadata({ data: { FQSubmission: true, Submission: true } }));
		return this.patch(payload) as Observable<any>;
	}

	getApplicationDeeplink(appId: string): Observable<any> {
		return this.post({ action: 'api/get-deeplink', appId }) as Observable<any>;
	}

	submitQuote(data?) {
		return this.post({ action: 'api/submission', applicationId: this.interviewMetadata.applicationId, ...data });
	}

	singleSubmitQuote(Context) {
		return this.post({
			action: 'api/single-submission',
			applicationId: this.interviewMetadata.applicationId,
			...Context,
		});
	}

	carrierSelect(rateID) {
		return this.post({ action: 'api/select-carrier', applicationId: this.interviewMetadata.applicationId, rateID });
	}

	// returns possible lobs per source
	getLobs(source): Observable<any> {
		return this.post({ action: 'getLobs', source });
	}

	validateSourceApi(source, tenant, businessType): Observable<any> {
		return this.post({ action: 'validateSource', source, tenant, businessType });
	}

	getTenant(source: string): Observable<string> {
		return this.post({ action: 'getTenant', source });
	}

	extractTokenApi(token): Observable<any> {
		return this.post({ action: 'api/extractToken', token, defaultOptions });
	}

	getThemeBySourceName(sourceName: string): Observable<ThemeDataModel> {
		const tenant = sessionStorage.getItem('tenant');

		return tenant
			? this.getTheme$(tenant, sourceName)
			: this.getTenant(sourceName).pipe(switchMap((tenantName: string) => this.getTheme$(tenantName, sourceName)));
	}

	getFileSrc(fileName: string, sourceName: string): string {
		const tenant = sessionStorage.getItem('tenant');
		const version = sessionStorage.getItem('version');
		return `${this.contentApiUrl}Theme/get-file?tenant=${tenant}&sourceName=${sourceName}&fileName=${fileName}&version=${version}`;
	}

	getLogo(carrier: string, lob: string): string {
		const tenant = sessionStorage.getItem('tenant');
		return `${this.contentApiUrl}logos/GetCarrierByDisplayNameLogo?tenant=${tenant}&carrierDisplayName=${carrier}&lob=${lob}`;
	}

	resendEmail(token) {
		return this.post({ action: 'api/renewAndResendDeeplinkToEmailMethod', token });
	}

	getPaymentData(rateID, planID) {
		return this.post({
			action: `api/pay`,
			applicationId: this.interviewMetadata.applicationId,
			rateID,
			planID,
			successUrl: `${window.location.origin}/${this.source}/payment-success?`,
			failureUrl: `${window.location.origin}/${this.source}/payment-failed?`,
		});
	}

	submissionResults() {
		return this.post({ action: 'api/submissionResults', applicationId: this.interviewMetadata.applicationId });
	}

	preBind(carrierName) {
		return this.post({
			action: 'api/preBind',
			applicationId: this.interviewMetadata.applicationId,
			Carrier: carrierName,
			Product: 'PersonalAuto',
			Scope: 'Prebind',
		});
	}

	preBindResults(carrierName) {
		return this.post({
			action: 'api/preBindResults',
			applicationId: this.interviewMetadata.applicationId,
			Carrier: carrierName,
			Product: 'PersonalAuto',
			Type: 'Prebind',
		});
	}

	decodeVin(vin): Observable<VehicleInfoModel> {
		return this.get({ action: `api/vehicles/decode-vin?vin=${vin}` });
	}

	getMakePerYear(year) {
		return this.get({ action: `api/vehicles/make?year=${year}&source=${this.source}` });
	}

	getModelPerMakeYear(make, year) {
		return this.get({
			action: `api/vehicles/model?year=${year}&make=${encodeURIComponent(make)}&source=${this.source}`,
		});
	}

	getTransactionId(transactionId, source) {
		defaultOptions.headers['source'] = source;
		return this.get({
			action: `api/paymenttransaction/${transactionId}`,
		});
	}

	getBodyStylePerModelPerMakeYear(model, make, year) {
		return this.get({
			action: `api/vehicles/body-style?year=${year}&make=${encodeURIComponent(make)}&model=${encodeURIComponent(
				model
			)}&source=${this.source}`,
		});
	}

	postLog(data: IServerLoggerReq) {
		if (this.token) {
			const url = this.baseApi + 'log';
			defaultOptions.headers['Authorization'] = 'Bearer ' + this.token;
			return this.httpClient.post<void>(url, data, defaultOptions);
		} else {
			return null;
		}
	}

	getVehicleAndDrivers() {
		return this.post({
			action: 'api/findPrefillData',
			applicationId: this.interviewMetadata.applicationId,
			product: 'PersonalAuto',
		});
	}

	updateDriver(drivers) {
		return this.post({
			action: 'api/updateDriver',
			applicationId: this.interviewMetadata.applicationId,
			drivers,
		});
	}

	updateDriverFQ(drivers, Carrier?, product = 'PersonalAuto') {
		return this.post({
			action: 'api/updateDriver-fq',
			applicationId: this.interviewMetadata.applicationId,
			product: product,
			drivers,
			...(Carrier && { Context: this.getContext(Carrier, product) }),
		});
	}

	getVehicleAndDriversMock() {
		return this.post({
			action: 'api/findPrefillDataMock',
			applicationId: this.interviewMetadata.applicationId,
			product: 'PersonalAuto',
		});
	}

	getQuestions(data) {
		return this.post({
			action: 'api/getQuestions',
			applicationId: this.interviewMetadata.applicationId,
			carrier: data.carrier,
			product: data.product,
		});
	}

	clearQQDefaults(data) {
		return this.post({
			action: 'api/clearQQDefaults',
			applicationId: this.interviewMetadata.applicationId,
			carrier: data.carrier,
			product: data.product,
		});
	}

	getIndustriesAndOccupations() {
		return this.get({ action: 'api/occupations' }) as Observable<IndustryApiResponse>;
	}

	validateSource(source) {
		const tenant = sessionStorage.getItem('tenant');

		return new Promise<boolean>((resolve, reject) => {
			let tenantFromUrl = sessionStorage.getItem('tenantFromUrl');
			let businessType;
			if (tenantFromUrl) {
				let bussType = window.location.pathname.split('/')[1].split('-')[1];
				businessType = bussType == 'pl' || bussType == 'opl' ? 'Personal' : 'Commercial';
				defaultOptions.headers['business-type'] = businessType;
			}

			if (this.route && source) {
				this.validateSourceApi(source, tenant, businessType).subscribe((response: ValidateSourceModel) => {
					if (response.validateSourceResult) {
						resolve(true);
					} else {
						resolve(false);
					}
				});
			} else {
				reject(false);
			}
		});
	}

	initTenant(): Subject<any> {
		let Observable$ = new Subject();
		this.initApp();
		/*
		 * on the new url structure, the tenant is part of the url - https://quote-qa-boltag.boltqa.com/{{secretSource}}
		 * on the old url structure, the tenant is not part of the url - https://d2cinterview-qa.boltqa.com/{{source}}
		 * need to get it from getTenant api call
		 */
		let host = this.isQuoteUrlStructure();
		if (host) {
			this.retrieveTenantFromNewUrl(host);
			this.handleLeadSource(Observable$);
		} else {
			var url = new URL(location.href).searchParams;
			if (url.get('tenant') && url.get('type') && url.get('v')) {
				this.tenant = url.get('tenant').toUpperCase();
				defaultOptions.headers['tenant'] = this.tenant;
				const businessType = url.get('type') == 'pl' ? 'Personal' : 'Commercial';
				defaultOptions.headers['business-type'] = businessType;
				sessionStorage.setItem('businessType', businessType);
				sessionStorage.setItem('tenant', this.tenant);
				this.saveSource(window.location.pathname.split('/')[1]);
				this.handleLeadSource(Observable$, url.get('type'), url.get('v')).then(() => {
					let source = this.getSource();
					this.post({ action: 'getLeadSourceDefaultConsumer', source: source, tenant: this.tenant }).subscribe(
						(defaultConsumer) => {
							if (defaultConsumer) {
								sessionStorage.setItem('defaultConsumer', JSON.stringify(defaultConsumer));
								if (defaultConsumer.tenant) {
									defaultOptions.headers['tenant'] = defaultConsumer.tenant;
								}
								if (defaultConsumer.subTenant) {
									defaultOptions.headers['sub-tenant'] = defaultConsumer.subTenant;
								}
								if (defaultConsumer.userExternalSource && defaultConsumer.groupExternalSource) {
									defaultOptions.headers['user-external-source'] = defaultConsumer.userExternalSource;
									defaultOptions.headers['group-external-source'] = defaultConsumer.groupExternalSource;
								}
							}
						}
					);
				});
			} else {
				this.saveSource(window.location.pathname.split('/')[1]);
				let defaultConsumerFromSession = sessionStorage.getItem('defaultConsumer');
				let businessType = sessionStorage.getItem('businessType');

				if (businessType) {
					defaultOptions.headers['business-type'] = businessType;
				}
				if (defaultConsumerFromSession) {
					let defaultConsumer = JSON.parse(defaultConsumerFromSession);
					if (defaultConsumer.tenant) {
						this.tenant = defaultConsumer.tenant;
						defaultOptions.headers['tenant'] = defaultConsumer.tenant;
					}
					if (defaultConsumer.subTenant) {
						defaultOptions.headers['sub-tenant'] = defaultConsumer.subTenant;
					}
					if (defaultConsumer.userExternalSource && defaultConsumer.groupExternalSource) {
						defaultOptions.headers['user-external-source'] = defaultConsumer.userExternalSource;
						defaultOptions.headers['group-external-source'] = defaultConsumer.groupExternalSource;
					}
					this.handleLeadSource(Observable$);
				} else {
					this.saveSource(window.location.pathname.split('/')[1]);
					this.getTenant(this.AlternateSource(this.source)).subscribe((tenant) => {
						if (tenant) {
							this.tenant = tenant;
							defaultOptions.headers['tenant'] = tenant.toUpperCase();
							sessionStorage.setItem('tenant', tenant);
							this.handleLeadSource(Observable$);
						}
					});
				}
			}
		}
		return Observable$;
	}

	handleLeadSource(Observable$, businessType = undefined, journeyType = undefined) {
		return new Promise<void>((resolve) => {
			// Get lead-source
			var isFullConsumerFlow = false;
			var data;

			let tenantFromUrl = sessionStorage.getItem('tenantFromUrl');

			if (tenantFromUrl) {
				let secretName = window.location.pathname.split('/')[1].split('-')[0];
				var consumerFlow = window.location.pathname.split('/')[1].split('-')[1];
				isFullConsumerFlow = consumerFlow == 'ocl' || consumerFlow == 'opl';
				data = { action: 'GetLeadSourceBySecret', tenant: tenantFromUrl, secretName, isD2C: !isFullConsumerFlow };
			} else {
				data = { action: 'GetLeadSource', tenant: this.tenant || this.configService.getTenant() };
			}
			this.post(data).subscribe((res) => {
				if (res.success) {
					const leadSourceData = res;
					sessionStorage.setItem('leadSource', JSON.stringify(leadSourceData));

					if (!res.leadSource.isConsumer || !res.leadSource.isEnabled) {
						this.routingService.navigateToErrorPage(StepsEnum.NO_CONSUMER_FLOW);
						resolve();
						Observable$.next(this.source);
					} else {
						if (data.action == 'GetLeadSourceBySecret') {
							this.saveSource(res.leadSource.domainPrefix);
						}

						Observable$.next(this.source);
						this.store.dispatch(UpdateInterviewMetadata({ data: { leadSource: leadSourceData.leadSource } }));

						this.initLanguageSettings(getD2CFeatures()?.languageSettings);

						if (!this.source && leadSourceData.leadSource.domainPrefix) {
							this.updateSourceFromSecret(leadSourceData.leadSource.domainPrefix);
						}

						leadSourceData.journeys = leadSourceData.journeys.map((i) =>
							i.lobs == 'PersonalAuto,PersonalHome' ? { ...i, lobs: 'PersonalHome,PersonalAuto' } : i
						);

						let lobsInApatite = leadSourceData.journeys.map((x) => {
							return x.lobs;
						});

						if (!isFullConsumerFlow && lobsInApatite && lobsInApatite.length === 0) {
							//no lobs
							this.routingService.navigateToErrorPage(StepsEnum.CARRIER_KICKOUT);
							resolve();
						} else if (lobsInApatite && lobsInApatite.length == 1) {
							// there is only one possible lob
							this.store.dispatch(
								UpdateInterviewMetadata({
									data: {
										lobSelection: [lobsInApatite[0]],
										lobsInApatite,
										isAnonymousFlowEnabled: res.isAnonymousFlowEnabled,
									},
								})
							);
							this.progressMeterService.setFlowType(lobsInApatite[0]);
						} else if (lobsInApatite) {
							// if there is lobsInApatite set store else the initial value is empty array keep as is
							this.store.dispatch(
								UpdateInterviewMetadata({
									data: {
										lobsInApatite,
										isAnonymousFlowEnabled: res.isAnonymousFlowEnabled,
									},
								})
							);
						}
					}
				} else {
					Observable$.next(false);
				}

				resolve();
			});
		});
	}

	updateSourceFromSecret(sourceFromSecret) {
		sessionStorage.setItem('source', sourceFromSecret);
		this.source = sourceFromSecret;

		let title = this.titleService.getTitle();
		if (!title || title == 'undefined' || title == 'bolt') {
			this.titleService.setTitle(sourceFromSecret);
		}

		// adding source name to header
		defaultOptions.headers['source'] = this.source;
		if (this.excludedSourceNames.indexOf(sourceFromSecret.toLowerCase() as StepsEnum) === -1) {
			this.store.dispatch(setSource({ data: sourceFromSecret.toLowerCase() }));
		}

		this.store.select(selectInterviewMetadata).subscribe((data) => {
			this.token = data.token;
			this.interviewMetadata = data;
			this.applicationInit = data && data.applicationId ? data.applicationId : '';
		});
	}

	getPetBreed(species: string, type: string): Observable<Array<PetBreedModel>> {
		return this.get({ action: `api/pets/${species.toLowerCase()}/breed/?type=${type.toLowerCase()}` });
	}

	createQuoteNote(noteData) {
		const url = this.baseApi + 'createQuoteNote';
		return this.httpClient.post(url, noteData, defaultOptions).pipe(
			catchError((error) => {
				return of(error);
			})
		) as Observable<any>;
	}

	saveFlow(lobsInitialized) {
		if (!this.interviewMetadata?.applicationId || !lobsInitialized) {
			return;
		}

		this.getFlow(this.interviewMetadata.applicationId)
			.pipe(
				first(),
				map((res) => this.getParsedPolicyAttachmentData(res?.attachment))
			)
			.subscribe((parsedRes) => {
				// if lobs are the same no need to save
				if (parsedRes && lobsInitialized == JSON.stringify(parsedRes.lobsInitialized)) {
					return
				}

				return this.setPolicyAttachment(
					this.interviewMetadata.applicationId,
					JSON.stringify({
						...parsedRes,
						...this.getParsedPolicyAttachmentData(lobsInitialized),
					})
				);
			});
	}

	getFlow(applicationId) {
		return this.getPolicyAttachment(applicationId);
	}

	getPolicyAttachment(applicationId: string) {
		return this.post({
			action: 'quote/GetPolicyAttachment',
			applicationId,
		});
	}

	setPolicyAttachment(applicationId: string, policyAttachmentData: string) {
		return this.post({
			action: 'quote/InsertPolicyAttachment',
			applicationId,
			policyAttachmentData,
		}).subscribe(() => { });
	}

	patchPolicyAttachment(policyAttachmentData: any) {
		if (!this.interviewMetadata.applicationId) {
			return;
		}

		return this.getPolicyAttachment(this.interviewMetadata.applicationId)
			.pipe(
				first(),
				map((res) => this.getParsedPolicyAttachmentData(res?.attachment))
			)
			.subscribe((parsedRes) => {
				return this.setPolicyAttachment(
					this.interviewMetadata.applicationId,
					JSON.stringify({ ...parsedRes, ...policyAttachmentData })
				);
			});
	}

	saveSource(source) {
		this.source = source;
		sessionStorage.setItem('source', source);
		this.store.dispatch(setSource({ data: source.toLowerCase() }));
		source = this.AlternateSource(source);
		defaultOptions.headers['source'] = source;
	}

	getSource() {
		let source = sessionStorage.getItem('source');
		return this.AlternateSource(source);
	}

	AlternateSource(source) {
		switch (source) {
			case 'application':
				source = 'fastlaneconsumer'
			default:
				source = source;
		}
		return source;
	}

	retrieveQuote(formData) {
		return this.post({
			action: 'api/retrieveQuote',
			applicationId: this.interviewMetadata.applicationId,
			...formData,
		});
	}

	getContext(carrier, product) {
		return {
			Context: {
				Scope: 'FullQuote',
				carrier: carrier,
				product: product,
			},
		};
	}

	updateDefaultOptions() {
		if (sessionStorage.getItem('tenant')) {
			defaultOptions.headers['tenant'] = sessionStorage.getItem('tenant');
		}

		if (this.getSource()) {
			defaultOptions.headers['source'] = this.getSource();
		}

		if (sessionStorage.getItem('businessType')) {
			defaultOptions.headers['business-type'] = sessionStorage.getItem('businessType');
		}
	}

	post<T>(data: any) {
		const url = this.baseApi + data.action;
		this.updateDefaultOptions();
		return this.httpClient.post<T>(url, data, defaultOptions).pipe(
			catchError((error) => {
				return of(error);
			})
		);
	}

	patch<T>(data: any) {
		const url = this.baseApi + data.action;
		this.updateDefaultOptions();
		return this.httpClient.patch<T>(url, data, defaultOptions).pipe(
			catchError((error) => {
				return of(error);
			})
		);
	}

	get<T>(data: any) {
		const url = this.baseApi + data.action;
		this.updateDefaultOptions();
		return this.httpClient.get<T>(url, defaultOptions).pipe(
			catchError((error) => {
				return of(error);
			})
		);
	}

	getParsedPolicyAttachmentData(policyAttachmentData: string) {
		if (policyAttachmentData) {
			try {
				return JSON.parse(policyAttachmentData);
			} catch {
				this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
			}
		} else {
			return null;
		}
	}

	private initLanguageSettings(languageSettingsResponse) {
		this.store
			.select(selectLanguageSettings)
			.pipe(
				first(),
				map((languageSettings) => languageSettings.selected)
			)
			.subscribe((selected) => {
				const languageSettings = {
					defaultLanguage: languageSettingsResponse?.defaultLanguage?.code || DEFAULT_UI_LANGUAGE,
					selected: selected || languageSettingsResponse?.defaultLanguage?.code || DEFAULT_UI_LANGUAGE,
					list: languageSettingsResponse?.availableLanguages || [],
				};

				this.store.dispatch(SetLanguageSettings(languageSettings));
			});
	}

	private getTheme$(tenant: string, sourceName: string): Observable<ThemeDataModel> {
		return this.httpClient.get<ThemeDataModel>(
			`${this.contentApiUrl}Theme/get-theme?tenant=${tenant}&sourceName=${sourceName}`
		) as Observable<ThemeDataModel>;
	}
}
