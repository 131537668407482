<section class="main-content bottom-space">
	<app-page-heading
		[subtitleKey]="isNoDiscoverVehiclesFound ? 'primaryVehicle.subTitle' : 'primaryVehicle.subTitleWithInfo'"
		[titleKey]="isNoDiscoverVehiclesFound ? 'primaryVehicle.title' : 'primaryVehicle.titleWithInfo'"
	></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="wrapper">
			<ng-container *ngIf="!isNoVehiclesFound()">
				<ng-container *ngFor="let vehicle of getActiveFields(vehiclesFormControl); let i = index">
					<app-vehicle
						[allVins]="allVins"
						[cantAdd]="cantAdd()"
						[formGroup]="vehicle"
						source="PersonalVehicles"
					></app-vehicle>
				</ng-container>
				<ng-container *ngFor="let vehicle of getActiveFields(discoverVehiclesFormControl); let i = index">
					<app-vehicle
						[allVins]="allVins"
						[cantAdd]="cantAdd()"
						[formGroup]="vehicle"
						source="discoverVehicles"
					></app-vehicle>
				</ng-container>

				<ng-container *ngFor="let vehicle of getActiveFields(this.manualVehiclesFormControl)">
					<app-vehicle
						[allVins]="allVins"
						[cantAdd]="cantAdd()"
						[formGroup]="vehicle"
						source="manualVehicles"
					></app-vehicle>
				</ng-container>
			</ng-container>

			<div *ngIf="isNoVehiclesFound()" class="accordion-block">
				<app-add-edit-vehicle
					[data]="{
						SequenceNum: SequenceNum
					}"
					[isNoVehicleFound]="true"
				>
				</app-add-edit-vehicle>
			</div>
		</div>
	</form>

	<div *ngIf="!isNoVehiclesFound()" class="add-car">
		<app-button
			(clicked)="addVehicle()"
			[disabled]="cantAdd()"
			materialIcon="add"
			severity="bordered"
			[title]="'buttons.addAnotherCar' | translate"
		></app-button>
		<div *ngIf="cantAdd()" class="error-cant-add">
			{{ "titles.fourCarsLimit" | translate }}
		</div>
	</div>
	<div [class.hidden]="isNoVehiclesFound()" class="next-button-wrapper">
		<next-button
			(clicked)="handleContinue()"
			(validationFailed)="continue()"
			[continue]="true"
			[disabled]="!checkValid() || !isAtLeastOneVehicleEnabled()"
			[myForm]="myform"
			[title]="'buttons.continue' | translate"
		></next-button>
		<span [ngClass]="{ show: isLoading }" class="loader show"></span>
	</div>
</section>
