import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionApiService } from '../../../../../../../../services/action-api.service';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { selectQuoteData } from '../../../../../../../../store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';
import { BasePageComponent } from '../../../../../base-page.component';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import {
	PrimaryUseOfVehicleOptionsProgressive,
	WasTheCarNewOptions,
} from '../../../../../../../../constants/vehicle-options.data';
import { Validations } from '../../../../../../../../utils/validation';
import { NextButtonComponent } from '../../../../../../../next-button/next-button.component';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { IInterviewMetadataState } from '../../../../../../../../store/states/interview-metadata.state';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { handleIncompleteAddressInAddressForm, sortByProperty } from 'src/app/utils/general.utils';

@Component({
	selector: 'app-progressive-vehicles',
	templateUrl: './progressive-vehicles.component.html',
	styleUrls: ['./progressive-vehicles.component.scss'],
})
export class ProgressiveVehiclesComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;
	preBindProcess: boolean;
	WasTheCarNewOptions = WasTheCarNewOptions;
	PrimaryUseOfVehicleOptionsProgressive = PrimaryUseOfVehicleOptionsProgressive;
	internalStep = 0;
	actionApiService: ActionApiService;
	vehicles = [];
	vehiclesFormArray = new FormArray([]);
	effectiveDate: string;
	interviewMetadata$: Observable<IInterviewMetadataState>;
	showPreBindLoader = false;
	dynamicallyAddedValidators = {
		CostNewValue: [Validators.required, Validations.costNewValueMoreThan0],
		MilesToWork: [Validators.required, Validations.fieldMoreThan0],
	};
	isNextDisabled = true;

	address = false;
	fieldsNames = {
		AddressLine1: 'FQLienholderAddress.AddressLine1',
		AddressLine2: 'FQLienholderAddress.AddressLine2',
		City: 'FQLienholderAddress.City',
		State: 'FQLienholderAddress.State',
		ZipCode: 'FQLienholderAddress.ZipCode',
		County: 'FQLienholderAddress.County',
	};
	fieldsNames2 = {
		AddressLine1: 'PersonalVehicleGarageAddress.AddressLine1',
		AddressLine2: 'PersonalVehicleGarageAddress.AddressLine2',
		City: 'PersonalVehicleGarageAddress.City',
		State: 'PersonalVehicleGarageAddress.State',
		ZipCode: 'PersonalVehicleGarageAddress.ZipCode',
		County: 'PersonalVehicleGarageAddress.County',
	};

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		private location: Location,
		protected store: Store<IAppState>,
		private formattedDateService: FormattedDateService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form);

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				this.effectiveDate = quoteData.EffectiveDate;
				this.vehicles = [...quoteData.PersonalVehicles]; //TODO: when primary vehicle need to change quoteData.PersonalVehicles ownership Type should be unchanged

				if (quoteData.discoverVehicles) {
					this.vehicles = [...this.vehicles, ...quoteData.discoverVehicles];
				}

				if (quoteData.manualVehicles) {
					this.vehicles = [...this.vehicles, ...quoteData.manualVehicles];
				}

				this.vehicles = sortByProperty(this.vehicles, 'SequenceNum');

				const additionalQuestionsProgressiveVehicles =
					quoteData.additionalQuestionsProgressiveVehicles && quoteData.additionalQuestionsProgressiveVehicles.length
						? quoteData.additionalQuestionsProgressiveVehicles
						: [];

				this.vehicles
					.filter((i) => i.SequenceNum > -1)
					.forEach((vehicle) => {
						const additionalQuestionsProgressiveVehiclesItem =
							additionalQuestionsProgressiveVehicles.find((item) => item.Id === vehicle.Id) || {};
						const formGroupItem = this.createFormGroup(additionalQuestionsProgressiveVehiclesItem, vehicle);
						this.vehiclesFormArray.push(formGroupItem);

						this.additionalQuestionsProgressiveVehicles().push(formGroupItem);

						this.registerChange(formGroupItem);
					});
			});

		this.interviewMetadata$ = this.store.select(selectInterviewMetadata);

		this.interviewMetadata$.pipe(untilDestroyed(this)).subscribe((data) => {
			this.preBindProcess = data.preBindProcess;
		});

		this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
			if (this.getActiveFields()[this.internalStep]) {
				this.isNextDisabled = !this.getActiveFields()[this.internalStep].valid;
			}
		});
		this.isNextDisabled = !this.getActiveFields()[this.internalStep].valid;
	}

	initForm(): void {
		this.form = new FormGroup({
			additionalQuestionsProgressiveVehicles: new FormArray([]),
		});
	}

	additionalQuestionsProgressiveVehicles(): FormArray {
		return this.form?.controls.additionalQuestionsProgressiveVehicles as FormArray;
	}

	createFormGroup(itemFormValue, vehicle) {
		let form = this.formBuilder.group({
			[this.Fields.Id.name]: [vehicle.Id],
			[this.Fields.SequenceNum.name]: [vehicle.enabled ? vehicle.SequenceNum : -1],
			[this.Fields.DateVehiclePurchased.name]: [
				{
					value:
						this.formattedDateService.formatDateIfItIsNotMasked(itemFormValue.DateVehiclePurchased) ||
						this.formattedDateService.formatDateIfItIsNotMasked(vehicle.DateVehiclePurchased),
					disabled: false,
				},
				[Validators.required, Validations.dateFormat, Validations.isDateAfterEffectiveDate(this.effectiveDate)],
			],
			[this.Fields.VIN.name]: [
				{ value: itemFormValue?.VIN || vehicle.VIN, disabled: false },
				[Validators.required, Validations.alphabetAndDigits],
			],
			[this.Fields.ExternalVINPrefix.name]: [
				{
					value: itemFormValue?.ExternalVINPrefix || vehicle.ExternalVINPrefix,
					disabled: vehicle.VIN && vehicle.ExternalVINPrefix,
				},
			],
			[this.Fields.Enabled.name]: [{ value: itemFormValue?.enabled || vehicle.enabled, disabled: false }],
			[this.Fields.PLYear.name]: [{ value: itemFormValue?.PLYear || vehicle.PLYear, disabled: false }],
			[this.Fields.PLMake.name]: [{ value: itemFormValue?.PLMake || vehicle.PLMake, disabled: false }],
			[this.Fields.PLModel.name]: [{ value: itemFormValue?.PLModel || vehicle.PLModel, disabled: false }],
			[this.Fields.BodyStyle.name]: [{ value: itemFormValue?.BodyStyle || vehicle.BodyStyle, disabled: false }],
			[this.Fields.PrimaryUseOfVehicle.name]: [
				{ value: itemFormValue.PrimaryUseOfVehicle || vehicle.PrimaryUseOfVehicle || null, disabled: false },
				[Validators.required],
			],
			[this.Fields.MilesToWorkPGR.name]: [
				{
					value: itemFormValue[this.Fields.MilesToWorkPGR.name] || null,
					disabled: !(
						itemFormValue.PrimaryUseOfVehicle == 'To_FromWork' || itemFormValue.PrimaryUseOfVehicle == 'To_FromSchool'
					),
				},
				[Validators.required],
			],
			[this.Fields.NumDaysDrivenPerWeek.name]: [
				{
					value: itemFormValue[this.Fields.NumDaysDrivenPerWeek.name] || null,
					disabled: !(
						itemFormValue.PrimaryUseOfVehicle == 'To_FromWork' || itemFormValue.PrimaryUseOfVehicle == 'To_FromSchool'
					),
				},
				[Validators.required, Validations.validDayWeek],
			],

			[this.Fields.VehicleUseForRideShareInd.name]: [
				{
					value: this.getValueForField(itemFormValue, vehicle, this.Fields.VehicleUseForRideShareInd.name),
					disabled: false,
				},
				[Validators.required],
			],
			[this.Fields.VehDeliveryUseInd.name]: [
				{
					value: this.getValueForField(itemFormValue, vehicle, this.Fields.VehDeliveryUseInd.name),
					disabled: false,
				},
				[Validators.required],
			],

			[this.Fields.BlindSpotWarningInd.name]: [
				{
					value: this.getValueForField(itemFormValue, vehicle, this.Fields.BlindSpotWarningInd.name),
					disabled: false,
				},
				[Validators.required],
			],

			[this.Fields.AnyModifications.name]: [
				{
					value: this.getValueForField(itemFormValue, vehicle, this.Fields.AnyModifications.name),
					disabled: false,
				},
				[Validators.required],
			],

			[this.Fields.ModificationsValue.name]: [
				{
					value: itemFormValue[this.Fields.ModificationsValue.name] || null,
					disabled: !this.getValueForField(itemFormValue, vehicle, this.Fields.AnyModifications.name),
				},
				[Validators.required, Validators.maxLength(9)],
			],

			[this.Fields.GarageAddressDifferent.name]: [
				{
					value: this.getValueForField(itemFormValue, vehicle, this.Fields.GarageAddressDifferent.name),
					disabled: false,
				},
				[Validators.required],
			],

			[this.Fields.PersonalVehicleGarageAddress.name]: this.formBuilder.group({
				[this.Fields.AddressLine1.name]: [
					{
						value: itemFormValue?.PersonalVehicleGarageAddress?.AddressLine1 || null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(50), Validations.isPoBox],
				],
				[this.Fields.AddressLine2.name]: [
					{ value: itemFormValue.PersonalVehicleGarageAddress?.AddressLine2 || null, disabled: false },
					[Validators.maxLength(50)],
				],
				[this.Fields.City.name]: [
					{
						value: itemFormValue.PersonalVehicleGarageAddress?.City || null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(25)],
				],
				[this.Fields.State.name]: [
					{
						value: itemFormValue.PersonalVehicleGarageAddress?.State || null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(2)],
				],
				[this.Fields.ZipCode.name]: [
					{
						value: itemFormValue.PersonalVehicleGarageAddress?.ZipCode || null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(5), Validations.zipStrictLength(5)],
				],
			}),

			[this.Fields.LoanLeaseAmount.name]: [
				{
					value: itemFormValue.FQLoanLeaseAmount || null,
					disabled: !(vehicle.OwnershipType == 'Leased' || vehicle.OwnershipType == 'Lien'),
				},
				[Validators.required],
			],

			[this.Fields.LoanLeaseName.name]: [
				{
					value: itemFormValue.FQLienholderName || null,
					disabled: !(vehicle.OwnershipType == 'Leased' || vehicle.OwnershipType == 'Lien'),
				},
				[Validators.required],
			],

			[this.Fields.FQLienholderAddress.name]: this.formBuilder.group({
				[this.Fields.AddressLine1.name]: [
					{
						value: itemFormValue?.FQLienholderAddress?.AddressLine1 || null,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(50), Validations.isPoBox],
				],
				[this.Fields.AddressLine2.name]: [
					{ value: itemFormValue.FQLienholderAddress?.AddressLine2, disabled: false },
					[Validators.maxLength(50)],
				],
				[this.Fields.City.name]: [
					{
						value: itemFormValue.FQLienholderAddress?.City,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(25)],
				],
				[this.Fields.State.name]: [
					{
						value: itemFormValue.FQLienholderAddress?.State,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(2)],
				],
				[this.Fields.ZipCode.name]: [
					{
						value: itemFormValue.FQLienholderAddress?.ZipCode,
						disabled: false,
					},
					[Validators.required, Validators.maxLength(5), Validations.zipStrictLength(5)],
				],
			}),
		});
		if (vehicle.ExternalVINPrefix) {
			form.controls[this.Fields.VIN.name].addValidators(Validations.ValidateVinPrefix(vehicle.ExternalVINPrefix));
		}

		form.controls[this.Fields.PrimaryUseOfVehicle.name].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value == 'To_FromWork' || value == 'To_FromSchool') {
				form.controls[this.Fields.NumDaysDrivenPerWeek.name].enable();
				form.controls[this.Fields.MilesToWorkPGR.name].enable();
			} else {
				form.controls[this.Fields.NumDaysDrivenPerWeek.name].disable();
				form.controls[this.Fields.MilesToWorkPGR.name].disable();
			}
		});

		form.controls[this.Fields.AnyModifications.name].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			value
				? form.controls[this.Fields.ModificationsValue.name].enable()
				: form.controls[this.Fields.ModificationsValue.name].disable();
		});
		let handleGarageAddressChange = (value) => {
			value
				? form.controls[this.Fields.PersonalVehicleGarageAddress.name].enable()
				: form.controls[this.Fields.PersonalVehicleGarageAddress.name].disable();
		};
		form.controls[this.Fields.GarageAddressDifferent.name].valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value) => {
				handleGarageAddressChange(value);
			});
		handleGarageAddressChange(form.controls[this.Fields.GarageAddressDifferent.name].value);

		if (vehicle.OwnershipType == 'Lien' || vehicle.OwnershipType == 'Leased') {
			form.controls[this.Fields.FQLienholderAddress.name].enable();
		} else {
			form.controls[this.Fields.FQLienholderAddress.name].disable();
		}
		// async validator added with a delay, in order to prevent multiple endpoint calls
		setTimeout(() => {
			if (vehicle.enabled) {
				form.controls[this.Fields.VIN.name].addAsyncValidators([Validations.validateVINAsync(this.actionService)]);
			}
		});

		return form;
	}

	getValueForField(itemFormValue, vehicle, nameField) {
		return typeof itemFormValue[nameField] == 'boolean'
			? itemFormValue[nameField]
			: typeof vehicle[nameField] == 'boolean'
			? vehicle[nameField]
			: null;
	}

	getActiveFields() {
		return this.additionalQuestionsProgressiveVehicles()?.controls?.filter(
			(control) => control.value.SequenceNum !== -1
		) as Array<FormGroup>;
	}

	handleContinue() {
		if (!this.isLastInternalStep()) {
			this.nextButtonComponent.loading = false;
			this.navigateInternal(true);
		} else {
			const PersonalVehicles = this.additionalQuestionsProgressiveVehicles().value.map(
				({ enabled, ...vehicleDataItem }) => ({ ...vehicleDataItem })
			);

			this.actionApiService
				.updateApplication({ PersonalVehicles }, [LobsEnum.PERSONAL_AUTO], CarriersEnum.Progressive)
				.subscribe((serverRes) => {
					this.onUpdateApplicationResult(serverRes, this.getActiveFields())
						.pipe(first())
						.subscribe((invalidReproducibleIndex) => {
							if (this.quoteDataService.hasErrorsFromServerForThisPage.value) {
								this.navigateInternalByIndex(invalidReproducibleIndex);
								this.handleValidation(serverRes.validationErrors);
							}
						});
				});
		}
	}

	setServerErrors(serverRes: any) {
		this.setReproducibleErrors(serverRes, this.getActiveFields());
	}

	isLastInternalStep() {
		return this.internalStep === this.getActiveFields().length - 1;
	}

	navigateInternal(forward: boolean) {
		this.internalStep += 1 * (forward ? 1 : -1);
		this.isNextDisabled = !this.getActiveFields()[this.internalStep].valid;
	}

	navigateInternalByIndex(index: number) {
		this.internalStep = index;
		this.isNextDisabled = !this.getActiveFields()[this.internalStep].valid;
	}

	getProgressbarWidth(): string {
		return ((this.internalStep + 1) / this.getActiveFields().length) * 100 + '%';
	}

	onWasTheCarNewChange(formGroupItem, value) {
		if (!this.isCostNewValueDisabled(value)) {
			formGroupItem.controls.CostNewValue.enable();
			formGroupItem.controls.CostNewValue.addValidators(this.dynamicallyAddedValidators.CostNewValue);
		} else {
			formGroupItem.controls.CostNewValue.disable();
			formGroupItem.controls.CostNewValue.removeValidators(this.dynamicallyAddedValidators.CostNewValue);
		}

		formGroupItem.controls.CostNewValue.updateValueAndValidity();
	}

	isCostNewValueDisabled(wasTheCarNew: boolean): boolean {
		return !wasTheCarNew;
	}

	goBack() {
		if (this.internalStep > 0) {
			this.navigateInternal(false);
		} else {
			this.location.back();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-progressive-vehicles',
			module: 'progressive-vehicles-module',
		});
	}

	handleAddressChange(vehicleForm: FormGroup) {
		this.address = true;
		handleIncompleteAddressInAddressForm(vehicleForm.get('PersonalVehicleGarageAddress') as FormGroup);
	}

	private registerChange(form: FormGroup): void {
		// after any change mark as not new
		form.valueChanges.pipe(first()).subscribe(() => {
			form.patchValue({ newListing: false });
		});
	}
}
