import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Fields } from 'src/app/constants/fields';
import { BasePageComponent } from '../../base-page.component';
import { Validations } from 'src/app/utils/validation';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { selectPLYearBuilt } from 'src/app/store/selectors/quote-data.selectors';

import { selectTypeProperty } from 'src/app/store/selectors/interview-metadata.selector';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { LobService } from 'src/app/services/lob.service';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { StepsEnum } from '../../../../../enums/steps.enum';

@Component({
	selector: 'app-house-details',
	templateUrl: './house-details.component.html',
	styleUrls: ['./house-details.component.scss'],
})
export class HouseDetailsComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
	lob = '';
	LobsEnum = LobsEnum;
	originYearMinus15: boolean;
	currentYear: number;
	typeProperty = '';
	noInfoFound = false;
	titleKey: string;
	subtitleKey: string;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private lobService: LobService
	) {
		super(injector, store, quoteDataService);
		this.currentYear = new Date().getFullYear();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	ngOnInit(): void {
		this.initForm();
		super.ngOnInit(StepsEnum.HOUSE_DETAILS);
		this.patchData(this.form);
		this.registerOnChange(this.form);

		this.noInfoFound = Object.keys(this.form.controls).every((key) => this.form.controls[key].value === null);

		this.store
			.select(selectPLYearBuilt)
			.pipe(first())
			.subscribe((data) => {
				this.originYearMinus15 = this.currentYear - data <= 15;
			});

		this.lobService.getLob$().subscribe((lob) => {
			if (lob) {
				this.lob = lob;
			}
		});

		this.setHeading();
	}

	setHeading(): void {
		this.titleKey = !this.noInfoFound ? 'house-details.titleWithInfo' : 'house-details.title';
		this.subtitleKey = !this.noInfoFound ? 'house-details.subTitleWithInfo' : 'house-details.subTitle';
	}

	initForm(): void {
		this.form = new FormGroup({
			[Fields.SquareFootage.name]: new FormControl(
				{
					value: null,
					disabled: false,
				},
				[Validations.SquareFootageMustBetween0, Validators.required, Validators.maxLength(30)]
			),
			[Fields.YearBuilt.name]: new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validators.maxLength(4),
				Validations.minNumber(1800),
				Validations.yearBuiltFuture,
			]),
		});

		this.store
			.select(selectTypeProperty)
			.pipe(first())
			.subscribe((typeProperty) => {
				this.typeProperty = typeProperty;
				if (typeProperty !== LobsEnum.CONDOMINIUM) {
					this.form.addControl(
						Fields.PLTypeOfDwelling.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);

					this.form.addControl(
						Fields.ArchitectureStyle.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);

					this.form.addControl(
						Fields.RoofType.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);

					this.form.addControl(
						Fields.MitRoofShape.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);

					this.form.addControl(
						Fields.TypeOfFoundation.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);

					this.form.addControl(
						Fields.PLNumberOfStories.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);

					this.form.addControl(
						Fields.PLConstructionType.name,
						new FormControl({ value: null, disabled: false }, [Validators.required])
					);
				}

				if (typeProperty == LobsEnum.CONDOMINIUM) {
					this.form.addControl(
						Fields.PLNumberOfUnits.name,
						new FormControl({ value: null, disabled: false }, [Validators.required, Validations.unitsMustBetween1to99])
					);
				}
			});
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}
}
