import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export const SessionIdKey = 'Session-Id';

@Injectable({ providedIn: 'root' })
export class SessionService {
	initSessionId(): void {
		const sessionId: string = uuidv4();

		sessionStorage.setItem(SessionIdKey, sessionId);
	}

	getSessionId(): string {
		return sessionStorage.getItem(SessionIdKey);
	}
}
