import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { DiscountsComponent } from './discounts.component';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
	declarations: [DiscountsComponent],
	exports: [DiscountsComponent],
	imports: [CommonModule, DirectivesModule, TranslateModule],
})
export class DiscountsModule {}
