import { AfterViewInit, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BasePageComponent } from "../../base-page.component";
import { Fields } from "../../../../../constants/fields";

import { QuoteDataService } from "src/app/services/quote-data.service";

import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { selectOriginTypeOfDwelling } from "src/app/store/selectors/result-data.selectors";
import { PLDwellingMap } from "src/app/utils/general.utils";
import { IDropdownOption } from "../../../../form-controls/form-control-interfaces";

@Component({
  templateUrl: "./properties.component.html",
  styleUrls: ["./properties.component.scss"]
})
export class PropertiesComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
  typeOfHouse: IDropdownOption;

  constructor(
    injector: Injector,
    private cdRef: ChangeDetectorRef,
    protected quoteDataService: QuoteDataService,
    protected store: Store<IAppState>
  ) {
    super(injector, store, quoteDataService);
  }

  ngAfterViewInit(): void {
    this.baseNgAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.patchData(this.form);
    this.registerOnChange(this.form);
    this.initChoose();
  }

  initForm(): void {
    this.form = new FormGroup({}, null, null);
    this.form.addControl(Fields.PLTypeOfDwelling.name, new FormControl({ value: null, disabled: true }, [Validators.required]));

  }

  getOptions() {
    let value = this.form.controls[Fields.PLTypeOfDwelling.name].value;

    let objIndex = this.FieldOptions.typeOfHomeOptions.findIndex((obj => obj.value === value));
    for (let i = 0; i < this.FieldOptions.typeOfHomeOptions.length; i++) {
      if (i === objIndex) {

        this.FieldOptions.typeOfHomeOptions[i].checked = true;
      } else {
        this.FieldOptions.typeOfHomeOptions[i].checked = false;
      }
    }

    return this.FieldOptions.typeOfHomeOptions;
  }

  initChoose() {
    this.store.select(selectOriginTypeOfDwelling).subscribe((data) => {
      let typeHouse = this.FieldOptions.typeOfHomeOptions.filter((item => item.value == PLDwellingMap(data)));
      if (typeHouse.length > 0) {
        this.typeOfHouse = typeHouse[0];
      }
    });

  }


}
