import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { RoutingService } from '../services/routing.service';
import { StepsEnum } from '../enums/steps.enum';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private readonly _router: Router, private routingService: RoutingService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap({
				error: (httpError: HttpErrorResponse) => {
					const serverError = 500;

					if (httpError.status >= serverError) {
						this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
					}
				},
			})
		);
	}
}
