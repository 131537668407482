<section class="main-content">
	<ng-container *ngIf="data && data.length">
		<ng-container *ngIf="!isEscrowFlow">
			<app-page-heading subtitleKey="subtitlepayment.subTitle" titleKey="payment.title"></app-page-heading>
			<div class="payment-type-toggle">
				<app-dynamic-toggle
					(parentFun)="handleTogglePaymentType($event)"
					[defualt]="'EFT'"
					[mapping]="PaymentPlansEnum"
					[options]="PaymentPlanTypes"
				>
				</app-dynamic-toggle>
			</div>

			<ul class="payment-plan-wrapper">
				<li
					(click)="handleSelectPaymentPlan(item)"
					*ngFor="let item of data; trackBy: id; let index = index"
					[class.selected]="item.selected"
					[ngClass]="{ display: item.type !== this.selectedPaymentType }"
					class="payment-plan-single"
				>
					<div class="header">
						<!-- Hidden radio button for accessibility purposes -->
						<p [attr.id]="'payment-plan-' + index">
							{{
								item.type === "Escrow"
									? ("labels.fullPayWithMortgage" | translate)
									: (item.firstPaymentAmount / item.totalAmount) * 100 === 100
									? ("labels.payInFull" | translate)
									: ("labels.downPayment" | translate)
							}}
							{{
								item.numberOfPayments - 1 > 1
									? "+ " + (item.numberOfPayments - 1).toString() + " " + ("labels.monthlyInstallments" | translate)
									: ""
							}}
							{{ item.numberOfPayments - 1 === 1 ? "+ 1 " + ("labels.installment" | translate) : "" }}
						</p>
						<input
							[attr.aria-labelledby]="'payment-plan-' + index"
							[attr.checked]="!!item.selected ? true : null"
							[attr.tabindex]="index === 0 ? 0 : null"
							class="hidden-radio"
							name="payment-plan"
							type="radio"
						/>
						<div class="circle">
							<div class="inner"></div>
						</div>
					</div>

					<div class="payment-plan-body">
						<div class="body">
							<div class="paynow first">
								<span>{{ "labels.payNow" | translate }}</span>
								<span class="price">{{ item.firstPaymentAmount | zeroOrTwoDigitsFraction }}</span>
							</div>
							<div class="totalamount">
								<span>{{ "labels.totalAmount" | translate }}</span>
								<span class="price">{{ item.totalAmount | zeroOrTwoDigitsFraction }}</span>
							</div>
						</div>

						<div [@collapse]="item.selected" class="footer">
							<ul class="description">
								<li>{{ item.description }}</li>
							</ul>
							<ul class="payments">
								<li>
									{{ item.numberOfPayments }}
									{{
										item.numberOfPayments > 1
											? ("labels.payments" | translate | uppercase)
											: ("labels.payment" | translate | uppercase)
									}}
								</li>
								<li>
									{{ item.totalPremium | zeroOrTwoDigitsFraction }} {{ " " }}
									{{ "labels.totalPremium" | translate | uppercase }}
								</li>
								<li>
									{{ item.paymentInvoices[0].taxes | currency: "USD":"symbol":"1.0-0" }} {{ " " }}
									{{ "labels.taxes" | translate | uppercase }}
								</li>
								<li>
									{{ (item.totalFees ? item.totalFees : 0) | currency: "USD":"symbol":"1.0-0" }} {{ " " }}
									{{ "labels.fees" | translate | uppercase }}
								</li>
								<li *ngIf="item.message">{{ item.message }}</li>
							</ul>
						</div>
					</div>
				</li>
			</ul>
			<div class="next-button-payment-plan">
				<next-button
					[disabled]="!isPaymentPlanSelected()"
					[title]="'buttons.continueToPayment' | translate"
				></next-button>
			</div>
		</ng-container>

		<ng-container *ngIf="isEscrowFlow">
			<app-payment-plan-escrow></app-payment-plan-escrow>
		</ng-container>
	</ng-container>
</section>
