import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnInit,
	Output,
	Renderer2,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { IError } from './form-control-interfaces';
import { RoutingService } from 'src/app/services/routing.service';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { IAppState } from 'src/app/store/states/app.state';
import { isMobile, isUndefined } from 'src/app/utils/general.utils';
import { ControlsType } from 'src/app/constants/controls-type';
import { EventActions, EventData } from '../../models/event-data.model';
import { GtmControlsActions } from '../../models/gtm-controls-actions.enum';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'app-base-form-control',
	template: '',
})
export class BaseFormControlComponent implements OnInit, AfterViewInit {
	@Input() value: any;
	@Input() placeholder = '';
	@Input() disabled: boolean;
	@Input() error: IError;
	@Input() name = '';
	@Input() cssClass: string;
	@Input() colWidth: string;
	@Input() formGroup: string;
	@Input() label: string;
	@Input() labelKey: string;
	@Input() subLabel: string;
	@Input() postfix: string;
	@Input() pattern: string;
	@Input() fieldObj;
	@Input() popupLink: string;
	@Output() isOnFocus = new EventEmitter<boolean>();
	@Input() tabindex: number;
	@Input() reproducibleIndex: number = null;

	controlType: ControlsType;
	isMobile: boolean;
	renderer: Renderer2;
	elementRef: ElementRef;
	controlContainer: any;
	isFocus$: Subject<boolean> = new Subject<boolean>();
	initValue: any;
	isPrefilled = false;
	routingService: RoutingService;
	StepsEnum = StepsEnum;
	describedByErrorId: string;

	constructor(injector: Injector, protected store: Store<IAppState>, protected eventsService: EventsService) {
		this.elementRef = injector.get(ElementRef);
		this.renderer = injector.get(Renderer2);
		this.controlContainer = injector.get(ControlContainer);
		this.routingService = injector.get(RoutingService);
	}

	ngOnInit(): void {
		this.isMobile = isMobile();

		if (this.cssClass) {
			this.renderer.addClass(this.elementRef.nativeElement, this.cssClass);
		}

		if (this.colWidth) {
			this.renderer.addClass(this.elementRef.nativeElement, this.colWidth);
		}
		// Generates id that should be provided to aria-describedby in ErrorMessageComponent
		this.describedByErrorId = `${this.name}${
			this.reproducibleIndex !== null ? '_' + this.reproducibleIndex : ''
		}_error`;
	}

	ngAfterViewInit(): void {
		this.displayAction();

		if (this.value) {
			this.prefillEdit();
		}
	}

	baseNgAfterViewInit(): void {}

	baseOnFocus() {
		this.initValue = isUndefined(this.value) ? '' : this.value;
		this.isOnFocus.emit(true);
		this.isFocus$.next(true);
	}

	baseOnBlur() {
		this.initValue = isUndefined(this.value) ? '' : this.value;
		this.editAction();
		this.isOnFocus.emit(false);
		this.isFocus$.next(false);
	}

	displayAction(): void {
		this.eventsService.addEvent(EventActions.DISPLAY, { label: this.getLabelName(GtmControlsActions.DISPLAY) });
	}

	prefillEdit() {
		this.eventsService.addEvent(EventActions.PREFILL, {
			label: this.getLabelName(GtmControlsActions.PREFILL),
			value: this.value,
		});
		this.isPrefilled = true;
	}

	editAction() {
		const actionName = this.isPrefilled ? EventActions.PREFILL_EDIT : EventActions.EDIT;
		let eventData: EventData = { label: this.getLabelName(GtmControlsActions[actionName]) };

		if (!this.controlType.includes('input') && this.value) {
			eventData.value = this.value;
		}

		this.eventsService.addEvent(actionName, eventData);
	}

	private getLabelName(controlAction: GtmControlsActions): string {
		const controlName = this.name ? this.name : this.fieldObj ? this.fieldObj.name : '';
		return `${controlName}_${controlAction}`;
	}
}
