<section class="main-content">
  <app-page-heading *ngIf="typeOfHouse" [title]="'titles.typeOfYourHome' | translate"
                    subtitle="
      {{'titles.thisLooksLike' | translate}}
      {{(this.typeOfHouse.value === 'Apartment' ? 'articles.indefinite.an' : 'articles.indefinite.a') | translate}}
      {{'dropdowns.' + typeOfHouse.labelKey | translate}},
      {{'titles.seemsRight' | translate}}?

"></app-page-heading>

  <app-page-heading *ngIf="!typeOfHouse" [title]="'titles.typeOfYourHome' | translate"></app-page-heading>

  <form #myForm="ngForm" [formGroup]="form" class="discounts-form stage">
    <app-radio-button
      [name]="Fields.PLTypeOfDwelling.name"
      [options]="getOptions()"
    >
    </app-radio-button>
  </form>

  <next-button
    (validationFailed)="handleValidation($event)"
    [disabled]="!myForm.control.controls[Fields.PLTypeOfDwelling.name].value"
    [myForm]="myForm"
    [title]="'buttons.continue' | translate"
  >
  </next-button>
</section>
