<div class="wrraper">
	<div [ngClass]="{ show: !showApproveMessage }" class="link-expired-message">
		<app-image [name]="isExpiredRenewable ? 'expiredLink' : 'expiredLinkAgent'"></app-image>

		<app-page-heading
			[subtitleKey]="isExpiredRenewable ? 'link-expired.subTitle' : 'link-expired-agent.subTitle'"
			[titleKey]="isExpiredRenewable ? 'link-expired.title' : 'link-expired-agent.title'"
		></app-page-heading>

		<ng-container *ngIf="isExpiredRenewable">
			<app-button (clicked)="handleResendLink()" [title]="'buttons.resendLink' | translate"></app-button>
			<div class="phone-number">
				<p>
					{{ "labels.orContactAgent" | translate }} {{ ": " }}<span>{{ agentPhoneNumber }}</span>
				</p>
			</div>
		</ng-container>
		<ng-container *ngIf="!isExpiredRenewable">
			<a
				[attr.aria-label]="'personalInfo.phone' | translate"
				class="app-button"
				href="tel:{{ agentPhoneNumber }}"
				tabindex="0"
			>
				<span class="title">
					<i appHiddenWhenCssDisabled class="material-icons" role="presentation">phone</i>
					{{ agentPhoneNumber }}
				</span>
			</a>
		</ng-container>
	</div>
</div>
