<div [class.no-vehicles-found]="isNoVehicleFound">
	<div class="add-edit-vehicle">
		<div [formGroup]="form" class="form-wrapper">
			<div class="question-wrapper field-inline">
				<unmasked-input
					[disabled]="mode == 'notVIN'"
					[emitKeyupOnBlur]="false"
					[name]="Fields.VIN.name"
					[pattern]="Fields.VIN.pattern"
					maxlength="17"
					[placeholder]="'labels.vinOptional' | translate"
				></unmasked-input>
				<div class="sub-text">
					{{ "titles.vinLocationHelp" | translate }}
				</div>
			</div>
			<div class="question-wrapper field-inline">
				<app-dropdown
					[disabled]="mode == 'VIN'"
					[labelInsideField]="'labels.plYear' | translate"
					[options]="yearOptions"
					[placeholder]=""
					[searchable]="true"
					class="app-dropdown-custom"
					name="PLYear"
				>
				</app-dropdown>
			</div>

			<div class="question-wrapper field-inline">
				<app-dropdown
					[disabled]="form?.controls?.PLYear?.invalid || !makeOptions.length || mode == 'VIN'"
					[labelInsideField]="'labels.plMake' | translate"
					[options]="makeOptions"
					[placeholder]="makeOptions.length ? ('labels.chooseOption' | translate) : ''"
					[searchable]="true"
					class="app-dropdown-custom"
					name="PLMake"
				>
				</app-dropdown>
			</div>

			<div class="question-wrapper field-inline">
				<app-dropdown
					[disabled]="form?.controls?.PLMake?.invalid || !modelOptions.length || mode == 'VIN'"
					[labelInsideField]="'labels.plModel' | translate"
					[options]="modelOptions"
					[placeholder]="modelOptions.length ? ('labels.chooseOption' | translate) : ''"
					[searchable]="true"
					class="app-dropdown-custom"
					name="PLModel"
				>
				</app-dropdown>
			</div>

			<div class="question-wrapper field-inline">
				<app-dropdown
					[disabled]="
						form?.controls?.PLMake?.invalid ||
						form?.controls?.PLModel?.invalid ||
						!bodyStyleOptions.length ||
						mode == 'VIN'
					"
					[labelInsideField]="'labels.bodyStyle' | translate"
					[options]="bodyStyleOptions"
					[placeholder]="bodyStyleOptions.length ? ('labels.chooseOption' | translate) : ''"
					[searchable]="true"
					class="app-dropdown-custom"
					name="BodyStyle"
				>
				</app-dropdown>
			</div>

			<app-question-wrapper *ngIf="form.get(Fields.OwnershipType.name).enabled">
				<app-dropdown
					[labelInsideField]="'labels.ownershipType' | translate"
					[name]="Fields.OwnershipType.name"
					[options]="OwnershipTypeOptions"
					[reAlphabeticalOrder]="false"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper>
				<unmasked-input
					[name]="Fields.NumberOfMiles.name"
					[pattern]="Fields.NumberOfMiles.pattern"
					[placeholder]="'labels.numberOfMiles' | translate"
					[withCommaFormat]="true"
					maxlength="6"
					secondaryType="number"
				></unmasked-input>
				<div class="sub-text">{{ "titles.medianMileage" | translate }}</div>
			</app-question-wrapper>
		</div>
	</div>
	<div *ngIf="!isNoVehicleFound" class="divider"></div>
	<div class="footer">
		<app-button
			(clicked)="addVehicle()"
			[disabled]="!form.valid"
			[materialIcon]="getAddButtonIcon()"
			[title]="getAddButtonText() | translate"
		></app-button>
		<button (click)="cancel()" *ngIf="!isNoVehicleFound" class="btn-cancel" type="button">
			{{ "buttons.cancel" | translate }}
		</button>
	</div>
</div>
