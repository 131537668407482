<section class="main-content bottom-space">
	<div [class.hidden]="!showPreBindLoader">
		<app-loader></app-loader>
		<app-page-heading [title]="'titles.justMoment' | translate"></app-page-heading>
	</div>
	<div [class.hidden]="showPreBindLoader">
		<app-page-heading [title]="'titles.pleaseProvideBelowInformation' | translate"></app-page-heading>

		<form #myform="ngForm" [formGroup]="form" class="stage">
			<div class="questions-container">
				<div [ngStyle]="{ width: ((activeTabIndex + 1) * 100) / drivers.length + '%' }" class="progress-bar"></div>
				<ng-container *ngIf="drivers.length > 0">
					<form
						*ngFor="let driver of drivers; let index = index"
						[class.hidden]="!isTabActive(index)"
						[formGroup]="getDriverHistoryForm(index)"
					>
						<div class="driver-header">
							<app-image [name]="'driverActiveIcon'" alt="null" height="32" role="presentation"></app-image>
							<p>
								{{
									getDriverHistoryForm(index).value["FirstName"] + " " + getDriverHistoryForm(index).value["LastName"]
										| uppercase
								}}
							</p>
						</div>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name) &&
								getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name).enabled
							"
						>
							<unmasked-input
								*ngIf="
									getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name) &&
									getDriverHistoryForm(index).get(Fields.DriverLicenseNumber.name).enabled
								"
								[name]="Fields.DriverLicenseNumber.name"
								[pattern]="Fields.DriverLicenseNumber.pattern"
								[placeholder]="'labels.driverLicenseNumber' | translate"
								maxlength="16"
								type="text"
							>
							</unmasked-input>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name) &&
								getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name).enabled
							"
							class="gender"
						>
							<app-dropdown
								[labelInsideField]="'labels.driverStateLicensed' | translate"
								[name]="Fields.DriverStateLicensed.name"
								[options]="FieldOptions.DriverStateLicensed"
								[reAlphabeticalOrder]="false"
								[tabindex]="
									getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name) &&
									getDriverHistoryForm(index).get(Fields.DriverStateLicensed.name).enabled
								"
								searchable="true"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.FQLicenseClass.name) &&
								getDriverHistoryForm(index).get(Fields.FQLicenseClass.name).enabled
							"
							class="gender"
						>
							<app-dropdown
								[labelInsideField]="'labels.fqLicenseClass' | translate"
								[name]="Fields.FQLicenseClass.name"
								[options]="FieldOptions.FQLicenseClassOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="
									getDriverHistoryForm(index).get(Fields.FQLicenseClass.name) &&
									getDriverHistoryForm(index).get(Fields.FQLicenseClass.name).enabled
								"
								searchable="true"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="getDriverHistoryForm(index).get(Fields.DriverLicenseStatus.name).value !== 'NotLicensed'"
							class="gender"
						>
							<app-dropdown
								[labelInsideField]="'labels.fqDriverRatingType' | translate"
								[name]="Fields.FQDriverRatingType.name"
								[options]="FieldOptions.PRG_FQDriverRatingTypeOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="
									getDriverHistoryForm(index).get(Fields.FQDriverRatingType.name) &&
									getDriverHistoryForm(index).get(Fields.FQDriverRatingType.name).enabled
								"
								searchable="true"
							></app-dropdown>
						</app-question-wrapper>

						


						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.FQMatriculaCard.name) &&
								getDriverHistoryForm(index).get(Fields.FQMatriculaCard.name).enabled
							"
						>
							<app-dropdown
								[labelInsideField]="'labels.fqMatriculaCard' | translate"
								[name]="Fields.FQMatriculaCard.name"
								[options]="FieldOptions.YesNoOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="getDriverHistoryForm(index).get(Fields.FQMatriculaCard.name)"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.FQMatriculaQualifiedToDrive.name) &&
								getDriverHistoryForm(index).get(Fields.FQMatriculaQualifiedToDrive.name).enabled
							"
						>
							<app-dropdown
								[labelInsideField]="'labels.fqMatriculaQualifiedToDrive' | translate"
								[name]="Fields.FQMatriculaQualifiedToDrive.name"
								[options]="FieldOptions.YesNoOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="getDriverHistoryForm(index).get(Fields.FQMatriculaQualifiedToDrive.name)"
							></app-dropdown>
						</app-question-wrapper>

						<p class="license-information-title">{{ "titles.licenseInformation" | translate }}</p>

						<!-- TODO - FIX ngif-->
						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.DriverDateLicensed.name) &&
								getDriverHistoryForm(index).get(Fields.DriverDateLicensed.name).enabled
							"
							[show]="
								getDriverHistoryForm(index).get(Fields.DriverDateLicensed.name) &&
								getDriverHistoryForm(index).get(Fields.DriverDateLicensed.name).enabled
							"
							class="date-of-birth"
						>
							<masked-input
								[inputMask]="'**/**/9999'"
								[name]="Fields.DriverDateLicensed.name"
								[placeholder]="'personalInfo.driverDateLicensed' | translate"
								class="date-input"
							>
							</masked-input>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.SnapshotPhoneNumber.name) &&
								getDriverHistoryForm(index).get(Fields.SnapshotPhoneNumber.name).enabled
							"
						>
							<masked-input
								*ngIf="
									getDriverHistoryForm(index).get(Fields.SnapshotPhoneNumber.name) &&
									getDriverHistoryForm(index).get(Fields.SnapshotPhoneNumber.name).enabled
								"
								[inputMask]="phoneNumberMask"
								[name]="Fields.SnapshotPhoneNumber.name"
								[placeholder]="'personalInfo.snapshotMobileNumber' | translate"
								[topPlaceholder]="true"
								type="tel"
							>
							</masked-input>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="getDriverHistoryForm(index).get(Fields.DriversLicenseBeenSuspendedOrRevoked.name)"
							class="gender"
						>
							<app-dropdown
								[labelInsideField]="'labels.driversLicenseBeenSuspendedOrRevoked' | translate"
								[name]="Fields.DriversLicenseBeenSuspendedOrRevoked.name"
								[options]="FieldOptions.YesNoOptions"
								[tabindex]="getDriverHistoryForm(index).get(Fields.DriversLicenseBeenSuspendedOrRevoked.name)"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.IsDefensiveDriver.name) &&
								getDriverHistoryForm(index).get(Fields.IsDefensiveDriver.name).enabled
							"
						>
							<app-dropdown
								[labelInsideField]="'labels.isDefensiveDriver' | translate"
								[name]="Fields.IsDefensiveDriver.name"
								[options]="FieldOptions.YesNoOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="getDriverHistoryForm(index).get(Fields.IsDefensiveDriver.name)"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.DefensiveDriverCourseDate.name) &&
								getDriverHistoryForm(index).get(Fields.DefensiveDriverCourseDate.name).enabled
							"
							[show]="
								getDriverHistoryForm(index).get(Fields.DefensiveDriverCourseDate.name) &&
								getDriverHistoryForm(index).get(Fields.DefensiveDriverCourseDate.name).enabled
							"
							class="date-of-birth"
						>
							<masked-input
								[inputMask]="'**/**/9999'"
								[name]="Fields.DefensiveDriverCourseDate.name"
								[placeholder]="'personalInfo.defensiveDriverCourseDate' | translate"
								class="date-input"
							>
							</masked-input>
						</app-question-wrapper>

						<app-question-wrapper *ngIf="getDriverHistoryForm(index).get(Fields.SR22OrFinancialResponsibility.name)">
							<app-dropdown
								[labelInsideField]="'labels.sr22OrFinancialResponsibility' | translate"
								[name]="Fields.SR22OrFinancialResponsibility.name"
								[options]="FieldOptions.YesNoOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="getDriverHistoryForm(index).get(Fields.SR22OrFinancialResponsibility.name)"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.IsGoodStudent.name) &&
								getDriverHistoryForm(index).get(Fields.IsGoodStudent.name).enabled
							"
						>
							<app-dropdown
								[labelInsideField]="'labels.isGoodStudent' | translate"
								[name]="Fields.IsGoodStudent.name"
								[options]="FieldOptions.YesNoOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="getDriverHistoryForm(index).get(Fields.IsGoodStudent.name)"
							></app-dropdown>
						</app-question-wrapper>

						<app-question-wrapper
							*ngIf="
								getDriverHistoryForm(index).get(Fields.FQIsStudent100Miles.name) &&
								getDriverHistoryForm(index).get(Fields.FQIsStudent100Miles.name).enabled
							"
						>
							<app-dropdown
								[labelInsideField]="'labels.fqIsStudent100Miles' | translate"
								[name]="Fields.FQIsStudent100Miles.name"
								[options]="FieldOptions.YesNoOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="getDriverHistoryForm(index).get(Fields.FQIsStudent100Miles.name)"
							></app-dropdown>
						</app-question-wrapper>
						<div class="button-state-filing-wrapper">
							<button (click)="openStateFilingPopup(index)" class="button-state-filing">
								{{ "buttons.iNeedStateFiling" | translate }}
							</button>

							<button
								[matTooltip]="'labels.stateFilling' | translate"
								appHiddenWhenCssDisabled
								appToggleTooltip
								class="info-icon material-icons"
							>
								<span aria-hidden="true">info_outline</span>
							</button>
						</div>
					</form>
				</ng-container>
				<div class="divider"></div>
				<div class="next-button-wrapper">
					<next-button
						(clicked)="handleContinue()"
						[continue]="true"
						[disabled]="
							(this.isLastTabActive() && !form.valid) ||
							(getDriverHistoryForm(activeTabIndex) && !getDriverHistoryForm(activeTabIndex).valid)
						"
						[loaderVisible]="false"
						[myForm]="myform"
						[title]="'buttons.continue' | translate"
					></next-button>
					<button (click)="goBack()" *ngIf="!isTabActive(0)" class="go-back">{{ "buttons.goBack" | translate }}</button>
				</div>
			</div>
		</form>
	</div>
</section>
