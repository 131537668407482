import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { NavigateToErrorPage } from '../store/actions/routing.actions';
import { IAppState } from '../store/states/app.state';
import { ActionApiService } from './action-api.service';
import { RoutingService } from './routing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateQuoteData } from '../store/actions/quote-data.actions';
import { UpdateCrossSellTo, UpdateInterviewMetadata } from '../store/actions/interview-metadata.actions';
import { LoaderActions } from '../store/actions';
import { LobsEnum } from '../enums/lobs.enum';
import { SourceFeaturesService } from './source-features.service';
import { flattenFQData, getD2CFeatures } from '../utils/general.utils';
import { LobService } from './lob.service';
import { ProgressMeterService } from './progress-meter.service';
import { StepsEnum } from '../enums/steps.enum';
import { SessionService } from './session.service';
import { ReplaySubject } from 'rxjs';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class RetrieveQuoteService {
	finishedRetrieved: boolean;

	constructor(
		private store: Store<IAppState>,
		private router: Router,
		private route: ActivatedRoute,
		private sessionService: SessionService,
		public routingService: RoutingService,
		public actionApiService: ActionApiService,
		public sourceFeaturesService: SourceFeaturesService,
		public lobService: LobService,
		public progressMeterService: ProgressMeterService
	) {
		this.finishedRetrieved = false;
	}

	extractToken(source) {
		let Observable$ = new ReplaySubject();
		if (this.route && source) {

			const urlParams = new URLSearchParams(window.location.search);
			const token = urlParams.get('token');
			const forcePageSkipping = urlParams.get('forcePageSkipping');
			if (token) {
				this.store.dispatch(UpdateInterviewMetadata({ data: { isRetrieved: true } }));
				this.store.dispatch(LoaderActions.ShowStartLoader());
				this.actionApiService.extractTokenApi(token).subscribe((res) => {
					if (!res.quoteData) {
						this.store.dispatch(UpdateInterviewMetadata({ data: { token: token, tokenStatus: res.status } }));
						this.store.dispatch(NavigateToErrorPage({ errorType: 'link-expired' }));
						this.store.dispatch(LoaderActions.HideStartloader());
					} else {
						this.sessionService.initSessionId();
						//updates the store with quote data
						//set all the drivers and vehicles in the quote data to be active
						let data = res.quoteData;
						data = this.setDriverAndVehiclesActive(res.quoteData);
						let FQDataFlatten = flattenFQData({ FQData: data.FQData });
						this.store.dispatch(
							UpdateQuoteData({
								data: {
									...data,
									validationErrors: res.validationErrors,
									...FQDataFlatten,
								},
							})
						);

						this.store.dispatch(
							UpdateInterviewMetadata({
								data: {
									isEmailInRetrieval: !!data.Email,
								},
							})
						);

						this.finishedRetrieved = true;
						this.reverseMapping(res, forcePageSkipping || false, Observable$);
					}
				});
			} else {
				Observable$.next(true);
			}
		}
		return Observable$;
	}

	reverseMapping(res, forcePageSkipping, Observable) {
		let lobs = res.quoteData.Lobs.filter((lob) => lob !== LobsEnum.FLOOD),
			initial = res.initial,
			occupancyType = res.quoteData.OccupancyType,
			typeOfDwelling = res.quoteData.PLTypeOfDwelling,
			typeProperty = '',
			primaryResidence;

		if (lobs) {
			if (lobs.includes(LobsEnum.PERSONAL_HOME)) {
				typeProperty = LobsEnum.PERSONAL_HOME;
				switch (occupancyType) {
					case 'OwnerPrimary':
						primaryResidence = 'yes';
						break;
					case 'OwnerSecondary':
						primaryResidence = 'no';
						break;
					default:
						break;
				}
			} else if (lobs.includes(LobsEnum.CONDOMINIUM)) {
				typeProperty = LobsEnum.CONDOMINIUM;
				switch (occupancyType) {
					case 'OwnerPrimary':
						primaryResidence = 'yes';
						break;
					case 'OwnerSecondary':
						primaryResidence = 'no';
						break;
					default:
						break;
				}
			} else if (lobs.includes(LobsEnum.DWELLING_FIRE)) {
				switch (occupancyType) {
					case 'Vacant':
						switch (typeOfDwelling) {
							case LobsEnum.CONDOMINIUM:
								typeProperty = LobsEnum.CONDOMINIUM;
								primaryResidence = 'no';
								break;
							case LobsEnum.PERSONAL_HOME:
								typeProperty = LobsEnum.PERSONAL_HOME;
								primaryResidence = 'no';
								break;
							default:
								break;
						}
						break;
					case 'TenantPrimary':
						switch (typeOfDwelling) {
							case LobsEnum.CONDOMINIUM:
								typeProperty = LobsEnum.CONDOMINIUM;
								primaryResidence = 'no';
								break;
							case LobsEnum.PERSONAL_HOME:
								typeProperty = LobsEnum.PERSONAL_HOME;
								primaryResidence = 'no';
								break;
							default:
								break;
						}
						break;
					default: {
						break;
					}
				}
			}

			this.actionApiService.getFlow(initial.applicationId).subscribe((getFlowRes) => {
				const policyAttachment = this.actionApiService.getParsedPolicyAttachmentData(getFlowRes?.attachment);
				let savedLobs = policyAttachment && policyAttachment.lobsInitialized?.filter((lob) => lob !== LobsEnum.FLOOD);
				let isMultiLob;
				if (savedLobs) {
					isMultiLob = savedLobs.length > 1;
				} else {
					isMultiLob = lobs.filter((lob) => lob !== LobsEnum.FLOOD).length > 1;
				}

				this.store.dispatch(
					UpdateInterviewMetadata({
						data: {
							_typeProperty: typeProperty,
							_primaryResidence: primaryResidence,
							lobSelection: lobs,
							applicationId: initial.applicationId,
							applicantId: initial.applicantId,
							friendlyId: initial.friendlyId,
							hasApplicationAppetite: res.initial.hasApplicationAppetite,
							lobsInitialized: savedLobs || lobs,
							isMultiLob: isMultiLob,
						},
					})
				);

				let isCrossSell = savedLobs?.length < lobs?.length;
				if (isCrossSell) {
					// add cross sell
					let unmatchedLob = lobs.filter((lob) => !savedLobs.includes(lob) && lob !== LobsEnum.FLOOD);
					this.store.dispatch(UpdateCrossSellTo({ crossSellTo: unmatchedLob }));
				}

				const redirectStage = res.redirectStage ? StepsEnum[res.redirectStage] : null;
				if (redirectStage && policyAttachment?.isConfirmationEmailSentDisplayed) {
					this.store.dispatch(LoaderActions.HideStartloader());
					this.redirectToStep(res.quoteData.Lobs, redirectStage, true);
				} else {
					const pageSkippingFlag = getD2CFeatures()?.pageSkipping;
					if ((pageSkippingFlag && !isCrossSell) || forcePageSkipping) {
						// skip to result page or closet page
						this.sourceFeaturesService.skipToResultPage(res);
						return;
					} else {
						this.store.dispatch(LoaderActions.HideStartloader());
						this.router.navigate([], {
							queryParams: {
								token: null,
								forcePageSkipping: null,
							},
							queryParamsHandling: 'merge',
						});
					}
				}
			});
		} else {
			this.store.dispatch(
				UpdateInterviewMetadata({
					data: {
						applicationId: initial.applicationId,
						friendlyId: initial.friendlyId,
						applicantId: initial.applicantId,
					},
				})
			);
		}
		Observable.next(true);
	}

	setDriverAndVehiclesActive(quoteData) {
		let Vehicles = quoteData.PersonalVehicles?.map((i) => {
			return { ...i, enabled: true };
		});
		let Drivers = quoteData.Drivers?.map((i) => {
			return { ...i, active: true };
		});
		return { ...quoteData, PersonalVehicles: Vehicles ? Vehicles : [], Drivers: Drivers ? Drivers : [] };
	}

	redirectToStep(lobs: string[], step: StepsEnum, removeToken = false) {
		const lob = this.lobService.getLob(lobs);
		const flowType = this.lobService.getFlowType(lob);
		const lobRoute = this.progressMeterService.getRouteName(flowType);

		this.routingService.navigateToStep(flowType, lobRoute, step, removeToken);
	}
}
