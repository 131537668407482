import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { LobsEnum, OfflineLobsEnum } from 'src/app/enums/lobs.enum';
import { LoaderActions, ProgressMeterActions } from 'src/app/store/actions';
import {
	removeCrossSellTo,
	UpdateInterviewMetadata,
	UpdateLobsInitialized,
} from 'src/app/store/actions/interview-metadata.actions';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { setOriginTypeOfDwelling } from 'src/app/store/actions/result-data.actions';
import { selectApplicationId, selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { selectAddress, selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { ILoaderInformationState } from 'src/app/store/states/loader-information.state';
import { BasePageComponent } from '../../base-page.component';
import { QuoteDataService } from '../../../../../services/quote-data.service';
import { IAppState } from '../../../../../store/states/app.state';
import { ActionApiService } from '../../../../../services/action-api.service';
import { selectLoaderInformation } from '../../../../../store/selectors/loader-information.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LobService } from 'src/app/services/lob.service';
import { ConfigService } from 'src/app/services/config.service';
import { getLobSelectionImage, isMultiLob } from 'src/app/utils/general.utils';

// READY_TO_INIT_LOBS = lobs that we figure out and no need to ask more questions(like DF - vacation home etc)
const READY_TO_INIT_LOBS = [LobsEnum.RENTERS, LobsEnum.PERSONAL_AUTO, LobsEnum.PETS];
const OFFLINE_LOBS = ['DeviceProtection'];

@UntilDestroy()
@Component({
	selector: 'app-lob-selection',
	templateUrl: './lob-selection.component.html',
	styleUrls: ['./lob-selection.component.scss'],
})
export class LobSelectionComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
	lobsInPD = [];
	loading = false;
	loaderInformation$: Observable<ILoaderInformationState>;
	lobsToShow = [];
	offlineLobsToShow = [];
	lobBundle;
	offlineLobBundle = [];
	interviewMetadata;
	lobsSelected: string[] = [];
	getLobService: LobService;
	originalHomeTypeLob; //save the lob type before changing it to home(DF/Condo)

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private actionApiService: ActionApiService,
		private configService: ConfigService
	) {
		super(injector, store);
		this.loaderInformation$ = this.store.select(selectLoaderInformation);
		this.actionApiService = injector.get(ActionApiService);
		this.getLobService = injector.get(LobService);
		this.configService = injector.get(ConfigService);
	}

	ngOnInit(): void {
		// subscribe once
		combineLatest([this.store.select(selectQuoteData), this.store.select(selectInterviewMetadata)])
			.pipe(first())
			.pipe(untilDestroyed(this))
			.subscribe(([quoteData, interviewMetadata]) => {
				this.interviewMetadata = interviewMetadata;
				this.quoteData = quoteData;
				if (quoteData.lobBundle) {
					// Only 1 bundle
					this.lobBundle = quoteData.lobBundle[0]?.split(',');
				}

				if (interviewMetadata.lobsInApatite.length > 0) {
					this.lobsToShow = interviewMetadata.lobsInApatite.filter((lob) => {
						return !OFFLINE_LOBS.includes(lob) && lob.indexOf(',') === -1;
					});
				}
				let match = interviewMetadata.lobsInApatite.filter((item) => OFFLINE_LOBS.includes(item));
				if (interviewMetadata.lobsInApatite.length > 0 && match.length > 0) {
					this.offlineLobsToShow = match;
				}

				this.lobsToShow = this.lobsToShow.map((lobName) => {
					if (lobName) {
						return {
							title: `lobs.${lobName}`,
							value: lobName,
							enabled: true,
							selected: false,
							imageName: getLobSelectionImage(lobName),
						};
					}
				});

				this.offlineLobsToShow = this.offlineLobsToShow.map((lobName) => {
					return {
						title: `lobs.${lobName}`,
						value: lobName,
						enabled: true,
						selected: false,
						imageName: getLobSelectionImage(lobName),
					};
				});

				let checkedLobs: any = [...interviewMetadata.lobsInitialized]; //lobSelection will show cross sell as both lobs selected which is wrong, lobsInitialized will not show home if going back to lob-selection without gogign to 3rd page
				// checkedLobs.forEach((item, index) => {
				// 	checkedLobs[index] = item === LobsEnum.CONDOMINIUM || item === LobsEnum.DWELLING_FIRE ? LobsEnum.PERSONAL_HOME : item; //mark condo and DF as Home to show it selected since we don't have condo/df in this page as option
				// });
				checkedLobs.forEach((i) => {
					this.handleSelects(i);
				});
				this.lobsInPD = quoteData.Lobs;
				this.store.dispatch(LoaderActions.Hideloader());
			});
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	handleSelects(lob) {
		// if lob is LobsEnum.CONDOMINIUM or LobsEnum.DWELLING_FIRE then mark it as LobsEnum.PERSONAL_HOME
		if (lob === LobsEnum.CONDOMINIUM || lob === LobsEnum.DWELLING_FIRE) {
			this.originalHomeTypeLob = lob;
			lob = LobsEnum.PERSONAL_HOME;
		}

		// if offline lob open new tab with the url
		if (Object.values(OfflineLobsEnum).includes(lob)) {
			let offlineLink = this.configService.getConfig()[`${lob}OfflineLink`];

			if (offlineLink) {
				window.open(offlineLink, '_blank');
			}
		} else {
			let selectedLobIndex = this.lobsToShow.findIndex((i) => i.value === lob);
			if (selectedLobIndex === -1) {
				return;
			}
			this.lobsToShow[selectedLobIndex].selected = !this.lobsToShow[selectedLobIndex].selected;
			//check for bundles for the current lob
			let bundleOption;
			let optionalBundledLobs;
			if (this.lobBundle) {
				optionalBundledLobs = this.lobBundle.indexOf(lob) > -1; //find((i) => i.includes(lob));
				//there is bundle option
				if (optionalBundledLobs) {
					bundleOption = this.lobBundle.filter((currLob) => currLob !== lob);
				}
			}

			//check if there is no lob selected
			if (!this.lobsToShow.map((lobItem) => lobItem.selected).includes(true)) {
				this.lobsToShow = this.lobsToShow.map((lobItem) => {
					return { ...lobItem, enabled: true };
				});
			} else {
				//there is lob selected
				this.lobsToShow = this.lobsToShow.map((currLob) => {
					if (currLob.value == bundleOption) {
						return { ...currLob, enabled: true };
					} else {
						if (currLob.value !== lob) {
							return { ...currLob, enabled: false };
						} else {
							return currLob;
						}
					}
				});
			}

			this.lobsSelected = this.lobsToShow.filter((i) => i.selected).map((lobI) => lobI.value);
			// map to (PersonalHome,PersonalAuto) to prevent to wrong bundle(auto,home)
			if (
				optionalBundledLobs &&
				this.lobBundle.length === this.lobsSelected.length &&
				this.lobBundle.indexOf(this.lobsSelected[0]) > -1 &&
				this.lobBundle.indexOf(this.lobsSelected[1]) > -1
			) {
				this.lobsSelected = this.lobBundle;
			}

			let storeDispatchData = { lobSelection: this.lobsSelected };
			if (
				this.originalHomeTypeLob &&
				this.lobsSelected.length === 1 &&
				this.lobsSelected[0] === LobsEnum.PERSONAL_HOME
			) {
				storeDispatchData = { lobSelection: [this.originalHomeTypeLob] };
			}
			this.store.dispatch(
				UpdateInterviewMetadata({
					data: storeDispatchData,
				})
			);
		}
	}

	handleContinue() {
		let lobs: any = this.lobsSelected?.length > 1 ? this.lobsSelected.join(',') : this.lobsSelected[0];
		this.store.dispatch(ProgressMeterActions.setFlowType({ flowType: lobs }));
		/*
		 *	lobsInPD = there is lob in the policy data
		 * READY_TO_INIT_LOBS = lobs that we figure out and no need to ask more questions(like DF - vacation home etc)
		 * lob = what the user selected right now in the component
		 */
		if (
			(!this.lobsInPD && READY_TO_INIT_LOBS.includes(lobs)) ||
			(READY_TO_INIT_LOBS.includes(lobs) && JSON.stringify([lobs]) !== JSON.stringify(this.lobsInPD))
		) {
			// on update or ready to init lobs
			this.store.dispatch(LoaderActions.ShowLoader());
			this.store
				.select(selectApplicationId)
				.pipe(first())
				.subscribe((appId) => {
					// there is applicationId
					if (appId) {
						this.store
							.select(selectAddress)
							.pipe(first())
							.pipe(untilDestroyed(this))
							.subscribe((data) => {
								this.actionApiService.updateApplication({ PropertyAddress: data }, [lobs]).subscribe((res) => {
									if (!res) {
										return;
									}
									this.store.dispatch(UpdateQuoteData({ data: res['data'] }));
									this.progressMeterService.goToNextPage();
									this.store.dispatch(UpdateLobsInitialized({ lobsInitialized: [lobs] }));
									this.progressMeterService.hasApatite.next(res.hasApplicationAppetite);
									this.store.dispatch(
										UpdateInterviewMetadata({
											data: {
												isMultiLob: isMultiLob(lobs.split(',')),
											},
										})
									);
								});
							});
					} else {
						// There is no application ID so need to init quote
						this.actionApiService
							.initApplication([lobs], this.quoteData.PropertyAddress)
							.pipe(untilDestroyed(this))
							.subscribe((res) => {
								this.store.dispatch(
									UpdateInterviewMetadata({
										data: {
											applicationId: res.initial.applicationId,
											friendlyId: res.initial.friendlyId,
											hasApplicationAppetite: res.initial.hasApplicationAppetite,
											isMultiLob: isMultiLob(lobs.split(',')),
										},
									})
								);
								this.progressMeterService.hasApatite.next(res.initial['hasApplicationAppetite']);
								this.updateAddressQuoteData();
								this.store.dispatch(UpdateLobsInitialized({ lobsInitialized: [lobs] }));
							});
					}
					this.removeCrossSell(lobs);
				});
		} else {
			// on no update or home lobs
			let splittedLobs = lobs.indexOf(',') > -1 ? lobs.split(',') : [lobs];
			this.store.dispatch(
				UpdateInterviewMetadata({ data: { lobsInitialized: splittedLobs, isMultiLob: isMultiLob(lobs.split(',')) } })
			); //set up home as initialized to see it when coming back to this page without going to 3rd page

			// if not home and lob didn't change we have no apatite(we already send address and lob in the your-address page),
			// if home or multi lob home is selected should continue to next pages and check apatite after updating lob
			if (this.lobsSelected.indexOf(LobsEnum.PERSONAL_HOME) === -1) {
				this.progressMeterService.hasApatite.next(this.interviewMetadata.hasApplicationAppetite);
			}
			this.removeCrossSell(lobs);
			// wait until update store is done
			setTimeout(() => {
				this.progressMeterService.goToNextPage();
			}, 0);
		}
	}

	updateAddressQuoteData() {
		this.store
			.select(selectAddress)
			.pipe(first())
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.actionApiService
					.updateApplication({ PropertyAddress: data })
					.pipe(untilDestroyed(this))
					.subscribe((serverRes) => {
						// for renters only - save the origin type of dwelling
						this.store.dispatch(
							setOriginTypeOfDwelling({
								OriginTypeOfDwelling: serverRes['data']['PLTypeOfDwelling']
									? serverRes['data']['PLTypeOfDwelling']
									: '',
							})
						);
						this.store.dispatch(UpdateQuoteData({ data: serverRes['data'] }));
						this.store.dispatch(
							UpdateInterviewMetadata({ data: { hasApplicationAppetite: serverRes.hasApplicationAppetite } })
						);
						this.progressMeterService.goToNextPage();
					});
			});
	}

	removeCrossSell(lobs) {
		// remove cross sell when selecting the item in lob-selection
		this.interviewMetadata.crossSellTo.forEach((crossSell) => {
			if (lobs.includes(crossSell)) {
				this.store.dispatch(removeCrossSellTo({ crossSellTo: [crossSell] }));
			}
		});
	}

	ngOnDestroy(): void {
		this.store.dispatch(LoaderActions.Hideloader());
	}
}
