import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { filter } from 'rxjs/operators';
import { ActivateStep } from '../store/actions/progress-meter.actions';
import { Subject } from 'rxjs';
import { StepsEnum } from '../enums/steps.enum';
import { selectSecretSource, selectSource } from '../store/selectors/result-data.selectors';
import { ProgressMeterActions } from '../store/actions';
import { Location } from '@angular/common';

@Injectable()
export class RoutingService {
	currentRoute: string;
	previousRoute: string;
	navigateSuccess = new Subject<StepsEnum>();
	closePopupWrapper = new Subject<boolean>();
	source: string;
	secretSource: string;

	constructor(private store: Store<IAppState>, private router: Router, private activatedRoute: ActivatedRoute, private location: Location) {
		this.initRouterSubscription();

		this.store.select(selectSource).subscribe((source) => {
			this.source = source;
		});

		this.store.select(selectSecretSource).subscribe((secretSource) => {
			this.secretSource = secretSource;
		});
	}

	initRouterSubscription(): void {
		if (!this.router.events) {
			return;
		}
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationStart))
			.subscribe((event: NavigationEnd | NavigationStart) => {
				if (event instanceof NavigationEnd) {
					this.previousRoute = this.currentRoute;
					this.currentRoute = event.url.substr(1) as StepsEnum;
				}
			});

		// Subscribe to the location change event to handle the back and forward button clicks ActivateStep
		this.location.subscribe((event) => {
			// Handle the event here. This will be triggered when the back or forward button is clicked.
			console.log('Back or forward button clicked', event);

			const path = event.url.split('?')[0];
			const pathSegments = path.split('/').filter(segment => segment);

			// Get the last segment of the path, which is the page
			const page = pathSegments[pathSegments.length - 1];

			this.store.dispatch(
				ActivateStep({
					stepName: page,
				})
			);
		});

	}

	public navigateToHomePage(): void {
		setTimeout(() => window.scroll(0, 0), 0);
		this.store.dispatch(
			ActivateStep({
				stepName: StepsEnum.YOUR_ADDRESS,
			})
		);

		if (this.secretSource) {
			this.router.navigate([this.secretSource], { queryParamsHandling: 'preserve' });
		} else {
			this.router.navigate([this.source], { queryParamsHandling: 'preserve' });
		}
	}

	public navigateToLobSelection(): void {
		setTimeout(() => window.scroll(0, 0), 0);
		this.store.dispatch(
			ActivateStep({
				stepName: StepsEnum.LOB_SELECTION,
			})
		);
		if (this.secretSource) {
			this.router.navigate([this.secretSource + '/lobs'], { queryParamsHandling: 'preserve' });
		} else {
			this.router.navigate([this.source + '/lobs'], { queryParamsHandling: 'preserve' });
		}
	}

	public navigateToStep(flowType: string, lob: string, step: string, removeToken = false): void {
		setTimeout(() => window.scroll(0, 0), 0);
		this.store.dispatch(ProgressMeterActions.setFlowType({ flowType }));

		this.store.dispatch(
			ActivateStep({
				stepName: step,
			})
		);
		let config: NavigationExtras;

		if (removeToken) {
			config = { queryParamsHandling: 'merge', queryParams: { token: null } }
		} else {

			config = { queryParamsHandling: 'preserve' };
		}
		// your-address & lob-selection are the only steps that don't have a lob in the route
		let nextRoute = [this.secretSource ? this.secretSource : this.source, lob, step];
		if (step === StepsEnum.YOUR_ADDRESS || step === StepsEnum.LOB_SELECTION) {
			nextRoute = [this.secretSource ? this.secretSource : this.source, step];
		}
		this.router.navigate(nextRoute, config);
	}

	public navigateToRoute(route: string) {
		setTimeout(() => window.scroll(0, 0), 0);
		let fullRoute = this.secretSource ? this.secretSource : this.source + '/' + route;
		const routeArray = route.split('/');
		const step = routeArray[routeArray.length - 1];
		this.store.dispatch(
			ActivateStep({ stepName: step })
		);

		this.router.navigate([fullRoute], { queryParamsHandling: 'preserve' });
	}

	public navigateToErrorPage(errorType: string) {
		setTimeout(() => window.scroll(0, 0), 0);
		// if no path, navigate to error page
		try {
			this.router.navigate([this.activatedRoute.children[0].snapshot.url[0].path, 'error', errorType], { queryParamsHandling: 'preserve' });
		} catch {
			this.router.navigate(['error', errorType], { queryParamsHandling: 'preserve' });
		}
	}

	public navigateToExternal(url: string, openInNewTab?: boolean): void {
		if (openInNewTab) {
			window.open(url, '_blank');
		} else {
			window.location.href = url;
		}
	}

	public callTo(phoneNumber: string): void {
		window.open('tel:' + phoneNumber, '_self');
	}
}
