import { IAppState } from '../states/app.state';
import { createSelector } from '@ngrx/store';
import { CoverageModel } from '../../models/coverage.model';

const resultData = (state: IAppState) => state.resultData;

export const selectRates = createSelector(resultData, (state) => state.rates);
export const getSelectedRate = createSelector(
	resultData,
	(state) => state.rates.find((rate) => rate.carrier === state.selectedCarrier) as CoverageModel
);
export const getSelectedRatePlan = createSelector(resultData, (state) => {
	// will find the quote that was selected and the payment plan that was selected inside it
	const selectedRate = state.rates.find((rate) => rate.selected);
	return selectedRate.paymentPlans.find((plan) => plan.selected);
});

export const getSelectedCarrier = createSelector(resultData, (state) => state.selectedCarrier);

export const selectRatePlanSelectedForOfflineBid = createSelector(
	resultData,
	(state) => state.rateSelectedForOfflineBid
);

export const selectCoverages = createSelector(resultData, (state) => state.coverages);
export const selectPaymentData = createSelector(resultData, (state) => state.paymentData);
export const selectFriendly = createSelector(resultData, (state) => state.friendlyId);
export const selectSingleSubmittedApplication = createSelector(resultData, (state) => state.submittedSingleApplication);
export const selectOriginTypeOfDwelling = createSelector(resultData, (state) => state.originTypeOfDwelling);
export const selectIsAdditionalQuestionsChosen = createSelector(
	resultData,
	(state) => state.isAdditionalQuestionsChosen
);
export const selectIsSecondAdditionalQuestionsChosen = createSelector(
	resultData,
	(state) => state.isSecondAdditionalQuestionsChosen
);
export const selectIsCurrentInsuranceChosen = createSelector(resultData, (state) => state.isCurrentInsuranceChosen);
export const selectIsDetailsChosen = createSelector(resultData, (state) => state.isDetailsChosen);
export const selectIsAltRatesVisible = createSelector(resultData, (state) => state.isAltRatesVisible);
export const selectIsUtilitiesChosen = createSelector(resultData, (state) => state.isUtilitiesChosen);
export const selectSource = createSelector(resultData, (state) => state.source);
export const selectSecretSource = createSelector(resultData, (state) => state.secretSource);
export const selectIsQuoteCompleted = createSelector(resultData, (state) => state.isQuoteCompleted);

export const selectIsRoofReplaced = createSelector(resultData, (state) => state.isRoofReplaced);
export const selectIsMartialStatusChosen = createSelector(resultData, (state) => state.isMartialStatusChosen);

export const selectToggledRatesSelected = createSelector(resultData, (state) => state.toggledRatesSelected);
