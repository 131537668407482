export const REASONS_FOR_NO_PRIOR_INSURANCE_OPTIONS = [
	{
		id: 0,
		value: 'ActiveMilitaryDuty',
		title: 'Active military duty',
		labelKey: 'reasonsForNoPriorInsurance.ActiveMilitaryDuty',
	},
	{
		id: 1,
		value: 'DrivingCompanyCar',
		title: 'Driving company car',
		labelKey: 'reasonsForNoPriorInsurance.DrivingCompanyCar',
	},
	{
		id: 2,
		value: 'DrivingCarOwnedByRelative',
		title: 'Driving car owned by a relative',
		labelKey: 'reasonsForNoPriorInsurance.DrivingCarOwnedByRelative',
	},
	{
		id: 3,
		value: 'HasNotOwnedOrOperatedVehicle',
		title: 'Has not owned or operated a car',
		labelKey: 'reasonsForNoPriorInsurance.HasNotOwnedOrOperatedVehicle',
	},
	{
		id: 4,
		value: 'OwnedVehParkedStoredOffRoadNotDriven',
		title: 'Owned car- parked/stored/off road/not driven',
		labelKey: 'reasonsForNoPriorInsurance.OwnedVehParkedStoredOffRoadNotDriven',
	},
	{ id: 5, value: 'NonPayment', title: 'Non-payment', labelKey: 'reasonsForNoPriorInsurance.NonPayment' },
	{ id: 6, value: 'Other', title: 'Other', labelKey: 'reasonsForNoPriorInsurance.Other' },
];

export const PRIOR_LIABILITY_LIMITS_AUTO_OPTIONS = [
	{ id: 0, value: 'ThirtyToSixty', title: '30K/60K', labelKey: 'priorLiabilityLimitsAuto.ThirtyToSixty' },
	{ id: 1, value: 'FiftyToHundred', title: '50K/100K', labelKey: 'priorLiabilityLimitsAuto.FiftyToHundred' },
	{
		id: 2,
		value: 'HundredToThreeHundred',
		title: '100K/300K',
		labelKey: 'priorLiabilityLimitsAuto.HundredToThreeHundred',
	},
	{
		id: 3,
		value: 'TwoHundredFiftyToFiveHundred',
		title: '250K/500K or higher',
		labelKey: 'priorLiabilityLimitsAuto.TwoHundredFiftyToFiveHundred',
	},
];

export const CARRIERS_LIST = [
	{
		id: 0,
		title: 'No Prior Insurance',
		value: 'NoPriorInsurance',
		labelKey: 'carriers.noPriorInsurance',
	},
	{
		id: 1,
		title: 'Twenty First Century',
		value: 'TwentyFirstCentury',
	},
	{
		id: 2,
		title: 'ACentral',
		value: 'ACentral',
	},
	{
		id: 3,
		title: 'AAA',
		value: 'AAA',
	},
	{
		id: 4,
		title: 'AARP',
		value: 'AARP',
	},
	{
		id: 5,
		title: 'Acadia',
		value: 'Acadia',
	},
	{
		id: 6,
		title: 'Ace',
		value: 'Ace',
	},
	{
		id: 7,
		title: 'Acuity',
		value: 'Acuity',
	},
	{
		id: 8,
		title: 'Adirondack Ins Exchange',
		value: 'AdirondackInsExchange',
	},
	{
		id: 9,
		title: 'Aegis',
		value: 'Aegis',
	},
	{
		id: 10,
		title: 'AIC',
		value: 'AIC',
	},
	{
		id: 11,
		title: 'AIG',
		value: 'AIG',
	},
	{
		id: 12,
		title: 'Alfa Alliance',
		value: 'AlfaAlliance',
	},
	{
		id: 13,
		title: 'Allied',
		value: 'Allied',
	},
	{
		id: 14,
		title: 'Allstate',
		value: 'Allstate',
	},
	{
		id: 15,
		title: 'America First',
		value: 'AmericaFirst',
	},
	{
		id: 16,
		title: 'American Commerce',
		value: 'AmericanCommerce',
	},
	{
		id: 17,
		title: 'American Family',
		value: 'AmericanFamily',
	},
	{
		id: 18,
		title: 'Amica',
		value: 'Amica',
	},
	{
		id: 19,
		title: 'ASI Lloyds',
		value: 'ASILloyds',
	},
	{
		id: 20,
		title: 'Austin Mutual',
		value: 'AustinMutual',
	},
	{
		id: 21,
		title: 'Autoone',
		value: 'Autoone',
	},
	{
		id: 22,
		title: 'Auto Owners',
		value: 'AutoOwners',
	},
	{
		id: 23,
		title: 'Badger Mutual',
		value: 'BadgerMutual',
	},
	{
		id: 24,
		title: 'Balboa',
		value: 'Balboa',
	},
	{
		id: 25,
		title: 'Bankers',
		value: 'Bankers',
	},
	{
		id: 26,
		title: 'Beacon National',
		value: 'BeaconNational',
	},
	{
		id: 27,
		title: 'Brethern Mutual',
		value: 'BrethernMutual',
	},
	{
		id: 28,
		title: 'Bristol West',
		value: 'BristolWest',
	},
	{
		id: 29,
		title: 'Capital Insurance Group',
		value: 'CapitalInsuranceGroup',
	},
	{
		id: 30,
		title: 'Celina',
		value: 'Celina',
	},
	{
		id: 31,
		title: 'Central Mutual Of OH',
		value: 'CentralMutualOfOH',
	},
	{
		id: 32,
		title: 'Chubb',
		value: 'Chubb',
	},
	{
		id: 33,
		title: 'Cincinnati',
		value: 'Cincinnati',
	},
	{
		id: 34,
		title: 'CNA',
		value: 'CNA',
	},
	{
		id: 35,
		title: 'Colonial Penn',
		value: 'ColonialPenn',
	},
	{
		id: 36,
		title: 'Colorado Casualty',
		value: 'ColoradoCasualty',
	},
	{
		id: 37,
		title: 'Columbia',
		value: 'Columbia',
	},
	{
		id: 38,
		title: 'Commerce West',
		value: 'CommerceWest',
	},
	{
		id: 39,
		title: 'Ameriprise',
		value: 'Ameriprise',
	},
	{
		id: 40,
		title: 'Constitutional Casualty',
		value: 'ConstitutionalCasualty',
	},
	{
		id: 41,
		title: 'Cornerstone',
		value: 'Cornerstone',
	},
	{
		id: 42,
		title: 'Countrywide',
		value: 'Countrywide',
	},
	{
		id: 43,
		title: 'CSE',
		value: 'CSE',
	},
	{
		id: 44,
		title: 'Cumberland',
		value: 'Cumberland',
	},
	{
		id: 45,
		title: 'Dairyland',
		value: 'Dairyland',
	},
	{
		id: 46,
		title: 'Deerbrook',
		value: 'Deerbrook',
	},
	{
		id: 47,
		title: 'Direct',
		value: 'Direct',
	},
	{
		id: 48,
		title: 'Donegal',
		value: 'Donegal',
	},
	{
		id: 49,
		title: 'Drive',
		value: 'Drive',
	},
	{
		id: 50,
		title: 'Electric',
		value: 'Electric',
	},
	{
		id: 51,
		title: 'EMC',
		value: 'EMC',
	},
	{
		id: 52,
		title: 'Encompass',
		value: 'Encompass',
	},
	{
		id: 53,
		title: 'Erie',
		value: 'Erie',
	},
	{
		id: 54,
		title: 'Esurance',
		value: 'Esurance',
	},
	{
		id: 55,
		title: 'Explorer',
		value: 'Explorer',
	},
	{
		id: 56,
		title: 'Farm Burea',
		value: 'FarmBurea',
	},
	{
		id: 57,
		title: 'Farmers',
		value: 'Farmers',
	},
	{
		id: 58,
		title: 'Firemans Fund',
		value: 'FiremansFund',
	},
	{
		id: 59,
		title: 'First American',
		value: 'FirstAmerican',
	},
	{
		id: 60,
		title: 'Foremost',
		value: 'Foremost',
	},
	{
		id: 61,
		title: 'Geico',
		value: 'Geico',
	},
	{
		id: 62,
		title: 'General Casualty',
		value: 'GeneralCasualty',
	},
	{
		id: 63,
		title: 'Germantown Mutual',
		value: 'GermantownMutual',
	},
	{
		id: 64,
		title: 'GMAC',
		value: 'GMAC',
	},
	{
		id: 65,
		title: 'Grange',
		value: 'Grange',
	},
	{
		id: 66,
		title: 'Great American',
		value: 'GreatAmerican',
	},
	{
		id: 67,
		title: 'Grinnell',
		value: 'Grinnell',
	},
	{
		id: 68,
		title: 'Guide One',
		value: 'GuideOne',
	},
	{
		id: 69,
		title: 'Hanover',
		value: 'Hanover',
	},
	{
		id: 70,
		title: 'Harbor',
		value: 'Harbor',
	},
	{
		id: 71,
		title: 'Harleysville',
		value: 'Harleysville',
	},
	{
		id: 72,
		title: 'Hartford OMNI',
		value: 'HartfordOMNI',
	},
	{
		id: 73,
		title: 'Hartford',
		value: 'Hartford',
	},
	{
		id: 74,
		title: 'Hastings Mutual',
		value: 'HastingsMutual',
	},
	{
		id: 75,
		title: 'Hawkeye Security',
		value: 'HawkeyeSecurity',
	},
	{
		id: 76,
		title: 'HDI',
		value: 'HDI',
	},
	{
		id: 77,
		title: 'Horace Mann',
		value: 'HoraceMann',
	},
	{
		id: 78,
		title: 'Houston General',
		value: 'HoustonGeneral',
	},
	{
		id: 79,
		title: 'IFA',
		value: 'IFA',
	},
	{
		id: 80,
		title: 'Imperial Casualty',
		value: 'ImperialCasualty',
	},
	{
		id: 81,
		title: 'Indiana Farmers',
		value: 'IndianaFarmers',
	},
	{
		id: 82,
		title: 'Indiana',
		value: 'Indiana',
	},
	{
		id: 83,
		title: 'Infinity',
		value: 'Infinity',
	},
	{
		id: 84,
		title: 'Insurequest',
		value: 'Insurequest',
	},
	{
		id: 85,
		title: 'Integon',
		value: 'Integon',
	},
	{
		id: 86,
		title: 'Integrity',
		value: 'Integrity',
	},
	{
		id: 87,
		title: 'Kemper',
		value: 'Kemper',
	},
	{
		id: 88,
		title: 'Liberty Mutual',
		value: 'LibertyMutual',
	},
	{
		id: 89,
		title: 'Liberty Northwest',
		value: 'LibertyNorthwest',
	},
	{
		id: 90,
		title: 'Main Street America',
		value: 'MainStreetAmerica',
	},
	{
		id: 91,
		title: 'Mapfre',
		value: 'Mapfre',
	},
	{
		id: 92,
		title: 'Mendota',
		value: 'Mendota',
	},
	{
		id: 93,
		title: 'Merchants Group',
		value: 'MerchantsGroup',
	},
	{
		id: 94,
		title: 'Mercury',
		value: 'Mercury',
	},
	{
		id: 95,
		title: 'Metlife',
		value: 'Metlife',
	},
	{
		id: 96,
		title: 'Metropolitan',
		value: 'Metropolitan',
	},
	{
		id: 97,
		title: 'Mid Continent',
		value: 'MidContinent',
	},
	{
		id: 98,
		title: 'Midwestern Indemnity',
		value: 'MidwesternIndemnity',
	},
	{
		id: 99,
		title: 'Montgomery',
		value: 'Montgomery',
	},
	{
		id: 100,
		title: 'MSA',
		value: 'MSA',
	},
	{
		id: 101,
		title: 'Mt Washington',
		value: 'MtWashington',
	},
	{
		id: 102,
		title: 'Mutual Benefit',
		value: 'MutualBenefit',
	},
	{
		id: 103,
		title: 'National General',
		value: 'NationalGeneral',
	},
	{
		id: 104,
		title: 'Nationwide',
		value: 'Nationwide',
	},
	{
		id: 105,
		title: 'New York Central Mutual',
		value: 'NewYorkCentralMutual',
	},
	{
		id: 106,
		title: 'NJ Manufacturers',
		value: 'NJManufacturers',
	},
	{
		id: 107,
		title: 'NJ Skylands',
		value: 'NJSkylands',
	},
	{
		id: 108,
		title: 'Northstar',
		value: 'Northstar',
	},
	{
		id: 109,
		title: 'Occidental',
		value: 'Occidental',
	},
	{
		id: 110,
		title: 'Ohio Casualty',
		value: 'OhioCasualty',
	},
	{
		id: 111,
		title: 'Omaha PC',
		value: 'OmahaPC',
	},
	{
		id: 112,
		title: 'One Beacon',
		value: 'OneBeacon',
	},
	{
		id: 113,
		title: 'Oregon Mutual',
		value: 'OregonMutual',
	},
	{
		id: 114,
		title: 'Palisades',
		value: 'Palisades',
	},
	{
		id: 115,
		title: 'Peerless Montgomery',
		value: 'PeerlessMontgomery',
	},
	{
		id: 116,
		title: 'Pekin',
		value: 'Pekin',
	},
	{
		id: 117,
		title: 'Pemco',
		value: 'Pemco',
	},
	{
		id: 118,
		title: 'Penn National',
		value: 'PennNational',
	},
	{
		id: 119,
		title: 'Plymouth Rock',
		value: 'PlymouthRock',
	},
	{
		id: 120,
		title: 'Preferred Mutual',
		value: 'PreferredMutual',
	},
	{
		id: 121,
		title: 'Proformance',
		value: 'Proformance',
	},
	{
		id: 122,
		title: 'Progressive',
		value: 'Progressive',
	},
	{
		id: 123,
		title: 'Prudential',
		value: 'Prudential',
	},
	{
		id: 124,
		title: 'Republic',
		value: 'Republic',
	},
	{
		id: 125,
		title: 'Rockford Mutual',
		value: 'RockfordMutual',
	},
	{
		id: 126,
		title: 'Royal And Sun Alliance',
		value: 'RoyalAndSunAlliance',
	},
	{
		id: 127,
		title: 'Safeco',
		value: 'Safeco',
	},
	{
		id: 128,
		title: 'SECURA',
		value: 'SECURA',
	},
	{
		id: 129,
		title: 'Selective',
		value: 'Selective',
	},
	{
		id: 130,
		title: 'Southern County',
		value: 'SouthernCounty',
	},
	{
		id: 131,
		title: 'Southern Mutual',
		value: 'SouthernMutual',
	},
	{
		id: 132,
		title: 'Southern Trust',
		value: 'SouthernTrust',
	},
	{
		id: 133,
		title: 'St. Paul Travelers',
		value: 'StPaulTravelers',
	},
	{
		id: 134,
		title: 'Standard Mutual',
		value: 'StandardMutual',
	},
	{
		id: 135,
		title: 'Star Casualty',
		value: 'StarCasualty',
	},
	{
		id: 136,
		title: 'State Auto',
		value: 'StateAuto',
	},
	{
		id: 137,
		title: 'State Farm',
		value: 'StateFarm',
	},
	{
		id: 138,
		title: 'Fidelity',
		value: 'Fidelity',
	},
	{
		id: 139,
		title: 'Travelers',
		value: 'Travelers',
	},
	{
		id: 140,
		title: 'TWFG',
		value: 'TWFG',
	},
	{
		id: 141,
		title: 'Unigard',
		value: 'Unigard',
	},
	{
		id: 142,
		title: 'United Fire And Casualty',
		value: 'UnitedFireAndCasualty',
	},
	{
		id: 143,
		title: 'Unitrin',
		value: 'Unitrin',
	},
	{
		id: 144,
		title: 'Universal',
		value: 'Universal',
	},
	{
		id: 145,
		title: 'USAA',
		value: 'USAA',
	},
	{
		id: 146,
		title: 'Utica National',
		value: 'UticaNational',
	},
	{
		id: 147,
		title: 'Victoria',
		value: 'Victoria',
	},
	{
		id: 148,
		title: 'West Bend',
		value: 'WestBend',
	},
	{
		id: 149,
		title: 'Western National',
		value: 'WesternNational',
	},
	{
		id: 150,
		title: 'Western Reserve Group',
		value: 'WesternReserveGroup',
	},
	{
		id: 151,
		title: 'Westfield',
		value: 'Westfield',
	},
	{
		id: 152,
		title: 'White Mountains',
		value: 'WhiteMountains',
	},
	{
		id: 153,
		title: 'Wilson Mutual',
		value: 'WilsonMutual',
	},
	{
		id: 154,
		title: 'Windsor',
		value: 'Windsor',
	},
	{
		id: 155,
		title: 'Zurich',
		value: 'Zurich',
	},
	{
		id: 156,
		title: 'Other Standard',
		value: 'OtherStandard',
	},
	{
		id: 157,
		title: 'Other Non Standard',
		value: 'OtherNonStandard',
	},
];
