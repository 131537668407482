import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, first, Observable, ReplaySubject } from 'rxjs';
import { LoaderActions } from 'src/app/store/actions';
import { selectLoaderInformation } from 'src/app/store/selectors/loader-information.selector';
import { getSelectedRate, selectFriendly, selectPaymentData } from 'src/app/store/selectors/result-data.selectors';
import { ILoaderInformationState } from 'src/app/store/states/loader-information.state';
import { FormattedDateService } from '../../../../../services/formatted-date.service';
import { Router } from '@angular/router';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { setIsQuoteCompleted } from '../../../../../store/actions/result-data.actions';
import { RoutingService } from '../../../../../services/routing.service';
import { selectApplicationId } from '../../../../../store/selectors/interview-metadata.selector';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('paymentMethodIframe') paymentMethodIframe: ElementRef;
	public loaderInformation$: Observable<ILoaderInformationState>;
	paymentData;
	selectedRate;
	friendlyID;
	PolicyStartDate = '';
	paymentMethodIframeUrl$: ReplaySubject<string> = new ReplaySubject(1);
	isEscrow = false;
	localStorageInterval;
	title: string;
	subtitle: string;

	constructor(
		private store: Store,
		private formattedDateService: FormattedDateService,
		private router: Router,
		private routingService: RoutingService
	) {
		this.store.dispatch(LoaderActions.ShowLoader());
		this.isEscrow = this.router.url.includes(StepsEnum.PAYMENT_ESCROW);
	}

	ngOnInit(): void {
		this.store
			.select(selectApplicationId)
			.pipe(first())
			.subscribe((appId) => {
				const paymentStatusKey = `paymentStatus-${appId}`;
				this.localStorageInterval = setInterval(() => {
					const paymentStatus = localStorage.getItem(paymentStatusKey);
					if (paymentStatus) {
						localStorage.removeItem('paymentStatus');
						if (paymentStatus === 'success') {
							this.store.dispatch(setIsQuoteCompleted({ data: true }));
							this.routingService.navigateToHomePage();
						}

						clearInterval(this.localStorageInterval);
					}
				}, 1000);
			});

		this.loaderInformation$ = this.store.select(selectLoaderInformation);

		// get all the data for the components
		this.store.dispatch(LoaderActions.ShowLoader());
		combineLatest([
			this.store.select(getSelectedRate),
			this.store.select(selectFriendly),
			this.store.select(selectPaymentData),
		])
			.pipe(first())
			.subscribe(([selectedRate, friendlyID, paymentData]) => {
				this.selectedRate = selectedRate;
				this.friendlyID = friendlyID;
				this.paymentData = { ...paymentData };
				if (this.paymentData.url) {
					this.paymentMethodIframeUrl$.next(this.paymentData.url);
				}
				this.store.dispatch(LoaderActions.Hideloader());
			});

		this.formattedDateService
			.getFormattedPolicyStartDate()
			.pipe(first())
			.subscribe((formattedDate) => {
				this.PolicyStartDate = formattedDate;
			});
	}

	ngOnDestroy() {
		clearInterval(this.localStorageInterval);
	}

	ngAfterViewInit() {}

	setIframeUrl(url: string) {
		// Iframe url is replaced programmatically to prevent the issue when Back button in browser
		// triggers navigation in iframe instead of parent page

		this.paymentMethodIframe.nativeElement.contentWindow.location.replace(url + '&output=embed');
	}
}
