<section class="main-content">
	<app-page-heading [subtitleKey]="subtitleKey" [titleKey]="titleKey"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="questions-container">
			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.PLTypeOfDwelling.name) && form.get(Fields.PLTypeOfDwelling.name).enabled }"
				class="question-wrapper field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.type' | translate"
					[name]="Fields.PLTypeOfDwelling.name"
					[options]="
						this.lob && this.lob !== LobsEnum.DWELLING_FIRE
							? FieldOptions.typeOfDwellingOptionsHouse
							: FieldOptions.typeOfDwellingOptionsHouseHO6
					"
					[placeholder]="'labels.chooseOption' | translate"
					[reAlphabeticalOrder]="false"
					[tabindex]="form.get(Fields.PLTypeOfDwelling.name) && form.get(Fields.PLTypeOfDwelling.name).enabled ? 0 : -1"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>

			<div
				*ngIf="this.typeProperty === LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.PLNumberOfUnits.name) && form.get(Fields.PLNumberOfUnits.name).enabled }"
				class="question-wrapper square-footage field-inline"
			>
				<unmasked-input
					*ngIf="form.get(Fields.PLNumberOfUnits.name) && form.get(Fields.PLNumberOfUnits.name).enabled"
					[name]="Fields.PLNumberOfUnits.name"
					[pattern]="Fields.PLNumberOfUnits.pattern"
					[placeholder]="'home.numberOfUnits' | translate"
					type="text"
				>
				</unmasked-input>
			</div>

			<div
				[ngClass]="{ show: form.get(Fields.SquareFootage.name) && form.get(Fields.SquareFootage.name).enabled }"
				class="question-wrapper square-footage field-inline"
			>
				<unmasked-input
					*ngIf="form.get(Fields.SquareFootage.name) && form.get(Fields.SquareFootage.name).enabled"
					[name]="Fields.SquareFootage.name"
					[pattern]="Fields.SquareFootage.pattern"
					[placeholder]="'home.squareFeetShort' | translate"
					type="text"
				>
				</unmasked-input>
			</div>

			<div
				[ngClass]="{ show: form.get(Fields.YearBuilt.name) && form.get(Fields.YearBuilt.name).enabled }"
				class="question-wrapper year-built field-inline"
			>
				<unmasked-input
					*ngIf="form.get(Fields.YearBuilt.name) && form.get(Fields.YearBuilt.name).enabled"
					[name]="Fields.YearBuilt.name"
					[pattern]="Fields.YearBuilt.pattern"
					[placeholder]="'home.yearBuilt' | translate"
					maxlength="4"
					type="text"
				>
				</unmasked-input>
			</div>
			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.ArchitectureStyle.name) && form.get(Fields.ArchitectureStyle.name).enabled }"
				class="question-wrapper architecture-style field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.architecturalStyle' | translate"
					[name]="Fields.ArchitectureStyle.name"
					[options]="FieldOptions.ArchitectureStyleOptions"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="
						form.get(Fields.ArchitectureStyle.name) && form.get(Fields.ArchitectureStyle.name).enabled ? 0 : -1
					"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>

			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.RoofType.name) && form.get(Fields.RoofType.name).enabled }"
				class="question-wrapper roof-type field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.roofMaterial' | translate"
					[name]="Fields.RoofType.name"
					[options]="FieldOptions.RoofTypeOptions"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="form.get(Fields.RoofType.name) && form.get(Fields.RoofType.name).enabled ? 0 : -1"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>

			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.MitRoofShape.name) && form.get(Fields.MitRoofShape.name).enabled }"
				class="question-wrapper roof-shape field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.roofShape' | translate"
					[name]="Fields.MitRoofShape.name"
					[options]="FieldOptions.MitRoofShapeOptions"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="form.get(Fields.MitRoofShape.name) && form.get(Fields.MitRoofShape.name).enabled ? 0 : -1"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>

			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.TypeOfFoundation.name) && form.get(Fields.TypeOfFoundation.name).enabled }"
				class="question-wrapper foundation-type field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.foundation' | translate"
					[name]="Fields.TypeOfFoundation.name"
					[options]="FieldOptions.TypeOfFoundation"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="form.get(Fields.TypeOfFoundation.name) && form.get(Fields.TypeOfFoundation.name).enabled ? 0 : -1"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>

			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{ show: form.get(Fields.PLNumberOfStories.name) && form.get(Fields.PLNumberOfStories.name).enabled }"
				class="question-wrapper number-of-stories field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.numberOfStories' | translate"
					[name]="Fields.PLNumberOfStories.name"
					[options]="FieldOptions.numOfStoriesOptions"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="
						form.get(Fields.PLNumberOfStories.name) && form.get(Fields.PLNumberOfStories.name).enabled ? 0 : -1
					"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>
			<div
				*ngIf="this.typeProperty !== LobsEnum.CONDOMINIUM"
				[ngClass]="{
					show: form.get(Fields.PLConstructionType.name) && form.get(Fields.PLConstructionType.name).enabled
				}"
				class="question-wrapper construction-type field-inline"
			>
				<app-dropdown
					[labelInsideField]="'home.constructionType' | translate"
					[name]="Fields.PLConstructionType.name"
					[options]="FieldOptions.constructionTypeOptions"
					[placeholder]="'labels.chooseOption' | translate"
					[tabindex]="
						form.get(Fields.PLConstructionType.name) && form.get(Fields.PLConstructionType.name).enabled ? 0 : -1
					"
					class="app-dropdown-custom"
				>
				</app-dropdown>
			</div>
		</div>
	</form>

	<next-button
		(validationFailed)="handleValidation($event)"
		[disabled]="!form.valid"
		[myForm]="myform"
		[title]="'buttons.continue' | translate"
	>
	</next-button>
</section>
