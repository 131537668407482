import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActionApiService } from 'src/app/services/action-api.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { Location } from '@angular/common';
import { Validations } from 'src/app/utils/validation';
import { BasePageComponent } from 'src/app/components/layout/pages/base-page.component';
import {
	CARRIERS_LIST,
	PRIOR_LIABILITY_LIMITS_AUTO_OPTIONS,
	REASONS_FOR_NO_PRIOR_INSURANCE_OPTIONS,
} from './models/additional-questions-options';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { NextButtonComponent } from '../../../../../../../next-button/next-button.component';
import { PrebindService } from '../../../../../../../../services/pre-bind.service';

@Component({
	selector: 'app-safeco-policy',
	templateUrl: './safeco-policy.component.html',
	styleUrls: ['./safeco-policy.component.scss'],
})
export class SafecoPolicyComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	YearsWithPriorCarrierAuto;
	isNCstate = false;

	carriersList = CARRIERS_LIST;
	reasonsNoPrior = REASONS_FOR_NO_PRIOR_INSURANCE_OPTIONS;
	priorLiabilityLimitsAutoOptions = PRIOR_LIABILITY_LIMITS_AUTO_OPTIONS;

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		private location: Location,
		private preBindService: PrebindService
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();
		setTimeout(() => {
			this.patchData(this.form);
		});
		this.preBindService.startPrebind(CarriersEnum.SafecoInsurance, LobsEnum.PERSONAL_AUTO, null, false);
		this.registerOnChange(this.form);

		const state = sessionStorage.getItem('state');
		const stateJson: IAppState = JSON.parse(state);
		var currentState = stateJson.quoteData.PropertyAddress.State;
		if (currentState == 'NC') {
			this.isNCstate = true;
		}
		this.AddNCQuesiton();
	}

	AddNCQuesiton() {
		if (this.isNCstate) {
			this.form.controls['FQData.SafecoPersonalPersonalAuto.ResidencyVerificationNC'].enable();
		} else {
			this.form.controls['FQData.SafecoPersonalPersonalAuto.ResidencyVerificationNC'].disable();
		}
	}

	initform(): void {
		this.form = new FormGroup({
			AutoInsuranceCancelled: new FormControl({ value: null, disabled: false }, [Validators.required]),
			CurrentPersonalAutoCarrier: new FormControl({ value: null, disabled: false }, [Validators.required]),
			SelectReasonNoPriorAutoInsurance: new FormControl({ value: null, disabled: false }, [Validators.required]),
			PriorCarrierExpirationDateAuto: new FormControl({ value: null, disabled: false }, [
				Validators.required,
				Validations.dateFormat,
				Validations.isPastDate,
			]),
			YearsWithContinuousCoverageAuto: new FormControl({ value: null, disabled: false }, [Validators.required]),
			YearsWithPriorCarrierAuto: new FormControl({ value: null, disabled: false }, [Validators.required]),
			SelectPriorLiabilityLimitsAuto: new FormControl({ value: null, disabled: false }, [Validators.required]),
			// TypeOfResidence: new FormControl({ value: null, disabled: false }, [Validators.required]),
			'FQData.SafecoPersonalPersonalAuto.VehDeliveryUse': new FormControl({ value: null, disabled: false }, [
				Validators.required,
			]),

			'FQData.SafecoPersonalPersonalAuto.ResidencyVerificationNC': new FormControl({ value: null, disabled: true }, [
				Validators.required,
			]),
		});

		//TODO implement asyncvalidator
		this.form.controls.YearsWithContinuousCoverageAuto?.valueChanges.subscribe((value) => {
			if (
				/^\d+$/.test(this.form.controls.YearsWithContinuousCoverageAuto.value) &&
				/^\d+$/.test(this.form.controls.YearsWithPriorCarrierAuto.value)
			) {
				if (
					parseInt(this.form.controls.YearsWithContinuousCoverageAuto.value) <
					parseInt(this.form.controls.YearsWithPriorCarrierAuto.value)
				) {
					this.form.controls.YearsWithPriorCarrierAuto.setErrors({ yearspriorcannotbehigercontinuous: true });
					this.form.controls.YearsWithPriorCarrierAuto.markAsTouched();
					this.form.updateValueAndValidity();
				} else {
					this.form.controls.YearsWithPriorCarrierAuto.setErrors(null);
					this.form.updateValueAndValidity();
				}
			}
		});

		this.form.controls.YearsWithPriorCarrierAuto?.valueChanges.subscribe((value) => {
			if (
				this.form.controls.YearsWithContinuousCoverageAuto.value !== null &&
				this.form.controls.YearsWithPriorCarrierAuto.value !== null
			) {
				if (
					parseInt(this.form.controls.YearsWithContinuousCoverageAuto.value) <
					parseInt(this.form.controls.YearsWithPriorCarrierAuto.value)
				) {
					this.form.controls.YearsWithPriorCarrierAuto.setErrors({ yearspriorcannotbehigercontinuous: true });
					this.form.updateValueAndValidity();
				} else {
					if (this.form.controls.YearsWithPriorCarrierAuto.errors != null) {
						this.form.controls.YearsWithPriorCarrierAuto.setErrors(this.form.controls.YearsWithPriorCarrierAuto.errors);
						this.form.updateValueAndValidity();
					} else {
						this.form.controls.YearsWithPriorCarrierAuto.setErrors(null);
					}
				}
			}
		});

		this.form.controls.CurrentPersonalAutoCarrier?.valueChanges.subscribe((value) => {
			if (value == 'NoPriorInsurance') {
				let formsToReset = [
					this.form.controls.PriorCarrierExpirationDateAuto,
					this.form.controls.YearsWithPriorCarrierAuto,
					this.form.controls.YearsWithContinuousCoverageAuto,
					this.form.controls.SelectPriorLiabilityLimitsAuto,
				];
				this.form.controls.SelectReasonNoPriorAutoInsurance.addValidators(Validators.required);
				this.resetForms(formsToReset);
			} else {
				this.resetForms([this.form.controls.SelectReasonNoPriorAutoInsurance]);
				this.form.controls.PriorCarrierExpirationDateAuto.addValidators([
					Validators.required,
					Validations.dateFormat,
					Validations.isPastDate,
				]);
				this.form.controls.YearsWithPriorCarrierAuto.addValidators(Validators.required);
				this.form.controls.YearsWithContinuousCoverageAuto.addValidators(Validators.required);
				this.form.controls.SelectPriorLiabilityLimitsAuto.addValidators(Validators.required);
				this.form.updateValueAndValidity();
			}
		});
	}

	ngAfterViewInit(): void {
		if (
			parseInt(this.form.controls.YearsWithContinuousCoverageAuto.value) <
			parseInt(this.form.controls.YearsWithPriorCarrierAuto.value)
		) {
			this.form.controls.YearsWithPriorCarrierAuto.setErrors({ yearspriorcannotbehigercontinuous: true });
			this.form.controls.YearsWithPriorCarrierAuto.markAsTouched();
			this.form.updateValueAndValidity();
		}
		this.baseNgAfterViewInit();
	}

	resetForms(formsToReset) {
		for (let i = 0; i <= formsToReset.length - 1; i++) {
			formsToReset[i].clearValidators();
			formsToReset[i].setValue(null);
			formsToReset[i].markAsUntouched();
			formsToReset[i].markAsPristine();
			this.form.updateValueAndValidity();
		}
	}

	handleContinue() {
		if (this.form.valid) {
			Object.keys(this.form.value).forEach((key) => {
				if (this.form.value[key] === null) {
					delete this.form.value[key];
				}
			});
			this.actionApiService
				.updateApplication(this.form.value, [LobsEnum.PERSONAL_AUTO], CarriersEnum.SafecoInsurance)
				.subscribe((serverRes) => {
					this.onUpdateApplicationResult(serverRes);
				});
		}
	}

	goBack() {
		this.location.back();
	}
}
