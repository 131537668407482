import { IAppState } from '../states/app.state';
import { createSelector } from '@ngrx/store';

const quoteData = (state: IAppState) => state.quoteData;

export const selectQuoteData = createSelector(quoteData, (state) => state);
export const selectFirstName = createSelector(quoteData, (state) => state.FirstName);
export const selectEffectiveDate = createSelector(quoteData, (state) => state.EffectiveDate);
export const selectOccupancyType = createSelector(quoteData, (state) => state.OccupancyType);
export const selectPLYearBuilt = createSelector(quoteData, (state) => state.PLYearBuilt);
export const selectAddress = createSelector(quoteData, (state) => state.PropertyAddress);
export const selectDrivers = createSelector(quoteData, (state) => state.Drivers);
export const selectManualDrivers = createSelector(quoteData, (state) => state.manualDrivers);
export const selectDiscoverDrivers = createSelector(quoteData, (state) => state.discoverDrivers);
export const selectManualVehicles = createSelector(quoteData, (state) => state.manualVehicles);
export const selectDiscoverVehicles = createSelector(quoteData, (state) => state.discoverVehicles);
export const selectPersonalVehicles = createSelector(quoteData, (state) => state.PersonalVehicles);
export const selectAllDrivers = createSelector(quoteData, (state) => state.additionalQuestionsProgressiveDrivers);
export const selectLobQuoteData = createSelector(quoteData, (state) => state.Lobs);

