export const dataInjectPrefix = {
	PersonalLossPLLossDescription1:
		"PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f16')].PLLossDescription",
	PersonalLossesDateOfLoss1: "PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f16')].DateOfLoss",
	PersonalLineLossAmount1: "PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f16')].PersonalLineLossAmount",
	PersonalLossPLLossDescription2:
		"PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f17')].PLLossDescription",
	PersonalLossesDateOfLoss2: "PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f17')].DateOfLoss",
	PersonalLineLossAmount2: "PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f17')].PersonalLineLossAmount",
	PersonalLossPLLossDescription3:
		"PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f18')].PLLossDescription",
	PersonalLossesDateOfLoss3: "PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f18')].DateOfLoss",
	PersonalLineLossAmount3: "PersonalLosses[?(@__id__=='9480f348-9447-41ea-9b44-16aad46b4f18')].PersonalLineLossAmount",
};
export const keyUpdate = (key) => {
	if (dataInjectPrefix[key]) {
		return dataInjectPrefix[key];
	}
	return key;
};
