import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ControlsType } from 'src/app/constants/controls-type';
import { IAppState } from 'src/app/store/states/app.state';
import { BaseFormControlComponent } from '../base-form-control.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionApiService } from 'src/app/services/action-api.service';
import { EventsService } from 'src/app/services/events.service';
import { isUndefined } from '../../../utils/general.utils';
import { nfd } from 'unorm';

@Component({
	selector: 'app-address-suggestion',
	templateUrl: './address-suggestion.component.html',
	styleUrls: ['./address-suggestion.component.scss'],
})
export class AddressSuggestionComponent extends BaseFormControlComponent implements OnInit, AfterViewInit {
	@ViewChild('placesRef') placesRef: GooglePlaceDirective;

	@Input() maxLength: number;
	@Input() minlength: number;
	@Input() title: string;
	@Input() form: FormGroup;
	@Input() addressLine1: string;
	@Input() addressLine2: string;
	@Input() city: string;
	@Input() state: string;
	@Input() zipCode: string;
	@Input() county: string;
	@Input() fieldsNames;
	@Input() toShowMap: boolean;
	@Input() placeholder;
	@Input() topPlaceholder: boolean;
	@Input() showIcon: boolean;
	@Input() addressFormName: string;

	@Output() addressChangedEvent: EventEmitter<any> = new EventEmitter();

	oldValue: any;
	unitValue;
	showMap: boolean;
	addressAutoComplete = 'addressAutoComplete';
	options = {
		componentRestrictions: { country: 'us' },
	};

	myForm: FormGroup;
	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
		this.controlType = ControlsType.address;
		this.name = this.addressAutoComplete;
	}

	get valueUnits(): string {
		if (this.fieldsNames['AddressLine2'] && this.form.get(this.fieldsNames['AddressLine2'])) {
			return this.form.get(this.fieldsNames['AddressLine2']).value;
		}
		return '';
	}

	set valueUnits(val) {
		this.form.get(this.fieldsNames['AddressLine2']).patchValue(val);
	}

	ngOnInit(): void {
		this.myForm = new FormGroup({
			name: new FormControl('', [Validators.minLength(10)]),
		});

		super.ngOnInit();
		if (isUndefined(this.topPlaceholder)) {
			this.topPlaceholder = true;
		}

		this.initForm();
		this.setValue();

		setTimeout(() => {
			if (this.addressLine1 && this.city && this.state && this.zipCode) {
				this.value = `${this.addressLine1}, ${this.city}, ${this.state} ${this.zipCode}`;
				this.unitValue = this.addressLine2;
			}
		}, 0);
	}

	ngOnChanges() {
		this.setValue();
		setTimeout(() => {
			if (this.addressLine1 && this.city && this.state && this.zipCode) {
				this.value = `${this.addressLine1}, ${this.city}, ${this.state} ${this.zipCode}`;
				this.unitValue = this.addressLine2;
			}
		}, 0);
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	initForm(): void {
		this.form.addControl(this.addressAutoComplete, new FormControl({ value: null, disabled: false }));
	}

	handleAddressChange(address: Address) {
		if (this.toShowMap) {
			this.updateMap(address);
		}
		this.onAddressSelect(address);
		this.setValue();
	}

	updateMap(address: Address) {
		new google.maps.Marker({
			position: address.geometry.location,
			map: new google.maps.Map(document.getElementById('map'), {
				center: address.geometry.location,
				zoom: 15,
			}),
		});
	}

	isInvalid() {
		let field = this.controlContainer.control.controls[this.name];

		return field ? field.invalid && field.touched : false;
	}

	onFocus() {
		super.baseOnFocus();
		this.form.controls[this.addressAutoComplete].markAsTouched();
	}

	setValue(): void {
		if (this.value) {
			this.form.controls[this.addressAutoComplete].setValue(this.value);

			if (!this.isPrefilled) {
				super.prefillEdit();
			} else {
				super.editAction();
			}
		}
	}

	onAddressSelect(addressContainer: Address) {
		this.addressLine1 = null;
		this.addressLine2 = null;
		this.city = null;
		this.state = null;
		this.zipCode = null;
		this.county = null;

		addressContainer.address_components.forEach((item) => {
			const itemType = item.types[0];

			const itemValue = item.short_name;
			switch (itemType) {
				case 'route':
					this.addressLine1 = this.addressLine1 ? this.addressLine1 + ' ' + itemValue : itemValue;
					break;
				case 'street_number': {
					this.addressLine1 = this.addressLine1 ? itemValue + ' ' + this.addressLine1 : itemValue;
					break;
				}
				case 'neighborhood':
				case 'locality': {
					this.city = itemValue;
					break;
				}
				case 'administrative_area_level_1': {
					this.state = itemValue;
					break;
				}
				case 'postal_code': {
					this.zipCode = itemValue;
					break;
				}
				case 'administrative_area_level_2': {
					if (itemValue) {
						this.county = nfd(itemValue).replace(/[\u0300-\u036f]/g, '');
					}
					break;
				}
				default: {
					break;
				}
			}
		});

		this.controlContainer.control.get(this.fieldsNames['AddressLine1']).patchValue(this.addressLine1);
		this.controlContainer.control.get(this.fieldsNames['City']).patchValue(this.city);
		this.controlContainer.control.get(this.fieldsNames['State']).patchValue(this.state);
		this.controlContainer.control.get(this.fieldsNames['ZipCode']).patchValue(this.zipCode);
		if (this.controlContainer.control.get(this.fieldsNames['County'])) {
			this.controlContainer.control.get(this.fieldsNames['County']).patchValue(this.county);
		}

		this.getFormattedAddress();
		this.setValue();
		this.showMap = true;
		this.addressChangedEvent.emit();
	}

	updateUnitValue(e) {
		this.valueUnits = e.currentTarget.value;
	}

	getFormattedAddress(): string {
		return (
			(this.addressLine1 ? this.addressLine1 : '') +
			(this.addressLine2 ? this.addressLine2 : '') +
			(this.city ? ', ' + this.city : '') +
			(this.state ? ', ' + this.state : '') +
			(this.zipCode ? ' ' + this.zipCode : '')
		);
	}

	validateNumsLettersSpace(event) {
		this.value = event.value.replace(/[^0-9a-zA-Z, ]/g, '');
	}

	validateNumsLettersSpaceSpecialChars(event) {
		this.valueUnits = event.addressLine2.replace(/[^0-9a-zA-Z-'.]/g, '');
	}
}
