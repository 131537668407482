import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
	name: 'formatCoverageName',
})
export class FormatCoverageNamePipe implements PipeTransform {
	transform(covName: string): string {
		if (covName !== undefined && covName !== null) {
			const arrayOfWords = covName
				.replace(/([A-Z])/g, ' $1')
				.split(' ')
				.map((word) => {
					if (['of', 'and', 'or', 'in', 'my'].includes(word.toLowerCase())) {
						return word.toLowerCase();
					}

					return word.charAt(0).toUpperCase() + word.slice(1);
				});

			return arrayOfWords.join(' ');
		}
		return null;
	}
}
