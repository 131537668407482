import { Component, ElementRef, Injector, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import * as moment from 'moment';
import * as zenscroll from 'zenscroll';
import { interval, Observable, of, ReplaySubject } from 'rxjs';
import { debounce, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Fields } from '../../../constants/fields';
import { MergeQuoteData, UpdateQuoteData } from '../../../store/actions/quote-data.actions';
import { IQuoteDataState } from '../../../store/states/quote-data.state';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { selectQuoteData } from '../../../store/selectors/quote-data.selectors';
import * as FieldOptions from '../../../constants/field-options';
import { StepsEnum } from '../../../enums/steps.enum';
import { disableControl, enableControl, isUndefined, PLDwellingMap } from 'src/app/utils/general.utils';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { keyUpdate } from 'src/app/utils/quote.data.utils';
import { D2CFeatures, IInterviewMetadataState } from 'src/app/store/states/interview-metadata.state';
import { selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { RoutingService } from 'src/app/services/routing.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { ActionApiService } from 'src/app/services/action-api.service';
import { IAppState } from 'src/app/store/states/app.state';
import { ICondition, IField, IFieldItem } from 'src/entities/field.interface';
import { SourcesEnum } from 'src/app/enums/sources.enum';
import { IQuoteError, IServerRes } from '../../../../entities/payload.interface';
import { PrefillIndication } from 'src/entities/prefill-indication.interface';
import { LoaderActions } from 'src/app/store/actions';
import { LobService } from 'src/app/services/lob.service';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { NextButtonComponent } from '../../next-button/next-button.component';
import { RetrieveQuoteService } from 'src/app/services/retrieve-quote.service';
import { ThemeModel } from '../../../models/theme.model';
import { selectActiveTheme } from '../../../store/selectors/active-theme.selector';
import { GeneralPropsEnum } from '../../../models/general-props.enum';
import { NavigateToNextPage } from 'src/app/store/actions/routing.actions';
import { EventsService } from 'src/app/services/events.service';
import { EventActions } from 'src/app/models/event-data.model';

const DATE_FORMAT = 'MM/DD/YYYY';
const MOMENT_VALUE_FIELDS = ['DateOfBirth'];
const PHONE_NUMBER_VALUE_FIELDS = ['PrimaryPhoneNumber'];

@UntilDestroy()
@Component({
	selector: 'app-base-page',
	template: '',
})
export class BasePageComponent implements OnInit, OnDestroy {
	@ViewChildren(NextButtonComponent) nextButtonComponents!: QueryList<NextButtonComponent>;
	Fields: IField = Fields;
	FieldOptions = FieldOptions;
	StepsEnum = StepsEnum;
	quoteData$: Observable<IQuoteDataState>;
	quoteData: IQuoteDataState;
	formBuilder: FormBuilder;
	elRef: ElementRef;
	lobs: LobsEnum;
	sources: SourcesEnum;
	interviewMetadata$: Observable<IInterviewMetadataState>;
	phoneNumber: string;
	SsnScriptUrl: string;
	routingService: RoutingService;
	form: FormGroup;
	actionService: ActionApiService;
	retrieveQuoteService: RetrieveQuoteService;
	policyId: string;
	destroy = new ReplaySubject(1);
	title: string;
	subtitle: string;
	titleKey: string;
	subtitleKey: string;
	loaderTitle: string;
	loaderSubTitle: string;
	prefillIndication: PrefillIndication[];
	getLobService: LobService;
	progressMeterService: ProgressMeterService;
	eventsService: EventsService;
	isEscrowFlow = false;
	theme: ThemeModel;
	EventActions = EventActions;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		protected quoteDataService: QuoteDataService = null
	) {
		this.formBuilder = injector.get(FormBuilder);
		this.elRef = injector.get(ElementRef);
		this.routingService = injector.get(RoutingService);
		this.actionService = injector.get(ActionApiService);
		this.retrieveQuoteService = injector.get(RetrieveQuoteService);
		this.getLobService = injector.get(LobService);
		this.progressMeterService = injector.get(ProgressMeterService);
		this.eventsService = injector.get(EventsService);
		this.isEscrowFlow = this.progressMeterService.isEscrowFlow();
	}

	private _subTitle: string;

	public get subTitle(): string {
		return this._subTitle;
	}

	public set subTitle(value: string) {
		this._subTitle = value;
	}

	get d2CFeatures(): D2CFeatures {
		return JSON.parse(sessionStorage.getItem('leadSource'))?.leadSource?.additionalInformation?.d2CFeatures;
	}

	private static formatMomentDateValue(value): string {
		if (value.includes('X')) {
			return 'XX/XX/' + value.match(/\d{4}/)[0];
		}
		return moment(value).format(DATE_FORMAT);
	}

	private static isKeyDateFormatNeeded(key): boolean {
		return MOMENT_VALUE_FIELDS.includes(key);
	}

	baseNgAfterViewInit(): void {
		const mainContent = this.elRef.nativeElement.querySelector('.main-content');

		if (mainContent) {
			mainContent.classList.add('show');
		}
	}

	ngOnInit(pageName?: string): void {
		this.quoteData$ = this.store.select(selectQuoteData);
		this.quoteData$.subscribe((res) => {
			this.quoteData = res;
		});

		this.interviewMetadata$ = this.store.select(selectInterviewMetadata);
		this.interviewMetadata$.subscribe((res) => {
			this.phoneNumber = res.PhoneNumber;
			this.SsnScriptUrl = res.ssnScriptUrl;
			this.policyId = res.PolicyId;
			this.sources = res.SourceKeyword;
			this.prefillIndication = res.prefillIndication;
		});

		if (this.quoteDataService) {
			this.quoteDataService.serverQuoteErrors.pipe(untilDestroyed(this)).subscribe((serverRes: any) => {
				if (serverRes && serverRes.validationErrors) {
					this.setServerErrors(serverRes);
				}
			});
		}

		this.store.dispatch(LoaderActions.Hideloader());

		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.theme = theme;

				if (pageName) {
					this.title = this.getPageTexts(pageName).title;
					this.subtitle = this.getPageTexts(pageName).subTitle;
				}
			});
	}

	getPageTexts(pageName: string): any {
		return this.theme[GeneralPropsEnum.texts][pageName];
	}

	setServerErrors(serverRes: IServerRes) {
		let hasErrorsFromServerForThisPage = false;
		const relevantError: IQuoteError[] = [];
		serverRes.validationErrors.forEach((qError) => {
			const field = this.form?.controls[qError.id];

			if (field) {
				relevantError.push(qError);
				field.setErrors({ serverError: qError.errors[0] });
				field.markAsTouched();
				if (!hasErrorsFromServerForThisPage) {
					hasErrorsFromServerForThisPage = true;
				}
			}
		});

		if (hasErrorsFromServerForThisPage) {
			this.handleValidation(relevantError);
		}

		this.quoteDataService.hasErrorsFromServerForThisPage.next(hasErrorsFromServerForThisPage);
	}

	ngOnDestroy(): void {
		this.destroy.next(true);
		this.destroy.complete();
	}

	registerOnChange(form: FormGroup, merge = false, deleteOnEmpty = true) {
		// deleteOnEmpty - if true, when the value is empty, it will be deleted from the store
		form.statusChanges.pipe(debounce(() => interval(300))).subscribe(() => {
			const dataObj = form.getRawValue();
			const dataWithValues = {};
			for (const [key, value] of Object.entries(dataObj)) {
				const newKey = keyUpdate(key);
				if (newKey !== key) {
					dataWithValues[newKey] = value;
				}
				dataWithValues[key] = value;

				if (this.isKeyPhoneNumberFormatNeeded(key)) {
					dataWithValues[key] = this.formatPhoneNumberValue(value);
				}
			}
			if (Object.keys(dataWithValues).length === 0) {
				return;
			}

			if (merge) {
				this.store.dispatch(
					MergeQuoteData({
						data: dataWithValues,
						deleteOnEmpty,
					})
				);
			} else {
				this.store.dispatch(
					UpdateQuoteData({
						data: dataWithValues,
					})
				);
			}
		});
	}

	patchData(form: FormGroup, shouldEmitEvents: boolean = true) {
		this.quoteData$.pipe(first()).subscribe((quoteData: IQuoteDataState) => {
			this.lobs = quoteData['Lobs[]'];
			Object.entries(quoteData).forEach(([key, value]) => {
				// value can be false don't use !value
				if (value === null || value === undefined || value === '') {
					return;
				}

				if (form.controls[key]) {
					// mapping values for PLTypeOfDwelling  value (for Renters Only)
					if (key === 'PLTypeOfDwelling') {
						this.getLobService
							.getLob$()
							.pipe(first())
							.subscribe((lob) => {
								if (lob === LobsEnum.RENTERS) {
									if (value) {
										value = PLDwellingMap(value);
										this.store.dispatch(UpdateQuoteData({ data: { PLTypeOfDwelling: value } }));
									} else {
										this.store.dispatch(UpdateQuoteData({ data: { PLTypeOfDwelling: '' } }));
									}
								} else if (lob === LobsEnum.PERSONAL_HOME) {
									// fixing bug 126523 when the user choose condo and then get back and choose home
									// TODO - REFACTOR
									if (value === LobsEnum.CONDOMINIUM) {
										value = null;
									}
								}
							});
					}

					if (key === 'D2CAgreeToTerms') {
						value = quoteData['CustomFields']['D2CAgreeToTerms'] === 'True';
					}

					if (key === 'PriorCarrierExpirationDateAuto') {
						value = moment(value).format(DATE_FORMAT);
					}
					if (key === Fields.CoDateOfBirth.name) {
						const regex = /\d{4}/g;
						const matches = value.match(regex);
						if (matches && matches.length > 0) {
							value = BasePageComponent.formatMomentDateValue(value);
						}
					}
					if (key === Fields.HeatingUpdateYN.name && quoteData[Fields.HeatingUpdateYN.name]) {
						value = quoteData[Fields.SW_HeatingUpdateYear.name];
					}
					if (key === Fields.ElectricalUpdateYN.name && quoteData[Fields.SW_ElectricalUpdatedYear.name]) {
						value = quoteData[Fields.SW_ElectricalUpdatedYear.name];
					}
					if (key === Fields.PlumbingUpdateYN.name && quoteData[Fields.SW_PlumbingUpdatedYear.name]) {
						value = quoteData[Fields.SW_PlumbingUpdatedYear.name];
					}

					this.getLobService
						.getLob$()
						.pipe(first())
						.subscribe((lob) => {
							if (key === 'DateOfBirth' && (lob === LobsEnum.PERSONAL_AUTO || lob === LobsEnum.HOME_AUTO)) {
								if (value) {
									const regex = /\d{4}/g;
									const matches = value.match(regex);
									if (matches && matches.length > 0) {
										value = 'XX/XX/' + matches[0];
									}
								}
							}
						});

					if (BasePageComponent.isKeyDateFormatNeeded(key)) {
						value = BasePageComponent.formatMomentDateValue(value);
					}

					if (!Array.isArray(value)) {
						form.patchValue({ [key]: value }, { onlySelf: true, emitEvent: shouldEmitEvents });
					}
				}
			});

			this.initMissingQuoteDataFields(form, quoteData);
		});
	}

	patchDataArray(form, key) {
		if (!key) {
			return;
		}

		this.quoteData$.pipe(first()).subscribe((quoteData: IQuoteDataState) => {
			if (!quoteData[key]) {
				return;
			}

			switch (key) {
				case 'PersonalLosses':
					let SequenceNum = 0;
					quoteData[key].forEach((item) => {
						if (item.DateOfLoss) {
							const lossItem = this.formBuilder.group({
								Id: item.Id,
								SequenceNum: SequenceNum++,
								DateOfLoss: [item.DateOfLoss, Validators.required],
								PersonalLineLossAmount: [item.PersonalLineLossAmount, Validators.required],
								PLLossDescription: [item.PLLossDescription, Validators.required],
								active: true,
							});
							const formArrayControl = form.controls[key] as FormArray;
							formArrayControl.push(lossItem);
						}
					});
					break;

				default:
					break;
			}
		});
	}

	formatPhoneNumberValue(value): string {
		return value ? value.replace(/[() ]/g, '') : '';
	}

	isKeyPhoneNumberFormatNeeded(key: string): boolean {
		return PHONE_NUMBER_VALUE_FIELDS.includes(key);
	}

	handleValidation(e) {
		setTimeout(() => {
			const elemToScroll = document.getElementsByClassName('error-wrapper');
			if (elemToScroll && elemToScroll.length > 0) {
				zenscroll.setup(800, document.getElementsByTagName('app-header')[0].getBoundingClientRect().height + 30);
				zenscroll.to(elemToScroll[0]);
			}
		}, 0);
	}

	// if it is => check if we are in the relevent page
	checkPage(page: StepsEnum[]): boolean {
		if (page && page.length > 0) {
			const isRelevantPage = page.find((name: StepsEnum) => name === this.routingService.currentRoute);
			return !!isRelevantPage;
		}
		return true;
	}

	getFieldValue(form: FormGroup, controlName: string, valueFromQuote: boolean): any {
		return form.controls[controlName] && !valueFromQuote
			? form.controls[controlName] && form.controls[controlName].value
			: this.quoteData[controlName];
	}

	disableControlAndChildren(field: IFieldItem, control: AbstractControl, form: FormGroup) {
		let childControl;
		disableControl(control);
		if (!field.children) {
			return;
		}
		field.children.forEach((childField: ICondition) => {
			childControl = form.controls[childField.name];
			disableControl(childControl);
		});
	}

	// check if there the condition is for a specific page
	isLobDisable(field: IFieldItem): boolean {
		if (field && field.disableLobs && this.lobs) {
			return field.disableLobs.some((ai) => this.lobs.includes(ai));
		}
		return false;
	}

	elementExist(selector): boolean {
		const elem = this.elRef.nativeElement.querySelector(selector);
		return !!elem;
	}

	handleCheckboxLogic(item, form: FormGroup) {
		const field = this.Fields[item];
		const children: IFieldItem[] = [];
		let checkboxHasValue = false;
		if (field && field.isCheckbox) {
			this.FieldOptions[field.isCheckbox].forEach((childField: IFieldItem) => {
				children.push(childField);
				const childControl = form.controls[childField.name];
				if (childControl && !!childControl.value) {
					checkboxHasValue = true;
				}
			});

			children.forEach((element) => {
				const childControl = form.controls[element.name];
				checkboxHasValue ? enableControl(childControl, element.name) : disableControl(childControl);
			});
		}
	}

	setReproducibleErrors(
		res: IServerRes,
		reproducibleFormsArray: FormGroup[],
		hasNonReproducibleErrorsFromServerForThisPage?: boolean
	) {
		let hasErrorsFromServerForThisPage = hasNonReproducibleErrorsFromServerForThisPage === true;
		const relevantError: IQuoteError[] = [];

		res.validationErrors.forEach((qError) => {
			const field = this.getReproducibleFormControlById(qError.id, reproducibleFormsArray);

			if (field) {
				field.setErrors({ serverError: qError.errors[0] });
				field.markAsTouched();

				if (!hasErrorsFromServerForThisPage) {
					hasErrorsFromServerForThisPage = true;
				}
			}

			if (hasErrorsFromServerForThisPage) {
				this.handleValidation(relevantError);
			}

			this.quoteDataService.hasErrorsFromServerForThisPage.next(hasErrorsFromServerForThisPage);
		});
	}

	getReproducibleControlIdFromFieldId(fieldId: string): string {
		const match = fieldId.match(/@Id=='(.*)'/);
		return (match && match[1]) || null;
	}

	getReproducibleControlNameFromFieldId(fieldId: string): string {
		const match = fieldId.match(/\.(.*)/);
		return (match && match[1]) || null;
	}

	getReproducibleFormControlById(id: string, reproducibleFormsArray: FormGroup[]): FormControl | null {
		const reproducibleId = this.getReproducibleControlIdFromFieldId(id);
		const controlName = this.getReproducibleControlNameFromFieldId(id);
		const reproducibleForm = reproducibleFormsArray.find(
			(control: FormGroup) => control.value.Id === reproducibleId
		) as FormGroup;

		return reproducibleId && controlName && reproducibleForm
			? (reproducibleForm.controls[controlName.split('.')[0]] as FormControl)
			: null;
	}

	getIndexOfFirstReproducibleWithError(serverRes: IServerRes, reproducibleFormsArray: FormGroup[]): number {
		if (serverRes.validationErrors && serverRes.validationErrors.length) {
			//get all reproducible control ids that have errors
			const errorIds = serverRes.validationErrors.map((error) => this.getReproducibleControlIdFromFieldId(error.id));

			if (errorIds.length > 0) {
				//find the first reproducible control that has an error
				for (const control of reproducibleFormsArray) {
					const index = errorIds.indexOf(control.value.Id);
					if (index !== -1) {
						return reproducibleFormsArray.indexOf(control);
					}
				}
			}
		}
		return -1;
	}

	onUpdateApplicationResult(
		serverRes: IServerRes,
		reproducibleFormsArray?: FormGroup[],
		preventNavigationToNext?: boolean
	): Observable<number> {
		setTimeout(() => {
			this.nextButtonComponents.forEach((nextButton) => (nextButton.loading = false));
		});

		let invalidReproducibleIndex = null;

		this.quoteDataService.serverQuoteErrors.next(serverRes);

		if (serverRes?.validationErrors?.length) {
			this.setServerErrors(serverRes);

			if (reproducibleFormsArray && reproducibleFormsArray.length) {
				const hasNonReproducibleErrorsFromServerForThisPage =
					this.quoteDataService.hasErrorsFromServerForThisPage.value;
				invalidReproducibleIndex = this.getIndexOfFirstReproducibleWithError(serverRes, reproducibleFormsArray);
				if (invalidReproducibleIndex !== -1) {
					// providing hasNonReproducibleErrorsFromServerForThisPage flag to prevent this.quoteDataService.hasErrorsFromServerForThisPage.value
					// from resetting to false if there are no errors in reproducibles (but there are errors for regular fields)
					this.setReproducibleErrors(serverRes, reproducibleFormsArray, hasNonReproducibleErrorsFromServerForThisPage);
				}
			}
		}

		this.handleValidation(serverRes.validationErrors);
		// hasErrorsFromServerForThisPage will be set in setErrors() or setReproducibleErrors()
		if (!this.quoteDataService.hasErrorsFromServerForThisPage.value && !preventNavigationToNext) {
			this.store.dispatch(NavigateToNextPage(serverRes));
		}
		// returns observable with invalidReproducibleIndex for reproducible internal navigation if needed
		return of(invalidReproducibleIndex);
	}

	private initMissingQuoteDataFields(form: FormGroup, quoteData: IQuoteDataState): void {
		const dataWithValues = {};
		Object.keys(form.controls).forEach((fieldName) => {
			if (isUndefined(quoteData[fieldName])) {
				dataWithValues[fieldName] = null;
			}
		});
		if (Object.keys(dataWithValues).length !== 0) {
			this.store.dispatch(
				UpdateQuoteData({
					data: dataWithValues,
				})
			);
		}
	}
}
