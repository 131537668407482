import { LobsEnum } from '../enums/lobs.enum';
import { IField } from '../../entities/field.interface';

export const Fields: IField = {
	Id: {
		name: 'Id',
		pattern: "^[a-z A-Z-']*$",
	},
	SequenceNum: {
		name: 'SequenceNum',
	},
	FirstName: {
		name: 'FirstName',
		pattern: "^[a-z A-Z-']*$",
	},
	LastName: {
		name: 'LastName',
		pattern: "^[a-z A-Z-']*$",
	},
	MiddleName: {
		name: 'MiddleName',
		pattern: '^[a-zA-Z]*$',
	},
	DateOfBirth: {
		name: 'DateOfBirth',
		prefix: 'Born on',
	},
	DOBStr: {
		name: 'DOBStr',
		prefix: 'Born on',
	},
	DOB: {
		name: 'DOB',
		prefix: 'Born on',
	},
	Gender: {
		name: 'Gender',
		prefix: 'Born on',
	},
	PersonalLineGender: {
		name: 'PersonalLineGender',
		prefix: 'Born on',
	},
	BodilyInjuryliability: {
		name: 'BI',
		prefix: 'Born on',
	},
	PoliciesWithAgent: {
		name: 'PoliciesWithAgent',
	},
	EffectiveDate: {
		name: 'EffectiveDate',
	},
	PreviousAddressAddressLine1: {
		name: 'PreviousAddress.AddressLine1',
	},
	PreviousAddressAddressLine2: {
		name: 'PreviousAddress.AddressLine2',
	},
	PreviousAddressZipCode: {
		name: 'PreviousAddress.ZipCode',
	},
	PreviousAddressCity: {
		name: 'PreviousAddress.City',
	},
	PreviousAddressState: {
		name: 'PreviousAddress.State',
	},
	PreviousAddressCounty: {
		name: 'PreviousAddress.County',
		disableGtmDisplayedEvent: true,
	},
	PersonalVehicleGarageAddressAddressLine1: {
		name: 'PersonalVehicleGarageAddress.AddressLine1',
	},
	PersonalVehicleGarageAddressAddressLine2: {
		name: 'PersonalVehicleGarageAddress.AddressLine2',
	},
	PersonalVehicleGarageAddressZipCode: {
		name: 'PersonalVehicleGarageAddress.ZipCode',
	},
	PersonalVehicleGarageAddressCity: {
		name: 'PersonalVehicleGarageAddress.City',
	},
	PersonalVehicleGarageAddressState: {
		name: 'PersonalVehicleGarageAddress.State',
	},
	PersonalVehicleGarageAddressCounty: {
		name: 'PersonalVehicleGarageAddress.County',
		// disableGtmDisplayedEvent: true,
	},
	PropertyAddressAddressLine1: {
		name: 'PropertyAddress.AddressLine1',
	},
	PropertyAddressAddressLine2: {
		name: 'PropertyAddress.AddressLine2',
	},
	PropertyAddressZipCode: {
		name: 'PropertyAddress.ZipCode',
	},
	PropertyAddressCity: {
		name: 'PropertyAddress.City',
		forceGtmDisplayedEvent: true,
	},
	PropertyAddressCounty: {
		name: 'PropertyAddress.County',
		disableGtmDisplayedEvent: true,
	},
	PropertyAddressState: {
		name: 'PropertyAddress.State',
		forceGtmDisplayedEvent: true,
		children: [
			{ name: 'MitWindowOpening', rule: "`'${fieldValue}' == 'FL'`" },
			{ name: 'PL_HeatedByOil', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'FireExtinguisher', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'DeadBoltLocks', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'PL_VaultedCeilings', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'PL_CrownMolding', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'PL_FlooringMaterial[]', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'PL_InteriorWallMaterial[]', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'PerimeterSecurityDD', rule: "`'${fieldValue}' != 'FL'`" },
			{ name: 'ElectricCircuitBreaker', rule: "`'${fieldValue}' != 'FL'`" },
		],
	},
	PLTypeOfDwelling: {
		name: 'PLTypeOfDwelling',
	},
	OccupancyType: {
		name: 'OccupancyType',
	},
	lobs: {
		name: 'lobs',
	},
	PLYearBuilt: {
		name: 'PLYearBuilt',
		pattern: '^[0-9]*$',
		prefix: 'Built in',
		children: [
			{ name: 'PLHeatingUpdate', rule: '`${fieldValue} < 1970`' },
			{ name: 'PLPlumbingUpdated', rule: '`${fieldValue} < 1970`' },
			{ name: 'PLElectircalUpdated', rule: '`${fieldValue} < 1970`' },
			{ name: 'ElectricCircuitBreaker', rule: '`${fieldValue} < 1970`' },
			{ name: 'ElectircalUpdatedYear', rule: '`${fieldValue} < 1970`' },
			{ name: 'PlumbingUpdatedYear', rule: '`${fieldValue} < 1970`' },
			{ name: 'PlumbingType', rule: '`${fieldValue} < 1970`' },
			{ name: 'HeatingUpdateYear', rule: '`${fieldValue} < 1970`' },
			{ name: 'PL_RoofUpdateYearRange', rule: '`new Date().getFullYear() - ${fieldValue} > 15`' },
		],
	},
	BuiltOnSlope: {
		name: 'BuiltOnSlope',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	SquareFootage: {
		name: 'PLSquareFootage',
		pattern: '^[0-9]*$',
		postfix: 'Square feet',
	},
	PLNumberOfStories: {
		name: 'PLNumberOfStories',
		postfix: 'Story',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	ArchitectureStyle: {
		name: 'ArchitectureStyle',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	TypeOfFoundation: {
		name: 'TypeOfFoundation',
		children: [
			{
				name: 'PL_BasementFinish',
				rule: "`'${fieldValue}' == 'Basement' || '${fieldValue}' == 'PartialBasement' || '${fieldValue}' == 'WalkoutBasement' `",
			},
		],
		disableLobs: [LobsEnum.CONDOMINIUM],
		group: 'foundation',
	},
	PL_BasementFinish: {
		name: 'PL_BasementFinish',
		disableLobs: [LobsEnum.CONDOMINIUM],
		group: 'foundation',
		groupException: { fieldName: 'TypeOfFoundation', validValues: ['Basement', 'PartialBasement', 'WalkoutBasement'] },
	},
	PLConstructionType: {
		name: 'PLConstructionType',
	},
	PLNumberOfUnits: {
		name: 'PLNumberOfUnits',
		pattern: '^[0-9]*$',
	},

	PL_NumberOfFloors: {
		name: 'PL_NumberOfFloors',
		disableLobs: [LobsEnum.PERSONAL_HOME],
	},
	PLFloorNumber: {
		name: 'PLFloorNumber',
		disableLobs: [LobsEnum.PERSONAL_HOME],
	},
	PLPersonalProperty: {
		name: 'PLPersonalProperty',
		disableLobs: [LobsEnum.PERSONAL_HOME],
	},
	ExteriorWallsConstruction: {
		name: 'ExteriorWallsConstruction',
	},
	MitRoofShape: {
		name: 'MitRoofShape',
	},
	RoofType: {
		name: 'RoofType',
	},
	PL_RoofUpdateYearRange: {
		name: 'PL_RoofUpdateYearRange',
	},
	PL_AdditionalStructures_Garage: {
		name: 'PL_AdditionalStructures_Garage',
		children: [
			{ name: 'TypeGarageCarport', rule: '`${fieldValue} == true` ' },
			{ name: 'PL_NumberCarSpace', rule: '`${fieldValue}==true`' },
		],
		group: 'garage',
	},
	PL_AdditionalStructures_Pool: {
		name: 'PL_AdditionalStructures_Pool',
		disableLobs: [LobsEnum.CONDOMINIUM],
		children: [
			{ name: 'PL_PoolType', rule: '`${fieldValue} == true` ' },
			{ name: 'PL_PoolFeatures', rule: '`${fieldValue} == true`' },
			{ name: 'PL_PoolFeatures_DivingBoard', rule: '`${fieldValue} == true`' },
			{ name: 'PL_PoolFeatures_4ftFence', rule: '`${fieldValue} == true`' },
			{ name: 'PL_PoolFeatures_Slide', rule: '`${fieldValue} == true`' },
			{ name: 'RemovableLockableLadder', rule: '`${fieldValue} == true`' },
		],
		isCheckbox: 'additionalStructuresOptions',
	},
	PL_AdditionalStructures_Deck: {
		name: 'PL_AdditionalStructures_Deck',
		isCheckbox: 'additionalStructuresOptions',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_AdditionalStructures_Trampoline: {
		name: 'PL_AdditionalStructures_Trampoline',
		children: [
			{ name: 'PL_NettedTrampoline', rule: '`${fieldValue} == true` ' },
			{ name: 'PL_TrampolineInFence', rule: '`${fieldValue} == true`' },
		],
		isCheckbox: 'additionalStructuresOptions',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	TypeGarageCarport: {
		name: 'TypeGarageCarport',
		group: 'garage',
	},
	PL_NumberCarSpace: {
		name: 'PL_NumberCarSpace',
		disableLobs: [LobsEnum.CONDOMINIUM],
		group: 'garage',
	},
	PL_PoolType: {
		name: 'PL_PoolType',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_PoolFeatures: {
		name: 'PL_PoolFeatures',
	},
	PL_PoolFeatures_DivingBoard: {
		name: 'PL_PoolFeatures_DivingBoard',
		isCheckbox: 'poolFeaturesOptions',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_PoolFeatures_4ftFence: {
		name: 'PL_PoolFeatures_4ftFence',
		children: [
			{
				name: 'PerimeterSecurityDD',
				rule: '`${fieldValue} == false || ${fieldValue} == null` ',
				leaveValueWhenHidden: true,
			},
		],
		isCheckbox: 'poolFeaturesOptions',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_PoolFeatures_Slide: {
		name: 'PL_PoolFeatures_Slide',
		isCheckbox: 'poolFeaturesOptions',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	RemovableLockableLadder: {
		name: 'RemovableLockableLadder',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_NettedTrampoline: {
		name: 'PL_NettedTrampoline',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_TrampolineInFence: {
		name: 'PL_TrampolineInFence',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PerimeterSecurityDD: {
		name: 'PerimeterSecurityDD',
		multiConditions: [{ fields: ['PropertyAddress.State', 'PL_PoolFeatures_4ftFence'], orCondition: false }],
		disableLobs: [LobsEnum.CONDOMINIUM],
		altValues: {
			check: 'Yes',
			uncheck: 'No',
		},
	},
	PL_PrimaryCounterMaterial: {
		name: 'PL_PrimaryCounterMaterial',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	FullBathNum: {
		name: 'FullBathNum',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	HalfBathNum: {
		name: 'HalfBathNum',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_FlooringMaterial: {
		name: 'PL_FlooringMaterial[]',
		disableLobs: [LobsEnum.CONDOMINIUM],
		maxLength: 5,
	},
	'PL_InteriorWallMaterial[]': {
		name: 'PL_InteriorWallMaterial[]',
		disableLobs: [LobsEnum.CONDOMINIUM],
		maxLength: 5,
	},
	PL_CeilingHeight: {
		name: 'PL_CeilingHeight',
	},
	PL_VaultedCeilings: {
		name: 'PL_VaultedCeilings',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	PL_CrownMolding: {
		name: 'PL_CrownMolding',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	NumberOfFirePlaces: {
		name: 'NumberOfFirePlaces',
		children: [{ name: 'PL_TypeFireplaces', rule: '`${fieldValue} > 0 `' }],
		disableLobs: [LobsEnum.CONDOMINIUM],
		group: 'firePlaces',
	},
	PL_TypeFireplaces: {
		name: 'PL_TypeFireplaces',
		disableLobs: [LobsEnum.CONDOMINIUM],
		group: 'firePlaces',
	},
	PL_CentralAC: {
		name: 'PL_CentralAC',
	},
	UtilityHookup365: {
		name: 'UtilityHookup365',
	},
	PLHeatingUpdate: {
		name: 'PLHeatingUpdate',
		children: [
			{
				name: 'HeatingUpdateYear',
				rule: "`'${fieldValue}' == 'CompleteUpdate' || '${fieldValue}' == 'PartialUpdate'`",
			},
		],
	},
	HeatingUpdateYear: {
		name: 'HeatingUpdateYear',
		pattern: '^[0-9]*$',
		multiConditions: [{ fields: ['PLHeatingUpdate', 'PLYearBuilt'] }],
	},
	PlumbingType: {
		name: 'PlumbingType',
	},
	PLPlumbingUpdated: {
		name: 'PLPlumbingUpdated',
		children: [
			{
				name: 'PlumbingUpdatedYear',
				rule: "`'${fieldValue}' == 'CompleteUpdate' || '${fieldValue}' == 'PartialUpdate'`",
			},
		],
	},
	PlumbingUpdatedYear: {
		name: 'PlumbingUpdatedYear',
		multiConditions: [{ fields: ['PLPlumbingUpdated', 'PLYearBuilt'] }],
		pattern: '^[0-9]*$',
	},
	PLElectircalUpdated: {
		name: 'PLElectircalUpdated',
		children: [
			{
				name: 'ElectircalUpdatedYear',
				rule: "`'${fieldValue}' == 'CompleteUpdate' || '${fieldValue}' == 'PartialUpdate'`",
			},
		],
	},
	ElectircalUpdatedYear: {
		name: 'ElectircalUpdatedYear',
		pattern: '^[0-9]*$',
		multiConditions: [{ fields: ['PLElectircalUpdated', 'PLYearBuilt'] }],
	},
	ElectricCircuitBreaker: {
		name: 'ElectricCircuitBreaker',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},
	HomePermanentFoundation: {
		name: 'HomePermanentFoundation',
	},
	SmokeDetectors: {
		name: 'SmokeDetector',
	},
	FireExtinguishers: {
		name: 'FireExtinguisher',
	},
	SprinklerSystem: {
		name: 'SprinklerSystem',
	},
	FireDetection: {
		name: 'FireDetection',
		isCheckbox: 'FireDetectionTypeOptions',
		children: [{ name: 'FireDetectionTypeMulti[]', rule: '`${fieldValue} == true`' }],
	},
	SprinklerType: {
		name: 'SprinklerSystemType',
	},
	DeadBoltLocks: {
		name: 'DeadBoltLocks',
		isCheckbox: 'homeSecurityFeaturesOptions',
		disableLobs: [LobsEnum.CONDOMINIUM],
	},

	BurglarAlarmType: {
		name: 'BurglarAlarmTypeMulti[]',
		disableGtmDisplayedEvent: true,
	},
	MitWindowOpening: {
		name: 'MitWindowOpening',
	},
	MaritalStatus: {
		name: 'MaritalStatus',
	},
	DriverOccupationStr: {
		name: 'DriverOccupationStr',
	},
	FQMatriculaCard: {
		name: 'FQMatriculaCard',
	},
	FQMatriculaQualifiedToDrive: {
		name: 'FQMatriculaQualifiedToDrive',
	},
	DriverLicenseNumber: {
		name: 'DriverLicenseNumber',
		pattern: '^[a-zA-Z0-9]*$',
	},
	SR22OrFinancialResponsibility: {
		name: 'SR22OrFinancialResponsibilityStatement',
	},
	// State Filing popup
	Filings: {
		name: 'Filings',
	},
	FilingState: {
		name: 'FilingState',
	},
	FilingCode: {
		name: 'FilingCode',
	},
	FilingCase: {
		name: 'FilingCase',
	},
	FilingOriginalDate: {
		name: 'FilingOriginalDate',
	},
	DriversLicenseBeenSuspendedOrRevoked: {
		name: 'DriversLicenseBeenSuspendedOrRevoked',
	},
	FQIsStudent100Miles: {
		name: 'FQIsStudent100Miles',
	},
	IsGoodStudent: {
		name: 'IsGoodStudent',
	},
	IsDefensiveDriver: {
		name: 'IsDefensiveDriver',
	},
	DefensiveDriverCourseDate: {
		name: 'DefensiveDriverCourseDate',
	},
	DriverStateLicensed: {
		name: 'DriverStateLicensed',
	},
	DriverLicenseStatus: {
		name: 'DriverLicenseStatus',
	},
	DriversLicenseBeenSuspendedOrRevokedSafeco: {
		name: 'DriversLicenseBeenSuspendedOrRevoked',
	},
	TypeOfResidence: {
		name: 'TypeOfResidence',
	},
	DriverDateLicensed: {
		name: 'DriverDateLicensed',
	},
	DriverRelationshipToDriver1: {
		name: 'DriverRelationshipToDriver1',
	},
	DriverEducation: {
		name: 'DriverEducation',
	},

	CurrentPersonalAutoCarrier: {
		name: 'CurrentPersonalAutoCarrier',
	},

	SelectReasonNoPriorAutoInsurance: {
		name: 'SelectReasonNoPriorAutoInsurance',
	},

	PriorCarrierExpirationDateAuto: {
		name: 'PriorCarrierExpirationDateAuto',
	},

	DriverEmploymentIndustry: {
		name: 'DriverEmploymentIndustry',
	},
	HasAccidents: {
		name: 'HasAccidents',
	},
	HasAutoLosses: {
		name: 'HasAutoLosses',
	},
	HasViolations: {
		name: 'HasViolations',
	},

	D2CAgreeToTerms: {
		name: 'D2CAgreeToTerms',
	},

	IAgreeToReceiveEmailsByBolt: {
		name: 'IAgreeToReceiveEmailsByBolt',
		//
	},
	Ssn: {
		name: 'SSN',
	},
	CoFirstName: {
		name: 'CoFirstName',
		pattern: '^[a-z A-Z]*$',
	},
	CoMiddleName: {
		name: 'CoMiddleName',
		pattern: '^[a-zA-Z]*$',
	},
	CoLastName: {
		name: 'CoLastName',
		pattern: '^[a-z A-Z]*$',
	},
	CoDateOfBirth: {
		name: 'CoDateOfBirth',
	},
	SelectRelationshipToClient: {
		name: 'SelectRelationshipToClient',
		children: [{ name: 'CoMaritalStatus', rule: "` '${fieldValue}' != 'Spouse'` " }],
	},
	AnyAdditionalInsured: {
		name: 'AnyAdditionalInsured',
		children: [
			{ name: 'CoFirstName', rule: '`${fieldValue} == true`' },
			{ name: 'CoMiddleName', rule: '`${fieldValue} == true`' },
			{ name: 'CoLastName', rule: '`${fieldValue} == true`' },
			{ name: 'CoDateOfBirth', rule: '`${fieldValue} == true`' },
			{ name: 'SelectRelationshipToClient', rule: '`${fieldValue} == true`' },
			{ name: 'CoMaritalStatus', rule: '`${fieldValue} == true`' },
			{ name: 'CoSSN', rule: '`${fieldValue} == true`' },
		],
	},
	CoMaritalStatus: {
		name: 'CoMaritalStatus',
		multiConditions: [{ fields: ['AnyAdditionalInsured', 'SelectRelationshipToClient'] }],
	},
	CoSSN: {
		name: 'CoSSN',
	},
	Houseoccup: {
		name: 'PL_Houseoccup',
	},
	Email: {
		name: 'Email',
		pattern: "^[a-zA-Z\\@\\d\\!\\#\\$\\%\\&'\\*\\+\\-\\/\\=\\?\\^\\_\\`\\{\\|\\}\\~\\.]*$",
	},
	PrimaryPhoneNumber: {
		name: 'PrimaryPhoneNumber',
	},
	IsMailAddress: {
		name: 'IsMailAddress',
		children: [
			{ name: 'MailingAddress.AddressLine1', rule: '`${fieldValue} == true `' },
			{ name: 'MailingAddress.AddressLine2', rule: '`${fieldValue} == true `' },
			{ name: 'MailingAddress.ZipCode', rule: '`${fieldValue} == true `' },
			{ name: 'MailingAddress.City', rule: '`${fieldValue} == true `' },
			{ name: 'MailingAddress.State', rule: '`${fieldValue} == true `' },
		],
	},
	'MailingAddress.AddressLine1': {
		name: 'MailingAddress.AddressLine1',
		pattern: '^[a-zA-Z\\d\\s]*$',
	},
	'MailingAddress.AddressLine2': {
		name: 'MailingAddress.AddressLine2',
		pattern: '^[a-zA-Z\\d\\s]*$',
	},
	'MailingAddress.ZipCode': {
		name: 'MailingAddress.ZipCode',
		pattern: '^[\\d\\s]*$',
	},
	'MailingAddress.City': {
		name: 'MailingAddress.City',
		pattern: '^[a-zA-Z]*$',
	},
	'MailingAddress.State': {
		name: 'MailingAddress.State',
		pattern: '^[a-zA-Z]*$',
	},
	'MailingAddress.County': {
		name: 'MailingAddress.County',
		pattern: '^[a-zA-Z]*$',
		disableGtmDisplayedEvent: true,
	},
	AnimalsOnThePremises_None: {
		name: 'AnimalsOnThePremises_None',
	},
	AnimalsOnThePremises_Dogs: {
		name: 'AnimalsOnThePremises_Dogs',
		children: [
			{ name: 'NumberOfDogsOnPremises', rule: '`${fieldValue} == true `' },
			{ name: 'DogsWithBiteHistory', rule: '`${fieldValue} == true `' },
			{ name: 'RestrictedDogs', rule: '`${fieldValue} == true `' },
			{ name: 'DogsBreedsSelection[]', rule: '`${fieldValue} == true `' },
		],
		isCheckbox: 'animalsOnThePremisesOptions',
	},
	AnimalsOnThePremises_Farm1to2: {
		name: 'AnimalsOnThePremises_Farm1to2',
		isCheckbox: 'animalsOnThePremisesOptions',
	},
	AnimalsOnThePremises_Farm3orMore: {
		name: 'AnimalsOnThePremises_Farm3orMore',
		isCheckbox: 'animalsOnThePremisesOptions',
	},
	AnimalsOnThePremises_Exotic: {
		name: 'AnimalsOnThePremises_Exotic',
		isCheckbox: 'animalsOnThePremisesOptions',
	},
	NumberOfDogsOnPremises: {
		name: 'NumberOfDogsOnPremises',
	},
	DogsWithBiteHistory: {
		name: 'DogsWithBiteHistory',
	},
	'DogsBreedsSelection[]': {
		name: 'DogsBreedsSelection[]',
		maxLength: 5,
	},
	PriorInsuranceProperty: {
		name: 'PriorInsuranceProperty',
		children: [
			{ name: 'CurrentPersonalHomeownerCarrier', rule: '`${fieldValue} == true` ' },
			{ name: 'YearsWithPriorCarrierHome', rule: '`${fieldValue} == true` ' },
		],
	},
	DriverAction: {
		name: 'DriverAction',
	},
	CurrentPersonalHomeownerCarrier: {
		name: 'CurrentPersonalHomeownerCarrier',
	},
	YearsWithPriorCarrierHome: {
		name: 'YearsWithPriorCarrierHome',
	},
	PurchaseDate: {
		name: 'PurchaseDate',
	},
	DateVehiclePurchased: {
		name: 'DateVehiclePurchased',
	},
	DateOccupied: {
		name: 'DateOccupied',
	},
	NumberofAcres: {
		name: 'NumberofAcres',
		disableLobs: [LobsEnum.CONDOMINIUM],
		pattern: '^\\.?\\d{0,2}\\.?\\d{0,2}$',
	},
	NumberOfClaimsHistory: {
		name: 'NumberOfClaimsHistory',
		children: [
			{
				name: 'PersonalLossPLLossDescription1',
				rule: '`${fieldValue} == 1 || ${fieldValue} == 2 || ${fieldValue} == 3` ',
			},
			{ name: 'PersonalLossesDateOfLoss1', rule: '`${fieldValue} == 1 || ${fieldValue} == 2 ||  ${fieldValue} == 3` ' },
			{ name: 'PersonalLineLossAmount1', rule: '`${fieldValue} == 1 || ${fieldValue} == 2 || ${fieldValue} == 3` ' },
			{ name: 'PersonalLossPLLossDescription2', rule: '` ${fieldValue} == 2 || ${fieldValue} == 3` ' },
			{ name: 'PersonalLossesDateOfLoss2', rule: '` ${fieldValue} == 2 || ${fieldValue} == 3` ' },
			{ name: 'PersonalLineLossAmount2', rule: '`${fieldValue} == 2 || ${fieldValue} == 3` ' },
			{ name: 'PersonalLossPLLossDescription3', rule: '` ${fieldValue} == 3` ' },
			{ name: 'PersonalLossesDateOfLoss3', rule: '` ${fieldValue} == 3` ' },
			{ name: 'PersonalLineLossAmount3', rule: '`${fieldValue} == 3` ' },
		],
	},
	NumberOfClaimsHistoryYesNo: {
		name: 'NumberOfClaimsHistoryYesNo',
	},
	PersonalLossPLLossDescription1: {
		name: 'PersonalLossPLLossDescription1',
	},
	PersonalLossesDateOfLoss1: {
		name: 'PersonalLossesDateOfLoss1',
	},
	PersonalLineLossAmount1: {
		name: 'PersonalLineLossAmount1',
	},
	PersonalLossPLLossDescription2: {
		name: 'PersonalLossPLLossDescription2',
	},
	PersonalLossesDateOfLoss2: {
		name: 'PersonalLossesDateOfLoss2',
	},

	PersonalLineLossAmount2: {
		name: 'PersonalLineLossAmount2',
	},

	PersonalLossPLLossDescription3: {
		name: 'PersonalLossPLLossDescription3',
	},
	PersonalLossesDateOfLoss3: {
		name: 'PersonalLossesDateOfLoss3',
	},

	PersonalLineLossAmount3: {
		name: 'PersonalLineLossAmount3',
	},
	PersonalProperty: {
		name: 'PersonalProperty',
	},
	FireSafetyFeatures: {
		name: 'FireSafetyFeatures',
	},
	HomeSecurityFeatures: {
		name: 'HomeSecurityFeatures',
		disableGtmDisplayedEvent: true,
	},
	PaymentDetails: {
		name: 'PaymentDetails',
	},
	CardNumber: {
		name: 'Card Number',
		pattern: '^[0-9]*$',
	},
	ExpirationDate: {
		name: 'Expiration Date',
	},
	CVCNumber: {
		name: 'CVC Number',
	},
	PaymentPlan: {
		name: 'Payment Plan',
	},

	RoofUpdatedYear: {
		name: 'RoofUpdatedYear',
	},

	PLRoofUpdated: {
		name: 'PLRoofUpdated',
	},

	YearBuilt: {
		name: 'PLYearBuilt',
		pattern: '^[0-9]*$',
	},

	Unit: {
		name: 'Unit',
	},

	VIN: {
		name: 'VIN',
	},
	ExternalVINPrefix: {
		name: 'ExternalVINPrefix',
		pattern: '^[a-zA-Z\\d\\s]*$',
	},

	OwnershipType: {
		name: 'OwnershipType',
	},

	WasTheCarNew: {
		name: 'WasTheCarNew',
	},

	CostNewValue: {
		name: 'CostNewValue',
		pattern: '^[0-9]*$',
	},

	PrimaryUseOfVehicle: {
		name: 'PrimaryUseOfVehicle',
	},

	MilesToWork: {
		name: 'MilesToWork',
		pattern: '^[0-9]*$',
	},

	MilesToWorkPGR: {
		name: 'MilesToWork',
		pattern: '^[0-9]*$',
	},

	NumDaysDrivenPerWeek: {
		name: 'FQNumDaysDrivenPerWeek',
		pattern: '^[0-9]{1}$',
	},
	VehicleUseForRideShareInd: {
		name: 'FQVehicleUseForRideShareInd',
	},
	VehDeliveryUseInd: {
		name: 'FQVehDeliveryUseInd',
	},
	BlindSpotWarningInd: {
		name: 'FQBlindSpotWarningInd',
	},
	AnyModifications: {
		name: 'AnyModifications',
	},
	ModificationsValue: {
		name: 'ModificationsValue',
		pattern: '^[0-9]*$',
	},
	GarageAddressDifferent: {
		name: 'GarageAddressDifferent',
	},
	// If GarageAddressDifferent Yes
	PersonalVehicleGarageAddress: {
		name: 'PersonalVehicleGarageAddress',
	},
	LoanLeaseAmount: {
		name: 'FQLoanLeaseAmount',
		pattern: '^[0-9]*$',
	},
	LoanLeaseName: {
		name: 'FQLienholderName',
		pattern: '^[A-Za-z]+$',
	},
	NumberOfMiles: {
		name: 'NumberOfMiles',
		pattern: '^[0-9]*$',
	},

	OEMCoverage: {
		name: 'OEMCoverage',
	},

	PLPetName: {
		name: 'PLPetName',
		pattern: '^[a-z A-Z]*$',
	},

	PLPetBreedType: {
		name: 'PLPetBreedType',
	},

	PLPetBreed: {
		name: 'PLPetBreed',
	},

	PLPetGender: {
		name: 'PLPetGender',
	},

	PetDOB: {
		name: 'PetDOB',
	},

	PLSpayedNeutered: {
		name: 'PLSpayedNeutered',
	},

	PLHasDisease: {
		name: 'PLHasDisease',
	},
	ProgressivePersonalAutoUBIExperienceEnrollment: {
		name: 'FQData.ProgressivePersonalAuto.UBIExperienceEnrollment',
	},
	ProgressivePersonalAutoUBIAcknowledgement: {
		name: 'FQData.ProgressivePersonalAuto.UBIAcknowledgement',
	},
	SnapshotPhoneNumber: {
		name: 'FQSnapshotPhoneNumber',
	},

	FQLicenseClass: {
		name: 'FQLicenseClass',
	},
	FQDriverRatingType: {
		name: 'FQDriverRatingType',
	},

	FQLienholderAddress: {
		name: 'FQLienholderAddress',
	},
	AddressLine1: {
		name: 'AddressLine1',
	},
	AddressLine2: {
		name: 'AddressLine2',
	},
	City: {
		name: 'City',
	},
	State: {
		name: 'State',
	},
	ZipCode: {
		name: 'ZipCode',
	},
	Enabled: {
		name: 'enabled',
	},
	PLYear: {
		name: 'PLYear',
	},
	PLMake: {
		name: 'PLMake',
	},
	PLModel: {
		name: 'PLModel',
	},
	BodyStyle: {
		name: 'BodyStyle',
	},

	// Progressive fields
	// step 1
	PGR_CurrentCarrier: {
		name: 'FQData.ProgressivePersonalAuto.CurrentPersonalAutoCarrier',
	},
	AutoInsuranceCancelled: {
		name: 'AutoInsuranceCancelled',
	},
	PGR_LiabilityLimits: {
		name: 'SelectPriorLiabilityLimitsAuto',
	},
	PGR_CarrierExpirationDate: {
		name: 'PriorCarrierExpirationDateAuto',
	},
	PGR_YearsAtAddress: {
		name: 'YearsAtAddress',
	},
	PGR_MonthsAtAddress: {
		name: 'MonthsAtAddress',
	},
	NumPIPClaims: {
		name: 'FQData.ProgressivePersonalAuto.NumPIPClaims',
	},
	ReasonForLapse: {
		name: 'ReasonForLapse',
	},
	YearsWithContinuousCoverageAuto: {
		name: 'YearsWithContinuousCoverageAuto',
	},
	YearsWithPriorCarrierAuto: {
		name: 'YearsWithPriorCarrierAuto',
	},
	PGR_SpinOffCode: {
		name: 'FQData.ProgressivePersonalAuto.SpinOffCode',
	},
	PGR_PriorCarrierPolicyNumAuto: {
		name: 'FQData.ProgressivePersonalAuto.PriorCarrierPolicyNumAuto',
	},
	PGR_MultiPolicyInd: {
		name: 'FQData.ProgressivePersonalAuto.MultiPolicyInd',
	},
	PGR_Houseoccup: {
		name: 'PL_Houseoccup',
	},

	// step 2
	PGR_DsbPkgInd: {
		name: 'FQData.ProgressivePersonalAuto.DsbPkgInd',
	},
	PGR_DsbBalanceInd: {
		name: 'FQData.ProgressivePersonalAuto.DsbBalanceInd',
	},
	PGR_MultiPolicyRiskCd: {
		name: 'FQData.ProgressivePersonalAuto.MultiPolicyRiskCd',
	},

	// step 3
	PGR_AllHdshldDrvListedInd: {
		name: 'FQData.ProgressivePersonalAuto.AllHdshldDrvListedInd',
	},
	eSignAcknowledgeDriversInd: {
		name: 'FQData.ProgressivePersonalAuto.eSignAcknowledgeDriversInd',
	},
	eSignAcknowledgeVehiclesInd: {
		name: 'FQData.ProgressivePersonalAuto.eSignAcknowledgeVehiclesInd',
	},

	// --- start ---
	// Stillwater Home Full Quote
	// Additional questions 1
	PermanentFoundation: {
		name: 'FQData.StillWaterPersonalHome.PermanentFoundation',
	},
	RoadAccess: {
		name: 'FQData.StillWaterPersonalHome.RoadAccess',
	},
	GatedOrLimited: {
		name: 'GatedOrLimited',
	},
	// if GatedOrLimited  is true then show
	LimitedAccessLocation: {
		name: 'FQData.StillWaterPersonalHome.LimitedAccessLocation',
	},
	RoomingorBoarding: {
		name: 'FQData.StillWaterPersonalHome.RoomingorBoarding',
	},
	UnsualConst: {
		name: 'FQData.StillWaterPersonalHome.UnusualConst',
	},
	BusinessOrDaycare: {
		name: 'BusinessOrDaycare',
	},
	//
	// Additional questions 2
	NumberOfMortgagees: {
		name: 'NumberOfMortgagees',
	},
	ExtendedVacancy: {
		name: 'FQData.StillWaterPersonalHome.ExtendedVacancy',
	},
	SW_PL_AdditionalStructures_Garage: {
		name: 'PL_AdditionalStructures_Garage',
	},
	// if SW_PL_AdditionalStructures_Garage  is true then show
	ConvertedGarage: {
		name: 'FQData.StillWaterPersonalHome.ConvertedGarage',
	},
	// if SW_PL_AdditionalStructures_Garage  is true then show
	UnsualVehicleStorage: {
		name: 'FQData.StillWaterPersonalHome.UnusualVehicleStorage',
	},
	HaveAnyLosses: {
		name: 'PLHaveAnyLosses',
	},
	// if HaveAnyLosses  is true then show
	// losses
	LossDescription: {
		name: 'PLLossDescription',
	},
	LossDate: {
		name: 'DateOfLoss',
	},
	LossPaidAmt: {
		name: 'PersonalLineLossAmount',
	},
	// end losses
	// YearsAtAddress - Send 1 when Checked 3 when Unchecked)
	YearsAtAddress: {
		name: 'YearsAtAddress',
	},
	// If YearsAtAddress Yes
	PreviousAddress: {
		name: 'PreviousAddress',
	},
	// If Yes - PolicyData.CurrentPersonalAutoCarrier = "Stillwater"
	// If No – PolicyData.CurrentPersonalAutoCarrier = "Other"
	SW_CurrentPersonalAutoCarrier: {
		name: 'CurrentPersonalAutoCarrier',
	},

	// Stillwater Third page
	ExistingDamage: {
		name: 'ExistingDamage',
	},
	RoofSlopeValid: {
		name: 'RoofSlopeValid',
	},
	RoofSprayPoly: {
		name: 'RoofSprayPoly',
	},
	RoofSprayPolyLast3: {
		name: 'RoofSprayPolyLast3',
	},
	RoofCondition: {
		name: 'RoofCondition',
	},
	UnusualVehicleStorage: {
		name: 'UnusualVehicleStorage',
	},
	FarmOrRanch: {
		name: 'FarmOrRanch',
	},
	ConvertedResidency: {
		name: 'ConvertedResidency',
	},
	ConstructionPermit: {
		name: 'ConstructionPermit',
	},
	AltWaterSource: {
		name: 'AltWaterSource',
	},
	MultiFamilyUnit: {
		name: 'MultiFamilyUnit',
	},

	AnimalLiabilityCoverage: {
		name: 'AnimalLiabilityCoverage',
		labelsByState: {
			FL: 'Include Dog Liability Coverage.',
			Other: 'Include Animal Liability Coverage.',
		},
	},
	DistanceToCoastCoastalDistance: {
		name: 'DistanceToCoastCoastalDistance',
		labelsByState: {
			NJ: 'Is the dwelling within 1000 feet of the ocean?',
			DE: 'Is the dwelling within 5 miles of the ocean?',
			Other: '',
		},
	},

	// Utilities
	// 1 step
	PLHeatingType: {
		name: 'PLHeatingType',
	},
	SW_ElectricCircuitBreaker: {
		name: 'ElectricCircuitBreaker',
	},
	SolidFuel: {
		name: 'FQData.StillWaterPersonalHome.SolidFuel',
	},
	// 2 step
	// Heating
	HeatingUpdateYN: {
		name: 'HeatingUpdateYN',
		children: [
			{ name: 'HeatingUpdateYear', rule: '`${!!fieldValue} ? ${fieldValue} : null`' },
			{ name: 'PLHeatingUpdate', rule: '`${fieldValue} ? "CompleteUpdate" : null`' },
		],
	},
	SW_PLHeatingUpdate: {
		name: 'PLHeatingUpdate',
	},
	SW_HeatingUpdateYear: {
		name: 'HeatingUpdateYear',
	},
	// Electrical
	ElectricalUpdateYN: {
		name: 'ElectricalUpdateYN',
		children: [
			{ name: 'ElectricalUpdatedYear', rule: '`${!!fieldValue} ? ${fieldValue} : null`' },
			{ name: 'PLElectricalUpdated', rule: '`${fieldValue} ? "CompleteUpdate" : null`' },
		],
	},
	SW_PLElectricalUpdated: {
		name: 'PLElectricalUpdated',
	},
	SW_ElectricalUpdatedYear: {
		name: 'ElectricalUpdatedYear',
	},
	// Plumbing
	PlumbingUpdateYN: {
		name: 'PlumbingUpdateYN',
		children: [
			{ name: 'PlumbingUpdatedYear', rule: '`${!!fieldValue} ? ${fieldValue} : null`' },
			{ name: 'PLPlumbingUpdated', rule: '`${fieldValue} ? "CompleteUpdate" : null`' },
		],
	},
	SW_PLPlumbingUpdated: {
		name: 'PLPlumbingUpdated',
	},
	SW_PlumbingUpdatedYear: {
		name: 'PlumbingUpdatedYear',
	},

	// Last details
	// 1 step
	SW_PurchaseDate: {
		name: 'PurchaseDate',
	},
	PL_NumOfFamilies: {
		name: 'PL_NumOfFamilies',
	},
	DistanceToFireHydrant: {
		name: 'DistanceToFireHydrant',
	},
	DistanceToFireStation: {
		name: 'DistanceToFireStation',
	},
	// If SW_PL_AdditionalStructures_Garage is true then show
	SW_TypeGarageCarport: {
		name: 'TypeGarageCarport',
	},
	// If SW_PL_AdditionalStructures_Garage is true then show
	NumberCarSpace: {
		name: 'PL_NumberCarSpace',
	},
	BasementFinishType: {
		name: 'BasementFinishType',
	},
	// 2 step
	SW_ExteriorWallsConstruction: {
		name: 'ExteriorWallsConstruction',
	},
	NumBathrooms: {
		name: 'FQData.StillWaterPersonalHome.NumBathrooms',
	},
	BathroomConstructionQuality: {
		name: 'FQData.StillWaterPersonalHome.BathroomConstructionQuality',
	},
	HalfBathroomConstructionQuality: {
		name: 'FQData.StillWaterPersonalHome.HalfBathroomConstructionQuality',
	},
	NumOfHalfBathrooms: {
		name: 'FQData.StillWaterPersonalHome.NumOfHalfBathrooms',
	},
	NumberOfFireplaces: {
		name: 'FQData.StillWaterPersonalHome.NumberOfFireplaces',
	},
	NumOfKitchens: {
		name: 'FQData.StillWaterPersonalHome.NumOfKitchens',
	},
	KitchenConstructionQuality: {
		name: 'FQData.StillWaterPersonalHome.KitchenConstructionQuality',
	},
	TrampolineSafety: {
		name: 'FQData.StillWaterPersonalHome.TrampolineSafety',
		title:
			'There is a trampoline on the property that is not located in a fenced portion of the yard OR is not equipped with a safety net',
	},
	SW_PL_AdditionalStructures_Pool: {
		name: 'PL_AdditionalStructures_Pool',
	},
	PoolUnfenced: {
		name: 'PoolUnfenced',
		title: 'One or more of the swimming pools is unfenced, unfilled or unmaintained.',
	},
	// If PL_AdditionalStructures_Pool is yes then show
	PoolFeatures: {
		name: 'PoolFeatures',
	},
	SW_PL_PoolFeatures_DivingBoard: {
		name: 'PL_PoolFeatures_DivingBoard',
	},
	SW_PL_PoolFeatures_Slide: {
		name: 'PL_PoolFeatures_Slide',
	},
	SW_PL_PoolFeatures_4ftFence: {
		name: 'PL_PoolFeatures_4ftFence',
	},
	// Stillwater Current insurance
	SW_AnyAdditionalInsured: {
		name: 'AnyAdditionalInsured',
	},
	SW_MortgageCompanyName: {
		name: 'MortgageCompanyName',
		pattern: '^[a-zA-Z\\d\\s]*$',
	},
	SW_NumberOfMortgagees: {
		name: 'NumberOfMortgagees',
		pattern: '[1-9][0-9]*',
	},
	SW_PersonalLosses: {
		name: 'PersonalLosses',
	},
	SW_AnimalLiabilityCoverage: {
		name: 'FQData.StillWaterPersonalHome.AnimalLiabiityCoverage',
	},
	// --- end ---

	// Escrow
	MortgageCompanyName: {
		name: 'MortgageCompanyName',
		pattern: '^[a-zA-Z\\d\\s]*$',
	},
	MortgageAccountNumber: {
		name: 'MortgageAccountNumber',
		pattern: '^[0-9]*$',
	},
	MortgageBillPay: {
		name: 'MortgageBillPay',
	},
	MortgageCompanyAddress: {
		name: 'MortgageCompanyAddress',
	},
	MortgageCompanyAddressAddressLine1: {
		name: 'MortgageCompanyAddress.AddressLine1',
	},
	MortgageCompanyAddressAddressLine2: {
		name: 'MortgageCompanyAddress.AddressLine2',
	},
	MortgageCompanyAddressZipCode: {
		name: 'MortgageCompanyAddress.ZipCode',
	},
	MortgageCompanyAddressCity: {
		name: 'MortgageCompanyAddress.City',
	},
	MortgageCompanyAddressState: {
		name: 'MortgageCompanyAddress.State',
	},
	MortgageCompanyAddressCounty: {
		name: 'MortgageCompanyAddress.County',
		disableGtmDisplayedEvent: true,
	},
	InspectionNotification: {
		name: 'FQData.StillWaterPersonalHome.InspectionNotification',
	},
};
