export enum LobsEnum {
	RENTERS = 'Renters',
	CONDOMINIUM = 'Condominium',
	PERSONAL_HOME = 'PersonalHome',
	DWELLING_FIRE = 'DwellingFire',
	PERSONAL_AUTO = 'PersonalAuto',
	HOME_AUTO = 'PersonalHome,PersonalAuto',
	AUTO_HOME = 'PersonalAuto,PersonalHome',
	PETS = 'Pets',
	FLOOD = 'Flood',
}

export enum OfflineLobsEnum {
	DEVICE_PROTECTION = 'DeviceProtection',
}
