import { createAction, props } from '@ngrx/store';
export const hidePopupAction = createAction('[Popup] hide');
export const showPopupAction = createStartPopupAction('[Popup] show');
export const confirmPopupAction = createConfirmPopupAction('[Popup] confirm');

function createStartPopupAction(name: string) {
	return createAction(name, props<{ componentName: string, payload: any }>());
}

function createConfirmPopupAction(name: string) {
	return createAction(name, props<{ popupName: string }>());
}
