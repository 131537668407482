<section class="main-content bottom-space">
	<app-page-heading [title]="'titles.letsAddAdditionalDrivers' | translate"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="questions-container">
			<div [ngStyle]="{ width: ((activeTabIndex + 1) * 100) / this.numberOfSteps + '%' }" class="progress-bar"></div>
			<ng-container *ngIf="drivers.length > 0">
				<form
					*ngFor="let driver of drivers; let index = index"
					[formGroup]="this.driversFormControl.controls[index]"
					[ngClass]="{ show: isTabActive(index) }"
					class="driver-form"
				>
					<div class="driver-header">
						<app-image [name]="'driverActiveIcon'" alt="null" height="32" role="presentation"></app-image>
						<p>
							{{
								this.driversFormControl.controls[index].value["FirstName"] +
									" " +
									this.driversFormControl.controls[index].value["LastName"] | uppercase
							}}
						</p>
					</div>

					<!-- =============================================== -->
					<!-- ============ First page questions ============= -->
					<!-- =============================================== -->

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DOB.name) &&
							getDriverHistoryForm().get(Fields.DOB.name) &&
							getDriverHistoryForm().get(Fields.DOB.name).enabled
						"
						class="date-of-birth"
						icon="calendar_today"
					>
						<masked-input
							[inputMask]="'**/**/9999'"
							[name]="Fields.DOB.name"
							[placeholder]="'personalInfo.dateOfBirth' | translate"
							class="date-input"
						>
						</masked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.Gender.name) &&
							getDriverHistoryForm().get(Fields.Gender.name) &&
							getDriverHistoryForm().get(Fields.Gender.name).enabled
						"
						icon="person"
						optionalKeyText="labels.moreInfo"
						tooltipKeyText="tooltips.chooseOption"
					>
						<app-gender-dropdown
							[name]="Fields.Gender.name"
							[tabindex]="
								getDriverHistoryForm().get(Fields.Gender.name) && getDriverHistoryForm().get(Fields.Gender.name).enabled
							"
						></app-gender-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.MaritalStatus.name) &&
							getDriverHistoryForm().get(Fields.MaritalStatus.name) &&
							getDriverHistoryForm().get(Fields.MaritalStatus.name).enabled
						"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'personalInfo.maritalStatus' | translate"
							[name]="Fields.MaritalStatus.name"
							[options]="FieldOptions.maritalStatusOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getDriverHistoryForm().get(Fields.MaritalStatus.name) &&
								getDriverHistoryForm().get(Fields.MaritalStatus.name).enabled
							"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DriverRelationshipToDriver1.name) &&
							getDriverHistoryForm().get(Fields.DriverRelationshipToDriver1.name) &&
							getDriverHistoryForm().get(Fields.DriverRelationshipToDriver1.name).enabled
						"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'labels.driverRelationshipToDriver1' | translate"
							[name]="Fields.DriverRelationshipToDriver1.name"
							[options]="FieldOptions.DriverRelationshipToDriver1Options"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getDriverHistoryForm().get(Fields.DriverRelationshipToDriver1.name) &&
								getDriverHistoryForm().get(Fields.DriverRelationshipToDriver1.name).enabled
							"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DriverLicenseStatus.name) &&
							getDriverHistoryForm().get(Fields.DriverLicenseStatus.name) &&
							getDriverHistoryForm().get(Fields.DriverLicenseStatus.name).enabled
						"
						icon="person"
					>
						<app-dropdown
							[labelInsideField]="'personalInfo.driverLicenseStatus' | translate"
							[name]="Fields.DriverLicenseStatus.name"
							[options]="FieldOptions.DriverLicenseStatus"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getDriverHistoryForm().get(Fields.DriverLicenseStatus.name) &&
								getDriverHistoryForm().get(Fields.DriverLicenseStatus.name).enabled
							"
						></app-dropdown>
					</app-question-wrapper>

					<!-- =============================================== -->
					<!-- ============ Second page questions ============ -->
					<!-- =============================================== -->

					<app-question-wrapper
						[show]="
							getDriverHistoryForm().get(Fields.DriverLicenseNumber.name) &&
							getDriverHistoryForm().get(Fields.DriverLicenseNumber.name).enabled &&
							this.showQuestion(Fields.DriverLicenseNumber.name)
						"
					>
						<unmasked-input
							[name]="Fields.DriverLicenseNumber.name"
							[pattern]="Fields.DriverLicenseNumber.pattern"
							[placeholder]="'labels.driverLicenseNumber' | translate"
							maxlength="16"
							type="text"
						>
						</unmasked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							getDriverHistoryForm().get(Fields.DriverStateLicensed.name) &&
							getDriverHistoryForm().get(Fields.DriverStateLicensed.name).enabled &&
							this.showQuestion(Fields.DriverStateLicensed.name)
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.driverStateLicensed' | translate"
							[name]="Fields.DriverStateLicensed.name"
							[options]="FieldOptions.DriverStateLicensed"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getDriverHistoryForm().get(Fields.DriverStateLicensed.name) &&
								getDriverHistoryForm().get(Fields.DriverStateLicensed.name).enabled
							"
							searchable="true"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
					[show]="
						getDriverHistoryForm().get(Fields.DriverEducation.name) &&
						getDriverHistoryForm().get(Fields.DriverEducation.name).enabled &&
						this.showQuestion(Fields.DriverEducation.name)
					"
				>
					<app-dropdown
						[labelInsideField]="'labels.driverEducation' | translate"
						[name]="Fields.DriverEducation.name"
						[options]="FieldOptions.DriverEducationOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="
							getDriverHistoryForm().get(Fields.DriverEducation.name) &&
							getDriverHistoryForm().get(Fields.DriverEducation.name).enabled
						"
					></app-dropdown>
				</app-question-wrapper>


					
					<app-question-wrapper
						[show]="
							getDriverHistoryForm().get(Fields.FQLicenseClass.name) &&
							getDriverHistoryForm().get(Fields.FQLicenseClass.name).enabled &&
							this.showQuestion(Fields.FQLicenseClass.name)
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.fqLicenseClass' | translate"
							[name]="Fields.FQLicenseClass.name"
							[options]="FieldOptions.FQLicenseClassOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getDriverHistoryForm().get(Fields.FQLicenseClass.name) &&
								getDriverHistoryForm().get(Fields.FQLicenseClass.name).enabled
							"
							searchable="true"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.FQDriverRatingType.name) &&
							getDriverHistoryForm().get(Fields.DriverLicenseStatus.name).value !== 'NotLicensed'
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.fqDriverRatingType' | translate"
							[name]="Fields.FQDriverRatingType.name"
							[options]="FieldOptions.PRG_FQDriverRatingTypeOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="
								getDriverHistoryForm().get(Fields.FQDriverRatingType.name) &&
								getDriverHistoryForm().get(Fields.FQDriverRatingType.name).enabled
							"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DriverEmploymentIndustry.name) &&
							getDriverHistoryForm().get(Fields.DriverEmploymentIndustry.name).enabled &&
							getDriverHistoryForm().get(Fields.DriverEmploymentIndustry.name)
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.driverEmploymentIndustry' | translate"
							[name]="Fields.DriverEmploymentIndustry.name"
							[options]="DriverEmploymentIndustryEmployedOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="getDriverHistoryForm().get(Fields.DriverEmploymentIndustry.name)"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DriverOccupationStr.name) &&
							getDriverHistoryForm().get(Fields.DriverOccupationStr.name) &&
							getDriverHistoryForm().get(Fields.DriverOccupationStr.name).enabled
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.occupation' | translate"
							[name]="Fields.DriverOccupationStr.name"
							[options]="occupationsOptions"
							[tabindex]="getDriverHistoryForm().get(Fields.DriverOccupationStr.name)"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.FQMatriculaCard.name) &&
							getDriverHistoryForm().get(Fields.FQMatriculaCard.name) &&
							getDriverHistoryForm().get(Fields.FQMatriculaCard.name).enabled
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.fqMatriculaCard' | translate"
							[name]="Fields.FQMatriculaCard.name"
							[options]="FieldOptions.YesNoOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="getDriverHistoryForm().get(Fields.FQMatriculaCard.name)"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.FQMatriculaQualifiedToDrive.name) &&
							getDriverHistoryForm().get(Fields.FQMatriculaQualifiedToDrive.name) &&
							getDriverHistoryForm().get(Fields.FQMatriculaQualifiedToDrive.name).enabled
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.fqMatriculaQualifiedToDrive' | translate"
							[name]="Fields.FQMatriculaQualifiedToDrive.name"
							[options]="FieldOptions.YesNoOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="getDriverHistoryForm().get(Fields.FQMatriculaQualifiedToDrive.name)"
						></app-dropdown>
					</app-question-wrapper>

					<ng-container *ngIf="!firstTabQuestions">
						<p class="license-information-title">{{ "labels.licenseInformation" | translate }}</p>
					</ng-container>

					<app-question-wrapper
						[show]="
							getDriverHistoryForm().get(Fields.DriverDateLicensed.name) &&
							getDriverHistoryForm().get(Fields.DriverDateLicensed.name).enabled &&
							this.showQuestion(Fields.DriverDateLicensed.name)
						"
						class="date-of-birth"
					>
						<masked-input
							[inputMask]="'**/**/9999'"
							[name]="Fields.DriverDateLicensed.name"
							[placeholder]="'personalInfo.driverDateLicensed' | translate"
							class="date-input"
						>
						</masked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.SnapshotPhoneNumber.name) &&
							getDriverHistoryForm().get(Fields.SnapshotPhoneNumber.name) &&
							getDriverHistoryForm().get(Fields.SnapshotPhoneNumber.name).enabled
						"
					>
						<masked-input
							[inputMask]="phoneNumberMask"
							[name]="Fields.SnapshotPhoneNumber.name"
							[placeholder]="'personalInfo.snapshotMobileNumber' | translate"
							[topPlaceholder]="true"
							type="tel"
						>
						</masked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DriversLicenseBeenSuspendedOrRevoked.name) &&
							getDriverHistoryForm().get(Fields.DriversLicenseBeenSuspendedOrRevoked.name) &&
							getDriverHistoryForm().get(Fields.DriversLicenseBeenSuspendedOrRevoked.name).enabled
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.driversLicenseBeenSuspendedOrRevoked' | translate"
							[name]="Fields.DriversLicenseBeenSuspendedOrRevoked.name"
							[options]="FieldOptions.YesNoOptions"
							[tabindex]="getDriverHistoryForm().get(Fields.DriversLicenseBeenSuspendedOrRevoked.name)"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							getDriverHistoryForm().get(Fields.IsDefensiveDriver.name) &&
							getDriverHistoryForm().get(Fields.IsDefensiveDriver.name).enabled &&
							this.showQuestion(Fields.IsDefensiveDriver.name)
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.isDefensiveDriver' | translate"
							[name]="Fields.IsDefensiveDriver.name"
							[options]="FieldOptions.YesNoOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="getDriverHistoryForm().get(Fields.IsDefensiveDriver.name)"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.DefensiveDriverCourseDate.name) &&
							getDriverHistoryForm().get(Fields.DefensiveDriverCourseDate.name) &&
							getDriverHistoryForm().get(Fields.DefensiveDriverCourseDate.name).enabled
						"
					>
						<masked-input
							[inputMask]="'**/**/9999'"
							[name]="Fields.DefensiveDriverCourseDate.name"
							[placeholder]="'personalInfo.defensiveDriverCourseDate' | translate"
							class="date-input"
						>
						</masked-input>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.IsGoodStudent.name) &&
							getDriverHistoryForm().get(Fields.IsGoodStudent.name) &&
							getDriverHistoryForm().get(Fields.IsGoodStudent.name).enabled
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.isGoodStudent' | translate"
							[name]="Fields.IsGoodStudent.name"
							[options]="FieldOptions.YesNoOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="getDriverHistoryForm().get(Fields.IsGoodStudent.name)"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper
						[show]="
							this.showQuestion(Fields.FQIsStudent100Miles.name) &&
							getDriverHistoryForm().get(Fields.FQIsStudent100Miles.name) &&
							getDriverHistoryForm().get(Fields.FQIsStudent100Miles.name).enabled
						"
					>
						<app-dropdown
							[labelInsideField]="'labels.fqIsStudent100Miles' | translate"
							[name]="Fields.FQIsStudent100Miles.name"
							[options]="FieldOptions.YesNoOptions"
							[reAlphabeticalOrder]="false"
							[tabindex]="getDriverHistoryForm().get(Fields.FQIsStudent100Miles.name)"
						></app-dropdown>
					</app-question-wrapper>

					<div *ngIf="this.showQuestion(Fields.Filings.name)" class="button-state-filing-wrapper">
						<button (click)="openStateFilingPopup(index)" class="button-state-filing">
							{{ "buttons.iNeedStateFiling" | translate }}
						</button>

						<button
							[matTooltip]="'labels.stateFilling' | translate"
							appHiddenWhenCssDisabled
							appToggleTooltip
							class="info-icon material-icons"
						>
							<span aria-hidden="true">info_outline</span>
						</button>
					</div>
				</form>
			</ng-container>
			<div class="divider"></div>
			<div class="next-button-wrapper">
				<app-button
					(clicked)="activateNextTab()"
					*ngIf="!this.isLastTabActive()"
					[disabled]="this.checkValidityCurrentFields()"
					[title]="'buttons.continue' | translate"
					materialIcon="chevron_right"
					severity="primary"
				></app-button>
				<next-button
					(clicked)="handleContinue()"
					*ngIf="this.isLastTabActive()"
					[continue]="true"
					[myForm]="myform"
					[title]="'buttons.continue' | translate"
				></next-button>
				<span [ngClass]="{ show: isLoading }" class="loader show"></span>
				<button (click)="goBack()" *ngIf="activeTabIndex" class="go-back">{{ "buttons.goBack" | translate }}</button>
			</div>
		</div>
	</form>
</section>
