<div class="main-content-popup">
	<div class="text-wrapper" *ngIf="this.data.source == 'discoverDrivers'">
		<p class="title">{{ "titles.enterDriverDetails" | translate }}</p>
		<p class="sub-title">
			{{ "titles.moreDetailsAboutDriver" | translate }}
		</p>
	</div>
	<form [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<app-question-wrapper
				[show]="
					form.get(Fields.DriverRelationshipToDriver1.name) && form.get(Fields.DriverRelationshipToDriver1.name).enabled
				"
				class="realationship-driver-1"
				icon="person"
			>
				<app-dropdown
					[name]="Fields.DriverRelationshipToDriver1.name"
					[options]="FieldOptions.DriverRelationshipToDriver1Options"
					[reAlphabeticalOrder]="false"
					[tabindex]="
						form.get(Fields.DriverRelationshipToDriver1.name) &&
						form.get(Fields.DriverRelationshipToDriver1.name).enabled
							? 0
							: -1
					"
					[labelInsideField]="'labels.relationshipToDriver1' | translate"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
				class="license-status"
				icon="person"
			>
				<app-dropdown
					[name]="Fields.DriverLicenseStatus.name"
					[options]="FieldOptions.DriverLicenseStatus"
					[reAlphabeticalOrder]="false"
					[tabindex]="
						form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled ? 0 : -1
					"
					[labelInsideField]="'personalInfo.driverLicenseStatus' | translate"
				></app-dropdown>
			</app-question-wrapper>

			<app-question-wrapper
				[show]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled"
				class="maritial-status"
				icon="person"
			>
				<app-dropdown
					[name]="Fields.MaritalStatus.name"
					[options]="FieldOptions.maritalStatusOptions"
					[reAlphabeticalOrder]="false"
					[tabindex]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled ? 0 : -1"
					[labelInsideField]="'personalInfo.maritalStatus' | translate"
				></app-dropdown>
			</app-question-wrapper>
		</div>
	</form>

	<div class="next-button-wrapper">
		<app-button
			(clicked)="handleAddEdit()"
			[disabled]="!this.form.valid"
			[title]="this.data.type == 'add' ? ('buttons.addDriver' | translate) : ('buttons.save' | translate)"
			materialIcon="check"
		></app-button>
		<button (click)="close()" class="close-button">{{ "buttons.cancel" | translate }}</button>
	</div>
</div>
