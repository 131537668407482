import { IKeyValue } from '../../entities/key-value.interface';
import { IFieldItem } from '../../entities/field.interface';
import { Fields } from '../constants/fields';
import * as bowser from 'bowser';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { LobsEnum, OfflineLobsEnum } from '../enums/lobs.enum';
import { D2CFeatures } from '../store/states/interview-metadata.state';

export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

export const isUndefined = (obj) => typeof obj === 'undefined';

export const TABLET_WIDTH = 450;
export const DESKTOP_WIDTH = 851;

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

export const formatNumberWithCommas = (value: string, maxlength?: number) => {
	if (value) {
		// get rid of extra charts
		value = value.toString().replace(/,/g, '').replace(/\D/g, '');

		// make value length smaller by number of possible commas
		if (maxlength && maxlength > 3) {
			// update maxlength considering number of possible commas
			maxlength -= Math.floor(maxlength / 3 - 1);

			value = value.slice(0, maxlength);
		}

		return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	return value;
};

export const mapKeyValueArrayToObj = (keyValueArr: IKeyValue[]) => {
	const obj = {};
	if (!keyValueArr || !keyValueArr.length) {
		return;
	}
	for (const keyValue of keyValueArr) {
		obj[keyValue.key.replace('PolicyData.', '')] = keyValue.value;
	}
	return obj;
};

export const NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES = ['Excluded', 'Not Applicable', null];
export const NOT_ALLOWED_ADDITIONAL_COVERAGES_VALUES_POPUP = [null];

export const getFieldByName = (name: string): IFieldItem => {
	const key: string = Object.keys(Fields).find((key: string) => {
		return Fields[key].name === name;
	});
	return Fields[key];
};

export const getDeviceType = (): string => {
	let type;
	if (window.innerWidth < TABLET_WIDTH) {
		type = MOBILE;
	} else if (window.innerWidth > TABLET_WIDTH && window.innerWidth < DESKTOP_WIDTH) {
		type = TABLET;
	} else {
		type = DESKTOP;
	}
	return type;
};

export const isMobile = (): boolean => {
	const deviceType = getDeviceType();
	return deviceType === MOBILE || deviceType === TABLET;
};

export const isSafari = (): boolean => {
	return bowser.getParser(window.navigator.userAgent).getBrowserName().toLowerCase().indexOf('safari') > -1;
};

export const enableControl = (control: AbstractControl, name: string) => {
	let field = getFieldByName(name);

	if (control) {
		control.enable({ emitEvent: false });
		if (field && field.disableGtmDisplayedEvent) {
			return;
		}
	}
};

export const disableControl = (control) => {
	if (control) {
		control.disable({ emitEvent: false });
	}
};

export const addDefaultIndication = (data) => {
	let dataToReturn = JSON.parse(JSON.stringify(data));
	let keys = Object.keys(data);
	keys.forEach((i) => {
		if (['OccupancyType'].includes(i) || (i == 'PLTypeOfDwelling' && data['PLTypeOfDwelling'] == 'Condominium')) {
			if (!dataToReturn['prefillIndication']) {
				dataToReturn['prefillIndication'] = [
					{
						id: i,
						source: 'Defaults',
					},
				];
			} else {
				dataToReturn['prefillIndication']?.push({
					id: i,
					source: 'Defaults',
				});
			}
		}
	});

	if (keys.includes('PLRoofUpdated') && keys.includes('RoofUpdatedYear') && keys.includes('PLYearBuilt')) {
		if (new Date().getFullYear() - data['PLYearBuilt'] < 15) {
			let addInfo = [
				{
					id: 'PLRoofUpdated',
					source: 'Defaults',
				},
				{
					id: 'RoofUpdatedYear',
					source: 'Defaults',
				},
			];
			if (!dataToReturn['prefillIndication']) {
				dataToReturn['prefillIndication'] = addInfo;
			} else {
				dataToReturn['prefillIndication'] = dataToReturn['prefillIndication'].concat(addInfo);
			}
		}
	}
	return dataToReturn;
};

export const PLDwellingMap = (key) => {
	switch (key) {
		case 'MultFamily':
		case 'Condominium':
		case 'Apartment':
			return 'Apartment';
		case 'Rowhouse':
			return 'Rowhouse';
		case 'SingleFamilyHouse':
			return 'SingleFamilyHouse';
		case 'Townhouse':
			return 'Townhouse';
		default:
			return '';
	}
};

export const firstLetterToLowerCase = (value: string) => {
	const firstLowercaseLetter = value.charAt(0).toLowerCase();
	const restOfString = value.slice(1);
	return firstLowercaseLetter + restOfString;
};

export const pricePerMonth = (premium: number, term: string): number => {
	return premium / numOfMonthsInTerm(term);
};

export const numOfMonthsInTerm = (term: string): number => {
	return term === 'TwelveMonths' ? 12 : 6;
};

export const yearlyTermDisplayName = (term: string, shortYear?: boolean): string => {
	if (term === 'TwelveMonths') {
		return shortYear ? 'terms.year-short' : 'terms.year';
	} else {
		return 'terms.6mo';
	}
};

export const toLowerCaseOccupation = (str: string): string => {
	str = str.toLowerCase();
	return str.replace(/[\/].*?[a-zA-Z]|^.*?[a-zA-Z]/gm, (match) => match.toUpperCase());
};

export const getDOBforDriver = (Id, quoteData): string => {
	let foundDriver = quoteData.Drivers.concat(quoteData.manualDrivers)
		.concat(quoteData.discoverDrivers)
		.find((item) => item.Id == Id);
	return foundDriver.DOB;
};

export const getLobSelectionImage = (lobName) => {
	switch (lobName) {
		case LobsEnum.RENTERS:
			return 'renters';
		case LobsEnum.PERSONAL_HOME:
			return 'home';
		case LobsEnum.PERSONAL_AUTO:
			return 'auto';
		case LobsEnum.PETS:
			return 'pets';
		case OfflineLobsEnum.DEVICE_PROTECTION:
			return 'deviceProtection';
	}
};

// todo remove?
export const getLobDisplayName = (lobName: string): string => {
	switch (lobName) {
		case LobsEnum.RENTERS:
			return 'lobs.Renters';
		case LobsEnum.PERSONAL_HOME:
			return 'lobs.PersonalHome';
		case LobsEnum.PERSONAL_AUTO:
			return 'lobs.PersonalAuto';
		case LobsEnum.PETS:
			return 'lobs.Pets';
		case OfflineLobsEnum.DEVICE_PROTECTION:
			return 'lobs.DeviceProtection';
	}
};

export const isMultiLob = (lobs: any[]) => {
	return (
		lobs.indexOf(LobsEnum.PERSONAL_AUTO) > -1 &&
		(lobs.indexOf(LobsEnum.PERSONAL_HOME) > -1 ||
			lobs.indexOf(LobsEnum.CONDOMINIUM) > -1 ||
			lobs.indexOf(LobsEnum.DWELLING_FIRE) > -1)
	);
};

export const duplicateObjectWithoutReadOnly = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

export const capitalize = (title) => {
	return title
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const flattenFQData = (obj) => {
	var result = {};
	(function f(e, p) {
		switch (typeof e) {
			case 'object':
				p = p ? p + '.' : '';
				for (var i in e) {
					f(e[i], p + i);
				}
				break;
			default:
				result[p] = e;
				break;
		}
	})(obj);
	return result;
};

export const sortByProperty = (arrayOfObject, property) => {
	return arrayOfObject.sort(function (a, b) {
		return a[property] - b[property];
	});
};

export const maskingLicenseNumber = (licenseNumber) => {
	return licenseNumber.substring(0, licenseNumber.length - 4) + 'XXXX';
};

export const convertJsonToArray = (data) => {
	let formBuilder = new FormBuilder();
	if (data.length > 0) {
		return formBuilder.array(
			data?.map((r) => {
				return formBuilder.group(r);
			})
		);
	} else {
		return formBuilder.array([]);
	}
};

export const getD2CFeatures = (): D2CFeatures => {
	return JSON.parse(sessionStorage.getItem('leadSource'))?.leadSource?.additionalInformation?.d2CFeatures;
};

export const handleIncompleteAddressInAddressForm = (addressForm: FormGroup) => {
	if (
		!addressForm.get('AddressLine1').value ||
		!addressForm.get('City').value ||
		!addressForm.get('ZipCode').value ||
		!addressForm.get('State').value
	) {
		addressForm.get('AddressLine1').setErrors({ notFullAddress: true });
		addressForm.get('AddressLine1').markAsTouched();
	}
};
