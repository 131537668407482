<div [class.visible]="isVisible" class="steps-wrapper">
	<div [class.multiLob]="moreThenOneLob" class="steps-flow">
		<ng-container *ngFor="let step of steps; let i = index; trackBy: trackByFn">
			<ng-container *ngIf="moreThenOneLob; else oneLob">
				<span
					class="multiLob-step"
					*ngIf="step.stepProperties.multiLobTitle"
					[ngClass]="{
						active:
							i === progressMeter.activeStep ||
							(i + step.stepProperties.showStepAsActiveFor?.length >= progressMeter.activeStep &&
								i < progressMeter.activeStep),
						visited: i < progressMeter.activeStep,
						noWidth: !step.stepProperties.showStepAsActiveFor
					}"
				>
					<ng-container *ngIf="step.stepProperties.showStepAsActiveFor; else noSteps">
						<span
							class="multiLob-step-progress multiLob visible"
							*ngFor="let innerStep of step.stepProperties.showStepAsActiveFor; let j = index"
							[ngClass]="{
								active: i + 1 + j === progressMeter.activeStep || (j == 0 && i === progressMeter.activeStep),
								visited: i + 1 + j < progressMeter.activeStep
							}"
							><span *ngIf="j == 0" class="multiLob-title">{{ ("multiLobSteps." + step.stepProperties.multiLobTitle) | translate }}</span
							><i class="dot"></i
						></span>
					</ng-container>
					<ng-template #noSteps>
						<span
							class="multiLob-step-progress multiLob visible"
							[ngClass]="{
								active: i === progressMeter.activeStep,
								visited: i < progressMeter.activeStep
							}"
							><span class="multiLob-title">{{ ("multiLobSteps." + step.stepProperties.multiLobTitle) | translate }}</span
							><i class="dot"></i
						></span>
					</ng-template>

					<!-- <i class="dot"></i> -->
				</span>
			</ng-container>
			<ng-template #oneLob>
				<span
					*ngIf="shouldDisplayStep(step)"
					[ngClass]="{
						active: step.active,
						visited: step.visited,
						visible: isStepVisible(step)
					}"
					class="step"
				>
					<span class="step-progress">
						<i class="dot"></i>
					</span>
					{{ ("steps." + step.stepProperties.title) | translate }}
				</span>
			</ng-template>
		</ng-container>
	</div>
</div>
