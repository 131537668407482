import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Fields } from 'src/app/constants/fields';
import { RoutingService } from 'src/app/services/routing.service';
import { LoaderActions } from 'src/app/store/actions';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { IQuoteDataState } from 'src/app/store/states/quote-data.state';
import { updateApplication } from 'src/app/store/actions/api.actions';
import { isUndefined } from 'src/app/utils/general.utils';
import { StepsEnum } from '../../enums/steps.enum';
import { EventActions } from '../../models/event-data.model';
import { GtmControlsActions } from '../../models/gtm-controls-actions.enum';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { getSelectedCarrier, getSelectedRate } from 'src/app/store/selectors/result-data.selectors';
import { ActionApiService } from 'src/app/services/action-api.service';
import { AppService } from 'src/app/services/app.service';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { LobService } from 'src/app/services/lob.service';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	selector: 'next-button',
	templateUrl: './next-button.component.html',
	styleUrls: ['./next-button.component.scss'],
})
export class NextButtonComponent implements OnInit {
	@Input() title: string;
	@Input() titleKey: string;
	@Input() disabled: boolean;
	@Input() kickout: any[];
	@Input() myForm: FormGroupDirective;
	@Input() animation?: boolean;
	@Input() continue: boolean;
	@Input() loaderVisible = true;
	@Input() showTextLoading: boolean;
	@Input() showIcon: boolean = true;
	@Input() textLoading: string;
	@Input() addPrefill: [];
	@Input() isFullQuote: boolean = false;
	@Input() shouldIgnoreDisabledFields: boolean = false;

	@Output() clicked = new EventEmitter();
	@Output() validationFailed = new EventEmitter();

	loading = false;
	doneLoading: boolean;
	Fields = Fields;
	lobs: Array<LobsEnum>;
	lob: string;
	quoteData: IQuoteDataState;
	StepsEnum = StepsEnum;
	last_route: string;
	path: string;
	selectedCarrier: string;

	constructor(
		private store: Store,
		private actionService: ActionApiService,
		private eventsService: EventsService,
		private routingService: RoutingService,
		private formattedDateService: FormattedDateService,
		private appService: AppService,
		private progressMeterService: ProgressMeterService,
		private lobService: LobService
	) {
		if (isUndefined(this.animation)) {
			this.animation = true;
		}
	}

	ngOnInit(): void {
		this.lobService
			.getLob$()
			.pipe(untilDestroyed(this))
			.subscribe((lob) => {
				if (lob) {
					this.lob = lob;
				}
			});

		this.store
			.select(getSelectedCarrier)
			.pipe(first())
			.subscribe((res) => {
				this.selectedCarrier = res;
			});
		let splitted_route = this.routingService.currentRoute.split('/');
		this.last_route = splitted_route[splitted_route.length - 1]?.split('?')[0];

		let currentPath =
			this.routingService.currentRoute.indexOf('/') > -1
				? this.routingService.currentRoute.split('/')[1]
				: this.routingService.currentRoute;
		this.path = currentPath?.split('?')[0];
	}

	onNextButtonClick(): void {
		let path = this.routingService.currentRoute.indexOf('/') > -1
			? this.routingService.currentRoute.split('/').pop()
			: this.routingService.currentRoute;
		path = path?.split('?')[0]
		this.eventsService.addEvent(EventActions.BUTTON_CLICK, {
			label: `next-button-${path}`,
			button: `next-button-${path}`,
			module: 'next-button-module',
		});
		if (this.continue) {
			if (this.loaderVisible) {
				this.loading = true;
			}

			this.clicked.emit();
			return;
		} else {
			this.clicked.emit();
			this.store.dispatch(LoaderActions.ShowLoader());

			setTimeout(() => {
				const errorList = [];
				let isError = false;

				if (this.kickout) {
					this.store
						.select(getSelectedRate)
						.pipe(first())
						.subscribe((data) => {
							this.actionService
								.singleSubmitQuote(data.carrier)
								.pipe(first())
								.subscribe(() => {
									this.loading = true;

									this.store.dispatch(
										updateApplication({
											formData: this.myForm.form.value,
											Carrier: this.isFullQuote && this.selectedCarrier ? this.selectedCarrier : null,
											isFullQuote: this.isFullQuote,
										})
									);

									return;
								});
						});
				} else {
					if (this.myForm) {
						Object.keys(this.myForm.form.controls).forEach((field) => {
							let control = this.myForm.form.controls[field];

							if (!control) {
								return;
							}

							if (control.enabled) {
								control.markAsTouched();
							}

							if (control.errors) {
								errorList.push({ errors: control.errors, name: field });
								isError = true;
								this.eventsService.addEvent(EventActions.SYS, { label: `${field}_${GtmControlsActions.ERROR}` });
							}
						});

						this.loading = true;

						if (!isError) {
							// If server returns validation errors related to controls on current page,
							// form will become invalid and errors will be displayed.
							this.myForm.form.statusChanges.pipe(first()).subscribe((validity) => {
								if (validity === 'INVALID') {
									this.loading = false;
									this.store.dispatch(LoaderActions.Hideloader());
								}
							});

							let data = this.shouldIgnoreDisabledFields ? this.myForm.form.value : this.myForm.form.getRawValue();
							if (data.EffectiveDate) {
								var date = new Date(data.EffectiveDate);
								date.setDate(date.getDate() + 1);
								if (this.lob !== LobsEnum.PERSONAL_AUTO) {
									data.PriorCarrierExperationDate = this.formattedDateService.formatDate(date);
								} else {
									data.PriorCarrierExpirationDateAuto = null;
								}
							}

							if (data.CoDateOfBirth) {
								const date = new Date(data.CoDateOfBirth);
								data.CoDateOfBirth = this.formattedDateService.formatDate(date, 'YYYY-MM-DD');
							}

							//filtering empty losses
							if (data.PersonalLosses) {
								data.PersonalLosses = data.PersonalLosses.filter((loss) => {
									if (loss.SequenceNum == -1) {
										return loss;
									} else {
										if (loss.DateOfLoss || loss.PLLossDescription || loss.PersonalLineLossAmount) {
											return loss;
										}
									}
								});
								// drops personalLosses property if there is no losses
								if (!data.PersonalLosses.length) {
									delete data.PersonalLosses;
								}
							}

							// only handle lemonade FQ page
							if (data.NumberOfMortgagees != null && typeof data.NumberOfMortgagees === 'boolean') {
								if (data.NumberOfMortgagees == true) {
									data.NumberOfMortgagees = 1;
								} else {
									data.NumberOfMortgagees = 0;
								}
							}
							if (this.addPrefill && this.addPrefill.length > 0) {
								//add prefillIndication
								data.prefillIndication = this.appService.createPrefillIndicationData(this.addPrefill);
							}

							delete data[this.Fields.PoolFeatures.name];

							this.transformPayload(data);

							this.store.dispatch(
								updateApplication({
									formData: { ...data },
									Carrier: this.isFullQuote && this.selectedCarrier ? this.selectedCarrier : null,
									isFullQuote: this.isFullQuote,
								})
							);
						} else {
							this.validationFailed.emit(errorList);
						}
					} else {
						// No form just go to next page
						if (this.animation) {
							this.loading = true;
						}
						this.progressMeterService.goToNextPage();
					}
				}
			}, 0);
		}
	}

	transformPayload(payload: any): void {
		[Fields.HeatingUpdateYN.name, Fields.ElectricalUpdateYN.name, Fields.PlumbingUpdateYN.name].forEach((field) => {
			if (payload[field]) {
				payload[field] = !!payload[field];
			}
		});
	}
}
