import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment/moment';
import { combineLatest } from 'rxjs';

import { ThemeModel } from '../models/theme.model';
import { selectQuoteData } from '../store/selectors/quote-data.selectors';
import { getSelectedCarrier } from '../store/selectors/result-data.selectors';
import { selectLobSelection } from '../store/selectors/interview-metadata.selector';
import { selectActiveTheme } from '../store/selectors/active-theme.selector';
import { IAppState } from '../store/states/app.state';
import { selectLanguageSettings } from '../store/selectors/languages.selector';
import { TranslateService } from '@ngx-translate/core';
import { getLobDisplayName } from '../utils/general.utils';

const NON_EMPTY_ELEMENTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label'];
const NO_TRANSLATION_TEXT = 'NO TRANSLATION';

@UntilDestroy()
@Directive({
	selector: '[appTranslation]',
})
export class TranslationDirective implements OnInit, OnDestroy {
	@Input('appTranslation') shortcut: string;
	@Input('masks') additionalMasks: any;
	@Input('attributeOnly') attributeOnly: boolean;
	@Input('translateAttr') translateAttr: string;

	template: TemplateRef<any>;
	theme: ThemeModel;
	texts = {};
	masks = {};
	lang: string;

	constructor(
		private element: ElementRef,
		private store: Store<IAppState>,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		if (this.shortcut) {
			combineLatest([
				this.store.select(selectActiveTheme),
				this.store.select(selectQuoteData),
				this.store.select(getSelectedCarrier),
				this.store.select(selectLobSelection),
				this.store.select(selectLanguageSettings),
			])
				.pipe(untilDestroyed(this))
				.subscribe(([theme, quoteData, selectedCarrier, lobSelection, langSettings]) => {
					if (!this.attributeOnly) {
						this.element.nativeElement.setAttribute('hidden', 'hidden');
					}

					const quoteDataMasks = quoteData;
					const agentNumber = theme.agentPhoneNumber;
					const firstName = quoteData.FirstName
						? quoteData.FirstName.charAt(0).toUpperCase() + quoteData.FirstName.slice(1)
						: '';

					this.texts = theme.texts;
					this.lang = langSettings.selected ? `:${langSettings.selected}` : '';

					this.masks = {
						...quoteDataMasks,
						selectedCarrier: selectedCarrier,
						lobSelection: getLobDisplayName(lobSelection[0]),
						first_name: firstName,
						FirstName: firstName,
						agent_number: `<span class="nowrap">${agentNumber}</span>`,
						agent_number_link: `<a href="tel:${agentNumber}" class="nowrap">${agentNumber}</a>`,
						...this.additionalMasks,
					};
					this.reloadTranslation();

					this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((event) => {
						this.lang = langSettings.selected ? `:${langSettings.selected}` : '';
						this.reloadTranslation();
					});
				});
		}
	}

	transformMaskValue(value: string): string {
		value = moment(value, moment.ISO_8601, true).isValid() ? moment(value).format('MM/DD/YY') : value;
		// if the mask contains keys from language files, translate them
		value = value && typeof value === 'string' ? this.translateService.instant(value) : value;
		return value;
	}

	unmask(value: string): string {
		let newValue = value;

		if (newValue) {
			for (const mask in this.masks) {
				if (this.masks.hasOwnProperty(mask)) {
					const req = `<%${mask}%>`;

					newValue = newValue.replace(new RegExp(req, 'g'), this.transformMaskValue(this.masks[mask]));
				}
			}
		}

		return newValue;
	}

	value(): string {
		const step = this.shortcut.split('.')[0];
		const key = this.shortcut.split('.')[1];

		let text = this.texts[step]?.hasOwnProperty(key + `${this.lang}`)
			? this.texts[step][key + `${this.lang}`]
			: this.texts[step][key];

		return this.unmask(text);
	}

	ngOnDestroy(): void {}

	private reloadTranslation() {
		if (this.value()) {
			if (!this.attributeOnly) {
				this.element.nativeElement.removeAttribute('hidden');
				this.element.nativeElement.innerHTML = this.value();
			}

			if (this.translateAttr) {
				this.translateAttr.split(',').forEach((attr) => {
					this.element.nativeElement.setAttribute(attr, this.value());
				});
			}
		} else {
			// If the element belongs to the list of elements that shouldn't be empty due to accessibility requirements,
			// add dummy text and hide the element for user and screen readers
			if (NON_EMPTY_ELEMENTS.includes(this.element.nativeElement.tagName.toLowerCase())) {
				this.element.nativeElement.innerHTML = NO_TRANSLATION_TEXT;
				this.element.nativeElement.setAttribute('aria-hidden', true);
			}
		}
	}
}
