<div class="container">
	<h1>{{ "labels.disclaimer" | translate }}</h1>
	<h2>{{ "fiskerDisclaimer.aboutMarsh" | translate }}</h2>
	<p>
		{{ "fiskerDisclaimer.marshDisclaimer" | translate }}
	</p>
	<h2>{{ "labels.privacyPolicy" | translate }}</h2>
	<p>
		{{ "fiskerDisclaimer.marshPrivacyPolicy" | translate }}
	</p>
	<p>
		{{ "labels.accessOurPrivacyPolicy" | translate }} {{ " " }}
		<a href="https://www.marsh.com/us/privacy-notice.html" target="_blank">{{ "labels.here" | translate }}</a
		>{{ "." }}
	</p>
	<h2>{{ "fiskerDisclaimer.ourRole" | translate }}</h2>
	<p>
		{{ "fiskerDisclaimer.exclusiveBrokerOfRecordToFisker" | translate }}
	</p>
	<p>
		{{ "fiskerDisclaimer.commissionCompensationDetails" | translate }}
		<a href="mailto:Affinity.Compliance@marsh.com">Affinity.Compliance@marsh.com.</a>
	</p>
	<p>
		{{ "fiskerDisclaimer.marshAndMcLennanEquityInterests" | translate }}
		<a href="https://www.marsh.com/us/about/about-marsh/leading-the-way-in-transparency.html" target="_blank">{{
			"labels.here" | translate
		}}</a
		>.
	</p>
	<h2>{{ "labels.insuranceLicenses" | translate }}</h2>
	<p>
		{{ "fiskerDisclaimer.marshUSALicensingCompliance" | translate }}
	</p>
	<table>
		<thead>
			<tr>
				<th>{{ "address.state" | translate }}</th>
				<th>{{ "labels.licenseNumber" | translate }}</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>AK</td>
				<td>7782</td>
			</tr>
			<tr>
				<td>AL</td>
				<td>184761</td>
			</tr>
			<tr>
				<td>AR</td>
				<td>100105836</td>
			</tr>
			<tr>
				<td>AZ</td>
				<td>1800003403</td>
			</tr>
			<tr>
				<td>CA</td>
				<td>0437153</td>
			</tr>
			<tr>
				<td>CO</td>
				<td>46594</td>
			</tr>
			<tr>
				<td>CT</td>
				<td>533650</td>
			</tr>
			<tr>
				<td>DC</td>
				<td>9902067</td>
			</tr>
			<tr>
				<td>DE</td>
				<td>152229</td>
			</tr>
			<tr>
				<td>FL</td>
				<td>L035099</td>
			</tr>
			<tr>
				<td>GA</td>
				<td>103207</td>
			</tr>
			<tr>
				<td>HI</td>
				<td>300200</td>
			</tr>
			<tr>
				<td>IA</td>
				<td>1001000374</td>
			</tr>
			<tr>
				<td>ID</td>
				<td>115417</td>
			</tr>
			<tr>
				<td>IL</td>
				<td>100291927</td>
			</tr>
			<tr>
				<td>IN</td>
				<td>307116N</td>
			</tr>
			<tr>
				<td>KS</td>
				<td>361436000-19</td>
			</tr>
			<tr>
				<td>KY</td>
				<td>DOI-514638</td>
			</tr>
			<tr>
				<td>LA</td>
				<td>288792</td>
			</tr>
			<tr>
				<td>MA</td>
				<td>1809884</td>
			</tr>
			<tr>
				<td>MD</td>
				<td>1280</td>
			</tr>
			<tr>
				<td>ME</td>
				<td>AGN37019</td>
			</tr>
			<tr>
				<td>MI</td>
				<td>4536</td>
			</tr>
			<tr>
				<td>MN</td>
				<td>20352519</td>
			</tr>
			<tr>
				<td>MO</td>
				<td>8615</td>
			</tr>
			<tr>
				<td>MS</td>
				<td>206096</td>
			</tr>
			<tr>
				<td>MT</td>
				<td>968820</td>
			</tr>
			<tr>
				<td>NC</td>
				<td>1000011881</td>
			</tr>
			<tr>
				<td>ND</td>
				<td>20290108</td>
			</tr>
			<tr>
				<td>NE</td>
				<td>100175985</td>
			</tr>
			<tr>
				<td>NH</td>
				<td>355051</td>
			</tr>
			<tr>
				<td>NJ</td>
				<td>8022364</td>
			</tr>
			<tr>
				<td>NM</td>
				<td>1800001554</td>
			</tr>
			<tr>
				<td>NV</td>
				<td>705</td>
			</tr>
			<tr>
				<td>NY</td>
				<td>PC-633691</td>
			</tr>
			<tr>
				<td>NY</td>
				<td>BR-633691</td>
			</tr>
			<tr>
				<td>OH</td>
				<td>24035</td>
			</tr>
			<tr>
				<td>OK</td>
				<td>8861</td>
			</tr>
			<tr>
				<td>OR</td>
				<td>100152619</td>
			</tr>
			<tr>
				<td>PA</td>
				<td>55145</td>
			</tr>
			<tr>
				<td>SC</td>
				<td>1907810878</td>
			</tr>
			<tr>
				<td>SD</td>
				<td>7001</td>
			</tr>
			<tr>
				<td>TN</td>
				<td>1569</td>
			</tr>
			<tr>
				<td>TX</td>
				<td>14797</td>
			</tr>
			<tr>
				<td>UT</td>
				<td>5263</td>
			</tr>
			<tr>
				<td>VA</td>
				<td>108379</td>
			</tr>
			<tr>
				<td>VI</td>
				<td>100341546</td>
			</tr>
			<tr>
				<td>VT</td>
				<td>538375</td>
			</tr>
			<tr>
				<td>WA</td>
				<td>82040</td>
			</tr>
			<tr>
				<td>WI</td>
				<td>100190887</td>
			</tr>
			<tr>
				<td>WV</td>
				<td>100111513</td>
			</tr>
			<tr>
				<td>WY</td>
				<td>9570</td>
			</tr>
		</tbody>
	</table>
</div>
