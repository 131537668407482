<section class="main-content">
	<app-page-heading [title]="'titles.homeUtilities' | translate"></app-page-heading>
	<form #myform="ngForm" [formGroup]="form" class="all-questions-wrapper stillwater-fq">
		<app-question-wrapper *ngIf="form.controls[Fields.PLHeatingType.name]?.enabled" class="question-container">
			<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
				<label [attr.id]="Fields.PLHeatingType.name" class="input-label">
					{{ "fields.PLHeatingType" | translate }}
				</label>
				<app-dropdown
					[labelInsideField]="'labels.pleaseSelect' | translate"
					[name]="Fields.PLHeatingType.name"
					[options]="FieldOptions.PLHeatingTypeOptions"
					[reAlphabeticalOrder]="false"
					notShowLabelWhenSelected="true"
				></app-dropdown>
			</div>
		</app-question-wrapper>

		<app-question-wrapper *ngIf="form.controls[Fields.SolidFuel.name]?.enabled" class="question-container">
			<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
				<label [attr.id]="Fields.SolidFuel.name" class="input-label">
					{{ "fields.FQData.StillWaterPersonalHome.SolidFuel" | translate }}
				</label>
				<app-yes-no [name]="Fields.SolidFuel.name" [vertical]="vertical"></app-yes-no>
			</div>
		</app-question-wrapper>

		<app-question-wrapper
			*ngIf="form.controls[Fields.SW_ElectricCircuitBreaker.name]?.enabled"
			class="question-container"
		>
			<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
				<label [attr.id]="Fields.SW_ElectricCircuitBreaker.name" class="input-label">
					{{ "fields.ElectricCircuitBreaker" | translate }}
				</label>
				<app-yes-no [name]="Fields.SW_ElectricCircuitBreaker.name" [vertical]="vertical"></app-yes-no>
			</div>
		</app-question-wrapper>

		<b class="basic-text">{{ "titles.anySystemsUpdated" | translate }}</b>

		<app-question-wrapper *ngFor="let field of stepTwoFields">
			<label [attr.id]="field.name" class="input-label">
				{{ "fields." + field.name | translate }}
			</label>
			<app-dropdown
				[labelInsideField]="'labels.pleaseSelect' | translate"
				[name]="field.name"
				[options]="field.options"
				[reAlphabeticalOrder]="false"
				notShowLabelWhenSelected="true"
				searchable="true"
			></app-dropdown>
		</app-question-wrapper>

		<next-button
			[disabled]="form.invalid"
			[loaderVisible]="false"
			[myForm]="myform"
			[title]="'buttons.continue' | translate"
			class="fullscreen"
			isFullQuote="true"
		></next-button>
	</form>
</section>
