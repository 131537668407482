import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { BasePageComponent } from '../../../../../base-page.component';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Fields } from '../stillwater-fields';
import { Validations } from 'src/app/utils/validation';
import { handleIncompleteAddressInAddressForm } from '../../../../../../../../utils/general.utils';

@Component({
	selector: 'app-stillwater-property-information',
	templateUrl: './stillwater-property-information.component.html',
	styleUrls: ['./stillwater-property-information.component.scss', '../stillwater.component.scss'],
})
export class StillwaterPropertyInformationComponent extends BasePageComponent implements OnInit {
	Fields = Fields;
	vertical = true;

	fieldsNamesPreviousAddress = {
		AddressLine1: 'PreviousAddress.AddressLine1',
		AddressLine2: 'PreviousAddress.AddressLine2',
		City: 'PreviousAddress.City',
		State: 'PreviousAddress.State',
		ZipCode: 'PreviousAddress.ZipCode',
		County: 'PreviousAddress.County',
	};

	fieldsNamesMailAddress = {
		AddressLine1: 'MailingAddress.AddressLine1',
		AddressLine2: 'MailingAddress.AddressLine2',
		City: 'MailingAddress.City',
		State: 'MailingAddress.State',
		ZipCode: 'MailingAddress.ZipCode',
		County: 'MailingAddress.County',
	};

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService
	) {
		super(injector, store);
	}

	get today(): Date {
		return new Date();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({
			[this.Fields.PurchaseDate.name]: [null, [Validators.required]],
			[this.Fields.YearsAtAddress.name]: [null, [Validators.required]],
			[this.Fields.PreviousAddress.name]: this.fb.group({
				AddressLine1: [null, [Validators.required, Validators.maxLength(50), Validations.isPoBox]],
				AddressLine2: [null, [Validators.maxLength(50)]],
				City: [null, [Validators.required, Validators.maxLength(25)]],
				State: [null, [Validators.required, Validators.maxLength(2)]],
				ZipCode: [null, Validations.zipStrictLength(5)],
				County: [null],
			}),
			[this.Fields.isMailAddress.name]: [null, [Validators.required]],

			[this.Fields.MailingAddress.name]: this.fb.group({
				AddressLine1: [null, [Validators.required, Validators.maxLength(50), Validations.isPoBox]],
				AddressLine2: [null, [Validators.maxLength(50)]],
				City: [null, [Validators.required, Validators.maxLength(25)]],
				State: [null, [Validators.required, Validators.maxLength(2)]],
				ZipCode: [null, Validations.zipStrictLength(5)],
				County: [null],
			}),
			[this.Fields.MultiFamilyUnit.name]: [null, [Validators.required]],
			[this.Fields.PL_NumOfFamilies.name]: [null, [Validators.required]],
			[this.Fields.ExistingDamageOnDwelling.name]: [null, [Validators.required]],
			[this.Fields.RoadAccess.name]: [null, [Validators.required]],
		});

		this.form.controls.YearsAtAddress.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			value
				? this.form.controls[this.Fields.PreviousAddress.name].enable()
				: this.form.controls[this.Fields.PreviousAddress.name].disable();
		});

		this.form.controls[this.Fields.isMailAddress.name].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			value
				? this.form.controls[this.Fields.MailingAddress.name].enable()
				: this.form.controls[this.Fields.MailingAddress.name].disable();
		});

		super.registerOnChange(this.form);
		this.patchData(this.form);
	}

	asFormGroup(form: AbstractControl): FormGroup {
		return form as FormGroup;
	}

	handleAddressChange(addressForm: AbstractControl) {
		handleIncompleteAddressInAddressForm(this.asFormGroup(addressForm));
	}
}
