import { AfterViewInit, Component, Injector, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { IAppState } from 'src/app/store/states/app.state';
import { BaseFormControlComponent } from '../base-form-control.component';
import { EventsService } from 'src/app/services/events.service';

@Component({
	selector: 'app-checkbox-image',
	templateUrl: './checkbox-image.component.html',
	styleUrls: ['./checkbox-image.component.scss'],
})
export class CheckboxImageComponent extends BaseFormControlComponent implements AfterViewInit {
	@Input() image;
	@Input() selectedImage;
	@Input() label;

	constructor(injector: Injector, protected store: Store<IAppState>, eventsService: EventsService) {
		super(injector, store, eventsService);
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	isInvalid() {
		const field = this.controlContainer.control.controls[this.name];
		return field.touched && field.status === 'INVALID';
	}
}
