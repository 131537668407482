import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAppState } from '../store/states/app.state';
import { IInterviewMetadataState } from '../store/states/interview-metadata.state';
import { Dictionary } from '../../entities/key-value.interface';
import { ResetInterviewMetadata } from '../store/actions/interview-metadata.actions';
import { ActionApiService } from './action-api.service';
import { ConfigService } from './config.service';
import { ResetQuoteData } from '../store/actions/quote-data.actions';
import { resetProgressBar } from '../store/actions/progress-meter.actions';
import { ResetResultData } from '../store/actions/result-data.actions';
import { GeneralPropsEnum } from '../models/general-props.enum';
import { ThemeModel } from '../models/theme.model';
import { UpdateActiveTheme } from '../store/actions/theme.action';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppService {
	interviewMetadata: IInterviewMetadataState;
	cssRules: Dictionary<string> = {};
	fonts: Dictionary<string> = {};
	classes: Dictionary<string> = {};
	themeName: string;
	favIconFile: string;

	constructor(
		private store: Store<IAppState>,
		private actionApiService: ActionApiService,
		private configService: ConfigService
	) {}

	generateStyles(source) {
		let Observable$ = new ReplaySubject();
		const state = this.configService.getState();
		const theme = state.theme as ThemeModel;

		if (source) {
			if (theme && source.toLowerCase() !== theme[GeneralPropsEnum.name].toLowerCase()) {
				this.loadCssRules(source, Observable$);
			} else {
				this.initCssRules(theme);
				Observable$.next(true);
			}
		} else {
			this.initCssRules(theme);
			Observable$.next(true);
		}

		return Observable$;
	}

	public clearData() {
		this.store.dispatch(resetProgressBar());
		this.store.dispatch(ResetQuoteData());
		this.store.dispatch(ResetInterviewMetadata());
		this.store.dispatch(ResetResultData());
	}

	createPrefillIndicationData(keys) {
		return keys.map((x) => {
			return { id: x, source: 'Defaults' };
		});
	}

	appendStyles(cssRules: Dictionary<string> = {}, selector = ':root'): void {
		let concatenatedStyles = '';

		const element = document.createElement('style');

		Object.entries(cssRules).forEach(([key, value]) => {
			if (
				!isNaN(Number(value)) &&
				!key.includes('weight') &&
				!key.includes('_nopx') &&
				!key.includes('fill') &&
				value?.toString()?.trim().length
			) {
				value = value + 'px';
			}

			if (value?.toString()?.trim().length) {
				concatenatedStyles += `${key}:${value};`;
			}
		});

		document.head.appendChild(element);

		(element.sheet as CSSStyleSheet).insertRule(`${selector}{${concatenatedStyles}}`);
	}

	trySetBaseOnStartup() {
		if (this.configService.getKey('icon')) {
			const favIcon: HTMLLinkElement = document.querySelector('#favicon');

			favIcon.href = this.configService.getKey('icon');
		}

		if (this.configService.getKey('title')) {
			const title = document.querySelector('#title');

			title.innerHTML = this.configService.getKey('title');
		}
	}

	private loadCssRules(source: string, Observable$) {
		const state = this.configService.getState();
		const theme = state.theme as ThemeModel;

		this.actionApiService
			.getThemeBySourceName(source.toUpperCase())
			.pipe(untilDestroyed(this))
			.subscribe({
				next: (themeDataRes) => {
					const themeData = JSON.parse(themeDataRes.customizationJsonString) as ThemeModel;
					this.themeName = themeDataRes.sourceName;
					this.favIconFile = themeData.images['favIcon'];

					this.changeIcon();

					if (themeData) {
						this.initCssRules(themeData);

						const theme = {
							...themeData,
							[GeneralPropsEnum.name]: themeDataRes.sourceName,
							[GeneralPropsEnum.properties]: this.cssRules,
							[GeneralPropsEnum.fonts]: this.fonts,
						} as ThemeModel;

						if (themeData?.landingPage?.length) {
							const lpSections = [...themeData?.landingPage];

							lpSections.forEach((section) => {
								if (!theme.texts[section.name]) {
									theme.texts[section.name] = {
										...section.texts,
									};
								}
							});
						}

						if (themeData.texts && Object.keys(themeData.texts).length) {
							theme[GeneralPropsEnum.texts] = themeData.texts;
						}

						this.store.dispatch(UpdateActiveTheme({ theme }));
					}

					sessionStorage.setItem('version', themeDataRes.version);
				},
				error: (err) => {
					this.initCssRules(theme);
				},
				complete: () => {
					Observable$.next(true);
				},
			});
	}

	private appendClasses(): void {
		document.body.className = '';

		if (this.classes) {
			const classArray = Object.entries(this.classes);

			classArray.forEach(([key, className]) => {
				document.body.classList.add(className);
			});
		}
	}

	private appendFonts(): void {
		const element = document.createElement('style');

		document.head.appendChild(element);

		Object.entries(this.fonts).forEach(([key]) => {
			if (this.fonts[key]?.length) {
				element.innerHTML += `@import url('${this.fonts[key]}');`;
			}
		});
	}

	private initCssRules(theme: ThemeModel): void {
		this.cssRules = theme[GeneralPropsEnum.properties];
		this.fonts = theme[GeneralPropsEnum.fonts];
		this.classes = theme[GeneralPropsEnum.additionalClasses];

		this.appendClasses();
		this.appendFonts();
		this.appendStyles(this.cssRules);

		if (theme[GeneralPropsEnum.landingPage]?.length) {
			theme[GeneralPropsEnum.landingPage].forEach(({ name, properties }) => {
				this.appendStyles(properties, `.${name}`);
			});
		}
	}

	private changeIcon() {
		const favIcon: HTMLLinkElement = document.querySelector('#favicon');
		const favIconUrl =
			favIcon && this.favIconFile
				? this.actionApiService.getFileSrc(this.favIconFile, this.themeName)
				: 'assets/images/favicon.ico';

		favIcon.href = favIconUrl;

		this.configService.setKey('icon', favIconUrl);
	}
}
