<ul class="radio-list-container">
	<li *ngFor="let option of options; let index = index">
		<button
			(click)="handleChooseOption(option.value)"
			[attr.data-automation]="option.imageName"
			[ngClass]="{ checked: option.selected, disabled: !option.enabled }"
			[tabindex]="!option.enabled ? -1 : 0"
			class="radio-button-item"
		>
			<span class="circle-wrapper">
				<span class="inner"></span>
			</span>
			<div *ngIf="option.enabled" [ngClass]="{ selectedOption: option.selected }" class="checkbox"></div>
			<span class="img-wrapper">
				<app-image
					[class.show]="option.selected"
					[name]="getFileName(option.imageName || option.value, true)"
					alt="null"
					role="presentation"
				></app-image>

				<app-image
					[class.show]="!option.selected"
					[name]="getFileName(option.imageName || option.value, false)"
					alt="null"
					role="presentation"
				></app-image>
			</span>

			<div class="text-container">
				<input
					[attr.id]="option.value"
					[attr.tabindex]="-1"
					[checked]="option.selected"
					[name]="name"
					[value]="option.value"
					class="radio-input"
					type="radio"
				/>
				<span class="title">
					{{ option.title | translate }}
				</span>

				<div *ngIf="option.enabled && !option.selected && this.isSomeOptionEnabled()" class="bundle-text">BUNDLE</div>
			</div>
		</button>
	</li>
</ul>
