import { Component, Input } from '@angular/core';
import { IFieldItem } from '../../../../../../entities/field.interface';

@Component({
	selector: 'app-question-wrapper',
	templateUrl: './question-wrapper.component.html',
	styleUrls: ['./question-wrapper.component.scss'],
})
export class QuestionWrapperComponent {
	@Input() show: boolean = true;
	@Input() icon: string;
	@Input() field: IFieldItem;
	@Input() optionalKeyText: string;
	@Input() tooltipKeyText: string;
}
