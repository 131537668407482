import { Routes } from '@angular/router';
import { EndSessionErrorComponent } from '../components/layout/pages/shared/error/end-session-error/end-session-error.component';
import { PageNotFoundErrorComponent } from '../components/layout/pages/shared/error/page-not-found-error/page-not-found-error.component';
import { StateNotAllowedComponent } from '../components/layout/pages/shared/error/state-not-allowed/state-not-allowed.component';
import { TechnicalErrorComponent } from '../components/layout/pages/shared/error/technical-error/technical-error.component';
import { LobSelectionComponent } from '../components/layout/pages/shared/lob-selection/lob-selection.component';
import { PaymentFailedComponent } from '../components/layout/pages/shared/payment-failed/payment-failed.component';
import { PaymentResultComponent } from '../components/layout/pages/shared/payment-result/payment-result.component';
import { StepsEnum } from '../enums/steps.enum';
import { AuthGuardService as AuthGuard } from '../services/auth-guard.service';
import { MARKETING_NAME_MASK } from '../constants/source-mask-string';
import { LinkExpiredErrorComponent } from '../components/layout/pages/shared/error/link-expired-error/link-expired-error.component';
import { LinkSentComponent } from '../components/layout/pages/shared/error/link-sent/link-sent.component';

import { PrimaryDriverComponent } from 'src/app/components/layout/pages/auto/primary-driver/primary-driver.component';
import { AdditionalDriversComponent } from 'src/app/components/layout/pages/auto/drivers/additional-drivers.component';
import { VehiclesPageComponent } from '../components/layout/pages/auto/vehicles/vehicles.component';
import { AutoCoveragesComponent } from 'src/app/components/layout/pages/auto/auto-coverages/auto-coverages.component';
import { PolicyDatePickerComponent } from 'src/app/components/layout/pages/shared/policy-date-picker/policy-date-picker.component';
import { ResultComponent } from 'src/app/components/layout/pages/shared/result/result.component';
import { DriverHistoryComponent } from '../components/layout/pages/auto/driver-history/driver-history.component';
import { SafecoVehiclesComponent } from '../components/layout/pages/auto/additional-questions/components/safeco/safeco-vehicles/safeco-vehicles.component';
import { PaymentPlanComponent } from '../components/layout/pages/shared/payment-plan/payment-plan.component';
import { PaymentComponent } from '../components/layout/pages/shared/payment/payment.component';
import { SafecoMissingDriversComponent } from 'src/app/components/layout/pages/auto/additional-questions/components/safeco/safeco-missing-drivers/safeco-missing-drivers.component';
import { CarrierKickoutComponent } from '../components/layout/pages/shared/kickouts/carrier-kickout/carrier-kickout.component';
import { SafecoDriversComponent } from 'src/app/components/layout/pages/auto/additional-questions/components/safeco/safeco-drivers/safeco-drivers.component';
import { SafecoPolicyComponent } from 'src/app/components/layout/pages/auto/additional-questions/components/safeco/safeco-policy/safeco-policy.component';
import { CallAgentComponent } from '../components/layout/pages/shared/call-agent/call-agent.component';
import { AlmostThereComponent } from '../components/layout/pages/shared/almost-there/almost-there.component';

import { PersonalDetailsHomeownersComponent } from 'src/app/components/layout/pages/homeowners/personal-details/personal-details.component';
import { PrimaryResidenceComponent } from 'src/app/components/layout/pages/homeowners/primary-residence/primary-residence.component';
import { PropertiesHomeownersComponent } from 'src/app/components/layout/pages/homeowners/properties/properties.component';
import { PropertyUsageComponent } from 'src/app/components/layout/pages/homeowners/property-usage/property-usage.component';
import { RoofReplacementComponent } from 'src/app/components/layout/pages/homeowners/roof-replacement/roof-replacement.component';
import { YearBuiltComponent } from 'src/app/components/layout/pages/homeowners/year-built/year-built.component';
import { HouseDetailsComponent } from 'src/app/components/layout/pages/homeowners/house-details/house-details.component';
import { LemonadeComponent } from 'src/app/components/layout/pages/homeowners/additional-questions/components/lemonade/lemonade.component';
import { SafetyAlarmsPage } from 'src/app/components/layout/pages/shared/safety-alarms/safety-alarms.component';

import { HomesiteComponent } from 'src/app/components/layout/pages/renters/additional-questions/components/homesite/homesite.component';
import { StillwaterComponent } from 'src/app/components/layout/pages/renters/additional-questions/components/stillwater/stillwater.component';
import { PropertiesComponent } from 'src/app/components/layout/pages/renters/properties/properties.component';
import { PaymentDetailsComponent } from 'src/app/components/layout/pages/shared/payment-details/payment-details.component';
import { PersonalDetailsComponent } from 'src/app/components/layout/pages/renters/personal-details/personal-details.component';

import { PetsComponent } from '../components/layout/pages/pets/pets/pets.component';
import { PersonalDetailsPetsComponent } from '../components/layout/pages/pets/personal-details/personal-details.component';
import { NoRatesErrorComponent } from '../components/layout/pages/shared/error/no-rates-error/no-rates-error.component';
import { DisclosureComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/disclosure/disclosure.component';
import { ProgressiveSnapshotComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-snapshot/progressive-snapshot.component';
import { ProgressiveDriversComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-drivers/progressive-drivers.component';
import { ProgressiveMissingDriversComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-missing-drivers/progressive-missing-drivers.component';
import { ProgressiveAdditionalDriversComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-additional-drivers/progressive-additional-drivers.component';
import { ProgressiveAdditionalQuestionsComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-additional-questions/progressive-additional-questions.component';
import { ProgressiveVehiclesComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-vehicles/progressive-vehicles.component';
import { ProgressiveCoveragesComponent } from '../components/layout/pages/auto/additional-questions/components/progressive/progressive-coverages/progressive-coverages.component';
import { StillwaterPropertyInformationComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-property-information/stillwater-property-information.component';
import { StillwaterAdditionalPropertyInformationComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-additional-property-information/stillwater-additional-property-information.component';
import { StillwaterUtilitiesComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-utilities/stillwater-utilities.component';
import { StillwaterDetailsComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-details/stillwater-details.component';
import { FiskerDisclaimerComponent } from '../components/layout/pages/shared/fisker-disclaimer/fisker-disclaimer.component';
import { SuccessPageEscrowComponent } from '../components/layout/pages/shared/success-page-escrow/success-page-escrow.component';
import { ConfirmationEmailSentComponent } from '../components/layout/pages/shared/confirmation-email-sent/confirmation-email-sent.component';
import { LandingPageComponent } from '../components/layout/pages/shared/landing-page/landing-page.component';
import { RetrieveQuotePage } from '../components/layout/pages/shared/retrieve-quote/retrieve quote.component';
import { StillwaterPropertySafetyComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-property-safety/stillwater-property-safety.component';
import { StillwaterCarrierDisclosureComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-carrier-disclosure/stillwater-carrier-disclosure.component';
import { StillwaterCurrentInsuranceComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-current-insurance/stillwater-current-insurance.component';
import { StillwaterPropertyFeaturesComponent } from '../components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-property-features/stillwater-property-features.component';
import { QuoteConfirmationComponent } from '../components/layout/pages/shared/quote-confirmation/quote-confirmation.component';
import { NoConsumerFlowComponent } from '../components/layout/pages/shared/error/no-consumer-flow/no-consumer-flow.component';

let HOME_ROUTES: Routes;
let AUTO_ROUTES: Routes;

AUTO_ROUTES = [
	{
		path: StepsEnum.PRIMARY_DRIVER,
		component: PrimaryDriverComponent,
		data: {
			title: `page-titles.auto-routes.primary-driver`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.DRIVERS,
		component: AdditionalDriversComponent,
		data: {
			title: `page-titles.auto-routes.drivers`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.VEHICLES,
		component: VehiclesPageComponent,
		data: {
			title: `page-titles.auto-routes.vehicles`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.DRIVER_HISTORY,
		component: DriverHistoryComponent,
		data: {
			title: `page-titles.auto-routes.driver-history`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.AUTO_COVERAGES,
		component: AutoCoveragesComponent,
		data: {
			title: `page-titles.auto-routes.auto-coverages`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.POLICY_START_DATE,
		component: PolicyDatePickerComponent,
		data: {
			title: `page-titles.auto-routes.policy-start-date`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CONFIRMATION_EMAIL_SENT,
		component: ConfirmationEmailSentComponent,
		data: {
			title: 'page-titles.auto-routes.confirmation-email-sent',
		},
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_DRIVERS,
		component: SafecoDriversComponent,
		data: {
			title: `page-titles.auto-routes.additional-questions-safeco-drivers`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_VEHICLES,
		component: SafecoVehiclesComponent,
		data: {
			title: `page-titles.auto-routes.additional-questions-safeco-vehicles`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_MISSING_DRIVERS,
		component: SafecoMissingDriversComponent,
		data: {
			title: `page-titles.auto-routes.additional-questions-missing-drivers`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_POLICY,
		component: SafecoPolicyComponent,
		data: {
			title: `page-titles.auto-routes.additional-questions-safeco-policy`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_DISCLOSURE,
		component: DisclosureComponent,
		data: {
			title: `page-titles.auto-routes.progressive-disclosure`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_SNAPSHOT,
		component: ProgressiveSnapshotComponent,
		data: {
			title: `page-titles.auto-routes.progressive-snapshot`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_VEHICLES,
		component: ProgressiveVehiclesComponent,
		data: {
			title: `page-titles.auto-routes.progressive-vehicles`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_DRIVERS,
		component: ProgressiveDriversComponent,
		data: {
			title: `page-titles.auto-routes.progressive-drivers`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS_MISSING_DRIVERS,
		component: ProgressiveMissingDriversComponent,
		data: {
			title: `page-titles.auto-routes.progressive-additional-questions-missing-drivers`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS,
		component: ProgressiveAdditionalQuestionsComponent,
		data: {
			title: `page-titles.auto-routes.progressive-additional-questions`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_ADDITIONAL_DRIVERS,
		component: ProgressiveAdditionalDriversComponent,
		data: {
			title: `page-titles.auto-routes.progressive-additional-drivers`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROGRESSIVE_COVERAGES,
		component: ProgressiveCoveragesComponent,
		data: {
			title: `page-titles.auto-routes.progressive-coverages`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.RESULT,
		component: ResultComponent,
		data: {
			title: `page-titles.auto-routes.result`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CALL_AGENT,
		component: CallAgentComponent,
		data: {
			title: `page-titles.auto-routes.call-agent`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_PLAN,
		component: PaymentPlanComponent,
		data: {
			title: `page-titles.auto-routes.payment-plan`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT,
		component: PaymentComponent,
		data: {
			title: `page-titles.auto-routes.payment`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_ESCROW,
		// PAYMENT_ESCROW still uses PaymentComponent because the differences are quite minor
		component: PaymentComponent,
		data: {
			title: `page-titles.auto-routes.payment-escrow`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CARRIER_KICKOUT,
		component: CarrierKickoutComponent,
		data: {
			title: `page-titles.auto-routes.carrier-kickout`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ALMOST_THERE,
		component: AlmostThereComponent,
		data: {
			title: 'page-titles.auto-routes.almost-there',
		},
	},
];

HOME_ROUTES = [
	{
		path: StepsEnum.PROPERTIES,
		component: PropertiesHomeownersComponent,
		data: {
			title: `page-titles.home-routes.properties`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PRIMARY_RESIDENCE,
		component: PrimaryResidenceComponent,
		data: {
			title: `page-titles.home-routes.primary-residence`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PROPERTY_USAGE,
		component: PropertyUsageComponent,
		data: {
			title: `page-titles.home-routes.property-usage`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.HOUSE_DETAILS,
		component: HouseDetailsComponent,
		data: {
			title: `page-titles.home-routes.house-details`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ROOF_REPLACEMENT,
		component: RoofReplacementComponent,
		data: {
			title: `page-titles.home-routes.roof-replacement`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.YEAR_BUILT,
		component: YearBuiltComponent,
		data: {
			title: `page-titles.home-routes.year-built`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PERSONAL_DETAILS_HOMEOWNERS,
		component: PersonalDetailsHomeownersComponent,
		data: {
			title: `page-titles.home-routes.personal-details-homeowners`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PERSONAL_DETAILS_BUNDLE,
		component: PrimaryDriverComponent,
		data: {
			title: `page-titles.home-routes.personal-details-homeowners`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.POLICY_START_DATE,
		component: PolicyDatePickerComponent,
		data: {
			title: `page-titles.home-routes.policy-start-date`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CONFIRMATION_EMAIL_SENT,
		component: ConfirmationEmailSentComponent,
		data: {
			title: 'page-titles.home-routes.confirmation-email-sent',
		},
	},
	{
		path: StepsEnum.RESULT,
		component: ResultComponent,
		data: {
			title: `page-titles.home-routes.result`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CALL_AGENT,
		component: CallAgentComponent,
		data: {
			title: `page-titles.home-routes.call-agent`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_PLAN,
		component: PaymentPlanComponent,
		data: {
			title: `page-titles.home-routes.payment-plan`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT,
		component: PaymentComponent,
		data: {
			title: `page-titles.home-routes.payment`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_INFORMATION_STILLWATER_HOME,
		component: StillwaterPropertyInformationComponent,
		data: {
			title: `page-titles.home-routes.additional-questions-property-information-stillwater-home`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_INFORMATION_STILLWATER_HOME,
		component: StillwaterAdditionalPropertyInformationComponent,
		data: {
			title: `page-titles.home-routes.additional-questions-additional-property-information-stillwater-home`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_SAFETY_STILLWATER_HOME,
		component: StillwaterPropertySafetyComponent,
		data: {
			title: `page-titles.home-routes.additional-questions-property-safety-stillwater-home`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_FEATURES_STILLWATER_HOME,
		component: StillwaterPropertyFeaturesComponent,
		data: {
			title: `page-titles.home-routes.additional-questions-property-features-stillwater-home`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_FEATURES_STILLWATER_HOME,
		component: StillwaterDetailsComponent,
		data: {
			title: `page-titles.home-routes.additional-questions-additional-property-features-stillwater-home`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_UTILITIES_STILLWATER_HOME,
		component: StillwaterUtilitiesComponent,
		data: {
			title: 'page-titles.home-routes.additional-questions-utilities-stillwater-home',
		},
		canActivate: [AuthGuard],
	},

	{
		path: StepsEnum.CURRENT_INSURANCE_STILLWATER,
		component: StillwaterCurrentInsuranceComponent,
		data: {
			title: 'page-titles.home-routes.current-insurance-stillwater',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CARRIER_DISCLOSURE_STILLWATER,
		component: StillwaterCarrierDisclosureComponent,
		data: {
			title: 'page-titles.home-routes.carrier-disclosure-stillwater',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_LEMONADE,
		component: LemonadeComponent,
		data: {
			title: 'page-titles.home-routes.additional-questions-lemonade',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.SAFETY_ALARMS,
		component: SafetyAlarmsPage,
		data: {
			title: 'page-titles.home-routes.safety-alarms',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.QUOTE_CONFIRMATION,
		component: QuoteConfirmationComponent,
		data: {
			title: 'page-titles.home-routes.quote-confirmation',
		},
		canActivate: [AuthGuard],
	},
];

const RENTERS_ROUTES: Routes = [
	{
		path: StepsEnum.PROPERTIES,
		component: PropertiesComponent,
		data: {
			title: `page-titles.renters-routes.properties`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PERSONAL_DETAILS,
		component: PersonalDetailsComponent,
		data: {
			title: `page-titles.renters-routes.personal-details`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ABOUT_YOUR_HOUSE,
		component: PropertiesComponent,
		data: {
			title: `page-titles.renters-routes.about-your-house`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.RESULT,
		component: ResultComponent,
		data: {
			title: `page-titles.renters-routes.result`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CALL_AGENT,
		component: CallAgentComponent,
		data: {
			title: `page-titles.renters-routes.call-agent`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_PLAN,
		component: PaymentPlanComponent,
		data: {
			title: `page-titles.renters-routes.payment-plan`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT,
		component: PaymentComponent,
		data: {
			title: `page-titles.renters-routes.payment`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_DETAILS,
		component: PaymentDetailsComponent,
		data: {
			title: `page-titles.renters-routes.payment-details`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.HOUSE_DETAILS,
		component: HouseDetailsComponent,
		data: {
			title: `page-titles.renters-routes.house-details`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.POLICY_DATEPICKER,
		component: PolicyDatePickerComponent,
		data: {
			title: `page-titles.renters-routes.policy-datepicker`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CONFIRMATION_EMAIL_SENT,
		component: ConfirmationEmailSentComponent,
		data: {
			title: 'page-titles.renters-routes.confirmation-email-sent',
		},
	},
	{
		path: StepsEnum.PAYMENT_PLAN,
		component: PaymentPlanComponent,
		data: {
			title: `Select payment plan`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_HOMESITE,
		component: HomesiteComponent,
		data: {
			title: `page-titles.renters-routes.additional-questions-homesite`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER,
		component: StillwaterComponent,
		data: {
			title: `page-titles.renters-routes.additional-questions-stillwater`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER_SECOND_PAGE,
		component: SafetyAlarmsPage,
		data: {
			title: `page-titles.renters-routes.additional-questions-stillwater-second-page`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CARRIER_KICKOUT,
		component: CarrierKickoutComponent,
		data: {
			title: `page-titles.renters-routes.carrier-kickout`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.QUOTE_CONFIRMATION,
		component: QuoteConfirmationComponent,
		data: {
			title: `page-titles.renters-routes.quote-confirmation`,
		},
		canActivate: [AuthGuard],
	},
	{
		path: 'renters',
		pathMatch: 'full',
		redirectTo: StepsEnum.PROPERTIES,
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: StepsEnum.PROPERTIES,
	},
];

const HOME_AUTO_ROUTES: Routes = [
	...HOME_ROUTES,
	{
		path: StepsEnum.ALMOST_THERE,
		component: AlmostThereComponent,
		data: {
			title: 'page-titles.home-routes.almost-there',
		},
	},
	...AUTO_ROUTES,
];

const PETS_ROUTES = [
	{
		path: StepsEnum.PET_DETAILS,
		component: PetsComponent,
		data: {
			title: 'page-titles.pets-routes.pet-details',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PERSONAL_DETAILS,
		component: PersonalDetailsPetsComponent,
		data: {
			title: 'page-titles.pets-routes.personal-information',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.RESULT,
		component: ResultComponent,
		data: {
			title: 'page-titles.pets-routes.quote-rates',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CALL_AGENT,
		component: CallAgentComponent,
		data: {
			title: 'page-titles.pets-routes.call-an-agent',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_PLAN,
		component: PaymentPlanComponent,
		data: {
			title: 'page-titles.pets-routes.select-payment-plan',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT,
		component: PaymentComponent,
		data: {
			title: 'page-titles.pets-routes.payment',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.PAYMENT_DETAILS,
		component: PaymentDetailsComponent,
		data: {
			title: 'page-titles.pets-routes.payment-details',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.POLICY_DATEPICKER,
		component: PolicyDatePickerComponent,
		data: {
			title: 'page-titles.pets-routes.pick-a-date',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CONFIRMATION_EMAIL_SENT,
		component: ConfirmationEmailSentComponent,
		data: {
			title: 'page-titles.pets-routes.confirmation-email-sent',
		},
	},
	{
		path: StepsEnum.PAYMENT_PLAN,
		component: PaymentPlanComponent,
		data: {
			title: 'page-titles.pets-routes.select-payment-plan',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.CARRIER_KICKOUT,
		component: CarrierKickoutComponent,
		data: {
			title: 'page-titles.pets-routes.session-ended',
		},
		canActivate: [AuthGuard],
	},
	{
		path: StepsEnum.QUOTE_CONFIRMATION,
		component: QuoteConfirmationComponent,
		data: {
			title: 'page-titles.pets-routes.quote-confirmation',
		},
		canActivate: [AuthGuard],
	},
	{
		path: 'pets',
		pathMatch: 'full',
		redirectTo: StepsEnum.PROPERTIES,
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: StepsEnum.PROPERTIES,
	},
];
AUTO_ROUTES.push(...HOME_ROUTES);
HOME_ROUTES.push(...AUTO_ROUTES);

export const routes: Routes = [
	{
		path: StepsEnum.PAGE_NOT_FOUND,
		component: PageNotFoundErrorComponent,
		data: {
			title: 'page-titles.routes.page-not-found',
		},
	},
	{
		path: StepsEnum.LINK_EXPIRED,
		component: LinkExpiredErrorComponent,
		data: {
			title: 'page-titles.routes.link-expired',
		},
	},
	{
		path: StepsEnum.CONFIRMATION_EMAIL_SENT,
		component: ConfirmationEmailSentComponent,
		data: {
			title: 'page-titles.routes.confirmation-email-sent',
		},
	},
	{
		path: 'error',
		children: [
			{
				path: StepsEnum.TECHNICAL_ERROR,
				component: TechnicalErrorComponent,
				data: {
					title: 'page-titles.routes.session-ended',
				},
			},
			{
				path: StepsEnum.NO_CONSUMER_FLOW,
				component: NoConsumerFlowComponent,
				data: {
					title: 'page-titles.routes.no-consumer-flow-found',
				},
			},
		],
	},
	{
		path: ':sourceid',
		pathMatch: 'full',
		redirectTo: ':sourceid/your-address',
	},
	{
		path: ':sourceid',
		children: [
			{
				path: 'homeowners',
				children: HOME_ROUTES,
			},
			{
				path: 'renters',
				children: RENTERS_ROUTES,
			},
			{
				path: 'auto',
				children: AUTO_ROUTES,
			},
			{
				path: 'homeowners-auto',
				children: HOME_AUTO_ROUTES,
			},
			{
				path: 'pets',
				children: PETS_ROUTES,
			},
			{
				path: StepsEnum.YOUR_ADDRESS,
				pathMatch: 'full',
				component: LandingPageComponent,
				canActivate: [AuthGuard],
				data: {
					title: `${MARKETING_NAME_MASK}`,
				},
			},
			{
				path: StepsEnum.RETRIEVE_QUOTE,
				pathMatch: 'full',
				component: RetrieveQuotePage,
				data: {
					title: 'page-titles.routes.retrieve-quote',
				},
			},

			{
				path: StepsEnum.LOB_SELECTION,
				pathMatch: 'full',
				component: LobSelectionComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'page-titles.routes.lob-selection',
				},
			},
			{
				path: StepsEnum.FISKER_DISCLAIMER,
				pathMatch: 'full',
				component: FiskerDisclaimerComponent,
				data: {
					title: 'page-titles.routes.disclaimer',
				},
			},
			{
				path: 'error',
				children: [
					{
						path: StepsEnum.TECHNICAL_ERROR,
						component: TechnicalErrorComponent,
						data: {
							title: 'page-titles.routes.session-ended',
						},
					},
					{
						path: StepsEnum.STATE_NOT_ALLOWED,
						component: StateNotAllowedComponent,
						data: {
							title: 'page-titles.routes.session-ended',
						},
					},
					{
						path: StepsEnum.END_SESSION,
						component: EndSessionErrorComponent,
						data: {
							title: 'page-titles.routes.session-ended',
						},
					},
					{
						path: StepsEnum.LINK_EXPIRED,
						component: LinkExpiredErrorComponent,
						data: {
							title: 'page-titles.routes.link-expired',
						},
					},
					{
						path: StepsEnum.LINK_SENT,
						component: LinkSentComponent,
						data: {
							title: 'page-titles.routes.link-sent',
						},
					},
					{
						path: StepsEnum.NO_RESULTS,
						component: NoRatesErrorComponent,
						data: {
							title: 'page-titles.routes.no-results',
						},
					},
					{
						path: StepsEnum.CARRIER_KICKOUT,
						component: CarrierKickoutComponent,
						data: {
							imageName: 'noRates',
							hideButtons: true,
							title: 'page-titles.routes.no-results',
						},
					},
				],
			},
			{
				path: StepsEnum.SUCCESS_PAGE,
				component: PaymentResultComponent,
				data: { status: 'success', title: 'page-titles.routes.thank-you-for-your-purchase' },
			},
			{
				path: StepsEnum.PAYMENT_FAILED,
				component: PaymentFailedComponent,
				data: { status: 'failed', title: 'page-titles.routes.payment-failed' },
			},

			{
				path: StepsEnum.SUCCESS_PAGE_ESCROW,
				component: SuccessPageEscrowComponent,
				data: { status: 'success', title: 'page-titles.routes.thank-you-for-your-purchase' },
			},
		],
	},
	{ path: '**', redirectTo: StepsEnum.PAGE_NOT_FOUND, pathMatch: 'full' },
];
