<button
	(click)="onClick()"
	[attr.aria-label]="title"
	[attr.disabled]="disabled ? true : null"
	class="app-button {{ severity }}"
	role="button"
	type="button"
>
	<span class="title">
		<span *ngIf="title">{{ title }}</span>
		<ng-container *ngIf="!title">
			<span [appTranslation]="titleKey" [masks]="masks"></span>
		</ng-container>

		<i
			*ngIf="materialIcon !== 'none'"
			appHiddenWhenCssDisabled
			aria-hidden="true"
			class="material-icons"
			role="presentation"
			>{{ materialIcon }}</i
		>
	</span>
</button>
