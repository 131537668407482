import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonSeverity = 'primary' | 'secondary' | 'bordered';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
	@Input() title: string;
	@Input() titleKey: string;
	@Input() masks: any;
	@Input() disabled: boolean;
	@Input() materialIcon = 'chevron_right';
	@Input() severity: ButtonSeverity = 'primary';

	@Output() clicked: EventEmitter<any> = new EventEmitter<any>();

	onClick() {
		this.clicked.emit();
	}
}
