import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BasePageComponent } from 'src/app/components/layout/pages/base-page.component';
import { IAppState } from 'src/app/store/states/app.state';

@Component({
	selector: 'app-stillwater-carrier-disclosure',
	templateUrl: './stillwater-carrier-disclosure.component.html',
	styleUrls: ['./stillwater-carrier-disclosure.component.scss'],
})
export class StillwaterCarrierDisclosureComponent extends BasePageComponent implements OnInit {
	isStepFilled: boolean;

	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.formBuilder.group({
			[this.Fields.InspectionNotification.name]: [null, [Validators.required, Validators.requiredTrue]],
		});

		super.patchData(this.form);
	}
}
