export enum ControlsType {
	address = 'address',
	checkbox = 'checkbox',
	datepicker = 'datepicker',
	dropdown = 'dropdown',
	maskedInput = 'masked-input',
	multiDropdown = 'multi-dropdown',
	numberStepper = 'number-stepper',
	segmentedControls = 'segmented-controls',
	slideToggle = 'slide-toggle',
	unmaskedInput = 'unmasked-input',
}
