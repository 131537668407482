export enum GeneralPropsEnum {
	name = 'name',
	agentPhoneNumber = 'agentPhoneNumber',
	agentPhoneNumberVisibility = 'agentPhoneNumberVisibility',
	properties = 'properties',
	repeatedTexts = 'repeatedTexts',
	fonts = 'fonts',
	images = 'images',
	texts = 'texts',
	leadSourceId = 'leadSourceId',
	marketingName = 'marketingName',
	paymentRedirect = 'paymentRedirect',
	logoUrl = 'logoURL',
	additionalClasses = 'additionalClasses',
	themeSettings = 'themeSettings',
	settings = 'settings',
	title = 'title',
	enabled = 'enabled',
	type = 'type',
	landingPage = 'landingPage',
	nav = 'nav',
	links = 'links',
	imagesList = 'imagesList',
}
