import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../../../models/event-data.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isMobile } from 'src/app/utils/general.utils';
import { selectActiveTheme } from '../../../../../../store/selectors/active-theme.selector';
import { GeneralPropsEnum } from '../../../../../../models/general-props.enum';
import { StepsEnum } from '../../../../../../enums/steps.enum';
import { EventsService } from 'src/app/services/events.service';

@UntilDestroy()
@Component({
	templateUrl: './technical-error.component.html',
})
export class TechnicalErrorComponent implements OnInit {
	EventActions = EventActions;
	title: string;
	subtitle: string;
	agentPhoneNumber: string;
	isMobile: boolean;
	callAgentButtonOn: any;
	backButtonOn: any;
	callAgentButtonUrl: string;
	backButtonUrl: string;

	constructor(private readonly store: Store, private readonly routingService: RoutingService, private eventsService: EventsService) { }

	ngOnInit(): void {
		this.isMobile = isMobile();

		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe((theme) => {
				this.title = theme[GeneralPropsEnum.texts][StepsEnum.TECHNICAL_ERROR].title;
				this.subtitle = theme[GeneralPropsEnum.texts][StepsEnum.TECHNICAL_ERROR].subTitle;
				this.callAgentButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.TECHNICAL_ERROR].callAgentButtonOn;
				this.callAgentButtonUrl = theme[GeneralPropsEnum.texts][StepsEnum.TECHNICAL_ERROR].callAgentButtonUrl;
				this.backButtonOn = theme[GeneralPropsEnum.texts][StepsEnum.TECHNICAL_ERROR].backButtonOn;
				this.backButtonUrl = theme[GeneralPropsEnum.texts][StepsEnum.TECHNICAL_ERROR].backButtonUrl;
			});
	}

	callAgent(): void {
		if (this.callAgentButtonUrl) {
			this.routingService.navigateToExternal(this.callAgentButtonUrl, true);
		} else {
			this.routingService.callTo(this.agentPhoneNumber);
		}
	}

	goHome(): void {
		if (this.backButtonUrl) {
			this.routingService.navigateToExternal(this.backButtonUrl, true);
		} else {
			this.routingService.navigateToHomePage();
		}
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'go-home', button: 'go-home', module: 'technical-error-module' });
	}

}
