import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IAppState } from '../../../store/states/app.state';
import { ActionApiService } from '../../../services/action-api.service';
import { selectActiveTheme } from '../../../store/selectors/active-theme.selector';
import { LandingSectionModel } from '../../../models/landing-section.model';
import { GeneralPropsEnum } from '../../../models/general-props.enum';

@UntilDestroy()
@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
	@Input() name: string;
	@Input() alt: string;
	@Input() width: string;
	@Input() height: string;
	@Input() landingSection: LandingSectionModel;
	@Input() role = 'presentation';
	@Input() classNames: string;

	src: string;

	constructor(private store: Store<IAppState>, private actionApiService: ActionApiService) {}

	ngOnInit(): void {
		this.store
			.select(selectActiveTheme)
			.pipe(untilDestroyed(this))
			.subscribe(({ name, images }) => {
				const fileName = this.landingSection
					? this.landingSection?.[GeneralPropsEnum.images][this.name]
					: images?.[this.name];

				if (fileName && !!name) {
					this.src = this.actionApiService.getFileSrc(fileName, name);
				}
			});
	}
}
