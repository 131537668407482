import { Injectable } from '@angular/core';
import { selectProgressMeter } from '../store/selectors/progress-meter.selector';
import { first } from 'rxjs';
import { carrierSelect, carrierSelectedForOfflineBid } from '../store/actions/result-data.actions';
import { hidePopupAction, showPopupAction } from '../store/actions/popup.actions';
import { Store } from '@ngrx/store';
import { ProgressMeterService } from './progress-meter.service';
import { IQuoteBridgeUrl } from '../../entities/payload.interface';
import { ActionApiService } from './action-api.service';
import { RoutingService } from './routing.service';
import { ConsumerBindTypeEnum } from '../enums/consumer-bind-type.enum';
import { selectInterviewMetadata } from '../store/selectors/interview-metadata.selector';
import { CoverageModel } from '../models/coverage.model';
import { MergeQuoteData } from '../store/actions/quote-data.actions';

@Injectable({ providedIn: 'root' })
export class BuyCoverageButtonService {
	constructor(
		private store: Store,
		private actionApi: ActionApiService,
		private progressMeterService: ProgressMeterService,
		private routingService: RoutingService
	) {}

	public buyCoverageButtonClicked(rates: CoverageModel[], route: string) {
		if (rates[0].consumerBindType === ConsumerBindTypeEnum.BRIDGE_TO_CARRIER && route !== 'call-agent') {
			// consumerBindType: BridgeToCarrier
			return this.redirectToBridgeToCarrierFlow(rates[0]);
		} else if (route) {
			this.store.dispatch(carrierSelectedForOfflineBid({ rates }));

			if (route === 'call-agent') {
				// consumerBindType: CallAgent
				this.store
					.select(selectProgressMeter)
					.pipe(first())
					.subscribe((res) => {
						this.store.dispatch(
							carrierSelect({ rateParamsArray: rates.map((rate) => ({ carrier: rate.carrier, rateID: rate.id })) })
						);
						this.routingService.navigateToRoute(this.progressMeterService.getRouteName(res.flowType) + '/' + route);
					});
			} else {
				// consumerBindType: API
				// get the progress steps and update it, each carrier have different flow
				this.moveToAPIBindFlow(rates[0]);
			}
		}
	}

	openAgreementPopup(rate) {
		this.store.dispatch(hidePopupAction());
		this.store.dispatch(carrierSelect({ rateParamsArray: [{ carrier: rate.carrier, rateID: rate.id }] }));
		this.store.dispatch(
			showPopupAction({
				componentName: 'homesite-agreement',
				payload: {
					text: 'popups.titles.homesite-agreement',
					rate,
					gtmLabel: 'ContactAgentPopUp',
				},
			})
		);
	}

	private redirectToBridgeToCarrierFlow(rate) {
		this.store.dispatch(carrierSelect({ rateParamsArray: [{ carrier: rate.carrier, rateID: rate.id }] }));
		const bridgeUrl$ = this.actionApi.post({ action: 'api/bridgeUrl', endpoint: `quotes`, quoteId: rate.id });
		bridgeUrl$.pipe(first()).subscribe((res: IQuoteBridgeUrl) => {
			if (res.success) {
				this.routingService.navigateToExternal(res.value, true);
			} else {
				this.openBridgeErrorPopup();
			}
		});
		return bridgeUrl$;
	}

	private moveToAPIBindFlow(rate) {
		if (rate.carrier === 'Homesite') {
			this.openAgreementPopup(rate);
			return;
		}

		this.store.dispatch(carrierSelect({ rateParamsArray: [{ carrier: rate.carrier, rateID: rate.id }] }));
		setTimeout(() => {
			this.store
				.select(selectInterviewMetadata)
				.pipe(first())
				.subscribe((res) => {
					// clear FQ defaults
					this.actionApi.clearQQDefaults({ carrier: rate.carrier, product: rate.lob }).subscribe((res) => {
						if (res) {
							this.store.dispatch(MergeQuoteData({ data: res, deleteOnEmpty: false }));
						}
						this.progressMeterService.goToNextPage();
					});
				});
		});
	}

	private openBridgeErrorPopup() {
		this.store.dispatch(hidePopupAction());
		this.store.dispatch(
			showPopupAction({
				componentName: 'alert',
				payload: {
					text: '',
					subtext: 'popups.subtitles.bridge-error',
				},
			})
		);
	}
}
