import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-non-oem-selection',
	templateUrl: './non-oem-selection.component.html',
	styleUrls: ['./non-oem-selection.component.scss'],
})
export class NonOemSelectionComponent {
	@Input() data: any;

	constructor() {}
}
