import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { inOutAnimation } from 'src/app/animations';
import { BasePageComponent } from 'src/app/components/layout/pages/base-page.component';
import {
	DriverStateLicensed,
	getNumberOptions,
	personalLossesDescriptionOptions,
} from 'src/app/constants/field-options';
import { IAppState } from 'src/app/store/states/app.state';
import { Validations } from 'src/app/utils/validation';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'app-stillwater-current-insurance',
	templateUrl: './stillwater-current-insurance.component.html',
	styleUrls: ['./stillwater-current-insurance.component.scss', '../stillwater.component.scss'],
	animations: [inOutAnimation],
})
export class StillwaterCurrentInsuranceComponent extends BasePageComponent implements OnInit {
	isStepFilled: boolean;
	lossSequenceNumber: number = 0;
	isPropertyAddressInFlorida: boolean;
	showManualFields = false;
	vertical = true;
	statesOptions = DriverStateLicensed;

	mortgageesOptions = getNumberOptions(0, 5);
	lossesOptions = personalLossesDescriptionOptions;
	lossFieldNames = [this.Fields.LossDate.name, this.Fields.LossPaidAmt.name, this.Fields.LossDescription.name];

	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	get losses(): FormArray {
		return this.form.controls[this.Fields.SW_PersonalLosses.name] as FormArray;
	}

	get today(): Date {
		return new Date();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.isPropertyAddressInFlorida = this.quoteData.PropertyAddress.State.toLowerCase() === 'fl';

		this.buildForm();

		super.registerOnChange(this.form);

		this.form.controls[this.Fields.HaveAnyLosses.name].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value) {
				if (this.getActiveLosses(this.losses).length === 0) {
					this.addLoss();
				}
			} else {
				this.removeAllLosses();
			}
		});

		super.patchDataArray(this.form, this.Fields.SW_PersonalLosses.name);
		super.patchData(this.form);

		this.handleOptionalControls(this.Fields.SW_NumberOfMortgagees.name, [
			this.Fields.MortgageCompanyAddress.name,
			this.Fields.MortgageAccountNumber.name,
			this.Fields.SW_MortgageCompanyName.name,
		]);

		this.handleOptionalControls(this.Fields.SW_AnyAdditionalInsured.name, [
			this.Fields.CoFirstName.name,
			this.Fields.CoMiddleName.name,
			this.Fields.CoLastName.name,
			this.Fields.CoDateOfBirth.name,
		]);

		if (
			this.form.controls[this.Fields.SW_PersonalLosses.name] &&
			this.form.controls[this.Fields.SW_PersonalLosses.name].value &&
			this.form.controls[this.Fields.SW_PersonalLosses.name].value.length
		) {
			this.lossSequenceNumber = this.form.controls[this.Fields.SW_PersonalLosses.name].value.length;
		}
	}

	getActiveLosses(losses: FormArray): AbstractControl[] {
		return losses.controls.filter((lose) => lose.value.active);
	}

	addLoss(): void {
		if (this.getActiveLosses(this.losses).length > 4) {
			this.updateValidationOfAllLossesForms();
			return;
		}

		const id = uuidv4();
		const lossItem = this.formBuilder.group({
			[this.Fields.Id.name]: id,
			[this.Fields.SequenceNum.name]: this.lossSequenceNumber++,
			[this.Fields.LossDate.name]: [''],
			[this.Fields.LossPaidAmt.name]: [null],
			[this.Fields.LossDescription.name]: [null],
			active: true,
		});

		lossItem.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			if (value.active) {
				if (value.DateOfLoss || value.PersonalLineLossAmount || value.PLLossDescription) {
					this.makeLossFieldsRequired(lossItem);
				} else {
					this.makeLossFieldsNotRequired(lossItem);
				}

				this.updateValidationOfAllLossesForms();
			}
		});

		this.losses.push(lossItem);

		this.updateValidationOfAllLossesForms();
	}

	removeLoss(id: string): void {
		const index = this.losses.controls.findIndex((item) => item.value.Id === id);

		this.losses.controls[index].patchValue({
			[this.Fields.LossDate.name]: null,
			[this.Fields.LossDescription.name]: null,
			[this.Fields.LossPaidAmt.name]: null,
			[this.Fields.SequenceNum.name]: -1,
			active: false,
		});

		this.updateValidationOfAllLossesForms();
	}

	private removeAllLosses(): void {
		this.losses.controls.forEach((item) => {
			this.removeLoss(item.value.Id);
		});
		this.lossSequenceNumber = 0;
	}

	private updateValidationOfAllLossesForms(): void {
		const filledLosses = this.losses.controls.filter(
			(item) => item.value.DateOfLoss || item.value.PersonalLineLossAmount || item.value.PLLossDescription
		);

		const emptyLosses = this.losses.controls.filter(
			(item) => !item.value.DateOfLoss && !item.value.PersonalLineLossAmount && !item.value.PLLossDescription
		);

		filledLosses.forEach((item) => {
			this.makeLossFieldsRequired(item as FormGroup);
		});

		emptyLosses.forEach((item) => {
			this.makeLossFieldsNotRequired(item as FormGroup);
		});
	}

	private makeLossFieldsRequired(lossItem: FormGroup): void {
		this.lossFieldNames.forEach((fieldName) => {
			lossItem.controls[fieldName].setValidators(Validators.required);
		});
		this.updateLossFieldsValidity(lossItem);
	}

	private makeLossFieldsNotRequired(lossItem: FormGroup): void {
		this.lossFieldNames.forEach((fieldName) => {
			lossItem.controls[fieldName].clearValidators();
			lossItem.controls[fieldName].removeValidators([]);
			lossItem.controls[fieldName].setErrors(null);
		});
		this.updateLossFieldsValidity(lossItem);
	}

	private updateLossFieldsValidity(lossItem: FormGroup): void {
		this.lossFieldNames.forEach((fieldName) => {
			lossItem.controls[fieldName].updateValueAndValidity({ emitEvent: false });
		});
	}

	private buildForm(): void {
		this.form = this.formBuilder.group({
			[this.Fields.HaveAnyLosses.name]: [null, [Validators.required]],
			[this.Fields.SW_AnyAdditionalInsured.name]: [null, [Validators.required]],
			[this.Fields.SW_AnimalLiabilityCoverage.name]: [null, [Validators.required]],
			[this.Fields.SW_NumberOfMortgagees.name]: [0, [Validators.required]],
			[this.Fields.SW_MortgageCompanyName.name]: [null, [Validators.required]],
			[this.Fields.MortgageAccountNumber.name]: [null, [Validators.required]],
			[this.Fields.MortgageCompanyAddress.name]: this.formBuilder.group({
				AddressLine1: [null, [Validators.required, Validators.maxLength(50)]],
				AddressLine2: [null, [Validators.maxLength(50)]],
				City: [null, [Validators.required, Validators.maxLength(25)]],
				State: [null, [Validators.required, Validators.maxLength(2)]],
				ZipCode: [null, [Validators.required, Validations.zipStrictLength(5)]],
			}),
			[this.Fields.CoFirstName.name]: [
				null,
				[Validators.required, Validators.maxLength(50), Validations.firstNameOneChar, Validations.alphabet],
			],
			[this.Fields.CoMiddleName.name]: [null, [Validators.maxLength(50), Validations.alphabet]],
			[this.Fields.CoLastName.name]: [
				null,
				[Validators.required, Validators.maxLength(50), Validations.firstNameOneChar, Validations.alphabet],
			],
			[this.Fields.CoDateOfBirth.name]: [
				null,
				[
					Validators.required,
					Validations.isDOBpastDate,
					Validations.dateFormat,
					Validations.isMoreThen18,
					Validations.isEarlierThan1900,
				],
			],
			[this.Fields.SW_PersonalLosses.name]: new FormArray([]),
		});
	}

	private handleOptionalControls(dependentControlName: string, optionalControlNames: string[]): void {
		const dependentControl = this.form.controls[dependentControlName];
		const optionalControls: AbstractControl[] = optionalControlNames.map((controlName) => this.form.get(controlName));

		const updateOptionalControls = (value: any) => {
			const shouldDisable = !value || Number(value) === 0;
			optionalControls.forEach((control) => {
				if (shouldDisable) {
					control.disable();
				} else {
					control.enable();
				}
			});
		};

		updateOptionalControls(dependentControl.value);

		dependentControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			updateOptionalControls(value);
		});
	}
}
