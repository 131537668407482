<section class="main-content">
	<app-page-heading [title]="'titles.stillwaterCurrentInsurance' | translate"></app-page-heading>

	<ng-container *ngIf="form">
		<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper stillwater-fq">
			<app-question-wrapper *ngIf="form.controls[Fields.HaveAnyLosses.name]?.enabled" class="question-container">
				<label [attr.id]="Fields.HaveAnyLosses.name" class="input-label">
					{{ "labels.anyHomeownerPolicyClaims" | translate }}
				</label>
				<app-yes-no [name]="Fields.HaveAnyLosses.name" [vertical]="vertical"></app-yes-no>
			</app-question-wrapper>

			<ng-container *ngIf="!!form.controls[Fields.HaveAnyLosses.name].value">
				<div *ngIf="losses" [@inOutAnimation] class="child-container">
					<ng-container *ngFor="let lossItem of getActiveLosses(losses); let i = index">
						<ng-container [formGroup]="lossItem">
							<div class="claim-title">
								<span>{{ "labels.claim" | translate }} {{ i + 1 }}</span>

								<button
									(click)="removeLoss(lossItem.value.Id)"
									*ngIf="getActiveLosses(losses).length > 1"
									[attr.aria-label]="'buttons.remove' | translate"
									class="remove-loss"
								>
									<i appHiddenWhenCssDisabled class="material-icons" role="presentation">delete_outline</i>
								</button>
							</div>

							<app-question-wrapper>
								<label [for]="Fields.LossDescription.name" class="input-label">
									{{ "labels.claimDescription" | translate }}
								</label>
								<app-dropdown
									[labelInsideField]="'labels.pleaseSelect' | translate"
									[name]="Fields.LossDescription.name"
									[options]="lossesOptions"
									[reproducibleIndex]="i"
									notShowLabelWhenSelected="true"
								>
								</app-dropdown>
							</app-question-wrapper>

							<app-question-wrapper>
								<label [for]="Fields.LossDate.name" class="input-label">{{
									"labels.whenDitItHappened" | translate
								}}</label>
								<app-datepicker
									[labelInsideField]="'labels.pleaseEnter' | translate"
									[maxDate]="today"
									[name]="Fields.LossDate.name"
									[reproducibleIndex]="i"
									[theControl]="lossItem"
									format="YYYY-MM-DD"
									icon="calendar_today"
									notshowLabelWhenFilled="true"
								></app-datepicker>
							</app-question-wrapper>

							<app-question-wrapper>
								<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
									<label [for]="Fields.LossPaidAmt.name" class="input-label">
										{{ "labels.plLossAmountStillwater" | translate }}
									</label>
									<unmasked-input
										[isCurrency]="true"
										[name]="Fields.LossPaidAmt.name"
										[pattern]="'^[0-9]*$'"
										[placeholder]="'labels.pleaseEnter' | translate"
										[reproducibleIndex]="i"
										maxlength="50"
										type="text"
										notshowLabelWhenFilled="true"
									></unmasked-input>
								</div>
							</app-question-wrapper>
						</ng-container>

						<div class="actions-wrapper">
							<button
								(click)="addLoss()"
								*ngIf="i === getActiveLosses(losses).length - 1 && getActiveLosses(losses).length !== 4"
								class="add-loss"
								type="button"
							>
								<span>{{ "buttons.addAnotherOne" | translate }}</span>
							</button>
						</div>

						<mat-divider></mat-divider>
					</ng-container>
				</div>
			</ng-container>

			<app-question-wrapper class="question-container">
				<label
					[attr.id]="Fields.SW_AnyAdditionalInsured.name"
					[for]="Fields.SW_AnyAdditionalInsured.name"
					class="input-label"
				>
					{{ "labels.anyAdditionalInsured" | translate }}
				</label>
				<app-yes-no [name]="Fields.SW_AnyAdditionalInsured.name" [vertical]="vertical"></app-yes-no>
			</app-question-wrapper>
			<div *ngIf="!!form.controls[Fields.SW_AnyAdditionalInsured.name].value" [@inOutAnimation] class="child-container">
				<div class="one-row">
					<app-question-wrapper>
						<label [for]="Fields.CoFirstName.name" class="input-label">
							{{ "personalInfo.firstName" | translate }}
						</label>
						<unmasked-input
							[name]="Fields.CoFirstName.name"
							[pattern]="Fields.CoFirstName.pattern"
							[placeholder]="'labels.pleaseEnter' | translate"
							maxlength="50"
							type="text"
							notshowLabelWhenFilled="true"
						>
						</unmasked-input>
					</app-question-wrapper>

					<app-question-wrapper>
						<label [for]="Fields.CoMiddleName.name" class="input-label">
							{{ "personalInfo.middleName" | translate }}
						</label>
						<unmasked-input
							[name]="Fields.CoMiddleName.name"
							[pattern]="Fields.CoMiddleName.pattern"
							[placeholder]="'labels.pleaseEnter' | translate"
							maxlength="50"
							type="text"
							notshowLabelWhenFilled="true"
						>
						</unmasked-input>
					</app-question-wrapper>
				</div>

				<app-question-wrapper>
					<label [for]="Fields.CoLastName.name" class="input-label">
						{{ "personalInfo.lastName" | translate }}
					</label>
					<unmasked-input
						[name]="Fields.CoLastName.name"
						[pattern]="Fields.CoLastName.pattern"
						[placeholder]="'labels.pleaseEnter' | translate"
						maxlength="50"
						type="text"
						notshowLabelWhenFilled="true"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper>
					<label [for]="Fields.CoDateOfBirth.name" class="input-label">
						{{ "personalInfo.dateOfBirth" | translate }}
					</label>

					<masked-input
						[inputMask]="'**/**/9999'"
						[name]="Fields.CoDateOfBirth.name"
						[placeholder]="'labels.pleaseEnter' | translate"
						class="date-input"
						notshowLabelWhenFilled="true"
					>
					</masked-input>
				</app-question-wrapper>

				<mat-divider></mat-divider>
			</div>

			<app-question-wrapper class="question-container">
				<div class="animal-liability">
					<label [for]="Fields.SW_AnimalLiabilityCoverage.name" class="input-label">
						{{
							(isPropertyAddressInFlorida
								? "labels.includeAnimalLiabilityCoverageFl"
								: "labels.includeAnimalLiabilityCoverage"
							) | translate
						}}
					</label>
					<button
						[attr.aria-label]="
							(isPropertyAddressInFlorida
								? 'labels.includeAnimalLiabilityCoverageTooltipFl'
								: 'labels.includeAnimalLiabilityCoverageTooltip'
							) | translate
						"
						[matTooltip]="
							(isPropertyAddressInFlorida
								? 'labels.includeAnimalLiabilityCoverageTooltipFl'
								: 'labels.includeAnimalLiabilityCoverageTooltip'
							) | translate
						"
						appToggleTooltip
						appHiddenWhenCssDisabled
						class="help-icon material-icons"
					>
						<span aria-hidden="true">help_outline</span>
					</button>
				</div>

				<app-yes-no [name]="Fields.SW_AnimalLiabilityCoverage.name" [vertical]="vertical"></app-yes-no>
			</app-question-wrapper>

			<app-question-wrapper class="question-container">
				<label [for]="Fields.SW_NumberOfMortgagees.name" class="input-label yes-no">
					{{ "labels.numberOfMortgage" | translate }}
				</label>

				<app-dropdown
					[labelInsideField]="'labels.pleaseSelect' | translate"
					[name]="Fields.SW_NumberOfMortgagees.name"
					[options]="mortgageesOptions"
					notShowLabelWhenSelected="true"
				></app-dropdown>
			</app-question-wrapper>

			<div *ngIf="form.controls[Fields.SW_NumberOfMortgagees.name].value > 0" [@inOutAnimation] class="child-container">
				<app-question-wrapper>
					<label [for]="Fields.SW_MortgageCompanyName.name" class="input-label">
						{{ "labels.stillwaterMortgageServicer" | translate }}
					</label>
					<unmasked-input
						[name]="Fields.SW_MortgageCompanyName.name"
						[pattern]="Fields.SW_MortgageCompanyName.pattern"
						[placeholder]="'labels.pleaseEnter' | translate"
						type="text"
						notshowLabelWhenFilled="true"
					>
					</unmasked-input>
				</app-question-wrapper>

				<div class="address-form-row row" formGroupName="MortgageCompanyAddress">
					<div class="col-md-7">
						<app-question-wrapper>
							<label [for]="Fields.MortgageCompanyAddress.name" class="input-label">
								{{ "address.suggestion.mortgageCompanyAddress" | translate }}
							</label>
							<unmasked-input
								[autocomplete]="false"
								[name]="'AddressLine1'"
								[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
								[placeholder]="'labels.pleaseEnter' | translate"
								[tabindex]="1"
								maxlength="50"
								type="text"
								withoutErrorMessage="true"
								notshowLabelWhenFilled="true"
							>
							</unmasked-input>
						</app-question-wrapper>
					</div>
					<div class="col-md-5">
						<app-question-wrapper>
							<label class="input-label" for="AddressLine2">
								{{ "address.aptUnit" | translate }}
							</label>
							<unmasked-input
								[autocomplete]="false"
								[name]="'AddressLine2'"
								[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
								[placeholder]="'labels.pleaseEnter' | translate"
								[tabindex]="1"
								id="AddressLine2"
								maxlength="30"
								type="text"
								withoutErrorMessage="true"
								notshowLabelWhenFilled="true"
							>
							</unmasked-input>
						</app-question-wrapper>
					</div>
					<div class="col-md-4">
						<app-question-wrapper>
							<label class="input-label" for="City">{{ "address.city" | translate }}</label>
							<unmasked-input
								[autocomplete]="false"
								[name]="'City'"
								[pattern]="'^[A-Za-z\ 0-9\-\'\,\.\d]*$'"
								[placeholder]="'labels.pleaseEnter' | translate"
								[tabindex]="1"
								id="City"
								maxlength="25"
								withoutErrorMessage="true"
								notshowLabelWhenFilled="true"
							>
							</unmasked-input>
						</app-question-wrapper>
					</div>
					<div class="col-md-4">
						<app-question-wrapper>
							<label class="input-label" for="State">{{ "address.state" | translate }}</label>
							<app-dropdown
								[labelInsideField]="'labels.pleaseEnter' | translate"
								[name]="'State'"
								[options]="statesOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="1"
								id="State"
								searchable="true"
								notShowLabelWhenSelected="true"
							></app-dropdown>
						</app-question-wrapper>
					</div>
					<div class="col-md-4">
						<app-question-wrapper>
							<label class="input-label" for="ZipCode">{{ "address.zipCode" | translate }}</label>
							<unmasked-input
								[autocomplete]="false"
								[name]="'ZipCode'"
								[pattern]="'^[0-9]*$'"
								[placeholder]="'labels.pleaseEnter' | translate"
								[tabindex]="1"
								id="ZipCode"
								maxlength="5"
								type="tel"
								withoutErrorMessage="true"
								notshowLabelWhenFilled="true"
							>
							</unmasked-input>
						</app-question-wrapper>
					</div>
				</div>

				<app-question-wrapper>
					<label [for]="Fields.MortgageAccountNumber.name" class="input-label">
						{{ "labels.mortgageAccountNumber" | translate }}
					</label>

					<unmasked-input
						[name]="Fields.MortgageAccountNumber.name"
						[pattern]="Fields.MortgageAccountNumber.pattern"
						[placeholder]="'labels.pleaseEnter' | translate"
						type="text"
						notshowLabelWhenFilled="true"
					>
					</unmasked-input>
				</app-question-wrapper>

				<mat-divider></mat-divider>
			</div>

			<next-button
				(validationFailed)="handleValidation($event)"
				[disabled]="form.invalid"
				[isFullQuote]="true"
				[loaderVisible]="true"
				[myForm]="myForm"
				[shouldIgnoreDisabledFields]="true"
				[title]="'buttons.continue' | translate"
				class="fullscreen"
			></next-button>
		</form>
	</ng-container>
</section>
