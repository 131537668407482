<section class="content">
	<div [class.multilob]="isMultiLob" class="top">
		<div class="logos-wrapper">
			<ng-container *ngFor="let rate of rates; let index = index">
				<!--        Should not display logo multiple times, if the carrier is the same-->
				<div *ngIf="rate.isSelected && rate.carrier !== rates[index - 1]?.carrier" class="logo">
					<img
						[attr.alt]="rate.carrier + ' logo'"
						[src]="this.actionApiService.getLogo(rate.carrier, rate.lob)"
						role="presentation"
					/>
					<div class="sr-only">{{ rate.carrier }}</div>
				</div>
			</ng-container>
		</div>

		<div class="price-container">
			<div class="price-month">
				<span class="price">${{ getRates() | number: "1.0-0" }}</span>
				<span class="monthly">{{ "/ " }} {{ "terms.month" | translate }} </span>
			</div>

			<div *ngIf="isYearPriceVisible" class="price-year">
				<span class="price">${{ getFullTermPremium() | number: "1.0-0" }}</span>
				<span class="monthly">/ {{ getFullTermName() | translate }} </span>
			</div>
		</div>
	</div>

	<div class="middle">
		<div>
			<ng-container *ngFor="let rate of rates">
				<ng-container *ngIf="rate.lob !== 'Flood'">
					<ng-container *ngTemplateOutlet="rateSection; context: { rate: rate }"></ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
</section>

<ng-template #rateSection let-rate="rate">
	<div *ngIf="rate.isSelected" class="coverages-wrapper">
		<h3 class="coverage-section-title">{{ "lobs." + rate.lob | translate }} {{ "labels.coverage" | translate }}</h3>

		<ng-container *ngIf="!!rate.coverages.policyLevelCoverages">
			<div class="table">
				<ul [attr.aria-label]="'labels.policyCoverages' | translate" class="tr">
					<ng-container
						*ngFor="let coverage of filterCoverages(reArrangeArray(rate.coverages.policyLevelCoverages), rate.lob)"
					>
						<ng-container
							*ngTemplateOutlet="
								coverageline;
								context: {
									coverage,
									coverageName: getCoverageTexts(coverage, rate.lob),
									coveragePrice: getCoveragePrice(coverage)
								}
							"
						></ng-container>
					</ng-container>
				</ul>
			</div>
		</ng-container>

		<!--  Additional Coverages Section -->
		<ng-container *featureFlag="'homeownersAdditionalCoverage'">
			<ng-container
				*ngIf="
					showAdditionalCoveragesSection &&
					!!rate.coverages.policyLevelCoverages &&
					filterAdditionalCoverages(rate.coverages.policyLevelCoverages, rate.lob).length &&
					filterAdditionalCoverages(rate.coverages.policyLevelCoverages, rate.lob)
				"
			>
				<h3 class="coverage-section-title">{{ "titles.additionalCoverages" | translate }}</h3>
				<div class="table">
					<ul [attr.aria-label]="'labels.policyCoverages' | translate" class="tr">
						<ng-container
							*ngFor="let coverage of filterAdditionalCoverages(rate.coverages.policyLevelCoverages, rate.lob)"
						>
							<ng-container
								*ngTemplateOutlet="
									coverageline;
									context: {
										coverage,
										coverageName: getCoverageTexts(coverage, rate.lob),
										coveragePrice: getCoveragePrice(coverage),
										additional: true
									}
								"
							></ng-container>
						</ng-container>
					</ul>
				</div>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="!!rate.coverages.entityCoverages">
			<br />
			<div
				*ngFor="
					let coveragesForEntity of getEntityCoveragesArray(rate.lob, rate.coverages.entityCoverages);
					let index = index
				"
				class="table entity-coverages-wrapper"
			>
				<ul attr.aria-label="{{ 'lobs.' + rate.lob | translate }} {{ 'labels.coverage' | translate }}" class="tr">
					<div class="entity-header">
						<img
							*ngIf="rate.lob === 'PersonalAuto'"
							alt="{{ cars[index].PLMake }} {{ 'labels.logo' | translate }}"
							class="car-logo"
							src="assets/images/car-logos/thumb/{{ getVehicleLogoName(cars[index].PLMake) }}.png"
						/>
						<img
							*ngIf="rate.lob === 'Pets'"
							alt="{{ pets[index].PLPetSpecies }}"
							class="pet-logo"
							src="assets/icons/pets-{{ pets[index].PLPetSpecies?.toLowerCase() }}-small-checked.svg"
						/>
						<div class="entity-title">
							<div [class.pet-name]="rate.lob === 'pets'" class="entity-name">
								{{ getEntityTitle(rate.lob, index) }}
							</div>
							<div *ngIf="rate.lob === 'PersonalAuto' && cars[index].VIN" class="vin">
								{{ cars[index].VIN }}
							</div>
						</div>
					</div>

					<ng-container *ngFor="let coverage of coveragesForEntity">
						<ng-container
							*ngTemplateOutlet="
								coverageline;
								context: {
									coverage,
									coverageName: getCoverageTexts(coverage, rate.lob),
									coveragePrice: getCoveragePrice(coverage)
								}
							"
						></ng-container>
					</ng-container>
				</ul>
			</div>
		</ng-container>
		<ng-template
			#coverageline
			let-additional="additional"
			let-coverage="coverage"
			let-coverageDescription="coverageDescription"
			let-coverageName="coverageName"
			let-coveragePrice="coveragePrice"
		>
			<li *ngIf="coverage.value !== undefined && coverageName" class="td">
				<div class="name">{{ coverageName.displayName | translate | formatCoverageName }}</div>
				<div class="dots"></div>
				<div class="price">
					{{ isValueNaN(coveragePrice) ? coveragePrice : (coveragePrice | currency: "USD":"symbol":"1.0-0") }}
				</div>

				<button
					[class.invisible-icon]="!(coverageName.description | translate)"
					[matTooltip]="coverageName.description | translate"
					appHiddenWhenCssDisabled
					appToggleTooltip
					attr.aria-label="{{ coverageName.displayName | translate }} + ' description:'"
					class="help-icon material-icons"
				>
					<span aria-hidden="true">help_outline</span>
				</button>
			</li>
		</ng-template>

		<ng-container *ngIf="rate.discounts">
			<ng-container *ngTemplateOutlet="discountsSection"></ng-container>
		</ng-container>

		<div
			*ngIf="(rate.lob == 'PersonalHome' || rate.lob == 'Condominium' || rate.lob == 'DwellingFire') && toggledRates"
		>
			<ng-container>
				<div *ngFor="let rate of toggledRates">
					<div class="floodToggle">
						<app-toggled-coverage
							(handleToggleEmit)="handleRateSelection($event, rate)"
							[coverage]="rate"
							[isSelected]="rate.isSelected"
							[isShortMode]="true"
						></app-toggled-coverage>
					</div>
				</div>

				<div *ngIf="isSelected" [ngClass]="{ show: isSelected }" class="wrapper-flood">
					<ng-container *ngFor="let rate of rates">
						<div *ngIf="rate.lob == 'Flood'" class="flood">
							<ng-container *ngTemplateOutlet="rateSection; context: { rate: rate }"></ng-container>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #discountsSection>
	<app-discounts *ngIf="discounts" [discounts]="discounts" class="discounts-all-coverages-popup table"></app-discounts>
</ng-template>

<ng-container *ngIf="!isActionsHidden">
	<div class="popup-bottom main-content">
		<div
			[appTranslation]="isOffline ? 'rates.callAgentTitle' : 'rates.talkToAgent'"
			[attr.role]="!isOffline ? 'complementary' : 'button'"
			class="call-agent-disclaimer"
			tabindex="0"
			translateAttr="aria-label"
		></div>

		<button
			(click)="onButtonClick()"
			[appTranslation]="!isOffline || openForSelect ? 'rates.allCovOnlineBtn' : 'rates.allCovOfflineBtn'"
			[attributeOnly]="true"
			[ngClass]="{ loading: showLoading }"
			class="app-button"
			tabindex="0"
			translateAttr="aria-label"
		>
			<span class="title">
				<span
					[appTranslation]="!isOffline || openForSelect ? 'rates.allCovOnlineBtn' : 'rates.allCovOfflineBtn'"
				></span>

				<i appHiddenWhenCssDisabled class="material-icons" role="presentation">
					{{ !isOffline || openForSelect ? "add" : "chevron_right" }}
				</i>
			</span>
			<span [ngClass]="{ show: showLoading }" class="loader"></span>
		</button>
	</div>
</ng-container>
