<div
	[ngClass]="{ 'show-error': ((field && field.errors && field.touched && !isFocus) || showOnInit) && checkField() }"
	class="error-wrapper-error"
>
	<div *ngIf="((field && field.errors && field.touched) || showOnInit) && checkField()" class="error-wrapper">
		<strong [attr.id]="describedByErrorId" role="alert">
			<ng-container *ngIf="field.errors && field.errors.required; else continue">
				{{ "errors.required" | translate }}
			</ng-container>
		</strong>
		<ng-template #continue>
			<ng-container *ngIf="field.errors && field.errors.invalidEmail">
				{{ "errors.invalidEmail" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidEmailAuto">{{
				"errors.invalidEmailAuto" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidBeforeDate">{{
				"errors.invalidBeforeDate" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidFeatureDate">{{
				"errors.invalidFeatureDate" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.max"
				>{{ "errors.maxValue" | translate }} {{ field.errors.max.max }}</ng-container
			>
			<ng-container *ngIf="field.errors && field.errors.invalidChars">{{
				"errors.invalidChars" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.exceededMaxDays">{{
				"errors.exceededMaxDays" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidPhoneNumber"
				>{{ "errors.invalidPhoneNumber" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidPhoneNumberAuto">{{
				"errors.invalidPhoneNumberAuto" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidAddress"
				>{{ "errors.invalidAddress" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidVINCharacters"
				>{{ "errors.invalidVINCharacters" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.valueNotEquals"
				>{{ "errors.valueNotEquals" | translate }} {{ fieldDisplayName }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.ageLessThanDeparture"
				>{{ "errors.ageLessThanDeparture" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.ageGreaterThanReturn"
				>{{ "errors.ageGreaterThanReturn" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.ageLessThan18"
				>{{ "errors.ageLessThan18" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.ageGreaterThan120"
				>{{ "errors.ageGreaterThan120" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.PostalCodeMinChars"
				>{{ "errors.postalCodeMinChars" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.postalCodeNumbersOnly">{{
				"errors.postalCodeNumbersOnly" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidAlphabetAndDigitsAnd"
				>{{ "errors.alphaDigitsOnly" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidVINCharactersPopup"
				>{{ "errors.invalidVINCharactersPopup" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidAlphabet"
				>{{ "errors.alphaDashDot" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.notMatch">{{
				"errors.emailNotMatch" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.notToday">{{ "errors.notToday" | translate }} </ng-container>
			<ng-container *ngIf="field.errors && field.errors.minlength && field.errors.minlength.actualLength === 1"
				>{{ fieldDisplayName }} {{ "errors.minLength" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.minlength && field.errors.minlength.actualLength !== 1"
				>{{ fieldDisplayName }} {{ "errors.mustBeLonger" | translate }} {{ field.errors.minlength.actualLength }}
				{{ "errors.characters" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.maxlength"
				>{{ fieldDisplayName }} {{ "errors.cantBeLonger" | translate }} {{ field.errors.maxlength.requiredLength }}
				{{ "errors.characters" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.min"
				>{{ "errors.cantBeLess" | translate }} {{ field.errors.number }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidExpirationDate"
				>{{ "errors.invalidExpirationDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidFutureDate"
				>{{ "errors.invalidFutureDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.futureDate">{{ "errors.futureDate" | translate }}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.lessThen18"
				>{{ "errors.lessThen18" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.lessThan16"
				>{{ "errors.lessThen16" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidYearBuiltFuture"
				>{{ "errors.invalidYearBuiltFuture" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.isDefensiveFutureDate"
				>{{ "errors.defensiveFutureDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidYearBuiltPast"
				>{{ "errors.invalidYearBuiltPast" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.costNewValueLessOrEqualTo0"
				>{{ "errors.lowCost" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.fieldLessOrEqualTo0">{{
				"errors.lessOrEqual0" | translate
			}}</ng-container>
			<ng-container *ngIf="field.errors && (field.errors.mileageLessThan1000 || field.errors.mileageMoreThan100k)"
				>{{ "errors.mileageMoreThan100k" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.fieldGreaterThen99"
				>{{ fieldDisplayName }} {{ "errors.cantBeGreaterThan99" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.moreThen4Stories"
				>{{ "errors.moreThen4Stories" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.ExteriorWallsIsNone"
				>{{ "errors.exteriorWallsIsNone" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.notfutureDateAnd60Days"
				>{{ "errors.future60Days" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.yearsAtAddressLessThenAge"
				>{{ "errors.yearsAtAddressLessThenAge" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidDateOfCoApplicantChildAfterDateOfParent"
				>{{ "errors.invalidDateOfCoApplicantChildAfterDateOfParent" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidDateOfCoApplicantParentBeforeDateOfChild"
				>{{ "errors.invalidDateOfCoApplicantParentBeforeDateOfChild" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidHalfFloor"
				>{{ "errors.invalidHalfFloor" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.plumbingUpdatePastDate"
				>{{ "errors.plumbingUpdatePastDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidPastDateRoofUpdate"
				>{{ "errors.invalidPastDateRoofUpdate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dateOfBirthBefore1900"
				>{{ "errors.dateOfBirthBefore1900" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidFutureDateRoofUpdate"
				>{{ "errors.invalidFutureDateRoofUpdate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.FutureDateIsInvalid"
				>{{ "errors.futureDateIsInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.isDateAfterEffectiveDate"
				>{{ "errors.isDateAfterEffectiveDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.exceedMaxFirePlaces"
				>{{ "errors.exceedMaxFirePlaces" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.plumbingUpdateFetureDate"
				>{{ "errors.plumbingUpdateFutureDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.heatingUpdatePastDate"
				>{{ "errors.heatingUpdatePastDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.heatingUpdateFetureDate"
				>{{ "errors.heatingUpdateFutureDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.electricalUpdateFetureDate"
				>{{ "errors.electricalUpdateFutureDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.electricalUpdatePastDate"
				>{{ "errors.electricalUpdatePastDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.fireDetectionTypeRequired"
				>{{ "errors.fireDetectionTypeRequired" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.bruglarAlarmTypeRequired"
				>{{ "errors.bruglarAlarmTypeRequired" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidSSN"
				>{{ "errors.invalidSSN" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidDobPast"
				>{{ "errors.invalidDobPast" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.lastNameOneChar"
				>{{ "errors.lastNameOneChar" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.firstNameOneChar"
				>{{ "errors.firstNameOneChar" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.puchasePastDateInvalid"
				>{{ "errors.puchasePastDateInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dateOccupiedPurchaseDateInvalid"
				>{{ "errors.dateOccupiedPurchaseDateInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dateOccupiedPastDateInvalid"
				>{{ "errors.dateOccupiedPastDateInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.puchaseFetureDateInvalid"
				>{{ "errors.puchaseFutureDateInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dateOccupiedFetureDateInvalid"
				>{{ "errors.dateOccupiedFutureDateInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.SquareFootageMustBetween0"
				>{{ "errors.squareFootageMustBetween0" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dateOccupidInvalid"
				>{{ "errors.dateOccupidInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.floorNumberHigher"
				>{{ "errors.floorNumberHigher" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.numberOfFloorsHigher"
				>{{ "errors.numberOfFloorsHigher" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.numbersdDigits"
				>{{ "errors.numbersdDigits" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dateFormatInvalid"
				>{{ "errors.dateFormatInvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidPoBox"
				>{{ "errors.invalidPoBox" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidEarlierThan1900"
				>{{ "errors.invalidEarlierThan1900" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidEqulesOrAfter1920"
				>{{ "errors.invalidEqulesOrAfter1920" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidAgeUnder18"
				>{{ "errors.invalidAgeUnder18" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidZipStrictLength"
				>{{ "errors.invalidZipStrictLength" | translate }} {{ field.errors.requiredLength }}
				{{ "errors.characters" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidZipNotExist"
				>{{ "errors.invalidZipNotExist" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.multiDropdownRequired"
				>{{ "errors.multiDropdownRequired" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.personalLossesDateFuture"
				>{{ "errors.personalLossesDateFuture" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.dogsBreedsMaxSelection"
				>{{ "errors.dogsBreedsMaxSelection" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.PersonalLossesDateBeforeDOB"
				>{{ "errors.personalLossesDateBeforeDOB" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.YearsAtAddressBeforeDOB"
				>{{ "errors.yearsAtAddressBeforeDOB" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.priorCarrierHomeBeforeDOB"
				>{{ "errors.priorCarrierHomeBeforeDOB" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.checklistRequired"
				>{{ "errors.checklistRequired" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.unitsMustBetween1to99"
				>{{ "errors.unitsMustBetween1to99" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidVIN"
				>{{ "errors.invalidVIN" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.vinNotMatchingVehicleInfo"
				>{{ "errors.vinNotMatchingVehicleInfo" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.vinNotMatchingVehicle"
				>{{ "errors.vinNotMatchingVehicle" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.duplicatedVIN"
				>{{ "errors.duplicatedVIN" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidVINLength"
				>{{ "errors.invalidVINLength" | translate }}
			</ng-container>

			<ng-container *ngIf="field.errors && field.errors.serverError">{{ field.errors.serverError }}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.mandatory">{{ "errors.mandatory" | translate }} </ng-container>
			<ng-container *ngIf="field.errors && field.errors.requiredNo">{{ "errors.requiredNo" | translate }}</ng-container>
			<ng-container *ngIf="field.errors && field.errors.driverDateLicensedIsLessThan16YearsAfterDOB"
				>{{ "errors.driverDateLicensedIsLessThan16YearsAfterDOB" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.licenseStatusForPrimaryDriver"
				>{{ "errors.licenseStatusForPrimaryDriver" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidDate"
				>{{ "errors.invalidDate" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidPetDobPast"
				>{{ "errors.invalidPetDobPast" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.petMoreThan20YearsOld"
				>{{ "errors.petMoreThan20YearsOld" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.yearspriorcannotbehigercontinuous"
				>{{ "errors.yearspriorcannotbehigercontinuous" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.vinnotvalid"
				>{{ "errors.vinnotvalid" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.cannotbeother"
				>{{ "errors.cannotbeother" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.notValidSelection"
				>{{ "errors.notValidSelection" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.notValidDayWeek"
				>{{ "errors.notValidDayWeek" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.invalidSnapshotNumber"
				>{{ "errors.invalidSnapshotNumber" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.isDateLessThanYears">
				<!-- generate text that allow to enter date less than 5 years for accident,violation,loss -->{{
					"errors.isDateLessThanYears" | translate
				}}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.positiveNumber"
				>{{ "errors.positiveNumber" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.greaterThan0"
				>{{ "errors.greaterThan0" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.matDatepickerParse"
				>{{ "errors.matDatepickerParse" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.matDatepickerMax"
				>{{ "errors.matDatepickerMax" | translate }}
				{{ moment(field.errors.matDatepickerMax.max).format("MM/DD/YYYY") }}.
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.matDatepickerMin"
				>{{ "errors.matDatepickerMin" | translate }}
				{{ moment(field.errors.matDatepickerMax.min).format("MM/DD/YYYY") }}.
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.notFullAddress">
				{{ "errors.notFullAddress" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.requiredTrue">
				{{ "errors.requiredTrue" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.number1to10">
				{{ "errors.number1to10" | translate }}
			</ng-container>
			<ng-container *ngIf="field.errors && field.errors.duplicateSpouse">
				{{ "errors.duplicateSpouse" | translate }}
			</ng-container>
		</ng-template>
	</div>
</div>

<span
	*ngIf="showIconError"
	[ngClass]="{ show: ((field && field.errors && field.touched && !this.isFocus) || showOnInit) && checkField() }"
	class="show-error-icon"
></span>
