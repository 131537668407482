<div [formGroup]="controlContainer.control" class="wrapper">
	<label [class.selected]="!!value" [for]="name" class="label">
		<ng-container *ngIf="!!value">
			<app-image [name]="selectedImage" alt="null" role="presentation"></app-image>
		</ng-container>

		<ng-container *ngIf="!value">
			<app-image [name]="image" alt="null" role="presentation"></app-image>
		</ng-container>

		<span class="circle-wrapper">
			<span class="inner"></span>
		</span>

		<span class="label-text">{{ label }}</span>
		<input
			[attr.aria-checked]="!!value"
			[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
			[attr.aria-invalid]="isInvalid() ? true : null"
			[formControlName]="name"
			[id]="name"
			class="checkbox"
			tabindex="0"
			type="checkbox"
		/>
	</label>
</div>
