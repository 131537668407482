import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/states/app.state';
import { hidePopupAction } from '../../../../store/actions/popup.actions';
import { EventActions } from '../../../../models/event-data.model';
import { selectActiveTheme } from '../../../../store/selectors/active-theme.selector';
import { GeneralPropsEnum } from '../../../../models/general-props.enum';
import { combineLatest, first } from 'rxjs';
import { selectLanguageSettings } from '../../../../store/selectors/languages.selector';

@Component({
	selector: 'app-phone',
	templateUrl: './phone.component.html',
	styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent implements OnInit {
	data: {
		subtext: string;
		text: string;
		data: any;
	};
	phoneNumber: any;
	eventActions = EventActions;
	workingHours: string;

	constructor(private store: Store<IAppState>) {}

	ngOnInit(): void {
		if (this.data) {
			this.phoneNumber = this.data?.data.phoneNumber;
		}
		combineLatest([this.store.select(selectActiveTheme), this.store.select(selectLanguageSettings)])
			.pipe(first())
			.subscribe(([theme, languageSettings]) => {
				this.workingHours = theme[GeneralPropsEnum.texts].header['workingHours:' + languageSettings.selected] || '';
			});
	}

	close() {
		this.store.dispatch(hidePopupAction());
	}
}
