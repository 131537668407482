import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionApiService } from 'src/app/services/action-api.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../../../../base-page.component';
import { first } from 'rxjs/operators';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { NextButtonComponent } from '../../../../../../../next-button/next-button.component';

@Component({
	selector: 'app-progressive-missing-drivers',
	templateUrl: './progressive-missing-drivers.component.html',
	styleUrls: ['./progressive-missing-drivers.component.scss'],
})
export class ProgressiveMissingDriversComponent extends BasePageComponent implements OnInit {
	@ViewChild(NextButtonComponent) nextButtonComponent: NextButtonComponent;
	actionApiService: ActionApiService;
	drivers = [];
	subscription$: Subscription;

	constructor(
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>,
		actionApiService: ActionApiService,
		private location: Location
	) {
		super(injector, store, quoteDataService);
		this.actionApiService = actionApiService;
	}

	get missingDriversFormControl(): FormArray {
		return this.form.controls.missingDrivers as FormArray;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();

		setTimeout(() => {
			this.patchData(this.form);
			this.registerOnChange(this.form);
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				let missingDrivers = [
					...(quoteData.missingDrivers && quoteData.missingDrivers.length ? quoteData.missingDrivers : []),
				];
				missingDrivers.sort((a, b) => (a.SequenceNum > b.SequenceNum ? 1 : -1));
				this.drivers = [...missingDrivers];
				this.drivers.forEach((driver, index) => {
					const missingDriverItem = missingDrivers.find((item) => item.Id === driver.Id);
					this.missingDriversFormControl.push(
						this.createDriverForm(driver.Id, driver.SequenceNum, missingDriverItem || {})
					);
				});
			});
	}

	createDriverForm(id, sequenceNum, missingDriverItem): FormGroup {
		return this.formBuilder.group({
			Id: id,
			SequenceNum: sequenceNum ? sequenceNum : 0,
			FirstName: missingDriverItem.FirstName,
			LastName: missingDriverItem.LastName,
			Gender: missingDriverItem.Gender,
			DriverAction: [missingDriverItem?.DriverAction ? missingDriverItem.DriverAction : null, [Validators.required]],
			confirmed: missingDriverItem.confirmed || false,
		}) as FormGroup;
	}

	initForm() {
		this.form = new FormGroup({
			missingDrivers: new FormArray([]),
		});
	}

	getMissingDriverForm(index: number) {
		return this.missingDriversFormControl.controls[index] as FormGroup;
	}

	handleContinue() {
		const drivers = this.missingDriversFormControl.value.map((driver) => {
			delete driver.confirmed;
			delete driver.FirstName;
			delete driver.LastName;
			delete driver.DOB;
			return driver;
		});

		// Need to check - If a driver is in the policy data and doesn't have driverAction = 'Add' - so it needs to be deleted from the policy data.
		let driversToDelete = [];

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				driversToDelete = drivers
					.filter((i) => i.DriverAction !== 'Add' && quoteData.discoverDrivers.map((i) => i.Id).includes(i.Id))
					.map((i) => i.Id);

				if (driversToDelete.length > 0) {
					//Need to call to update drivers to delete from the quote data
					let Personaldrivers = [...quoteData.Drivers].map((item) => {
						return { Id: item.Id, SequenceNum: item.SequenceNum };
					});

					let discoverDriversToUpdate = quoteData.discoverDrivers.map((i, index) => {
						let currDriver = drivers.filter((j) => j.Id == i.Id);
						if (currDriver.length && currDriver[0]['DriverAction'] !== 'Add') {
							return { ...i, active: false, SequenceNum: index };
						} else {
							return { ...i, active: true, SequenceNum: index };
						}
					});

					this.actionApiService
						.updateDriverFQ(
							{
								Drivers: {
									Drivers: Personaldrivers,
									discoverDrivers: discoverDriversToUpdate,
									manualDrivers: quoteData.manualDrivers,
								},
							},
							CarriersEnum.Progressive
						)
						.subscribe((response) => {
							//Delete the drivers from update Quote Data
							this.store.dispatch(
								UpdateQuoteData({
									data: {
										discoverDrivers: quoteData.discoverDrivers.filter((i) => !driversToDelete.includes(i.Id)),
									},
								})
							);
							this.actionApiService
								.updateApplication({ DiscoveredDrivers: drivers }, [LobsEnum.PERSONAL_AUTO], CarriersEnum.Progressive)
								.subscribe((serverRes) => {
									this.onUpdateApplicationResult(serverRes, this.missingDriversFormControl.controls as FormGroup[]);
								});
						});
				} else {
					//No need to update quote data
					this.actionApiService
						.updateApplication({ DiscoveredDrivers: drivers }, [LobsEnum.PERSONAL_AUTO], CarriersEnum.Progressive)
						.subscribe((serverRes) => {
							this.onUpdateApplicationResult(serverRes, this.missingDriversFormControl.controls as FormGroup[]);
						});
				}
			});
	}

	goBack() {
		this.location.back();
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, { label: 'go-back', button: 'go-back-progressive-missing-drivers', module: 'progressive-missing-drivers-module' });

	}
}
