import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BasePageComponent } from 'src/app/components/layout/pages/base-page.component';
import { Fields } from 'src/app/constants/fields';
import { IAppState } from 'src/app/store/states/app.state';

@Component({
	selector: 'app-disclosure',
	templateUrl: './disclosure.component.html',
	styleUrls: ['./disclosure.component.scss'],
})
export class DisclosureComponent extends BasePageComponent implements OnInit, AfterViewInit {
	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		this.initform();
	}

	initform(): void {
		this.form = new FormGroup({}, null, null);
		this.form.addControl(
			'FQData.ProgressivePersonalAuto.eSignInd',
			new FormControl({ value: true, disabled: false }, [Validators.required])
		);
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}
}
