import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { BasePageComponent } from '../../../../../base-page.component';
import { IAppState } from '../../../../../../../../store/states/app.state';
import { QuoteDataService } from '../../../../../../../../services/quote-data.service';
import { Fields } from '../stillwater-fields';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { first } from 'rxjs/operators';

const PL_TYPE_OF_DWELLING_OPTIONS = ['Townhouse', 'Rowhouse'];

@Component({
	selector: 'app-stillwater-property-safety',
	templateUrl: './stillwater-property-safety.component.html',
	styleUrls: ['./stillwater-property-safety.component.scss', '../stillwater.component.scss'],
})
export class StillwaterPropertySafetyComponent extends BasePageComponent implements OnInit {
	Fields = Fields;
	vertical = true;

	constructor(
		injector: Injector,
		protected store: Store<IAppState>,
		private fb: FormBuilder,
		protected quoteDataService: QuoteDataService
	) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.form = this.fb.group({
			[this.Fields.DistanceToFireHydrant.name]: [null, [Validators.required]],
			[this.Fields.DistanceToFireStation.name]: [null, [Validators.required]],
			[this.Fields.LimitedAccessLocation.name]: [null, [Validators.required]],
			[this.Fields.PLNumberOfUnitsInFirewall.name]: [null, [Validators.required]],
			[this.Fields.PermanentFoundation.name]: [null, [Validators.required]],
			[this.Fields.AltWaterSource.name]: [{value: null, disabled: true}, [Validators.required]],
		});

		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((quoteData) => {
				if (quoteData.PropertyAddress.State !== 'FL' || quoteData.PropertyAddress.County !== 'MONROE') {
					this.form.controls[this.Fields.AltWaterSource.name].disable();
				}
				if (!PL_TYPE_OF_DWELLING_OPTIONS.includes(quoteData.PLTypeOfDwelling)) {
					this.form.controls[this.Fields.PLNumberOfUnitsInFirewall.name].disable();
				}
				this.form.updateValueAndValidity();

				super.registerOnChange(this.form);
				this.patchData(this.form);
			});
	}
}
