import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routing/routing';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appReducers } from './store/reducers/app.reducers';
import { effects } from './store/effects';
import { servicesArr } from './services/services';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionApiService } from './services/action-api.service';
import { PopupDirective, PopupWrapperComponent } from './components/layout/popup/popup-wrapper/popup-wrapper.component';
import { NgOnDestroyService } from './services/ng-on-destroy.service';
import { AppComponent } from './app.component';
import { NextButtonModule } from './components/next-button/next-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from './components/form-controls/form-control.module';
import { HeaderModule } from './components/layout/header/header.module';
import { PopupModule } from './components/layout/popup/popup.module';
import { ProgressMeterComponent } from './components/layout/progress-meter/progress-meter.component';
import { AddressComponent } from './components/form-controls/address/address.component';
import { SsnMaskingPipe } from './pipes/ssn-masking.pipe';
import { PersonalDetailsComponent } from './components/layout/pages/renters/personal-details/personal-details.component';
import { PropertiesComponent } from './components/layout/pages/renters/properties/properties.component';
import { PopoverAddressComponent } from './components/form-controls/popover-address/popover-address.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { LabelModule } from './components/form-controls/label/label.module';
import { ErrorMessageModule } from './components/error-message/error-message.module';
import { ResultComponent } from './components/layout/pages/shared/result/result.component';
import { RateNumberPipe } from './pipes/rate-number.pipe';
import { CarrierOfferCardComponent } from './components/shared/carrier-offer-card/carrier-offer-card.component';
import { PaymentDetailsComponent } from './components/layout/pages/shared/payment-details/payment-details.component';
import { YourDetailsComponent } from './components/layout/pages/shared/payment-details/your-details/your-details.component';
import { HouseDetailsComponent } from './components/layout/pages/homeowners/house-details/house-details.component';
import { PolicyDatePickerComponent } from './components/layout/pages/shared/policy-date-picker/policy-date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { CarrierKickoutComponent } from './components/layout/pages/shared/kickouts/carrier-kickout/carrier-kickout.component';
import { metaReducers } from './store/meta-reducers';
import { BasePageComponent } from './components/layout/pages/base-page.component';
import { PaymentPlanComponent } from './components/layout/pages/shared/payment-plan/payment-plan.component';
import { PaymentComponent } from './components/layout/pages/shared/payment/payment.component';
import { SuccessPageComponent } from './components/layout/pages/shared/success-page/success-page.component';
import { PaymentFailedComponent } from './components/layout/pages/shared/payment-failed/payment-failed.component';
import { SafePipe } from './pipes/safe.pipe';
import { CoverageComponent } from './components/layout/pages/shared/result/coverage/coverage.component';
import { HomesiteComponent } from './components/layout/pages/renters/additional-questions/components/homesite/homesite.component';
import { StillwaterComponent } from './components/layout/pages/renters/additional-questions/components/stillwater/stillwater.component';
import { PaymentResultComponent } from './components/layout/pages/shared/payment-result/payment-result.component';
import { PropertiesHomeownersComponent } from './components/layout/pages/homeowners/properties/properties.component';
import { PrimaryResidenceComponent } from './components/layout/pages/homeowners/primary-residence/primary-residence.component';
import { PropertyUsageComponent } from './components/layout/pages/homeowners/property-usage/property-usage.component';
import { SingleYesNoComponent } from './components/layout/pages/homeowners/shared/single-yes-no/single-yes-no.component';
import { ConfigService, IConfig } from './services/config.service';
import { QuestionWrapperModule } from './components/layout/question-wrapper/question-wrapper.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { LobSelectionComponent } from './components/layout/pages/shared/lob-selection/lob-selection.component';
import { RoofReplacementComponent } from './components/layout/pages/homeowners/roof-replacement/roof-replacement.component';
import { YearBuiltComponent } from './components/layout/pages/homeowners/year-built/year-built.component';
import { RadioButtonsComponent } from './components/layout/pages/homeowners/shared/radio-buttons/radio-buttons.component';
import { PersonalDetailsHomeownersComponent } from './components/layout/pages/homeowners/personal-details/personal-details.component';
import { ErrorComponent } from './components/layout/pages/shared/error/error.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { TechnicalErrorComponent } from './components/layout/pages/shared/error/technical-error/technical-error.component';
import { EndSessionErrorComponent } from './components/layout/pages/shared/error/end-session-error/end-session-error.component';
import { StateNotAllowedComponent } from './components/layout/pages/shared/error/state-not-allowed/state-not-allowed.component';
import { ToggleModule } from './components/shared/toggle/toggle.module';
import { AppService } from './services/app.service';
import { DirectivesModule } from './directives/directives.module';
import { PageNotFoundErrorComponent } from './components/layout/pages/shared/error/page-not-found-error/page-not-found-error.component';
import { LoaderComponent as AppLoaderComponent } from './components/shared/loader/loader.component';
import { ImageModule } from './components/shared/image/image.module';
import { LemonadeComponent } from './components/layout/pages/homeowners/additional-questions/components/lemonade/lemonade.component';
import { LemonadeSecondPageComponent } from './components/layout/pages/homeowners/additional-questions/components/lemonade-second-page/lemonade-second-page.component';
import { RetrieveQuoteService } from './services/retrieve-quote.service';
import { PrimaryDriverComponent } from './components/layout/pages/auto/primary-driver/primary-driver.component';
import { AdditionalDriversComponent } from './components/layout/pages/auto/drivers/additional-drivers.component';
import { DriverComponent } from './components/layout/pages/auto/drivers/driver/driver.component';
import { VehiclesPageComponent } from './components/layout/pages/auto/vehicles/vehicles.component';
import { VehicleComponent } from './components/layout/pages/auto/vehicles/vehicle/vehicle.component';
import { AddEditVehicleComponent } from './components/layout/popup/add-edit-vehicle/add-edit-vehicle.component';
import { PrimaryVehicleComponent } from './components/layout/popup/primary-vehicle/primary-vehicle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AutoCoveragesComponent } from './components/layout/pages/auto/auto-coverages/auto-coverages.component';
import { VehicleCoverComponent } from './components/layout/pages/auto/auto-coverages/vehicle-cover/vehicle-cover.component';
import { LinkExpiredErrorComponent } from './components/layout/pages/shared/error/link-expired-error/link-expired-error.component';
import { DriverHistoryComponent } from './components/layout/pages/auto/driver-history/driver-history.component';
import { SafecoVehiclesComponent } from './components/layout/pages/auto/additional-questions/components/safeco/safeco-vehicles/safeco-vehicles.component';
import { SafecoMissingDriversComponent } from './components/layout/pages/auto/additional-questions/components/safeco/safeco-missing-drivers/safeco-missing-drivers.component';
import { SafecoDriversComponent } from './components/layout/pages/auto/additional-questions/components/safeco/safeco-drivers/safeco-drivers.component';
import { SafecoPolicyComponent } from './components/layout/pages/auto/additional-questions/components/safeco/safeco-policy/safeco-policy.component';
import { AddEditPetComponent } from './components/layout/pages/pets/add-edit-pet/add-edit-pet.component';
import { PetsComponent } from './components/layout/pages/pets/pets/pets.component';
import { LinkSentComponent } from './components/layout/pages/shared/error/link-sent/link-sent.component';
import { CallAgentComponent } from './components/layout/pages/shared/call-agent/call-agent.component';
import { ToggledCoverageModule } from './components/shared/toggled-coverage/toggled-coverage.module';
import { AlmostThereComponent } from './components/layout/pages/shared/almost-there/almost-there.component';
import { LegalTextComponent } from './components/shared/legal-text/legal-text.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { PersonalDetailsPetsComponent } from './components/layout/pages/pets/personal-details/personal-details.component';
import { TextUnmaskingPipe } from './pipes/text-unmasking.pipe';
import { RadioButtonsMultiSelectionComponent } from './components/layout/pages/homeowners/shared/radio-buttons-multi-selection/radio-buttons.component';
import { SafetyAlarmsPage } from './components/layout/pages/shared/safety-alarms/safety-alarms.component';
import { RetrieveQuotePage } from './components/layout/pages/shared/retrieve-quote/retrieve quote.component';
import { UpsellBoxModule } from './components/shared/upsell-box/upsell-box.module';
import { PageHeadingComponent } from './components/shared/page-heading/components/page-heading/page-heading.component';
import { NoRatesErrorComponent } from './components/layout/pages/shared/error/no-rates-error/no-rates-error.component';
import { DisclosureComponent } from './components/layout/pages/auto/additional-questions/components/progressive/disclosure/disclosure.component';
import { ProgressiveSnapshotComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-snapshot/progressive-snapshot.component';
import { ProgressiveDriversComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-drivers/progressive-drivers.component';
import { ProgressiveAdditionalDriversComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-additional-drivers/progressive-additional-drivers.component';
import { ProgressiveMissingDriversComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-missing-drivers/progressive-missing-drivers.component';
import { ProgressiveAdditionalQuestionsComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-additional-questions/progressive-additional-questions.component';
import { ProgressiveVehiclesComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-vehicles/progressive-vehicles.component';
import { ProgressiveCoveragesComponent } from './components/layout/pages/auto/additional-questions/components/progressive/progressive-coverages/progressive-coverages.component';
import { FiskerDisclaimerComponent } from './components/layout/pages/shared/fisker-disclaimer/fisker-disclaimer.component';
import { StillwaterPropertyInformationComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-property-information/stillwater-property-information.component';
import { StillwaterAdditionalPropertyInformationComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-additional-property-information/stillwater-additional-property-information.component';
import { StillwaterUtilitiesComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-utilities/stillwater-utilities.component';
import { StillwaterDetailsComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-details/stillwater-details.component';
import { PaymentPlanEscrowComponent } from './components/layout/pages/shared/payment-plan-escrow/payment-plan-escrow.component';
import { SuccessPageEscrowComponent } from './components/layout/pages/shared/success-page-escrow/success-page-escrow.component';
import { ConfirmationEmailSentComponent } from './components/layout/pages/shared/confirmation-email-sent/confirmation-email-sent.component';
import { YourAddressComponent } from './components/layout/pages/shared/landing-page/sections/your-address/your-address.component';
import { LandingPageComponent } from './components/layout/pages/shared/landing-page/landing-page.component';
import { FooterComponent } from './components/layout/pages/shared/landing-page/sections/footer/footer.component';
import { StatisticComponent } from './components/layout/pages/shared/landing-page/sections/statistic/statistic.component';
import { CarriersComponent } from './components/layout/pages/shared/landing-page/sections/carriers/carriers.component';
import { HowItWorksComponent } from './components/layout/pages/shared/landing-page/sections/how-it-works/how-it-works.component';
import { VideoComponent } from './components/layout/pages/shared/landing-page/sections/video/video.component';
import { PlainTextComponent } from './components/layout/pages/shared/landing-page/sections/plain-text/plain-text.component';
import { TestimonialsComponent } from './components/layout/pages/shared/landing-page/sections/testimonials/testimonials.component';
import { FaqComponent } from './components/layout/pages/shared/landing-page/sections/faq/faq.component';
import { WhoWeAreComponent } from './components/layout/pages/shared/landing-page/sections/who-we-are/who-we-are.component';
import { ZeroOrTwoDigitsFraction } from './pipes/zero-or-two-digits-frac.pipe';
import { EventsService } from './services/events.service';
import { SectionBaseComponent } from './components/layout/pages/shared/landing-page/sections/section-base.component';
import { LanguageService } from './services/language.service';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './utils/http-loader.factory';
import { StillwaterPropertySafetyComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-property-safety/stillwater-property-safety.component';
import { StillwaterPropertyFeaturesComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-property-features/stillwater-property-features.component';
import { StillwaterCarrierDisclosureComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-carrier-disclosure/stillwater-carrier-disclosure.component';
import { StillwaterCurrentInsuranceComponent } from './components/layout/pages/homeowners/additional-questions/components/stillwater/stillwater-current-insurance/stillwater-current-insurance.component';
import { QuoteConfirmationComponent } from './components/layout/pages/shared/quote-confirmation/quote-confirmation.component';
import { CoveragesDetailsComponent } from './components/shared/coverages-details/coverages-details.component';
import { getD2CFeatures } from './utils/general.utils';
import { DiscountsModule } from './components/shared/discounts/discounts.module';
import { NoConsumerFlowComponent } from './components/layout/pages/shared/error/no-consumer-flow/no-consumer-flow.component';
import { PhonePipe } from './pipes/phone.pipe';
import { NgxHotjarModule } from 'ngx-hotjar';
import { AnchorCarrierComponent } from './components/layout/pages/shared/anchor-carrier/anchor-carrier.component';
import { CoveragesListComponent } from './components/shared/coverages-list/coverages-list.component';

@NgModule({
	declarations: [
		AppComponent,
		BasePageComponent,
		AppLoaderComponent,
		PopupDirective,
		PopupWrapperComponent,
		ProgressMeterComponent,
		AddressComponent,
		SsnMaskingPipe,
		PersonalDetailsComponent,
		PropertiesComponent,
		PropertiesHomeownersComponent,
		YourAddressComponent,
		PopoverAddressComponent,
		ResultComponent,
		CoverageComponent,
		RateNumberPipe,
		ZeroOrTwoDigitsFraction,
		SafePipe,
		CarrierOfferCardComponent,
		PaymentDetailsComponent,
		YourDetailsComponent,
		HouseDetailsComponent,
		PolicyDatePickerComponent,
		PaymentPlanComponent,
		CarrierKickoutComponent,
		PaymentComponent,
		SuccessPageComponent,
		PaymentFailedComponent,
		HomesiteComponent,
		StillwaterComponent,
		SafetyAlarmsPage,
		RetrieveQuotePage,
		PaymentResultComponent,
		PrimaryResidenceComponent,
		PropertyUsageComponent,
		SingleYesNoComponent,
		LobSelectionComponent,
		RoofReplacementComponent,
		YearBuiltComponent,
		RadioButtonsComponent,
		RadioButtonsMultiSelectionComponent,
		PersonalDetailsHomeownersComponent,
		PersonalDetailsPetsComponent,
		ErrorComponent,
		TechnicalErrorComponent,
		NoRatesErrorComponent,
		EndSessionErrorComponent,
		StateNotAllowedComponent,
		PageNotFoundErrorComponent,
		LemonadeComponent,
		LemonadeSecondPageComponent,
		PrimaryDriverComponent,
		AdditionalDriversComponent,
		DriverComponent,
		VehiclesPageComponent,
		VehicleComponent,
		AddEditVehicleComponent,
		PrimaryVehicleComponent,
		AutoCoveragesComponent,
		VehicleCoverComponent,
		LinkExpiredErrorComponent,
		LinkSentComponent,
		FiskerDisclaimerComponent,
		DriverHistoryComponent,
		SafecoVehiclesComponent,
		SafecoMissingDriversComponent,
		SafecoDriversComponent,
		SafecoPolicyComponent,
		AddEditPetComponent,
		PetsComponent,
		CallAgentComponent,
		AlmostThereComponent,
		LegalTextComponent,
		PageHeadingComponent,
		DisclosureComponent,
		ProgressiveSnapshotComponent,
		ProgressiveDriversComponent,
		ProgressiveAdditionalDriversComponent,
		ProgressiveMissingDriversComponent,
		ProgressiveAdditionalQuestionsComponent,
		ProgressiveVehiclesComponent,
		ProgressiveCoveragesComponent,
		StillwaterPropertyInformationComponent,
		StillwaterAdditionalPropertyInformationComponent,
		StillwaterPropertySafetyComponent,
		StillwaterPropertyFeaturesComponent,
		StillwaterUtilitiesComponent,
		StillwaterCarrierDisclosureComponent,
		StillwaterCurrentInsuranceComponent,
		StillwaterDetailsComponent,
		PaymentPlanEscrowComponent,
		SuccessPageEscrowComponent,
		ConfirmationEmailSentComponent,
		LandingPageComponent,
		FooterComponent,
		StatisticComponent,
		CarriersComponent,
		HowItWorksComponent,
		VideoComponent,
		PlainTextComponent,
		TestimonialsComponent,
		FaqComponent,
		WhoWeAreComponent,
		SectionBaseComponent,
		QuoteConfirmationComponent,
		CoveragesDetailsComponent,
		NoConsumerFlowComponent,
		PhonePipe,
		AnchorCarrierComponent,
		CoveragesListComponent,
	],
	imports: [
		BrowserModule,
		EffectsModule.forRoot([...effects]),
		StoreModule.forRoot(appReducers, { metaReducers }),
		StoreDevtoolsModule.instrument(),
		RouterModule.forRoot(routes),
		HttpClientModule,
		BrowserAnimationsModule,
		NextButtonModule,
		FormsModule,
		ReactiveFormsModule,
		FormControlModule,
		ImageModule,
		HeaderModule,
		PopupModule,
		CommonModule,
		LabelModule,
		ErrorMessageModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatCardModule,
		MatTooltipModule,
		QuestionWrapperModule,
		NgSelectModule,
		ToggleModule,
		DirectivesModule,
		MatSlideToggleModule,
		ToggledCoverageModule,
		MatIconModule,
		MatTabsModule,
		UpsellBoxModule,
		MatButtonToggleModule,
		MatExpansionModule,
		MatDividerModule,
		DiscountsModule,
		TranslateModule.forRoot({
			defaultLanguage: LanguageService.currentLanguage.value,
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient, LanguageService],
			},
		}),
		NgxHotjarModule.forRoot('GTM-5MHCF5X9'),
	],
	providers: [
		// TODO remove currency pipe from this file
		CurrencyPipe,
		NgOnDestroyService,
		{
			provide: APP_INITIALIZER,
			useFactory: (
				_http: HttpClient,
				configService: ConfigService,
				actionApiService: ActionApiService,
				retrieveQuoteService: RetrieveQuoteService,
				appService: AppService,
				eventsService: EventsService,
				languageService: LanguageService
			) => {
				return (): Promise<any> => {
					return new Promise<void>((resolve) => {
						appService.trySetBaseOnStartup();

						_http.get('./environment.json').subscribe((config: IConfig) => {
							configService.config = config;
							sessionStorage.setItem('environment', JSON.stringify(config));
							actionApiService.initTenant().subscribe((source) => {
								languageService.initLanguage();

								appService.generateStyles(actionApiService.AlternateSource(source)).subscribe(() => {
									if (!source) {
										resolve();
										return;
									}
									actionApiService
										.validateSource(source)
										.then((res) => {
											retrieveQuoteService.extractToken(source).subscribe(() => {
												eventsService.init(getD2CFeatures()?.mixpanelToken);
												resolve();
											});
										})
										.catch(() => {
											resolve();
										});
								});
							});
						});
					});
				};
			},
			deps: [
				HttpClient,
				ConfigService,
				ActionApiService,
				RetrieveQuoteService,
				AppService,
				EventsService,
				LanguageService,
			],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionInterceptor,
			multi: true,
		},
		servicesArr,
	],
	bootstrap: [AppComponent],
	exports: [
		SafePipe,
		TextUnmaskingPipe,
		FooterComponent,
		StatisticComponent,
		CarriersComponent,
		AnchorCarrierComponent,
		CoveragesListComponent,
	],
})
export class AppModule { }
