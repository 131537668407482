<section class="content">
	<div class="toggle-coverages-wrapper">
		<button>{{ "labels.additionalCoverages" | translate }}</button>
	</div>
	<p class="header">{{ "labels.additionalCoverages" | translate }}</p>

	<form [formGroup]="formGroup" class="all-additional-coverages">
		<div *ngFor="let currCoverage of coveragesTexts" class="toggle-wraaper">
			<div class="toggle-row">
				<div class="additional-coverage-name">
					<div>
						<p>{{ "additionalCoverages." + currCoverage.name + ".displayName" | translate }}</p>
						<button
							[attr.aria-label]="currCoverage.tooltipText"
							[matTooltip]="'additionalCoverages.' + currCoverage.name + '.tooltipText' | translate"
							appHiddenWhenCssDisabled
							class="help-icon material-icons"
							appToggleTooltip
						>
							<span aria-hidden="true">help_outline</span>
						</button>
					</div>
				</div>

				<mat-slide-toggle
					(click)="handleToggle(currCoverage.name)"
					[formControlName]="currCoverage.name"
					class="example-margin"
					>{{ "labels.toggleCoverage" | translate }}
				</mat-slide-toggle>
			</div>

			<app-question-wrapper *ngIf="currCoverage.withDD && checkIsActive(currCoverage.name)">
				<app-dropdown
					[name]="currCoverage.name + 'DD'"
					[options]="options[currCoverage.name]"
					[reAlphabeticalOrder]="false"
					[labelInsideField]="'labels.select' | translate"
					notShowLabelWhenSelected="true"
				></app-dropdown>
			</app-question-wrapper>
		</div>
	</form>

	<div class="bottom">
		<next-button
			(clicked)="handleContinue()"
			[continue]="true"
			[disabled]="!this.formGroup.valid"
			[showIcon]="false"
			title="Apply"
		></next-button>

		<button (click)="handleCancel()" class="cancel-button">{{ "buttons.cancel" | translate }}</button>
	</div>
</section>
