<section class="main-content main-content-personal-details">
	<app-page-heading
		[subtitle]="'titles.fewQuestionsAboutVehicle' | translate"
		[title]="'titles.lastDetailsToConfirm' | translate"
	></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div [style.width]="getProgressbarWidth()" class="progress-bar"></div>
			<div
				*ngFor="let vehicleForm of getActiveFields(); let index = index"
				[class.hidden]="index !== this.internalStep"
				[formGroup]="vehicleForm"
				class="vehicle-form"
			>
				<app-vehicle
					[cantAdd]="false"
					[formGroup]="vehicleForm"
					[isAdditionalQuestionsPage]="true"
					source="discoverVehicles"
				></app-vehicle>
				<app-question-wrapper>
					<unmasked-input
						[disabled]="!vehicleForm.value.ExternalVINPrefix"
						[emitKeyupOnBlur]="false"
						[name]="Fields.VIN.name"
						[pattern]="Fields.VIN.pattern"
						[placeholder]="'labels.vin' | translate"
						maxlength="17"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.wasTheCarNew' | translate"
						[name]="Fields.WasTheCarNew.name"
						[options]="WasTheCarNewOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper>
					<masked-input
						[inputMask]="'**/**/9999'"
						[name]="Fields.DateVehiclePurchased.name"
						[placeholder]="'labels.dateVehiclePurchased' | translate"
						class="date-input"
					>
					</masked-input>
				</app-question-wrapper>
				<app-question-wrapper *ngIf="vehicleForm.get(Fields.CostNewValue.name).enabled">
					<unmasked-input
						[isCurrency]="true"
						[name]="Fields.CostNewValue.name"
						[pattern]="Fields.CostNewValue.pattern"
						[placeholder]="'labels.costNewValue' | translate"
						[withCommaFormat]="true"
						maxlength="6"
						secondaryType="number"
					></unmasked-input>
				</app-question-wrapper>
				<app-question-wrapper>
					<app-dropdown
						[labelInsideField]="'labels.primaryUseOfVehicle' | translate"
						[name]="Fields.PrimaryUseOfVehicle.name"
						[options]="PrimaryUseOfVehicleOptions"
						[reAlphabeticalOrder]="false"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get(Fields.MilesToWork.name).enabled">
					<unmasked-input
						[maxlength]="3"
						[name]="Fields.MilesToWork.name"
						[pattern]="Fields.MilesToWork.pattern"
						[placeholder]="'labels.milesToWork' | translate"
						secondaryType="number"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper *ngIf="vehicleForm.get('OEMCoverage').enabled">
					<app-dropdown
						[options]="FieldOptions.OriginalPartsOptions"
						class="app-dropdown-custom"
						[labelInsideField]="'labels.partsReplacementType' | translate"
						name="OEMCoverage"
					>
					</app-dropdown>
					<div class="sub-text">{{ "titles.optingOemPremium" | translate }}</div>
				</app-question-wrapper>
			</div>

			<div class="spacer"></div>
			<next-button
				(clicked)="handleContinue()"
				[continue]="true"
				[disabled]="isNextDisabled"
				isFullQuote="true"
				[title]="'buttons.continue' | translate"
			></next-button>
			<button (click)="goBack()" class="back-button" type="button">{{ "buttons.goBack" | translate }}</button>
		</div>
	</form>
</section>
