import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, first } from 'rxjs';

import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { typeOfPropertyUsage } from 'src/app/constants/field-options';
import { selectAddress, selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { UpdateQuoteData } from 'src/app/store/actions/quote-data.actions';
import { selectApplicationId, selectInterviewMetadata } from 'src/app/store/selectors/interview-metadata.selector';
import { LoaderActions } from 'src/app/store/actions';
import { ActionApiService } from 'src/app/services/action-api.service';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';
// import { ILoaderInformationState } from 'src/app/store/states/loader-information.state';
// import { selectLoaderInformation } from 'src/app/store/selectors/loader-information.selector';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { updateApplication } from 'src/app/store/actions/api.actions';
import { LobService } from '../../../../../services/lob.service';
import { getD2CFeatures, isMultiLob } from 'src/app/utils/general.utils';

@Component({
	selector: 'app-property-usage',
	templateUrl: './property-usage.component.html',
	styleUrls: ['./property-usage.component.scss'],
})
export class PropertyUsageComponent implements OnInit, OnDestroy {
	lobsInPD: string[] = [];
	OccupancyType = '';
	//loaderInformation$: Observable<ILoaderInformationState>;
	optionsPropertyUsages = typeOfPropertyUsage;
	typeProperty;
	address;
	lob: string;
	lobSelection: string[] = [];
	lobsInitialized: string[] = [];
	crossSellTo: string[] = [];
	isMultiLob: boolean;

	constructor(
		private store: Store,
		private progressMeterService: ProgressMeterService,
		private actionApi: ActionApiService,
		private lobService: LobService
	) {
		//this.loaderInformation$ = this.store.select(selectLoaderInformation);
	}

	ngOnInit(): void {
		combineLatest([
			this.store.select(selectQuoteData),
			this.store.select(selectInterviewMetadata),
			this.store.select(selectAddress),
		])
			.pipe(first())
			.subscribe(([quoteData, interviewMetadata, address]) => {
				if (quoteData.OccupancyType !== 'OwnerPrimary') {
					this.OccupancyType = quoteData.OccupancyType;
				}
				this.lobsInPD = quoteData.Lobs;
				this.lobSelection = interviewMetadata.lobSelection;
				this.lobsInitialized = interviewMetadata.lobsInitialized;
				this.crossSellTo = interviewMetadata.crossSellTo;
				this.isMultiLob = interviewMetadata.isMultiLob;
				this.typeProperty = interviewMetadata._typeProperty;
				this.address = { PropertyAddress: address };
			});
	}

	handleSelect(value) {
		if (this.OccupancyType !== value) {
			this.OccupancyType = value;
			this.store.dispatch(UpdateQuoteData({ data: { OccupancyType: this.OccupancyType } }));
		}
	}

	handleContinue() {
		const isMultiLobOrCrossSellActive = this.isMultiLob || this.crossSellTo?.length > 0;
		if (this.typeProperty === LobsEnum.PERSONAL_HOME && this.OccupancyType === 'OwnerSecondary') {
			this.initApp(LobsEnum.PERSONAL_HOME, isMultiLobOrCrossSellActive);
		} else if (this.typeProperty === LobsEnum.CONDOMINIUM && this.OccupancyType === 'OwnerSecondary') {
			this.initApp(LobsEnum.CONDOMINIUM, isMultiLobOrCrossSellActive);
		} else {
			this.initApp(LobsEnum.DWELLING_FIRE, isMultiLobOrCrossSellActive);
		}
	}

	initApp(selectedLob, isMultiLobOrCrossSellActive) {
		let isFloodCrossSellActive = getD2CFeatures()?.floodCoverage;

		// if multiLob add auto if flood cross sell add flood
		let products = [];
		isMultiLobOrCrossSellActive && products.push('PersonalAuto');
		isFloodCrossSellActive && !isMultiLobOrCrossSellActive && products.push('Flood');
		let lobs = [selectedLob, ...products];
		if (
			this.lobService.checkIsLobDifferent(
				[selectedLob, isMultiLobOrCrossSellActive ? 'PersonalAuto' : 'Flood'],
				this.lobsInPD
			)
		) {
			this.store.dispatch(LoaderActions.ShowLoader());

			if (this.crossSellTo?.length && this.crossSellTo.includes(LobsEnum.PERSONAL_HOME)) {
				const lobs = this.getLobsListWithSelection(selectedLob);
				this.store.dispatch(
					updateApplication({
						formData: this.addAdditionalData(this.address),
						page: true,
						additionalData: null,
						lobs,
					})
				);
			} else {
				this.store
					.select(selectApplicationId)
					.pipe(first())
					.subscribe((appId) => {
						// there is applicationId
						if (appId) {
							this.actionApi.updateApplication(this.addAdditionalData(this.address), lobs).subscribe((res) => {
								this.store.dispatch(UpdateQuoteData({ data: res['data'] }));
								lobs = lobs.filter((lob) => lob !== LobsEnum.FLOOD);
								this.store.dispatch(
									UpdateInterviewMetadata({
										data: {
											isMultiLob: isMultiLob(selectedLob),
											lobsInitialized: lobs,
											lobSelection: lobs,
										},
									})
								);
								this.progressMeterService.hasApatite.next(res.hasApplicationAppetite);
								// wait until update store is done
								setTimeout(() => {
									this.progressMeterService.goToNextPage();
								}, 0);
							});
						}
						// no longer relevant we start to quote on last page so appId will have value all the time
						else {
							// There is no application ID so need to init quote
							this.actionApi.initApplication(lobs, this.address.PropertyAddress).subscribe((res) => {
								this.store.dispatch(UpdateInterviewMetadata({
									data: {
										applicationId: res.initial.applicationId,
										friendlyId: res.initial.friendlyId,
										hasApplicationAppetite: res.initial.hasApplicationAppetite, lobsInitialized: lobs
									}
								}));
								this.progressMeterService.hasApatite.next(res['hasApplicationAppetite']);
								// updateApplication will update and move to next page
								this.store.dispatch(
									updateApplication({
										formData: this.addAdditionalData(this.address),
										page: true,
									})
								);
							});
						}
					});
			}
		} else {
			this.store.dispatch(updateApplication({ formData: { OccupancyType: this.OccupancyType }, page: true }));
			// is cross sell to is empty then isMultiLob is false otherwise isMultiLob() will return the value
			this.store.dispatch(
				UpdateInterviewMetadata({
					data: {
						isMultiLob: this.crossSellTo?.length > 0 ? false : isMultiLob(lobs),
						lobsInitialized: [selectedLob],
					},
				})
			);
		}
	}

	getLobsListWithSelection(selectedLob) {
		const homeLobs: any = [LobsEnum.PERSONAL_HOME, LobsEnum.DWELLING_FIRE, LobsEnum.CONDOMINIUM];
		const lobsInitialized: any = [...this.lobsInitialized];
		const selectedLobIndex = lobsInitialized.findIndex((el) => homeLobs.includes(el));

		if (selectedLobIndex === -1) {
			return [selectedLob, ...lobsInitialized];
		} else {
			lobsInitialized.splice(selectedLobIndex, 1, selectedLob);
			return [...lobsInitialized];
		}
	}

	addAdditionalData(data) {
		const additionalData: any = {};
		additionalData.OccupancyType = this.OccupancyType;
		if (this.typeProperty === LobsEnum.CONDOMINIUM) {
			additionalData.PLTypeOfDwelling = LobsEnum.CONDOMINIUM;
		}
		return { ...data, ...additionalData };
	}

	ngOnDestroy(): void {
		this.store.dispatch(LoaderActions.Hideloader());
	}
}
