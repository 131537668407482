import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, first, ReplaySubject } from 'rxjs';
import { LoaderActions } from 'src/app/store/actions';
import { selectFriendly, selectPaymentData } from 'src/app/store/selectors/result-data.selectors';

@UntilDestroy()
@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, AfterViewInit {
	friendlyID;
	paymentData;
	paymentMethodIframeUrl$: ReplaySubject<string> = new ReplaySubject(1);
	@ViewChild('paymentMethodIframe') paymentMethodIframe: ElementRef;

	constructor(private store: Store) {}

	ngOnInit(): void {
		combineLatest([this.store.select(selectFriendly), this.store.select(selectPaymentData)])
			.pipe(untilDestroyed(this))
			.subscribe(([friendlyID, paymentData]) => {
				this.friendlyID = friendlyID;

				this.paymentData = { ...paymentData };
				if (this.paymentData.url) {
					this.paymentMethodIframeUrl$.next(this.paymentData.url);
				}
				this.store.dispatch(LoaderActions.Hideloader());
			});
	}

	ngAfterViewInit() {
		this.paymentMethodIframeUrl$.pipe(first()).subscribe((url) => {
			this.setIframeUrl(url);
		});
	}

	setIframeUrl(url: string) {
		// Iframe url is replaced programmatically to prevent the issue when Back button in browser
		// triggers navigation in iframe instead of parent page

		this.paymentMethodIframe.nativeElement.contentWindow.location.replace(url + '&output=embed');
	}
}
