import { Injectable } from '@angular/core';
import { StepsEnum } from '../enums/steps.enum';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { IAppState } from '../store/states/app.state';
import { Store } from '@ngrx/store';
import { getFlow, selectProgressMeter } from '../store/selectors/progress-meter.selector';
import { ActivateStep, setFlowType } from '../store/actions/progress-meter.actions';
import { selectQuoteData } from '../store/selectors/quote-data.selectors';
import { selectInterviewMetadata } from '../store/selectors/interview-metadata.selector';
import { RoutingService } from './routing.service';
import {
	getSelectedCarrier,
	selectRatePlanSelectedForOfflineBid,
	selectToggledRatesSelected,
} from '../store/selectors/result-data.selectors';
import { LobsEnum } from '../enums/lobs.enum';
import { CoverageModel } from '../models/coverage.model';
import { IProgressMeter } from '../store/states/progress-meter.state';
import { IQuoteDataState } from '../store/states/quote-data.state';
import { IInterviewMetadataState } from '../store/states/interview-metadata.state';
import { CarriersEnum } from '../enums/carriers.enum';
import { getD2CFeatures } from '../utils/general.utils';
import { NavigateToErrorPage } from '../store/actions/routing.actions';
import { RoutingEnum } from '../enums/routing.enum';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ProgressMeterService {
	flow;
	selectedCarrier: string;
	progressMeter: IProgressMeter;
	quoteData: IQuoteDataState;
	interviewMetadata: IInterviewMetadataState;
	console: any;
	routingService: RoutingService;
	rateSelectedForOfflineBid: CoverageModel[];
	toggledRatesSelected: CoverageModel[];
	stopNavigation: boolean = false;
	hasApatite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	constructor(protected store: Store<IAppState>, routingService: RoutingService, private router: Router) {
		this.routingService = routingService;
		combineLatest([
			this.store.select(selectProgressMeter),
			this.store.select(selectQuoteData),
			this.store.select(selectInterviewMetadata),
			this.store.select(getFlow),
			this.store.select(selectRatePlanSelectedForOfflineBid),
			this.store.select(selectToggledRatesSelected),
		]).subscribe(
			([progressMeter, quoteData, interviewMetadata, flow, rateSelectedForOfflineBid, toggledRatesSelected]) => {
				this.progressMeter = progressMeter;
				this.quoteData = quoteData;
				this.interviewMetadata = interviewMetadata;
				this.flow = flow;
				this.rateSelectedForOfflineBid = rateSelectedForOfflineBid;
				this.toggledRatesSelected = toggledRatesSelected;
			}
		);

		this.store.select(getSelectedCarrier).subscribe((selectedCarrier) => {
			this.selectedCarrier = selectedCarrier;
		});

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (event instanceof NavigationEnd) {
					let route = event.url.substr(1) as StepsEnum;
					if (route.indexOf(StepsEnum.YOUR_ADDRESS) > -1 || route.indexOf(StepsEnum.LOB_SELECTION) > -1 || route.indexOf(StepsEnum.PROPERTIES) > -1 || route.indexOf(StepsEnum.PRIMARY_RESIDENCE) > -1) {
						// reset stop navigation because user can select other address or other product that has apatite (those pages are before init so we need to make sure we allows user to get to create application before stop him from navigating)
						this.stopNavigation = false;
					}
				}
			});

		this.hasApatite.subscribe(hasApplicationAppetite => {
			if (!getD2CFeatures()?.checkStateAvailability) {
				this.stopNavigation = false;
				return;
			}

			if (typeof hasApplicationAppetite === 'boolean' && !hasApplicationAppetite && getD2CFeatures()?.checkStateAvailability) {
				this.store.dispatch(NavigateToErrorPage({ errorType: 'state-not-allowed' }));
				this.stopNavigation = true;
			}

			if (typeof hasApplicationAppetite === 'boolean' && hasApplicationAppetite) {
				this.stopNavigation = false;
			}
		});
	}

	getActiveFlow() {
		return this.flow;
	}

	goToNextPage(i = 1) {
		let getStage = () => {
			let flow = this.progressMeter.flowOptions[this.progressMeter.flowType];
			if (this.progressMeter.activeStep + i >= flow.length) {
				console.error('Next stage is beyond the flow length');
				return flow[this.progressMeter.activeStep];
			}
			return flow[this.progressMeter.activeStep + i];
		};

		let stage = getStage();

		if (
			(stage.checkCondition && !this.conditionFunctions(stage.name)) ||
			(stage.isCrossSell && !this.checkCrossSell(stage))
		) {
			// if condition failed go to next stage
			this.goToNextPage(++i);
			return;
		}

		// no stage condition or condition met - we can show this stage
		/*
		 * if the next stage not match one in init flow and the lob not in the url navigate to stage name with lob
		 * else navigate to stage name without lob
		 */
		if (this.stopNavigation) {
			return;
		}

		if (
			this.progressMeter.flowType !== 'init' &&
			stage.name !== StepsEnum.YOUR_ADDRESS &&
			stage.name !== StepsEnum.LOB_SELECTION
		) {
			this.routingService.navigateToRoute(`${this.getRouteName(this.progressMeter.flowType)}/${stage.name}`);
		} else {
			this.routingService.navigateToRoute(stage.name);
		}
	}

	goToPreviousPage(i = 1) {
		let getStage = () => {
			let flow = this.progressMeter.flowOptions[this.progressMeter.flowType];
			if (this.progressMeter.activeStep - i < 0) {
				console.error('Precious stage is beyond the flow length');
				return flow[this.progressMeter.activeStep];
			}
			return flow[this.progressMeter.activeStep - i];
		};

		let stage = getStage();

		if (
			(stage.checkCondition && !this.conditionFunctions(stage.name)) ||
			(stage.isCrossSell && !this.checkCrossSell(stage))
		) {
			// if condition failed go to previous stage
			this.goToPreviousPage(++i);
			return;
		}

		// no stage condition or condition met - we can show this stage
		/*
		 * if the next stage not match one in init flow and the lob not in the url navigate to stage name with lob
		 * else navigate to stage name without lob
		 */

		if (stage.name == StepsEnum.YOUR_ADDRESS) {
			this.routingService.navigateToHomePage();
			return;
		}

		if (stage.name == 'lobs') {
			this.routingService.navigateToLobSelection();
			return;
		}

		if (
			this.progressMeter.flowType !== 'init' &&
			stage.name !== StepsEnum.YOUR_ADDRESS &&
			stage.name !== StepsEnum.LOB_SELECTION
		) {
			this.routingService.navigateToRoute(`${this.getRouteName(this.progressMeter.flowType)}/${stage.name}`);
		} else {
			this.routingService.navigateToRoute(stage.name);
		}
	}

	checkCrossSell(stage) {
		return stage.isCrossSell.filter((item) => this.interviewMetadata.crossSellTo.includes(item)).length > 0;
	}

	conditionFunctions(name) {
		switch (name) {
			case StepsEnum.LOB_SELECTION:
				return this.interviewMetadata.lobsInApatite.length > 1 || this.interviewMetadata.lobsInApatite.length === 0;

			case StepsEnum.PROPERTY_USAGE:
				return this.interviewMetadata._primaryResidence === 'no';

			case StepsEnum.ROOF_REPLACEMENT:
				return (
					new Date().getFullYear() - this.quoteData.PLYearBuilt > 15 &&
					this.interviewMetadata._typeProperty !== LobsEnum.CONDOMINIUM
				);

			case StepsEnum.ADDITIONAL_QUESTIONS_HOMESITE:
				return this.selectedCarrier === 'Homesite'; //TODO: get carrier name from enum

			case StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER:
			case StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_INFORMATION_STILLWATER_HOME:
			case StepsEnum.ADDITIONAL_QUESTIONS_STILLWATER_SECOND_PAGE:
			case StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_INFORMATION_STILLWATER_HOME:
			case StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_SAFETY_STILLWATER_HOME:
			case StepsEnum.ADDITIONAL_QUESTIONS_PROPERTY_FEATURES_STILLWATER_HOME:
			case StepsEnum.ADDITIONAL_QUESTIONS_UTILITIES_STILLWATER_HOME:

			case StepsEnum.ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_FEATURES_STILLWATER_HOME:
			case StepsEnum.CARRIER_DISCLOSURE_STILLWATER:
			case StepsEnum.CURRENT_INSURANCE_STILLWATER:
				return this.selectedCarrier?.toLowerCase() === CarriersEnum.stillwater.toLowerCase();

			case StepsEnum.ADDITIONAL_QUESTIONS_LEMONADE:
				return this.selectedCarrier === 'Lemonade'; //TODO: get carrier name from enum

			case StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_DRIVERS:
			case StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_POLICY:
			case StepsEnum.ADDITIONAL_QUESTIONS_SAFECO_VEHICLES:
				return this.selectedCarrier === 'Safeco Insurance'; //TODO: get carrier name from enum

			case StepsEnum.ADDITIONAL_QUESTIONS_MISSING_DRIVERS:
				return this.selectedCarrier === 'Safeco Insurance' && this.quoteData?.missingDrivers?.length > 0; //TODO: get carrier name from enum

			case StepsEnum.PROGRESSIVE_DISCLOSURE:
			case StepsEnum.PROGRESSIVE_SNAPSHOT:
			case StepsEnum.PROGRESSIVE_DRIVERS:
			case StepsEnum.PROGRESSIVE_VEHICLES:
			case StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS:
			case StepsEnum.PROGRESSIVE_COVERAGES:
				return this.selectedCarrier === 'Progressive'; //TODO: get carrier name from enum

			case StepsEnum.PROGRESSIVE_ADDITIONAL_QUESTIONS_MISSING_DRIVERS:
				return this.selectedCarrier === 'Progressive' && this.quoteData?.missingDrivers?.length > 0; //TODO: get carrier name from enum
			case StepsEnum.PROGRESSIVE_ADDITIONAL_DRIVERS:
				return (
					this.selectedCarrier === 'Progressive' &&
					this.quoteData?.missingDrivers?.filter((r) => r.DriverAction == 'Add').length > 0
				); //TODO: get carrier name from enum

			case StepsEnum.PAYMENT_ESCROW:
				return this.isEscrowFlow();

			// PAYMENT_ESCROW overrides the PAYMENT, so they have inverted conditions
			case StepsEnum.PAYMENT:
				return !this.isEscrowFlow();

			case StepsEnum.CONFIRMATION_EMAIL_SENT:
				// should be displayed when anonymous flow enabled and no email provided in retrieval
				return this.interviewMetadata.isAnonymousFlowEnabled && !this.interviewMetadata.isEmailInRetrieval;

			default:
				return false;
		}
	}

	setFlowType(lob): void {
		this.store.dispatch(setFlowType({ flowType: lob }));
	}

	getFlowWithConditionChecked() {
		return this.getActiveFlow().filter((stage) => {
			if (!stage.checkCondition) {
				return stage;
			} else {
				if (this.conditionFunctions(stage.name)) {
					return stage;
				}
			}
		});
	}

	getRouteName(flowType) {
		switch (flowType) {
			case LobsEnum.RENTERS:
				return 'renters';
			case LobsEnum.PERSONAL_HOME:
				return 'homeowners';
			case LobsEnum.PERSONAL_AUTO:
				return 'auto';
			case LobsEnum.PETS:
				return 'pets';
			case LobsEnum.HOME_AUTO:
				return 'homeowners-auto';
		}
	}

	getFlowType(routeName: string): LobsEnum {
		switch (routeName) {
			case 'renters':
				return LobsEnum.RENTERS;
			case 'homeowners':
				return LobsEnum.PERSONAL_HOME;
			case 'auto':
				return LobsEnum.PERSONAL_AUTO;
			case 'pets':
				return LobsEnum.PETS;
			case 'homeowners-auto':
				return LobsEnum.HOME_AUTO;
			default:
				return null;
		}
	}

	isEscrowFlow(): boolean {
		// Escrow should be enabled only for single lob (no toggled rates e.g. Flood)
		if (this.rateSelectedForOfflineBid?.length === 1 && this.toggledRatesSelected.length === 0) {
			// Escrow flow is enabled when lob and carrier match those configured for Escrow
			return !!this.interviewMetadata.leadSource?.additionalInformation?.d2CFeatures?.escrowFlow?.filter(
				({ lob, carrier }) =>
					this.rateSelectedForOfflineBid[0].lob.toLowerCase() === lob.toLowerCase() &&
					this.rateSelectedForOfflineBid[0].carrier.toLowerCase() === carrier.toLowerCase()
			).length;
		}
	}

	isLobValid(lob) {
		return Object.values(RoutingEnum).includes(lob);
	}
}
