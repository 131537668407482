<section class="main-content">
	<app-page-heading
		[subtitle]="'titles.pleaseShareInformation' | translate"
		[title]="'titles.thankYouForChoosingStillwater' | translate"
	></app-page-heading>

	<ng-container *ngIf="form">
		<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper stillwater-fq">
			<app-question-wrapper>
				<label [attr.id]="Fields.PurchaseDate.name" class="input-label">
					{{ "labels.purchaseDateSW" | translate }}
				</label>
				<app-datepicker
					[maxDate]="today"
					[name]="Fields.PurchaseDate.name"
					[notshowLabelWhenFilled]="true"
					[placeholder]="'placeholders.pleaseEnter'"
					[theControl]="form"
					format="YYYY-MM-DD"
				></app-datepicker>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.YearsAtAddress.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.YearsAtAddress.name" class="input-label">
						{{ "labels.yearsAtAddressSW" | translate }}
					</label>
					<app-yes-no [name]="Fields.YearsAtAddress.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls['YearsAtAddress']?.value" class="question-container">
				<label [attr.id]="Fields.YearsAtAddress.name" class="input-label">
					{{ "labels.previousAddressSW" | translate }}
				</label>
				<app-address-suggestion
					(addressChangedEvent)="handleAddressChange(form.get('PreviousAddress'))"
					[addressLine1]="form.get('PreviousAddress.AddressLine1').value"
					[addressLine2]="form.get('PreviousAddress.AddressLine2').value"
					[city]="form.get('PreviousAddress.City').value"
					[county]="form.get('PreviousAddress.County').value"
					[fieldsNames]="fieldsNamesPreviousAddress"
					[form]="form"
					[placeholder]="'placeholders.previousHomeAddress'"
					[state]="form.get('PreviousAddress.State').value"
					[toShowMap]="false"
					[zipCode]="form.get('PreviousAddress.ZipCode').value"
				>
				</app-address-suggestion>
				<error-message [field]="form.get('PreviousAddress').get('AddressLine1')"></error-message>
				<mat-divider></mat-divider>
			</app-question-wrapper>
			<app-question-wrapper *ngIf="form.controls[Fields.isMailAddress.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.isMailAddress.name" class="input-label">
						{{ "labels.isMailAddress" | translate }}
					</label>
					<app-yes-no [name]="Fields.isMailAddress.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls['IsMailAddress']?.value" class="question-container">
				<label [attr.id]="Fields.isMailAddress.name" class="input-label">
					{{ "labels.MailingAddress" | translate }}
				</label>
				<app-address-suggestion
					(addressChangedEvent)="handleAddressChange(form.get('MailingAddress'))"
					[addressLine1]="form.get('MailingAddress.AddressLine1').value"
					[addressLine2]="form.get('MailingAddress.AddressLine2').value"
					[city]="form.get('MailingAddress.City').value"
					[county]="form.get('MailingAddress.County').value"
					[fieldsNames]="fieldsNamesMailAddress"
					[form]="form"
					[placeholder]="'placeholders.MailingAddress'"
					[state]="form.get('MailingAddress.State').value"
					[toShowMap]="false"
					[zipCode]="form.get('MailingAddress.ZipCode').value"
				>
				</app-address-suggestion>
				<error-message [field]="form.get('MailingAddress').get('AddressLine1')"></error-message>
				<mat-divider></mat-divider>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.PL_NumOfFamilies.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.PL_NumOfFamilies.name" class="input-label">
						{{ "labels.plNumOfFamilies" | translate }}
					</label>
					<app-dropdown
						[name]="Fields.PL_NumOfFamilies.name"
						[options]="FieldOptions.NumOfFamilies"
						[reAlphabeticalOrder]="false"
						labelInsideField="#"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.MultiFamilyUnit.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.MultiFamilyUnit.name" class="input-label">
						{{ "labels.multiFamilyUnit" | translate }}
					</label>
					<app-yes-no [name]="Fields.MultiFamilyUnit.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.controls[Fields.ExistingDamageOnDwelling.name]?.enabled"
				class="question-container"
			>
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.ExistingDamageOnDwelling.name" class="input-label">
						{{ "labels.existingDamageOnDwelling" | translate }}
					</label>
					<app-yes-no [name]="Fields.ExistingDamageOnDwelling.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.RoadAccess.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.RoadAccess.name" class="input-label">
						{{ "labels.roadAccess" | translate }}
					</label>
					<app-yes-no [name]="Fields.RoadAccess.name" [vertical]="vertical"></app-yes-no>
				</div>
			</app-question-wrapper>

			<next-button
				(validationFailed)="handleValidation($event)"
				[disabled]="!form.valid"
				[loaderVisible]="false"
				[myForm]="myForm"
				[shouldIgnoreDisabledFields]="true"
				[title]="'buttons.continue' | translate"
				class="fullscreen"
				isFullQuote="true"
			></next-button>
		</form>
	</ng-container>
</section>
