<section class="main-content bottom-space">
	<app-page-heading subtitleKey="driverHistory.subTitle" titleKey="driverHistory.title"></app-page-heading>

	<form #myform="ngForm" [formGroup]="form" class="stage">
		<div class="questions-container driver-history" formArrayName="driverHistory">
			<div class="tabs">
				<div
					*ngFor="let driver of driverHistory.controls; let driverIndex = index"
					[class.active]="isTabActiveOrPassed(driverIndex)"
					class="tab"
				></div>
			</div>

			<div
				*ngFor="let driver of driverHistory.controls; let driverIndex = index"
				[class.visible]="isTabActive(driverIndex)"
				[formGroupName]="driverIndex"
				class="driver-history-form"
			>
				<span class="driver-name">{{ driver.value.FirstName + " " + driver.value.LastName | uppercase }}</span>
				<!-- accident wrapper -->
				<div class="wrapper">
					<div class="left">
						<label class="title">{{ "titles.anyAccidents" | translate }}</label>
						<label class="sub-title">{{ "titles.accidentsDetails" | translate }}</label>
					</div>

					<div class="right">
						<mat-button-toggle-group formControlName="HasAccidents">
							<mat-button-toggle [value]="false">
								<span class="no-css-button-indicator" role="presentation"> &#10004;&nbsp; </span>
								{{ "labels.no" | translate }}
							</mat-button-toggle>
							<mat-button-toggle [value]="true">
								<span class="no-css-button-indicator" role="presentation"> &#10004;&nbsp; </span>
								{{ "labels.yes" | translate }}
							</mat-button-toggle>
						</mat-button-toggle-group>
					</div>

					<div
						*ngIf="getEnabledIncidentsLength(driver, 'Accidents') > 0"
						class="incidents-wrapper"
						formArrayName="Accidents"
					>
						<div
							*ngFor="let incident of getIncidents(driverIndex, 'Accidents').controls; let incidentIndex = index"
							[formGroupName]="incidentIndex"
							class="incident"
						>
							<ng-container *ngIf="incident.value.SequenceNum > -1">
								<app-question-wrapper>
									<app-dropdown
										[options]="accidents_type"
										[reAlphabeticalOrder]="false"
										data-automation="select-type-accidents"
										[labelInsideField]="'labels.type' | translate"
										name="Description"
										namePrefix="Accident"
									></app-dropdown>
								</app-question-wrapper>

								<app-question-wrapper class="date-of-birth">
									<masked-input
										[inputMask]="'99/99/9999'"
										class="date-input"
										data-automation="select-date-accidents"
										name="DateOfAccident"
										[placeholder]="'labels.when' | translate"
									>
									</masked-input>
								</app-question-wrapper>
								<!-- remove incident -->
								<button
									(click)="removeIncident(incidentIndex, driverIndex, 'Accidents', 'HasAccidents')"
									class="remove-button-desktop"
								>
									close
								</button>

								<button
									(click)="removeIncident(incidentIndex, driverIndex, 'Accidents', 'HasAccidents')"
									class="remove-button-mobile"
								>
									<i>close</i>
									<span>{{ "buttons.remove" | translate }}</span>
								</button>
							</ng-container>
						</div>
						<!-- add another incident -->
						<app-button
							(clicked)="addIncident(driverIndex, 'Accidents', 'HasAccidents')"
							*ngIf="getEnabledIncidentsLength(driver, 'Accidents') < 5"
							class="add-another-button"
							materialIcon="none"
							severity="secondary"
							[title]="'buttons.addAnother' | translate"
						></app-button>
					</div>
				</div>
				<!-- END accident wrapper-->

				<!-- violations wrapper -->
				<div class="wrapper">
					<div class="left">
						<label class="title">{{ "titles.anyViolations" | translate }}</label>
						<label class="sub-title">{{ "titles.anyViolationsDetails" | translate }}</label>
					</div>

					<div class="right">
						<mat-button-toggle-group formControlName="HasViolations">
							<mat-button-toggle [value]="false">
								<span class="no-css-button-indicator" role="presentation"> &#10004;&nbsp; </span>
								{{ "labels.no" | translate }}
							</mat-button-toggle>
							<mat-button-toggle [value]="true">
								<span class="no-css-button-indicator" role="presentation"> &#10004;&nbsp; </span>
								{{ "labels.yes" | translate }}
							</mat-button-toggle>
						</mat-button-toggle-group>
					</div>

					<div
						*ngIf="getEnabledIncidentsLength(driver, 'Violations') > 0"
						class="incidents-wrapper"
						formArrayName="Violations"
					>
						<div
							*ngFor="let incident of getIncidents(driverIndex, 'Violations').controls; let incidentIndex = index"
							[formGroupName]="incidentIndex"
							class="incident"
						>
							<ng-container *ngIf="incident.value.SequenceNum > -1">
								<app-question-wrapper>
									<app-dropdown
										[options]="violations_type"
										[reAlphabeticalOrder]="false"
										data-automation="select-type-violations"
										[labelInsideField]="'labels.type' | translate"
										name="Description"
									></app-dropdown>
								</app-question-wrapper>

								<app-question-wrapper class="date-of-birth">
									<masked-input
										[inputMask]="'99/99/9999'"
										class="date-input"
										data-automation="select-date-violations"
										name="DateOfViolation"
										[placeholder]="'labels.when' | translate"
									>
									</masked-input>
								</app-question-wrapper>
								<!-- remove incident -->
								<button
									(click)="removeIncident(incidentIndex, driverIndex, 'Violations', 'HasViolations')"
									class="remove-button-desktop"
								>
									close
								</button>

								<button
									(click)="removeIncident(incidentIndex, driverIndex, 'Violations', 'HasViolations')"
									class="remove-button-mobile"
								>
									<i>close</i>
									<span>{{ "buttons.remove" | translate }}</span>
								</button>
							</ng-container>
						</div>
						<!-- add another incident -->
						<app-button
							(clicked)="addIncident(driverIndex, 'Violations', 'HasViolations')"
							*ngIf="getEnabledIncidentsLength(driver, 'Violations') < 5"
							class="add-another-button"
							materialIcon="none"
							severity="secondary"
							[title]="'buttons.addAnother' | translate"
						></app-button>
					</div>
				</div>
				<!-- END violations wrapper-->

				<!-- losses wrapper -->
				<div class="wrapper">
					<div class="left">
						<label class="title">{{ "titles.anyLosses" | translate }}</label>
						<label class="sub-title">{{ "titles.anyLossesDetails" | translate }}</label>
					</div>

					<div class="right">
						<mat-button-toggle-group formControlName="HasAutoLosses">
							<mat-button-toggle [value]="false">
								<span class="no-css-button-indicator" role="presentation"> &#10004;&nbsp; </span>
								{{ "labels.no" | translate }}
							</mat-button-toggle>
							<mat-button-toggle [value]="true">
								<span class="no-css-button-indicator" role="presentation"> &#10004;&nbsp; </span>
								{{ "labels.yes" | translate }}
							</mat-button-toggle>
						</mat-button-toggle-group>
					</div>

					<div *ngIf="getEnabledIncidentsLength(driver, 'Losses') > 0" class="incidents-wrapper" formArrayName="Losses">
						<div
							*ngFor="let incident of getIncidents(driverIndex, 'Losses').controls; let incidentIndex = index"
							[formGroupName]="incidentIndex"
							class="incident"
						>
							<ng-container *ngIf="incident.value.SequenceNum > -1">
								<app-question-wrapper>
									<app-dropdown
										[options]="losses_type"
										[reAlphabeticalOrder]="false"
										data-automation="select-type-losses"
										[labelInsideField]="'labels.type' | translate"
										name="AutoLossesDescription"
									></app-dropdown>
								</app-question-wrapper>

								<app-question-wrapper class="date-of-birth">
									<masked-input
										[inputMask]="'99/99/9999'"
										class="date-input"
										data-automation="select-date-losses"
										name="AutoLossesDate"
										[placeholder]="'labels.when' | translate"
									></masked-input>
								</app-question-wrapper>
								<!-- remove incident -->
								<button
									(click)="removeIncident(incidentIndex, driverIndex, 'Losses', 'HasAutoLosses')"
									class="remove-button-desktop"
								>
									close
								</button>

								<button
									(click)="removeIncident(incidentIndex, driverIndex, 'Losses', 'HasAutoLosses')"
									class="remove-button-mobile"
								>
									<i>close</i>
									<span>{{ "buttons.remove" | translate }}</span>
								</button>
							</ng-container>
						</div>
						<!-- add another incident -->
						<app-button
							(clicked)="addIncident(driverIndex, 'Losses', 'HasAutoLosses')"
							*ngIf="getEnabledIncidentsLength(driver, 'Losses') < 5"
							class="add-another-button"
							materialIcon="none"
							severity="secondary"
							[title]="'buttons.addAnother' | translate"
						></app-button>
					</div>
				</div>
				<!-- END losses wrapper-->
				<div class="separator"></div>

				<next-button
					(clicked)="handleContinue()"
					[continue]="true"
					[disabled]="!driver.valid"
					[loaderVisible]="false"
					[myForm]="myform"
					[title]="'buttons.continue' | translate"
					class="continue-button"
				></next-button>

				<app-button
					(clicked)="activatePrevTab()"
					*ngIf="activeTabIndex > 0"
					class="back-button"
					materialIcon="none"
					severity="secondary"
					[title]="'buttons.goBack' | translate"
				></app-button>
				<app-button
					(clicked)="handleSkipButton()"
					*ngIf="skipbutton && activeTabIndex == 0"
					class="skip-button"
					severity="secondary"
					[title]="'buttons.skipIncidentsForAnyDriver' | translate"
				>
				</app-button>
			</div>
		</div>
	</form>
</section>
