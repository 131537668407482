export const OwnershipTypeOptions = [
	{ id: 1, value: 'Lien', title: 'Financed', labelKey: 'ownershipType.Lien' },
	{ id: 2, value: 'Owned', title: 'Owned', labelKey: 'ownershipType.Owned' },
	{ id: 3, value: 'Leased', title: 'Leased', labelKey: 'ownershipType.Leased' },
];

export const WasTheCarNewOptions = [
	{ id: 1, value: true, title: 'Yes', labelKey: 'yesNo.yes' },
	{ id: 2, value: false, title: 'No', labelKey: 'yesNo.no' },
];

export const PrimaryUseOfVehicleOptions = [
	{ id: 1, value: 'Business', title: 'Business', labelKey: 'primaryUseOfVehicleOptions.Business' },
	{ id: 2, value: 'Pleasure', title: 'Pleasure', labelKey: 'primaryUseOfVehicleOptions.Pleasure' },
	{ id: 3, value: 'To_FromWork', title: 'Commute to work', labelKey: 'primaryUseOfVehicleOptions.To_FromWork' },
	{ id: 4, value: 'To_FromSchool', title: 'Commute to school', labelKey: 'primaryUseOfVehicleOptions.To_FromSchool' },
];

export const PrimaryUseOfVehicleOptionsProgressive = [
	{ id: 1, value: 'Business', title: 'Business', labelKey: 'primaryUseOfVehicleOptions.Business' },
	{ id: 2, value: 'Pleasure', title: 'Pleasure', labelKey: 'primaryUseOfVehicleOptions.Pleasure' },
	{ id: 3, value: 'Farming', title: 'Farming', labelKey: 'primaryUseOfVehicleOptions.Farming' },
	{ id: 4, value: 'To_FromWork', title: 'Commute to work', labelKey: 'primaryUseOfVehicleOptions.To_FromWork' },
	{ id: 5, value: 'To_FromSchool', title: 'Commute to school', labelKey: 'primaryUseOfVehicleOptions.To_FromSchool' },
];

