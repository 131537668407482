import { IKeyValue } from '../../entities/key-value.interface';

export class EventData {
	eventCategory?: string;
	action?: EventActions;
	label?: string;
	value?: string;
	button?: string;
	module?: string;
	variables?: IKeyValue[];
	[key: string]: string | IKeyValue[];
}

export enum EventActions {
	BUTTON_CLICK = 'BUTTON_CLICK',
	LINK_CLICK = 'LINK_CLICK',
	DISPLAY = 'DISPLAY',
	EDIT = 'EDIT',
	PREFILL = 'PREFILL',
	PREFILL_EDIT = 'PREFILL_EDIT',
	SYS = 'SYS',
	RADIO_BUTTON = 'RADIO_BUTTON',
	INPUT_FOCUS = 'INPUT_FOCUS',
	DROPDOWN_CHANGE = 'DROPDOWN_CHANGE',
	BOX_CHECK = 'BOX_CHECK',
	START_SESSION = 'START_SESSION',
	END_SESSION = 'END_SESSION',
	PAGE_VIEW = 'PAGE_VIEW',
}
