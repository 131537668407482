import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isMobile } from 'src/app/utils/general.utils';

import { selectProgressMeter } from 'src/app/store/selectors/progress-meter.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { IProgressMeter } from 'src/app/store/states/progress-meter.state';
import { StepsEnum } from '../../../enums/steps.enum';
import { filter } from 'rxjs/operators';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { LobService } from 'src/app/services/lob.service';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';
import { EventsService } from 'src/app/services/events.service';
import { EventActions } from 'src/app/models/event-data.model';

@UntilDestroy()
@Component({
	selector: 'app-progress-meter',
	templateUrl: './progress-meter.component.html',
	styleUrls: ['./progress-meter.component.scss'],
})
export class ProgressMeterComponent implements OnInit {
	@Input() isErrorPage: boolean;
	progressMeter: IProgressMeter;
	activeStepName: string;
	isFinalePage: boolean;
	isProgressMeterVisible = false;
	width: number;
	lob;
	isMobile = isMobile();

	constructor(
		private store: Store<IAppState>,
		private router: Router,
		private getLobService: LobService,
		private progressMeterService: ProgressMeterService,
	) {
		combineLatest([this.store.select(selectProgressMeter), this.getLobService.getLob$()])
			.pipe(untilDestroyed(this))
			.subscribe(([progressMeter, lob]) => {
				this.progressMeter = progressMeter;
				this.activeStepName = progressMeter.flowOptions[progressMeter.flowType][this.progressMeter.activeStep].name;
				this.lob = lob;

				let step = window.location.pathname.split('/')[2];
				if (step === StepsEnum.LOB_SELECTION || step === StepsEnum.YOUR_ADDRESS) {
					this.handleIsPMVisibleForCurrentLob('init');
				} else {
					this.handleIsPMVisibleForCurrentLob(this.lob);
				}

				this.progressWidth();
			});
	}

	progressWidth() {
		let steps = this.progressMeterService.getFlowWithConditionChecked().map(({ name }) => name);
		let activeStepIndex = steps.indexOf(this.activeStepName);

		this.width =
			StepsEnum.LOB_SELECTION == this.activeStepName
				? Math.floor((activeStepIndex / 9) * 100)
				: (activeStepIndex / steps.length) * 100;
	}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd || event instanceof NavigationStart),
				untilDestroyed(this)
			)
			.subscribe((event: NavigationEnd | NavigationStart) => {
				if (event instanceof NavigationEnd) {
					const currentPage = this.router.url.split('?')[0].replace('/', '');
					this.isFinalePage = currentPage === StepsEnum.SUCCESS_PAGE || currentPage === StepsEnum.PAYMENT_FAILED;
				}
			});
	}

	handleIsPMVisibleForCurrentLob(lob: string): void {
		this.isProgressMeterVisible =
			this.activeStepName !== StepsEnum.YOUR_ADDRESS &&
			this.activeStepName !== StepsEnum.CONFIRMATION_EMAIL_SENT &&
			(lob === null ||
				lob === LobsEnum.RENTERS ||
				lob === LobsEnum.PETS ||
				lob === LobsEnum.PERSONAL_HOME ||
				lob === LobsEnum.CONDOMINIUM) &&
			!this.isFinalePage &&
			!this.isErrorPage &&
			!this.isMobile;
	}
}
