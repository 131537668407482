<div *ngIf="showMap && toShowMap" id="map"></div>

<div
	[ngClass]="{
		'with-placeholder': placeholder,
		error: error || controlContainer.control.controls[addressFormName]?.error,
		disabled: disabled
	}"
	class="input-container address-suggestion"
>
	<div class="input-field address-field">
		<label
			[for]="addressAutoComplete"
			[ngClass]="{ 'has-value': value, noIcon: showIcon == false, hidden: !value }"
			class="inner-label"
			>{{ placeholder | translate }}</label
		>
		<i *ngIf="showIcon !== false" appHiddenWhenCssDisabled class="material-icons" role="presentation"> location_on </i>
		<input
			#placesRef="ngx-places"
			(focus)="onFocus()"
			(keyup)="validateNumsLettersSpace(this)"
			(onAddressChange)="handleAddressChange($event)"
			[(ngModel)]="value"
			[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
			[attr.aria-invalid]="isInvalid() ? true : null"
			[id]="addressAutoComplete"
			[maxlength]="maxLength"
			[minlength]="minlength"
			[ngClass]="{
				error: isInvalid() || controlContainer.control.controls[addressFormName]?.error,
				focus: (isFocus$ | async),
				hasvalue: value,
				noIcon: showIcon == false
			}"
			[options]="options"
			[placeholder]="placeholder | translate"
			[tabindex]="tabindex ? tabindex : 0"
			class="unmasked-input"
			ngx-google-places-autocomplete
			tabindex="0"
			type="text"
		/>
	</div>

	<div class="input-field unit-field">
		<form [formGroup]="form">
			<input [attr.name]="addressAutoComplete" [value]="value" type="hidden" />
			<label [ngClass]="{ 'has-value': valueUnits, hidden: !valueUnits, noIcon: showIcon == false  }" class="inner-label" for="units">
				{{ "address.unit" | translate }}
			</label>
			<input
				(input)="updateUnitValue($event)"
				(keyup)="validateNumsLettersSpaceSpecialChars(this)"
				[attr.aria-describedby]="isInvalid() ? describedByErrorId : null"
				[attr.aria-invalid]="isInvalid() ? true : null"
				[ngClass]="{ hasvalue: valueUnits }"
				[value]="valueUnits"
				class="units"
				id="units"
				[placeholder]="'address.unit' | translate"
				type="text"
			/>
		</form>
	</div>
</div>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || addressAutoComplete"
	[field]="controlContainer.control.controls[addressFormName]"
	showIconError="true"
>
</error-message>

<error-message
	[describedByErrorId]="describedByErrorId"
	[fieldDisplayName]="postfix || addressAutoComplete"
	[field]="form.controls[addressAutoComplete]"
	[isFocus$]="isFocus$"
	showIconError="true"
>
</error-message>
