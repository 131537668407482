import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { Fields } from 'src/app/constants/fields';
import { RoofUpdatedYear } from 'src/app/constants/field-options';
import { BasePageComponent } from '../../base-page.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { selectInterviewMetadata, selectOriginYearBuilt } from 'src/app/store/selectors/interview-metadata.selector';
import { first } from 'rxjs';
import { UpdateInterviewMetadata } from 'src/app/store/actions/interview-metadata.actions';

@Component({
	selector: 'app-roof-replacement',
	templateUrl: './roof-replacement.component.html',
	styleUrls: ['./roof-replacement.component.scss'],
})
export class RoofReplacementComponent extends BasePageComponent implements OnInit, AfterViewInit {
	PLRoofUpdated;
	Fields = Fields;
	RoofUpdatedYear = RoofUpdatedYear;
	yearBuilt;

	constructor(injector: Injector, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initform();
		this.store
			.select(selectInterviewMetadata)
			.pipe(first())
			.subscribe((data) => {
				if (data.isRetrieved || data.isPLRoofUpdatedWasSelected) {
					this.patchData(this.form);
					if (this.form.controls['PLRoofUpdated']?.value) {
						this.handleSelect(this.form.controls['PLRoofUpdated'].value);
					}
				}
			});

		this.registerOnChange(this.form);
		this.store.select(selectOriginYearBuilt).subscribe((year) => {
			this.yearBuilt = year;
		});
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	initform(): void {
		this.form = new FormGroup({}, null, null);
		this.form.addControl(
			Fields.PLRoofUpdated.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);
		this.form.addControl(
			Fields.RoofUpdatedYear.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.controls.RoofUpdatedYear.valueChanges.pipe(first()).subscribe((res) => {
			if (Number.isInteger(res)) {
				let currentYear = new Date().getFullYear();
				if (currentYear - res > 15) {
					this.form.controls.RoofUpdatedYear.setValue(currentYear - 15);
				}
			} else {
				this.form.controls.RoofUpdatedYear.setValue(null);
			}
		});
	}

	handleSelect(event) {
		this.store.dispatch(UpdateInterviewMetadata({ data: { isPLRoofUpdatedWasSelected: true } }));
		if (event !== this.form.controls['PLRoofUpdated'].value) {
			setTimeout(() => {
				this.form.controls[Fields.RoofUpdatedYear.name].setValue(null);
			}, 0);
			this.form.controls[Fields.RoofUpdatedYear.name].markAsUntouched();
			this.form.controls['PLRoofUpdated'].setValue(event);
			this.PLRoofUpdated = event;
		}

		if (event === 'NotUpdated') {
			setTimeout(() => {
				this.form.controls[Fields.RoofUpdatedYear.name].setValue(this.yearBuilt);
			}, 300);
			this.form.controls.RoofUpdatedYear.setValidators(null);
			this.form.controls.RoofUpdatedYear.updateValueAndValidity();
		} else {
			this.form.controls.RoofUpdatedYear.setValidators([Validators.required]);
			this.form.controls.RoofUpdatedYear.updateValueAndValidity();
		}
	}
}
