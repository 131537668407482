import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IAppState } from 'src/app/store/states/app.state';
import { BasePageComponent } from '../../base-page.component';
import { Store } from '@ngrx/store';
import { selectRates } from 'src/app/store/selectors/result-data.selectors';
import { LoaderActions } from 'src/app/store/actions';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { EventActions } from 'src/app/models/event-data.model';
import { PaymentPlansEnum } from 'src/app/enums/payment-plans.enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StepsEnum } from '../../../../../enums/steps.enum';
import { paymentPlanSelect } from 'src/app/store/actions/result-data.actions';

@UntilDestroy()
@Component({
	selector: 'app-payment-plan',
	templateUrl: './payment-plan.component.html',
	styleUrls: ['./payment-plan.component.scss'],
	animations: [
		trigger('collapse', [
			state('false', style({ height: '0', visibility: 'hidden' })),
			state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
			transition('false => true', animate('250ms linear')),
			transition('true => false', animate('250ms linear')),
		]),
	],
})
export class PaymentPlanComponent extends BasePageComponent implements OnInit, OnDestroy {
	data = [];
	noPaymentPlans = false;
	eventActions = EventActions;
	PaymentPlanTypes;
	selectedPaymentType: string = '';
	PaymentPlansEnum = PaymentPlansEnum;

	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.updatePaymentPlans();
	}

	updatePaymentPlans() {
		this.store.select(selectRates).subscribe((res) => {
			const selectedQuoteIndex = res.findIndex((quote) => quote.selected);
			// TODO: replace rates in store to quotes to be the same from the server
			if (selectedQuoteIndex !== -1) {
				//check if payment plan is selected already before
				//gets all the type which are available
				this.PaymentPlanTypes = this.createPaymentPlansAvailable(res, selectedQuoteIndex);
				let paymentPlans = [...res[selectedQuoteIndex].paymentPlans];
				this.data = paymentPlans.sort((a, b) => {
					if (a.type === 'Escrow') {
						return -1;
					} else if (b.type === 'Escrow') {
						return 1;
					} else {
						return a.totalAmount - b.totalAmount;
					}
				});
			} else {
				this.routingService.navigateToErrorPage(StepsEnum.CARRIER_KICKOUT);
				return;
			}
		});
	}

	id(index, item): string {
		return item.id;
	}

	createPaymentPlansAvailable(paymentPlans, selectedQuoteIndex) {
		let paymentPlansTypes = [
			...new Set(
				paymentPlans[selectedQuoteIndex].paymentPlans.map((paymentPlanOption) => {
					return paymentPlanOption.type;
				})
			),
		];
		if (paymentPlansTypes.includes('EFT')) {
			paymentPlansTypes.sort(function (x, y) {
				return x == 'EFT' ? -1 : y == 'EFT' ? 1 : 0;
			});
		}

		if (paymentPlansTypes.includes('Escrow')) {
			paymentPlansTypes.sort(function (x, y) {
				return x == 'Escrow' ? -1 : y == 'Escrow' ? 1 : 0;
			});
		}
		return paymentPlansTypes;
	}

	handleTogglePaymentType(type) {
		this.selectedPaymentType = type;
	}

	isPaymentPlanSelected() {
		return !(
			[...new Set(this.data.map((i) => i.selected))].length == 1 &&
			[...new Set(this.data.map((i) => i.selected))][0] == false
		);
	}

	handleSelectPaymentPlan(data) {
		this.store.dispatch(paymentPlanSelect({ selectedPaymentPlan: data }));
	}
}
