export enum StepsEnum {
	YOUR_ADDRESS = 'your-address',
	ABOUT_YOUR_HOUSE = 'about-your-house',
	PERSONAL_DETAILS = 'personal-details',
	RESULT = 'rates',
	RESULT_LOADER = 'result-loader',
	CALL_AGENT = 'call-agent',
	HOUSE_DETAILS = 'house-details',
	POLICY_DATEPICKER = 'policy-date-picker',
	CONFIRMATION_EMAIL_SENT = 'confirmation-email-sent',
	PAYMENT_DETAILS = 'payment-details',
	PROPERTIES = 'properties',
	PRIMARY_RESIDENCE = 'primary-residence',
	LOB_SELECTION = 'lobs',
	PAYMENT_PLAN = 'payment-plan',
	SUCCESS_PAGE = 'payment-success',
	SUCCESS_PAGE_ESCROW = 'payment-success-escrow',
	PAYMENT = 'payment',
	PAYMENT_ESCROW = 'payment-escrow',
	PAYMENT_RESULT = 'payment-result',
	PAYMENT_FAILED = 'payment-failed',
	CARRIER_KICKOUT = 'carrier-kickout',
	ADDITIONAL_QUESTIONS_HOMESITE = 'additional-questions-homesite',
	ADDITIONAL_QUESTIONS_LEMONADE = 'additional-questions-lemonade',
	RETRIEVE_QUOTE = 'retrieve-quote',
	QUOTE_CONFIRMATION = 'quote-confirmation',

	// Stillwater Renters
	ADDITIONAL_QUESTIONS_STILLWATER = 'additional-questions-stillwater',
	ADDITIONAL_QUESTIONS_STILLWATER_SECOND_PAGE = 'additional-questions-stillwater-second-page',

	// Stillwater Home
	ADDITIONAL_QUESTIONS_PROPERTY_INFORMATION_STILLWATER_HOME = 'property-information-stillwater',
	ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_INFORMATION_STILLWATER_HOME = 'additional-property-information-stillwater',
	ADDITIONAL_QUESTIONS_PROPERTY_SAFETY_STILLWATER_HOME = 'property-safety-stillwater',
	ADDITIONAL_QUESTIONS_PROPERTY_FEATURES_STILLWATER_HOME = 'property-features-stillwater',
	ADDITIONAL_QUESTIONS_UTILITIES_STILLWATER_HOME = 'property-utilities-stillwater',
	ADDITIONAL_QUESTIONS_ADDITIONAL_PROPERTY_FEATURES_STILLWATER_HOME = 'additional-property-features-stillwater',
	CARRIER_DISCLOSURE_STILLWATER = 'carrier-disclosure',
	CURRENT_INSURANCE_STILLWATER = 'current-insurance',

	// Homeowners
	PROPERTY_USAGE = 'property-usage',
	ROOF_REPLACEMENT = 'roof-replacement',
	YEAR_BUILT = 'year-built',
	PERSONAL_DETAILS_HOMEOWNERS = 'personal-details',
	PERSONAL_DETAILS_BUNDLE = 'personal-details-bundle',
	POLICY_START_DATE = 'policy-start-date',
	SAFETY_ALARMS = 'safety-alarms',

	// Auto
	PRIMARY_DRIVER = 'primary-driver',
	DRIVERS = 'drivers',
	AUTO_COVERAGES = 'coverages',
	VEHICLES = 'vehicles',
	DRIVER_HISTORY = 'driver-history',
	ADDITIONAL_QUESTIONS_MISSING_DRIVERS = 'safeco-missing-drivers',

	// safeco
	ADDITIONAL_QUESTIONS_SAFECO_DRIVERS = 'safeco-drivers',
	ADDITIONAL_QUESTIONS_SAFECO_VEHICLES = 'safeco-vehicles',
	ADDITIONAL_QUESTIONS_SAFECO_POLICY = 'safeco-policy',

	// progressive
	PROGRESSIVE_DISCLOSURE = 'progressive-disclosure',
	PROGRESSIVE_SNAPSHOT = 'progressive-snapshot',
	PROGRESSIVE_VEHICLES = 'progressive-vehicles',
	PROGRESSIVE_DRIVERS = 'progressive-drivers',
	PROGRESSIVE_ADDITIONAL_DRIVERS = 'progressive-additional-drivers',
	PROGRESSIVE_ADDITIONAL_QUESTIONS_MISSING_DRIVERS = 'progressive-missing-drivers',
	PROGRESSIVE_ADDITIONAL_QUESTIONS = 'progressive-additional-questions',
	PROGRESSIVE_COVERAGES = 'progressive-coverages',

	// Pets
	PET_DETAILS = 'pet-details',

	// Multi-lob
	ALMOST_THERE = 'almost-there',

	// errors
	PAGE_NOT_FOUND = 'page-not-found-error',
	LINK_EXPIRED = 'link-expired',
	LINK_EXPIRED_AGENT = 'link-expired-agent',
	LINK_SENT = 'link-sent',
	STATE_NOT_ALLOWED = 'state-not-allowed',
	ERROR = 'error',
	TECHNICAL_ERROR = 'technical',
	END_SESSION = 'end-session',
	NO_CONSUMER_FLOW = 'no-consumer-flow',

	// theme manager specific pages
	NO_RESULTS = 'no-rates',

	// other pages
	FISKER_DISCLAIMER = 'fisker-disclaimer',
}
