import { Injectable } from "@angular/core";
import { GtmService } from "./gtm.service";
import { MixPanelService } from "./mixpanel.service";
import { EventActions, EventData } from "../models/event-data.model";

@Injectable({
	providedIn: 'root',
})
export class EventsService {

	constructor(private gtmService: GtmService, private mixPanelService: MixPanelService) { }

	init(mixPanelKey: string) {
		this.gtmService.initService();
		this.mixPanelService.initService(mixPanelKey);
	}

	addEvent(eventType: EventActions, eventData?: EventData) {
		this.gtmService.fireEvent(eventType, eventData);
		this.mixPanelService.fireEvent(eventType, eventData);
	}
}
