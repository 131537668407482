import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
	@Input() name: string;
	@Input() className: string;
	@Input() label: string;
	@Input() subLabel: string;
	@Input() tabindex: string;
	@Input() popupLink: string;

	constructor() {}

	ngOnInit(): void {}
}
