import { IAppState } from '../states/app.state';
import { createSelector } from '@ngrx/store';

const interviewMetadata = (state: IAppState) => state.interviewMetadata;

export const selectInterviewMetadata = createSelector(interviewMetadata, (l) => l);
export const selectToken = createSelector(interviewMetadata, (state) => state.token);
export const selectSourceKeyword = createSelector(interviewMetadata, (state) => state.SourceKeyword);
export const selectApplicationId = createSelector(interviewMetadata, (state) => state.applicationId);
export const selectFriendlyId = createSelector(interviewMetadata, (state) => state.friendlyId);
export const selectLobSelection = createSelector(interviewMetadata, (state) => state.lobSelection);
export const selectLobsInApatite = createSelector(interviewMetadata, (state) => state.lobsInApatite);
export const selectTypeProperty = createSelector(interviewMetadata, (state) => state._typeProperty);
export const selectPrimaryResidence = createSelector(interviewMetadata, (state) => state._primaryResidence);
export const selectOriginYearBuilt = createSelector(interviewMetadata, (state) => state.originYearBuilt);
export const selectBI = createSelector(interviewMetadata, (state) => state.BI);
export const selectLobsInitialized = createSelector(interviewMetadata, (state) => state.lobsInitialized);
export const selectLobsSelection = createSelector(interviewMetadata, (state) => state.lobSelection);

export const selectIsConfirmationEmailSentDisplayed = createSelector(
	interviewMetadata,
	(state) => state.isConfirmationEmailSentDisplayed
);
export const selectIsConfirmationEmailQuoteNoteSent = createSelector(
	interviewMetadata,
	(state) => state.isConfirmationEmailQuoteNoteSent
);
