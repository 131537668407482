import { createAction, props } from '@ngrx/store';

export const updateApplication = createAction(
	'[API] Update application',
	props<{
		formData;
		page?;
		additionalData?;
		lobs?: string[];
		Carrier?,
		isFullQuote?,
	}>()
);
