<section class="main-content">
	<app-page-heading [title]="'titles.homeFeaturesPage' | translate"></app-page-heading>
	<ng-container *ngIf="form">
		<form #myForm="ngForm" [formGroup]="form" class="all-questions-wrapper stillwater-fq">
			<app-question-wrapper
				*ngIf="form.controls[Fields.ExteriorWallsConstruction.name]?.enabled"
				class="question-container"
			>
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.ExteriorWallsConstruction.name" class="input-label">
						{{ "fields.ExteriorWallsConstruction" | translate }}
					</label>
					<app-dropdown
						[name]="Fields.ExteriorWallsConstruction.name"
						[options]="FieldOptions.ExteriorWallsConstructionOptions"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.pleaseSelect' | translate"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.NumberOfFireplaces.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.NumberOfFireplaces.name" class="input-label">
						{{ 'fields.FQData.StillWaterPersonalHome.NumberOfFireplaces' | translate }}
					</label>
					<app-dropdown
						[name]="Fields.NumberOfFireplaces.name"
						[options]="FieldOptions.NumberZeroToTenOptions"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.pleaseSelect' | translate"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<app-question-wrapper *ngIf="form.controls[Fields.NumberOfKitchens.name]?.enabled" class="question-container">
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.NumberOfKitchens.name" class="input-label">
						{{ 'fields.FQData.StillWaterPersonalHome.NumOfKitchens' | translate }}
					</label>
					<app-dropdown
						[name]="Fields.NumberOfKitchens.name"
						[options]="FieldOptions.NumberOneToTenOptions"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.pleaseSelect' | translate"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<app-question-wrapper
				*ngIf="form.controls[Fields.KitchenConstructionQuality.name]?.enabled"
				class="question-container"
			>
				<div [ngClass]="{ vertical: vertical }" class="question-wrapper show">
					<label [attr.id]="Fields.KitchenConstructionQuality.name" class="input-label">
						{{ 'fields.FQData.StillWaterPersonalHome.KitchenConstructionQuality' | translate }}
					</label>
					<app-dropdown
						[name]="Fields.KitchenConstructionQuality.name"
						[options]="FieldOptions.KitchenConstructionQuality"
						[reAlphabeticalOrder]="false"
						[labelInsideField]="'labels.pleaseSelect' | translate"
						notShowLabelWhenSelected="true"
					></app-dropdown>
				</div>
			</app-question-wrapper>

			<p class="bathroom-text-header">{{ "other.pleaseAdd" | translate }}</p>
			<div class="bathrooms-section-wraaper" formGroupName="FQData">
				<div formGroupName="StillWaterPersonalHome">
					<div class="bathrooms-section" formArrayName="BathroomInformation">
						<div *ngFor="let bathroom of bathroomInformationArray?.controls; let bathroomIndex = index">
							<ng-container *ngIf="bathroom.value.SequenceNum != -1">
								<div class="header-row-bathroom">
									<p class="bathroom-number">#{{ bathroomIndex + 1 }}</p>
									<button
										(click)="deleteBathroomRow(bathroomIndex)"
										*ngIf="this.numActiveBathrooms > 1"
										appHiddenWhenCssDisabled
										class="info-icon material-icons"
									>
										<span aria-hidden="true">delete</span>
									</button>
								</div>

								<div [formGroupName]="bathroomIndex" class="bathroom-row">
									<div class="question-wrapper show">
										<label class="input-label">{{ "labels.bathroomType" | translate }}</label>
										<app-dropdown
											[name]="Fields.BathroomType.name"
											[options]="FieldOptions.BathroomTypeOptions"
											[reAlphabeticalOrder]="false"
											[labelInsideField]="'labels.pleaseSelect' | translate"
											notShowLabelWhenSelected="true"
										></app-dropdown>
									</div>
									<div class="question-wrapper show">
										<label class="input-label">{{ "labels.numberOfBathrooms" | translate }}</label>
										<unmasked-input maxlength="2" name="NumberOfBathrooms" placeholder="#" secondaryType="number">
										</unmasked-input>
									</div>
									<div class="question-wrapper show">
										<label class="input-label">{{ "labels.constructionQuality" | translate }} </label>
										<app-dropdown
											[name]="Fields.BathroomConstructionQuality.name"
											[options]="FieldOptions.KitchenConstructionQuality"
											[reAlphabeticalOrder]="false"
											[labelInsideField]="'labels.pleaseSelect' | translate"
											notShowLabelWhenSelected="true"
										></app-dropdown>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<button
				(click)="addBathroomRow(true)"
				[disabled]="this.numActiveBathrooms == 3"
				[ngClass]="{ disabled: this.numActiveBathrooms == 3 }"
				class="add-bathroom-button"
			>
				{{ "buttons.addAnotherOne" | translate }}
			</button>
			
			<next-button
				(validationFailed)="handleValidation($event)"
				[disabled]="!form.valid"
				[loaderVisible]="false"
				[myForm]="myForm"
				[title]="'buttons.continue' | translate"
				class="fullscreen"
				isFullQuote="true"
			></next-button>
		</form>
	</ng-container>
</section>
