import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IServerRes } from 'src/entities/payload.interface';

@Injectable({
  providedIn: 'root',
})
export class QuoteDataService {
  serverQuoteErrors: BehaviorSubject<IServerRes> = new BehaviorSubject<IServerRes>(null);
  hasErrorsFromServerForThisPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateDriver: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  addManualDriver: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
  }
}
