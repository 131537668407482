import { AfterViewInit, Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Fields } from 'src/app/constants/fields';
import { EventActions } from 'src/app/models/event-data.model';
import { ActionApiService } from 'src/app/services/action-api.service';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import {
	selectDiscoverVehicles,
	selectManualVehicles,
	selectPersonalVehicles,
} from 'src/app/store/selectors/quote-data.selectors';
import { IAppState } from 'src/app/store/states/app.state';
import { first } from 'rxjs/operators';
import { BasePageComponent } from 'src/app/components/layout/pages/base-page.component';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NextButtonComponent } from 'src/app/components/next-button/next-button.component';
import { CarriersEnum } from 'src/app/enums/carriers.enum';
import { LobsEnum } from 'src/app/enums/lobs.enum';
import { sortByProperty } from 'src/app/utils/general.utils';

const FilteredStates = ['TX', 'MN', 'NC', 'UT', 'VA'];

@Component({
	selector: 'app-progressive-coverages',
	templateUrl: './progressive-coverages.component.html',
	styleUrls: ['./progressive-coverages.component.scss'],
})
export class ProgressiveCoveragesComponent extends BasePageComponent implements OnInit, AfterViewInit {
	@ViewChildren(NextButtonComponent) nextButtonComponents!: QueryList<NextButtonComponent>;

	loading = false;
	phoneNumberMask = '999-999-9999';
	eventActions = EventActions;
	actionApiService: ActionApiService;
	internalStep: number = 1;
	SelectPriorLiabilityLimitsAutoChanged: boolean = false;
	BodilyInjuryliabilityListOptions: IDropdownOption[] = [
		{ id: 1, title: "Don't know", value: 'Dontknow', labelKey: 'bodilyInjuryLiability.dontKnow' },
		{ id: 2, title: '30/60', value: 'cov30to60', labelKey: 'bodilyInjuryLiability.thirtyToSixty' },
		{ id: 3, title: '50/100', value: 'cov50to100', labelKey: 'bodilyInjuryLiability.fiftyToHundred' },
		{ id: 4, title: '100/100', value: 'cov100to100', labelKey: 'bodilyInjuryLiability.hundredToHundred' },
		{ id: 5, title: '100/300', value: 'cov100to300', labelKey: 'bodilyInjuryLiability.hundredToThreeHundred' },
		{ id: 6, title: '250/500', value: 'cov250to500', labelKey: 'bodilyInjuryLiability.twoHundredFiftyToFiveHundred' },
		{ id: 7, title: '300/300', value: 'cov300to300', labelKey: 'bodilyInjuryLiability.threeHundredToThreeHundred' },
		{ id: 8, title: '500/500', value: 'cov500to500', labelKey: 'bodilyInjuryLiability.fiveHundredToFiveHundred' },
		{ id: 9, title: '100CSL', value: 'cov100CSL', labelKey: 'bodilyInjuryLiability.hundredCSL' },
		{ id: 10, title: '300CSL', value: 'cov300CSL', labelKey: 'bodilyInjuryLiability.threeHundredCSL' },
		{ id: 11, title: '500CSL', value: 'cov500CSL', labelKey: 'bodilyInjuryLiability.fiveHundredCSL' },
	];
	UMListOptions: IDropdownOption[] = [
		{ id: 1, title: 'Reject', value: 'Reject', labelKey: 'umList.reject' },
		{ id: 2, title: '30/60', value: 'cov30to60', labelKey: 'umList.thirtyToSixty' },
		{ id: 3, title: '50/100', value: 'cov50to100', labelKey: 'umList.fiftyToHundred' },
		{ id: 4, title: '100/100', value: 'cov100to100', labelKey: 'umList.hundredToHundred' },
		{ id: 5, title: '100/300', value: 'cov100to300', labelKey: 'umList.hundredToThreeHundred' },
		{ id: 6, title: '250/500', value: 'cov250to500', labelKey: 'umList.twoHundredFiftyToFiveHundred' },
		{ id: 7, title: '300/300', value: 'cov300to300', labelKey: 'umList.threeHundredToThreeHundred' },
		{ id: 8, title: '500/500', value: 'cov500to500', labelKey: 'umList.fiveHundredToFiveHundred' },
		{ id: 9, title: '100CSL', value: 'cov100CSL', labelKey: 'umList.hundredCSL' },
		{ id: 10, title: '300CSL', value: 'cov300CSL', labelKey: 'umList.threeHundredCSL' },
		{ id: 11, title: '500CSL', value: 'cov500CSL', labelKey: 'umList.fiveHundredCSL' },
	];
	PDListOptions: IDropdownOption[] = [
		{ id: 1, title: "Don't know", value: 'Dontknow', labelKey: 'pdList.dontKnow' },
		{ id: 2, title: '25,000', value: 'cov25000', labelKey: 'pdList.twentyFiveThousand' },
		{ id: 3, title: '50,000', value: 'cov50000', labelKey: 'pdList.fiftyThousand' },
		{ id: 4, title: '100,000', value: 'cov100000', labelKey: 'pdList.oneHundredThousand' },
		{ id: 5, title: '250,000', value: 'cov250000', labelKey: 'pdList.twoHundredFiftyThousand' },
		{ id: 6, title: '500,000', value: 'cov500000', labelKey: 'pdList.fiveHundredThousand' },
	];
	MPListOptions: IDropdownOption[] = [
		{ id: 1, title: 'None', value: 'None', labelKey: 'mpList.none' },
		{ id: 2, title: '500', value: 'cov500', labelKey: 'mpList.fiveHundred' },
		{ id: 3, title: '1,000', value: 'cov1000', labelKey: 'mpList.oneThousand' },
		{ id: 4, title: '2,000', value: 'cov2000', labelKey: 'mpList.twoThousand' },
		{ id: 5, title: '2,500', value: 'cov2500', labelKey: 'mpList.twoThousandFiveHundred' },
		{ id: 6, title: '5,000', value: 'cov5000', labelKey: 'mpList.fiveThousand' },
		{ id: 7, title: '10,000', value: 'cov10000', labelKey: 'mpList.tenThousand' },
		{ id: 8, title: '15,000', value: 'cov15000', labelKey: 'mpList.fifteenThousand' },
		{ id: 9, title: '25,000', value: 'cov25000', labelKey: 'mpList.twentyFiveThousand' },
		{ id: 10, title: '50,000', value: 'cov50000', labelKey: 'mpList.fiftyThousand' },
		{ id: 11, title: '100,000', value: 'cov100000', labelKey: 'mpList.oneHundredThousand' },
	];
	UMPDListOptions: IDropdownOption[] = [
		{ id: 1, title: 'No coverage', value: 'NoCoverage', labelKey: 'umpdList.noCoverage' },
		{ id: 2, title: '25,000', value: 'cov25000', labelKey: 'umpdList.twentyFiveThousand' },
		{ id: 3, title: '50,000', value: 'cov50000', labelKey: 'umpdList.fiftyThousand' },
		{ id: 4, title: '100,000', value: 'cov100000', labelKey: 'umpdList.oneHundredThousand' },
	];
	PIPLIMITListOptions: IDropdownOption[] = [
		{ id: 1, title: 'No coverage', value: 'No_Coverage', labelKey: 'pipLimit.noCoverage' },
		{ id: 2, title: '2,500', value: 'cov2500', labelKey: 'pipLimit.twoThousandFiveHundred' },
		{ id: 3, title: '5,000', value: 'cov5000', labelKey: 'pipLimit.fiveThousand' },
		{ id: 4, title: '10,000', value: 'cov10000', labelKey: 'pipLimit.tenThousand' },
		{ id: 5, title: '25,000', value: 'cov25000', labelKey: 'pipLimit.twentyFiveThousand' },
		{ id: 6, title: '50,000', value: 'cov50000', labelKey: 'pipLimit.fiftyThousand' },
		{ id: 7, title: '100,000', value: 'cov100000', labelKey: 'pipLimit.oneHundredThousand' },
	];
	TowingLaborListOptions: IDropdownOption[] = [
		{ id: 1, title: 'No Coverage', value: 'NoCoverage', labelKey: 'towingLaborList.noCoverage' },
		{ id: 2, title: '25', value: 'TwentyFive', labelKey: 'towingLaborList.twentyFive' },
		{ id: 3, title: '40', value: 'Forty', labelKey: 'towingLaborList.forty' },
		{ id: 4, title: '50', value: 'Fifty', labelKey: 'towingLaborList.fifty' },
		{ id: 5, title: '75', value: 'SeventyFive', labelKey: 'towingLaborList.seventyFive' },
		{ id: 6, title: '80', value: 'Eighty', labelKey: 'towingLaborList.eighty' },
		{ id: 7, title: '100', value: 'Hundred', labelKey: 'towingLaborList.hundred' },
		{ id: 8, title: '120', value: 'TwelveHundred', labelKey: 'towingLaborList.oneHundredTwenty' },
		{ id: 9, title: '200', value: 'TwoHundred', labelKey: 'towingLaborList.twoHundred' },
		{ id: 10, title: 'Unlimited', value: 'Unlimited', labelKey: 'towingLaborList.unlimited' },
	];
	TransportationExpenseListOptions: IDropdownOption[] = [
		{ id: 1, title: 'No coverage', value: 'NoCoverage', labelKey: 'transportationExpenseList.noCoverage' },
		{ id: 2, title: '20/600', value: 'cov20to600', labelKey: 'transportationExpenseList.twentyToSixHundred' },
		{ id: 3, title: '30/900', value: 'cov30to900', labelKey: 'transportationExpenseList.thirtyToNineHundred' },
		{
			id: 4,
			title: '40/1200',
			value: 'cov40to1200',
			labelKey: 'transportationExpenseList.fortyToOneThousandTwoHundred',
		},
		{
			id: 5,
			title: '50/1500',
			value: 'cov50to1500',
			labelKey: 'transportationExpenseList.fiftyToOneThousandFiveHundred',
		},
	];

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		protected store: Store<IAppState>
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	get PersonalVehiclesFormArray(): FormArray {
		return this.form.controls['PersonalVehicles'] as FormArray;
	}

	get vehiclesFormArray(): FormArray {
		return this.form.controls['manualVehicles'] as FormArray;
	}

	get discoverdFormArray(): FormArray {
		return this.form.controls['discoverVehicles'] as FormArray;
	}

	ngAfterViewInit(): void {
		this.baseNgAfterViewInit();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initForm();
		this.patchData(this.form);
		this.registerOnChange(this.form, true);

		this.store
			.select(selectPersonalVehicles)
			.pipe(first())
			.subscribe((res) => {
				sortByProperty([...res], 'SequenceNum').forEach((item) => {
					if (item && item.SequenceNum !== -1 && item.enabled) {
						const formGroupItem = this.createFormGroup(item);
						this.PersonalVehiclesFormArray.push(formGroupItem);
					}
				});
			});

		this.store
			.select(selectDiscoverVehicles)
			.pipe(first())
			.subscribe((res) => {
				sortByProperty([...res], 'SequenceNum').forEach((item) => {
					if (item && item.SequenceNum !== -1 && item.enabled) {
						const formGroupItem = this.createFormGroup(item);
						this.discoverdFormArray.push(formGroupItem);
					}
				});
			});

		this.store
			.select(selectManualVehicles)
			.pipe(first())
			.subscribe((res) => {
				sortByProperty([...res], 'SequenceNum').forEach((item) => {
					if (item && item.SequenceNum !== -1 && item.enabled) {
						const formGroupItem = this.createFormGroup(item);
						this.vehiclesFormArray.push(formGroupItem);
					}
				});
			});
	}

	initForm(): void {
		this.form = new FormGroup({
			PersonalVehicles: new FormArray([]),
			discoverVehicles: new FormArray([]),
			manualVehicles: new FormArray([]),
		});

		this.form.addControl(
			Fields.BodilyInjuryliability.name,
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.addControl('UM', new FormControl({ value: null, disabled: false }, [Validators.required]));

		this.form.addControl('PD', new FormControl({ value: null, disabled: false }, [Validators.required]));

		this.form.addControl('MP', new FormControl({ value: null, disabled: false }, [Validators.required]));

		this.form.addControl('UMPD', new FormControl({ value: null, disabled: false }, [Validators.required]));

		this.form.addControl('PIP', new FormControl({ value: null, disabled: false }, [Validators.required]));

		this.form.addControl(
			'AccidentForgiveness',
			new FormControl({ value: null, disabled: false }, [Validators.required])
		);

		this.form.controls.BI.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
			if (res.includes('CSL')) {
				this.form.controls.PD.disable();
			} else {
				this.form.controls.PD.enable();
			}
		});
	}

	createFormGroup(item) {
		let data;
		let formGroupItem;

		data = {
			Id: item['Id'],
			SequenceNum: item['SequenceNum'],
			PLYear: [item['PLYear'], [Validators.required]],
			PLMake: [item['PLMake'], [Validators.required]],
			PLModel: [item['PLModel'], [Validators.required]],
			enabled: [item.enabled],
		};

		if (item.enabled) {
			data.CompDeductible = [item['CompDeductible'] || null, [Validators.required]];
			data.CollDeductible = [item['CollDeductible'] || null, [Validators.required]];
			data.LoanLease = [
				{
					value:
						item.OwnershipType == 'Owned' ? false : typeof item['LoanLease'] == 'boolean' ? item['LoanLease'] : null,
					disabled:
						item['CompDeductible'] == 'NoCoverage' ||
						item['CollDeductible'] == 'NoCoverage' ||
						item.OwnershipType == 'Owned',
				},
				[Validators.required],
			];
			data.TowingAndLabor = [item['TowingAndLabor'] || null, [Validators.required]];
			data.TransportationExpense = [item['TransportationExpense'] || null, [Validators.required]];
			data.FullGlass = [typeof item['FullGlass'] == 'boolean' ? item['FullGlass'] : null, [Validators.required]];
		}

		formGroupItem = this.formBuilder.group(data) as FormGroup;

		formGroupItem.controls.CompDeductible?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
			formGroupItem.controls.CollDeductible?.setErrors(null);
			formGroupItem.controls.CollDeductible.setValue('NoCoverage');
			formGroupItem.controls.TowingAndLabor?.setErrors(null);
		});

		if (item.OwnershipType == 'Owned') {
			formGroupItem.controls.LoanLease?.disable();
		} else {
			formGroupItem.controls.CompDeductible?.valueChanges.subscribe((value) => {
				if (value == 'NoCoverage') {
					formGroupItem.controls.LoanLease?.disable();
				} else {
					formGroupItem.controls.LoanLease?.enable();
					formGroupItem.controls.LoanLease?.markAsUntouched();
					formGroupItem.controls.LoanLease?.setValue(null);
				}
			});

			formGroupItem.controls.CollDeductible?.valueChanges.subscribe((value) => {
				if (value == 'NoCoverage') {
					formGroupItem.controls.LoanLease?.disable();
				} else {
					formGroupItem.controls.LoanLease?.enable();
					formGroupItem.controls.LoanLease?.markAsUntouched();
					formGroupItem.controls.LoanLease?.setValue(null);
				}
			});
		}

		return formGroupItem;
	}

	navigateInternal(number) {
		if (this.internalStep + number < 0) {
			return;
		}
		if (this.internalStep + number > this.getAllVehicles()?.length + 1) {
			this.handleContinue();
			return;
		}
		this.internalStep += number;
		this.eventsService.addEvent(this.EventActions.BUTTON_CLICK, {
			label: 'go-back',
			button: 'go-back-progressive-coverages',
			module: 'progressive-coverages-module',
		});
	}

	navigateToStepWithFirstError(errors, invalidReproducibleIndex: number) {
		if (errors && errors.length) {
			// If has error from server but invalidReproducibleIndex is -1, error is for the first page
			this.internalStep = invalidReproducibleIndex !== -1 ? invalidReproducibleIndex + 2 : 1;
		}
	}

	handleContinue() {
		if (this.loading) {
			return;
		}

		this.nextButtonComponents.forEach((nextButton) => (nextButton.loading = true));
		//get only the relevant
		let PersonalVehicles = [];

		this.form.value.PersonalVehicles.forEach((item) => {
			if (item.enabled) {
				PersonalVehicles.push(this.deleteExtrafieldsAndHandleLoanLease(item));
			}
		});

		this.form.value.discoverVehicles.forEach((item) => {
			if (item.enabled) {
				PersonalVehicles.push(this.deleteExtrafieldsAndHandleLoanLease(item));
			}
		});
		this.form.value.manualVehicles.forEach((item) => {
			if (item.enabled) {
				PersonalVehicles.push(this.deleteExtrafieldsAndHandleLoanLease(item));
			}
		});
		let data = {
			BI: this.form.value.BI,
			UM: this.form.value.UM,
			PD: this.form.value.PD || null,
			MP: this.form.value.MP,
			UMPD: this.form.value.UMPD,
			PIP: this.form.value.PIP,
			AccidentForgiveness: this.form.value.AccidentForgiveness,
			PersonalVehicles: PersonalVehicles,
		};

		//add SelectPriorLiabilityLimitsAuto default to null once BI value changed
		if (this.SelectPriorLiabilityLimitsAutoChanged) {
			data['SelectPriorLiabilityLimitsAuto'] = null;
		}

		this.loading = true;

		this.actionApiService
			.updateApplication(data, [LobsEnum.PERSONAL_AUTO], CarriersEnum.Progressive)
			.subscribe((serverRes) => {
				this.onUpdateApplicationResult(serverRes, this.getAllVehicles() as FormGroup[])
					.pipe(first())
					.subscribe((invalidReproducibleIndex) => {
						this.loading = false;
						if (this.quoteDataService.hasErrorsFromServerForThisPage.value) {
							this.navigateToStepWithFirstError(serverRes.validationErrors, invalidReproducibleIndex);
							this.handleValidation(serverRes.validationErrors);
						}
					});
			});
	}

	getVehicleLogoName(make: string): string {
		return make.split(' ').join('-').toLowerCase().replace('.', '');
	}

	getAllVehicles() {
		return [
			...this.PersonalVehiclesFormArray.controls,
			...this.discoverdFormArray.controls,
			...this.vehiclesFormArray.controls,
		];
	}

	deleteExtrafieldsAndHandleLoanLease(item) {
		delete item.enabled;
		delete item.PrimaryUseOfVehicle;
		delete item.NumberOfMiles;
		delete item.OwnershipType;
		delete item.WasTheCarNew;
		if (item.CollDeductible == 'NoCoverage' || item.CompDeductible == 'NoCoverage') {
			item.LoanLease = false;
		}
		return item;
	}
}
