import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { log } from 'console';

@Component({
  selector: 'app-dynamic-toggle',
  templateUrl: './dynamic-toggle.component.html',
  styleUrls: ['./dynamic-toggle.component.scss']
})
export class DynamicToggleComponent implements OnInit {

  //Inputs
  @Input() options;
  @Input() mapping;
  @Input() defualt;

  @Output() parentFun: EventEmitter<any> = new EventEmitter();
  selectedOption;

  constructor() { }

  ngOnInit(): void {
    this.selectDefault();
  }

  selectDefault() {
    //if there are are more than one option and the default value exists - it will be selected
    if (this.options.length > 1 && this.defualt && this.options.includes(this.defualt)) {
      this.selectedOption = this.defualt;
    } else {
      //if there is only one option available, this option will be the default
      this.selectedOption = this.options[0];
    }
    this.parentFun.emit(this.selectedOption);
  }

  handleToggle(type) {
    this.selectedOption = type;
    this.parentFun.emit(type);
  }

  //This function maps option value to a title only if mapping input variable exists.
  mappingFunction(type) {
    if (this.mapping && this.mapping[type]) {
      return this.mapping[type]
    } else {
      return type
    }
  }

}
