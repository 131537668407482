<section class="preview {{ section.name }}">
	<div class="content {{ order }}">
		<div class="title-section">
			<h1 appTranslation="video.title"></h1>
			<p appTranslation="video.subTitle"></p>
		</div>

		<div *ngIf="url" class="video-section">
			<video [src]="url | safe: 'resourceUrl'" playsinline preload="auto" style="width: 100%; height: 100%"></video>
		</div>
	</div>
</section>
